import React from "react";

import success_company_illustration from "../../assets/images/success_company_illustration.svg";
import success_illustration from "../../assets/images/success_illustration.svg";

const SectionSuccess = ({ type, title, desc }) => {
  return (
    <div className="d-flex flex-column align-items-center text-center px-4 gap-2">
      <img
        src={
          type === "COMPANY"
            ? success_company_illustration
            : success_illustration
        }
        alt=""
      />
      <h3 className="fw-bold">{title}</h3>
      <h6 className="text-muted">{desc}</h6>
    </div>
  );
};

export default SectionSuccess;
