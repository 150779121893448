import React, { useState, useEffect } from "react";
import {
  Modal,
  FormGroup,
  Form,
  Button,
  Card,
  Row,
  Col,
} from "react-bootstrap";
import { Calendar } from "react-modern-calendar-datepicker";
import { TimePicker } from "antd";
import moment from "moment";
import "moment/locale/id";
import { connect } from "react-redux";
import { message } from "antd";
import useBreakpoint from "../responsiveUtils/useBreakpoint";

// icons
import { MdEditCalendar } from "react-icons/md";

moment.locale("id");

function NestedDateTime(props) {
  const [labelValue, setLabelValue] = useState(
    props.sewaKendaraanReducer.startDate
      ? moment(props.sewaKendaraanReducer.startDate).format("dddd") +
          ", " +
          moment(props.sewaKendaraanReducer.startDate).format("DD/MMM/YYYY")
      : null
  );

  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const defaultStartDate = {
    year: parseInt(moment().format("YYYY")),
    month: parseInt(moment().format("MM")),
    day: parseInt(moment().format("DD")),
  };

  const currentValue = null;
  const [selectedDayRange, setSelectedDayRange] = useState(currentValue);

  const handleCalender = (data) => {
    setSelectedDayRange(data);
  };

  useEffect(() => {
    let label = props.sewaKendaraanReducer.startDate
      ? moment(props.sewaKendaraanReducer.startDate).format("dddd") +
        ", " +
        moment(props.sewaKendaraanReducer.startDate).format("DD/MMM/YYYY")
      : null;
    setLabelValue(label);
  }, [props.sewaKendaraanReducer]);

  // const getDisabledHours = () => {
  //   var hours = [];
  //   for (var i = 0; i < moment().hour(); i++) {
  //     hours.push(i);
  //   }
  //   return hours;
  // };

  // const getDisabledMinutes = (selectedHour) => {
  //   var minutes = [];
  //   if (selectedHour === moment().hour()) {
  //     for (var i = 0; i < moment().minute(); i++) {
  //       minutes.push(i);
  //     }
  //   }
  //   return minutes;
  // };

  const confirmationButton = () => {
    let value = { startDate: null, endDate: null };
    if (props.sewaKendaraanReducer.tipe === "INSTANT") {
      value.startDate = moment(new Date(), "YYYY-MM-DDThh:mm:ssTZD");
      value.endDate = null;
    } else {
      if (selectedDayRange) {
        value.startDate = moment(
          `${selectedDayRange.year}-${(selectedDayRange.month + "").padStart(
            2,
            "0"
          )}-${(selectedDayRange.day + "").padStart(
            2,
            "0"
          )} ${selectedTime}:00`,
          "YYYY-MM-DDThh:mm:ssTZD"
        );
        value.endDate = null;
      } else {
        alert("Silakan pilih range tanggal.");
      }
    }
    if (props.updateProps) {
      props.updateProps(value);
    }
    handleClose();
  };

  const setTipe = (tipe) => {
    if (props.updateProps) {
      props.updateProps({ tipe });
    }
  };

  const currentTime = moment().format("HH:mm");
  const [selectedTime, setSeletedTime] = useState(currentTime);

  const { isMobile } = useBreakpoint();

  return (
    <>
      <FormGroup className="form-group" onClick={handleShow}>
        {labelValue === null ? (
          <Form.Control
            type="text"
            placeholder={props.placeholder}
            className="form-white"
            readOnly
            value={labelValue}
          />
        ) : (
          <div className="filled d-flex">
            <div className="w-100 me-3">
              <p className="fw-medium">{labelValue}</p>
              <h6 className="text-muted">
                Waktu Antar
                <b className="ms-1">
                  {props.sewaKendaraanReducer.tipe === "INSTANT"
                    ? "(Dibawah 6 jam)"
                    : moment(props.sewaKendaraanReducer.startDate).format(
                        "HH:mm"
                      )}
                </b>
              </h6>
            </div>
            <div className="opacity-50 mt-1">
              <MdEditCalendar />
            </div>
          </div>
        )}
      </FormGroup>

      <Modal
        contentClassName={`${!isMobile && "modal-80w"} p-1`}
        show={show}
        className=""
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton className="bg-white">
          <strong>Waktu dan Tanggal Pengambilan</strong>
        </Modal.Header>
        <Card.Body>
          {/* <h5 className="mb-3">Waktu dan Tanggal Pengambilan</h5> */}
          <div className="gr-btn-select d-grid d-flex">
            <Button
              variant="light"
              className={
                props.sewaKendaraanReducer.tipe === "INSTANT"
                  ? "br-color active w-50"
                  : "br-color w-50"
              }
              onClick={() => setTipe("INSTANT")}
            >
              <p className="fw-bold mb-0">Sewa Sekarang</p>
              <h6 className="mb-0 fw-light">Ambil dibawah 6 jam</h6>
            </Button>
            <Button
              variant="light"
              className={
                props.sewaKendaraanReducer.tipe === "SCHEDULED"
                  ? "br-color active w-50"
                  : "br-color w-50"
              }
              onClick={() => setTipe("SCHEDULED")}
            >
              <p className="fw-bold mb-0">Sewa Terjadwal</p>
              <h6 className="mb-0 fw-light">Ambil diatas 6 jam</h6>
            </Button>
          </div>
          {props.sewaKendaraanReducer.tipe === "SCHEDULED" ? (
            <>
              <Calendar
                calendarSelectedDayClassName="date-select"
                value={selectedDayRange}
                onChange={(data) => {
                  setSeletedTime(moment().format("HH:mm"));
                  handleCalender(data);
                }}
                minimumDate={defaultStartDate}
                shouldHighlightWeekends
              />
              <Row>
                <Col xs={8} className="align-self-center text-end">
                  <span className="h5">Pilih Jam Antar:</span>
                </Col>
                <Col xs={4} className="align-self-center">
                  <TimePicker
                    allowClear={false}
                    minuteStep={10}
                    defaultValue={moment(currentTime, "HH:mm")}
                    value={selectedTime ? moment(selectedTime, "HH:mm") : null}
                    onChange={(time) => {
                      if (selectedDayRange) {
                        const startDateTarget = moment(
                          `${selectedDayRange.year}-${(
                            selectedDayRange.month + ""
                          ).padStart(2, "0")}-${(
                            selectedDayRange.day + ""
                          ).padStart(2, "0")} ${moment().format("HH:mm")}:00`,
                          "YYYY-MM-DDThh:mm:ssTZD"
                        ).format("YYYY-MM-DD");
                        if (startDateTarget === moment().format("YYYY-MM-DD")) {
                          if (
                            moment(time, "HH:mm").diff(
                              moment(new Date(), "HH:mm")
                            ) > 1
                          ) {
                            setSeletedTime(
                              moment(time, "HH:mm").format("HH:mm")
                            );
                          } else {
                            message.error("Jam Antar tidak valid!");
                            setSeletedTime(moment().format("HH:mm"));
                          }
                        } else {
                          setSeletedTime(moment(time, "HH:mm").format("HH:mm"));
                        }
                      } else {
                        message.error("Silakan pilih tanggal!");
                        setSeletedTime(moment().format("HH:mm"));
                      }
                    }}
                    format="HH:mm"
                  />
                </Col>
              </Row>
            </>
          ) : (
            <div>
              <h6 className="text-light text-muted my-3">
                Kendaraan diantar dalam waktu kurang dari 6 jam dengan ketentuan
                :
              </h6>
              <Card className="border-0 h6 px-2 py-3 ice">
                <Row>
                  <Col xs={4}>• 1 atau 3 Jam</Col>
                  <Col xs={8}>Blind Van, Small Pickup, Small Box</Col>
                </Row>
              </Card>
              <Card className="border-0 h6 px-2 py-3 ice">
                <Row>
                  <Col xs={4}>• 2 atau 4 Jam</Col>
                  <Col xs={8}>CDE dan CDD</Col>
                </Row>
              </Card>
              <Card className="border-0 h6 px-2 py-3 ice">
                <Row>
                  <Col xs={4}>• 3 atau 6 Jam</Col>
                  <Col xs={8}>Fuso Ringan, Fuso Berat</Col>
                </Row>
              </Card>
            </div>
          )}
        </Card.Body>
        <Modal.Footer className="border-top-0 bg-ice">
          <Button
            size="sm"
            variant="light"
            className="fw-bold me-4"
            onClick={() => handleClose()}
          >
            Batal
          </Button>
          <Button
            size="sm"
            variant="primary"
            className="fw-bold rounded-pill px-3"
            onClick={() => confirmationButton()}
          >
            Tambah
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => ({
  map: state.map,
  address: state.map.address,
  sewaKendaraanReducer: state.sewaKendaraanReducer,
});
export default connect(mapStateToProps)(NestedDateTime);
