import React, { useState } from "react";
import { Card, Row, Col, Dropdown } from "react-bootstrap";
import useBreakpoint from "../../responsiveUtils/useBreakpoint";
import { useSelector, useDispatch } from "react-redux";
import { getRoleList } from "../../../redux/action/usermanagement_action";
import RoleUserEdit from "./RoleUserEdit";
import RoleUserDetail from "./RoleUserDetail";
import RoleUserInput from "./RoleUserInput";
import { MdRadioButtonChecked } from "react-icons/md";

const RoleList = () => {
  const dispatch = useDispatch();
  const { isDesktop, isTablet } = useBreakpoint();
  const [sortBy, setSortBy] = useState("Terbaru");
  const auth = useSelector((st) => st.authReducer);
  const roleList = useSelector((st) => st.userMgtReducer.roleList.data);

  const onChangeSortBy = (value) => {
    if (value === "Terbaru") {
      dispatch(getRoleList(auth.access_token, "desc"));
    } else {
      dispatch(getRoleList(auth.access_token, "asc"));
    }
    setSortBy(value);
  };

  const loopAccess = (index) => {
    let component = [];
    let accessRoles = roleList[index].access;
    if (accessRoles && accessRoles.length === 0) {
      return <h6 style={{ color: "#797979" }}> Belum Memiliki Akses</h6>;
    } else {
      for (let index = 0; index < accessRoles.length; index++) {
        if (index < 2) {
          component.push(
            <div>
              <MdRadioButtonChecked
                style={{ fontSize: "10px", color: "#C0C0C0" }}
              />{" "}
              <span key={index} style={{ color: "#797979" }}>
                {accessRoles[index].name}
              </span>
            </div>
          );
        } else {
          break;
        }
      }
      if (accessRoles.length > 2) {
        component.push(
          <div>
            <MdRadioButtonChecked
              style={{ fontSize: "10px", color: "#C0C0C0" }}
            />
            &nbsp;
            <span key="2" style={{ color: "#797979" }}>
              {accessRoles.length - 2} Lainnya
            </span>
          </div>
        );
      }
      return component;
    }
  };

  const showData = () => {
    let component = [];

    if (roleList && roleList.length > 0) {
      roleList.forEach((item, i) => {
        component.push(
          <Card
            className="rounded-card bg-white p-4 mt-2 mb-3 border-0"
            key={i}
          >
            <Row>
              <Col lg={9}>
                <h4 className="fw-bold mb-2" style={{ fontSize: "20px" }}>
                  {item.name}
                </h4>
                <Row>
                  <Col lg={7}>
                    <h5 className="mb-1" style={{ color: "#797979" }}>
                      Deskripsi:
                    </h5>
                    <h6 style={{ color: "#797979" }}>
                      {item.description ? item.description : "-"}
                    </h6>
                  </Col>
                  <Col lg={5}>
                    <h5 className="mb-1" style={{ color: "#797979" }}>
                      {" "}
                      Akses:
                    </h5>
                    {loopAccess(i)}
                  </Col>
                </Row>
              </Col>
              <Col lg={3}>
                <div className="d-flex flex-column justify-content-between align-items-end h-100">
                  <RoleUserEdit detail={item} />
                  <RoleUserDetail detail={item} />
                </div>
              </Col>
            </Row>
          </Card>
        );
      });

      return component;
    } else {
      return (
        <Card className="rounded-card bg-white p-4 mt-2 mb-3 border-0">
          <h6
            className="text-center mb-2"
            style={{ padding: "35px", color: "#8C8C8C" }}
          >
            Anda Belum Menambahkan Role
          </h6>
        </Card>
      );
    }
  };

  const showDataMobile = () => {
    let component = [];

    if (roleList && roleList.length > 0) {
      roleList.forEach((item, i) => {
        component.push(
          <Card
            className="rounded-card bg-white p-4 mt-2 mb-3 border-0"
            key={i}
          >
            <Row>
              <Col sm={9} xs={9}>
                <h4 className="fw-bold mb-2" style={{ fontSize: "20px" }}>
                  {item.name}
                </h4>
                <Row>
                  <Col sm={5} xs={5}>
                    <h5 style={{ color: "#797979" }}>Deskripsi:</h5>
                  </Col>
                  <Col sm={7} xs={7}>
                    <h5 style={{ color: "#797979" }}>
                      {item.description ? item.description : "-"}
                    </h5>
                  </Col>
                </Row>
                <Row>
                  <Col sm={5} xs={5}>
                    <h5 style={{ color: "#797979" }}>Akses:</h5>
                  </Col>
                  <Col sm={7} xs={7}>
                    <h6 style={{ color: "#797979" }}>
                      {item.access && item.access.length > 0
                        ? item.access.length
                        : "0"}{" "}
                      Akses Terpilih
                    </h6>
                  </Col>
                </Row>
              </Col>
              <Col sm={3} xs={3}>
                <div className="d-flex flex-column justify-content-between align-items-end h-100">
                  <RoleUserEdit detail={item} />
                  <RoleUserDetail detail={item} />
                </div>
              </Col>
            </Row>
          </Card>
        );
      });

      return component;
    } else {
      return (
        <Card className="rounded-card bg-white p-4 mt-2 mb-3 border-0">
          <h6
            className="text-center mb-2"
            style={{ padding: "35px", color: "#8C8C8C" }}
          >
            Anda Belum Menambahkan Role
          </h6>
        </Card>
      );
    }
  };

  return (
    <div>
      {isDesktop ? (
        <>
          <div className="px-4 py-2 mt-2">
            <h3>List Role</h3>
            <h5>List Role dari list yang sudah anda buat.</h5>
            <h4 className="mt-3 d-inline me-5">Urutkan Berdasarkan:</h4>
            <Dropdown className="d-inline mx-2 ms-auto">
              <Dropdown.Toggle
                id="dropdown-autoclose-true"
                size="sm"
                variant="light"
                className="bg-white text-secondary px-3 border shadow-sm rounded"
              >
                {sortBy}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  href="#"
                  onClick={() => {
                    onChangeSortBy("Terbaru");
                  }}
                >
                  Terbaru
                </Dropdown.Item>
                <Dropdown.Item
                  href="#"
                  onClick={() => {
                    onChangeSortBy("Terlama");
                  }}
                >
                  Terlama
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className={`scrollable-70 px-2`}>{showData()}</div>
        </>
      ) : isTablet ? (
        <>
          <div className="px-4 py-2 mt-2">
            <div className="d-flex justify-content-between">
              <h3>List Role</h3>
              <RoleUserInput />
            </div>
            <h5>List Role dari list yang sudah anda buat.</h5>
            <h4 className="mt-3 d-inline me-5">Urutkan Berdasarkan:</h4>
            <Dropdown className="d-inline mx-2 ms-auto">
              <Dropdown.Toggle
                id="dropdown-autoclose-true"
                size="sm"
                variant="light"
                className="bg-white text-secondary px-3 border shadow-sm rounded"
              >
                {sortBy}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  href="#"
                  onClick={() => {
                    onChangeSortBy("Terbaru");
                  }}
                >
                  Terbaru
                </Dropdown.Item>
                <Dropdown.Item
                  href="#"
                  onClick={() => {
                    onChangeSortBy("Terlama");
                  }}
                >
                  Terlama
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className={`scrollable-70 px-2`}>{showData()}</div>
        </>
      ) : (
        <>
          <div className="py-2 mt-2">
            <h3>List Role</h3>
            <div className="d-flex justify-content-between mb-4">
              <h5>
                List Role dari list yang sudah
                <br /> anda buat.
              </h5>
              <RoleUserInput />
            </div>

            <h4 className="mt-3 me-2 d-inline">Urutkan Berdasarkan:</h4>
            <Dropdown className="ms-auto d-inline">
              <Dropdown.Toggle
                id="dropdown-autoclose-true"
                size="sm"
                variant="light"
                className="bg-white text-secondary px-3 border shadow-sm rounded"
              >
                {sortBy}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  href="#"
                  onClick={() => {
                    onChangeSortBy("Terbaru");
                  }}
                >
                  Terbaru
                </Dropdown.Item>
                <Dropdown.Item
                  href="#"
                  onClick={() => {
                    onChangeSortBy("Terlama");
                  }}
                >
                  Terlama
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div>{showDataMobile()}</div>
        </>
      )}
    </div>
  );
};

export default RoleList;
