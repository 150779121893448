import { Row, Col, Button, Modal, Form } from "react-bootstrap";
import { BsFillTelephoneFill } from "react-icons/bs";

// redux
import { useSelector } from "react-redux";

const ContactModal = ({ showContactModal, closeContactModal }) => {
  const trxDetail = useSelector((st) => st.trxHistoryReducer?.trxDetail);

  return (
    <Modal
      contentClassName="modal-80w p-1"
      show={showContactModal}
      onHide={closeContactModal}
      className=""
      centered
    >
      <Modal.Header closeButton className="bg-white">
        <strong>Kontak Driver</strong>
      </Modal.Header>
      <Row>
        <div className="d-flex align-items-center my-4">
          <BsFillTelephoneFill className="ms-4 text-secondary mb-2" />
          <h5 className="fw-bold text-center ms-4">
            {trxDetail?.data?.mst_driver?.wa_number}
          </h5>
        </div>
      </Row>
    </Modal>
  );
};

export default ContactModal;
