import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Button, Modal, Form } from "react-bootstrap";
import { Dropdown, Radio, Space, Spin, Steps, Divider } from "antd";
import GMap from "../../components/gMap/SimpleMap";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import {
  getStatusDesc,
  getStatusDescDetail,
  getStatusRent,
  getStatusRentDesc,
  getStatusRentDescDetail
} from "../../helper/trx_status_helper";
import StatusOrderModal from "../../components/modalTrxList/StatusOrderModal";
import PartnerDetailModal from "../../components/modalTrxList/PartnerDetailModal";
import RouteDetailModal from "../../components/modalInput/RouteDetailModal";
import CancelOrderModal from "../../components/modalTrxList/CancelOrderModal";
import ContactModal from "../../components/modalTrxList/ContactModal";
import util from "../../helper/util";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import RentInvoice from "../../components/print/RentInvoice";
import { socket } from "../../services/socket";
import useBreakpoint from "../../components/responsiveUtils/useBreakpoint";
import iconRent from "./../../assets/icon/icon-address-rent.svg"


// sections
import SendSection from "./SendSection";
import RentSection from "./RentSection";

// global state
import { setTrxState } from "../../redux/action/trx_history_action";
import { getHistoryPO, getInfoPengantaranMutasi } from "../../redux/action/sewakendaraan_action";

// api
import TrxListApi from "../../api/trx_list_api";

// icons
import {
  BsArrowLeft,
  BsThreeDotsVertical,
  BsArrowRight,
  BsFillCircleFill,
} from "react-icons/bs";
import { BiChevronDown, BiChevronUp } from 'react-icons/bi'
import { MdLocationPin } from "react-icons/md";

import icon_kirim_barang_on from "../../assets/images/sidebar-icon/icon_kBarang_on.png";
import icon_sewa_on from "../../assets/images/sidebar-icon/icon_sewa_on.png";
import icon_send_sch from "../../assets/images/icon_send_sch.svg";
import icon_send_ins from "../../assets/images/icon_send_ins.svg";
import icon_rent_sch from "../../assets/images/icon_rent_sch.svg";
import icon_rent_ins from "../../assets/images/icon_rent_ins.svg";
import { hideSidenavMobile } from "../../components/layouts/layout_helper";
import ModalInfoIntegrasi from "./components/Modal/ModalInfoIntegrasi";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const DetailTransaksi = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = useParams();
  let query = useQuery();
  const auth = useSelector((st) => st.authReducer);
  const trxDetail = useSelector((st) => st.trxHistoryReducer.trxDetail);
  const historyPO = useSelector((st)=> st.sewaKendaraanReducer.historyPO)
  const infoPengantaranMutasi = useSelector((st)=> st.sewaKendaraanReducer.infoPengantaranMutasi)
  const { isMobile, isDesktop, isTablet } = useBreakpoint();
  const [showMapTabletAndMobile, setShowMapTabletAndMobile] = useState(false);
  const [showDetailHistoryLogMobile, setShowDetailHistoryLogMobile] = useState(true);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [showRouteDetailModal, setShowRouteDetailModal] = useState(false);
  const [showPartnerDetailModal, setShowPartnerDetailModal] = useState(false);
  const [showOrderStatusModal, setShowOrderStatusModal] = useState(false);
  const [showCancelOrderModal, setShowCancelOrderModal] = useState(false);

  const getOrderDetail = async () => {
    try {
      dispatch(
        setTrxState(
          { trxDetail: { loading: true, data: {} } },
          auth.refresh_token
        )
      );

      let api = new TrxListApi();
      let data;
      let { data: orderDetail } = await api.getRentOrderDetail(
        auth.access_token,
        id
      );

      data = { ...orderDetail };

      dispatch(
        setTrxState(
          { trxDetail: { loading: false, data: { ...data } } },
          auth.refresh_token
        )
      );
    } catch (error) {
      dispatch(
        setTrxState(
          { trxDetail: { loading: false, data: {} } },
          auth.refresh_token
        )
      );
    }
  };

  const generateSection = () => {
    const helper = {
      RENT: (
        <RentSection
          setShowDetailHistoryLogMobile={setShowDetailHistoryLogMobile}
          setShowMapTabletAndMobile={setShowMapTabletAndMobile}
          openPartnerDetailModal={openPartnerDetailModal}
          openContactModal={openContactModal}
          getOrderDetail={getOrderDetail}
          infoPengantaranMutasi={infoPengantaranMutasi}
        />
      ),
    };

    return helper["RENT"];
  };

  useEffect(() => {
    getOrderDetail();
    dispatch(getHistoryPO(id))
    // dispatch(getInfoPengantaranMutasi(id))
  }, []);

  useEffect(() => {
    if (isMobile) hideSidenavMobile();
  }, [isMobile]);

  const openDropdown = () => {
    setShowDropdown(true);
  };

  const closeDropdown = () => {
    setShowDropdown(false);
  };

  const openContactModal = () => {
    setShowContactModal(true);
  };

  const closeContactModal = () => {
    setShowContactModal(false);
  };

  const openRouteDetailModal = () => {
    setShowRouteDetailModal(true);
  };

  const closeRouteDetailModal = () => {
    setShowRouteDetailModal(false);
  };

  const openPartnerDetailModal = () => {
    setShowPartnerDetailModal(true);
  };

  const closePartnerDetailModal = () => {
    setShowPartnerDetailModal(false);
  };

  const openOrderStatusModal = () => {
    setShowOrderStatusModal(true);
  };

  const closeOrderStatusModal = () => {
    setShowOrderStatusModal(false);
  };

  const openCancelOrderModal = () => {
    setShowCancelOrderModal(true);
  };

  const closeCancelOrderModal = () => {
    setShowCancelOrderModal(false);
  };

  const getIcon = () => {
    if (trxDetail?.data?.type_order === "RENT") {
      if (trxDetail?.data?.trx_booking_rent?.rent_category === "SCHEDULED") {
        return icon_rent_sch;
      }
      if (trxDetail?.data?.trx_booking_rent?.rent_category === "INSTANT") {
        return icon_rent_ins;
      }
    }

    return null;
  };

  const goBackPage = () => {
    history.goBack();
  };

  const componentRef = useRef();

  const dropdownOverlay = () => {
    return (
      <div className="bg-white drpdwn-menu">
        {/* <div
          className="p-2 pointer drpdwn-item"
          hidden={isTrxDone()}
          onClick={openCancelOrderModal}
        >
          <p>Ajukan Pembatalan</p>
        </div> */}
        <div className="p-2 pointer drpdwn-item">
          <ModalInfoIntegrasi data={historyPO}/>
        </div>
        <div className="p-2 pointer drpdwn-item" hidden={isTrxDone()}>
          <p className="m-0">Ajukan Keluhan</p>
        </div>
        <div className="p-2 pointer drpdwn-item">
          <p className="m-0">Bantuan CS eLOG</p>
        </div>
        <div className="p-2 pointer drpdwn-item" hidden={!isTrxDone()}>
          <ReactToPrint
            trigger={() => {
              return <a href="#">Download Invoice</a>;
            }}
            content={() => componentRef.current}
          ></ReactToPrint>
        </div>
      </div>
    );
  };

  const getStatus = (status) => {
    const helper = {
      PENDING: "Menunggu Konfirmasi",
    };

    return helper[status];
  };

  const getStatusTextColor = (status) => {
    const helper = {
      PENDING: "text-warning",
    };

    return helper[status];
  };

  const getLastLog = () => {
    const arr = trxDetail?.data?.log_booking_status;
    return arr?.[arr?.length - 1];
  };

  const isTrxDone = () => {
    if (
      trxDetail?.data?.booking_status === "CANCELLED" ||
      trxDetail?.data?.booking_status === "FINISHED"
    ) {
      return true;
    }

    return false;
  };

  if (trxDetail?.loading) {
    return (
      <div
        style={{ minHeight: "100vh", width: "100%", padding: "30px" }}
        className="d-flex align-items-center justify-content-center bg-ice"
      >
        <Spin />
      </div>
    );
  }

  const handleRedirectTracking = () => {
    const website = process.env.REACT_APP_WEBSITE_URL
    window.open(`${website}/tracking/rent/${id}`, "_blank")
  }


  return (
    <>
      <RentInvoice ref={componentRef} />
      <div
        style={{ minHeight: "100vh", width: "100%", padding: "30px" }}
        className="bg-ice"
      >
        
        <Row>
          
          <Col
            xs={12}
            md={12}
            lg={8}
            className="scrollable"
            style={{ height: "93vh" }}
            // hidden={
            //   showMapTabletAndMobile || showDetailHistoryLogMobile
            //     ? true
            //     : false
            // }
          >
            <div
              className="d-flex gap-1 pointer"
              onClick={() => {
                if (showMapTabletAndMobile) setShowMapTabletAndMobile(false);
                else if (showDetailHistoryLogMobile)
                  setShowDetailHistoryLogMobile(false);
                else goBackPage();
              }}
            >
              <BsArrowLeft />
              <h4 className="fw-bold">
                {showMapTabletAndMobile || showDetailHistoryLogMobile
                  ? "Detail Pesanan"
                  : "Daftar Transaksi"}
              </h4>
            </div>

            <Row className="d-flex align-items-center">
              <Col className="d-flex align-items-center">
                <img src={getIcon()} className="i-sidebar" alt="" />
                <Col className="ms-2 mt-2">
                  <div className="d-flex align-items-center">
                    <h3 className="fw-bold me-2">
                      {trxDetail?.data?.type_order === "RENT"
                        ? "Sewa Kendaraan"
                        : "Kirim Barang"}
                    </h3>
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <h5 className="">
                      Terjadwal - {moment(trxDetail?.data?.created_at).format("DD MMM YYYY")}
                    </h5>
                    {/* <h5> &bull; </h5>
                    <h5>
                      {moment(trxDetail?.data?.created_at).format("HH : mm")}
                    </h5> */}
                  </div>
                </Col>
              </Col>
              <Col
                className={`${
                  isMobile ? "text-start" : "text-end"
                }  d-flex align-items-center`}
              >
                <Col className={`${!isMobile ? " mt-3" : "ms-2"}`}>
                  <div className="" hidden={isMobile}>
                    <h5 className="me-2">ID Transaksi</h5>
                  </div>
                  <div className="">
                    <h3 className="text-primary fw-bold">
                      {trxDetail?.data?.order_number}
                    </h3>
                  </div>
                </Col>
                <Dropdown
                  onClick={() => {
                    if (showDropdown) {
                      closeDropdown();
                    } else {
                      openDropdown();
                    }
                  }}
                  overlay={dropdownOverlay}
                  trigger={["click"]}
                  visible={showDropdown}
                  placement="bottomRight"
                >
                  <Button
                    variant="outline-primary"
                    className="ms-2"
                    style={{ marginTop: isMobile ? "-7.6rem" : undefined }}
                  >
                    <BsThreeDotsVertical />
                  </Button>
                </Dropdown>
              </Col>
            </Row>
            {/* <Row hidden={!isTrxDone()}>
              <Col>
                <Button className="w-100" variant="outline-primary">
                  Ajukan Keluhan
                </Button>
              </Col>
              <Col>
                <Button
                  className="w-100"
                  onClick={() => {
                    history.push("/sewa-kendaraan");
                  }}
                >
                  Order Lagi
                </Button>
              </Col>
            </Row> */}
            {generateSection()}
          </Col>
          <Col
            className="rounded-card position-relative card-map-right bg-white ms-2"
            style={{ maxHeight: "92vh"}}
            // hidden={showDetailHistoryLogMobile}
            // hidden={
            //   showDetailHistoryLogMobile
            //     ? false
            //     : !isDesktop
            //     ? true
            //     : !isTrxDone()
            //     ? true
            //     : false
            // }
          >
            <div className="pt-3 pb-2 border-bottom d-flex justify-content-between align-items-center">
              <h5 className="fw-bold">Aktivitas Pesanan</h5>
              {
                (trxDetail?.data?.trx_booking_rent?.rent_status === "GO_TO_PICKUP_LOCATION" ||
                trxDetail?.data?.trx_booking_rent?.rent_status === "DELIVER_REPLACE" ||
                trxDetail?.data?.trx_booking_rent?.rent_status === "DELIVER_MUTATION" ||
                trxDetail?.data?.trx_booking_rent?.rent_status === "ON_GOING" ||
                trxDetail?.data?.trx_booking_rent?.rent_status === "GO_TO_RETURN_LOCATION" ||
                trxDetail?.data?.trx_booking_rent?.rent_status === "FINISHED") &&
                <Button
                  size="sm"
                  className="fw-bold py-1 px-2 rounded text-capitalize"
                  onClick={handleRedirectTracking}
                >
                  Live Tracking
                </Button>
              }
            </div>
            <Row className="py-4 px-1" style={{ maxHeight: "78vh", overflow:"auto", overflowX:"hidden"}}>
              {trxDetail?.data?.log_booking_status?.length > 0 &&
                [...trxDetail?.data?.log_booking_status]
                  // ?.reverse()
                  ?.map((el, i) => (
                    <>
                      {/* <Col xs={3}>
                        <h6 className="float-end">
                          {moment(el?.crated_at).format("DD MMM YYYY, HH:mm")}
                        </h6>
                        <h6 className="float-end fw-bold">
                          {moment(el?.created_at).format("HH : mm")} WIB
                        </h6>
                      </Col> */}
                      <Col xs={12}>
                        <Steps
                          progressDot
                          // status={i == 0 ? "process" : "wait"}
                          direction="vertical"
                        >
                          <Steps.Step
                            style={{fontSize:"10px"}}
                            // title={getStatusDesc(el?.order_status)}
                            description={
                              <>
                                <div className="d-flex justify-content-between align-items-center mb-2"  style={{marginTop:"-12px"}}>
                                  <h6 className="fw-bold p-0 m-0" style={{fontSize:"13px"}}>{getStatusRent(el?.order_status)}</h6>
                                  <h6 className="text-muted p-0 m-0" style={{fontSize:"10px"}}>{moment(el?.crated_at).format("DD MMM YYYY, HH:mm")}</h6>
                                </div>
                                <h6 className="p-0" style={{fontSize:"10px", color:"#BBBBBB"}}>{getStatusRentDesc(el?.order_status)}</h6>
                                {/* <h6 className="mb-3 p-0" style={{fontSize:"10px", color:"#333333", marginTop:"-5px"}}>{getStatusRentDescDetail(el?.order_status)}</h6> */}
                                {/* {(el?.order_status ===
                                  "GO_TO_PICKUP_LOCATION" ||
                                  el?.order_status ===
                                    "GO_TO_RETURN_LOCATION") && (
                                  <>
                                    <p style={{marginTop:"-10px"}}>
                                      Nama : {trxDetail?.data?.mst_driver?.name}
                                    </p>
                                    <p style={{marginTop:"-10px"}}>
                                      Nomor Ponsel : +
                                      {trxDetail?.data?.mst_driver?.wa_number}
                                    </p>
                                  </>
                                )} */}
                              </>
                            }
                          />
                          {i <
                            trxDetail?.data?.log_booking_status.length - 1 && (
                            <Divider />
                          )}
                        </Steps>
                      </Col>
                    </>
                  ))}
            </Row>
            <div hidden className="position-absolute bottom-0 border-top pb-2 pt-3" style={{backgroundColor:"white", width:"95%", overflow:"hidden"}}>
              <h6
                className="fw-bold text-center"
                style={{color:"#FF1DBC", textDecoration:"underline", fontSize:"11px", cursor:"pointer"}}
                onClick={()=> setShowDetailHistoryLogMobile(!showDetailHistoryLogMobile)}
              >
                Tampilkan Lebih Sedikit
                <BiChevronUp style={{fontSize:"14px"}}/>
              </h6>
            </div>
          </Col>


          <Col
            className="rounded-card card-map-right"
            style={{ height: "93vh" }}
            hidden
            // hidden={showDetailHistoryLogMobile ? false : true}
            // hidden={
            //   showMapTabletAndMobile
            //     ? false
            //     : !isDesktop
            //     ? true
            //     : isTrxDone()
            //     ? true
            //     : false
            // }
            // hidden
          >
            <GMap hideControlButton />
            <div
              className="card-map-item-top "
              style={{ width: isMobile ? "87%" : "94%" }}
            >
              <div
                className="bg-white rounded-card px-1 py-3 shadow-md d-flex justify-content-evenly align-items-center gap-1"
                style={{ width: "100%", opacity: "0.9" }}
              >
                <>
                <div className="d-flex">
                  <h6 className="m-0" style={{fontSize:"11px"}}>Kendaraan:</h6>
                  <h6 className="m-0 ms-1 fw-bold" style={{fontSize:"11px", color:"#0FAA4B"}}>Xenia Sport XXL</h6>
                </div>
                <div className="d-flex">
                  <h6 className="m-0" style={{fontSize:"11px"}}>Lokasi:</h6>
                  <h6 className="m-0 ms-1 fw-bold" style={{fontSize:"11px", color:"#0FAA4B"}}>Alamat Sewa</h6>
                </div>
                </>
              </div>
            </div>
            <div className="card-map-item m-auto" style={{ width: "94%" }}>
              <div
                className={`bg-white rounded-card py-3 shadow-md d-flex w-100 ${
                  isMobile ? "flex-column" : "align-items-center"
                }`}
                style={{ width: "100%" }}
              >
                <Row className="ms-4 w-100">
                  <Col className="col-lg-1 m-0 p-0">
                    <img
                      src={iconRent}
                      alt="rent icon"
                      style={{
                        marginTop:"-3px",
                        width: "18px",
                      }}
                    />
                  </Col>
                  <Col className="col-lg-11 m-0 p-0">
                    <div className="px-0" style={{marginBottom:"40px"}}>
                      <h6 className="fw-bold" style={{fontSize:"15px"}}>{getStatusRent(trxDetail?.data?.trx_booking_rent?.rent_status)}</h6>
                      <h6 className="m-0 mb-1" style={{fontSize:"10px", color:"#BBBBBB"}}>{getStatusRentDesc(trxDetail?.data?.trx_booking_rent?.rent_status)}</h6>
                      <h6 className="m-0"style={{fontSize:"10px", color:"#333333"}}>{getStatusRentDescDetail(trxDetail?.data?.trx_booking_rent?.rent_status)}</h6>
                    </div>
                  </Col>
                </Row>
                
                <div className="position-absolute bottom-0 border-top w-100 py-3 overflow-hidden">
                  <h6
                    className="fw-bold text-center m-0"
                    style={{color:"#FF1DBC", textDecoration:"underline", fontSize:"11px", cursor:"pointer"}}
                    onClick={()=> setShowDetailHistoryLogMobile(!showDetailHistoryLogMobile)}
                  >
                    Tampilkan Lebih Banyak
                    <BiChevronDown style={{fontSize:"14px"}}/>
                  </h6>
                </div>
                
                {/* <Row>
                  <Col xs={3}>
                    <Row>
                      <Col>
                        <h6 className="text-muted">
                          {moment(getLastLog()?.created_at).format(
                            "DD MMM YYYY"
                          )}
                        </h6>
                        <h5>
                          {moment(getLastLog()?.created_at).format("HH : mm")}{" "}
                          WIB
                        </h5>
                      </Col>
                      <Col hidden={isMobile}>
                        <BsFillCircleFill className="text-secondary" />
                        <div
                          className="br-dash"
                          style={{
                            height: "35px",
                            marginLeft: "7px",
                            marginTop: "5px",
                          }}
                        />
                        <BsFillCircleFill style={{ color: "#ced4da" }} />
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <h5 className="fw-bold">
                      {getStatusDesc(getLastLog()?.order_status)}
                    </h5>
                    <h5>{getStatusDescDetail(getLastLog()?.order_status)}</h5>
                    {(getLastLog()?.order_status === "GO_TO_PICKUP_LOCATION" ||
                      getLastLog()?.order_status ===
                        "GO_TO_RETURN_LOCATION") && (
                      <>
                        <p>Nama : {trxDetail?.data?.mst_driver?.name}</p>
                        <p>
                          Nomor Ponsel : +
                          {trxDetail?.data?.mst_driver?.wa_number}
                        </p>
                      </>
                    )}
                    <h5
                      className="text-primary pointer mt-2"
                      onClick={openOrderStatusModal}
                    >
                      Lihat Detail
                      <BsArrowRight style={{ marginLeft: "5px" }} />
                    </h5>
                  </Col>
                </Row> */}
              </div>
            </div>
          </Col>
        </Row>
        <StatusOrderModal
          showOrderStatusModal={showOrderStatusModal}
          closeOrderStatusModal={closeOrderStatusModal}
          log_booking_status={trxDetail?.data?.log_booking_status}
          trxDetail={trxDetail}
        />
        <CancelOrderModal
          showCancelOrderModal={showCancelOrderModal}
          closeCancelOrderModal={closeCancelOrderModal}
        />
        <PartnerDetailModal
          showPartnerDetailModal={showPartnerDetailModal}
          closePartnerDetailModal={closePartnerDetailModal}
        />
        <RouteDetailModal
          showRouteDetailModal={showRouteDetailModal}
          closeRouteDetailModal={closeRouteDetailModal}
          trxDetail={trxDetail}
        />
        <ContactModal
          showContactModal={showContactModal}
          closeContactModal={closeContactModal}
        />
      </div>
    </>
  );
};

export default DetailTransaksi;
