const initState = {
  isLoading: false,
  data: [],
  activeEventKey: null,
  custom_phone_number: null,
};

export const paymentListReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_PAYMENT_LIST":
      return { ...state, ...action.payload };
    case "SET_ACTIVE_EVENT_KEY":
      return { ...state, activeEventKey: action.payload };
    case "SET_CUSTOM_PHONE_NUMBER":
      return { ...state, custom_phone_number: action.payload };
    case "RESET_PAYMENT_LIST":
      return { ...initState, ...action.payload };
    default:
      return state;
  }
};
