import React, { useState, useEffect } from "react";
import { Row, Col, Card, Modal, Form, Button } from "react-bootstrap";
import { Checkbox, Dropdown } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { updateDetail } from "../../redux/action/kirimbarang_action";

// icons
import { IoChevronDown } from "react-icons/io5";
import { AiFillMinusCircle, AiFillPlusCircle } from "react-icons/ai";

function PickHelper(props) {
  const dispatch = useDispatch();
  const selectedPartnerService = useSelector(
    (st) => st.kirimbarangReducer.selectedPartnerService
  );
  const detailOrder = useSelector((st) => st.kirimbarangReducer.detailOrder);
  const [show, setShow] = useState(false);
  const [checked, setChecked] = useState(false);
  const [totalHelperRequested, setTotalHelperRequested] = useState(0);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleIncrement = () => {
    let currentHelper = detailOrder.total_helper_requested;
    let helperAvailability =
      selectedPartnerService?.mst_partner_service_pricing
        ?.helper_availability_send;
    if (currentHelper < helperAvailability) {
      let value = currentHelper + 1;
      setTotalHelperRequested(value);

      dispatch(
        updateDetail({
          detailOrder: {
            ...detailOrder,
            total_helper_requested: value,
            helper_availability_send_selected: true,
          },
        })
      );
      // props.hitSummary({ total_helper: value });
    }

    setChecked(true);
  };
  const handleDecrement = () => {
    let currentHelper = detailOrder.total_helper_requested;
    if (currentHelper > 0) {
      let value = currentHelper - 1;
      setTotalHelperRequested(value);

      dispatch(
        updateDetail({
          detailOrder: {
            ...detailOrder,
            total_helper_requested: value,
            helper_availability_send_selected: value === 0 ? false : true,
          },
        })
      );
      // props.hitSummary({ total_helper: value });
    } else {
      setChecked(false);
      // props.hitSummary({ total_helper: 0 });
    }
  };

  useEffect(() => {
    props.hitSummary({});
  }, [checked, totalHelperRequested]);

  const onChangeChecklist = () => {
    if (checked) {
      setChecked(false);
      // props.hitSummary({ total_helper: 0 });
      setTotalHelperRequested(0);
      dispatch(
        updateDetail({
          detailOrder: {
            ...detailOrder,
            total_helper_requested: 0,
            helper_availability_send_selected: false,
          },
        })
      );
      // props.hitSummary({});
    } else {
      // props.hitSummary({ total_helper: 1 });
      setTotalHelperRequested(1);

      dispatch(
        updateDetail({
          detailOrder: {
            ...detailOrder,
            total_helper_requested: 1,
            helper_availability_send_selected: true,
          },
        })
      );
      // props.hitSummary({});

      setChecked(true);
    }
  };

  const dropdownOverlay = () => {
    return (
      <Row className="card-body bg-white drpdwn-pick">
        <Col xs={6} className="align-self-center">
          <p className="fw-bold mb-0">Helper</p>
          <h6 className="mb-0 text-muted">Maksimal 3 Orang Helper</h6>
        </Col>
        <Col xs={5} className="align-self-center text-end">
          <h6 className="mb-0 fw-bold">
            + Rp{" "}
            {selectedPartnerService?.mst_partner_service_pricing
              ?.helper_price_send * detailOrder.total_helper_requested}
          </h6>
        </Col>
        <Col xs={7} className="align-self-center mt-4">
          <p className="fw-bold mb-0">Jumlah Helper</p>
          <h6 className="mb-0 text-muted">
            Biaya Rp{" "}
            {
              selectedPartnerService?.mst_partner_service_pricing
                ?.helper_price_send
            }{" "}
            /orang
          </h6>
        </Col>
        <Col xs={5} className="align-self-center mt-4 d-flex  float-end">
          <Button
            variant="link"
            onClick={() => {
              handleDecrement();
            }}
            disabled={detailOrder.total_helper_requested <= 1}
          >
            <AiFillMinusCircle className="text-muted" />
          </Button>
          <Form.Control type="number" value={totalHelperRequested} />
          <Button
            variant="link"
            onClick={() => {
              handleIncrement();
            }}
            disabled={detailOrder.total_helper_requested >= 3}
          >
            <AiFillPlusCircle />
          </Button>
        </Col>
      </Row>
    );
  };

  return (
    <>
      {props.disabled ? (
        <Card className="border-0 mb-1 opacity-50">
          <Row className="card-body py-2">
            <Col xs={1} className="align-self-center text-center">
              <Checkbox
                checked={detailOrder.helper_availability_send_selected}
              />
            </Col>
            <Col xs={10} className="align-self-center">
              <p className="fw-bold mb-0">Helper</p>
              <h6 className="mb-0 text-muted">
                Bantu bongkar/muat tanpa batasan jarak dan berat.
              </h6>
            </Col>
            <Col xs={1} className="align-self-center text-center">
              <h6
                className="mb-0"
                // hidden={props.helper_availability_rent === 0}
              >
                <IoChevronDown className="pointer" />
              </h6>
            </Col>
          </Row>
        </Card>
      ) : (
        <Dropdown
          onClick={() => {
            if (show) {
              handleClose();
            } else {
              handleShow();
            }
          }}
          visible={show}
          overlay={dropdownOverlay}
          trigger={["click"]}
        >
          <Card className="border-0 mb-1">
            <Row className="card-body py-2 pointer">
              <Col xs={1} className="align-self-center text-center">
                <Checkbox
                  checked={checked}
                  onClick={onChangeChecklist}
                  // disabled={props.helper_availability_rent === 0}
                  // checked={props.helper_availability_rent_selected}
                  // onClick={() => {
                  //   props.handleChecklist?.();
                  // }}
                />
              </Col>
              <Col xs={10} className="align-self-center ">
                <p className="fw-bold mb-0">Helper</p>
                <h6 className="mb-0 text-muted">
                  Bantu bongkar/muat tanpa batasan jarak dan berat.
                </h6>
              </Col>
              <Col xs={1} className="align-self-center text-center">
                <h6
                  className="mb-0"
                  // hidden={props.helper_availability_rent === 0}
                >
                  <IoChevronDown onClick={handleShow} className="pointer" />
                </h6>
              </Col>
            </Row>
          </Card>
        </Dropdown>
      )}

      {/* <Modal
        contentClassName="modal-100"
        show={show}
        onHide={handleClose}
        className=""
        centered
      >
        <Modal.Header closeButton className="bg-white">
          <strong>Driver Helper</strong>
        </Modal.Header>
      </Modal> */}
    </>
  );
}

export default PickHelper;
