import { BaseApiRequest } from "../base-api";
import { message } from "antd";
import axios from "axios";
import qs from "query-string";

export default class DocumentPayment extends BaseApiRequest {
  constructor() {
    super(process.env.REACT_APP_API_END_POINT);
  }

  async getBillingTransaction(token, query) {
    let result;
    const stringified = await qs.stringify(query, { arrayFormat: "bracket" });
    try {
      let resApi = await axios.get(`${this.endpoint}/customer/billing?${stringified}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      result = resApi.data;
    } catch (e) {
      console.log(e.response);
      message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async getPaymentList(token) {
    let result;
    try {
      let resApi = await axios.get(`${this.endpoint}/customer/billing/payment/list`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      result = resApi.data;
    } catch (e) {
      console.log(e.response);
      message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async getBillingTransactionById(token, id) {
    let result;
    try {
      let resApi = await axios.get(`${this.endpoint}/customer/billing/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      result = resApi.data;
    } catch (e) {
      console.log(e.response);
      message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }
  
  async patchConfirmBillingById(token, id) {
    console.log(token)
    console.log(id)
    let result;
    try {
      let resApi = await axios.patch(`${this.endpoint}/customer/billing/${id}/confirm`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      });

      result = resApi.data;
    } catch (e) {
      console.log(e.response);
      message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async patchFinishCheckBillingById(token, id) {
    let result;
    try {
      let resApi = await axios.patch(`${this.endpoint}/customer/billing/${id}/finish-check`, 
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      });

      result = resApi.data;
    } catch (e) {
      console.log(e.response);
      message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async patchFinishPaymentBillingById(token, id, payload) {
    let result;
    try {
      let resApi = await axios.patch(`${this.endpoint}/customer/billing/${id}/finish-payment`, 
      payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      });

      result = resApi.data;
    } catch (e) {
      console.log(e.response);
      message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  
  }

  async addBulkDocumentAndData(token, id, payload) {
    let result;
    try {
      let resApi = await axios.post(`${this.endpoint}/customer/billing/order-billing/${id}/data`, 
      payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      });

      result = resApi.data;
    } catch (e) {
      console.log(e.response);
      message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async updateDocumentById(token, id, payload) {
    let result;
    try {
      let resApi = await axios.put(`${this.endpoint}/customer/billing/order-billing/document/${id}`, 
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      result = resApi.data;
    
    } catch (e) {
      console.log(e.response);
      message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async updateAdditionalDataById(token, id, payload) {
    let result;
    try {
      let resApi = await axios.put(`${this.endpoint}/customer/billing/order-billing/additional-data/${id}`, 
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      result = resApi.data;
    
    } catch (e) {
      console.log(e.response);
      message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }
  async deleteDocumentById(token, id) {
    let result;
    try {
      let resApi = await axios.delete(`${this.endpoint}/customer/billing/order-billing/document/${id}`, 
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      result = resApi.data;
    
    } catch (e) {
      console.log(e.response);
      message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async deleteAdditionalDataById(token, id) {
    let result;
    try {
      let resApi = await axios.delete(`${this.endpoint}/customer/billing/order-billing/additional-data/${id}`, 
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      result = resApi.data;
    
    } catch (e) {
      console.log(e.response);
      message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async patchBulkRejectDocument(token, id, payload) {
    let result;
    try {
      let resApi = await axios.patch(`${this.endpoint}/customer/billing/order-billing/${id}/document/bulk-reject`, 
        payload,    
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      result = resApi.data;
    
    } catch (e) {
      console.log(e.response);
      message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }
  
  async patchBulkAppropriateDocument(token, id, payload) {
    let result;
    try {
      let resApi = await axios.patch(`${this.endpoint}/customer/billing/order-billing/${id}/document/bulk-confirm`, 
      payload,  
      {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      result = resApi.data;
    
    } catch (e) {
      console.log(e.response);
      message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async patchResetDocument(token, id) {
    let result;
    try {
      let resApi = await axios.patch(`${this.endpoint}/customer/billing/order-billing/document/${id}/reset-status`,
      {},
      {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      result = resApi.data;
    
    } catch (e) {
      console.log(e.response);
      message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async patchReceiptPaymentBill(token, id, payload) {
    let result;
    try {
      let resApi = await axios.patch(`${this.endpoint}/customer/billing/${id}/receipt`,
      payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
      );
      result = resApi.data;
    
    } catch (e) {
      console.log(e.response);
      message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async getCountNotifPengecekan(token) {
    let result;
    try {
      let resApi = await axios.get(`${this.endpoint}/customer/billing/count/need-check`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
      );
      result = resApi.data;
    
    } catch (e) {
      console.log(e.response);
      message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async getCountNotifPembayaran(token) {
    let result;
    try {
      let resApi = await axios.get(`${this.endpoint}/customer/billing/count/need-process`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
      );
      result = resApi.data;
    
    } catch (e) {
      console.log(e.response);
      message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }
}
