import React, { useEffect, useState } from "react";
import { Pagination, Table } from "antd";
import { Col, Dropdown, Row } from "react-bootstrap";
import PropTypes from "prop-types";

const CustomTable = (props) => {
  /*======================== Props ======================== */

  const {
    classProps,
    tableProps,
    tableColumns,
    data,
    limit: initLimit,
    page: initPage,
    loading,
    handleChange,
    total_data,
  } = props;

  /*======================== UseState ======================== */

  const [limit, setLimit] = useState(initLimit || 10);
  const [page, setPage] = useState(initPage || 1);
  const [dataSource, setDataSource] = useState([]);

  /*======================== Handler ======================== */

  const limitOpts = [10, 20, 30, 50];

  const handlePage = (value) => {
    handleChange("page", value);
    setPage(value);
  };

  const handleLimit = (value) => {
    handleChange("limit", value);
    setLimit(value);
    setPage(1);
  };

  const handlePageOf = () => {
    const totalData = total_data || data.length;
    const start = limit * (page - 1) + 1;
    let end = totalData;
    if (limit <= totalData) {
      if (limit * page <= totalData) {
        end = limit * page;
      }
    }
    return `${start} - ${end} of ${totalData}`;
  };

  /*======================== UseEffect ======================== */

  useEffect(() => {
    const dataTemp = data.map((item, index) => ({
      ...item,
      no: (page - 1) * limit + index + 1,
      key: (page - 1) * limit + index + 1,
    }));
    setDataSource(dataTemp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  /*======================== Return ======================== */

  return (
    <>
      <Table
        className={`custom-table ${classProps ? classProps : ""}`}
        footer={undefined}
        pagination={false}
        {...tableProps}
        {...{ loading, dataSource }}
        size={"middle"}
        tableLayout={"auto"}
        columns={tableColumns}
      />
      <div>
        <Row className="custom-footer mt-3 align-items-center">
          <Col>
            <div className="d-flex align-items-center">
              <p className="mb-0 label">Show: </p>
              <Dropdown className="d-inline mx-2">
                <Dropdown.Toggle
                  id="dropdown-autoclose-true"
                  size="sm"
                  variant="light"
                  className="bg-white text-secondary px-3 border shadow-sm rounded"
                >
                  <strong>{limit}</strong>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {limitOpts.map((item) => (
                    <Dropdown.Item
                      key={item}
                      onClick={() => {
                        handleLimit(item);
                      }}
                    >
                      {item}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Col>
          <Col sm={6} className="d-flex justify-content-center">
            <Pagination
              showSizeChanger={false}
              disabled={loading}
              showLessItems
              onChange={handlePage}
              current={page}
              pageSize={limit}
              total={total_data || data.length}
            />
          </Col>
          <Col>
            <p className="mb-0 label float-end">{handlePageOf()}</p>
          </Col>
        </Row>
      </div>
    </>
  );
};

CustomTable.propTypes = {
  tableProps: PropTypes.shape({
    bordered: PropTypes.bool,
    expandable: PropTypes.bool,
    title: PropTypes.string,
    showHeader: PropTypes.bool,
    rowSelection: PropTypes.bool,
  }),
  tableColumns: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.array.isRequired,
  limit: PropTypes.number,
  total_data: PropTypes.number,
  loading: PropTypes.bool,
  handleChange: PropTypes.func,
  classProps: PropTypes.string,
};

export default CustomTable;
