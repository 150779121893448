import {
  Modal,
  Button
} from "react-bootstrap";
import { ReactComponent as ICSuccessPayment } from '../../../assets/images/success_payment.svg';

const SuccessPayment = (props) => {
  const {show, onHide} = props

  return (
    <Modal
      show={show}
      onHide={() => onHide(!show)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='rounded-pill'
    >
      <Modal.Body>
        <div className="d-flex 
          flex-column 
          justify-content-center 
          align-items-center 
          p-4 pb-0"
        >
          <ICSuccessPayment className="mb-3" />
          <div className="w-75">
            <h2 className="font-weight-bold">
              Pembayaran Kamu Berhasil!
            </h2>
            <p className="text-center text-muted mb-0">
              Terima kasih sudah mempercayakan pesanan anda dengan eLOG.
            </p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex flex-column">
      <Button
          className="mt-3 rounded-pill mx-auto btn-block w-50"
          onClick={() => onHide()}
        >
          Selesai
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default SuccessPayment;