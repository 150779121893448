import React, { useState, useEffect } from "react";
import {
  Modal,
  Button
} from "react-bootstrap";
import { BiEdit } from "react-icons/bi";
import ConfirmDeleteDocument from "./ConfirmDeleteDocument"
import Info from "./../../../../../components/info"

const ChangeRequestDocument = ({title="Dokumen", onSubmit, data}) => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [inputState, setInputState] = useState('')
  const [modalDelete, setModalDelete] = useState(false)

  const handleSubmit = (param) => {
    onSubmit(data.id, {name: inputState}, param)
    handleClose()
  }

  useEffect(()=> {
    setInputState(data?.name)
  },[data])

  return(
    <>
      <BiEdit onClick={handleShow} className="pointer" style={{color:"#FF1D8C", fontSize:`${title === "Data" && "25px"}`, marginTop:"-5px"}}/>  
      <Modal
        contentClassName="rounded-card p-2"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton className="bg-white">
          <strong>Ubah Request {title}</strong>
        </Modal.Header>
        <div className="p-4">
            <div className="px-2 mb-4">
                <Info info={`Dokumen atau data yang anda ubah akan langsung terganti ke partner anda`}/>
            </div>
            <div className="px-2 mb-4">
                <h5 className="fw-bold">Nama {title}</h5>
                <div className="border rounded d-flex justify-content-between overflow-hidden">
                    <input type="text" value={inputState} placeholder={`Tulis Nama ${title}`} onChange={(e)=> setInputState(e.target.value)} className="border-0 py-1 px-3 w-100 focus-ring-0" />
                </div>
            </div>
        </div>
        <Modal.Footer>
            <div className="w-100 d-flex justify-content-between align-items-center">
            <h5
              size="sm"
              className="py-1 h-25 text-primary underline pointer"
                  onClick={()=>{handleClose(); setModalDelete(true)}}
              >
              Hapus {title}
            </h5>
            <Button
                size="sm"
                className="fw-bold py-1 h-25 px-4 rounded-pill text-capitalize"
                onClick={()=>handleSubmit('update')}
                >
                Submit Ubah
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <ConfirmDeleteDocument title={title} show={modalDelete} setShow={setModalDelete} onSubmitDelete={handleSubmit} />
    </>
    )
  }

export default ChangeRequestDocument;
