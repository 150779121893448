import React, { useState } from "react";
import {
    Modal,
    ModalFooter,
    Button
} from "react-bootstrap";
import { FaDownload, FaUpload } from "react-icons/fa"
import { message } from "antd";
import Info from "./../../../../../components/info"


function UploadBulk({
    show,
    handleClose,
    submitDownload,
    submit
}) {

    const [inputState, setInputState] = useState(null)

    const onSelectFile = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            message.error("Terjadi kesalahan. Silahkan upload kembali");
        } else if (e.target.files[0].size > 20000000) {
            message.error("Ukuran Maksimal foto adalah 20Mb");
        } else if (e.target.files[0].name.split('.').pop() !== "csv") {
            message.error("Extension File harus .CSV");
        } else {
            setInputState(e.target.files[0])
        }
    };

    const handleSubmitUpload = async () => {
        const fd = new FormData()
        fd.append("file",inputState)
        submit(fd)
        setTimeout(()=>{
            setInputState(null)
        },1000)
    }


  return<>  
        <Modal
            contentClassName="rounded-card p-2"
            show={show}
            onHide={handleClose}
            centered
        >
            <Modal.Header closeButton className="bg-white">
                <strong>Upload Rute Anda</strong>
            </Modal.Header>

            <div className="py-3 px-4">
                <h6 className="text-muted">Anda dapat menambahkan rute dengan data rute yang anda punya. Anda disarankan menyiapkan data sesuai dengan kebutuhan eLOG, tetapi anda tetap dapat merubah atau melakukan pelengkapan data setelah melakukan upload.</h6>
                <div className="border-bottom my-3"></div>
                <h6 className="text-muted mb-3">Beberapa data yang dibutuhkan eLOG untuk menambahkan rute adalah:</h6>
                <div className="mb-1">
                    <h6 className="fw-bold mb-0">1. Kode Rute</h6>
                    <h6 className="text-muted ms-3">Berupa kode unik untuk setiap rute yang dibuat. Pastikan kode hanya untuk 1 rute saja.</h6>
                </div>
                <div className="mb-1">
                    <h6 className="fw-bold mb-0">2. Nama Rute</h6>
                    <h6 className="text-muted ms-3">Untuk mempermudah pencarian data, nama rute diperlukan dan tidak diperbolehkan memiliki nama rute yang sama.</h6>
                </div>
                <div className="mb-1">
                    <h6 className="fw-bold mb-0">3. Alamat Pengambilan</h6>
                    <h6 className="text-muted ms-3">Adalah alamat yang ditujukan untuk mengambil barang yang akan dikirimkan.</h6>
                </div>
                <div className="mb-1">
                    <h6 className="fw-bold mb-0">4. Alamat Pengiriman</h6>
                    <h6 className="text-muted ms-3">Adalah alamat yang ditujukan untuk mengantarkan barang atau alamat tujuan.</h6>
                </div>
                <Info info="File yang dapat diupload ke eLOG berupa <strong>.csv</strong> dan maksimal file berukuran <strong>20mb</strong>.."/>
                {/* <div className="p-2 rounded mt-1 mb-3" style={{background:"#EAF4F6"}}>
                    <h6 className="text-muted m-0" style={{fontSize:"10px"}}>File yang dapat diupload ke eLOG berupa <strong>.csv</strong> dan maksimal file berukuran <strong>20mb</strong>..</h6>                    
                </div> */}
                <div className="mb-3  border-top pt-3">
                    <h6 className="text-muted">Silahkan download template untuk data Rute anda:</h6>
                    <div className="d-flex">
                        <div
                            className="d-flex py-1 px-3 justify-content-center align-items-center pointer bg-primary text-white"
                            style={{borderRadius:"10px"}}
                            onClick={()=> submitDownload()}
                        >
                            <FaDownload className="pointer fw-bold"/>
                            <h6 className="ms-2 text-white me-1 mt-2 pointer">Download Template</h6>
                        </div>
                    </div>
                </div>
                <div className="">
                    <h6 className="fw-bold mb-2">Lampirkan File Upload</h6>
                    <div className="d-flex ms-3">
                        <label className="d-flex justify-content-center align-items-center pointer">
                            <FaUpload className="pointer fw-bold"/>
                            {inputState ?
                                <h6 className="fw-bold ms-2 me-1 mt-2">{inputState.name}</h6> :
                                <h6 className="text-muted ms-2 me-1 mt-2 pointer">Pilih File</h6>
                            }
                            <input multiple type="file"style={{ visibility: "hidden", width:"50px" }} onChange={(e)=>onSelectFile(e)} ></input>
                        </label>
                    </div>
                </div>
            </div>
            <ModalFooter className="bg-white">
                <Button
                    disabled={!inputState ? true : false}
                    size="sm"
                    className="py-1 px-3 rounded-pill text-capitalize"
                    onClick={()=> handleSubmitUpload()}
                >
                    Upload Data Rute
                </Button>
            </ModalFooter>
        </Modal>
    </>;
}

export default UploadBulk;
