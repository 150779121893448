import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Modal,
  Form,
  Row,
  Col,
  InputGroup,
  FloatingLabel,
} from "react-bootstrap";

import useBreakpoint from "../../../../components/responsiveUtils/useBreakpoint";
import { FaSearch } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import PropTypes from "prop-types";
import "../style.scss";
import { message } from "antd";

import iconDocs from "../../../../assets/images/icon-docs.svg";
import ProcurementApi from "../../../../api/Management/procurement-api";

const UploadDocument = (props) => {
  /*======================== Hooks ======================== */

  const { isMobile } = useBreakpoint();

  /*======================== Props ======================== */

  const { item, index, updateDocument, addDocument, deleteDocument } = props;

  /*======================== UseState ===================== */

  /*======================== Handler ====================== */

  const handleUploadFile = async (ev) => {
    let document = ev.target.files[0];
    try {
      const procurementApi = new ProcurementApi();
      const fd = new FormData();
      fd.append("file", document);

      const uploadFiles = await procurementApi.uploadDocument(fd);

      if (uploadFiles) {
        setDocumentValue(uploadFiles.Location, document.name);
      }
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleAddDocument = () => {
    if (item.document_name === "" || item.document_path === "") {
      message.error("Nama dan File Dokumen harus diisi");
    } else {
      addDocument();
    }
  };

  const onChangeName = (e) => {
    item.document_name = e.target.value;
    handleUpdateValue();
  };

  const setDocumentValue = (value, name) => {
    item.document_path = value;
    item.document_file_name = name;
    handleUpdateValue();
  };

  const handleDeleteDocument = () => {
    deleteDocument(item);
  };

  const handleUpdateValue = () => {
    let value = {
      index: index,
      value: {
        ...item,
      },
    };
    updateDocument(value);
  };

  /*======================== UseEffect =====================*/

  useEffect(() => {}, []);

  /*======================== Return ======================== */

  return (
    <div
      style={{
        border: "1px solid #c0c0c0",
        borderRadius: "10px",
        marginBottom: "12px",
      }}
    >
      <InputGroup className="mt-0">
        <InputGroup.Text className="border-0 mt-2">
          <img src={iconDocs} className="icon-form" alt="" />
        </InputGroup.Text>
        <FloatingLabel
          label="Nama Dokumen"
          style={{
            width: isMobile ? "88.5%" : "92.2%",
            fontSize: "12px",
            height: "35px",
          }}
        >
          <Form.Control
            className="border-0 py-0"
            type="text"
            placeholder="Tulis Nama Dokumen"
            value={item.document_name}
            onChange={(e) => {
              onChangeName(e);
            }}
          />
        </FloatingLabel>
      </InputGroup>

      <hr className="mb-1"></hr>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "12px 0",
        }}
      >
        <img src={iconDocs} style={{ marginLeft: "12px" }} alt="" />
        <div style={{ width: "90%" }}>
          {item.document_path ? (
            <>
              <label
                style={{
                  color: "#CE1771",
                  fontSize: "13px",
                  cursor: "pointer",
                }}
              >
                {item.document_file_name}
              </label>
              <label
                htmlFor={"formFile" + item.id}
                className="underline float-end me-2"
                style={{
                  color: "#CE1771",
                  fontSize: "13px",
                  cursor: "pointer",
                }}
              >
                Ubah Dokumen
              </label>
              <input
                hidden
                type="file"
                className="form-control"
                id={"formFile" + item.id}
                accept=".pdf, .xls, .xlsx, .doc, .docx"
                onChange={handleUploadFile}
              />
            </>
          ) : (
            <>
              <label
                htmlFor={"formFile"}
                className="underline"
                style={{
                  color: "#CE1771",
                  fontSize: "13px",
                  cursor: "pointer",
                }}
              >
                Upload Dokumen
              </label>
              <input
                hidden
                type="file"
                className="form-control"
                id={"formFile"}
                accept=".pdf, .xls, .xlsx, .doc, .docx"
                onChange={handleUploadFile}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

UploadDocument.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  updateDocument: PropTypes.func,
  addDocument: PropTypes.func,
  deleteDocument: PropTypes.func,
};

export default UploadDocument;
