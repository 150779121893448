import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Button, Tabs, Tab } from "react-bootstrap";
import { Dropdown, Spin, Steps, Divider } from "antd";
import GMap from "../../components/gMap/SimpleMap";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import {
  getStatusDesc,
  getStatusDescDetail,
} from "../../helper/trx_status_helper";
import StatusOrderModal from "../../components/modalTrxList/StatusOrderModal";
import PartnerDetailModal from "../../components/modalTrxList/PartnerDetailModal";
import RouteDetailModal from "../../components/modalInput/RouteDetailModal";
import CancelOrderModal from "../../components/modalTrxList/CancelOrderModal";
import ContactModal from "../../components/modalTrxList/ContactModal";
import ClaimInsurance from "../../components/modalTrxList/ClaimInsurance";
import util from "../../helper/util";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import SendInvoice from "../../components/print/SendInvoice";
import { socket } from "../../services/socket";
import ShareTrackingLinkModal from "../../components/modalTrxList/ShareTrackingLinkModal";
import useBreakpoint from "../../components/responsiveUtils/useBreakpoint";

// sections
import SendSection from "./SendSection";
import DokumenKendala from "./DokumenKendala";

// global state
import { setTrxState } from "../../redux/action/trx_history_action";

// api
import TrxListApi from "../../api/trx_list_api";

// icons
import {
  BsArrowLeft,
  BsThreeDotsVertical,
  BsArrowRight,
  BsFillCircleFill,
} from "react-icons/bs";
import { MdLocationPin } from "react-icons/md";
import { FaMapMarkedAlt, FaShareAlt } from "react-icons/fa";

import icon_send_sch from "../../assets/images/icon_send_sch.svg";
import icon_send_ins from "../../assets/images/icon_send_ins.svg";
import { hideSidenavMobile } from "../../components/layouts/layout_helper";

const DetailTransaksi = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const auth = useSelector((st) => st.authReducer);
  const trxDetail = useSelector((st) => st.trxHistoryReducer.trxDetail);
  const { isMobile, isDesktop, isTablet } = useBreakpoint();
  const [showMapTabletAndMobile, setShowMapTabletAndMobile] = useState(false);
  const [showDetailHistoryLogMobile, setShowDetailHistoryLogMobile] =
    useState(false);
  const [driverLocation, setDriverLocation] = useState({});

  const [showDropdown, setShowDropdown] = useState(false);
  const [showComplaint, setShowComplaint] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [showRouteDetailModal, setShowRouteDetailModal] = useState(false);
  const [showPartnerDetailModal, setShowPartnerDetailModal] = useState(false);
  const [showOrderStatusModal, setShowOrderStatusModal] = useState(false);
  const [showCancelOrderModal, setShowCancelOrderModal] = useState(false);
  const [showClaimInsurance, setShowClaimInsurance] = useState(false);
  const [showShareTrackingLinkModal, setShowShareTrackingLinkModal] =
    useState(false);

  const [eventKey, setEventKey] = useState("1");

  const handleChangeTab = (key) => {
    setEventKey(key);
  };

  // const {
  //   state: { type },
  // } = location;
  const getOrderDetail = async () => {
    try {
      dispatch(
        setTrxState(
          { trxDetail: { loading: true, data: {} } },
          auth.refresh_token
        )
      );

      let api = new TrxListApi();
      let data;
      let { data: orderDetail } = await api.getSendOrderDetail(
        auth.access_token,
        id
      );

      data = { ...orderDetail };

      dispatch(
        setTrxState(
          { trxDetail: { loading: false, data: { ...data } } },
          auth.refresh_token
        )
      );
    } catch (error) {
      dispatch(
        setTrxState(
          { trxDetail: { loading: false, data: {} } },
          auth.refresh_token
        )
      );
    }
  };

  const generateSection = () => {
    const helper = {
      SEND_GOODS: (
        <SendSection
          setShowDetailHistoryLogMobile={setShowDetailHistoryLogMobile}
          setShowMapTabletAndMobile={setShowMapTabletAndMobile}
          openRouteDetailModal={openRouteDetailModal}
          openPartnerDetailModal={openPartnerDetailModal}
          openContactModal={openContactModal}
        />
      ),
    };

    return helper["SEND_GOODS"];
  };

  // functions that run with interval time, for calling socket
  const emitDriverPosition = () => {
    if (trxDetail?.data?.id) {
      emitDriverSocket(); // initial

      setInterval(() => {
        emitDriverSocket();
      }, 60000);
    }
  };

  const emitDriverSocket = () => {
    socket.emit(
      "live_tracking_send",
      {
        order_id: trxDetail?.data?.id,
      },
      (payload) => {
        // console.log("live tracking", payload);
        setDriverLocation({
          lat: payload?.data?.mst_driver?.latitude,
          lng: payload?.data?.mst_driver?.longitude,
        });
      }
    );
  };

  useEffect(() => {
    getOrderDetail();
  }, []);

  useEffect(() => {
    emitDriverPosition();
  }, [trxDetail?.data?.id]);

  useEffect(() => {
    if (isMobile) hideSidenavMobile();
  }, [isMobile]);

  const openDropdown = () => {
    setShowDropdown(true);
  };

  const closeDropdown = () => {
    setShowDropdown(false);
  };

  const openContactModal = () => {
    setShowContactModal(true);
  };

  const closeContactModal = () => {
    setShowContactModal(false);
  };

  const openRouteDetailModal = () => {
    setShowRouteDetailModal(true);
  };

  const closeRouteDetailModal = () => {
    setShowRouteDetailModal(false);
  };

  const openPartnerDetailModal = () => {
    setShowPartnerDetailModal(true);
  };

  const closePartnerDetailModal = () => {
    setShowPartnerDetailModal(false);
  };

  const openOrderStatusModal = () => {
    setShowOrderStatusModal(true);
  };

  const closeOrderStatusModal = () => {
    setShowOrderStatusModal(false);
  };

  const openCancelOrderModal = () => {
    setShowCancelOrderModal(true);
  };

  const closeCancelOrderModal = () => {
    setShowCancelOrderModal(false);
  };

  const openClaimInsurance = () => {
    setShowClaimInsurance(true);
  };

  const closeClaimInsurance = () => {
    setShowClaimInsurance(false);
  };

  const openShareTrackingLinkModal = () => {
    setShowShareTrackingLinkModal(true);
  };

  const closeShareTrackingLinkModal = () => {
    setShowShareTrackingLinkModal(false);
  };

  const getIcon = () => {
    if (trxDetail?.data?.type_order === "SEND_GOODS") {
      if (trxDetail?.data?.trx_booking_send?.send_category === "SCHEDULED") {
        return icon_send_sch;
      }
      if (trxDetail?.data?.trx_booking_send?.send_category === "INSTANT") {
        return icon_send_ins;
      }
    }

    return null;
  };

  const goBackPage = () => {
    history.goBack();
  };

  const componentRef = useRef();

  const dropdownOverlay = () => {
    return (
      <div className="bg-white drpdwn-menu">
        {/* <div
          className="p-2 pointer drpdwn-item"
          onClick={openCancelOrderModal}
          hidden={isTrxDone()}
        >
          <p>Ajukan Pembatalan</p>
        </div> */}
        <div className="p-2 pointer drpdwn-item" hidden={isTrxDone()}>
          <p>Ajukan Keluhan</p>
        </div>
        <div className="p-2 pointer drpdwn-item">
          <p>Bantuan CS eLOG</p>
        </div>
        <div className="p-2 pointer drpdwn-item" hidden={!isTrxDone()}>
          <ReactToPrint
            trigger={() => {
              return <a href="#">Download Invoice</a>;
            }}
            content={() => componentRef.current}
          ></ReactToPrint>
        </div>
      </div>
    );
  };

  const complaint = () => {
    return (
      <div className="bg-white drpdwn-menu w-100">
        <div
          onClick={openClaimInsurance}
          className="p-2 pointer drpdwn-item w-100"
          hidden={!trxDetail?.data?.trx_booking_send?.insurance_policy_file}
        >
          <p>Ajukan Klaim Asuransi</p>
        </div>
        <div className="p-2 pointer drpdwn-item w-100">
          <p>Ajukan Keluhan Transaksi</p>
        </div>
      </div>
    );
  };

  const getLastLog = () => {
    const arr = trxDetail?.data?.log_booking_status;
    return arr?.[arr?.length - 1];
  };

  if (trxDetail?.loading) {
    return (
      <div
        style={{ minHeight: "100vh", width: "100%", padding: "30px" }}
        className="d-flex align-items-center justify-content-center bg-ice"
      >
        <Spin />
      </div>
    );
  }

  const isTrxDone = () => {
    if (
      trxDetail?.data?.trx_booking_send?.status === "CANCELLED" ||
      trxDetail?.data?.trx_booking_send?.status === "FINISHED"
    ) {
      return true;
    }

    return false;
  };

  return (
    <>
      <SendInvoice ref={componentRef} />
      <div
        style={{
          minHeight: "100vh",
          width: "100%",
          padding: "30px",
        }}
        className="bg-ice"
      >
        {/* {JSON.stringify(driverLocation)} */}

        <div
          className="d-flex gap-2 pointer"
          onClick={() => {
            if (showMapTabletAndMobile) setShowMapTabletAndMobile(false);
            else if (showDetailHistoryLogMobile)
              setShowDetailHistoryLogMobile(false);
            else goBackPage();
          }}
        >
          <BsArrowLeft />
          <h4 className="fw-bold">
            {showMapTabletAndMobile || showDetailHistoryLogMobile
              ? "Detail Pesanan"
              : "Daftar Transaksi"}
          </h4>
        </div>
        <Row className="card-map-right" style={{ position: "relative" }}>
          <Col
            xs={12}
            md={12}
            lg={7}
            className="scrollable"
            style={{ height: "90vh" }}
            hidden={
              showMapTabletAndMobile || showDetailHistoryLogMobile
                ? true
                : false
            }
          >
            <Row
              className="d-flex align-items-center"
              style={{ backgroundColor: "#fff", borderRadius: "20px" }}
            >
              <Col className="d-flex align-items-center">
                <img src={getIcon()} className="i-sidebar" alt="" />
                <Col className="ms-2 mt-4">
                  <div className="d-flex align-items-center">
                    <h3 className="fw-bold me-2">
                      {trxDetail?.data?.type_order === "RENT"
                        ? "Sewa Kendaraan"
                        : "Kirim Barang"}
                    </h3>
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <h5 className="">
                      {moment(trxDetail?.data?.created_at).format(
                        "DD MMM YYYY"
                      )}
                    </h5>
                    <h5> &bull; </h5>
                    <h5>
                      {moment(trxDetail?.data?.created_at).format("HH : mm")}
                    </h5>
                  </div>
                </Col>
              </Col>
              <Col
                className={`${
                  isMobile ? "text-start" : "text-end"
                }  d-flex align-items-center`}
              >
                <Col className={`${!isMobile ? "mt-4" : "ms-2"} `}>
                  <div className="" hidden={isMobile}>
                    <h5 className="me-2">ID Transaksi</h5>
                  </div>
                  <div className="">
                    <h3 className="text-primary fw-bold">
                      {trxDetail?.data?.order_number}
                    </h3>
                  </div>
                </Col>
                <Dropdown
                  onClick={() => {
                    if (showDropdown) {
                      closeDropdown();
                    } else {
                      openDropdown();
                    }
                  }}
                  overlay={dropdownOverlay}
                  trigger={["click"]}
                  visible={showDropdown}
                  placement="bottomRight"
                >
                  <Button
                    variant="outline-primary"
                    className="ms-2"
                    style={{ marginTop: isMobile ? "-7.6rem" : undefined }}
                  >
                    <BsThreeDotsVertical />
                  </Button>
                </Dropdown>
              </Col>
              <Row>
                <Col>
                  <Tabs
                    defaultActiveKey={eventKey}
                    className="tabs-trx border-bottom-2"
                    onSelect={(e) => handleChangeTab(e)}
                  >
                    <Tab eventKey="1" title="Detail Pesanan"></Tab>
                    <Tab eventKey="2" title="Dokumen & Kendala"></Tab>
                  </Tabs>
                </Col>
              </Row>
            </Row>

            {/* Detail Pesanan */}
            {eventKey === "1" ? (
              <>
                <Row hidden={!isTrxDone()}>
                  <Col>
                    <Dropdown
                      onClick={() => {
                        if (showComplaint) {
                          setShowComplaint(false);
                        } else {
                          setShowComplaint(true);
                        }
                      }}
                      overlay={complaint}
                      trigger={["click"]}
                      visible={showComplaint}
                      placement="bottomRight"
                    >
                      <Button className="w-100" variant="outline-primary">
                        Ajukan Keluhan
                      </Button>
                    </Dropdown>
                  </Col>
                  <Col>
                    <Button
                      className="w-100"
                      onClick={() => {
                        history.push("/kirim-barang");
                      }}
                    >
                      Order Lagi
                    </Button>
                  </Col>
                </Row>
                <SendSection
                  setShowDetailHistoryLogMobile={setShowDetailHistoryLogMobile}
                  setShowMapTabletAndMobile={setShowMapTabletAndMobile}
                  openRouteDetailModal={openRouteDetailModal}
                  openPartnerDetailModal={openPartnerDetailModal}
                  openContactModal={openContactModal}
                  getOrderDetail={getOrderDetail}
                />
              </>
            ): (
              <Row>
                <DokumenKendala />
              </Row>
            )}
          </Col>
          <Col
            className="rounded-card card-map-right bg-white ms-4 shadow-md"
            style={{ minHeight: "90vh" }}
            hidden={
              showDetailHistoryLogMobile
                ? false
                : !isDesktop
                ? true
                : !isTrxDone()
                ? true
                : false
            }
          >
            <Row className="p-2 py-4 me-4">
              {trxDetail?.data?.log_booking_status?.length > 0 &&
                [...trxDetail?.data?.log_booking_status]
                  ?.reverse()
                  ?.map((el, i) => (
                    <>
                      <Col xs={3}>
                        <h6 className="float-end">
                          {moment(el?.crated_at).format("DD MMM YYYY")}
                        </h6>
                        <h6 className="float-end fw-bold">
                          {moment(el?.created_at).format("HH : mm")} WIB
                        </h6>
                      </Col>
                      <Col xs={9}>
                        <Steps
                          progressDot
                          status={i == 0 ? "process" : "wait"}
                          direction="vertical"
                        >
                          <Steps.Step
                            title={getStatusDesc(el?.order_status)}
                            description={
                              <>
                                <p>{getStatusDescDetail(el?.order_status)}</p>
                                {(el?.order_status ===
                                  "GO_TO_LOCATION_PICKUP" ||
                                  el?.order_status ===
                                    "GO_TO_RETURN_LOCATION") && (
                                  <>
                                    <p style={{ marginTop: "-10px" }}>
                                      Nama : {trxDetail?.data?.mst_driver?.name}
                                    </p>
                                    <p style={{ marginTop: "-10px" }}>
                                      Nomor Ponsel : +
                                      {trxDetail?.data?.mst_driver?.wa_number}
                                    </p>
                                  </>
                                )}
                              </>
                            }
                          />
                          {i <
                            trxDetail?.data?.log_booking_status.length - 1 && (
                            <Divider />
                          )}
                        </Steps>
                      </Col>
                    </>
                  ))}
            </Row>
            {/* End of Detail Pesanan */}
          </Col>

          <Col
            className="rounded-card card-map-right"
            style={{ height: "90vh" }}
            hidden={
              showMapTabletAndMobile
                ? false
                : !isDesktop
                ? true
                : isTrxDone()
                ? true
                : false
            }
          >
            {/* {console.log("from componet", driverLocation)} */}
            <GMap hideControlButton driverLocation={driverLocation} />
            <div className="card-map-item-top " style={{ width: "93%" }}>
              <div
                className={`d-flex ${
                  isMobile && "flex-column"
                } align-items-center position-absolute gap-2 mt-2`}
                style={{ right: 0 }}
              >
                <a
                  rel="noreferrer"
                  target="_blank"
                  href={`${process.env.REACT_APP_WEBSITE_URL}/tracking/${trxDetail?.data?.id}`}
                >
                  <Button size="sm">
                    <FaMapMarkedAlt />
                  </Button>
                </a>
                <Button size="sm" onClick={openShareTrackingLinkModal}>
                  <FaShareAlt />
                </Button>
              </div>
              <div
                className={`bg-white rounded-card px-4 p-3 ms-2 shadow-md d-flex ${
                  isMobile ? "flex-column" : "align-items-center"
                }  gap-2 justify-content-center`}
                style={{ width: "80%", opacity: "0.9" }}
              >
                <>
                  <div className="d-flex">
                    <MdLocationPin className="text-secondary mb-2" />
                    <h6>1 Pickup</h6>
                    <h6>&bull;</h6>
                    <MdLocationPin className="text-primary mb-2" />
                    <h6>
                      {" "}
                      {trxDetail?.data?.trx_booking_send
                        ?.trx_booking_send_destination.length - 1}{" "}
                      Dropoff
                    </h6>
                  </div>
                  {/* <h6>&bull;</h6> */}
                </>
                <div className="d-flex">
                  <h6>
                    Total Jarak {trxDetail?.data?.trx_booking_send?.total_km} Km
                  </h6>
                  <h6>
                    {" "}
                    ETA :{" "}
                    {util.etaConvertion(
                      trxDetail?.data?.trx_booking_send?.total_duration
                    )}
                  </h6>
                </div>
              </div>
            </div>
            <div className="card-map-item m-auto" style={{ width: "92%" }}>
              <div
                className="bg-white ms-2 rounded-card p-4 shadow-md"
                style={{ width: "100%" }}
              >
                <Row>
                  <Col xs={3}>
                    <Row>
                      <Col>
                        <h6 className="text-muted">
                          {moment(getLastLog()?.created_at).format(
                            "DD MMM YYYY"
                          )}
                        </h6>
                        <h5>
                          {moment(getLastLog()?.created_at).format("HH : mm")}{" "}
                          WIB
                        </h5>
                      </Col>
                      <Col hidden={isMobile}>
                        <BsFillCircleFill className="text-secondary" />
                        <div
                          className="br-dash"
                          style={{
                            height: "35px",
                            marginLeft: "7px",
                            marginTop: "5px",
                          }}
                        />
                        <BsFillCircleFill style={{ color: "#ced4da" }} />
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <h5 className="fw-bold">
                      {getStatusDesc(getLastLog()?.order_status)}
                    </h5>
                    <h5>{getStatusDescDetail(getLastLog()?.order_status)}</h5>
                    {(getLastLog()?.order_status === "GO_TO_PICKUP_LOCATION" ||
                      getLastLog()?.order_status ===
                        "GO_TO_RETURN_LOCATION") && (
                      <>
                        <p>Nama : {trxDetail?.data?.mst_driver?.name}</p>
                        <p>
                          Nomor Ponsel : +
                          {trxDetail?.data?.mst_driver?.wa_number}
                        </p>
                      </>
                    )}
                    <h5
                      className="text-primary pointer mt-2"
                      onClick={openOrderStatusModal}
                    >
                      Lihat Detail
                      <BsArrowRight style={{ marginLeft: "5px" }} />
                    </h5>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
        <StatusOrderModal
          showOrderStatusModal={showOrderStatusModal}
          closeOrderStatusModal={closeOrderStatusModal}
          log_booking_status={trxDetail?.data?.log_booking_status}
          trxDetail={trxDetail}
        />
        <CancelOrderModal
          showCancelOrderModal={showCancelOrderModal}
          closeCancelOrderModal={closeCancelOrderModal}
        />
        <PartnerDetailModal
          showPartnerDetailModal={showPartnerDetailModal}
          closePartnerDetailModal={closePartnerDetailModal}
        />
        <RouteDetailModal
          showRouteDetailModal={showRouteDetailModal}
          closeRouteDetailModal={closeRouteDetailModal}
          trxDetail={trxDetail}
        />
        <ContactModal
          showContactModal={showContactModal}
          closeContactModal={closeContactModal}
        />
        <ClaimInsurance
          showClaimInsurance={showClaimInsurance}
          closeClaimInsurance={closeClaimInsurance}
        />
        <ShareTrackingLinkModal
          showShareTrackingLinkModal={showShareTrackingLinkModal}
          closeShareTrackingLinkModal={closeShareTrackingLinkModal}
        />
      </div>
    </>
  );
};

export default DetailTransaksi;
