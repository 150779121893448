import { Row, Col, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import moment from "moment/moment";
import RequestDocument from "../Modal/RequestDocument";
import Obrolan from "../Modal/Obrolan";
import ProcurementDokumen from "../Modal/ProcurementDokumen";
import noData from "../../../../assets/images/no-data.png";

import "../../style.scss";
import {
  getDocumentData,
  getLogNegotiationDocument,
  requestNewDoc,
  sendMessage,
} from "../../../../redux/action/Partner/partner_action";
import { useDispatch } from "react-redux";
import { message } from "antd";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const DokumenKontrakDetail = (props) => {
  /*======================== Props ======================== */

  const { data } = props;

  /*======================== Hooks ======================== */
  const dispatch = useDispatch();
  const params = useParams();
  const authData = useSelector((state) => state.authReducer);
  /*======================== State ======================== */
  const [openRequest, setOpenRequest] = useState(false);
  const [openObrolan, setOpenObrolan] = useState(false);
  const [openProcurementDokumen, setOpenProcurementDokumen] = useState(false);
  const [log, setLog] = useState("");
  const [queryParams, setQueryParams] = useState({
    limit: 9999,
    page: 1,
    sort_by: "created_at",
    order_by: "DESC",
  });

  /*======================== Handler ======================== */

  const handleClickDocument = () => {
    if (data.procurementDocumentTotal > 0) {
      setOpenProcurementDokumen(true);
    }
  };

  const handleSendMessage = async (data) => {
    try {
      const payload = {
        ...data,
        delivery_procurement_id: params.procurement,
        partner_id: params.id,
      };
      await dispatch(sendMessage(payload));
      message.success("Berhasil mengirimkan pesan");
      await getLogDispatch();
    } catch (error) {
      message.error("Gagal mengirimkan pesan");
    }
  };

  const handleRequestDoc = async () => {
    try {
      await dispatch(requestNewDoc(params.procurement, params.id));
      message.success("Berhasil membuat permohonan update document");
      setOpenRequest(false);
    } catch (error) {
      message.error("Gagal membuat permhonann update dokumen");
    }
  };

  const renderPartnerDocument = () => {
    let component = [];
    if (data.partnerDocument && data.partnerDocument.length > 0) {
      data.partnerDocument.forEach((item, i) => {
        component.push(
          <div className="bg-white rounded-card w-full p-3 mb-2" key={i}>
            <p className="text-muted mb-0"> File Dokumen</p>
            <Row>
              <Col xs={8}>
                <a
                  className="underline"
                  style={{
                    color: "#CE1771",
                    fontSize: "13px",
                    cursor: "pointer",
                  }}
                  href={item.document_path}
                  target="_blank"
                  rel="noreferrer"
                  // download={data.document_name}
                >
                  {item.document_name}
                </a>
              </Col>
              <Col xs={4}>
                <p className="mb-0" align="right">
                  {moment(item.created_at).format("DD MMM YYYY")}
                </p>
              </Col>
            </Row>
          </div>
        );
      });
    } else {
      return (
        <div className="bg-white rounded-card w-full p-3 mb-2">
          <p className="text-muted mb-0"> File Dokumen</p>
          <p className="text-muted mb-0"> Belum Ada Dokumen</p>
        </div>
      );
    }

    return component;
  };

  const renderHistoryDocument = () => {
    let component = [];
    if (data.updatedPartnerDocument && data.updatedPartnerDocument.length > 0) {
      data.updatedPartnerDocument.forEach((item, i) => {
        component.push(
          <div
            className="border-box bg-white rounded-card w-full p-2 mt-2"
            key={i}
          >
            <p className="text-muted mb-0"> File Dokumen</p>
            <Row>
              <Col xs={8}>
                <p
                  className="underline mb-0"
                  style={{
                    color: "#CE1771",
                    cursor: "pointer",
                  }}
                >
                  {item.document_name}
                </p>
              </Col>
              <Col xs={4}>
                <p className="mb-0" align="right">
                  {moment(item.created_at).format("DD MMM YYYY")}
                </p>
              </Col>
            </Row>
          </div>
        );
      });
    } else {
      return (
        <div className="empty">
          <p className="text-muted"> Tidak Ada perubahan Dokumen</p>
        </div>
      );
    }

    return component;
  };

  const renderLog = () => {
    let component = [];
    if (log && log.length > 0) {
      log.forEach((item, i) => {
        component.push(
          <div
            className="border-box bg-white rounded-card w-full py-2 px-3 mt-2"
            key={i}
          >
            <div
              className="py-1"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <p className="text-muted mb-0">
                {" "}
                {item.created_by === authData.company_name
                  ? "Anda"
                  : item.created_by}
              </p>
              <p className="mb-0">
                {" "}
                {moment(item.created_at).format("DD MMM YYYY")}
              </p>
            </div>
            <hr className="my-2 text-muted" />
            <div>
              <p className="text-muted mb-0"> Deskripsi </p>
              <p className="mb-0"> {item.description}</p>
            </div>
          </div>
        );
      });
    } else {
      return (
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{ marginTop: "5rem" }}
        >
          <img src={noData} alt="no-data" />
          <p className="mt-1">Tidak ad aktivitas update dokumen</p>
        </div>
      );
    }

    return component;
  };

  const getLogDispatch = async () => {
    try {
      const resp = await dispatch(
        getLogNegotiationDocument(queryParams, params.id, params.procurement)
      );
      setLog(resp);
    } catch (error) {
      message.error("Gagal mendapatkan log");
    }
  };

  /*======================== useEffect ======================== */

  useEffect(() => {
    getLogDispatch();
  }, []);

  /*======================== Return ======================== */

  return (
    <Row className="mt-4">
      <Col xs={12} md={6}>
        <h5 className="fw-normal mb-2">Dokumen Kontrak</h5>
        <div className="bg-white rounded-card w-full p-3 mb-3">
          <div>
            <p className="text-muted mb-0"> Dokumen Kontrak</p>
            <p
              className="underline mb-0"
              style={{
                color: "#CE1771",
                cursor: "pointer",
              }}
              onClick={() => handleClickDocument()}
            >
              {data.procurementDocumentTotal} Dokumen Kontrak
            </p>
          </div>
        </div>

        <h5 className="fw-normal mb-2">Dokumen Partner</h5>
        {renderPartnerDocument()}

        <h5 className="fw-normal mb-2">Perubahan Dokumen</h5>
        <div className="bg-white rounded-card w-full p-1">
          <div className="history-document">
            {renderHistoryDocument()}

            <div className="document-footer">
              <Button
                size="sm"
                className="rounded-10 text-capitalize w-100"
                onClick={() => setOpenRequest(true)}
              >
                Request Dokumen Baru
              </Button>
            </div>
          </div>
        </div>
      </Col>
      <Col xs={12} md={6}>
        <h5 className="fw-normal mb-2">Aktivitas Perubahan Dokumen</h5>
        <div className="bg-white rounded-card w-full p-1">
          <div className="changes-activity">
            <div className={"changes-activity-content"}>{renderLog()}</div>
            <div className="changes-activity-footer">
              <Button
                size="sm"
                className="rounded-10 text-capitalize w-25 float-end"
                onClick={() => setOpenObrolan(true)}
              >
                Buat Obrolan
              </Button>
            </div>
          </div>
        </div>
      </Col>

      {openRequest && (
        <RequestDocument
          isOpen={openRequest}
          handleClose={() => setOpenRequest(false)}
          handleRequestDoc={handleRequestDoc}
        />
      )}

      {openObrolan && (
        <Obrolan
          isOpen={openObrolan}
          handleClose={() => setOpenObrolan(false)}
          handleSendMessage={handleSendMessage}
        />
      )}

      {openProcurementDokumen && (
        <ProcurementDokumen
          isOpen={openProcurementDokumen}
          data={data.procurementDocument}
          handleClose={() => setOpenProcurementDokumen(false)}
        />
      )}
    </Row>
  );
};

export default DokumenKontrakDetail;
