import React, { Component, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Card, Row, Col } from "react-bootstrap";
import ModalPromo from "../../components/modalPromo";
import util from "../../helper/util";
import moment from "moment";
import AdditionalData from "./components/AdditionalData"

// images
import iconClock from "../../assets/images/icon-clock.svg";
import iconCircle from "../../assets/images/icon-circle.svg";
import iconMark from "../../assets/images/icon-mark.svg";
import iconService from "../../assets/images/icon-service.svg";
import iconTruck from "../../assets/images/icon-truck.svg";
import icon_pick_up from "../../assets/images/icon_pick_up.svg"
import icon_checked from "../../assets/images/icon_checked_UM.svg";

// icons
import { BsShieldFillCheck } from "react-icons/bs";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
// import { GoPrimitiveDot } from "react-icons/go";

const TabSummary = () => {
  const [summaryOpen, setSummaryOpen] = useState(false);
  const detail_price = useSelector((st) => st.kirimbarangReducer.detail_price);
  const pengambilan = useSelector((st) => st.kirimbarangReducer.pengambilan);
  const pengambilan_new = useSelector((st) => st.kirimbarangReducer.pengambilan_new);
  const pengiriman = useSelector((st) => st.kirimbarangReducer.pengiriman);
  const backToPickupAddress = useSelector(
    (st) => st.kirimbarangReducer.backToPickupAddress
  );
  const selectedVType = useSelector(
    (st) => st.kirimbarangReducer.selectedVType
  );
  const selectedPartnerService = useSelector(
    (st) => st.kirimbarangReducer.selectedPartnerService
  );
  const detailOrder = useSelector((st) => st.kirimbarangReducer.detailOrder);
  const tipe = useSelector((st) => st.kirimbarangReducer.tipe);
  const date = useSelector((st) => st.kirimbarangReducer.date);
  const dateLabel = useSelector((st) => st.kirimbarangReducer.dateLabel);
  const contract = useSelector((st) => st.kirimbarangReducer.contract);

  const toggleSummary = () => {
    setSummaryOpen((prev) => !prev);
  };

  return (
    <>
      <Card.Body className="pt-0">
        <h5 className="fw-light mb-2">Rincian Pengiriman</h5>
        <div className="rc-ice p-2 mb-2 scrollable">
          <Card className="border-0 mb-1">
            <Row className="card-body py-1">
              <Col xs={2} className="align-self-center text-center">
                <img src={iconClock} alt="" className="img-fluid" />
              </Col>
              <Col xs={10} className="align-self-center">
                <p className="fw-bold mb-0">
                  Pengiriman {tipe === "INSTANT" ? "Sekarang" : "Terjadwal"}
                </p>
                <h6 className="mb-0 text-muted">
                  {dateLabel
                    ? dateLabel
                    : `${moment().format("dddd")}, ${moment().format(
                        "DD/MMM/YYYY"
                      )}`}
                </h6>
              </Col>
            </Row>
          </Card>
          <Card className="border-0">
            <Card.Body className="smrry">
            {pengambilan_new.map((eachData,i)=>(
              <>
                <Row className="my-2">
                <Col xs={2}>
                  <img
                    src={icon_pick_up}
                    alt=""
                    className=""
                    style={{ marginLeft: "8px", width: "25px", }}
                  />
                  <span
                    className="mark-number"
                    style={{ marginLeft: "12px", marginTop: "2px" }}
                  >
                  {i + 1}
                  </span>
                  <div
                    className="br-dash mt-2"
                    style={{ height: "35px", marginLeft: "19px" }}
                  />
                </Col>
                <Col xs={10}>
                  <h6 className="text-muted">{eachData.namaPenerima}</h6>
                  <h6 className="text-muted">+62{eachData.nomorTelepon}</h6>
                  <h6 className="text-muted">{eachData.lokasi}</h6>
                </Col>
              </Row>
              </>
            ))}
              
              <Row className="">
                <Col xs={2} className="text-center align-self-center">
                  {/* <GoPrimitiveDot /> */}
                </Col>
                <Col
                  xs={10}
                  className="align-self-center pointer"
                  onClick={toggleSummary}
                >
                  <h6 className="text-muted">
                    {summaryOpen ? (
                      <>
                        Sembunyikan <IoChevronUp />
                      </>
                    ) : pengiriman.length > 1 ? (
                      <>
                        {pengiriman.length - 1} Perhentian <IoChevronDown />
                      </>
                    ) : null}
                  </h6>
                </Col>
              </Row>
              {summaryOpen ? (
                <>
                  <Row className="my-2">
                    {pengiriman?.map((eachData, i) => {
                      if (i !== pengiriman.length - 1) {
                        return (
                          <>
                            <Col xs={2} className="text-center">
                              <img
                                src={iconMark}
                                alt=""
                                style={{
                                  marginLeft: "-1px",
                                  width: "18px",
                                }}
                              />
                              <span
                                className="mark-number"
                                style={{ marginLeft: "11px", marginTop: "2px" }}
                              >
                                {i + 1}
                              </span>
                              <div
                                className="br-dash mt-2"
                                style={{ height: "35px", marginLeft: "19px" }}
                              />
                            </Col>
                            <Col xs={10}>
                              <h6 className="text-muted">
                                {eachData.namaPenerima}
                              </h6>
                              <h6 className="text-muted">
                                +62{eachData.nomorTelepon}
                              </h6>
                              <h6 className="text-muted">{eachData.lokasi}</h6>
                            </Col>
                          </>
                        );
                      }
                    })}
                  </Row>
                </>
              ) : null}
              <Row className="mt-3">
                <Col xs={2} className="text-center">
                  <img
                    src={iconMark}
                    alt=""
                    style={{
                      // marginLeft: ".1em",
                      width: "18px",
                    }}
                  />
                  <span
                    className="mark-number"
                    style={{
                      marginLeft: ".9em",
                      marginTop: "2px",
                    }}
                  >
                    {pengiriman.length}
                  </span>
                  <div
                    className="br-dash mt-2"
                    style={{ height: "35px", marginLeft: "1.1em" }}
                  />
                </Col>
                <Col xs={10}>
                  <h6 className="text-muted">
                    {pengiriman[pengiriman.length - 1]?.namaPenerima}
                  </h6>
                  <h6 className="text-muted">
                    +62{pengiriman[pengiriman.length - 1]?.nomorTelepon}
                  </h6>
                  <h6 className="text-muted">
                    {pengiriman[pengiriman.length - 1]?.lokasi}
                  </h6>
                </Col>
              </Row>
              {backToPickupAddress.toggled && (
                <>
                  <Row className="mt-2">
                    <Col xs={2} className="text-center align-self-center">
                      {/* <GoPrimitiveDot /> */}
                    </Col>
                    <Col
                      xs={10}
                      className="align-self-center"
                    >
                      <h6 className="text-muted">
                        Titik Balik (COD)
                      </h6>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col xs={2} className="text-center">
                      <img
                        src={iconMark}
                        alt=""
                        style={{
                          marginLeft: "-1px",
                          width: "18px",
                        }}
                      />
                      <span
                        className="mark-number"
                        style={{ marginLeft: "11px", marginTop: "2px" }}
                      >
                        {pengiriman.length + 1}
                      </span>
                      <div
                        className="br-dash mt-2"
                        style={{ height: "35px", marginLeft: "19px" }}
                      />
                    </Col>
                    <Col xs={10}>
                      <h6 className="text-muted">
                        {backToPickupAddress?.namaPenerima}
                      </h6>
                      <h6 className="text-muted">
                        +62{backToPickupAddress?.nomorTelepon}
                      </h6>
                      <h6 className="text-muted">
                        {backToPickupAddress?.lokasi}
                      </h6>
                    </Col>
                  </Row>
                </>
                
              )}
              <Row>
                <Col xs={{ offset: 2, size: 8 }}>
                  <hr className="mb-2" />
                  <h6 className="mb-0">
                    Jarak : {detailOrder?.total_km}Km, Durasi :{" "}
                    {util.etaConvertion(detailOrder?.eta)}
                  </h6>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card className="border-0 my-1">
            <Row className="card-body py-1">
              <Col xs={2} className="align-self-center text-center">
                <img src={iconTruck} alt="" className="img-fluid" />
              </Col>
              <Col xs={10} className="align-self-center">
                <p className="fw-bold mb-0">Informasi Mitra</p>
                <h6 className="mb-0 text-muted mt-1">
                  Jenis Kendaraan - {selectedVType.name}
                </h6>
                <h6 className="mb-0 text-muted mt-1">
                  Mitra Layanan - {selectedPartnerService?.mst_partner?.name}{" "}
                  <span className="ms-2 text-success">
                    Terproteksi <BsShieldFillCheck />
                  </span>
                </h6>
              </Col>
            </Row>
          </Card>
          <Card className="border-0 my-1">
            <Row className="card-body py-1">
              <Col xs={2} className="align-self-center text-center">
                <img src={iconService} alt="" className="img-fluid" />
              </Col>

              { contract && contract.id === "1" && (
                <Col xs={10} className="align-self-center">
                  <p className="fw-bold mb-0">Layanan</p>
                  <h6 className="mb-0 text-muted mt-1">
                    Tenaga Supir <span className="text-success">(Termasuk)</span>{" "}
                  </h6>
                  {detailOrder?.total_helper_requested > 0 && (
                    <h6 className="mb-0 text-muted mt-1">
                      Helper ({detailOrder?.total_helper_requested} orang)
                    </h6>
                  )}
                  {detailOrder?.is_insurance_checked && (
                    <h6 className="mb-0 text-muted mt-1">
                      Asuransi ({detailOrder?.insurance_desc})
                    </h6>
                  )}
                </Col>
              )}

              { contract && contract.id !== "1" && (
                <Col xs={10} className="align-self-center">
                  <p className="fw-bold mb-0">Layanan Tambahan</p>
                  <img
                    src={icon_checked}
                    alt=""
                    style={{
                      marginLeft: "0px",
                      marginRight: "0px",
                      width: "20px",
                    }}
                  /> <span style={{fontSize: "12px"}}> Sudah Termasuk Semua </span>
                </Col>
              )}
            </Row>
          </Card>
        </div>
        
        { contract && contract.id !== "1" && (
          <AdditionalData />
        )}
        
      </Card.Body>
      <Card.Footer className="bg-0 shadow-top border-top-0">
        {/* <ModalPromo /> */}
        <div className="px-1 mt-3 d-flex align-items-center justify-content-between">
          <h4>Harga</h4>
          <h4 className="fw-bold">
            Rp{" "}
            {util.thousandSeparatorPure(Math.ceil(detail_price.final_price)) ||
              "-"}
          </h4>
        </div>
        {/* <Row className="px-1 mt-3">
          <Col md={6}>
            <h4>Harga</h4>
          </Col>
          <Col md={6} className="text-end">
            <h4 className="fw-bold">
              Rp{" "}
              {util.thousandSeparatorPure(
                Math.ceil(detail_price.final_price)
              ) || "-"}
            </h4>
          </Col>
        </Row> */}
      </Card.Footer>
    </>
  );
};

export default TabSummary;
