/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FiEdit, FiPlusCircle, FiTrash2 } from "react-icons/fi";
import { formatRupiah } from "../../../../helper/formatCurrency";
import Kontrak from "../Modal/Kontrak";

const PenawaranKontrak = (props) => {
  /*======================== Props ======================== */

  const { kontrakItems, setKontrakItems, handleDeleteKontrak } = props;
  /*======================== UseState ======================== */

  const [isOpen, setIsOpen] = useState(false);
  const [editIndex, setEditIndex] = useState(undefined);
  const [initialValues, setInitialValues] = useState(undefined);

  /*======================== Handler ======================== */

  const handleOpen = (index) => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    setInitialValues(undefined);
  };

  const handleEdit = (index, value) => {
    setEditIndex(index);
    setInitialValues(value);
    setIsOpen(true);
  };

  const handleRemoveItem = (index, item) => {
    if (item.id?.length > 10) {
      handleDeleteKontrak(item.id);
    }
    const temp = kontrakItems;
    temp.splice(index, 1);
    setKontrakItems([...temp]);
  };

  const handleSubmit = (value) => {
    if (editIndex !== undefined) {
      const temp = kontrakItems;
      temp[editIndex] = value;
      setKontrakItems([...temp]);
      setEditIndex(undefined);
    } else {
      setKontrakItems((old) => [...old, value]);
    }
    setInitialValues(undefined);
    setIsOpen(false);
  };

  /*======================== UseEffect ======================== */

  /*======================== Others ======================== */

  /*======================== Return ======================== */

  return (
    <div>
      <Row className="mt-4 procurement-content penawaran-kontrak">
        <h5 className="fw-bold mt-3">Daftar Penawaran Kontrak</h5>
        {kontrakItems.length > 0 &&
          kontrakItems.map((item, index) => (
            <Col key={index} xs={12} md={6} className="mb-2">
              <div className="bg-white add-card pr-9">
                <div className="width-100">
                  <div className="d-flex flex-wrap">
                    <Col xs={6}>
                      <p className="text-muted mb-1 label">Rute Anda</p>
                      <h5>
                        {`${item.route.pick_up_name} - ${item.route.delivery_name}`}
                      </h5>
                    </Col>
                    <Col xs={6}>
                      <p className="text-muted mb-1 label">Total Budget</p>
                      <h5>{formatRupiah(item.total_budget)}</h5>
                    </Col>
                    <hr className="divider" />
                    <Col xs={12} className="pe-3">
                      <p className="text-muted mb-1 label">
                        Kebutuhan Kendaraan
                      </p>
                      <h5>
                        {item.vehicles
                          .map((_) => `${_.vehicle.name} (${_.quantity})`)
                          .join(", ")}
                      </h5>
                    </Col>
                  </div>
                </div>
                <div
                  className="action-card delete border-start"
                  style={{ right: "72px" }}
                  onClick={() => handleRemoveItem(index, item)}
                >
                  <FiTrash2 className="icon-trash" />
                </div>
                <div
                  className="action-card edit"
                  onClick={() => handleEdit(index, item)}
                >
                  <FiEdit className="icon-edit" />
                </div>
              </div>
            </Col>
          ))}
        <Col xs={12} md={6} className="mb-2">
          <div className="bg-white add-card">
            <div className="width-100">
              <div className="d-flex flex-wrap">
                <Col xs={6}>
                  <p className="text-muted mb-1 label">Rute Anda</p>
                  <h5>Belum Pilih Rute</h5>
                </Col>
                <Col xs={6}>
                  <p className="text-muted mb-1 label">Total Budget</p>
                  <h5>Rp. 0</h5>
                </Col>
                <hr className="divider" />
                <div>
                  <p className="text-muted mb-1 label">Kebutuhan Kendaraan</p>
                  <h5>Belum Pilih Kendaraan</h5>
                </div>
              </div>
            </div>
            <div className="action-card add" onClick={handleOpen}>
              <FiPlusCircle className="icon-plus" />
            </div>
          </div>
        </Col>
      </Row>
      <Kontrak {...{ isOpen, initialValues, handleClose, handleSubmit }} />
    </div>
  );
};

export default PenawaranKontrak;
