import { BaseApiRequest } from "./base-api";
import { OpResult } from "../helper/operation_result";
import { message } from "antd";
import axios from "axios";

export default class MasterInsuranceApi extends BaseApiRequest {
  constructor() {
    super(process.env.REACT_APP_API_END_POINT);
  }

  async getMasterInsurance(query) {
    let result = OpResult.failed("Failed GET Insurance fees");
    try {
      let resApi = await axios.get(
        this.endpoint + `/master/const/kbru/insurance?${query}`
      );
      result = resApi.data;
    } catch (e) {
      console.log(e.response);
      // message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }
}
