/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { useHistory } from "react-router-dom";
import NotifApi from "../../api/notif-api";
import { useSelector } from "react-redux";

import icon_kirim_barang_on from "../../assets/images/sidebar-icon/icon_kBarang_on.png";
import icon_sewa_on from "../../assets/images/sidebar-icon/icon_sewa_on.png";

import moment from "moment";
import icon_notif_info from "../../assets/images/icon_notif_info.svg";
import icon_notif_discount from "../../assets/images/icon_notif_discount.svg";
import icon_notif_money_red from "../../assets/images/icon_notif_money_red.svg";
import icon_notif_money from "../../assets/images/icon_notif_money.svg";
import icon_notif_phone from "../../assets/images/icon_notif_phone.svg";
import icon_notif_penawaran from "../../assets/images/icon_penawaran.svg";
import icon_notif_chat from "../../assets/images/icon_chat.svg";
import icon_notif_change_list from "../../assets/images/icon_change_list.svg";
import icon_notif_rp from "../../assets/images/icon_doc_rp.svg";

const SidebarNotifItem = ({ data }) => {
  const history = useHistory();
  const auth = useSelector((st) => st.authReducer);
  const getNotifIcon = () => {
    const mathcer = {
      SEND: icon_kirim_barang_on,
      RENT: icon_sewa_on,
      BALANCE: icon_notif_money,
      INFO: icon_notif_info,
      ACCOUNT: icon_notif_phone,
      PROMO: icon_notif_discount,
      OFFER_ACTIVITY: icon_notif_chat,
      OFFER_OWNED: icon_notif_penawaran,
      OFFER_CHANGE: icon_notif_change_list,
      OFFER_CONTRACT_OWNED: icon_notif_info,
      INVOICE_ACTIVITY: icon_notif_chat,
      INVOICE_OWNED: icon_notif_rp,
      INVOICE_CHANGE: icon_notif_change_list,
      INVOICE_PAYMENT: icon_notif_money
    };
    return mathcer[data?.category];
  };

  const handleOnClick = async () => {
    try {
      const api = new NotifApi();
      await api.markAsRead(data?.id, auth.access_token);
      let urlPath = data?.url.replace(process.env.REACT_APP_WEBAPP_URL, "");
      console.log(data?.url.split(process.env.REACT_APP_WEBAPP_URL));
      // history.push(urlPath);
      window.location = data?.url;
    } catch (error) {
      console.log(error?.response);
    }
  };

  return (
    <div
      className="w-100 px-4 py-2 text-start pointer border-bottom-1"
      style={{ backgroundColor: data?.is_read > 0 ? "white" : "#f8f9fa" }}
      onClick={handleOnClick}
    >
      <div className="d-flex align-items-center ">
        <span>
          <img src={getNotifIcon()} width={20} height={20} className="me-2" />
        </span>
        <h6 className="text-muted mt-2">
          {data?.name} &bull; {moment(data?.created_at).format("DD MMM YYYY")}
        </h6>
      </div>
      <h6 className="fw-bold mt-2">{data?.headings}</h6>
      <h6 className="text-muted">{data?.subtitle}</h6>
      <h6 className="text-muted">{data?.contents}</h6>
    </div>
  );
};

export default SidebarNotifItem;
