const initialState = {
  alamat_anda: {
    data: [],
    meta: {},
    all: [],
  },
  alamat_anda_sewa: {
    data: [],
    meta: {},
    all: [],
  },
  alamat_pengiriman: {
    data: [],
    meta: {},
    all: [],
  },
  loading: false,
};

export const alamatReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "SET_ALAMAT":
      return { ...state, ...payload };
    case "SET_ALAMAT_SEWA":
      return { ...state, ...payload };
    case "SET_ALAMAT_ANDA_ALL":
      return {
        ...state,
        loading: false,
        alamat_anda: { ...state.alamat_anda, ...payload },
      };
    case "SET_ALAMAT_PENGIRIMAN_ALL":
      return {
        ...state,
        loading: false,
        alamat_pengiriman: { ...state.alamat_pengiriman, ...payload },
      };
    case "RESET_ALAMAT_ANDA":
      return { ...state, alamat_anda: { data: [], meta: {}, all: [] } };
    case "RESET_ALAMAT_PENGIRIMAN":
      return { ...state, alamat_pengiriman: { data: [], meta: {}, all: [] } };
    default:
      return state;
  }
};
