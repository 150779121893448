export const formatRupiah = (value) => {
  if (!value) {
    return ''
  }
  let stringValue = value.toString();
  let sisa = stringValue.length % 3;
  let rupiah = stringValue.substr(0, sisa);
  let ribuan = stringValue.substr(sisa).match(/\d{3}/g);
  if (ribuan) {
    let separator = sisa ? "." : "";
    rupiah += separator + ribuan.join(".");
    return `Rp. ${rupiah}`;
  } else {
    return `Rp. ${value}`;
  }
};
export const formatThousand = (value) => {
  let stringValue = value.toString();
  let sisa = stringValue.length % 3;
  let rupiah = stringValue.substr(0, sisa);
  let ribuan = stringValue.substr(sisa).match(/\d{3}/g);
  if (ribuan) {
    let separator = sisa ? "." : "";
    rupiah += separator + ribuan.join(".");
    return `${rupiah}`;
  } else {
    return `${value}`;
  }
};
