import React from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import GoogleMapReact, { fitBounds } from "google-map-react";
import { setMap } from "../../redux/action/map_action";
import Geocode from "react-geocode";
import _ from "lodash";
import Breakpoint from "../responsiveUtils/Breakpoint";
import useBreakpoint from "../responsiveUtils/useBreakpoint";

// images
import iconCircle from "../../assets/images/icon-circle.svg";
import iconMarker from "../../assets/images/icon-mark.svg";
import iconMarkerO from "../../assets/images/icon-marker-o.svg";
import icon_pick_up from "../../assets/images/icon_pick_up.svg"

// icons
import { AiOutlineAim } from "react-icons/ai";
import { FiMinus, FiPlus } from "react-icons/fi";
import { FaRoute } from "react-icons/fa";

import { updatePickupDetail as updatePickupDetailSewaKendaraan } from "../../redux/action/sewakendaraan_action";
import {
  updateDetail,
  updatePickupDetail as updatePickupDetailKirimBarang,
  updateSpesificShippmentDetail,
} from "../../redux/action/kirimbarang_action";
import { withSuccess } from "antd/lib/modal/confirm";

class GetMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      draggable: true,
      zoom: 13,
      mapOptions: {
        zoomControl: false,
        scaleControl: false,
        fullscreenControl: false,
        mapId: "4ef51c620988c579",
        styles: undefined,
      },
      isRoutesShowed: false,
      gmapRefs: null,
      gmapServices: null,
      gmapPolylines: [],
    };
  }

  nowLocation = () => {
    let props = this.props;
    navigator.geolocation.watchPosition(function (position) {
      props.dispatch(
        setMap({
          center: {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          },
        })
      );
    });
  };

  setZoom(zoom) {
    this.setState({
      zoom,
    });
  }

  handleApiLoaded = async (map, maps) => {
    this.setState({ ...this.state, gmapRefs: map, gmapServices: maps });

    if (this.props.activeMenu === "SewaKendaraan") {
    } else {
      if (
        this.props.pengambilanKirimBarang_new[0].lat &&
        this.props.pengambilanKirimBarang_new[0].lng &&
        this.props.pengirimanKirimBarang[0].lat &&
        this.props.pengirimanKirimBarang[0].lng &&
        this.state.isRoutesShowed
      ) {
        await this.unoptimizedRoute(map, maps);
      }
    }
  };

  async unoptimizedRoute(map, maps) {
    let routes = [];
    // routes.push({
    //   lat: this.props.pengambilanKirimBarang_new.lat,
    //   lng: this.props.pengambilanKirimBarang_new.lng,
    // });
    routes.push(...this.props.pengambilanKirimBarang_new);
    routes.push(...this.props.pengirimanKirimBarang);
    routes.map(async (x, index) => {
      if (routes[index].lat && routes[index].lng && index + 1 < routes.length) {
        const directionsService = new maps.DirectionsService();
        const directionsDisplay = new maps.DirectionsRenderer();
        await directionsService.route(
          {
            origin: new window.google.maps.LatLng(
              routes[index].lat,
              routes[index].lng
            ),
            destination: new window.google.maps.LatLng(
              routes[index + 1].lat,
              routes[index + 1].lng
            ),
            travelMode: window.google.maps.TravelMode.DRIVING,
            // optimized: true,
            // optimizeWaypoints: true
          },
          (response, status) => {
            if (status === "OK") {
              directionsDisplay.setDirections(response);
              const routePolyline = new maps.Polyline({
                path: response.routes[0].overview_path,
                strokeColor: "#d63384",
                strokeOpacity: 0.8,
                strokeWeight: 5,
              });
              routePolyline.setMap(map);
            } else {
              window.alert("Directions request failed due to " + status);
            }
          }
        );
      }
    });
  }

  async optimizedRoute(map, maps) {
    let waypts = [];
    let routes = [];
    let farestDestination = {
      lat: this.props.pengirimanKirimBarang[0].lat,
      lng: this.props.pengirimanKirimBarang[0].lng,
    };

    if (
      this.props.pengambilanKirimBarang_new[0].lat &&
      this.props.pengambilanKirimBarang_new[0].lng &&
      this.props.pengirimanKirimBarang[0].lat &&
      this.props.pengirimanKirimBarang[0].lng &&
      this.state.isRoutesShowed
    ) {
      // routes.push({
      //   lat: this.props.pengambilanKirimBarang_new.lat,
      //   lng: this.props.pengambilanKirimBarang_new.lng,
      // });
      routes.push(...this.props.pengambilanKirimBarang_new);
      routes.push(...this.props.pengirimanKirimBarang);

      const rad = function (x) {
        return (x * Math.PI) / 180;
      };

      const getDistance = function (p1, p2) {
        const R = 6378137; // Earth’s mean radius in meter
        const dLat = rad(p2.lat - p1.lat);
        const dLong = rad(p2.lng - p1.lng);
        const a =
          Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos(rad(p1.lat)) *
            Math.cos(rad(p2.lat)) *
            Math.sin(dLong / 2) *
            Math.sin(dLong / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const d = R * c;
        return d; // returns the distance in meter
      };

      let countFarestDestination = getDistance(
        {
          lat: this.props.pengirimanKirimBarang[0].lat,
          lng: this.props.pengirimanKirimBarang[0].lng,
        },
        {
          lat: this.props.pengambilanKirimBarang_new[0].lat,
          lng: this.props.pengambilanKirimBarang_new[0].lng,
        }
      );
      [...this.props.pengirimanKirimBarang].map((x, index) => {
        const getDistanceValue = getDistance(
          {
            lat: x.lat,
            lng: x.lng,
          },
          {
            lat: this.props.pengambilanKirimBarang_new[0].lat,
            lng: this.props.pengambilanKirimBarang_new[0].lng,
          }
        );
        if (getDistanceValue > countFarestDestination) {
          farestDestination = {
            lat: x.lat,
            lng: x.lng,
          };
        } else {
          waypts.push({
            location: new window.google.maps.LatLng(x.lat, x.lng),
            stopover: true,
          });
        }
      });
    }
    if (waypts.length) {
      const directionsService = new maps.DirectionsService();
      const directionsDisplay = new maps.DirectionsRenderer({
        suppressMarkers: true,
      });

      await directionsService.route(
        {
          origin: new window.google.maps.LatLng(
            this.props.pengambilanKirimBarang_new[0].lat,
            this.props.pengambilanKirimBarang_new[0].lng
          ),
          destination: new window.google.maps.LatLng(
            farestDestination.lat,
            farestDestination.lng
          ),
          travelMode: window.google.maps.TravelMode.DRIVING,
          waypoints: waypts,
          optimizeWaypoints: this.props.isOptimize ? true : undefined,
        },
        (response, status) => {
          if (status === "OK") {
            directionsDisplay.setDirections(response);
            const routePolyline = new maps.Polyline({
              path: response.routes[0].overview_path,
              strokeColor: "#d63384",
              strokeOpacity: 0.8,
              strokeWeight: 5,
            });
            routePolyline.setMap(map);
          } else {
            window.alert("Directions request failed due to " + status);
          }
        }
      );
    }
  }

  componentDidMount() {
    this.generateKey();
  }

  componentDidUpdate(prevProps, prevStates) {
    const isArrayEqual = (x, y) => {
      return _(x).differenceWith(y, _.isEqual).isEmpty();
    };

    const isObjectEqual = (x, y) => {
      return _.isEqual(x, y);
    };

    const reRenderMapPickupUpdated = () => {
      return isArrayEqual(
        prevProps.pengambilanKirimBarang_new,
        this.props.pengambilanKirimBarang_new
      )
        ? false
        : true;
    };

    const reRenderMapKirimBarangUpdated = () => {
      return isArrayEqual(
        prevProps.pengirimanKirimBarang,
        this.props.pengirimanKirimBarang
      )
        ? false
        : true;
    };

    const reRenderMapTitikBalik = () => {   // tambah titik balik
      return isObjectEqual(
        prevProps.backToPickupAddress,
        this.props.backToPickupAddress
      )
        ? false
        : true;
    };

    const reRenderMapKirimBarangDragged = () => {
      return prevProps.dragLogs.length < this.props.dragLogs.length;
    };

    const isBackToAddressToggled = () => {
      return (
        prevProps.backToPickupAddress.toggled !==
        this.props.backToPickupAddress.toggled
      );
    };
    // polyline
    let Line;

    if (
      prevStates.isRoutesShowed !== this.state.isRoutesShowed ||
      prevProps.isOptimize !== this.props.isOptimize ||
      reRenderMapKirimBarangUpdated() ||
      reRenderMapKirimBarangDragged() ||
      reRenderMapPickupUpdated() ||
      reRenderMapTitikBalik() // tambah titik balik
    ) {
      // alert("here");

      // polyline
      let paths = [];

      // paths[0] = {
      //   lat: this.props.pengambilanKirimBarang_new.lat,
      //   lng: this.props.pengambilanKirimBarang_new.lng,
      // };
      this.props.pengambilanKirimBarang_new.forEach((eachData) => {
        if (eachData.lat && eachData.lng) {
          paths.push({ lat: eachData.lat, lng: eachData.lng });
        }
      });
      this.props.pengirimanKirimBarang.forEach((eachData) => {
        if (eachData.lat && eachData.lng) {
          paths.push({ lat: eachData.lat, lng: eachData.lng });
        }
      });

      if(this.props.backToPickupAddress.lat && this.props.backToPickupAddress.lng){ // tambah titik balik sumbu
        paths.push({
          lat: this.props.backToPickupAddress.lat,
          lng: this.props.backToPickupAddress.lng,
        })  
      }

      console.log({ paths });
      // console.log(paths,"====> ini data paths")
      // console.log(this.state.gmapPolylines,"====> ini data gmapPolylines")
      this.setState({ ...this.state, gmapPolylines: [...paths] });

      // Line.setPath(paths);
      this.generateKey();
    }
    if (this.props.activeMenu === "SewaKendaraan") {
      if (
        !prevProps.pengambilansewaKendaraan.lat &&
        prevProps.pengambilansewaKendaraan.lat !==
          this.props.pengambilansewaKendaraan.lat
      ) {
        this.setState({
          zoom: 17,
        });
      }
    } else {
      if (
        !prevProps.pengambilanKirimBarang_new.lat &&
        prevProps.pengambilanKirimBarang_new.lat !==
          this.props.pengambilanKirimBarang_new.lat
      ) {
        this.setState({
          zoom: 17,
        });
      }

      // set fitbounds map
      let doFitBounds = false;
      const bounds = new window.google.maps.LatLngBounds();
      // if (
      //   this.props.pengambilanKirimBarang_new.length 
      //   // &&
      //   // this.props.pengambilanKirimBarang_new[0].lat &&
      //   // this.props.pengambilanKirimBarang_new[0].lng
      // ) {
      //   // doFitBounds = true; 
      //   // bounds.extend(
      //   //   new window.google.maps.LatLng(
      //   //     this.props.pengambilanKirimBarang_new,
      //   //     this.props.pengambilanKirimBarang_new.lng
      //   //   )
      //   // );
      //   this.props.pengambilanKirimBarang_new.forEach((eachData) => {
      //     if (eachData.lat && eachData.lng) {
      //       doFitBounds = true;
      //       bounds.extend(
      //         new window.google.maps.LatLng(eachData.lat, eachData.lng)
      //       );
      //     }
      //   });
      // }
      this.props.pengambilanKirimBarang_new.forEach((eachData) => {
        if (eachData.lat && eachData.lng) {
          doFitBounds = true;
          bounds.extend(
            new window.google.maps.LatLng(eachData.lat, eachData.lng)
          );
        }
      });
      this.props.pengirimanKirimBarang.forEach((eachData) => {
        if (eachData.lat && eachData.lng) {
          doFitBounds = true;
          bounds.extend(
            new window.google.maps.LatLng(eachData.lat, eachData.lng)
          );
        }
      });
      if (doFitBounds && this.state.gmapRefs) {
        this.state.gmapRefs.fitBounds(bounds);
      }

      if (!this.state.isRoutesShowed) {
        Line = new window.google.maps.Polyline({
          geodesic: true,
          path: this.state.gmapPolylines,
          map: this.state.gmapRefs,
          strokeOpacity: 0.0,
          icons: [
            {
              icon: {
                path: "M 0,-1 0,1",
                strokeOpacity: 1,
                scale: 4,
              },
              offset: "0",
              repeat: "20px",
            },
          ],
          strokeColor: "#d63384",
        });
      } else {
        Line = new window.google.maps.Polyline();
        Line.setVisible(false);
      }
    }
  }
  
  generateKey() {
    let key =
      this.props.activeMenu === "SewaKendaraan"
        ? this.props.pengambilansewaKendaraan.lat
        : [...this.props.pengambilanKirimBarang_new] +
            "-" +
            this.props.activeMenu ===
          "SewaKendaraan"
        ? this.props.pengambilansewaKendaraan.lng
        : [...this.props.pengambilanKirimBarang_new];
    if(this.props.activeMenu === "KirimBarang"){ // tambah refresh update pengambilan sumbu
      key.map((x) => {
        if (x.lat && x.lng) {
          key = `${key}-${x.lat}-${x.lng}`;
          console.log(key)
        }
      })
    }
    let pengiriman =
    this.props.activeMenu === "SewaKendaraan"
    ? []
    : [...this.props.pengirimanKirimBarang];
    pengiriman.map((x) => {
      if (x.lat && x.lng) {
        key = `${key}-${x.lat}-${x.lng}`;
        console.log(key)
      }
    });
    if(this.props.backToPickupAddress.lat && this.props.backToPickupAddress.lng){ // tambah refresh titik balik sumbu
      key = `${key}-${this.props.backToPickupAddress.lat}-${this.props.backToPickupAddress.lng}`;
    }
      
    key = `${key}${this.state.isRoutesShowed}` + this.props.isOptimize;
    this.setState({ key });
  }

  handleOnCircleInteraction(childKey, childProps, mouse) {
    if (mouse && mouse.lat && mouse.lng) {
      Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_API_KEY);
      Geocode.setLanguage("id");
      Geocode.fromLatLng(mouse.lat, mouse.lng).then(
        async (response) => {
          if (childKey === "pengambilansewaKendaraan") {
            this.setState({
              draggable: false,
            });
            this.props.dispatch(
              updatePickupDetailSewaKendaraan({
                lat: mouse.lat,
                lng: mouse.lng,
              })
            );
          }
          if (childKey === "pengambilanKirimBarang") {
            this.setState({
              draggable: false,
            });
            this.props.dispatch(
              updatePickupDetailKirimBarang({
                lat: mouse.lat,
                lng: mouse.lng,
              })
            );
          }
          const reg = new RegExp("^[0-9]+$");
          if (reg.test(childKey)) {
            const index = parseInt(childKey);
            this.props.dispatch(
              updateSpesificShippmentDetail(
                {
                  lat: mouse.lat,
                  lng: mouse.lng,
                },
                index
              )
            );
          }
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }

  handleOnCircleInteraction3(childKey, childProps, mouse) {
    if (mouse && mouse.lat && mouse.lng) {
      Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_API_KEY);
      Geocode.setLanguage("id");
      Geocode.fromLatLng(mouse.lat, mouse.lng).then(
        async (response) => {
          const lokasi = response.results[0].formatted_address;

          if (childKey === "pengambilansewaKendaraan") {
            this.setState({
              draggable: true,
            });
            this.props.dispatch(
              updatePickupDetailSewaKendaraan({
                lat: mouse.lat,
                lng: mouse.lng,
                lokasi,
              })
            );
          }
          if (childKey === "pengambilanKirimBarang") {
            this.setState({
              draggable: true,
            });
            this.props.dispatch(
              updatePickupDetailKirimBarang({
                lat: mouse.lat,
                lng: mouse.lng,
                lokasi,
              })
            );
          }
          const reg = new RegExp("^[0-9]+$");
          if (reg.test(childKey)) {
            const index = parseInt(childKey);
            this.props.dispatch(
              updateSpesificShippmentDetail(
                {
                  lat: mouse.lat,
                  lng: mouse.lng,
                  lokasi,
                },
                index
              )
            );
          }
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }

  _onChange = (data) => {
    this.setState({
      center: data.center,
      zoom: data.zoom,
    });
  };

  showGoogleMap() {
    let componentItem = [];
    componentItem.push(
      <GoogleMapReact
        // draggable={this.state.draggable}
        // onChildMouseDown={(a, b, c) => {
        //   this.handleOnCircleInteraction(a, b, c);
        // }}
        // onChildMouseUp={(a, b, c) => {
        //   this.handleOnCircleInteraction3(a, b, c);
        // }}
        // onChildMouseMove={(a, b, c) => {
        //   this.handleOnCirc  leInteraction(a, b, c);
        // }}
        key={this.state.key}
        options={this.state.mapOptions}
        center={this.props.map.center}
        defaultZoom={13}
        zoom={this.state.zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={async ({ map, maps }) => {
          await this.handleApiLoaded(map, maps);
        }}
      >
        {/* <div
          className="place"
          lat={this.props.pengambilanKirimBarang.lat}
          lng={this.props.pengambilanKirimBarang.lng}
          key={"pengambilanKirimBarang"}
          style={{ marginTop: "-1.5rem" }}
          hidden={this.props.activeMenu === "SewaKendaraan"}
        >
          <img
            src={iconCircle}
            alt=""
            className="animate__animated animate__bounce icon-marker"
          />
        </div> */}
        {this.props.pengambilanKirimBarang_new.map((data, i) => (
          <div
          className="place"
          lat={data.lat}
          lng={data.lng}
          key={i}
          style={{ marginTop: "-1.5rem" }}
          hidden={this.props.activeMenu === "SewaKendaraan"}
        >
          <img
            src={icon_pick_up}
            alt=""
            className="animate__animated animate__bounce icon-marker"
          />
          <span className="key-marker">{i + 1}</span>
        </div>
        ))}

        {this.props.pengirimanKirimBarang.map((data, i) => (
          <div
            className="place"
            lat={data.lat}
            lng={data.lng}
            key={i}
            style={{ marginTop: "-1.5rem" }}
            hidden={this.props.activeMenu === "SewaKendaraan"}
          >
            <img
              src={iconMarker}
              alt=""
              className="animate__animated animate__bounce icon-marker"
            />
            <span className="key-marker">{i + 1}</span>
          </div>
        ))}
        <div
          className="place"
          lat={this.props.backToPickupAddress?.lat}
          lng={this.props.backToPickupAddress?.lng}
          key={"back_to_pickup"}
          style={{ marginTop: "-1.5rem" }}
          hidden={this.props.activeMenu === "SewaKendaraan"}
        >
          <img
            src={iconMarker}
            alt=""
            className="animate__animated animate__bounce icon-marker"
          />
          <span className="key-marker">
            {this.props.pengirimanKirimBarang.length + 1}
          </span>
        </div>
        <div
          className="place"
          lat={this.props.pengambilansewaKendaraan.lat}
          lng={this.props.pengambilansewaKendaraan.lng}
          key={"pengambilansewaKendaraan"}
          style={{ marginTop: "-1.5rem" }}
          hidden={this.props.activeMenu === "KirimBarang"}
        >
          <img
            src={iconMarkerO}
            alt=""
            className="animate__animated animate__bounce"
          />
        </div>
      </GoogleMapReact>
    );
    return componentItem;
  }

  render() {
    return (
      <>
        <div className="map-control">
          <Breakpoint.Dekstop>
            <>
              {this.props.hideControlButton || this.props.hideRoute ? null : (
                <Button
                  size="sm"
                  variant={
                    !this.state.isRoutesShowed ? "outline-primary" : "primary"
                  }
                  className="mb-2 px-3 shadow"
                  onClick={() => {
                    this.setState({
                      isRoutesShowed: !this.state.isRoutesShowed,
                      zoom: 13,
                    });
                  }}
                >
                  {!this.state.isRoutesShowed ? (
                    <>
                      <FaRoute className="text-primary mr-2 hvr-white" />{" "}
                      <span className="hvr-white text-primary">Tampilan Route</span>
                    </>
                  ) : (
                    <>
                      <FaRoute className="text-white mr-2" />{" "}
                      <span className="text-white">Tampilan Route</span> 
                    </>
                  )}
                </Button>
              )}
              {this.props.hideControlButton ? null : (
                <div className="d-flex">
                  <Button
                    className="shadow skyblue"
                    variant="light"
                    size="sm"
                    onClick={() => this.nowLocation()}
                  >
                    <AiOutlineAim />
                  </Button>
                  <div className="ms-5">
                    <Button
                      variant="light"
                      className="me-1 shadow skyblue"
                      size="sm"
                      onClick={() => this.setZoom(this.state.zoom - 1)}
                    >
                      <FiMinus />
                    </Button>
                    <Button
                      className="shadow skyblue"
                      variant="light"
                      size="sm"
                      onClick={() => this.setZoom(this.state.zoom + 1)}
                    >
                      <FiPlus />
                    </Button>
                  </div>
                </div>
              )}
            </>
          </Breakpoint.Dekstop>
          <Breakpoint.Tablet>
            <>
              {this.props.hideControlButton || this.props.hideRoute ? null : (
                <Button
                  size="sm"
                  variant={
                    !this.state.isRoutesShowed ? "outline-primary" : "primary"
                  }
                  className="mb-2 shadow "
                  style={{ position: "fixed", top: "20px", right: 20 }}
                  onClick={() => {
                    this.setState({
                      isRoutesShowed: !this.state.isRoutesShowed,
                      zoom: 13,
                    });
                  }}
                >
                  {!this.state.isRoutesShowed ? (
                    <>
                      <FaRoute className="text-primary mr-2 hvr-white" />
                    </>
                  ) : (
                    <>
                      <FaRoute className="text-white mr-2" />
                    </>
                  )}
                </Button>
              )}
              {this.props.hideControlButton ? null : (
                <div className="d-flex">
                  <Button
                    className="shadow lipstick"
                    variant="light"
                    size="sm"
                    style={{ position: "fixed", top: "60px", right: 20 }}
                    onClick={() => this.nowLocation()}
                  >
                    <AiOutlineAim />
                  </Button>
                </div>
              )}
            </>
          </Breakpoint.Tablet>
          <Breakpoint.Mobile>
            <>
              <>
                {this.props.hideControlButton || this.props.hideRoute ? null : (
                  <Button
                    size="md"
                    variant={
                      !this.state.isRoutesShowed
                        ? "outline-primary bg-white"
                        : "primary"
                    }
                    className="mb-2 shadow "
                    style={{ position: "fixed", top: "20px", right: 20 }}
                    onClick={() => {
                      this.setState({
                        isRoutesShowed: !this.state.isRoutesShowed,
                        zoom: 13,
                      });
                    }}
                  >
                    {!this.state.isRoutesShowed ? (
                      <>
                        <FaRoute className="text-primary mr-2 hvr-white" />
                      </>
                    ) : (
                      <>
                        <FaRoute className="text-white mr-2" />
                      </>
                    )}
                  </Button>
                )}
                {this.props.hideControlButton ? null : (
                  <div className="d-flex">
                    <Button
                      className="shadow lipstick"
                      variant="light"
                      size="md"
                      style={{ position: "fixed", top: "20px", right: 60 }}
                      onClick={() => this.nowLocation()}
                    >
                      <AiOutlineAim />
                    </Button>
                  </div>
                )}
              </>
            </>
          </Breakpoint.Mobile>
        </div>
        {this.showGoogleMap()}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  map: state.map,
  pengambilanKirimBarang: state.kirimbarangReducer.pengambilan,
  pengambilanKirimBarang_new: state.kirimbarangReducer.pengambilan_new,
  isOptimize: state.kirimbarangReducer.isOptimize,
  pengirimanKirimBarang: state.kirimbarangReducer.pengiriman,
  pengambilansewaKendaraan: state.sewaKendaraanReducer,
  activeMenu: state.map.activeMenu,
  dragLogs: state.kirimbarangReducer.dragLogs,
  backToPickupAddress: state.kirimbarangReducer.backToPickupAddress,
  trxDetail: state.trxHistoryReducer.trxDetail,
});

export default connect(mapStateToProps)(GetMap);
