import React, { useEffect, useState } from "react";
import { Row, Col, Tabs, Tab } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import SideInfo from "./SideInfo";
import { useHistory } from "react-router-dom";
import SidebarNotifItem from "../../components/notifDropdown/SidebarNotifItem";
import NotifApi from "../../api/notif-api";
import moment from "moment";
import qs from "query-string";
import { getNotification, setTabKey } from "../../redux/action/notif_action";
import useBreakpoint from "../../components/responsiveUtils/useBreakpoint";

import icon_kirim_barang_on from "../../assets/images/sidebar-icon/icon_kBarang_on.png";
import icon_sewa_on from "../../assets/images/sidebar-icon/icon_sewa_on.png";
import icon_notif_info from "../../assets/images/icon_notif_info.svg";
import icon_notif_discount from "../../assets/images/icon_notif_discount.svg";
import icon_notif_money_red from "../../assets/images/icon_notif_money_red.svg";
import icon_notif_money from "../../assets/images/icon_notif_money.svg";
import icon_notif_phone from "../../assets/images/icon_notif_phone.svg";

const Notification = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const notif = useSelector((st) => st.notifReducer.notification);
  const tabKey = useSelector((st) => st.notifReducer.tabKey);
  const auth = useSelector((st) => st.authReducer);
  const { isMobile } = useBreakpoint();

  const [query, setQuery] = useState({ limit: 999 });

  useEffect(() => {
    const stringified = qs.stringify(query);
    if (auth.access_token) {
      dispatch(getNotification(stringified, auth.access_token));
    }
  }, [query]);

  const handleChangeTab = (key) => {
    if (+key === 1) {
      setQuery({ ...query, filter: undefined });
    }
    if (+key === 2) {
      setQuery({ ...query, filter: "transaction" });
    }
    if (+key === 3) {
      setQuery({ ...query, filter: "offer" });
    }
    if (+key === 4) {
      setQuery({ ...query, filter: "invoice" });
    }
    if (+key === 5) {
      setQuery({ ...query, filter: "other" });
    }
    dispatch(setTabKey(+key));
  };

  const markAllRead = async () => {
    try {
      const api = new NotifApi();
      await api.markAllRead(auth.access_token);
    } catch (error) {
      console.log(error?.response);
    }
  };

  return (
    <div
      style={{ width: "100%", padding: "30px" }}
      className="bg-ice min-vh-100"
    >
      <Row className="">
        <Col xs={12} md={12} lg={4}>
          <SideInfo />
        </Col>
        <Col className={`${isMobile && "mt-18rem"}`}>
          <h3 className="fw-bold mt-4">
            Notifikasi
            <span
              onClick={markAllRead}
              className="h5 float-end pointer text-primary underline fw-bold me-4
            "
            >
              Tandai Dibaca
            </span>
          </h3>
          <h6 className="text-muted">
            Temukan informasi seputar pesanan dan promo disini.
          </h6>
          <div className="bg-white rounded-card w-full pb-3">
            <p
              hidden={isMobile}
              className="float-end pointer text-primary underline fw-bold me-4 mt-4"
              size="sm"
              onClick={markAllRead}
            >
              Tandai Dibaca
            </p>
            <Tabs
              defaultActiveKey={tabKey}
              activeKey={tabKey}
              className="tabs-trx mb-2 border-bottom-2 p-3"
              onSelect={handleChangeTab}
            >
              <Tab eventKey="1" title="Semua"></Tab>
              <Tab eventKey="2" title="Pesanan"></Tab>
              <Tab eventKey="3" title="Penawaran"></Tab>
              <Tab eventKey="4" title="Invoice"></Tab>
              <Tab eventKey="5" title="Lainnya"></Tab>
            </Tabs>
            <div className="scrollable" style={{ height: "80vh" }}>
              {notif?.data?.map((el) => (
                <SidebarNotifItem data={el} />
              ))}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Notification;
