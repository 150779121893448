import RuteApi from "../../../api/RuteAlamat/rute-api";
import { resetAuth } from "../auth_action";

export const getRuteList = (params) => async (dispatch, state) => {
  try {
    const api = new RuteApi();

    dispatch({
      type: "SET_RUTE",
      payload: { loading: true },
    });

    const response = await api.getRuteList(
      state().authReducer.access_token,
      params
    );

    if (response.status === 401) {
      dispatch(resetAuth());
    }
    const { data, ...meta } = response;
    dispatch({
      type: "SET_RUTE",
      payload: { loading: false, data, meta },
    });
  } catch (error) {
    console.log(error?.response);
  }
};

export const getRuteListByName = (params) => async (dispatch, state) => {
  try {
    const api = new RuteApi();

    dispatch({
      type: "SET_RUTE",
      payload: { loading: true },
    });

    const response = await api.getRuteListByName(
      state().authReducer.access_token,
      params
    );

    if (response.status === 401) {
      dispatch(resetAuth());
    }
    const { data, ...meta } = response;
    dispatch({
      type: "SET_RUTE",
      payload: { loading: false, data, meta },
    });
  } catch (error) {
    console.log(error?.response);
  }
};

export const getRuteAll = (params) => async (dispatch, state) => {
  try {
    const api = new RuteApi();

    dispatch({
      type: "SET_RUTE",
      payload: { loading: true },
    });

    const response = await api.getRuteAll(
      state().authReducer.access_token,
      params
    );

    if (response.status === 401) {
      dispatch(resetAuth());
    }
    dispatch({
      type: "SET_RUTE",
      payload: { loading: false, all: response.data },
    });
  } catch (error) {
    console.log(error?.response);
  }
};

export const getRuteDetail = (params) => async (dispatch, state) => {
  try {
    const api = new RuteApi();

    dispatch({
      type: "SET_RUTE",
      payload: { loading: true },
    });

    const response = await api.getRuteDetail(
      state().authReducer.access_token,
      params
    );

    if (response.status === 401) {
      dispatch(resetAuth());
    }

    dispatch({
      type: "SET_RUTE",
      payload: { loading: false },
    });
    return response?.data;
  } catch (error) {
    console.log(error?.response);
  }
};

export const createRute = (payload) => async (dispatch, state) => {
  try {
    const api = new RuteApi();

    dispatch({
      type: "SET_RUTE",
      payload: { loading: true },
    });

    const response = await api.createRute(
      state().authReducer.access_token,
      payload
    );

    if (response.status === 401) {
      dispatch(resetAuth());
    }

    dispatch({
      type: "RESET_RUTE",
    });
    return response;
  } catch (error) {
    console.log(error?.response);
  }
};

export const downloadSample = () => async (dispatch, state) => {
  try{
    const api = new RuteApi();
    const response = await api.downloadSampleBulk(
      state().authReducer.access_token
    );
    if (response.status === 401) {
      dispatch(resetAuth());
    }
    return response;
  }catch(error){
    console.log(error?.response);
  }
};

export const createBulkRute = (payload) => async (dispatch, state) => {
  try {
    const api = new RuteApi();

    const response = await api.createBulkRute(
      state().authReducer.access_token,
      payload
    );
    if (response.status === 401) {
      dispatch(resetAuth());
    }

    return response;
  } catch (error) {
    console.log(error?.response);
  }
};

export const updateRute = (payload) => async (dispatch, state) => {
  try {
    const api = new RuteApi();

    dispatch({
      type: "SET_RUTE",
      payload: { loading: true },
    });

    const response = await api.updateRute(
      state().authReducer.access_token,
      payload
    );

    if (response.status === 401) {
      dispatch(resetAuth());
    }

    dispatch({
      type: "RESET_RUTE",
    });
    return response;
  } catch (error) {
    console.log(error?.response);
  }
};

export const deleteRute = (route_ids) => async (dispatch, state) => {
  try {
    const api = new RuteApi();

    dispatch({
      type: "SET_RUTE",
      payload: { loading: true },
    });

    const response = await api.deleteRute(
      state().authReducer.access_token,
      route_ids
    );

    if (response.status === 401) {
      dispatch(resetAuth());
    }
    return response;
  } catch (error) {
    console.log(error?.response);
  } finally {
    dispatch({
      type: "SET_RUTE",
      payload: { loading: false },
    });
  }
};
