import React, { useEffect, useState } from "react";
import useBreakpoint from "../../components/responsiveUtils/useBreakpoint";
import {
  getRoleList,
  getRoleListByName,
  getAccountList,
  getAccountListByName
} from "../../redux/action/usermanagement_action";
import { useSelector, useDispatch } from "react-redux";
import DekstopHeader from "../../components/modalUserManagement/Header/DekstopHeader";
import MobileHeader from "../../components/modalUserManagement/Header/MobileHeader";
import RoleContent from "../../components/modalUserManagement/Role/RoleContent";
import UserAccountContent from "../../components/modalUserManagement/UserAccount/UserAccountContent";

import {
  hideSidenavMobile,
  openSidenavMobile,
} from "../../components/layouts/layout_helper";


function UserManagement() {
  const dispatch = useDispatch();
  const auth = useSelector((st) => st.authReducer);
  const { isMobile, isTablet } = useBreakpoint();
  const [eventKey, setEventKey] = useState(1);

  const handleChangeTab = (key) => {
    setEventKey(+key);
  };

  useEffect(() => {
    if (isMobile) {
      hideSidenavMobile();
    }
  }, [isMobile]);

  const getRoleByName = (name) => {
    if (name) {
      if (name.length > 2) {
        dispatch(getRoleListByName(auth.access_token, name));
      }
    } else {
      dispatch(getRoleList(auth.access_token));
    }
  }

  const getAccountByName = (name) => {
    if (name) {
      if (name.length > 2) {
        dispatch(getAccountListByName(auth.access_token, name));
      }
    } else {
      dispatch(getAccountList(auth.access_token));
    }
  }


  return (
    <div
      style={{ minHeight: "100vh", width: "100%", padding: "30px 30px 5px 30px" }}
      className="bg-ice"
    >
      {/* HEADER */}
      {!isMobile ?
        (
          <DekstopHeader
            handleChangeTab={handleChangeTab}
            getRoleByName={getRoleByName}
            getAccountByName={getAccountByName}
            eventKey={eventKey}
          />
        )
        :
        (
          <MobileHeader
            handleChangeTab={handleChangeTab}
            openSidenavMobile={openSidenavMobile}
            getRoleByName={getRoleByName}
            getAccountByName={getAccountByName}
            eventKey={eventKey}
          />
        )}
      {/* END HEADER */}

      {/* CONTENT */}
      {eventKey === 1 ?
        <>
          <RoleContent />
        </>
        :
        <>
          <UserAccountContent />
        </>
      }

      {/* CONTENT */}
    </div>
  )
}

export default UserManagement;
