import { BaseApiRequest } from "../base-api";
import { message } from "antd";
import axios from "axios";

export default class VehicleApi extends BaseApiRequest {
  constructor() {
    super(process.env.REACT_APP_API_END_POINT);
  }

  async getVehicleSubtype(token, params) {
    let result;
    try {
      const response = await axios.get(
        // `${this.endpoint}/master/vehicle/subtype`, // before
        `${this.endpoint}/customer/vehicle/subtype`,
        {
          params,
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      result = response.data;
    } catch (e) {
      // message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }
}
