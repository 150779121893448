import { BaseApiRequest } from "../base-api";
import { message } from "antd";
import axios from "axios";

export default class RuteApi extends BaseApiRequest {
  constructor() {
    super(process.env.REACT_APP_API_END_POINT);
  }

  async getRuteList(token, params) {
    let result;
    try {
      const response = await axios.get(`${this.endpoint}/route/list`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });

      result = response.data;
    } catch (e) {
      // message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async getRuteListByName(token, params) {
    let result;
    try {
      const response = await axios.get(`${this.endpoint}/route`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });

      result = response.data;
    } catch (e) {
      // message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async getRuteAll(token, params) {
    let result;
    try {
      const response = await axios.get(`${this.endpoint}/route/list-search`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });

      result = response.data;
    } catch (e) {
      // message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async getRuteDetail(token, params) {
    let result;
    try {
      const response = await axios.get(`${this.endpoint}/route/detail`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });

      result = response.data;
    } catch (e) {
      // message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async createRute(token, payload) {
    let result;
    try {
      const response = await axios.post(`${this.endpoint}/route`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      message.success("Rute anda berhasil dibuat.");
      // return response;
      result = response.data;
    } catch (e) {
      // message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async downloadSampleBulk(token) {
    const Authorization = `Bearer ${token}`
    const response = await fetch(`${this.endpoint}/route/bulk-upload/sample`, {
        method: 'GET',
        headers: {
            Authorization,
        },
    }) 
    return response
  }
  
  async createBulkRute(token, payload) {
    let result;
    try {
      const response = await axios.post(`${this.endpoint}/route/bulk-upload`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      message.success("Rute anda berhasil dibuat.");
      // return response;
      result = response.data;
    } catch (e) {
      // message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async updateRute(token, payload) {
    let result;
    const { id, ...others } = payload;
    try {
      const response = await axios.patch(`${this.endpoint}/route`, others, {
        headers: { Authorization: `Bearer ${token}` },
      });

      result = response.data;
      message.success("Rute anda berhasil diedit.");
    } catch (e) {
      // message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async deleteRute(token, route_ids) {
    let result;
    try {
      const response = await axios.delete(`${this.endpoint}/route`, {
        headers: { Authorization: `Bearer ${token}` },
        data: {
          route_ids,
        },
      });

      result = response.data;
    } catch (e) {
      // message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }
}
