import React, { useState } from "react";
import {
  Modal
} from "react-bootstrap";
import moment from "moment";


const ModalInfoIntegrasi = ({data}) => {
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    
    const mapingStatus = (val) => {
		if(val === 0){
			return "Butuh Konfirmasi"
		}else if(val === 1){
			return "Menunggu Customer"
		}else if(val === 2){
			return "Diterima"
		}else{
			return "-"
		}
	}
	
	const mapingStatusColor = (val) => {
		if(val === 1){
			return "text-[#FFAC14]"
		}else if(val === 2){
			return "text-[#2BBECB]"
		}else if(val === 3){
			return "text-[#FFAA4B]"
		}else{
			return ""
		}
	}

	const mapingInfoIntegrasi = (val) => {
		if(val=== "EXTENTION"){
			return "Perpanjangan"
		}else if(val === "MUTATION"){
			return "Mutasi kendaraan"
		}else if(val === "MANUAL"){
			return "Pengadaan Baru"
		}else if(val === "REPLACE"){
			return "Ganti Kendaraan"
		}else if(val === "RENEWAL"){
			return "Renewal"
		}
		return "-"
	}

    return (
    <>
      <p className="m-0" onClick={()=>handleShow()}>Info Integrasi</p>
       <Modal
        contentClassName="rounded-card p-2"
        show={show}
        onHide={handleClose}
        size="md"
        centered
      >
        <Modal.Header closeButton className="bg-white">
          <strong>Info Integrasi</strong>
        </Modal.Header>
        <div className="p-1 flex justify-content-center align-items-center">
          
          <table className="table table-borderless" style={{fontSize:"12px"}}>
            <thead>
              <tr>
                <th>Nomor PO</th>
                <th>Tanggal</th>
                <th>Info Integrasi</th>
                <th>No. Order eLOG</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, i)=>(
              <tr>
                <td>{item?.pma_po_number}</td>
                <td>{moment(item?.current_start_date).utc().format("DD/MM/YYYY")}</td>
                <td>{mapingInfoIntegrasi(item?.type)}</td>
                <td className="fw-bold" style={{color:"#FF1D8C", textDecoration:"underline", cursor:"pointer"}}>{item?.trx_order?.order_number}</td>
              </tr>
              ))}
            </tbody>
          </table>
          {!data?.length ? <h6 className="text-muted text-center my-5">- - - Data Tidak Tersedia - - -</h6> : null}
        </div>
      </Modal>
    </>
    )
}

export default ModalInfoIntegrasi;
