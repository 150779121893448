/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useState } from "react";
import { Col, Dropdown, Modal, Row } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import PropTypes from "prop-types";
import {
  FiMinus,
  FiPlus,
  FiPlusCircle,
  FiSearch,
  FiTrash2,
} from "react-icons/fi";
import { Button, Input, InputNumber } from "antd";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { formatThousand } from "../../../../helper/formatCurrency";
import { NumericFormat } from "react-number-format";
import "./style.scss";

const Kontrak = (props) => {
  /*======================== Props ======================== */

  const { isOpen, initialValues, handleClose, handleSubmit } = props;

  /*======================== Hooks ======================== */

  /*======================== Redux ======================== */

  const routeData = useSelector((st) => st.ruteReducer.all);
  const vehicleData = useSelector((st) => st.vehicleReducer.subtype.data);

  /*======================== UseState ======================== */

  const [routeOpts, setRouteOpts] = useState([]);
  const [vehicleOpts, setVehicleOpts] = useState([]);

  const [search, setSearch] = useState("");
  const [route, setRoute] = useState(undefined);
  const [items, setItems] = useState([
    {
      vehicle: {},
      budget: "",
      quantity: "",
    },
  ]);
  /*======================== Handler ======================== */

  const handleRoute = (value) => {
    setRoute(value);
  };

  const handleItems = (idx, value) => {
    const temp = items;
    temp[idx] = value;

    setItems([...temp]);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleBudget = (is_text) => {
    const total = items.reduce(
      (old, current) => old + Number(current.budget) * Number(current.quantity),
      0
    );
    if (is_text) {
      if (total) {
        return formatThousand(total);
      } else {
        return 0;
      }
    } else {
      return total;
    }
  };

  const handleAddCard = () => {
    setItems((old) => [
      ...old,
      {
        vehicle: {},
        budget: "",
        quantity: 0,
      },
    ]);
  };

  const handleReset = () => {
    setSearch("");
    setRoute(undefined);
    setItems([
      {
        vehicle: {},
        budget: "",
        quantity: 0,
      },
    ]);
  };

  const handleRemoveItem = (index) => {
    const temp = items;
    temp.splice(index, 1);
    setItems([...temp]);
  };

  const handleOnSubmit = () => {
    handleSubmit({
      id: initialValues?.id || "",
      route,
      vehicles: items,
      total_budget: handleBudget(false),
      is_update: initialValues?.id?.length > 10 ? true : false,
      is_new: initialValues?.id?.length > 10 ? false : true,
    });
    handleReset();
  };

  const handleOnClose = () => {
    handleReset();
    handleClose();
  };

  const handleDisabledSubmit = () => {
    if (!route || handleBudget(false) < 1) {
      return true;
    }
    return false;
  };
  /*======================== UseEffect ======================== */

  useEffect(() => {
    if (routeData?.route_address?.length > 0) {
      if (search) {
        setRouteOpts([
          ...routeData.route_address.filter(
            (item) =>
              item.delivery_name.toLowerCase().includes(search.toLowerCase()) ||
              item.pick_up_name.toLowerCase().includes(search.toLowerCase())
          ),
        ]);
      } else {
        setRouteOpts([...routeData.route_address]);
      }
    }
  }, [routeData, search]);

  useEffect(() => {
    if (vehicleData?.length > 0) {
      setVehicleOpts([...vehicleData]);
    }
  }, [vehicleData]);

  useEffect(() => {
    if (initialValues) {
      setRoute(initialValues.route);
      setItems([...initialValues.vehicles]);
    }
  }, [initialValues]);

  /*======================== Others ======================== */

  /*======================== Return ======================== */

  return (
    <Modal
      contentClassName="rounded-10 p-2 kontrak-form"
      size="lg"
      show={isOpen}
      onHide={handleOnClose}
      className="p-3"
      centered
    >
      <div className="d-flex align-items-center p-3 border-bottom">
        <h2 className="fw-bold mb-0">Buat Penawaran Kontrak</h2>
        <MdClose className="ms-auto me-2 pointer" onClick={handleOnClose} />
      </div>
      <Modal.Body>
        <Row>
          <Col md={12}>
            <h4 className="fw-bold">Rute Anda</h4>
            <div className="custom-dropdown card rounded-10">
              <Dropdown className="width-100">
                <Dropdown.Toggle id="dropdown-autoclose-true" size="lg">
                  {route?.route_id
                    ? `${route.pick_up_name} - ${route.delivery_name}`
                    : "Pilih Rute Anda"}
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu rounded-10 custom-scroll">
                  <div className="px-3 py-2">
                    <Input
                      size="large"
                      placeholder="Cari Rute Anda"
                      suffix={<FiSearch />}
                      className="rounded-10 custom-field-input"
                      onChange={(e) => {
                        handleSearch(e);
                      }}
                    />
                    <hr className="mb-0 mt-4" />
                  </div>
                  {routeOpts.map((item, index) => (
                    <div key={item.route_id}>
                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          handleRoute(item);
                        }}
                      >
                        {item.pick_up_name} - {item.delivery_name}
                      </Dropdown.Item>
                      {index !== routeOpts.length - 1 && (
                        <hr className="mx-3 my-2" />
                      )}
                    </div>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Col>
          <Col md={12}>
            <h4 className="fw-bold mt-3">Total Budget</h4>
            <Input
              size="large"
              disabled
              placeholder=""
              prefix={"Rp."}
              className="rounded-10 px-2 py-3 custom-field-input"
              value={handleBudget(true)}
            />
          </Col>
        </Row>
        <hr className="my-4" />
        <div>
          <h4 className="fw-bold">Kebutuhan Penawaran</h4>
          <div className="card-container custom-scroll">
            {items.map((item, index) => (
              <div key={index} className="bg-white item-card">
                <div
                  className="py-3 ps-4 width-100"
                  style={{ paddingRight: "102px" }}
                >
                  <div className="d-flex flex-wrap">
                    <Row style={{ minHeight: "58px" }}>
                      <Col xs={6}>
                        <div className="custom-dropdown card rounded-10">
                          <Dropdown className="width-100">
                            <Dropdown.Toggle
                              id="dropdown-autoclose-true"
                              size="lg"
                            >
                              {item.vehicle?.type_id
                                ? item.vehicle.name
                                : "Pilih Kendaraan"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu rounded-10 custom-scroll">
                              {vehicleOpts.map((vehicleItem, idx2) => (
                                <div key={idx2}>
                                  <Dropdown.Item
                                    href="#"
                                    onClick={() => {
                                      handleItems(
                                        index,
                                        {
                                          ...item,
                                          vehicle: vehicleItem,
                                        },
                                        true
                                      );
                                    }}
                                  >
                                    {vehicleItem.name}
                                  </Dropdown.Item>
                                  {idx2 !== vehicleOpts.length - 1 && (
                                    <hr className="mx-3 my-2" />
                                  )}
                                </div>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </Col>
                      <Col xs={3} className="">
                        <Input
                          size="large"
                          disabled
                          type="number"
                          placeholder=""
                          suffix={"kg"}
                          className="rounded-10 px-2 py-3 custom-field-input"
                          value={item.vehicle?.max_weight || 0}
                        />
                      </Col>
                      <Col xs={3} className="">
                        <Input
                          size="large"
                          disabled
                          type="number"
                          placeholder=""
                          suffix={"m3"}
                          className="rounded-10 px-2 py-3 custom-field-input"
                          value={item.vehicle?.volume || 0}
                        />
                      </Col>
                      <Col xs={6} className="mt-3">
                        <NumericFormat
                          value={item.budget}
                          onValueChange={(values) => {
                            handleItems(index, {
                              ...item,
                              budget: values.floatValue,
                            });
                          }}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          allowLeadingZeros={false}
                          allowNegative={false}
                          disabled={!item.vehicle.type_id}
                          prefix={"Rp."}
                          placeholder="(Rp) Price"
                          spellCheck="false"
                          className="rounded-10 height-100 px-2 py-3 custom-field-input"
                          customInput={Input}
                        />
                      </Col>
                      <Col
                        xs={3}
                        className="mt-3 d-flex align-items-center"
                        style={{ gap: ".5rem" }}
                      >
                        <Button
                          className="icon-counter"
                          shape="circle"
                          icon={<FiMinus />}
                          disabled={!item.vehicle.type_id}
                          onClick={() => {
                            if (item.quantity > 0) {
                              handleItems(index, {
                                ...item,
                                quantity: (item.quantity =
                                  Number(item.quantity) - 1),
                              });
                            }
                          }}
                        />
                        <Input
                          size="small"
                          // min={1}
                          type="number"
                          placeholder=""
                          className="rounded-10 height-100 px-2 py-3 custom-field-input"
                          disabled={!item.vehicle.type_id}
                          onChange={(e) => {
                            if (e.target.value > 0) {
                              handleItems(index, {
                                ...item,
                                quantity: e.target.value.replace(/^0+/, ""),
                              });
                            } else {
                              handleItems(index, {
                                ...item,
                                quantity: 0,
                              });
                            }
                          }}
                          value={item.quantity}
                        />
                        <Button
                          className="icon-counter"
                          shape="circle"
                          icon={<FiPlus />}
                          disabled={!item.vehicle.type_id}
                          onClick={() => {
                            handleItems(index, {
                              ...item,
                              quantity: (item.quantity =
                                Number(item.quantity) + 1),
                            });
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
                {items.length > 1 && (
                  <div
                    className="action-card delete"
                    onClick={() => handleRemoveItem(index)}
                  >
                    <FiTrash2 className="icon-trash" />
                  </div>
                )}
              </div>
            ))}

            {/* add items */}
            <div className="bg-white item-card">
              <div
                className="py-3 ps-4 width-100"
                style={{ paddingRight: "102px" }}
              >
                <div className="d-flex flex-wrap">
                  <Row style={{ minHeight: "58px" }}>
                    <Col xs={6}>
                      <div className="custom-dropdown card rounded-10">
                        <Input
                          size="large"
                          disabled
                          type="number"
                          placeholder="Pilih Kendaraan"
                          className="rounded-10 px-2 py-3 custom-field-input"
                        />
                      </div>
                    </Col>
                    <Col xs={3} className="">
                      <Input
                        size="large"
                        disabled
                        type="number"
                        placeholder="Tonase"
                        suffix={"kg"}
                        className="rounded-10 px-2 py-3 custom-field-input"
                      />
                    </Col>
                    <Col xs={3} className="">
                      <Input
                        size="large"
                        disabled
                        type="number"
                        placeholder="Volume"
                        suffix={"m3"}
                        className="rounded-10 px-2 py-3 custom-field-input"
                      />
                    </Col>
                    <Col xs={6} className="mt-3">
                      <Input
                        disabled
                        size="large"
                        type="number"
                        // placeholder="Price"
                        placeholder="(Rp) Price"
                        // prefix={"Rp."}
                        className="rounded-10 height-100 px-2 py-3 custom-field-input"
                      />
                    </Col>
                    <Col
                      xs={3}
                      className="mt-3 d-flex align-items-center"
                      style={{ gap: ".5rem" }}
                    >
                      <Button
                        disabled
                        className="icon-counter"
                        shape="circle"
                        icon={<FiMinus />}
                      />
                      <Input
                        disabled
                        size="large"
                        type="number"
                        placeholder="qty"
                        className="rounded-10 height-100 px-2 py-3 custom-field-input"
                      />
                      <Button
                        disabled
                        className="icon-counter"
                        shape="circle"
                        icon={<FiPlus />}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="action-card add" onClick={handleAddCard}>
                <FiPlusCircle className="icon-plus" />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <div className="footer">
        <Button
          size="sm"
          className="fw-bold py-2 px-4 rounded-pill text-capitalize custom-btn-text me-3"
          onClick={handleOnClose}
        >
          Batalkan
        </Button>
        <Button
          disabled={handleDisabledSubmit()}
          size="sm"
          className="fw-bold py-2 px-4 rounded-pill text-capitalize custom-btn-primary"
          onClick={handleOnSubmit}
        >
          Buat Penawaran Kontrak
        </Button>
      </div>
    </Modal>
  );
};

Kontrak.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
};

export default Kontrak;
