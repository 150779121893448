import React, { useState } from "react";
import {
  Modal
} from "react-bootstrap";


const ModalLayananTambahan = () => {
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
    <>
      <h6
        className="fw-bold"
        style={{color:"#FF1DBC", textDecoration:"underline", fontSize:"11px", cursor:"pointer"}}
        onClick={()=>handleShow()}
    >
        Lihat Detail
    </h6>
      
       <Modal
        contentClassName="rounded-card p-2"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton className="bg-white">
          <strong>Layanan Tambahan</strong>
        </Modal.Header>
        <div className="p-4">
          <div className="w-100 border rounded px-3 py-2 d-flex justify-content-between align-items-center mb-2">
            <h6 className="m-0">Maintenance Vehicle</h6>
            <h6 className="m-0">Rp. 100.000</h6>
          </div>
          <div className="w-100 border rounded px-3 py-2 d-flex justify-content-between align-items-center mb-2">
            <h6 className="m-0">Service Vehicle</h6>
            <h6 className="m-0">Rp. 750.000</h6>
          </div>
        </div>
      </Modal>
    </>
    )
}

export default ModalLayananTambahan;
