import React, { useState, useEffect } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { Checkbox } from "antd";
import { useSelector, useDispatch } from "react-redux";
import PickFleetGoods from "../../components/modalFleet/PickFleetGoods";
import PickupService from "../../components/modalFleet/PickupService";
import PickPartner from "../../components/modalPartner/PickPartner";
import PickHelper from "../../components/modalPartner/PickHelperSendGoods";
import InfoModalNormal from "../../components/modalInfo/infoNormal";
import PickInsurance from "../../components/modalPartner/PickInsurance";
import util from "../../helper/util";

// images
import iconService from "../../assets/images/icon-service.svg";

// api
import OrderSendApi from "../../api/order-send-api";
import { updateDetail } from "../../redux/action/kirimbarang_action";

const TabService = () => {
  const dispatch = useDispatch();
  const kirimBarangReducer = useSelector((st) => st.kirimbarangReducer);
  const loading = useSelector((st) => st.kirimbarangReducer.vType.isLoading);
  const pengambilan = useSelector((st) => st.kirimbarangReducer.pengambilan);
  const pengambilan_new = useSelector((st) => st.kirimbarangReducer.pengambilan_new);
  const pengiriman = useSelector((st) => st.kirimbarangReducer.pengiriman);
  const tipe = useSelector((st) => st.kirimbarangReducer.tipe);
  const detail_price = useSelector((st) => st.kirimbarangReducer.detail_price);
  const total_price = useSelector(
    (st) => st.kirimbarangReducer.detailOrder.total_price
  );
  const backToPickupAddress = useSelector(
    (st) => st.kirimbarangReducer.backToPickupAddress
  );
  const selectedVType = useSelector(
    (st) => st.kirimbarangReducer.selectedVType
  );
  const selectedPartnerService = useSelector(
    (st) => st.kirimbarangReducer.selectedPartnerService
  );
  const detailOrder = useSelector((st) => st.kirimbarangReducer.detailOrder);

  const contract = useSelector((st) => st.kirimbarangReducer.contract);
  const additionalData = useSelector((st) => st.kirimbarangReducer.additionalData);
  const deliveryProcurementRoute = useSelector((st) => st.kirimbarangReducer.deliveryProcurementRoute);

  const hitSummary = async (keyPayload) => {
    const api = new OrderSendApi();
    const { partner_service_id, total_helper, insurance_amount } = keyPayload;
    let payload = {};
    // arrange payload
    payload["send_category"] = tipe;
    payload["date"] =
      tipe === "INSTANT" ? new Date().toISOString() : kirimBarangReducer.date;
    payload["is_return"] = backToPickupAddress.toggled ? 1 : 0;
    payload["send_destination"] = [];

    // pengambilan
    for(let i = 0; i<pengambilan_new.length; i++){
      let pickupPayload = {};
      // console.log("bener array gak ===>",props.pengambilan_new[i])
      pickupPayload["destination_type"] = "PICKUP";
      pickupPayload["destination_order"] = i;
      pickupPayload["longitude"] = pengambilan_new[i].lng;
      pickupPayload["latitude"] = pengambilan_new[i].lat;
      pickupPayload["address"] = pengambilan_new[i].lokasi;
      pickupPayload["location_description"] = pengambilan_new[i].patokan;
      pickupPayload["cod_amount"] = null;
      pickupPayload["note"] = pengambilan_new[i].catatan;
      pickupPayload["contact_name"] = pengambilan_new[i].namaPenerima;
      pickupPayload["contact_phone_no"] = `0${pengambilan_new[i].nomorTelepon}`;
      pickupPayload["item_type"] = pengambilan_new[i].item_type;
      pickupPayload["place_id"] = pengambilan_new[i].place_id;
      pickupPayload["address_name"] = pengambilan_new[i].address_name;
      payload["send_destination"].push(pickupPayload);
    }

    // pengiriman
    pengiriman.forEach((eachData, i) => {
      let dropoffPayload = {};
      dropoffPayload["destination_type"] = "DROPOFF";
      dropoffPayload["destination_order"] = i + pengambilan_new.length;
      dropoffPayload["longitude"] = eachData.lng;
      dropoffPayload["latitude"] = eachData.lat;
      dropoffPayload["address"] = eachData.lokasi;
      dropoffPayload["location_description"] = eachData.patokan;
      dropoffPayload["cod_amount"] = eachData.nominalCod;
      dropoffPayload["note"] = eachData.catatan;
      dropoffPayload["contact_name"] = eachData.namaPenerima;
      dropoffPayload["contact_phone_no"] = `0${eachData.nomorTelepon}`;
      dropoffPayload["item_type"] = eachData.item_type;
      dropoffPayload["place_id"] = eachData.place_id;
      dropoffPayload["address_name"] = eachData.address_name;
      dropoffPayload["dropoff_reference"] = eachData.dropoff_reference;
      payload["send_destination"].push(dropoffPayload);
    });

    

    // back to pickup address
    if (backToPickupAddress.toggled) {
      let backAddressPayload = {};
      backAddressPayload["destination_type"] = "DROPOFF";
      backAddressPayload["destination_order"] = payload["send_destination"].length;
      backAddressPayload["longitude"] = backToPickupAddress.lng;
      backAddressPayload["latitude"] = backToPickupAddress.lat;
      backAddressPayload["address"] = backToPickupAddress.lokasi;
      backAddressPayload["location_description"] = backToPickupAddress.patokan;
      backAddressPayload["cod_amount"] = backToPickupAddress.nominalCod;
      // backAddressPayload["note"] = backToPickupAddress.catatan;
      backAddressPayload["contact_name"] = backToPickupAddress.namaPenerima;
      backAddressPayload["contact_phone_no"] = `0${backToPickupAddress.nomorTelepon}`;
      // backAddressPayload["item_type"] = backToPickupAddress.item_type;
      backAddressPayload["place_id"] = backToPickupAddress.place_id;
      backAddressPayload["address_name"] = backToPickupAddress.address_name;
      payload["send_destination"].push(backAddressPayload);
    }

    payload["sub_type_id"] = selectedVType.id;
    payload["partner_service_id"] = partner_service_id
      ? partner_service_id
      : selectedPartnerService.id;
    payload["total_helper"] = total_helper
      ? total_helper
      : detailOrder.total_helper_requested;
    payload["insurance_fee"] = detailOrder?.insurance_fee;
    payload["insurance_fee_id"] = detailOrder?.insurance_fee_id;

    if (contract && contract.id !== "1") {
      payload["tender_id"] = contract.id;
      payload["partner_id"] = selectedPartnerService.partner_id;
      payload["delivery_procurement_route_id"] = deliveryProcurementRoute.id;
    }

    if (additionalData && additionalData.noTF) {
      payload["dispatch_note"] = {
        noTF: additionalData.noTF,
        file: getAdditionalFilePayload(additionalData.file),
      };
    }

    if (selectedVType.id && (partner_service_id || selectedPartnerService.id)) {
      const { data: summary } = await api.getOrderSummary(payload);
      // console.log("SUMMARY", summary);
      dispatch(
        updateDetail({
          detailOrder: {
            ...detailOrder,
            total_km: summary?.total_km,
            eta: summary?.eta,
          },
          detail_price: { ...summary?.detail_price },
        })
      );
    }
  };

  const getAdditionalFilePayload = (items) => {
    let data = [];
    items.forEach((item, i) => {
      data.push({
        document_name: item.document_name,
        document_path: item.document_path,
      });
    });

    return data;
  };

  const showAdditionalInfo = () => {
    let component = [];
    if (
      selectedPartnerService && 
      selectedPartnerService.trx_delivery_partner_offer &&
      selectedPartnerService.trx_delivery_partner_offer.vehicle_offer[0].additional_fee && 
      selectedPartnerService.trx_delivery_partner_offer.vehicle_offer[0].additional_fee.length > 0) {
        selectedPartnerService.trx_delivery_partner_offer.vehicle_offer[0].additional_fee.forEach((val, i) => {
          component.push(
            <Card className="border-0 mb-1" key={i}>
              <Row className="card-body py-2">
                <Col xs={9} className="align-self-center">
                  <p className="fw-bold mb-0 text-capitalize">{val.type}</p>
                </Col>
                <Col xs={3} className="align-self-center text-end">
                  <h6 className="mb-0 fw-bold">Rp. {util.thousandSeparatorPure(val.fee)}</h6>
                </Col>
              </Row>
            </Card>
          )
        })
        return component;
      } else {
        return (
          <Card className="border-0 mb-1">
            <Row className="card-body py-2">
              <Col xs={12} className="align-self-center">
                <p className="fw-bold mb-0 text-capitalize">Tidak Ada Layanan Tambahan</p>
              </Col>
            </Row>
          </Card>
        )
      }
  }
  
  // console.log({ detail_price });
  // console.log(selectedPartnerService);
  return (
    <>
      <Card.Body className="pt-0 scrollable">
        <h5 className="fw-light mb-2">Pilih Armada Pengiriman</h5>
        <div className="rc-ice p-2 mb-2">
          <PickFleetGoods />
          {/* <PickupService /> */}
        </div>
        <h5 className="fw-light mt-3 mb-2">Pilih Partner Pengiriman</h5>
        <div className="rc-ice p-2 mb-2">
          <PickPartner hitSummary={hitSummary} />
        </div>
        <h5 className="fw-light mt-3 mb-2">
        {contract && contract.id === "1" ? (
          <>
            Pilih Layanan Tambahan <InfoModalNormal />
          </>
        ) : (
          <>
            Layanan Tersedia
          </>
        )}
        </h5>
        <div className="rc-ice p-2 mb-2">
        {contract && contract.id === "1" && (
          <Card className="border-0 mb-1 opacity-50">
            <Row className="card-body py-2">
              <Col xs={1} className="align-self-center text-center">
                <Checkbox defaultChecked disabled/>
              </Col>
              <Col xs={9} className="align-self-center">
                <p className="fw-bold mb-0">Bantuan Supir</p>
                <h6 className="mb-0 text-muted">
                  Bantu bongkar/muat maksimal dengan jarak 20 meter.
                </h6>
              </Col>
              <Col xs={2} className="align-self-center text-end">
                <h6 className="mb-0 text-success">Gratis</h6>
              </Col>
            </Row>
          </Card>
         )}
          {contract && contract.id === "1" && (
            <PickHelper
              hitSummary={hitSummary}
              disabled={
                !selectedPartnerService?.mst_partner_service_pricing
                  ?.helper_price_send ||
                !selectedPartnerService?.mst_partner_service_pricing
                  ?.helper_availability_send
                  ? true
                  : false
              }
            />
          )}
          {contract && contract.id === "1"  && (
            <PickInsurance
              disabled={selectedPartnerService?.id ? false : true}
              hitSummary={hitSummary}
            />
          )}

          {contract && contract.id !== "1" && (
            <>
              {showAdditionalInfo()}
            </>
          )}
        </div>
      </Card.Body>
      <Card.Footer className="bg-0 shadow-top border-top-0">
        <div className="px-1 mt-3 d-flex align-items-center justify-content-between">
          <h4>Harga</h4>
          <h4 className="fw-bold">
            Rp{" "}
            {util.thousandSeparatorPure(Math.ceil(detail_price?.final_price)) ||
              "0"}
          </h4>
        </div>
        {/* <Row className="px-1 mt-2">
          <Col md={6}>
            <h4>Harga</h4>
          </Col>
          <Col md={6} className="text-end">
            <h4 className="fw-bold">
              Rp {util.thousandSeparatorPure(detail_price.final_price) || "0"}
            </h4>
          </Col>
        </Row> */}
      </Card.Footer>
    </>
  );
};
// class TabService extends Component {

//   render() {

//   }
// }

export default TabService;
