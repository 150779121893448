import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Avatar, Divider, Badge, message } from "antd";
import util from "../../helper/util";
import { resetAuth, setAuth } from "../../redux/action/auth_action";
import ProfileApi from "../../api/profile-api";
import AccountApi from "../../api/auth-api";
import useBreakpoint from "../../components/responsiveUtils/useBreakpoint";
import {
  hideSidenavMobile,
  openSidenavMobile,
  toggleMarginTopNav,
} from "../../components/layouts/layout_helper";

// icons
import { MdEdit, MdChevronRight } from "react-icons/md";
import { AiOutlineUser } from "react-icons/ai";
import icon_wallet from "../../assets/images/icon_wallet.svg";
import icon_bell from "../../assets/images/icon_bell.svg";
import { IoMenuOutline, IoChevronDown, IoChevronUp } from "react-icons/io5";

const SideInfo = () => {
  const dispatch = useDispatch();
  const auth = useSelector((st) => st.authReducer);
  const notif = useSelector((st) => st.notifReducer);
  const history = useHistory();
  const propicInput = useRef(null);
  const { isMobile, isTablet, isDesktop } = useBreakpoint();
  const [toggleMenu, setToggleMenu] = useState(true);

  const onChangeProfilePicture = async (ev) => {
    try {
      const accountApi = new AccountApi();
      const profileApi = new ProfileApi();

      const fd = new FormData();

      fd.append("profile_picture", ev.target.files[0]);

      const result = await accountApi.checkToken(auth.refresh_token);
      if (result.status === "success") {
        const profileRes = await profileApi.updatePropic(fd, auth.access_token);

        if (profileRes.status === "success") {
          message.success(profileRes.message);
          const { data } = await accountApi.getDetailCustomer(
            auth.access_token
          );

          dispatch(setAuth({ ...auth, ...data }));

          // setIsSubmit(true);
        } else {
          message.error(profileRes.message);
        }
      } else {
        history.push("/login");
        dispatch(resetAuth());
      }
    } catch (error) {
      console.log(error.response);
    }
  };

  const getActiveSideInfoMenu = (pathParams) => {
    if (window.location.pathname === pathParams) return "side-info-menu-active";

    return null;
  };

  useEffect(() => {
    if (isMobile) hideSidenavMobile();
  }, [isMobile]);

  const headSection = () => {
    if (isDesktop)
      return (
        <>
          <div className="d-flex justify-content-center w-100 position-relative">
            <Avatar
              size={300}
              shape="square"
              className="rounded-card avatar-blue"
              src={auth?.profile_picture}
              icon={!auth.profile_picture && <AiOutlineUser />}
            ></Avatar>
            <Button
              className="rounded-circle position-absolute"
              style={{ bottom: "-5%", right: "20%" }}
              onClick={() => {
                propicInput.current.click();
              }}
              // ref="profile-picture"
            >
              <MdEdit />
            </Button>
            <input
              type="file"
              id="profile-picture"
              ref={propicInput}
              onChange={onChangeProfilePicture}
              hidden
            />
          </div>
          <div className="text-center w-100 mt-3">
            <h6 className="text-muted">
              Akun{" "}
              {auth.customer_type === "COMPANY" ? "Perusahaan" : "Personal"}
            </h6>
            <h2 className="fw-bold">{auth?.name}</h2>
            <h4
              className={`${
                auth?.status === "APPROVED" ? "text-success" : "text-warning"
              }`}
            >
              {auth?.status === "APPROVED"
                ? "Identitas Terverifikasi"
                : "Identitas Belum Terverifikasi"}
            </h4>
          </div>
        </>
      );

    if (isTablet)
      return (
        <>
          <Row className="">
            <Col
              md={4}
              className="d-flex justify-content-center position-relative"
            >
              <Avatar
                size={150}
                shape="square"
                className="rounded-card avatar-blue"
                src={auth?.profile_picture}
                icon={!auth.profile_picture && <AiOutlineUser />}
              ></Avatar>
              <Button
                className="rounded-circle position-absolute"
                style={{ bottom: "-5%", right: "20%" }}
                onClick={() => {
                  propicInput.current.click();
                }}
                // ref="profile-picture"
              >
                <MdEdit />
              </Button>
              <input
                type="file"
                id="profile-picture"
                ref={propicInput}
                onChange={onChangeProfilePicture}
                hidden
              />
            </Col>
            <Col className="flex-column justify-content-end d-flex w-100 mt-3">
              <h6 className="text-muted">
                Akun{" "}
                {auth.customer_type === "COMPANY" ? "Perusahaan" : "Personal"}
              </h6>
              <h2 className="fw-bold">{auth?.name}</h2>
              <h4
                className={`${
                  auth?.status === "APPROVED" ? "text-success" : "text-warning"
                }`}
              >
                {auth?.status === "APPROVED"
                  ? "Identitas Terverifikasi"
                  : "Identitas Belum Terverifikasi"}
              </h4>
            </Col>
          </Row>
        </>
      );

    if (isMobile)
      return (
        <>
          <div className="d-flex mt-4 ms-2">
            <IoMenuOutline
              onClick={openSidenavMobile}
              className="text-secondary pointer me-2"
              style={{ fontSize: 25 }}
            />

            <h4 className="mt-1">Pengaturan</h4>
          </div>
          <Row className="mt-2">
            <Col
              xs={5}
              className="d-flex justify-content-center position-relative"
            >
              <Avatar
                size={100}
                shape="square"
                className="rounded-card avatar-blue"
                src={auth?.profile_picture}
                icon={!auth.profile_picture && <AiOutlineUser />}
              ></Avatar>
              <Button
                className="rounded-circle position-absolute"
                style={{ bottom: "-5%", right: "20%" }}
                size="sm"
                onClick={() => {
                  propicInput.current.click();
                }}
                // ref="profile-picture"
              >
                <MdEdit />
              </Button>
              <input
                type="file"
                id="profile-picture"
                ref={propicInput}
                onChange={onChangeProfilePicture}
                hidden
              />
            </Col>
            <Col className="flex-column justify-content-end d-flex w-100 mt-3">
              <h6 className="text-muted">
                Akun{" "}
                {auth.customer_type === "COMPANY" ? "Perusahaan" : "Personal"}
              </h6>
              <h2 className="fw-bold">{auth?.name}</h2>
              <h4
                className={`${
                  auth?.status === "APPROVED" ? "text-success" : "text-warning"
                }`}
              >
                {auth?.status === "APPROVED"
                  ? "Identitas Terverifikasi"
                  : "Identitas Belum Terverifikasi"}
              </h4>
            </Col>
          </Row>
        </>
      );
  };

  return (
    // DESKTOP
    <div
      className={`${
        isMobile && "head-nav-settings-mobile"
      } rounded-card bg-white p-2`}
    >
      {headSection()}
      <hr className="mt-2 text-muted" />
      <div
        className={`d-flex align-items-center pointer px-3 p-2 side-info-menu `}
        onClick={() => history.push("/pengaturan/saldo")}
      >
        <img src={icon_wallet} alt="" />
        <h6 className="text-muted ms-2">Saldo</h6>
        <h6 className="fw-bold ms-auto">
          Rp {util.thousandSeparatorPure(auth.balance)}
        </h6>
        <MdChevronRight className="" style={{ marginTop: "-10px" }} />
      </div>
      <div
        className="d-flex align-items-center pointer px-3 p-2 side-info-menu"
        onClick={() => history.push("/pengaturan/notifikasi")}
      >
        <img src={icon_bell} alt="" className="ms-1 mb-1" />
        <h6 className="text-muted ms-3">Notifikasi</h6>
        <div
          className="d-flex ms-auto align-items-center "
          style={{ marginTop: "-10px" }}
        >
          <Badge
            count={notif.total_unread}
            style={{ backgroundColor: "#ce1771" }}
            className=""
          />
          <MdChevronRight className="" />
        </div>
      </div>
      <hr className="mt-2 text-muted" />
      <div hidden={isMobile ? toggleMenu : false}>
        <div
          className={`d-flex align-items-center pointer px-3 p-2 side-info-menu ${getActiveSideInfoMenu(
            "/pengaturan/profile"
          )} `}
          onClick={() => history.push("/pengaturan/profile")}
        >
          <h6 className="text-muted ">Profil</h6>
          <div
            className="d-flex ms-auto align-items-center "
            style={{ marginTop: "-10px" }}
          >
            <MdChevronRight className="" />
          </div>
        </div>
        {/* <div
        className={`d-flex align-items-center pointer px-3 p-2 side-info-menu ${getActiveSideInfoMenu(
          "/pengaturan/saldo"
        )}`}
        onClick={() => history.push("/pengaturan/saldo")}
      >
        <h6 className="text-muted ">Pengaturan Saldo</h6>
        <div
          className="d-flex ms-auto align-items-center "
          style={{ marginTop: "-10px" }}
        >
          <MdChevronRight className="" />
        </div>
      </div> */}
        <div className="d-flex align-items-center pointer px-3 p-2 side-info-menu">
          <h6 className="text-muted ">Daftar Alamat</h6>
          <div
            className="d-flex ms-auto align-items-center "
            style={{ marginTop: "-10px" }}
          >
            <MdChevronRight className="" />
          </div>
        </div>
        <div
          className={`d-flex align-items-center pointer px-3 p-2 side-info-menu ${getActiveSideInfoMenu(
            "/pengaturan/rekening"
          )}`}
          onClick={() => history.push("/pengaturan/rekening")}
        >
          <h6 className="text-muted ">Info Rekening</h6>
          <div
            className="d-flex ms-auto align-items-center "
            style={{ marginTop: "-10px" }}
          >
            <MdChevronRight className="" />
          </div>
        </div>
        {/* <div
        className={`d-flex align-items-center pointer px-3 p-2 side-info-menu ${getActiveSideInfoMenu(
          "/pengaturan/notifikasi"
        )}`}
        onClick={() => history.push("/pengaturan/notifikasi")}
      >
        <h6 className="text-muted ">Notifikasi</h6>
        <div
          className="d-flex ms-auto align-items-center "
          style={{ marginTop: "-10px" }}
        >
          <MdChevronRight className="" />
        </div>
      </div> */}
      </div>
      <div
        hidden={!isMobile}
        className=""
        onClick={() => {
          setToggleMenu(!toggleMenu);
          toggleMarginTopNav();
        }}
      >
        <h4 className="text-primary  text-center">
          Tampilkan Lebih {toggleMenu ? "Banyak" : "Sedikit"}{" "}
          <span>{toggleMenu ? <IoChevronDown /> : <IoChevronUp />}</span>
        </h4>
      </div>
    </div>
    // TABLET AND MOBILE
  );
};

export default SideInfo;
