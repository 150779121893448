import React, { useState } from "react";
import {
  Modal,
  Form,
  InputGroup,
  FloatingLabel,
  Button,
  Row,
  Col,
} from "react-bootstrap";
import InfoBar from "../infoBar/InfoBar";
import PlacesAutocomplete from "react-places-autocomplete";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { resetAuth, setAuth } from "../../redux/action/auth_action";
import ProfileApi from "../../api/profile-api";
import AccountApi from "../../api/auth-api";
import { message } from "antd";
import useBreakpoint from "../responsiveUtils/useBreakpoint";

// icons
import icon_pin_colored from "../../assets/images/icon_pin_colored.svg";
import { MdChevronRight, MdClose } from "react-icons/md";
import ProfileMap from "../gMap/ProfileMap";
import { FaSearch, FaMapMarkerAlt } from "react-icons/fa";

const Address = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const auth = useSelector((st) => st.authReducer);
  const { isMobile, isTablet, isDesktop } = useBreakpoint();

  const [show, setShow] = useState(false);
  const [address, setaddress] = useState("");
  const [centerMap, setCenterMap] = useState([-6.2087634, 106.845599]);
  const [body, setBody] = useState({
    address: "",
  });

  const openModal = () => {
    setShow(true);
  };

  const closeModal = () => {
    setShow(false);
  };

  const steps = [
    {
      id: 1,
      name: "main menu",
    },
    {
      id: 2,
      name: "pick pinpoint",
    },
  ];

  const [activeStep, setActiveStep] = useState({
    id: 1,
    name: "main menu",
  });

  const handleNext = () => {
    setActiveStep(steps[1]);
  };

  const handleBack = () => {
    setActiveStep(steps[0]);
  };

  const handleChange = (address) => {
    setaddress(address);
  };

  const handleSelect = async (address, placeId, suggestion) => {};

  const searchOptions = {
    componentRestrictions: { country: ["idn"] },
  };

  const handleSubmit = async () => {
    try {
      const accountApi = new AccountApi();
      const profileApi = new ProfileApi();

      const result = await accountApi.checkToken(auth.refresh_token);
      if (result.status === "success") {
        const profileRes = await profileApi.updateAddress(
          { ...body },
          auth.access_token
        );

        if (profileRes.status === "success") {
          message.success(profileRes.message);
          const { data } = await accountApi.getDetailCustomer(
            auth.access_token
          );

          dispatch(setAuth({ ...auth, ...data }));

          // setIsSubmit(true);
          closeModal();
        } else {
          closeModal();
          message.error(profileRes.message);
        }
      } else {
        history.push("/login");
        dispatch(resetAuth());
      }
    } catch (error) {
      console.log(error.response);
    }
  };

  return (
    <>
      <div
        className="d-flex align-items-center pointer  px-2"
        onClick={openModal}
      >
        <img src={icon_pin_colored} width={25} height={25} alt="" />
        <div className="ms-3">
          <h6 className="text-muted ">Alamat</h6>
          <h5>{auth.address || "Belum memasukan data"}</h5>
        </div>
        <div
          className="d-flex ms-auto align-items-center "
          style={{ marginTop: "-10px" }}
        >
          <MdChevronRight className="" />
        </div>
      </div>
      <Modal
        contentClassName="rounded-card p-2"
        show={show}
        onHide={closeModal}
        className="p-3"
        centered
      >
        <div className="d-flex p-2">
          <p className="fw-bold">Alamat</p>
          <MdClose className="ms-auto me-2 pointer" onClick={closeModal} />
        </div>
        <Modal.Body>
          <div className="">
            {activeStep.id === 1 ? (
              <>
                <InputGroup className="border rounded-input mt-2">
                  <InputGroup.Text
                    className="border-0 rounded-input"
                    style={{ backgroundColor: "#f8f9fa" }}
                  >
                    <img src={icon_pin_colored} alt="" />
                  </InputGroup.Text>
                  <FloatingLabel
                    className="rounded-input"
                    label="Alamat Lengkap"
                    style={{
                      fontSize: "12px",
                      width: isMobile ? "80%" : "90%",
                    }}
                  >
                    <Form.Control
                      type={"text"}
                      as="textarea"
                      className="border-0 rounded-input"
                      placeholder="m"
                      defaultValue={auth?.address}
                      onChange={(ev) => {
                        setBody({ ...body, address: ev.target.value });
                      }}
                      style={{ height: "50px" }}
                    ></Form.Control>
                  </FloatingLabel>
                </InputGroup>
                <Row>
                  {/* <Col xs={7}>
                    <InputGroup className="border rounded-input mt-2">
                      <InputGroup.Text
                        className="border-0 rounded-input"
                        style={{ backgroundColor: "#f8f9fa" }}
                      >
                        <img src={icon_pin_colored} alt="" />
                      </InputGroup.Text>
                      <FloatingLabel
                        className="rounded-input"
                        label="Kota atau Kecamatan"
                        style={{ fontSize: "12px", width: "80%" }}
                      >
                        <Form.Control
                          type={"text"}
                          className="border-0 rounded-input"
                          placeholder="m"
                          style={{ height: "50px" }}
                        ></Form.Control>
                      </FloatingLabel>
                    </InputGroup>
                  </Col>
                  <Col>
                    <InputGroup className="border rounded-input mt-2">
                      <InputGroup.Text
                        className="border-0 rounded-input"
                        style={{ backgroundColor: "#f8f9fa" }}
                      >
                        <img src={icon_pin_colored} alt="" />
                      </InputGroup.Text>
                      <FloatingLabel
                        className="rounded-input"
                        label="Kode Pos"
                        style={{ fontSize: "12px", width: "60%" }}
                      >
                        <Form.Control
                          type={"text"}
                          className="border-0 rounded-input"
                          placeholder="m"
                          style={{ height: "50px" }}
                        ></Form.Control>
                      </FloatingLabel>
                    </InputGroup>
                  </Col> */}
                </Row>
                {/* <p className="fw-bold mt-2">Tambahkan Pinpoin (opsional)</p>
                <div
                  className="mt-2"
                  style={{ height: "100px", borderRadius: "20px" }}
                >
                  <ProfileMap />
                </div> */}
              </>
            ) : (
              <>
                <PlacesAutocomplete
                  searchOptions={searchOptions}
                  value={address}
                  onChange={handleChange}
                  onSelect={handleSelect}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div>
                      <InputGroup className="cstm-input">
                        <InputGroup.Text className="border-0">
                          <FaSearch />
                        </InputGroup.Text>
                        <FloatingLabel
                          label={"cari lokasi"}
                          style={{ width: "85%", fontSize: "12px" }}
                        >
                          <Form.Control
                            {...getInputProps({
                              placeholder: "cari lokasi",
                              className: "location-search-input",
                            })}
                            className="border-0"
                            type="text"
                            placeholder={"cari lokasi"}
                          />
                        </FloatingLabel>
                      </InputGroup>
                      {/* {console.log(suggestions)} */}
                      <div className="fs-13">
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion, i) => {
                          const className = suggestion.active
                            ? "p-2 active"
                            : "p-2";
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? { backgroundColor: "#fafafa", cursor: "pointer" }
                            : { backgroundColor: "#ffffff", cursor: "pointer" };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                              key={i}
                            >
                              <div>
                                <FaMapMarkerAlt className="text-muted" />{" "}
                                {suggestion.description}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
                <div
                  className="mt-2"
                  style={{ height: "250px", borderRadius: "20px" }}
                >
                  <ProfileMap center={centerMap} />
                </div>
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {activeStep.id === 1 ? (
            <>
              <p className="text-primary pointer me-4" onClick={closeModal}>
                Batalkan
              </p>
              <Button
                disabled={body?.address ? false : true}
                size="sm"
                variant="primary"
                className="float-end rounded-pill px-4 py-2"
                // style={{ paddingRight: "100px", paddingLeft: "100px" }}
                onClick={() => {
                  handleSubmit();
                }}
              >
                Simpan
              </Button>
            </>
          ) : (
            <>
              <p className="text-primary pointer me-4" onClick={handleBack}>
                Kembali
              </p>
              <Button
                size="sm"
                variant="primary"
                className="float-end rounded-pill px-4 py-2"
                onClick={() => {
                  handleSubmit();
                }}
              >
                Berikutnya
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Address;
