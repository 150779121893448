import React, { Component } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import moment from "moment";
import util from "../../helper/util";

// images
import iconClock from "../../assets/images/icon-clock.svg";
import iconMark from "../../assets/images/icon-marker-o.svg";
import iconService from "../../assets/images/icon-service.svg";
import iconTruck from "../../assets/images/icon-truck.svg";
import iconLine from "../../assets/images/icon-line.svg";

// icons
import { BsShieldFillCheck } from "react-icons/bs";

class TabSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const data = this.props.sewaKendaraanReducer;
    const startDate = data.startDate
      ? moment(data.startDate).format("dddd") +
        ", " +
        moment(data.startDate).format("DD/MMM/YYYY")
      : null;
    const endDate = data.endDate
      ? moment(data.endDate).format("dddd") +
        ", " +
        moment(data.endDate).format("DD/MMM/YYYY")
      : null;

    const totalDate = () => {
      var startDate = moment(this.props.sewaKendaraanReducer.startDate);
      var endDate = moment(this.props.sewaKendaraanReducer.endDate);
      var diff = moment.duration(endDate.diff(startDate));
      var total =
        Math.floor(diff.asWeeks()) + " Minggu, " + (diff.days() % 7) + " Hari.";

      return total;
    };
    return (
      <>
        <Card.Body className="pt-0">
          <h5 className="fw-light mb-2">Rangkuman Sewa</h5>
          <div className="rc-ice p-2 mb-2">
            <Card className="border-0 mb-1">
              <Row className="card-body py-2">
                <Col xs={2} className="align-self-start text-center">
                  <img src={iconClock} alt="" className="img-fluid" />
                </Col>
                <Col xs={10} className="align-self-center">
                  <p className="fw-bold mb-0">Sewa Terjadwal</p>
                  <div className="d-flex">
                    <img
                      src={iconLine}
                      alt=""
                      style={{ marginTop: "5px", height: "33px" }}
                    />
                    <h6 className="mb-0 text-muted mt-1 ms-2">
                      {startDate}
                      <span className="mt-2 d-block">{endDate}</span>
                    </h6>
                  </div>
                  <hr className="my-2" />
                  <h6 className="mb-0 text-muted">
                    Durasi Sewa: <b>{totalDate()}</b>
                  </h6>
                </Col>
              </Row>
            </Card>
            <Card className="border-0">
              <Card.Body className="smrry">
                <Row>
                  <Col xs={2} className="text-center">
                    <img src={iconMark} alt="" />
                  </Col>
                  <Col xs={10}>
                    <p className="fw-bold mb-0 text-dark">Data Pengambilan</p>
                    <h6 className="text-muted">{data.namaPenerima}</h6>
                    <h6 className="text-muted">+62{data.nomorTelepon}</h6>
                    <h6 className="text-muted">{data.lokasi}</h6>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <Card className="border-0 my-1">
              <Row className="card-body py-1">
                <Col xs={2} className="align-self-center text-center">
                  <img src={iconTruck} alt="" className="img-fluid" />
                </Col>
                <Col xs={10} className="align-self-center">
                  <p className="fw-bold mb-0">Informasi Mitra</p>
                  <h6 className="mb-0 text-muted">
                    Jenis Kendaraan - {data.selectedVType.name}
                  </h6>
                  <h6 className="mb-0 text-muted">
                    Mitra Layanan -{" "}
                    {data.selectedPartnerService.mst_partner.name}{" "}
                    <span className="ms-2 text-success">
                      Terproteksi <BsShieldFillCheck />
                    </span>
                  </h6>
                </Col>
              </Row>
            </Card>
            <Card className="border-0 my-1">
              <Row className="card-body py-1">
                <Col xs={2} className="align-self-center text-center">
                  <img src={iconService} alt="" className="img-fluid" />
                </Col>
                <Col xs={10} className="align-self-center">
                  <p className="fw-bold mb-0">Layanan Tambahan</p>
                  <h6 className="mb-0 text-muted">Helper</h6>
                </Col>
              </Row>
            </Card>
          </div>
        </Card.Body>
        <Card.Footer className="bg-0 shadow-top border-top-0">
          <div className="px-1 mt-3 d-flex align-items-center justify-content-between">
            <h4>Harga</h4>
            <h4 className="fw-bold">
              Rp{" "}
              {util.thousandSeparatorPure(
                parseInt(this.props.totalPrice.final_price || 0)
              ) || "-"}
            </h4>
          </div>
          {/* <Row className="px-1 mt-3">
            <Col md={6}>
              <h4>Harga</h4>
            </Col>
            <Col md={6} className="text-end">
              <h4 className="fw-bold">
                Rp{" "}
                {parseInt(this.props.totalPrice.final_price || 0)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
              </h4>
            </Col>
          </Row> */}
        </Card.Footer>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  sewaKendaraanReducer: state.sewaKendaraanReducer,
  detailOrder: state.sewaKendaraanReducer.detailOrder,
  totalPrice: state.sewaKendaraanReducer.totalPrice,
});
export default connect(mapStateToProps)(TabSummary);
