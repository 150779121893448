const initialState = {
  data: [],
  meta: {},
  all: [],
  loading: false,
};

export const ruteReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "SET_RUTE":
      return { ...state, ...payload };
    case "RESET_RUTE":
      return { ...state, data: [], meta: {}, all: [] };
    default:
      return state;
  }
};
