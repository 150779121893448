import React, { useState, useEffect } from "react";
import { Calendar } from "react-modern-calendar-datepicker";
import moment from "moment";

// Component
import {
  Modal,
  Card,
  Button
} from "react-bootstrap";

// Icons
import { FaCalendarAlt } from "react-icons/fa"


const Daterange = ({placeholder, dateFilter, handleDateFilter}) => {

  const [showModal, setShowModal] = useState(false)
  const [label, setLabel] = useState("");
  const [selectedDayRange, setSelectedDayRange] = useState({
    from: null,
    to: null,
  });


  // Handle Function
  // Modal
  const handleClose = () => setShowModal(false)
  const handleShow = () => setShowModal(true);
  // End Modal

  const handlePick = () => {
    let date = {
      from: {
        ...selectedDayRange.from,
        month: selectedDayRange.from?.month - 1,
      },
      to: { ...selectedDayRange.to, month: selectedDayRange.to?.month - 1 },
    };

    let label = `${moment({ ...date.from }).format("DD/MMM/YYYY")} s/d ${moment(
      { ...date.to }
    ).format("DD/MMM/YYYY")}`;
    setLabel(label);
    handleDateFilter({ ...date });
    handleClose();
  };

  useEffect(()=>{
    if(!dateFilter){
      setSelectedDayRange({
        from: null,
        to: null,
      })
    }
  },[dateFilter])


  return(
    <>
      {/* Select Header */}
      <div
        className="px-3 mb-2 d-flex justify-content-between align-items-center pointer shadow-sm"
        style={{
          borderRadius: "4px",
          backgroundColor: `${dateFilter ? "#F3F8F8" : "white"}`,
          border: `${dateFilter ? "1px solid #2BBECB" : "1px solid #E2E5E8"}`,
          paddingTop: "6px",
          paddingBottom: "5px",
        }}
        onClick={handleShow}
        >
        <h5
          className="m-0 fw-bold"
          style={{
            // color: `${dateFilter ? "#2BBECB" : "#BFBFBF"}`
            color: `black`
          }}
        >
          {dateFilter ? label : placeholder}
        </h5>
        <FaCalendarAlt style={{color:"#ff1d8c", marginLeft: "15px"}}/>
      </div>
      {/* END - Select Header */}

      {/* Modal */}
      <Modal
        contentClassName={`modal-80w p-1`}
        show={showModal}
        onHide={handleClose}
        centered
      >
      {/* Header */}
      <Modal.Header closeButton className="bg-white">
        <strong>Pilih Tanggal Transaksi</strong>
      </Modal.Header>
      {/* Body */}
      <Card.Body>
        <Calendar
          value={selectedDayRange}
          onChange={(data) => {
            setSelectedDayRange(data);
          }}
          colorPrimary="#27C0CD"
          colorPrimaryLight="#F3F8F8"
          shouldHighlightWeekends
        />
      </Card.Body>
      {/* Footer */}
      <Modal.Footer className="border-top-0 bg-ice">
        <Button
          size="sm"
          variant="light"
          className="fw-bold me-4"
          onClick={() => handleClose()}
        >
          Batal
        </Button>
        <Button
          size="sm"
          variant="primary"
          className="fw-bold px-3 rounded-pill"
          onClick={() => handlePick()}
        >
          Pilih
        </Button>
      </Modal.Footer>
    </Modal>
    {/* END - Modal */}
  </>
  )
}

export default Daterange;
