import moment from "moment";
import React, { useEffect, useState } from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { FiChevronLeft } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  createAlamat,
  getAlamatDetail,
  updateAlamat,
} from "../../../../redux/action/RuteAlamat/alamat_action";
import PinPointMap from "./Modal/PinPointMap";

const AlamatPengiriman = () => {
  /*======================== Hooks ======================== */

  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  /*======================== Redux ======================== */

  const loading = useSelector((st) => st.alamatReducer.loading);
  const customer_id = useSelector((st) => st.authReducer.id);

  /*======================== UseState ======================== */

  const [isEdit, setIsEdit] = useState(false);
  const [formValue, setFormValue] = useState({
    code: "",
    name: "",
    complete_address: "",
    pic: "",
    phone_number: "",
    detail_location: "",
    notes_for_driver: "",
  });
  const [initialValues, setInitialValues] = useState({
    code: "",
    name: "",
    complete_address: "",
    pic: "",
    phone_number: "",
    detail_location: "",
    notes_for_driver: "",
    latitude: -6.2087634,
    longitude: 106.845599,
  });
  const [trigger, setTrigger] = useState(1);

  /*======================== Handler ======================== */

  const handleBack = () => {
    history.push("/pengaturan/rute-alamat", {
      section: "alamat pengiriman",
    });
  };

  const handleEdit = () => {
    if (isEdit) {
      setFormValue(initialValues);
      setTrigger((oldValue) => oldValue + 1);
    }
    setIsEdit((oldValue) => !oldValue);
  };

  const handleChange = (type, value) => {
    switch (type) {
      case "latLng":
        setFormValue({
          ...formValue,
          latitude: value.lat,
          longitude: value.lng,
        });
        break;

      case "phone_number":
        setFormValue({
          ...formValue,
          [type]: value,
        });
        break;

      default:
        setFormValue({
          ...formValue,
          [type]: value,
        });
        break;
    }
  };

  const handleSubmit = async () => {
    const payload = {
      ...formValue,
      customer_id,
      address_type: "DELIVERY",
      phone_number: "+62" + formValue.phone_number,
      address_usage: "Layanan Kirim Barang",
    };
    let response;
    if (params.id) {
      response = await dispatch(updateAlamat({ ...payload, id: params.id }));
    } else {
      response = await dispatch(createAlamat(payload));
    }
    if (response?.status === "success") {
      handleBack();
    }
  };

  /*======================== UseEffect ======================== */

  useEffect(() => {
    if (params.id) {
      async function getDetail() {
        const response = await dispatch(getAlamatDetail(params.id));
        const data = {
          code: response.code,
          name: response.name,
          complete_address: response.complete_address,
          pic: response.pic,
          phone_number: response.phone_number.slice(3),
          detail_location: response.detail_location,
          notes_for_driver: response.notes_for_driver,
          latitude: response.latitude,
          longitude: response.longitude,
        };
        setFormValue(data);
        setInitialValues(data);
      }

      getDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  /*======================== Return ======================== */

  return (
    <div
      style={{ minHeight: "100vh", width: "100%", padding: "30px" }}
      className="bg-ice custom-form height-100"
    >
      <Card
        className="p-4 rounded-card border-0"
        style={{ backgroundColor: "white" }}
      >
        <div className="d-flex flex-row align-items-center">
          <FiChevronLeft
            fontSize={25}
            className="pointer me-3"
            onClick={handleBack}
          />
          <div>
            {params.id ? (
              <div>
                <h1 className="mb-0">{isEdit ? 'Ubah' : 'Detail'} Alamat Pengiriman</h1>
                <span className="fs-12">
                  {initialValues.code} - {initialValues.name}
                </span>
                <p className="mb-0 mt-1">
                  Dibuat tanggal:{" "}
                  {moment(initialValues.created_at).format("DD/MM/YYYY")}
                </p>
              </div>
            ) : (
              <>
                <h1>Tambah Alamat Pengiriman</h1>
                <p className="mb-0">
                  Atur alamat pengiriman untuk memudahkan anda melakukan order
                </p>
              </>
            )}
          </div>
          {params.id && (
            <Button
              size="sm"
              className="fw-bold py-2 px-4 rounded text-capitalize float-end ms-auto"
              onClick={handleEdit}
            >
              {isEdit ? "Batalkan" : "Ubah data"}
            </Button>
          )}
        </div>
      </Card>
      <Row className="mt-4">
        <Col xs={12} md={6} className="mt-2">
          <div>
            <h5 className="fw-bold">Informasi Akun</h5>
            <div className="bg-white rounded-card w-full p-3">
              <Row>
                <Col xs={6}>
                  <div className="px-2">
                    <p className="text-muted label mb-0">kode alamat</p>
                    <Form.Control
                      disabled={params.id || loading}
                      className="border-0 ps-0 pt-1"
                      placeholder="Tulis kode alamat"
                      onChange={(event) => {
                        handleChange("code", event.target.value);
                      }}
                      defaultValue={formValue.code}
                    ></Form.Control>
                  </div>
                </Col>
                <Col xs={6}>
                  <div className="px-2">
                    <p className="text-muted label mb-0">nama alamat</p>
                    <Form.Control
                      disabled={params.id && !isEdit}
                      className="border-0 ps-0 pt-1"
                      placeholder="Tulis nama alamat"
                      onChange={(event) => {
                        handleChange("name", event.target.value);
                      }}
                      defaultValue={formValue.name}
                    ></Form.Control>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div>
            <h5 className="fw-bold mt-3">Alamat Lengkap</h5>
            <div className="bg-white rounded-card w-full p-3">
              <div className="px-2">
                <p className="text-muted label mb-0">alamat lengkap</p>
                <Form.Control
                  disabled={params.id && !isEdit}
                  as="textarea"
                  className="border-0 ps-0 pt-1"
                  placeholder="Tulis alamat lengkap"
                  onChange={(event) => {
                    handleChange("complete_address", event.target.value);
                  }}
                  defaultValue={formValue.complete_address}
                  style={{ height: "70px" }}
                ></Form.Control>
              </div>
            </div>
          </div>
          <div>
            <h5 className="fw-bold mt-3">Pin Point Alamat</h5>
            <div className="bg-white rounded-card w-full p-3">
              <PinPointMap
                {...{ handleChange, trigger, initialValues }}
                showForm={!params.id || (params.id && isEdit)}
              />
            </div>
          </div>
        </Col>
        <Col xs={12} md={6} className="mt-2">
          <div>
            <h5 className="fw-bold">Data PIC Alamat</h5>
            <div className="bg-white rounded-card w-full p-3">
              <Row>
                <Col xs={6}>
                  <div className="px-2">
                    <p className="text-muted label mb-0">nama PIC</p>
                    <Form.Control
                      disabled={params.id && !isEdit}
                      className="border-0 ps-0 pt-1"
                      placeholder="Tulis nama PIC"
                      onChange={(event) => {
                        if (/^[a-zA-Z ]*$/g.test(event.target.value)) {
                          handleChange("pic", event.target.value);
                        }
                      }}
                      value={formValue.pic}
                    ></Form.Control>
                  </div>
                </Col>
                <Col xs={6}>
                  <div className="px-2">
                    <p className="text-muted label mb-0">no. Handphone PIC</p>
                    <div className="d-flex align-items-center">
                      <span className="ms-2 fw-bold fs-13 me-2">+62</span>
                      <Form.Control
                        disabled={(params.id && !isEdit) || loading}
                        type="number"
                        className="border-0 ps-0 pt-1"
                        placeholder="Tulis No Handphone"
                        onChange={(event) => {
                          if (event.target.value.length < 13) {
                            handleChange("phone_number", event.target.value);
                          }
                        }}
                        value={formValue.phone_number}
                      ></Form.Control>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div>
            <h5 className="fw-bold mt-3">Patokan Alamat</h5>
            <div className="bg-white rounded-card w-full p-3">
              <div className="px-2">
                <p className="text-muted label mb-0">detail lokasi / alamat</p>
                <Form.Control
                  disabled={params.id && !isEdit}
                  as="textarea"
                  className="border-0 ps-0 pt-1"
                  placeholder="Tulis Detail Lokasi / Alamat"
                  onChange={(event) => {
                    handleChange("detail_location", event.target.value);
                  }}
                  defaultValue={formValue.detail_location}
                  style={{ height: "70px" }}
                ></Form.Control>
              </div>
            </div>
          </div>
          <div>
            <h5 className="fw-bold mt-3">Catatan untuk pengemudi (Optional)</h5>
            <div className="bg-white rounded-card w-full p-3">
              <div className="px-2">
                <p className="text-muted label mb-0">tulis catatan</p>
                <Form.Control
                  disabled={params.id && !isEdit}
                  as="textarea"
                  className="border-0 ps-0 pt-1"
                  placeholder="Tulis Catatan untuk pengemudi"
                  onChange={(event) => {
                    handleChange("notes_for_driver", event.target.value);
                  }}
                  defaultValue={formValue.notes_for_driver}
                  style={{ height: "100px" }}
                ></Form.Control>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      {(!params.id || (params.id && isEdit)) && (
        <Button
          size="sm"
          className="fw-bold py-2 px-4 rounded-pill text-capitalize float-end mt-4"
          disabled={Boolean(
            !formValue.code ||
              !formValue.complete_address ||
              !formValue.detail_location ||
              !formValue.name ||
              !formValue.pic ||
              !formValue.phone_number ||
              !formValue.latitude ||
              !formValue.longitude ||
              loading
          )}
          onClick={handleSubmit}
        >
          Simpan alamat
        </Button>
      )}
    </div>
  );
};

export default AlamatPengiriman;
