import React from "react";
import { Col } from "react-bootstrap";
import moment from "moment";
import ModalLayananTambahan from "../Modal/ModalLayananTambahan";
import ModalInfoPengantaranMutasi from "../Modal/ModalInfoPengantaranMutasi";
import {
    getStatusRent,
    getStatusRentColor
} from "../../../../helper/trx_status_helper";


const InfoPesanan = ({trxDetail, infoPengantaranMutasi}) => {

    const startDate = trxDetail?.data?.trx_booking_rent?.pickup_time ?
        `${moment(trxDetail?.data?.trx_booking_rent?.pickup_time).format("DD MMM YYYY HH:mm")}`:
    null;

    const endDate = trxDetail?.data?.trx_booking_rent?.date_to_return ?
        `${moment(trxDetail?.data?.trx_booking_rent?.date_to_return).format("DD MMM YYYY HH:mm")}` :
    null;


    const getStatusKendaraan = (val) => {
        if(val === 0){
            return "Kendaraan Normal"
        }else if(val === 1){
            return "Ganti Sementara"
        }else if(val === 2){
            return "Ganti Tetap"
        }else{
            return "-"
        }
    }

    const getColorStatusKendaraan = (val) => {
        if(val === 0){
            return "#0FAA4B"
        }else if(val === 1){
            return "#FFAC14"
        }else if(val === 2){
            return "#FFAC14"
        }else{
            return "#6C757D"
        }
    }

    console.log(trxDetail)

    const dataInfoPesanan = [
        {
            title: trxDetail?.data?.trx_booking_rent?.external_id ? "No PO" : "",
            value: trxDetail?.data?.trx_booking_rent?.external_id ? trxDetail?.data?.trx_booking_rent?.external_id : "" ,
            style: {}
        },
        {
            title: "Status Pesanan",
            value: getStatusRent(trxDetail?.data?.trx_booking_rent?.rent_status),
            style: {color:getStatusRentColor(trxDetail?.data?.trx_booking_rent?.rent_status)}
        },
        {
            title: "Periode Pesanan",
            value: trxDetail?.data?.trx_booking_rent?.rent_day ? trxDetail?.data?.trx_booking_rent?.rent_day + " hari" : "-",
            style: {}
        },
        {
            title: "Waktu Pengantaran",
            value: startDate ? startDate + " WIB" : "-",
            style: {}
        },
        {
            title: "Waktu Pengembalian",
            value: endDate ? endDate + " WIB" : "-",
            style: {}
        },
        {
            title: "Mutasi Kendaraan",
            value: !trxDetail?.data?.trx_booking_rent?.trx_booking_rent_mutation?.length ? "Tidak" : <ModalInfoPengantaranMutasi trxDetail={trxDetail} infoPengantaranMutasi={infoPengantaranMutasi}/>,
            style: {color: trxDetail?.data?.trx_booking_rent?.is_mutation === 0 ? "#BBBBBB" : "#0FAA4B"}
        },
        {
            title: "Status Kendaraan",
            value: getStatusKendaraan(trxDetail?.data?.trx_booking_rent?.unit_type),
            style: {color:getColorStatusKendaraan(trxDetail?.data?.trx_booking_rent?.unit_type)}
        },
    ]

    const dataLayanan = [
        {
            title: "Layanan Driver",
            value: trxDetail?.data?.trx_booking_rent?.driver_required > 0 ? "Ya" : "Tidak",
            style: {color: trxDetail?.data?.trx_booking_rent?.driver_required ? "#0FAA4B" : "#BBBBBB"}
        },
        {
            title: "Layanan Helper",
            value: trxDetail?.data?.trx_booking_rent?.helper > 0 ? "Ya" : "Tidak",
            style: {color: trxDetail?.data?.trx_booking_rent?.helper ? "#0FAA4B" : "#BBBBBB"}
        },
        {
            title: "Layanan Tambahan",
            value: "Tidak", //<ModalLayananTambahan/>
            style: {color: trxDetail?.data?.trx_booking_rent?.helper ? "#BBBBBB" : "#0FAA4B"}
        },
    ]
    
    return(
        <Col className="rounded-card px-3 bg-white mb-3 mt-2 me-3 col-lg-3">
            <div className="pt-3 pb-2 border-bottom">
                <h5 className="fw-bold">Info Pesanan</h5>
            </div>

            {dataInfoPesanan.map((item, i) => (
                <div key={i} className="my-3">
                    <h6 className="mb-1 text-muted">
                        {item.title}
                    </h6>
                    <h6 className="fw-bold" style={item.style}>
                        {item.value}
                    </h6>
                </div>
            ))}

            {/* pembatas */}
            <div className="border-bottom"></div>

            {dataLayanan.map((item, i) => (
                <div key={i} className="my-3">
                    <h6 className="mb-1 text-muted">
                        {item.title}
                    </h6>
                    <h6 className="fw-bold" style={item.style}>
                        {item.value}
                    </h6>
                </div>
            ))}
        </Col>
    );
}

export default InfoPesanan;
