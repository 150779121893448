import axios from "axios";
import { storage } from "../redux/local_storage";
import { OpResult } from "../helper/operation_result";
import { message } from "antd";

class BaseApiRequest {
  constructor(endpoint) {
    this.endpoint = endpoint;
    this.token = undefined;
    this.config = undefined;
    //init token
    this.init();
  }

  init() {
    const store = storage.get(process.env.REACT_APP_LOCAL_STORAGE_NAME);
    if (store) {
      let username = store.username;
      if (username && store.token) {
        this.setConfig(store.token);
      }
    }
  }

  setConfig(token) {
    this.token = token;
    this.config = {
      headers: { authorization: `Bearer ${token}` },
      validateStatus: function (status) {
        return status >= 200 && status < 500;
      },
    };
  }

  resetLocalStorageAndRedirect() {
    storage.delete(process.env.REACT_APP_LOCAL_STORAGE_NAME);
  }

  async get(uri, token, params, headers) {
    try {
      const config = {
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
          Authorization: `Bearer ${token}`,
        },
      };

      if (true) {
        let response = await axios.get(this.endpoint + uri, config, { params });
        if (response.status >= 400 && response.status < 500) {
          if (response.status === 401) {
            this.resetLocalStorageAndRedirect();
          }

          let resp = response.data
            ? response.data
            : OpResult.failed(response.statusText);
          resp.code = response.status;
          return resp;
        } else {
          return response.data;
        }
      } else {
        this.resetLocalStorageAndRedirect();
      }
    } catch (error) {
      return OpResult.failed(`request failed`);
    }
  }

  async post(route, req_body, token) {
    try {
      let response = await axios.post(this.endpoint + route, req_body, {
        headers: { authorization: `Bearer ${token}` },
      });
      if (response.status >= 400 && response.status < 500) {
        if (response.status === 401) {
          this.resetLocalStorageAndRedirect();
        }

        let resp = response.data
          ? response.data
          : OpResult.failed(response.statusText);
        resp.code = response.status;
        return resp;
      } else {
        return response.data;
      }
    } catch (error) {
      return OpResult.failed(error?.response?.data?.message);
    }
  }

  async put(route, req_body) {
    try {
      let response = await axios.put(
        this.endpoint + route,
        req_body,
        this.config
      );

      if (response.status >= 400 && response.status < 500) {
        let resp = OpResult.failed(response.statusText);
        resp.code = response.status;
        return resp;
      } else {
        return response.data;
      }
    } catch (error) {
      return OpResult.failed(`request failed`);
    }
  }

  async patch(route, req_body, token) {
    const config = {
      headers: { authorization: `Bearer ${token}` },
    };
    try {
      let response = await axios.patch(this.endpoint + route, req_body, config);

      if (response.status >= 400 && response.status < 500) {
        let resp = OpResult.failed(response.statusText);
        resp.code = response.status;
        return resp;
      } else {
        return response.data;
      }
    } catch (error) {
      return OpResult.failed(error?.response?.data?.message);
    }
  }

  async download(route, req_body) {
    try {
      const config = { ...this.config, responseType: "blob" };
      if (this.token) {
        let response = await axios.post(
          this.endpoint + route,
          req_body,
          config
        );
        if (response.status >= 400 && response.status < 500) {
          if (response.status === 401) {
            this.resetLocalStorageAndRedirect();
          }
          let resp = response.data
            ? response.data
            : OpResult.failed(response.statusText);
          resp.code = response.status;
          return resp;
        } else {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          let link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            req_body.loan_id + "_" + req_body.document_type + ".pdf"
          );
          document.body.appendChild(link);
          link.click();
          return true;
        }
      } else {
        this.resetLocalStorageAndRedirect();
      }
    } catch (error) {
      return OpResult.failed(`request failed`);
    }
  }
}

export { BaseApiRequest };
