/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  FloatingLabel,
  Form,
  InputGroup,
  Modal,
} from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import PropTypes from "prop-types";

const RuteAlamatPengambilan = (props) => {
  /*======================== Props ======================== */

  const {
    isOpen,
    handleClose,
    handleSubmit,
    alamatPengambilan,
    editPengambilan,
    pickingList,
  } = props;

  /*======================== UseState ======================== */

  const [alamat, setAlamat] = useState(pickingList);
  const [search, setSearch] = useState(undefined);
  const [dataSelected, setDataSelected] = useState([]);

  /*======================== Handler ======================== */

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleDataSelected = (value) => {
    const temp = dataSelected.findIndex((item) => item.id === value.id);
    if (temp === -1) {
      setDataSelected((old) => [...old, value]);
    } else {
      setDataSelected((old) => [...old.filter((item) => item.id !== value.id)]);
    }
  };

  const handleShow = (value) => {
    if (alamatPengambilan.length < 1) {
      return true;
    } else {
      if (editPengambilan) {
        const temp = alamatPengambilan.findIndex(
          (item) => item.id === value.id
        );
        if (temp !== -1) {
          return true;
        }
      } else {
        const temp = alamatPengambilan.findIndex(
          (item) => item.id === value.id
        );
        if (temp === -1) {
          return true;
        }
      }
    }
  };

  const handleClassSelected = (value) => {
    const temp = dataSelected.findIndex((item) => item.id === value.id);
    if (temp === -1) {
      return "rounded-10 pointer";
    } else {
      return "rounded-10 pointer active";
    }
  };

  const handleOnClose = () => {
    handleClose();
    setSearch(undefined);
    setDataSelected([]);
  };

  const handleOnSelect = () => {
    handleClose();
    setSearch(undefined);
    if (!editPengambilan && alamatPengambilan.length > 0) {
      handleSubmit([...alamatPengambilan, ...dataSelected]);
    } else {
      handleSubmit(dataSelected);
    }
    setDataSelected([]);
  };

  /*======================== UseEffect ======================== */

  useEffect(() => {
    if (search) {
      setAlamat(
        pickingList.filter(
          (item) =>
            item.name.toLowerCase().includes(search.toLowerCase()) ||
            item.code.toLowerCase().includes(search.toLowerCase())
        )
      );
    } else {
      setAlamat(pickingList);
    }
  }, [search]);

  useEffect(() => {
    if (alamatPengambilan.length > 0 && editPengambilan) {
      setDataSelected(alamatPengambilan);
    }
  }, [alamatPengambilan, editPengambilan]);

  /*======================== Return ======================== */

  return (
    <Modal
      contentClassName="rounded-10 p-2"
      show={isOpen}
      onHide={handleOnClose}
      className="p-3"
      centered
    >
      <div className="d-flex align-items-center p-3 border-bottom">
        <h2 className="fw-bold mb-0">Pilih Alamat Pengambilan</h2>
        <MdClose className="ms-auto me-2 pointer" onClick={handleOnClose} />
      </div>
      <Modal.Body>
        <InputGroup className="border rounded-10">
          <FloatingLabel
            className="rounded-10 w-75"
            label={"Cari kode / nama alamat anda"}
            style={{ width: "", fontSize: "12px", height: "50px" }}
          >
            <Form.Control
              className="border-0 rounded-10"
              type="text"
              style={{ height: "50px" }}
              placeholder="."
              onChange={(event) => handleSearch(event)}
            />
          </FloatingLabel>
          <InputGroup.Text
            className="border-0 search-icon-trx rounded-10 ms-auto"
            style={{ height: "50px" }}
          >
            <FaSearch />
          </InputGroup.Text>
        </InputGroup>

        <div className="rute-opts custom-scroll mt-4">
          {alamat.map((item, index) => {
            if (handleShow(item)) {
              return (
                <Card
                  key={item.id}
                  className={`${handleClassSelected(item)}`}
                  onClick={() => handleDataSelected(item)}
                >
                  <Card.Body>
                    {item.code} - {item.name}
                  </Card.Body>
                </Card>
              );
            }
          })}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size="sm"
          className="w-100 fw-bold py-2 px-4 rounded-pill text-capitalize mt-2"
          onClick={handleOnSelect}
        >
          Pilih Alamat Pengambilan ({dataSelected.length})
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

RuteAlamatPengambilan.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  alamatPengambilan: PropTypes.array,
  editPengambilan: PropTypes.bool,
  deliveryList: PropTypes.array,
};

export default RuteAlamatPengambilan;
