const initialState = {
  subtype: {
    data: [],
    meta: {},
  },
  loading: false,
};

export const vehicleReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "SET_VEHICLE":
      return { ...state, ...payload };
    default:
      return state;
  }
};
