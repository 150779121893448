const initialState = {
  roleList: {
    loading: false,
    data: []
  },
  accessRoleList: {
    loading: false,
    data: []
  },
  roleListForAccount: {
    data: []
  },
  selectedRole: {
    id: '',
    name: '',
    role_code: '',
    description: '',
    access: []
  },
  accountList: {
    loading: false,
    data: []
  }, 
};

export const userMgtReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "SET_ROLE_LIST":
      return { ...state, roleList: { ...payload } };
    case "SET_ACCESS_ROLE_LIST":
      return { ...state, accessRoleList: { ...payload } };
    case "SET_SELECTED_ROLE":
      return { ...state, selectedRole: { ...payload } };
    case "SET_DROPLIST_ROLE":
      return { ...state, roleListForAccount: { ...payload } };
    case "SET_ACCOUNT_LIST":
      return { ...state, accountList: { ...payload } };
    default:
      return state;
  }
};
