import {
  Row,
  Col,
  Tabs,
  Tab,
  InputGroup,
  FloatingLabel,
  Form,
} from "react-bootstrap";
import { useState, useEffect } from "react";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import { draftToMarkdown, markdownToDraft } from "markdown-draft-js";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getProcurementHeaderDetail,
  getProcurementDocumentDetail,
} from "../../../../redux/action/Partner/partner_action";
import InformasiUtamaDetail from "./InformasiUtamaDetail";
import DokumenKontrakDetail from "./DokumenKontrakDetail";
import PenawaranKontrakListDetail from "./PenawaranKontrakListDetail";
import { FaSearch } from "react-icons/fa";
import { FiChevronLeft } from "react-icons/fi";

const PenawaranDetailComponent = () => {
  /*======================== Hooks ======================== */

  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const procurementName = useSelector(
    (st) => st.partnerManagementReducer.selectedProcurement
  );

  /*======================== UseState ======================== */

  const [tab, setTab] = useState("informasi_utama");
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [header, setHeader] = useState("");
  const [document, setDocument] = useState("");
  const [search, setSearch] = useState(undefined);

  /*======================== Handler ======================== */

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleChange = (type, value) => {
    switch (type) {
      case "tab":
        setTab(value);
        break;

      default:
        break;
    }
  };

  const handleBack = () => {
    history.goBack();
  };

  /*======================== UseEffect ======================== */

  useEffect(() => {
    async function getHeaderDetail() {
      const response = await dispatch(
        getProcurementHeaderDetail(params.procurement)
      );
      if (response) {
        const rawData = markdownToDraft(response.tnc);
        const contentState = convertFromRaw(rawData);
        const newEditorState = EditorState.createWithContent(contentState);
        setEditorState(newEditorState);
        setHeader(response);
      }
    }

    async function getDocumentDetail() {
      const response = await dispatch(
        getProcurementDocumentDetail(params.procurement, params.id)
      );
      setDocument(response);
    }

    getHeaderDetail();
    getDocumentDetail();
  }, []);

  /*======================== Others ======================== */
  const tabOptions = [
    {
      title: "Informasi Utama",
      value: "informasi_utama",
    },
    {
      title: "Dokumen Kontrak",
      value: "dokumen_kontrak",
    },
    {
      title: "Penawaran Kontrak",
      value: "penawaran_kontrak",
    },
  ];

  /*======================== Return ======================== */

  return (
    <Row>
      <Col xs={12}>
        <div className="bg-white rounded-card w-full pt-2 ps-3">
          <div className="d-flex flex-row align-items-center">
            <FiChevronLeft
              fontSize={25}
              className="pointer me-3"
              onClick={handleBack}
            />
            <div>
              <h1>{procurementName}</h1>
            </div>
          </div>

          <Tabs
            activeKey={tab}
            className="tabs-trx mb-2 border-bottom-2"
            onSelect={(value) => handleChange("tab", value)}
          >
            {tabOptions.map((item, index) => (
              <Tab key={index} eventKey={item.value} title={item.title} />
            ))}
          </Tabs>
          {tab === "penawaran_kontrak" && (
            <Row
              className="py-3 ps-3 align-items-center"
              style={{ width: "400px" }}
            >
              <InputGroup className="border rounded-card">
                <FloatingLabel
                  className="rounded-card"
                  label={"Cari penawaran"}
                  style={{ width: "", fontSize: "12px", height: "50px" }}
                >
                  <Form.Control
                    className="border-0 rounded-card"
                    type="text"
                    style={{ height: "50px" }}
                    placeholder="."
                    onChange={(event) => handleSearch(event)}
                  />
                </FloatingLabel>
                <InputGroup.Text
                  className="border-0 search-icon-trx rounded-card ms-auto"
                  style={{ height: "50px" }}
                >
                  <FaSearch />
                </InputGroup.Text>
              </InputGroup>
            </Row>
          )}
        </div>
      </Col>
      <Col xs={12}>
        {tab === "informasi_utama" && header && (
          <InformasiUtamaDetail data={header} {...{ editorState }} />
        )}
        {tab === "dokumen_kontrak" && document && (
          <DokumenKontrakDetail data={document} />
        )}
        {tab === "penawaran_kontrak" && (
          <PenawaranKontrakListDetail {...{ search }} />
        )}
      </Col>
    </Row>
  );
};

export default PenawaranDetailComponent;
