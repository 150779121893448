import React, { useState } from "react";
import {
  Modal
} from "react-bootstrap";
import { Avatar } from "antd";
import { AiOutlineUser } from "react-icons/ai";
import{ MdImageNotSupported } from "react-icons/md";


const ModalTandaTangan = ({dataMaping}) => {
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
    <>
      <h6
        className="fw-bold"
        style={{color:"#FF1DBC", textDecoration:"underline", fontSize:"11px", cursor:"pointer"}}
        onClick={()=>handleShow()}
      >
        Lihat Tanda Tangan
      </h6>
      
       <Modal
        contentClassName="rounded-card p-2"
        show={show}
        size="sm"
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton className="bg-white">
          <strong>Tanda Tangan</strong>
        </Modal.Header>
        <div className="p-2" style={{height:"290px"}}>
        <Avatar
          className="me-2 mt-2 border rounded overflow-hidden"
          style={{objectFit:"fill"}}
          shape="square"
          size={265}
          src={dataMaping.signature}
          icon={
            !dataMaping.signature && <MdImageNotSupported />
          }
          ></Avatar>
        </div>
      </Modal>
    </>
    )
}

export default ModalTandaTangan;
