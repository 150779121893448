import React, { useState } from "react";
import {
  Modal,
  Button
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { formatRupiah } from "../../../../../helper/formatCurrency";
import { Avatar } from "antd";
import{ MdImageNotSupported } from "react-icons/md";
import { message } from "antd";
import Info from "./../../../../../components/info"
import { FaRegCopy } from "react-icons/fa";

const ProcessPayment = ({show, setShow, onSubmit, data, setModalPaymentMethod, setPayload, payload,setModalProofPayment}) => {

  const dataPayment = useSelector((st) => st.documentPaymentReducer.detail);
  const [buktiPenagihan, setBuktiPenagihan] = useState(false)
  const handleClose = () => setShow(false);

  const handleSubmit = () => {
    handleClose()
    onSubmit()
  }
  
  const handleChangeMethod =()=> {
    handleClose()
    setModalPaymentMethod(true)
  }

  const onSelectFile = (e) => {
    console.log(e.target.files)
    if (!e.target.files || e.target.files.length === 0) {
      setPayload({
        ...payload,
        receipt: null
      })
      return;
    }
    if (e.target.files[0].size > 5000000) {
      message.error("Ukuran Maksimal foto adalah 5Mb");
    } else {
      setPayload({
        ...payload,
        receipt: e.target.files[0]
      })
    }
  };

  console.log("ini data payload",payload)

  return(
      <Modal
        contentClassName="rounded-card p-2"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton className="bg-white">
          <strong>Proses Pembayaran</strong>
        </Modal.Header>
        <div className="py-4">
          <h5 className="px-4">Silahkan lakukan pembayaran ke rekening / saldo eLOG sesuai dengan metode pembayaran yang anda pilih. eLOG akan meneruskan pembayaran anda ke partner.</h5>
          <hr />
          <div className="px-4">
            <div className="d-flex align-items-center">
              <Avatar
                className="me-3 border rounded overflow-hidden"
                style={{objectFit:"fill"}}
                shape="square"
                size={45}
                src={data?.logo}
                icon={!data?.logo && <MdImageNotSupported/>}
              />
              <h4 className="col m-0">{data?.customer_payment_method} (Bank Transfer)</h4>
            </div>
            <div className="row mt-3">
              <p className="col">Nomor Tujuan</p>
              <div className="col fw-bold d-flex justify-content-between">
                <p>: {data?.customer_payment_account_number}</p>
                <FaRegCopy
                  className="pointer"
                  style={{color:"#CE1671"}}
                  onClick={() => {navigator.clipboard.writeText(data?.customer_payment_account_number); message.success("Nomor Berhasil Tersalin")}}
                />
              </div>
            </div>
            <div className="row">
              <p className="col">Atas Nama</p>
              <p className="col fw-bold">: {data?.customer_payment_account_name}</p>
            </div>
            <div className="row">
              <p className="col">Total Pembayaran</p>
              <p className="col fw-bold">: {dataPayment?.payment_amount_after_discount ? formatRupiah(dataPayment?.payment_amount_after_discount) : "-"}</p>
            </div>
          </div>
          <hr />
          <div className="px-4">
            <Info info="Pastikan pembayaran anda sudah benar dan aktifkan tombol checklist dibawah ini untuk menyelesaikan proses."/>
            {/* <div className="rounded p-2 mb-3" style={{backgroundColor:"#E7F3F5"}}>
              <h6 className="m-0 text-muted">
                Pastikan pembayaran anda sudah benar dan aktifkan tombol checklist dibawah ini untuk menyelesaikan proses.
              </h6>
            </div> */}
            <div className="d-flex align-items-center" onClick={()=> setBuktiPenagihan(!buktiPenagihan)}>
              <input checked={buktiPenagihan} type="checkbox" className="m-0 p-0 me-2 pointer" />
              <h6 className="m-0 pointer">Saya sudah membayar penagihan</h6>
            </div>
          </div>
          {buktiPenagihan && 
            <>
              <hr/>
              {!payload?.receipt ?
                <label className="w-100 px-4" >
                  <div className="rounded d-flex justify-content-center align-items-center pointer p-2" style={{border:"1px dotted #FF1D8C"}}>
                    <h5 className="m-0 text-primary">Upload Bukti Pembayaran</h5>
                  </div>
                  <input
                    type="file"
                    style={{ visibility: "hidden" }}
                    onChange={onSelectFile}
                  />
                </label>
                :
                <label className="w-100">
                  <div className="px-4 mb-4">
                    <div className="rounded d-flex justify-content-between align-items-center" style={{border:"1px solid #FF1D8C"}}>
                      <h5 className="m-0 px-4 text-primary">{payload?.receipt?.name.length > 40 ? `${payload?.receipt?.name.substring(0, 40)}...` : payload?.receipt?.name}</h5>
                      <div className="pointer">
                        <h5 className="m-0 p-2 px-3 text-white bg-primary ">Ubah</h5>
                      </div>
                    </div>
                  </div>
                  <input
                    type="file"
                    style={{ visibility: "hidden", display:"none" }}
                    onChange={onSelectFile}
                  />
                </label>
              }
            </>
          }
        </div>
        
        <Modal.Footer style={{marginTop:`${buktiPenagihan ? "-40px" : "0px" }`}}>
          <div className="w-100 d-flex justify-content-between align-items-center" >
            <h5
              size="sm"
              className="py-1 h-25 text-primary underline pointer"
              onClick={handleChangeMethod}
              >
              Ubah Metode
            </h5>
            <Button
              disabled={buktiPenagihan ? false : true}
              size="sm"
              className="fw-bold py-1 h-25 px-4 rounded-pill text-capitalize"
              style={{backgroundColor:`${buktiPenagihan ? "#CE1671" : "#BBBBBB"}`, border:"none"}}
              onClick={handleSubmit}
            >
              Konfirmasi
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    )
  }
export default ProcessPayment;
