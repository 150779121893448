import React, { useState } from "react";
import {
  Modal,
  Form,
  InputGroup,
  FloatingLabel,
  Button,
  Row,
  Col,
} from "react-bootstrap";
import { Upload, message } from "antd";
import { previewImage } from "../../helper/imgPreview";
import InfoBar from "../infoBar/InfoBar";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { resetAuth, setAuth } from "../../redux/action/auth_action";
import ProfileApi from "../../api/profile-api";
import AccountApi from "../../api/auth-api";
import useBreakpoint from "../responsiveUtils/useBreakpoint";

import SectionSuccess from "./SectionSuccess";

// icon
import icon_arrow_gear_red from "../../assets/images/icon_arrow_gear_red.svg";
import icon_pin_colored from "../../assets/images/icon_pin_colored.svg";
import icon_city from "../../assets/images/icon_city.svg";
import icon_camera_drag from "../../assets/images/icon_camera_drag.svg";

import { MdChevronRight, MdClose } from "react-icons/md";

const CompanyData = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const auth = useSelector((st) => st.authReducer);
  const { isMobile, isTablet, isDesktop } = useBreakpoint();
  const [show, setShow] = useState(false);
  const [previewSiupImage, setPreviewSiupImage] = useState(null);
  const [previewTdpImage, setPreviewTdpImage] = useState(null);
  const [body, setBody] = useState({
    company_name: "",
    company_address: "",
    siup_uploaded_file: "",
    tdp_uploaded_file: "",
  });

  const steps = [
    {
      id: 1,
      name: "nama dan alamat",
      desc: "Step 1. Masukkan Nama dan Alamat perusahaan",
    },
    {
      id: 2,
      name: "dokumen perusahaan",
      desc: "Step 2. Masukkan Foto dokumen perusahaan",
    },
  ];

  const [activeStep, setActiveStep] = useState({
    id: 1,
    name: "nama dan alamat",
    desc: "Step 1. Masukkan Nama dan Alamat perusahaan",
  });

  const openModal = () => {
    setShow(true);
  };

  const closeModal = () => {
    setShow(false);
  };

  const handleNext = () => {
    setActiveStep(steps[1]);
  };

  const handleBack = () => {
    setActiveStep(steps[0]);
  };

  // drag n drop upload
  const { Dragger } = Upload;

  const tdpProps = {
    name: "file",
    multiple: false,
    maxCount: 1,
    accept: "image/*",
    beforeUpload() {
      return false;
    },
    onChange(info) {
      setBody({ ...body, tdp_uploaded_file: info.file });
      setPreviewTdpImage(previewImage(info.file));
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const siupProps = {
    name: "file",
    multiple: false,
    maxCount: 1,
    accept: "image/*",
    beforeUpload() {
      return false;
    },
    onChange(info) {
      setBody({ ...body, siup_uploaded_file: info.file });
      setPreviewSiupImage(previewImage(info.file));
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const getPreviewImage = (previewImg, data) => {
    return previewImg ? previewImg : data ? data : icon_camera_drag;
  };

  const getPreviewClass = (previewImg, data) => {
    return previewImg || data ? "w-100" : "";
  };

  const handleSubmit = async () => {
    try {
      const accountApi = new AccountApi();
      const profileApi = new ProfileApi();

      const fd = new FormData();

      for (const key in body) {
        if (body[key]) {
          fd.append(key, body[key]);
        }
      }

      const result = await accountApi.checkToken(auth.refresh_token);
      if (result.status === "success") {
        const profileRes = await profileApi.updateToCompany(
          fd,
          auth.access_token
        );

        if (profileRes.status === "success") {
          message.success(profileRes.message);
          const { data } = await accountApi.getDetailCustomer(
            auth.access_token
          );

          dispatch(setAuth({ ...auth, ...data }));

          // setIsSubmit(true);
          closeModal();
        } else {
          closeModal();
          message.error(profileRes.message);
        }
      } else {
        history.push("/login");
        dispatch(resetAuth());
      }
    } catch (error) {
      console.log(error.response);
    }
  };

  return (
    <>
      {auth.customer_type === "COMPANY" ? (
        <div
          className=" pointer bg-white rounded-card p-3 mt-2"
          onClick={openModal}
        >
          <div className="">
            <div className="d-flex align-items-center border-bottom-1">
              <img src={icon_city} width={25} height={25} alt="" />

              <div className="ms-3">
                <h6 className="text-muted ">Nama Perusahaan</h6>
                <h5>{auth?.company_name}</h5>
              </div>
              <div
                className="d-flex ms-auto align-items-center"
                style={{ marginTop: "-10px" }}
              >
                <MdChevronRight className="" />
              </div>
            </div>
            <div className="d-flex align-items-center mt-3 border-bottom-1">
              <img src={icon_pin_colored} width={25} height={25} alt="" />
              <div className="ms-3">
                <h6 className="text-muted ">Alamat</h6>
                <h5>{auth?.company_address || "Belum memasukan data"}</h5>
              </div>
            </div>
            <div
              className={`d-flex ${
                isMobile ? "flex-column" : ""
              } align-items-center mt-3 border-bottom-1 gap-2`}
            >
              <img
                src={auth?.siup_uploaded_file}
                width={400}
                height={200}
                alt=""
                className="img-fluid"
              />
              <img
                src={auth?.tdp_uploaded_file}
                width={400}
                height={200}
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      ) : (
        <div
          className="bg-white rounded-card w-full p-3 mt-2 border border-primary pointer"
          onClick={openModal}
        >
          <div className="d-flex align-items-center   px-2">
            <img src={icon_arrow_gear_red} width={25} height={25} alt="" />

            <div className="ms-3">
              {/* <h6 className="text-muted ">Data KTP</h6> */}
              <h5>Menjadi akun Perusahaan</h5>
            </div>
          </div>
        </div>
      )}
      <Modal
        contentClassName="rounded-card p-2"
        show={show}
        onHide={closeModal}
        className="p-3"
        // size="lg"
        centered
      >
        <div className="d-flex p-2">
          <p className="fw-bold">Data Perusahaan</p>
          <MdClose className="ms-auto me-2 pointer" onClick={closeModal} />
        </div>
        <Modal.Body>
          <h6>{activeStep.desc}</h6>
          <div className="">
            <div className="d-flex gap-2 align-items-center mb-2 w-100">
              <div className="rounded-card border-secondary progress-tabs border"></div>
              <div
                className={`rounded-card ${
                  activeStep.id === 2 ? "border-secondary" : "border-muted "
                } progress-tabs border`}
              ></div>
            </div>
            {activeStep.id === 1 ? (
              <>
                <InputGroup className="border rounded-input mt-4">
                  <InputGroup.Text
                    className="border-0 rounded-input"
                    style={{ backgroundColor: "#f8f9fa" }}
                  >
                    <img src={icon_city} alt="" />
                  </InputGroup.Text>
                  <FloatingLabel
                    className="rounded-input"
                    label="Nama Perusahaan"
                    style={{
                      fontSize: "12px",
                      width: isMobile ? "75%" : "90%",
                    }}
                  >
                    <Form.Control
                      type={"text"}
                      className="border-0 rounded-input"
                      placeholder="m"
                      style={{ height: "50px" }}
                      defaultValue={auth?.company_name}
                      onChange={(ev) =>
                        setBody({ ...body, company_name: ev.target.value })
                      }
                    ></Form.Control>
                  </FloatingLabel>
                </InputGroup>
                <InputGroup className="border rounded-input mt-2">
                  <InputGroup.Text
                    className="border-0 rounded-input"
                    style={{ backgroundColor: "#f8f9fa" }}
                  >
                    <img src={icon_pin_colored} alt="" />
                  </InputGroup.Text>
                  <FloatingLabel
                    className="rounded-input"
                    label="Alamat Perusahaan"
                    style={{
                      fontSize: "12px",
                      width: isMobile ? "75%" : "90%",
                    }}
                  >
                    <Form.Control
                      type={"text"}
                      as="textarea"
                      className="border-0 rounded-input"
                      placeholder="m"
                      defaultValue={auth?.company_address}
                      onChange={(ev) =>
                        setBody({ ...body, company_address: ev.target.value })
                      }
                      style={{ height: "50px" }}
                    ></Form.Control>
                  </FloatingLabel>
                </InputGroup>
              </>
            ) : (
              <>
                <div className="w-100">
                  <Row
                    className={`${
                      isMobile ? "flex-column" : ""
                    }  d-flex gap-2 justify-content-center`}
                  >
                    <Col className="">
                      <h6>Tanda Daftar Perusahaan (TDP)</h6>
                      <Dragger
                        showUploadList={false}
                        {...tdpProps}
                        className="p-4"
                        style={{ backgroundColor: "white" }}
                      >
                        <img
                          src={getPreviewImage(
                            previewTdpImage,
                            auth?.tdp_uploaded_file
                          )}
                          alt=""
                          className={getPreviewClass(
                            previewTdpImage,
                            auth?.tdp_uploaded_file
                          )}
                        />
                        <p className="text-primary mt-3">Browse file</p>
                      </Dragger>
                    </Col>
                    <Col className="">
                      <h6>Surat Izin Usaha Perdagangan (SIUP)</h6>
                      <Dragger
                        showUploadList={false}
                        {...siupProps}
                        className="p-4"
                        style={{ backgroundColor: "white" }}
                      >
                        <img
                          src={getPreviewImage(
                            previewSiupImage,
                            auth?.siup_uploaded_file
                          )}
                          alt=""
                          className={getPreviewClass(
                            previewSiupImage,
                            auth?.siup_uploaded_file
                          )}
                        />
                        <p className="text-primary mt-3">Browse file</p>
                      </Dragger>
                    </Col>
                  </Row>
                </div>
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="mt-4">
          <p className="text-primary pointer me-4" onClick={handleBack}>
            Kembali
          </p>
          <Button
            size="sm"
            variant="primary"
            className="float-end rounded-pill px-4 py-2"
            onClick={() => {
              if (activeStep.id === 1) {
                handleNext();
              } else {
                handleSubmit();
              }
            }}
          >
            Berikutnya
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CompanyData;
