import React, { useState } from "react";
import {
  Modal,
  Form,
  InputGroup,
  FloatingLabel,
  Button,
  Row,
  Col,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  getRekeningList,
  updateSelectedBank,
} from "../../redux/action/balance_action";
import { resetAuth } from "../../redux/action/auth_action";
import BalanceApi from "../../api/balance-api";
// icons
import { MdChevronRight, MdClose } from "react-icons/md";
import { message } from "antd";

const PickBankAccount = ({ show, closeModal, setShowModal }) => {
  const dispatch = useDispatch();
  const bankAccounts = useSelector((st) => st.balanceReducer.bankAccounts);
  const auth = useSelector((st) => st.authReducer);

  const setMainBankAccount = async (data, id) => {
    const api = new BalanceApi();

    const response = await api.setMainBankAccount(auth.access_token, id);

    if (response.status === 401) {
      dispatch(resetAuth());
    }

    if (response.status === "success") {
      // dispatch(getRekeningList(auth.access_token));
      dispatch(updateSelectedBank(data));
      message.success(response.message);
      setShowModal({ withdraw: true });
    }
  };

  return (
    <Modal
      contentClassName="rounded-card p-2"
      show={show}
      onHide={closeModal}
      className="p-3"
      centered
    >
      <div className="d-flex p-2">
        <p className="fw-bold">Pilih Rekening Anda</p>
        <MdClose className="ms-auto me-2 pointer" onClick={closeModal} />
      </div>
      <Modal.Body>
        {bankAccounts?.data?.length === 0 && (
          <div className="rounded-card bg-white w-100 p-4 mt-2">
            <h6 className="text-muted">Belum ada data rekening</h6>
          </div>
        )}
        {bankAccounts?.data?.map((el) => (
          <Row
            className="rounded-card w-100 p-4 mt-2 pick-bank pointer ms-1"
            onClick={() => setMainBankAccount(el, el.id)}
          >
            <Col xs={3} className="d-flex align-items-center">
              <img src={el.logo} className="img-fluid" alt="" />
            </Col>
            <Col>
              <h5 className="fw-bold">{el.bank}</h5>
              <h6 className="">{el.account_number}</h6>
              <h6>a/n {el.account_name}</h6>
            </Col>
          </Row>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <p
          className="text-primary pointer me-4"
          onClick={() => setShowModal({ withdraw: true })}
        >
          Kembali
        </p>
        <Button
          size="sm"
          variant="primary"
          className="float-end rounded-pill px-4 py-2"
          // onClick={() => {
          //   handleSubmit();
          // }}
        >
          Pilih
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PickBankAccount;
