import VehicleApi from "../../../api/Master/vehicle-api";
import { resetAuth } from "../auth_action";

export const getVehicleSubtype = (params) => async (dispatch, state) => {
  try {
    const api = new VehicleApi();

    dispatch({
      type: "SET_VEHICLE_SUBTYPE",
      payload: { loading: true },
    });

    const response = await api.getVehicleSubtype(
      state().authReducer.access_token,
      params
    );

    if (response.status === 401) {
      dispatch(resetAuth());
    }
    const { data, ...meta } = response;
    dispatch({
      type: "SET_VEHICLE",
      payload: { loading: false, subtype: { data, meta } },
    });
  } catch (error) {
    console.log(error?.response);
  }
};
