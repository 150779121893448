import React, { useState } from "react";
import {
  Modal,
  Button
} from "react-bootstrap";

const ConfirmAcceptBilling = ({onSubmit}) => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const handleSubmit = async() => {
    onSubmit()
    handleClose()
  }

  return(
  <>
    <Button
      size="sm"
      className="fw-bold py-1 h-25 px-4 rounded-pill text-capitalize"
      onClick={handleShow}
    >
      Terima Penagihan
    </Button>
    
    <Modal
      contentClassName="rounded-card p-2"
      show={show}
      onHide={handleClose}
      centered
    >
      <Modal.Header closeButton className="bg-white">
        <strong>Konfirmasi Terima Penagihan</strong>
      </Modal.Header>
      <div className="p-4">
          <h6 className="mb-2">Jika anda melakukan konfirmasi, anda harus melanjutkan ke proses pengecekan dokumen dan pembayaran.</h6>
          <h6 className="m-0">Apakah anda yakin akan menerima tagihan ini?</h6>
      </div>
      <Modal.Footer>
        <Button
          size="sm"
          className="fw-bold py-1 h-25 px-4 rounded-pill text-capitalize"
          onClick={handleSubmit}
        >
          Konfirmasi Terima
        </Button>
      </Modal.Footer>
    </Modal>
  </>
  )
}

export default ConfirmAcceptBilling;
