import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Modal,
  Form,
  Row,
  Col,
  InputGroup,
  FloatingLabel,
} from "react-bootstrap";
import PropTypes from "prop-types";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { useDispatch, useSelector } from "react-redux";
import iconDocs from "../../../../assets/images/icon-docs.svg";
import { MdClose } from "react-icons/md";

const ModalUpdateNoShipment = (props) => {

const { isMobile } = useBreakpoint();
  const dispatch = useDispatch();

  /*======================== Props ======================== */

  const { isOpen, handleClose, handleSubmit, value } = props;

  /*======================== UseState ===================== */

  const [noTF, setNoTF] = useState("");

  /*======================== Handler ====================== */

  const handleOnSelect = () => {
    handleSubmit(noTF)
    handleClose();
  };

  const handleOnClose = () => {
    handleClose();
  };

  /*======================== UseEffect =====================*/

  useEffect(() => {
    if (value) {
      setNoTF(value);
    }
  }, [value]);

  return (
    <Modal
      contentClassName="rounded-card p-2"
      show={isOpen}
      onHide={handleOnClose}
      className="ice p-3"
      centered
    >
      <div className="d-flex p-2 border-bottom">
        <h5 className="fw-bold">Data Tambahan (Optional)</h5>
        <MdClose className="ms-auto me-2 pointer" onClick={handleOnClose} />
      </div>
      <Modal.Body>
        <div className="additional-data-form">
          <InputGroup className="mt-1">
            <InputGroup.Text className="border-0">
              <img src={iconDocs} className="icon-form" alt="" />
            </InputGroup.Text>
            <FloatingLabel
              label="Nomor Pengiriman"
              style={{ width: isMobile ? "88.5%" : "92.2%", fontSize: "12px" }}
            >
              <Form.Control
                className="border-0"
                type="text"
                placeholder="Nomor Pengiriman"
                value={noTF}
                onChange={(e) => {
                  setNoTF(e.target.value);
                }}
              />
            </FloatingLabel>
          </InputGroup>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className="w-50 float-end">
          <Button
            size="sm"
            className="w-100 fw-bold rounded-pill text-capitalize mt-2"
            onClick={handleOnSelect}
          >
            Submit
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

ModalUpdateNoShipment.propTypes = {
    isOpen: PropTypes.bool,
    handleClose: PropTypes.func,
    handleSubmit: PropTypes.func,
  };

export default ModalUpdateNoShipment