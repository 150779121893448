import React, { Component, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import GoogleMapReact from "google-map-react";
import { useLocation } from "react-router-dom";

// images
import iconCircle from "../../assets/images/icon-circle.svg";
import iconMarker from "../../assets/images/icon-mark.svg";
import iconMarkerO from "../../assets/images/icon-marker-o.svg";
import streetViewSolid from "../../assets/images/street-view-solid.svg";

export default function SimpleMap({ driverLocation }) {
  const [state, setState] = useState({
    center: [-6.2087634, 106.845599],
    zoom: 13,
    draggable: true,
    gmapRefs: null,
    gmapServices: null,
    mapOptions: {
      zoomControl: false,
      scaleControl: false,
      fullscreenControl: false,
      mapId: "4ef51c620988c579",
      styles: undefined,
    },
    driverMarkers: [],
  });

  useEffect(() => {
    // current location driver
    if (driverLocation && state.gmapRefs && state.gmapServices) {
      let marker = new state.gmapServices.Marker({
        position: new window.google.maps.LatLng(
          driverLocation.lat,
          driverLocation.lng
        ),
        map: state.gmapRefs,
        icon: streetViewSolid,
      });

      // clear, only display the last
      state.driverMarkers.forEach((el, i) => {
        el.setMap(null);
      });

      setState({
        ...state,
        driverMarkers: [...state.driverMarkers, marker],
      });
    }
  }, [driverLocation]);

  const location = useLocation();

  const trxDetail = useSelector((st) => st.trxHistoryReducer.trxDetail);

  const handleApiLoaded = async (map, maps) => {
    setState({ ...state, gmapRefs: map, gmapServices: maps });

    await renderDirectionsRoute(map, maps);
  };

  const renderDirectionsRoute = async (map, maps) => {
    // const {
    //   state: { type },
    // } = location;
    const { mst_driver } = trxDetail?.data;
    let routes = [];
    const directionsService = new maps.DirectionsService();
    const directionsDisplay = new maps.DirectionsRenderer();
    const bounds = new maps.LatLngBounds();

    if (mst_driver && trxDetail?.data?.type_order === "RENT") {
      // destructuring variables

      const lngDriver = trxDetail?.data?.mst_driver?.current_location
        ?.split(" ")[0]
        ?.replace("POINT(", "");
      const latDriver = trxDetail?.data?.mst_driver?.current_location
        ?.split(" ")[1]
        ?.replace(")", "");

      routes.push(
        {
          lat: latDriver,
          lng: lngDriver,
        },
        {
          lat: trxDetail?.data?.trx_booking_rent?.pickup_location_latitude,
          lng: trxDetail?.data?.trx_booking_rent?.pickup_location_longitude,
        }
      );

      map.setCenter(
        new window.google.maps.LatLng(routes[0].lat, routes[0].lng)
      );

      await directionsService.route(
        {
          origin: new window.google.maps.LatLng(routes[0].lat, routes[0].lng),
          destination: new window.google.maps.LatLng(
            routes[1].lat,
            routes[1].lng
          ),
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (response, status) => {
          if (status === "OK") {
            directionsDisplay.setDirections(response);
            const routePolyline = new maps.Polyline({
              path: response.routes[0].overview_path,
              strokeColor: "#d63384",
              strokeOpacity: 0.8,
              strokeWeight: 5,
            });

            routePolyline.setMap(map);

            new maps.Marker({
              position: new window.google.maps.LatLng(
                routes[0].lat,
                routes[0].lng
              ),
              map,
              icon: iconCircle,
            });

            new maps.Marker({
              position: new window.google.maps.LatLng(
                routes[1].lat,
                routes[1].lng
              ),
              map,
              icon: iconMarkerO,
            });

            bounds.extend(
              new window.google.maps.LatLng(routes[0].lat, routes[0].lng)
            );

            bounds.extend(
              new window.google.maps.LatLng(routes[1].lat, routes[1].lng)
            );

            map.fitBounds(bounds);
          } else {
            if (lngDriver && latDriver) {
              window.alert("Directions request failed due to " + status);
            }
          }
        }
      );
      //  type order dari send ngga ada
    } else if (mst_driver && trxDetail?.data?.type_order === "SEND_GOODS") {
      let trx_booking_send_destination =
        trxDetail?.data?.trx_booking_send?.trx_booking_send_destination;
      routes = [...trx_booking_send_destination];

      new maps.Marker({
        position: new window.google.maps.LatLng(
          routes[0].latitude,
          routes[0].longitude
        ),
        map,
        icon: iconCircle,
      });

      map.setCenter(
        new window.google.maps.LatLng(routes[0].latitude, routes[0].longitude)
      );

      bounds.extend(
        new window.google.maps.LatLng(routes[0].latitude, routes[0].longitude)
      );

      for (
        let index = 0;
        index < trx_booking_send_destination.length;
        index++
      ) {
        if (index === trx_booking_send_destination.length - 1) break;

        await directionsService.route(
          {
            origin: new window.google.maps.LatLng(
              routes[index].latitude,
              routes[index].longitude
            ),
            destination: new window.google.maps.LatLng(
              routes[index + 1].latitude,
              routes[index + 1].longitude
            ),
            travelMode: window.google.maps.TravelMode.DRIVING,
          },
          (response, status) => {
            if (status === "OK") {
              directionsDisplay.setDirections(response);
              const routePolyline = new maps.Polyline({
                path: response.routes[0].overview_path,
                strokeColor: "#d63384",
                strokeOpacity: 0.8,
                strokeWeight: 5,
              });

              routePolyline.setMap(map);

              new maps.Marker({
                position: new window.google.maps.LatLng(
                  routes[index + 1].latitude,
                  routes[index + 1].longitude
                ),
                map,
                icon: iconMarkerO,
              });

              bounds.extend(
                new window.google.maps.LatLng(
                  routes[index + 1].latitude,
                  routes[index + 1].longitude
                )
              );

              map.fitBounds(bounds);
            } else {
              window.alert("Directions request failed due to " + status);
            }
          }
        );
      }
    }
  };

  const showGoogleMap = () => {
    return (
      <GoogleMapReact
        draggable={state.draggable}
        options={state.mapOptions}
        center={state.center}
        zoom={state.zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={async ({ map, maps }) => {
          await handleApiLoaded(map, maps);
        }}
      ></GoogleMapReact>
    );
  };

  return <>{showGoogleMap()}</>;
}
