import BalanceApi from "../../api/balance-api";
import { resetAuth } from "./auth_action";

export const getRekeningList = (token) => async (dispatch) => {
  try {
    const api = new BalanceApi();

    dispatch({
      type: "SET_BANK_ACCOUNTS",
      payload: { loading: true, data: [] },
    });

    let response = await api.getRekeningList(token);

    if (response.status === 401) {
      dispatch(resetAuth());
    }

    dispatch({
      type: "SET_BANK_ACCOUNTS",
      payload: { loading: false, data: response?.data },
    });
  } catch (error) {
    console.log(error?.response);
  }
};

export const getDisbursementMethods = () => async (dispatch) => {
  try {
    const api = new BalanceApi();
    dispatch({
      type: "SET_DISBURSEMENT_METHODS",
      payload: { loading: true, data: [] },
    });

    let response = await api.getDisburementMethodList();

    if (response.status === 401) {
      dispatch(resetAuth());
    }

    dispatch({
      type: "SET_DISBURSEMENT_METHODS",
      payload: { loading: false, data: response?.data },
    });
  } catch (error) {
    console.log(error?.response);
  }
};

export const getDisbursementHistory = (token, query) => async (dispatch) => {
  try {
    const api = new BalanceApi();

    dispatch({
      type: "SET_HISTORY_BALANCE_LIST",
      payload: { loading: true, data: [] },
    });

    let response = await api.getDisbursementHistory(token, query);

    if (response.status === 401) {
      dispatch(resetAuth());
    }

    dispatch({
      type: "SET_HISTORY_BALANCE_LIST",
      payload: { loading: false, data: response?.data },
    });
  } catch (error) {
    console.log(error?.response);
  }
};

export const updateSelectedBank = (payload) => ({
  type: "SET_SELECTED_BANK_ACCOUNT",
  payload,
});
