export const getStatusDesc = (status) => {
  const helper = {
      ON_GOING: "Transaksi sedang di proses", // rent
      GO_TO_PICKUP_LOCATION: "Menuju Lokasi Pengambilan", // rent
      GO_TO_RETURN_LOCATION: "Menuju Lokasi Pengembalian", // rent
      WAITING_FOR_PAYMENT: "Menunggu Pembayaran",
      PENDING: "Transaksi sedang diproses",
      WAITING_FOR_CONFIRMATION: "Menunggu Konfirmasi",
      CONFIRMED: "Transaksi sudah terkonfirmasi",
      GO_TO_LOCATION_PICKUP: "Menuju Lokasi Pengambilan",
      ARRIVE_AT_LOCATION_PICKUP: "Sampai di Lokasi Pengambilan",
      LOADING_GOODS_PICKUP: "Proses Bongkar Muat Barang",
      GO_TO_LOCATION_DROPOFF: "Menuju Lokasi Pengiriman",
      ARRIVE_AT_LOCATION_DROPOFF: "Sampai di Lokasi Pengiriman",
      LOADING_GOODS_DROPOFF: "Proses Bongkar Muat Barang",
      CONFIRM_COD: "Transaksi sedang diproses",
      FINISHED: "Transaksi selesai",
      CANCELLED: "Transaksi dibatalkan"
  };

  return helper[status];
};

export const getStatusTextColor = (status) => {
  const helper = {
    WAITING_FOR_PAYMENT: "text-warning",
    WAITING_FOR_CONFIRMATION: "text-warning",
    ON_GOING: "text-secondary",
    GO_TO_PICKUP_LOCATION: "text-secondary",
    GO_TO_RETURN_LOCATION: "text-secondary",
    CONFIRMED: "text-success",
    CANCELLED: "text-danger",
    FINISHED: "text-success",
    PENDING: "text-warning",
  };

  return helper[status];
};

export const getStatusDescDetail = (status) => {
  const helper = {
      ON_GOING: "Driver sedang menuju lokasi pengembalian", // rent
      GO_TO_PICKUP_LOCATION: "Driver sedang menuju lokasi pengambilan / penjemputan", // rent
      GO_TO_RETURN_LOCATION: "Driver sedang menuju lokasi pengembalian", // rent
      WAITING_FOR_PAYMENT: "Silahkan melakukan pembayaran untuk melanjutkan transaksi",
      PENDING: "Transaksi sedang diproses",
      WAITING_FOR_CONFIRMATION: "Transaksi sedang dalam proses konfirmasi oleh mitra eLOG",
      CONFIRMED: "Transaksi sudah dikonfirmasi oleh miitra eLOG",
      DELIVER_MUTATION: "Sedang mengantar kendaraan mutasi",
      GO_TO_LOCATION_PICKUP: "Driver sedang menuju lokasi pengambilan",
      ARRIVE_AT_LOCATION_PICKUP: "Driver tiba di lokasi pengambilan",
      LOADING_GOODS_PICKUP: "Driver mengambil barang",
      GO_TO_LOCATION_DROPOFF: "Driver sedang menuju lokasi Pengiriman",
      ARRIVE_AT_LOCATION_DROPOFF: "Driver tiba di lokasi Pengiriman",
      LOADING_GOODS_DROPOFF: "Driver memberikan barang",
      CONFIRM_COD: "Transaksi COD sudah dikonfirmasi oleh miitra eLOG",
      FINISHED: "Transaksi selesai",
      CANCELLED: "Transaksi dibatalkan"
  };

  return helper[status];
};


export const getStatusRent = (status) => {
  const helper = {
      WAITING_FOR_PAYMENT: "Menunggu Pembayaran", // rent
      WAITING_FOR_CONFIRMATION: "Menunggu Konfirmasi", // rent
      CONFIRMED: "Menunggu Jadwal", // rent,
      DELIVER_MUTATION: "Mengantar Mutasi",
      WAITING_CONFIRMATION_STOP: "Menunggu Berhenti Sewa",
      WAITING_CUSTOMER_CONFIRM_MUTATION: "Menunggu Mutasi Sewa",
      GO_TO_MUTATION_ORIGIN: "Mutasi Origin",
      GO_TO_MUTATION_DESTINATION: "Menuju Lokasi Mutasi",
      GO_TO_PICKUP_LOCATION: "Mengantar Kendaraan",
      ON_GOING: "Sedang Berlangsung",
      GO_TO_RETURN_LOCATION: "Pengembalian Kendaraan",
      FINISHED: "Pesanan Selesai",
      CANCELLED: "Pesanan dibatalkan"
  };

  return helper[status];
};


export const getStatusRentColor = (status) => {
  const helper = {
      WAITING_FOR_PAYMENT: "#FFAC14", // rent
      WAITING_FOR_CONFIRMATION: "#FFAC14", // rent
      CONFIRMED: "#FF8749", // rent
      WAITING_CONFIRMATION_STOP: "Menunggu Berhenti Sewa",
      WAITING_CUSTOMER_CONFIRM_MUTATION: "Menunggu Mutasi Sewa",
      GO_TO_MUTATION_ORIGIN: "Mutasi Origin",
      GO_TO_MUTATION_DESTINATION: "Menuju Lokasi Mutasi",
      GO_TO_PICKUP_LOCATION: "#FF1D8C",
      ON_GOING: "#2BBECB",
      GO_TO_RETURN_LOCATION: "#1E31AF",
      FINISHED: "#0FAA4B",
      CANCELLED: "#FF0000"
  };

  return helper[status];
};



export const getStatusRentDesc = (status) => {
  const helper = {
    WAITING_FOR_PAYMENT: "Partner melakukan proses pembayaran pesanan sesuai harga yang tertera.", 
    WAITING_FOR_CONFIRMATION: "Partner melakukan konfirmasi pesanan dan penugasaan untuk driver.",
    CONFIRMED: "Pesanan sedang menunggu jadwal untuk memulai pengiriman pesanan.",
    WAITING_CONFIRMATION_STOP: "-", // rent
    WAITING_CUSTOMER_CONFIRM_MUTATION: "-", // rent
    GO_TO_MUTATION_ORIGIN: "-", // rent
    GO_TO_MUTATION_DESTINATION: "-", // rent
    GO_TO_PICKUP_LOCATION: "Pengemudi sedang menuju ke alamat sewa.",
    ON_GOING: "Kendaraan sedang digunakan di alamat sewa. ",
    GO_TO_RETURN_LOCATION: "Kendaraan sedang dikembalikan oleh pengemudi dari alamat sewa.",
    FINISHED: "Pesanan anda telah selesai dilakukan.",
    CANCELLED: "Pesanan anda telah selesai dilakukan."
  };

  return helper[status];
};


export const getStatusRentDescDetail = (status) => {
  const helper = {
    WAITING_FOR_CONFIRMATION: "Driver Terpilih: Ericko Lim (081234567890)",
    WAITING_CONFIRMATION_STOP: "-", // rent
    WAITING_CUSTOMER_CONFIRM_MUTATION: "-", // rent
    GO_TO_MUTATION_ORIGIN: "-", // rent
    GO_TO_MUTATION_DESTINATION: "-", // rent
    GO_TO_PICKUP_LOCATION: "Kendaraan: Daihatsu Grand Max - K 1234 AA",
    ON_GOING: "Kendaraan: Daihatsu Grand Max - K 1234 AA",
    GO_TO_RETURN_LOCATION: "Kendaraan: Daihatsu Grand Max - K 1234 AA",
  };

  return helper[status];
};
