import InvoiceApi from "../../../api/Invoice/invoice-api";
import { resetAuth } from "../auth_action";

export const getInvoiceList = (params) => async (dispatch, state) => {
  try {
    const api = new InvoiceApi();

    dispatch({
      type: "SET_INVOICE",
      payload: { loading: true },
    });

    const response = await api.getInvoiceList(
      state().authReducer.access_token,
      params
    );

    if (response.status === 401) {
      dispatch(resetAuth());
    }
    const { data, ...meta } = response;
    dispatch({
      type: "SET_INVOICE",
      payload: { loading: false, data: [...data], meta, filter: params },
    });
  } catch (error) {
    console.log(error?.response);
  }
};

export const getInvoiceAll = (params) => async (dispatch, state) => {
  try {
    const api = new InvoiceApi();

    dispatch({
      type: "SET_INVOICE",
      payload: { loading: true },
    });

    const response = await api.getInvoiceAll(
      state().authReducer.access_token,
      params
    );

    if (response.status === 401) {
      dispatch(resetAuth());
    }
    dispatch({
      type: "SET_INVOICE",
      payload: { loading: false, all: response.data },
    });
  } catch (error) {
    console.log(error?.response);
  }
};

export const getInvoiceDetail = (params) => async (dispatch, state) => {
  try {
    const api = new InvoiceApi();

    dispatch({
      type: "SET_INVOICE",
      payload: { loading: true },
    });

    const response = await api.getInvoiceDetail(
      state().authReducer.access_token,
      params
    );

    if (response.status === 401) {
      dispatch(resetAuth());
    }

    dispatch({
      type: "SET_INVOICE",
      payload: { loading: false },
    });
    return response?.data;
  } catch (error) {
    console.log(error?.response);
  }
};

export const createInvoice = (payload) => async (dispatch, state) => {
  try {
    const api = new InvoiceApi();

    dispatch({
      type: "SET_INVOICE",
      payload: { loading: true },
    });

    const response = await api.createInvoice(
      state().authReducer.access_token,
      payload
    );

    if (response.status === 401) {
      dispatch(resetAuth());
    }

    dispatch({
      type: "RESET_INVOICE",
    });
    return response;
  } catch (error) {
    console.log(error?.response);
  }
};

export const updateInvoice = (payload) => async (dispatch, state) => {
  try {
    const api = new InvoiceApi();

    dispatch({
      type: "SET_INVOICE",
      payload: { loading: true },
    });

    const response = await api.updateInvoice(
      state().authReducer.access_token,
      payload
    );

    if (response.status === 401) {
      dispatch(resetAuth());
    }

    dispatch({
      type: "RESET_INVOICE",
    });
    return response;
  } catch (error) {
    console.log(error?.response);
  }
};

export const deleteInvoice = (route_ids) => async (dispatch, state) => {
  try {
    const api = new InvoiceApi();

    dispatch({
      type: "SET_INVOICE",
      payload: { loading: true },
    });

    const response = await api.deleteInvoice(
      state().authReducer.access_token,
      route_ids
    );

    if (response.status === 401) {
      dispatch(resetAuth());
    }
    return response;
  } catch (error) {
    console.log(error?.response);
  } finally {
    dispatch({
      type: "SET_INVOICE",
      payload: { loading: false },
    });
  }
};

export const assignInvoiceChecker = (payload) => async (dispatch, state) => {
  try {
    const api = new InvoiceApi();

    dispatch({
      type: "SET_INVOICE",
      payload: { loading: true },
    });

    const response = await api.assignInvoiceChecker(
      state().authReducer.access_token,
      payload
    );

    if (response.status === 401) {
      dispatch(resetAuth());
    }

    dispatch({
      type: "RESET_INVOICE",
    });
    return response;
  } catch (error) {
    console.log(error?.response);
  }
};

export const createNegotiationLog = (payload) => async (dispatch, state) => {
  try {
    const api = new InvoiceApi();

    dispatch({
      type: "SET_INVOICE",
      payload: { loading: true },
    });

    const response = await api.createNegotiationLog(
      state().authReducer.access_token,
      payload
    );

    if (response.status === 401) {
      dispatch(resetAuth());
    }

    dispatch({
      type: "RESET_INVOICE",
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const requestNewDoc = (idDoc) => async (dispatch, state) => {
  try {
    const api = new InvoiceApi();

    dispatch({
      type: "SET_INVOICE",
      payload: { loading: true },
    });

    const response = await api.requestNewDoc(
      state().authReducer.access_token,
      idDoc
    );

    if (response.status === 401) {
      dispatch(resetAuth());
    }

    dispatch({
      type: "RESET_INVOICE",
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateCheckingStatus = (payload) => async (dispatch, state) => {
  try {
    const api = new InvoiceApi();

    dispatch({
      type: "SET_INVOICE",
      payload: { loading: true },
    });

    const response = await api.updateCheckingStatus(
      state().authReducer.access_token,
      payload
    );

    if (response.status === 401) {
      dispatch(resetAuth());
    }

    dispatch({
      type: "RESET_INVOICE",
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const makePayment = (payload) => async (dispatch, state) => {
  try {
    const api = new InvoiceApi();

    dispatch({
      type: "SET_INVOICE",
      payload: { loading: true },
    });

    const response = await api.makePayment(
      state().authReducer.access_token,
      payload
    );

    if (response.status === 401) {
      dispatch(resetAuth());
    }

    dispatch({
      type: "RESET_INVOICE",
    });
    return response;
  } catch (error) {
    throw error;
  }
};
