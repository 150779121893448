import React from "react";
import { Row, Col } from "react-bootstrap";
import { BiChevronDown, BiChevronUp } from 'react-icons/bi'
import ModalKondisiKendaraan from './../Modal/ModalKondisiKendaraan'
import iconMutasi from "./../../../../assets/icon/icon-address-mutasi.svg"
import iconRent from "./../../../../assets/icon/icon-address-rent.svg"
import ModalMulaiMutasi from "../Modal/ModalMulaiMutasi";
import ModalSelesaiMutasi from "../Modal/ModalSelesaiMutasi";
import ModalMulaiMutasiCustomerConfirm from "../Modal/ModalMulaiMutasiCustomerConfirm";
import ModalMulaiMutasiPartnerConfirm from "../Modal/ModalMulaiMutasiPartnerConfirm";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { finishMutation, startMutation } from "../../../../redux/action/sewakendaraan_action";
import ModalDokumentasiPenyerahan from "../Modal/ModalDokumentasiPenyerahan";
import ModalInstruksiDokumentasi from "../Modal/ModalInstruksiDokumentasi";
import { message } from "antd";



const DetailAlamat = ({
  trxDetail,
  expandDetailAlamat,
  setExpandDetailAlamat,
  getOrderDetail,
  infoPengantaranMutasi
}) => {

  const auth = useSelector((st) => st.authReducer.access_token);
  const dispatch = useDispatch()
  
  // MULAI MUTASI
  const [payloadMulai, setPayloadMulai] = useState(3)
  const [showMulai, setShowMulai] = useState(false)
  const handleCloseMulai = () => setShowMulai(false);
  const handleShowMulai = () => setShowMulai(true);
  
  const [showMulaiCustomer, setShowMulaiCustomer] = useState(false)
  const handleCloseMulaiCustomer = () => setShowMulaiCustomer(false);
  const handleShowMulaiCustomer = () => setShowMulaiCustomer(true);

  const [showMulaiPartner, setShowMulaiPartner] = useState(false)
  const handleCloseMulaiPartner = () => setShowMulaiPartner(false);
  const handleShowMulaiPartner = () => setShowMulaiPartner(true);

  const handleCloseAllMulai = () => {
    handleCloseMulai()
    handleCloseMulaiCustomer()
    handleCloseMulaiPartner()
  }

  const handleNextMulai = () => {
    handleCloseAllMulai()
    if(payloadMulai === 0) {
      handleShowMulaiPartner(true)
    }
    if(payloadMulai === 1) {
      handleShowMulaiCustomer(true)
    }
  }

  const handlePrevMulai = () => {
    handleCloseAllMulai()
    handleShowMulai(true)
  }

  const handleSubmitMulai = async() => {
    handleCloseAllMulai()
    const token = await auth
    const id = await trxDetail?.data?.trx_booking_rent?.customer_active_mutation?.id
    const payload = await {is_customer_deliver_vehicle: payloadMulai}
    dispatch(startMutation(id,payload,token, getOrderDetail))
  }
  // END MULAI MUTASI



  // SELESAI MUTASI
  const [payloadSelesai, setPayloadSelesai] = useState({
    is_skip : 0,
    received_by : null,
    odometer : null,
    fuel : null,
    photo_interior : [],
    photo_exterior : [],
    receiver_photo : null,
    photo_odometer : null
  })
  
  const [showSelesai, setShowSelesai] = useState(false)
  const handleCloseSelesai = () => setShowSelesai(false);
  const handleShowSelesai = () => setShowSelesai(true);

  const [showSelesaiInstruksi, setShowSelesaiInstruksi] = useState(false)
  const handleCloseSelesaiInstruksi = () => setShowSelesaiInstruksi(false);
  const handleShowSelesaiInstruksi = () => setShowSelesaiInstruksi(true);

  const [showSelesaiDokumentasi, setShowSelesaiDokumentasi] = useState(false)
  const handleCloseSelesaiDokumentasi = () => setShowSelesaiDokumentasi(false);
  const handleShowSelesaiDokumentasi = () => setShowSelesaiDokumentasi(true);


  const handleCloseAllSelesai = () => {
    handleCloseSelesai()
    handleCloseSelesaiInstruksi()
    handleCloseSelesaiDokumentasi()
  }

  const handleNextSelesai = () => {
    handleCloseAllSelesai()
      handleShowSelesaiDokumentasi(true)
  }

  const handleInstruksiDokumentasi = () => {
    handleCloseAllSelesai()
    handleShowSelesaiInstruksi(true)
  }

  const handlePrevSelesai = () => {
    handleCloseAllSelesai()
    handleShowSelesai(true)
  }
  
  const handleSubmitSelesai = async(param) => {
    const token = await auth
    const id = await trxDetail?.data?.trx_booking_rent?.customer_active_mutation?.id

    if(payloadSelesai.fuel > 20 ){
      return message.error("Maksimal Bar Bensin 20")
    }
    
    // if Skip input dokumentasi
    if(param === "skip"){
      const body = {is_skip : 1}
      dispatch(finishMutation(id, body, token, getOrderDetail))
      handleCloseAllSelesai()
      return; 
    }
    const fd = new FormData()
    // console.log(payloadSelesai)
    for (const key in payloadSelesai) {
      
      if(key === "photo_interior"){
        if(payloadSelesai.photo_interior.length){
          payloadSelesai.photo_interior.forEach(e => fd.append(key, e))
        }
      }else if(key === "photo_exterior"){
        if(payloadSelesai.photo_exterior.length){
          payloadSelesai.photo_exterior.forEach(e => fd.append(key, e))
        }
      }else{
        if(payloadSelesai[key]){
          fd.append(key, payloadSelesai[key])
        }
        if(key === "is_skip"){
          fd.append(key, payloadSelesai[key])
        }
      }
    }
    dispatch(finishMutation(id, fd, token, getOrderDetail))
    handleCloseAllSelesai()
  }
  // END SELESAI MUTASI

  const alamatSewa = [
      {
          title: "Alamat Sewa - ",
          value: `${trxDetail?.data?.trx_booking_rent?.pickup_contact_name} (${trxDetail?.data?.trx_booking_rent?.pickup_contact_phone_number})`,
      },
      {
          title: trxDetail?.data?.trx_booking_rent?.return_address_name ? `${trxDetail?.data?.trx_booking_rent?.return_address_name} - ` : "",
          value: trxDetail?.data?.trx_booking_rent?.pickup_address ? trxDetail?.data?.trx_booking_rent?.pickup_address : "-"
      },
      {
          title: "Patokan: ",
          value: trxDetail?.data?.trx_booking_rent?.pickup_address_note ? trxDetail?.data?.trx_booking_rent?.pickup_address_note : "-"
      },
      {
          title: "Catatan (Belum): ",
          value: "-"
      },
  ]

  const alamatMutasi = [
    {
        title: "Alamat Mutasi",
        value: ``,
    },
    {
        title: trxDetail?.data?.trx_booking_rent?.customer_active_mutation?.return_address_name ? trxDetail?.data?.trx_booking_rent?.customer_active_mutation?.return_address_name + " -" : "",
        value: trxDetail?.data?.trx_booking_rent?.customer_active_mutation?.return_address
    },
    {
        title: trxDetail?.data?.trx_booking_rent?.customer_active_mutation?.return_address_note ? "Patokan: " : "",
        value:  trxDetail?.data?.trx_booking_rent?.customer_active_mutation?.return_address_note
    },
    // {
    //     title: "Catatan: ",
    //     value: "-"
    // },
  ]


    return(
      <>
        <Row className="position-relative">
          <div
           className={`${expandDetailAlamat ? "rounded-card bg-white position-absolute":"rounded-card bg-white"} overflow-hidden`} 
           style={expandDetailAlamat ? {width:"100%",height:"98vh", zIndex:10} : {minHeight:"360px", maxHeight:"325px"}}
          >
            <div className="pt-3 pb-2 border-bottom d-flex justify-content-between align-items-center">
                <h5 className="fw-bold">Detail Alamat</h5>
                <ModalKondisiKendaraan trxDetail={trxDetail}/>
            </div>
            <Row className="mt-3 mb-2">
              <Col className="col-1 m-0 d-flex justify-content-center align-items-start">
                <img
                  src={iconRent}
                  alt="rent icon"
                  style={{
                    width: "18px",
                  }}
                />
              </Col>
              <Col className="m-0 p-0">
                {alamatSewa.map((item, i)=>(
                  <div key={i} className="mb-2">          
                    <h6 className="text-muted">
                      <strong>{item.title}</strong>
                      {item.value}
                    </h6>                
                  </div>
                ))}
              </Col>
            </Row>

            <div className="border-bottom"></div>

            {infoPengantaranMutasi.length ? infoPengantaranMutasi.map((item, i)=>(
            <Row className="mt-3 mb-2">                
              <Col className="col-1 m-0 d-flex justify-content-center align-items-start">
                <img
                  src={iconMutasi}
                  alt="mutasi icon"
                  style={{
                    marginLeft: "-1px",
                    width: "18px",
                  }}
                />
              </Col>
              <Col className="m-0 p-0">
                <div key={i} className="mb-4">          
                  <h6 className="text-muted">
                    <strong>Alamat Mutasi {i+1 === 1 ? "" : i+1}</strong>
                  </h6>
                  <h6 className="text-muted">
                    <strong>{item?.return_address_name ? item?.return_address_name +" - " : "" }</strong>
                    {item?.return_address}
                  </h6>
                  <h6 className="text-muted">
                    <strong>{item?.return_address_name ? item?.return_address_name +" - " : "" }</strong>
                    {item.return_address}
                  </h6>                
                  <h6 className="text-muted">
                    <strong>{item?.return_address_note ? "Patokan - " : "" }</strong>
                    {item?.return_address_note}
                  </h6>                
                </div>
              {/* {alamatMutasi.map((item, i)=>(
                <div key={i} className="mb-2">          
                  <h6 className="text-muted">
                    <strong>{item.title}</strong>
                    {item.value}
                  </h6>                
                </div>
              ))} */}
              {!trxDetail?.data?.trx_booking_rent?.trx_booking_rent_mutation?.length && infoPengantaranMutasi.length === i+1 &&
                <div className="d-flex justify-content-center align-items-center rounded" style={{backgroundColor:"#F1F3F4", height:"40px", cursor:"not-allowed", width:"98%"}}>
                  <h6 className="text-muted m-0"><strong>Tidak Ada Mutasi</strong></h6>
                </div>
              }
              { trxDetail?.data?.trx_booking_rent?.customer_active_mutation?.mutation_status === 0 && infoPengantaranMutasi.length === i+1 &&
                <ModalMulaiMutasi
                  handleNextMulai={handleNextMulai}
                  payloadMulai={payloadMulai} 
                  setPayloadMulai={setPayloadMulai}
                  trxDetail={trxDetail}
                  show={showMulai}
                  handleClose={handleCloseMulai}
                  handleShow={handleShowMulai}
                />
              }
              { trxDetail?.data?.trx_booking_rent?.customer_active_mutation?.mutation_status === 2 &&
                trxDetail?.data?.trx_booking_rent?.customer_active_mutation?.is_customer_deliver_vehicle === 1 &&
                infoPengantaranMutasi.length === i+1 &&
                <ModalSelesaiMutasi
                  handleNextSelesai={handleNextSelesai}
                  handleInstruksiDokumentasi={handleInstruksiDokumentasi}
                  show={showSelesai}
                  handleClose={handleCloseSelesai}
                  handleShow={handleShowSelesai}
                  submit={handleSubmitSelesai}
                />
              }              
              </Col>
            </Row>
            )) : null}

            
            <div className="border-top pt-2 pb-1 position-absolute bg-white overflow-hidden w-100 bottom-0">
              <h6
                className="fw-bold text-center"
                style={{color:"#FF1DBC", textDecoration:"underline", fontSize:"11px", cursor:"pointer"}}
                onClick={()=> setExpandDetailAlamat(!expandDetailAlamat)}
                >
                  {expandDetailAlamat ? "Tampilkan Lebih Sedikit": "Tampilkan Lebih Banyak"}
                  {expandDetailAlamat ? 
                    <BiChevronUp style={{fontSize:"14px"}}/>
                      : 
                    <BiChevronDown style={{fontSize:"14px"}}/>
                  }
              </h6>
            </div>
          </div>
        </Row>

        
        {/* Mulai Mutasi */}
        
        <ModalMulaiMutasiCustomerConfirm
          handlePrevMulai={handlePrevMulai}
          show={showMulaiCustomer}
          handleClose={handleCloseMulaiCustomer}
          submit={handleSubmitMulai}
        />
        <ModalMulaiMutasiPartnerConfirm
          show={showMulaiPartner}
          handleClose={handleCloseMulaiPartner}
          submit={handleSubmitMulai}
        />
        
        {/* End Mulai Mutasi */}

        <ModalInstruksiDokumentasi
          handlePrevSelesai={handlePrevSelesai}
          show={showSelesaiInstruksi}
          handleClose={handleCloseSelesaiInstruksi}
        />
        <ModalDokumentasiPenyerahan
          payloadSelesai={payloadSelesai}
          setPayloadSelesai={setPayloadSelesai}
          show={showSelesaiDokumentasi}
          handleClose={handleCloseSelesaiDokumentasi}
          submit={handleSubmitSelesai}
        />
      </>
    );
}

export default DetailAlamat;
