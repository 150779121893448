import { BaseApiRequest } from "./base-api";
import { OpResult } from "../helper/operation_result";

export default class TrxListApi extends BaseApiRequest {
  constructor() {
    super(process.env.REACT_APP_API_END_POINT);
  }

  async getAllOrderList(token, query = "") {
    let result = OpResult.failed("Failed GET customer order");
    try {
      let resApi = await this.get(`/customer/order?${query}`, token);
      if (resApi.status) {
        result = resApi;
      }
    } catch (e) {
      result = OpResult.failed("Internal Error");
    }
    return result;
    // return result.data; before
  }

  async getSendOrderList(token, query = "") {
    let result = OpResult.failed("Failed GET customer order");
    try {
      let resApi = await this.get(`/customer/order?type_order=SEND_GOODS&${query}`, token);
      if (resApi.status) {
        result = resApi;
      }
    } catch (e) {
      result = OpResult.failed("Internal Error");
    }
    return result;
    // return result.data; before
  }

  async getRentOrderList(token, query = "") {
    let result = OpResult.failed("Failed GET customer order");
    try {
      let resApi = await this.get(`/customer/order?type_order=RENT&${query}`, token);
      if (resApi.status) {
        result = resApi;
      }
    } catch (e) {
      result = OpResult.failed("Internal Error");
    }
    return result;
    // return result.data; before
  }

  async getSendOrderDetail(token, id) {
    let result = OpResult.failed("Failed GET customer order detail");
    try {
      let resApi = await this.get(`/customer/order/send/${id}`, token);
      if (resApi.status) {
        result = resApi;
      }
    } catch (e) {
      result = OpResult.failed("Internal Error");
    }
    return result;
  }

  async getSendObstacleDocument(token, id) {
    let result = OpResult.failed("Failed GET obstacle document");
    try {
      let resApi = await this.get(`/customer/order/send/${id}/doc-and-obstacle`, token);
      if (resApi.status) {
        result = resApi;
      }
    } catch (e) {
      result = OpResult.failed("Internal Error");
    }
    return result;
  }

  async getRentOrderDetail(token, id) {
    let result = OpResult.failed("Failed GET customer order detail");
    try {
      let resApi = await this.get(`/customer/order/rent/${id}`, token);
      if (resApi.status) {
        result = resApi;
      }
    } catch (e) {
      result = OpResult.failed("Internal Error");
    }
    return result;
  }

  async getPartnerDetail(token, id) {
    let result = OpResult.failed("Failed GET partner detail");
    try {
      let resApi = await this.get(`/customer/order/partner/${id}`, token);
      if (resApi.status) {
        result = resApi;
      }
    } catch (e) {
      result = OpResult.failed("Internal Error");
    }
    return result;
  }

  async cancelRentOrder(token, payload, id) {
    let result = OpResult.failed("Failed Cancel Order");

    try {
      let resApi = await this.patch(
        `/customer/order/rent/${id}/cancel`,
        payload,
        token
      );
      if (resApi.status) {
        result = resApi;
      }
    } catch (error) {
      result = OpResult.failed("Internal Error");
    }

    return result;
  }

  async cancelSendOrder(token, payload, id) {
    let result = OpResult.failed("Failed Cancel Order");

    try {
      let resApi = await this.patch(
        `/customer/order/send/${id}/cancel`,
        payload,
        token
      );
      if (resApi.status) {
        result = resApi;
      }
    } catch (error) {
      result = OpResult.failed("Internal Error");
    }

    return result;
  }

  async getListPartner(token) {
    let result = OpResult.failed("Failed GET customer order");
    try {
      let resApi = await this.get(`/customer/order/partner/`, token);
      if (resApi.status) {
        result = resApi;
      }
    } catch (e) {
      result = OpResult.failed("Internal Error");
    }
    console.log(result.data)
    return result.data;
  }
}
