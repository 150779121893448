/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useEffect } from "react";
import {
  Card,
  Col,
  FloatingLabel,
  Form,
  InputGroup,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import { FiChevronLeft } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  getProcurementContractDetail,
  getProcurementDocumentDetail,
  getProcurementHeaderDetail,
} from "../../../redux/action/Management/procurement_action";
import { convertFromRaw, EditorState } from "draft-js";
import { markdownToDraft } from "markdown-draft-js";
import "./styles.scss";
import InformasiUtamaDetail from "./InformasiUtamaDetail";
import DokumenKontrakDetail from "./DokumenKontrakDetail";
import PenawaranKontrakListDetail from "./PenawaranKontrakListDetail";
import { Spin } from "antd";
import moment from "moment";
import { FaSearch } from "react-icons/fa";

const DetailProcurement = () => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const loading = useSelector((st) => st.procurementReducer.loading);

  const [header, setHeader] = useState("");
  const [document, setDocument] = useState("");
  // const [contract, setContract] = useState("");
  const [search, setSearch] = useState(undefined);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const [section, setSection] = useState("1");
  const handleBack = () => {
    history.push("/penawaran");
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleStatusLabel = (status) => {
    switch (status) {
      case "DRAFT":
        return "Draft";
      case "WAITING SCHEDULE":
        return "Menunggu Jadwal";
      case "CANCEL":
        return "Dibatalkan";
      case "PROCESS":
        return "Proses Penawaran";
      case "PARTNER SELECTION":
        return "Seleksi Partner";
      case "DONE":
        return "Kontrak Selesai";
      default:
        return "";
    }
  };
  const handleStatusColor = (status) => {
    switch (status) {
      case "DRAFT":
      case "PROCESS":
        return "text-orange text-capitalize";
      case "WAITING SCHEDULE":
        return "text-blue text-capitalize";
      case "CANCEL":
        return "text-red text-capitalize";
      case "DONE":
        return "text-green text-capitalize";
      case "PARTNER SELECTION":
        return "text-blue text-capitalize";
      default:
        return "sub-label text-capitalize";
    }
  };

  useEffect(() => {
    async function getHeaderDetail() {
      const response = await dispatch(getProcurementHeaderDetail(params.id));
      if (response) {
        const rawData = markdownToDraft(response.tnc);
        const contentState = convertFromRaw(rawData);
        const newEditorState = EditorState.createWithContent(contentState);
        setEditorState(newEditorState);
        setHeader(response);
      }
    }

    async function getDocumentDetail() {
      const response = await dispatch(getProcurementDocumentDetail(params.id));
      setDocument(response);
    }

    getHeaderDetail();
    getDocumentDetail();
  }, []);

  const sectionOpts = [
    {
      title: "Informasi Utama",
      value: 1,
    },
    {
      title: "Dokumen Kontrak",
      value: 2,
    },
    {
      title: "Penawaran Kontrak",
      value: 3,
    },
  ];

  return (
    <div
      style={{ minHeight: "100vh", width: "100%", padding: "30px" }}
      className="bg-ice procurement-detail height-100"
    >
      {header && (
        <Card
          className="px-4 pt-4 pb-0 rounded-card border-0"
          style={{ backgroundColor: "white" }}
        >
          <div className="d-flex align-items-center">
            <FiChevronLeft
              fontSize={25}
              className="pointer me-3"
              onClick={handleBack}
            />
            <div className="w-100">
              <Row>
                <div>
                  <div className="d-flex align-items-center">
                    <h1 className="me-3">{header.name}</h1>
                    <p className={`status ${handleStatusColor(header.status)}`}>
                      {handleStatusLabel(header.status)}
                    </p>
                  </div>
                  <p className="mb-0 mt-1">
                    Dibuat tanggal:{" "}
                    {moment(header.created_at).format("DD/MM/YYYY")}
                  </p>
                </div>
              </Row>
              <Row className="card-body pb-0 ps-0">
                <Col xs={12}>
                  <Tabs
                    activeKey={section}
                    className="tabs-trx border-bottom-2"
                    onSelect={(value) => setSection(value)}
                  >
                    {sectionOpts.map((item, index) => (
                      <Tab
                        key={index}
                        eventKey={item.value}
                        title={item.title}
                      />
                    ))}
                  </Tabs>
                </Col>
              </Row>
              {section === "3" && (
                <Row
                  className="py-3 ps-3 align-items-center"
                  style={{ width: "400px" }}
                >
                  <InputGroup className="border rounded-card">
                    <FloatingLabel
                      className="rounded-card"
                      label={"Cari penawaran"}
                      style={{ width: "", fontSize: "12px", height: "50px" }}
                    >
                      <Form.Control
                        className="border-0 rounded-card"
                        type="text"
                        style={{ height: "50px" }}
                        placeholder="."
                        onChange={(event) => handleSearch(event)}
                      />
                    </FloatingLabel>
                    <InputGroup.Text
                      className="border-0 search-icon-trx rounded-card ms-auto"
                      style={{ height: "50px" }}
                    >
                      <FaSearch />
                    </InputGroup.Text>
                  </InputGroup>
                </Row>
              )}
            </div>
          </div>
        </Card>
      )}
      {loading && section !== "3" && (
        <Card
          className="p-4 rounded-card border-0 d-flex align-items-center justify-content-center mt-4"
          style={{
            height: "350px",
          }}
        >
          <Spin />
        </Card>
      )}
      {section === "1" && header && (
        <InformasiUtamaDetail data={header} {...{ editorState }} />
      )}
      {section === "2" && document && <DokumenKontrakDetail data={document} />}
      {section === "3" && <PenawaranKontrakListDetail {...{ search }} />}
    </div>
  );
};

export default DetailProcurement;
