/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Avatar, Badge } from "antd";
import SidebarNotification from "../notifDropdown/SidebarNotification";
import { Dropdown, Button } from "react-bootstrap";
import Breakpoint from "../responsiveUtils/Breakpoint";
import useBreakpoint from "../responsiveUtils/useBreakpoint";
// icons
import { FiChevronRight, FiChevronDown } from "react-icons/fi";
import { AiOutlineUser } from "react-icons/ai";
import { hideSidenavMobile } from "./layout_helper";

// Images
import logoElog from "../../assets/images/logo-elog-revised.svg";
import logoElogRound from "../../assets/images/favicon.svg";
import sidebarOn from "../../assets/images/sidebar-icon/icon-side-on.svg";
import sidebarOff from "../../assets/images/sidebar-icon/icon-side-off.svg";
import icon_kirim_barang_off from "../../assets/images/sidebar-icon/icon_kBarang_off.png";
import icon_kirim_barang_on from "../../assets/images/sidebar-icon/icon_kBarang_on.png";
import icon_sewa_off from "../../assets/images/sidebar-icon/icon_sewa_off.png";
import icon_sewa_on from "../../assets/images/sidebar-icon/icon_sewa_on.png";
import icon_list_off from "../../assets/images/sidebar-icon/icon_List_off.png";
import icon_list_on from "../../assets/images/sidebar-icon/icon_List_on.png";
import icon_ulasan_off from "../../assets/images/sidebar-icon/icon_Ulasan_off.png";
import icon_ulasan_on from "../../assets/images/sidebar-icon/icon_Ulasan_on.png";
import icon_favorit_off from "../../assets/images/sidebar-icon/icon_favorit_off.png";
import icon_favorit_on from "../../assets/images/sidebar-icon/icon_favorit_on.png";
import icon_voucher_off from "../../assets/images/sidebar-icon/icon_Voucher_off.png";
import icon_voucher_on from "../../assets/images/sidebar-icon/icon_Voucher_on.png";
import icon_settings_off from "../../assets/images/sidebar-icon/icon_Setting_off.png";
import icon_settings_on from "../../assets/images/sidebar-icon/icon_Setting_on.png";
import icon_logout from "../../assets/images/sidebar-icon/icon-keluar.svg";
import icon_procurement from "../../assets/images/sidebar-icon/icon_procurement.png";
import icon_kendaraan from "../../assets/images/sidebar-icon/icon_kendaraan.svg";
import icon_invoice from "../../assets/images/sidebar-icon/icon_invoice.png";
import icon_rute from "../../assets/images/sidebar-icon/icon_rute.png";
import icon_complete_data from "../../assets/images/sidebar-icon/icon_complete_data.svg";

function Sidebar(props) {
  const history = useHistory();
  const location = useLocation();
  const currentPath = location.pathname.replace("/", "");
  const { isDesktop, isTablet, isMobile } = useBreakpoint();

  const [activeSidebar, setActiveSidebar] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const toggleSidebar = () => setActiveSidebar(!activeSidebar);

  const logoutSubmitHandler = () => {
    localStorage.clear();
    window.location = "/"; // THIS works
  };

  const goToPage = () => {
    if (!props.authReducer.access_token) {
      history.push("/login");
    } else {
      history.push("/pengaturan/profile");
    }
  };

  const showDropdown = () => {
    setDropdownVisible(true);
  };

  const closeDropdown = () => {
    setDropdownVisible(false);
  };

  // SSO
  const completePersonaldata = async () => {
    const token = await props.authReducer.sso_access_token
    window.location.href = `https://dev.elog.co.id/full-fill-acount/customer/${token}`;
  }
  // END - SSO

  useEffect(() => {
    if (isTablet) {
      setActiveSidebar(true);
    }
  }, [isTablet]);

  return (
    <>
      {!isMobile ? (
        <div
          id="sidebar"
          className={!activeSidebar ? "sidebar" : "sidebar active"}
        >
          <div className="px-3 px-lg-4 py-4">
            <img
              className={
                !activeSidebar ? "logo-dashboard" : "logo-dashboard-sm"
              }
              src={!activeSidebar ? logoElog : logoElogRound}
              alt=""
            />
          </div>
          <div className="mx-3 mb-3">
            <div className="underline-0 clearfix" style={{ borderBottom: "1px solid #eaeaea" }}>
              <div
                className={
                  !activeSidebar
                    ? "pb-3 d-flex p-0"
                    : "pb-3 d-flex mt-5 e-side-on border-0"
                }
              >
                <div className="d-flex pointer " onClick={goToPage}>
                  <div className="">
                    <Avatar
                      className="avatar-blue rounded"
                      shape="square"
                      size={50}
                      src={props.authReducer.profile_picture}
                      icon={
                        !props.authReducer.profile_picture && "G"
                      }
                    ></Avatar>
                  </div>
                  {!activeSidebar ? (
                    <span className="align-self-center ms-3 text-muted">
                      {props.authReducer.name}
                    </span>
                  ) : null}
                </div>

                {!activeSidebar ? (
                  <div
                    className="w-100 text-end align-self-center"
                    hidden={!props.authReducer.access_token}
                  >
                    <Badge
                      count={props.notif.total_unread}
                      style={{ backgroundColor: "#ce1771" }}
                      className=""
                    />
                  </div>
                ) : (
                  <Badge
                    count={props.notif.total_unread}
                    style={{
                      backgroundColor: "#ce1771",
                      marginLeft: "-10px",
                      marginTop: "-5px",
                    }}
                    hidden={!props.authReducer.access_token}
                    className=""
                  />
                )}
                <div
                  className={`text-end align-self-center p-2 ${
                    !props.authReducer.access_token && "w-100"
                  }`}
                >
                  {props.authReducer.access_token ? (
                    <Dropdown>
                      <Dropdown.Toggle
                        className="pointer"
                        variant="ghost"
                        as={FiChevronDown}
                      ></Dropdown.Toggle>

                      <SidebarNotification
                        show={dropdownVisible}
                        handleShow={showDropdown}
                        handleClose={closeDropdown}
                      />
                    </Dropdown>
                  ) : (
                    <FiChevronDown
                      className="pointer"
                      style={{ color: "#333" }}
                      onClick={() => {
                        if (!props.authReducer.access_token)
                          history.push("/login");
                        else setDropdownVisible((prev) => !prev);
                      }}
                    />
                  )}
                </div>
              </div>
              {/* SSO */}
              {!props.authReducer.access_token && props.authReducer.sso_access_token &&
                <div 
                  className={`pointer mb-3 d-flex justify-content-center ${!activeSidebar ? "w-100" : "w-25 float-end me-4"}`} 
                  style={{border:"1px dotted #CE1771", borderRadius:"20px", padding:0}}
                  onClick={completePersonaldata}
                >
                  {!activeSidebar ? 
                    (<h5 style={{color:"#CE1771"}} className="fw-bold py-1 mt-2">Lengkapi Akun Anda</h5>)
                    : <img src={icon_complete_data} alt="icon_complete_data" className="py-1 mt-2 ms-2"/> 
                  }
                </div>
              }
              {/* END - SSO */}
            </div>
          </div>
          {isDesktop && (
            <div
              id="toggle-sidebar"
              onClick={toggleSidebar}
              className={!activeSidebar ? "" : "active"}
            >
              <img src={!activeSidebar ? sidebarOn : sidebarOff} alt="" />
            </div>
          )}
          <div style={{maxHeight:"70vh", overflowY:"scroll", overflowX:"hidden"}}>
            <h5
              className={
                !activeSidebar ? "ms-3 text-muted" : "mb-3 text-muted e-side-on"
              }
            >
              Layanan
            </h5>
            <div className={!activeSidebar ? "" : "sidebar-on"}>
              <NavLink to="/kirim-barang" className="sidebar-link text-muted">
                <img
                  className="i-sidebar"
                  src={
                    currentPath === "kirim-barang"
                      ? icon_kirim_barang_on
                      : icon_kirim_barang_on
                  }
                  alt=""
                />
                {!activeSidebar ? "Kirim Barang" : ""}
              </NavLink>
              <NavLink to="/sewa-kendaraan" className="sidebar-link text-muted">
                <img
                  className="i-sidebar"
                  src={
                    currentPath === "sewa-kendaraan" ? icon_sewa_on : icon_sewa_on
                  }
                  alt=""
                />
                {!activeSidebar ? "Sewa Kendaraan" : ""}
              </NavLink>
              <NavLink to="/daftar-transaksi" className="sidebar-link text-muted">
                <img className="i-sidebar" src={icon_list_on} alt="" />
                {!activeSidebar ? "Daftar Transaksi" : ""}
              </NavLink>
              {/* <NavLink disabled to="/ulasan" className="sidebar-link text-muted">
                <img className="i-sidebar" src={icon_ulasan_on} alt="" />
                {!activeSidebar ? "Ulasan" : ""}
              </NavLink>
              <NavLink disabled to="/favorit" className="sidebar-link text-muted">
                <img className="i-sidebar" src={icon_favorit_on} alt="" />
                {!activeSidebar ? "Favorit" : ""}
              </NavLink> */}
              {/* <NavLink disabled to="/404" className="sidebar-link text-muted">
            <img className="i-sidebar" src={icon_voucher_on} alt="" />
            {!activeSidebar ? "Promo" : ""}
          </NavLink> */}
            </div>
            
            <div className={!activeSidebar ? "" : "sidebar-on"}>
              {props.authReducer.access_token && (
                <>
                  <h5
                    className={
                      !activeSidebar
                        ? "ms-3 mt-3 text-muted"
                        : "mb-3 mt-3 text-muted e-side-on"
                    }
                  >
                    Finance
                  </h5>       
                  <NavLink to="/dokumen-pembayaran" className="sidebar-link text-muted">
                    <img className="i-sidebar" src={icon_settings_on} alt="" />{" "}
                    {!activeSidebar ? "Dokumen & Pembayaran" : ""}
                  </NavLink>
                </>
              )}
            </div>            
            <div className={!activeSidebar ? "" : "sidebar-on"}>
              {props.authReducer.access_token && (
                <>
                  <h5
                    className={
                      !activeSidebar
                        ? "ms-3 mt-3 text-muted"
                        : "mb-3 mt-3 text-muted e-side-on"
                    }
                  >
                    Partner
                  </h5>
                  <NavLink to="/partner" className="sidebar-link text-muted">
                    <img
                      className="i-sidebar p-2"
                      src={icon_procurement}
                      alt=""
                    />{" "}
                    {!activeSidebar ? "Partner Official" : ""}
                  </NavLink>
                </>
              )}
            </div>

            <h5
              className={
                !activeSidebar
                  ? "ms-3 mt-3 text-muted"
                  : "mb-3 mt-3 text-muted e-side-on"
              }
            >
              Management
            </h5>
            <div className={!activeSidebar ? "" : "sidebar-on"}>
              {/* <NavLink to="/integrasi-api" className="sidebar-link text-muted">
                <img className="i-sidebar" src={icon_list_on} alt="" />{" "}
                {!activeSidebar ? "Integrasi API" : ""}
              </NavLink> */}
              <div className={!activeSidebar ? "" : "sidebar-on"}>
                <NavLink
                  to="/user-management"
                  className="sidebar-link text-muted"
                >
                  <img className="i-sidebar" src={icon_settings_on} alt="" />{" "}
                  {!activeSidebar ? "Role & User" : ""}
                </NavLink>
              </div>
              {props.authReducer.access_token && (
                <NavLink to="/penawaran" className="sidebar-link text-muted">
                  <img className="i-sidebar p-2" src={icon_procurement} alt="" />{" "}
                  {!activeSidebar ? "Penawaran" : ""}
                </NavLink>
              )}
              {props.authReducer.access_token && (
                <NavLink
                  to="/pengaturan/rute-alamat"
                  className="sidebar-link text-muted"
                >
                  <img className="i-sidebar p-2" src={icon_rute} alt="" />{" "}
                  {!activeSidebar ? "Rute & Alamat" : ""}
                </NavLink>
              )}
              {/* {props.authReducer.access_token && (
                <NavLink to="/invoices" className="sidebar-link text-muted">
                  <img className="i-sidebar p-2" src={icon_invoice} alt="" />{" "}
                  {!activeSidebar ? "Invoice & Dokumen" : ""}
                </NavLink>
              )} */}
            </div>


            <h5
              className={
                !activeSidebar
                  ? "ms-3 mt-3 text-muted"
                  : "mb-3 mt-3 text-muted e-side-on"
              }
            >
              Pengaturan
            </h5>
            <div className={!activeSidebar ? "" : "sidebar-on"}>
              <NavLink
                to="/pengaturan/profile"
                className="sidebar-link text-muted"
              >
                <img className="i-sidebar" src={icon_settings_on} alt="" />{" "}
                {!activeSidebar ? "Pengaturan" : ""}
              </NavLink>
              {props.authReducer.access_token === null ? null : (
                <a
                  href=""
                  onClick={logoutSubmitHandler}
                  className="sidebar-link text-muted"
                >
                  <img
                    className="i-sidebar"
                    src={icon_logout}
                    alt=""
                    style={{ padding: "10px" }}
                  />{" "}
                  {!activeSidebar ? "Keluar" : ""}
                </a>
              )}
            </div>
          </div>
        </div>
      ) : (
        <>
          {/* overlay */}
          {/* <Button
            style={{ marginLeft: 120, position: "fixed", zIndex: 200 }}
            onClick={openSidenavMobile}
          >
            klikkk
          </Button> */}
          <div
            className="sidebar-overlay hidden w-100"
            onClick={hideSidenavMobile}
          ></div>
          <div id="sidebar" className={"sidebar active mobile "}>
            <div className="px-3 px-lg-4 py-4">
              <img
                className={"logo-dashboard-mobile"}
                src={logoElogRound}
                alt=""
              />
            </div>
            <div className="mx-3 mb-3">
              <div className="underline-0">
                <div
                  style={{ borderBottom: "1px solid #eaeaea" }}
                  className={"pb-3 d-flex mt-5 e-side-on border-0"}
                >
                  <div className="d-flex pointer " onClick={goToPage}>
                    <div className="">
                      <Avatar
                        className="avatar-blue"
                        shape="square"
                        size={50}
                        src={props.authReducer.profile_picture}
                        icon={
                          !props.authReducer.profile_picture && (
                            <AiOutlineUser />
                          )
                        }
                      ></Avatar>
                    </div>
                  </div>
                  <Badge
                    count={props.notif.total_unread}
                    style={{
                      backgroundColor: "#ce1771",
                      marginLeft: "-10px",
                      marginTop: "-5px",
                    }}
                    hidden={!props.authReducer.access_token}
                    className=""
                  />
                  <div
                    className={`text-end align-self-center p-2 ${
                      !props.authReducer.access_token && "w-100"
                    }`}
                  >
                    {props.authReducer.access_token ? (
                      <Dropdown>
                        <Dropdown.Toggle
                          className="pointer"
                          variant="ghost"
                          as={FiChevronDown}
                        ></Dropdown.Toggle>

                        <SidebarNotification
                          show={dropdownVisible}
                          handleShow={showDropdown}
                          handleClose={closeDropdown}
                        />
                      </Dropdown>
                    ) : (
                      <FiChevronDown
                        className="pointer"
                        style={{ color: "#333" }}
                        onClick={() => {
                          if (!props.authReducer.access_token)
                            history.push("/login");
                          else setDropdownVisible((prev) => !prev);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <h5 className={"mb-3 text-muted e-side-on"}>Layanan</h5>
            <div className={"sidebar-on"}>
              <NavLink to="/kirim-barang" className="sidebar-link text-muted">
                <img
                  className="i-sidebar"
                  src={
                    currentPath === "kirim-barang"
                      ? icon_kirim_barang_on
                      : icon_kirim_barang_on
                  }
                  alt=""
                />
              </NavLink>
              <NavLink to="/sewa-kendaraan" className="sidebar-link text-muted">
                <img
                  className="i-sidebar"
                  src={
                    currentPath === "sewa-kendaraan"
                      ? icon_sewa_on
                      : icon_sewa_on
                  }
                  alt=""
                />
              </NavLink>
              <NavLink
                to="/daftar-transaksi"
                className="sidebar-link text-muted"
              >
                <img className="i-sidebar" src={icon_list_on} alt="" />
              </NavLink>
              {/* <NavLink
                disabled
                to="/ulasan"
                className="sidebar-link text-muted"
              >
                <img className="i-sidebar" src={icon_ulasan_on} alt="" />
              </NavLink>
              <NavLink
                disabled
                to="/favorit"
                className="sidebar-link text-muted"
              >
                <img className="i-sidebar" src={icon_favorit_on} alt="" />
              </NavLink> */}
              {/* <NavLink disabled to="/404" className="sidebar-link text-muted">
          <img className="i-sidebar" src={icon_voucher_on} alt="" />
          {!activeSidebar ? "Promo" : ""}
        </NavLink> */}
            </div>
            <h5 className={"mb-3 mt-4 text-muted e-side-on"}>Management</h5>
            <div className={"sidebar-on"}>
              {/* <NavLink to="/integrasi-api" className="sidebar-link text-muted">
                <img className="i-sidebar" src={icon_list_on} alt="" />{" "}
              </NavLink> */}
              <NavLink
                to="/user-management"
                className="sidebar-link text-muted"
              >
                <img className="i-sidebar" src={icon_settings_on} alt="" />{" "}
              </NavLink>
            </div>
            <h5 className={"mb-3 mt-4 text-muted e-side-on"}>Pengaturan</h5>
            <div className={"sidebar-on"}>
              <NavLink
                to="/pengaturan/rute"
                className="sidebar-link text-muted"
              >
                <img className="i-sidebar" src={icon_settings_on} alt="" />{" "}
              </NavLink>
              <NavLink
                to="/pengaturan/profile"
                className="sidebar-link text-muted"
              >
                <img className="i-sidebar" src={icon_settings_on} alt="" />{" "}
              </NavLink>
              {props.authReducer.access_token === null ? null : (
                <a
                  href=""
                  onClick={logoutSubmitHandler}
                  className="sidebar-link text-muted"
                >
                  <img
                    className="i-sidebar"
                    src={icon_logout}
                    alt=""
                    style={{ padding: "10px" }}
                  />{" "}
                </a>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  notif: state.notifReducer,
});
export default connect(mapStateToProps)(Sidebar);
