import React, { useState } from "react";
import {
  Modal,
  Button
} from "react-bootstrap";
import { useSelector } from "react-redux";
import noData from "../../../../../assets/images/no-data.png";
import { message } from "antd";
import Info from "../../../../../components/info";

const StatusPayment = ({show, setShow, onSubmit, file, setFile, setModalProofPayment}) => {

  const detail = useSelector((st) => st.documentPaymentReducer.detail);
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const handleMapingPaymentStatus = (param,val) => {
    let result = {
      status: "-",
      color: ""
    }
    if(val === 0){
      result.status = "Menunggu Anda"
      result.color = "#FFAC14"
    }
    if(val === 1 || val === 2){
      result.status = "Menunggu eLOG"
      result.color = "#FFAC14"
    }
    if(val === 3){
      result.status = "Berhasil"
      result.color = "#0FAA4B"
    }
    if(param === 'status') return result.status
    if(param === 'color') return result.color
  }

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setFile(null)
      return;
    }
    if (e.target.files[0].size > 5000000) {
      message.error("Ukuran Maksimal foto adalah 5Mb");
    } else {
      setFile(e.target.files[0])
      // setGetFile(true)
    }
  };
  
  const handleSubmit = () => {
    onSubmit()
    handleClose()
  }

  return(
  <>
    <Button
      size="sm"
      className="fw-bold py-1 h-25 px-4 rounded-pill text-capitalize"
      onClick={handleShow}
      variant="outline-primary"
    >
      Status Pembayaran
    </Button>
    
    <Modal
      contentClassName="rounded-card p-2"
      show={show}
      onHide={handleClose}
      centered
    >
      <Modal.Header closeButton className="bg-white border-0">
      </Modal.Header>
      <div className="py-4">
          <div className="d-flex flex-column align-items-center">
            <img src={noData} alt="no-data" width={345} />
            <h3 className="mb-3 fw-bold" style={{color: handleMapingPaymentStatus('color', detail?.payment_status)}}>{handleMapingPaymentStatus("status", detail?.payment_status) === "Menunggu eLOG" ? 'Menunggu Proses eLOG...' : handleMapingPaymentStatus("status", detail?.payment_status)}</h3>
            <h5 className="px-4 m-0 text-center">eLOG sedang melakukan konfirmasi pembayaran anda dan memproses pembayaran ke partner. Proses pembayaran akan otomatis berakhir maks. <span className="fw-bold">2 x 24 jam</span>.</h5>
          </div>
          <hr />
          <div className="px-4 text-center fw-bold">
            {detail?.customer_payment_method} (Bank Transfer)
          </div>
          <hr />
          <div className="px-4">
            <label className="w-100" >
              {!detail?.customer_payment_confirmation_receipt && !file &&
                <>
                  <div className="rounded d-flex justify-content-center align-items-center pointer p-2" style={{border:"1px dotted #FF1D8C"}}>
                    <h5 className="m-0 text-primary">Upload Bukti Pembayaran</h5>
                  </div>
                  <input
                    type="file"
                    style={{ visibility: "hidden" }}
                    onChange={(e)=>onSelectFile(e)}
                  />
                </>
                }
              {detail?.customer_payment_confirmation_receipt &&
                <div className="rounded d-flex justify-content-between align-items-center mb-3" style={{border:"1px solid #FF1D8C"}}>
                  <h5 className="m-0 px-4 text-primary">{detail?.customer_payment_confirmation_receipt?.length > 40 ? `${detail?.customer_payment_confirmation_receipt.substring(0,40)}..` : detail?.customer_payment_confirmation_receipt }</h5>
                  <div className="pointer" onClick={()=>{handleClose();setModalProofPayment(true)}}>
                    <h5 className="m-0 p-2 px-3 text-white bg-primary">Lihat</h5>
                  </div>
                </div>
              }
            </label>
            {file &&
                <div className="rounded mb-4 pointer" style={{border:"1px solid #FF1D8C"}}>
                  <label className="d-flex align-items-center justify-content-between pointer">
                    <h5 className="m-0 px-4 text-primary">{file?.name.length > 20 ? file?.name.substring(0,20) : file?.name}</h5>
                  <div>
                      <h5 className="m-0 p-2 px-3 text-white bg-primary">Ganti</h5>
                  </div>
                  <input
                  type="file"
                  style={{ visibility: "hidden", display:'none' }}
                  onChange={onSelectFile}
                  ></input>
                  </label>
                </div>
              }
              {file &&
                <Button
                  className="w-100 mb-3"
                  style={{ marginTop:"-15px" }}
                  onClick={()=> handleSubmit()}
                >
                  Submit Upload
                </Button>
              }
              <Info info="Upload bukti pembayaran agar eLOG dapat mempercepat proses pengecekan pembayaran tagihan anda"/>
          </div>
      </div>
    </Modal>
  </>
  )
}

export default StatusPayment;
