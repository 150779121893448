import React from "react";
import { Button, Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import { useHistory } from "react-router-dom";

const BatalkanProcurement = (props) => {
  /*======================== Props ======================== */

  const { isOpen, handleClose } = props;
  const history = useHistory();

  /*======================== UseState ======================== */

  /*======================== Handler ======================== */

  const handleOnClose = () => {
    handleClose();
  };

  const handleBatalkan = async () => {
    history.push("/penawaran", {
      section: "penawaran anda",
    });
  };

  /*======================== UseEffect ======================== */

  /*======================== Return ======================== */

  return (
    <Modal
      contentClassName="rounded-card p-2"
      show={isOpen}
      onHide={handleOnClose}
      className="p-3"
      centered
    >
      <div className="d-flex p-2 border-bottom">
        <h2 className="fw-bold">Batalkan</h2>
        <MdClose className="ms-auto me-2 pointer" onClick={handleOnClose} />
      </div>
      <Modal.Body>
        <div className="jenis-barang-opts mt-1">
          <p className="my-5">
            Apakah anda yakin untuk tidak melanjutkan pengisian data penawaran?
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size="sm"
          className="w-100 fw-bold py-2 px-4 rounded-pill text-capitalize mt-2"
          style={{ background: "transparent", color: "#ce1771" }}
          onClick={handleBatalkan}
        >
          Batalkan Pengisian
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BatalkanProcurement;
