import React from "react";
import {
  Modal
} from "react-bootstrap";
import { formatRupiah } from "../../../../../helper/formatCurrency";

const DetailBillingPayment = ({show, setShow, data}) => {
  const handleClose = () => setShow(false);

    return(
      <>
        <h6
          size="sm"
          className="py-1 m-0 h-25 text-primary pointer underline"
          onClick={()=>setShow(true)}
        >
          Lihat Rincian
        </h6>
        <Modal
          contentClassName="rounded-card p-2"
          show={show}
          onHide={handleClose}
          centered
        >
          <Modal.Header closeButton className="bg-white">
            <strong>Rincian Biaya Penagihan</strong>
          </Modal.Header>
          <div className="p-4">
          
            <h6 className="fw-bold mb-2">Transaksi</h6>
            {data?.trx_order_billing?.map((item, i)=>
              <div key={i} className="d-flex justify-content-between">
                <h6 className="">{item?.trx_order?.order_number}</h6>
                <h6 className="fw-bold">{formatRupiah(item?.trx_order.total_customer_paid)}</h6>
              </div>
            )}
            {/* <hr /> */}
            {/* <h6 className="fw-bold mb-2">Biaya Lainnya</h6>
            <div className="d-flex justify-content-between">
              <h6 className="">PPN</h6>
              <h6 className="fw-bold" style={{color:"#0FAA4B"}}>-</h6>
            </div>
            <div className="d-flex justify-content-between">
              <h6 className="">Platform Fee</h6>
              <h  6 className="fw-bold" style={{color:"#0FAA4B"}}>-</h6>
            </div> */}
            {data?.trx_billing_discount?.length ?
              <>
                <hr />
                <div className="d-flex justify-content-between align-items-center">
                  <h4 className="fw-bold m-0">SUBTOTAL</h4>
                  <h4 className="fw-bold m-0">{data?.payment_amount ? formatRupiah(data?.payment_amount) : "-"}</h4>
                </div>

                <hr />
                <h6 className="fw-bold mb-2">Potongan Khusus</h6>
              </> : null
            }
            {data?.trx_billing_discount?.map((item, i)=>
            <div key={i} className="d-flex justify-content-between">
              <h6 className="">{item?.name}</h6>
              {item?.discount_amount_from_percentage && 
                <h6 className="fw-bold text-right" style={{color:"#0FAA4B"}}>
                  - {formatRupiah(item?.discount_amount_from_percentage)}
                </h6>
              }
              {item?.discount_fix && 
                <h6 className="fw-bold text-right" style={{color:"#0FAA4B"}}>
                  - {formatRupiah(item?.discount_fix)}
                </h6>
              }
              {!item?.discount_amount_from_percentage && !item?.discount_fix &&
                <h6 className="fw-bold" style={{color:"#0FAA4B"}}>-</h6>
              }
            </div>
            )}
          </div>
          <Modal.Footer>
            <div className="w-100 d-flex justify-content-between">
              <h3 className="fw-bold" style={{color:"#209AB7"}}>TOTAL TAGIHAN</h3>
              <h3 className="fw-bold" style={{color:"#209AB7"}}>{data?.payment_amount_after_discount ? formatRupiah(data?.payment_amount_after_discount) : "-"}</h3>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    )
  }

export default DetailBillingPayment;
