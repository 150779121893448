import React, { useState, useRef } from "react";
import {
  Modal,
  Button
} from "react-bootstrap";
import { BsFillTrashFill, BsPlusLg } from "react-icons/bs";
import { message } from "antd";
import { addBulkDocumentAndData } from "../../../../../redux/action/Finance/document_payment_action";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Info from "./../../../../../components/info"


const RequestDocumentData = ({data, index}) => {

  const params = useParams()
  const { id } = params

  const auth = useSelector((st) => st.authReducer);
  const dispatch = useDispatch()
  const inputDocRef = useRef()
  const inputAdditionalDataRef = useRef()

  const [show, setShow] = useState(false)

  const [document, setDocument] = useState([''])
  const [additionalData, setAdditionalData] = useState([''])
  const [errorInput, setErrorInput] = useState([])

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const addDocument = () => {
    if(document.length >= 20 ){
      message.error('maximal 20 yah')
    }else if(!document[document.length-1]){
      message.error('Input data Terlebih dahulu')
    }else{
      setDocument([...document, ''])
    }
  }

  const setValueDocument = async (e, index) => {
    var newDocument = document
    newDocument[index] = e.target.value
    setDocument(newDocument)
    var findDuplicatArr = document => document.filter((item, index) =>
    document.indexOf(item) !== index)
    var duplicateEl = await findDuplicatArr(document)
    let errArr = []
    let trigger = false
    for (let i = 0; i < document.length; i++) {
      for (let j = 0; j < duplicateEl.length; j++) {
        if (document[i] === duplicateEl[j]) {
          errArr.push(true)
          trigger = true
          break
        } else {
          trigger = false
        }
      }
      if (trigger === false) {
        errArr.push(false)
      }
    }
    setErrorInput(errArr)
  }

  const deleteDocument = async (value, index) => {
    // get data duplikat by index delete
    let indexDuplikat = []
    let changeIndexVal = []
    for(let i = 0; i< document.length; i++){
      if(document[i] === value){
        indexDuplikat.push(i)
      }
    }
    // get data kembarannya (bukan yang dipilih && jika data duplikat ada 2)
    if(indexDuplikat.length === 2){
      changeIndexVal = indexDuplikat.filter(i => i !== index )
    }
    // End get data duplikat by index delete
    
    // delete input resi by index delete
    let resi = document.filter((data,i) => i !== index)
    setDocument(resi)
    // End delete input resi by index delete
    
    // proses filter array error input
    let duplikat = errorInput.filter((data,i)=> i !== index)
    // jika ada data duplikat dan jumlahnya 2
    if(indexDuplikat.length === 2){
      changeIndexVal = changeIndexVal[0]
      // jika index yang mau di apus urutannya lebih besar dari duplikatnnya
      if(index > changeIndexVal){
        // hpaus langsung indexnya
        duplikat[changeIndexVal] = false
      }else{
        // index nya kurang 1 dlu baru di hapus
        duplikat[changeIndexVal - 1] = false
      }
    }
    setErrorInput(duplikat)
  }


  const addAdditionalData = () => {
    if(additionalData.length >= 20 ){
      message.error('maximal 20 yah')
    }else if(!additionalData[additionalData.length-1]){
      message.error('Input data Terlebih dahulu')
    }else{
      setAdditionalData([...additionalData, ''])
    }
  }

  const setValueAdditionalData = async (e, index) => {
    var newAdditionalData = additionalData
    newAdditionalData[index] = e.target.value
    setAdditionalData(newAdditionalData)
    var findDuplicatArr = additionalData => additionalData.filter((item, index) =>
    additionalData.indexOf(item) !== index)
    var duplicateEl = await findDuplicatArr(additionalData)
    let errArr = []
    let trigger = false
    for (let i = 0; i < additionalData.length; i++) {
      for (let j = 0; j < duplicateEl.length; j++) {
        if (additionalData[i] === duplicateEl[j]) {
          errArr.push(true)
          trigger = true
          break
        } else {
          trigger = false
        }
      }
      if (trigger === false) {
        errArr.push(false)
      }
    }
    setErrorInput(errArr)
  }

  const deleteAdditionalData = async (value, index) => {
    // get data duplikat by index delete
    let indexDuplikat = []
    let changeIndexVal = []
    for(let i = 0; i< additionalData.length; i++){
      if(additionalData[i] === value){
        indexDuplikat.push(i)
      }
    }
    // get data kembarannya (bukan yang dipilih && jika data duplikat ada 2)
    if(indexDuplikat.length === 2){
      changeIndexVal = indexDuplikat.filter(i => i !== index )
    }
    // End get data duplikat by index delete
    
    // delete input resi by index delete
    let resi = additionalData.filter((data,i) => i !== index)
    setAdditionalData(resi)
    // End delete input resi by index delete
    
    // proses filter array error input
    let duplikat = errorInput.filter((data,i)=> i !== index)
    // jika ada data duplikat dan jumlahnya 2
    if(indexDuplikat.length === 2){
      changeIndexVal = changeIndexVal[0]
      // jika index yang mau di apus urutannya lebih besar dari duplikatnnya
      if(index > changeIndexVal){
        // hpaus langsung indexnya
        duplikat[changeIndexVal] = false
      }else{
        // index nya kurang 1 dlu baru di hapus
        duplikat[changeIndexVal - 1] = false
      }
    }
    setErrorInput(duplikat)
  }

  const handleSubmit = async() => {
    if(document.length > 1 && document[document.length - 1] === ""){
      document.pop()
    }
    if(additionalData.length > 1 && additionalData[additionalData.length - 1] === ""){
      additionalData.pop()
    }
    const payload = {
      document_names: document,
      additional_data_names: additionalData
    }
    dispatch(addBulkDocumentAndData(auth.access_token, data?.id, payload, id, index))
  }


  return(
    <>
      <Button
        size="sm"
        className="fw-bold py-1 h-25 px-4 rounded-pill text-capitalize" variant="outline-primary"
        onClick={handleShow}
      >
        Request
      </Button>
      
      <Modal
        contentClassName="rounded-card p-2"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton className="bg-white">
          <strong>Request Dokumen & Data</strong>
        </Modal.Header>
        <div className="py-4">
          <div className="px-4">
            <Info info="Dokumen dan Data Tambahan yang anda request akan diunggah atau diisi oleh partner."/>
          </div>

          <div className="px-4">
          <h5 className="fw-bold">Nama Dokumen</h5>
            {document.map((item, i)=>
              <div  key={i} className="border mb-2 rounded d-flex justify-content-between overflow-hidden">
                <input 
                  type="text" 
                  value={item} 
                  disabled={document.length - 1 !== i ? true : false } 
                  placeholder="Tulis Nama Dokumen" 
                  className="border-0 py-1 px-3 w-100 focus-ring-0" 
                  onChange={(e)=> setValueDocument(e, i)} 
                />
                <div className={`px-2 overflow-hidden ${document.length - 1 === i && "bg-primary" }`} style={{borderLeft:"1px solid #DEE2E6"}}>
                  {document.length - 1 === i ?
                  <BsPlusLg className="pointer mt-1" style={{color:"#E2E5E8"}} onClick={addDocument}/>
                  :
                  <BsFillTrashFill className="pointer mt-1" style={{color:"#E2E5E8"}} onClick={()=>deleteDocument(item, i)}/>
                  }
                </div>
              </div>
            )}
          <hr />

          <h5 className="fw-bold">Nama Data</h5>
            {additionalData.map((item, i)=>
              <div  key={i} className="border mb-2 rounded d-flex justify-content-between overflow-hidden">
                <input 
                  type="text" 
                  value={item} 
                  disabled={additionalData.length - 1 !== i ? true : false } 
                  placeholder="Tulis Nama Data" 
                  className="border-0 py-1 px-3 w-100 focus-ring-0" 
                  onChange={(e)=> setValueAdditionalData(e, i)} 
                />
                <div className={`px-2 overflow-hidden ${additionalData.length - 1 === i && "bg-primary" }`} style={{borderLeft:"1px solid #DEE2E6"}}>
                  {additionalData.length - 1 === i ?
                  <BsPlusLg className="pointer mt-1" style={{color:"#E2E5E8"}} onClick={addAdditionalData}/>
                  :
                  <BsFillTrashFill className="pointer mt-1" style={{color:"#E2E5E8"}} onClick={()=>deleteAdditionalData(item, i)}/>
                  }
                </div>
              </div>
            )}
          </div>
        </div>
        <Modal.Footer>
          <Button
            disabled={document[0] || additionalData[0] ? false : true}
            size="sm"
            className="fw-bold py-1 h-25 px-4 rounded-pill text-capitalize"
            style={{backgroundColor:`${document[0] || additionalData[0] ? "#CE1671" : "#BBBBBB"}`, border:"none"}}
            onClick={handleSubmit}
          >
            Submit Request
          </Button>
        </Modal.Footer>
      </Modal>
    </>
    )
  }

export default RequestDocumentData;
