import React, { useState, useRef } from "react";
import {
  Modal,
  Card,
  Button,
} from "react-bootstrap";
import {
  getAccountList,
} from "../../../redux/action/usermanagement_action";
import { useDispatch, useSelector } from "react-redux";
import UserAccountForm from "./UserAccountForm";
import { resetAuth } from "../../../redux/action/auth_action";
import UserMgtApi from "../../../api/user-management.api";
import { message } from "antd";


const UserAccountEdit = ({ detail }) => {
  const dispatch = useDispatch();
  const childRef = useRef(null);
  const auth = useSelector((st) => st.authReducer);

  const [show, setShow] = useState(false);
  const [dataForm, setDataForm] = useState({
    id: '',
    role_id: '',
    name: '',
    email: '',
    phone: '',
    password: '',
    photo: ''
  });

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setDataForm({
      id: detail.id,
      role_id: detail.role_id ? detail.role_id : '',
      name: detail.name,
      email: detail.email,
      phone: detail.phone ? initPhoneNumber(detail.phone) : '',
      password: '',
      photo: detail.profile_picture,
      isEdit: true
    });
    setShow(true);
  }

  const initPhoneNumber =  (item) => {
    if (item.charAt(0) === '0') {
      return item.substring(1);
    } else if (item.charAt(0) === '6') {
      return item.substring(2);
    } else {
      return item;
    }
  };

  const handleInput = async () => {
    childRef.current.handleInput();
  };

  const submit = (data) => {
    updateAccount(data)
  };

  const updateAccount = async (data) => {
    try {
      const api = new UserMgtApi();
      let fd = new FormData();

      for (const key in data) {
        fd.append(key, data[key]);
      }
      const response = await api.updateAccount(auth.access_token, data.id, fd);

      if (response.status === 401) {
        dispatch(resetAuth());
      } else if (response.status === "success") {
        message.success("Akun Berhasil Diupdate");
        handleClose();
        dispatch(getAccountList(auth.access_token));
      }

    } catch (error) {
      console.log(error?.response);
    }
  };

  return (
    <>
      <h6 className="text-primary underline mb-2 text-end" onClick={handleShow} style={{ cursor: "pointer" }}>Edit Akun</h6>

      <Modal
        contentClassName=""
        show={show}
        onHide={handleClose}
        className=""
        centered
      >
        <Modal.Header closeButton className="bg-white">
          <div>
            <h4 className="fw-bold">Edit Akun</h4>
            <h6>Ubah detail akun yang sudah anda buat.</h6>
          </div>
        </Modal.Header>

        <Card className="rounded-card bg-white p-4 mb-2 border-0">
          <UserAccountForm ref={childRef} submit={submit} dataForm={dataForm} />
        </Card>

        <Modal.Footer className="border-top-0 mx-1 bg-ice">
          <Button
            size="sm"
            variant="primary"
            className="float-end rounded-pill px-3"
            onClick={() => {
              handleInput();
            }}
          >
            Simpan
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default UserAccountEdit;
