import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  Form,
  InputGroup,
  FloatingLabel,
  Button,
} from "react-bootstrap";
import InfoBar from "../infoBar/InfoBar";
import { resetAuth } from "../../redux/action/auth_action";
import BalanceApi from "../../api/balance-api";
import useBreakpoint from "../responsiveUtils/useBreakpoint";

// icons
import account_circle_blue from "../../assets/images/account_circle_blue.svg";
import account_colored from "../../assets/images/account_colored.svg";
import iconUser from "../../assets/images/icon-user.svg";
import info_add_rekening_mobile from "../../assets/images/info_add_rekening_mobile.png";

import { MdClose, MdSearch } from "react-icons/md";
import { HiChevronDown } from "react-icons/hi";
import { BsCheckCircleFill } from "react-icons/bs";
import OtpEngine from "../../api/otp-engine";
import { getRekeningList } from "../../redux/action/balance_action";
import _ from "lodash";

const AddBankAccount = ({ show, closeModal, setShowModal }) => {
  const { isMobile } = useBreakpoint();

  const [id, setId] = useState(null)
  const [otpProcess, setOtpProcess] = useState(false)
  const [otpSuccess, setOtpSuccess] = useState(false)
  const [counter, setCounter] = useState(0);

  const [showSearchBank, setShowSearchBank] = useState(false);
  const [selectedDisbursementMethod, setSelectedDisbursementMethod] = useState({
    name: null,
    id: null,
  });
  const [accountNumber, setAccountNumber] = useState(null);
  const [accountName, setAccountName] = useState(null);
  const dispatch = useDispatch();
  const auth = useSelector((st) => st.authReducer);
  const disbursementMethods = useSelector(
    (st) => st.balanceReducer.disbursementMethods
  );

  const [disbursementMethodsRender, setDisbursementMethodsRender] = useState(
    []
  );

  const [searchKeyword, setSearchKeyword] = useState("");

  const validateAccount = async () => {
    try {
      const api = new BalanceApi();

      let body = {};
      body["disbursement_method_id"] = selectedDisbursementMethod.id;
      body["account_number"] = +accountNumber;

      const response = await api.validateBankAccount(auth.access_token, body);

      if (response.status === 401) {
        dispatch(resetAuth());
      }

      setAccountName(response?.data?.account_name);
    } catch (error) {
      console.log(error?.response);
    }
  };

  const createBankAccount = async () => {
    try {
      const api = new BalanceApi();

      let body = {};
      body["disbursement_method_id"] = selectedDisbursementMethod.id;
      body["account_number"] = +accountNumber;
      body["is_main"] = 1;
      body["otp"] = "wa";
      body["otp_id"] = id

      const response = await api.createBankAccount(auth.access_token, body);

      if (response.status === 401) {
        dispatch(resetAuth());
      }

      if (response.status === "success") {
        dispatch(getRekeningList(auth.access_token))
        setShowModal({ success: true });
        setAccountName(null)
        setOtpSuccess(false)
        setSelectedDisbursementMethod({
          name: null,
          id: null,
        })
      }
    } catch (error) {
      console.log(error?.response);
    }
  };

  const handleOtpEngine = async() => {
    setCounter(15)
    setOtpProcess(true)
    // // hit endpoint get id, then set id to form state and redirect to whatsapp
    const body = {
      data_user_id: -1,
      // data_no_hp: state.user,
      data_no_hp: auth?.wa_number,
      data_destination: 1,
      data_type: 4
    }

    const otpEngine = new OtpEngine();
    const response = await otpEngine.otpRequest(body)

    if (response?.status === "success"){
      setId(response?.data?.id)
      window.open(response?.data?.wa_me,'_blank');
    }
  }

  const cekOtpEngine = async() => {
    const otpEngine = new OtpEngine();
    const response = await otpEngine.otpCheck(id)
    if (response?.status === "success"){
      setOtpSuccess(true)
    }
  }

  useEffect(() => {
    if (searchKeyword) {
      let filtered = [];
      // search
      filtered = _.filter(disbursementMethods?.data, (item) => {
        return _.includes(
          item?.name?.toLowerCase(),
          searchKeyword?.toLowerCase()
        );
      });
      setDisbursementMethodsRender([...filtered]);
    }
  }, [searchKeyword]);

  

  useEffect(()=>{
    counter > 0 && setTimeout(()=> setCounter(counter-1),1000)
    if(counter === 0 && otpProcess){
      cekOtpEngine()
      setOtpProcess(false)
    } 
  },[counter])

  return (
    <Modal
      contentClassName="rounded-card p-2"
      show={show}
      onHide={closeModal}
      className="p-3"
      centered
    >
      <div className="d-flex p-2">
        <p className="fw-bold">Tambah Rekening</p>
        <MdClose className="ms-auto me-2 pointer" onClick={closeModal} />
      </div>
      <Modal.Body>
        <div className="">
          <img src={account_circle_blue} className="mb-2" alt="" />
          <h4 className="fw-bold">Data Rekening</h4>
          <h6 className="text-muted">
            Masukan Data Rekening untuk kemudahkan penarikan dana kamu.
          </h6>
          <InputGroup className="border rounded-input" style={!otpSuccess ? {backgroundColor:`#E9ECEF`}:{}}> {/* here */}
            <InputGroup.Text
              className="border-0 rounded-input"
              style={{ backgroundColor: "#f8f9fa" }}
            >
              <img src={account_colored} alt="" />
            </InputGroup.Text>
            <FloatingLabel
              className="rounded-input"
              label="Jenis Bank"
              style={{ fontSize: "12px", width: isMobile ? "65%" : "80%" }}
            >
              <Form.Control
                disabled={!otpSuccess ? true : false} // here
                type="text"
                className="border-0 rounded-input pointer"
                placeholder="m"
                onFocus={(ev) => ev.currentTarget.blur()}
                onClick={() => setShowSearchBank((prev) => !prev)}
                value={selectedDisbursementMethod.name}
                // onChange={(ev) => {
                //   setBody({ ...body, email: ev.target.value });
                // }}
                style={{ height: "50px" }}
              ></Form.Control>
            </FloatingLabel>
            <InputGroup.Text
              onClick={() => {
                if(otpSuccess && id){
                    setShowSearchBank((prev) => !prev)
                }
              }} //here
              className="border-0 rounded-input pointer"
              style={!otpSuccess ? {backgroundColor:`#E9ECEF`}:{}} //here
            >
              <HiChevronDown />
            </InputGroup.Text>
          </InputGroup>

          <div className="w-100 position-relative">
            <div
              hidden={!showSearchBank}
              className="bg-white w-100 rounded-card shadow-md p-2 position-absolute"
              style={{ zIndex: "100" }}
            >
              <InputGroup className="border rounded-input">
                <InputGroup.Text className="border-0 rounded-input">
                  <MdSearch />
                </InputGroup.Text>
                <FloatingLabel
                  className="rounded-input"
                  label="Cari Bank"
                  style={{ fontSize: "12px", width: isMobile ? "80%" : "88%" }}
                >
                  <Form.Control
                    type="text"
                    className="border-0 rounded-input pointer"
                    placeholder="m"
                    onChange={(ev) => {
                      setSearchKeyword(ev.target.value);
                    }}
                    style={{ height: "50px" }}
                  ></Form.Control>
                </FloatingLabel>
              </InputGroup>
              <div className="" style={{ height: "250px", overflowY: "auto" }}>
                {searchKeyword
                  ? disbursementMethodsRender?.map((el) => (
                      <>
                        <div
                          className="p-2 pointer disbursement-method-element"
                          onClick={() => {
                            setSelectedDisbursementMethod({
                              name: el.name,
                              id: el.id,
                            });
                            setShowSearchBank((prev) => !prev);
                          }}
                        >
                          {el.name}
                        </div>
                      </>
                    ))
                  : disbursementMethods?.data?.map((el) => (
                      <>
                        <div
                          className="p-2 pointer disbursement-method-element"
                          onClick={() => {
                            setSelectedDisbursementMethod({
                              name: el.name,
                              id: el.id,
                            });
                            setShowSearchBank((prev) => !prev);
                          }}
                        >
                          {el.name}
                        </div>
                      </>
                    ))}
              </div>
            </div>
          </div>
          <InputGroup className="border rounded-input mt-2" style={!otpSuccess ? {backgroundColor:`#E9ECEF`}:{}}> {/* here */}
            <InputGroup.Text
              className="border-0 rounded-input"
              style={{ backgroundColor: "#f8f9fa" }}
            >
              <img src={account_colored} alt="" />
            </InputGroup.Text>
            <FloatingLabel
              className="rounded-input"
              label="Nomor Rekening"
              style={{ 
                fontSize: "12px", 
                width: isMobile ? "50%" : "67.2%", 
                backgroundColor:`${!otpSuccess ? "#E9ECEF" : "#FFFFFF"}` //here
            }}
            >
              <Form.Control
                disabled={!otpSuccess ? true : false} // here
                type="number"
                className="border-0 rounded-input"
                placeholder="m"
                onChange={(ev) => {
                  setAccountNumber(ev.target.value);
                }}
                style={{ height: "50px" }}
              ></Form.Control>
            </FloatingLabel>
            <InputGroup.Text className="border-0 rounded-input pointer" style={!otpSuccess ? {backgroundColor:`#E9ECEF`}:{}}> {/* here */}
              <Button
                disabled={!otpSuccess ? true : false} // here
                hidden={!otpSuccess ? true : false}
                variant="outline-primary rounded-pill"
                onClick={validateAccount}
                size="sm"
              >
                Periksa
              </Button>
            </InputGroup.Text>
          </InputGroup>
          {accountName && (
            <InputGroup className="bg-ice my-1 d-flex border  rounded-input">
              <img src={iconUser} alt="" className="icon-form-o" />
              <FloatingLabel
                label="Nama Pemilik Rekening"
                style={{ width: isMobile ? "70%" : "82%", fontSize: "12px" }}
                className="form-disabled"
              >
                <Form.Control
                  type="text"
                  placeholder="Nama Pemilik Rekening"
                  className="form-disabled border-0 py-3 bg-ice"
                  style={{ height: "55px", fontSize: "11px" }}
                  value={accountName}
                />
              </FloatingLabel>
              <InputGroup.Text className="border-0 form-disabled rounded-input">
                <BsCheckCircleFill className="text-success" />
              </InputGroup.Text>
            </InputGroup>
          )}
          {isMobile ? (
            <img src={info_add_rekening_mobile} alt="" className="w-100 mt-2" />
          ) : (
            <InfoBar
              infoBarClassName={"mt-2"}
              text={
                <>
                  <h6 className="mb-2" style={{ fontSize: "10px" }}>
                    Pastikan data rekening sudah benar karena data digunakan
                    untuk mentransfer hasil Pendapatan kamu.
                  </h6>
                </>
              }
            />
          )}
        </div>
      </Modal.Body>

      <Modal.Footer>
        <p className="text-primary pointer me-4" onClick={closeModal}>
          Batalkan
        </p>
        
        {otpSuccess ?
            <Button
                size="sm"
                variant="primary"
                className="float-end rounded-pill px-4 py-2"
                onClick={() => {
                    createBankAccount();
                }}
            >
                Simpan
            </Button> :
            !otpProcess && 
            <Button
                size="sm"
                variant="primary"
                className="float-end rounded-pill px-4 py-2"
                onClick={() => {
                    handleOtpEngine()
                }}
            >
                Request OTP
            </Button>
        }
        {otpProcess && !otpSuccess && counter !== 0 &&
            <div
                className="d-flex justify-content-center align-item-center"
                style={{
                    backgroundColor:"#E9ECEF",
                    padding:"8px 0",
                    width:"100px",
                    borderRadius:"20px",
                    fontSize:"14px",
                    color:"#545454"
                }}
            >
                {counter}
            </div>
        }
      </Modal.Footer>
    </Modal>
  );
};

export default AddBankAccount;
