import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Card, Button } from "react-bootstrap";
import { message } from "antd";
import Gmap from "../../components/gMap";
import TabVehicleRent from "./TabVehicleRent";
import TabService from "./TabService";
import TabSummary from "./TabSummary";
import TabAuth from "../../components/tabAuth";
import TabPayment from "../../components/tabPayment";
import PaymentPending from "../../components/tabPayment/PaymentPending";
import { updatePickupDetail } from "../../redux/action/sewakendaraan_action";
import {
  setActivePaymentList,
  resetActivePaymentList,
} from "../../redux/action/active_payment_action";
import { resetAuth } from "../../redux/action/auth_action";
import OrderRentApi from "../../api/order-rent-api";
import AccountApi from "../../api/auth-api";
import { gePaymentList } from "../../redux/action/payment_list_action";
import useBreakpoint from "../../components/responsiveUtils/useBreakpoint";
import {
  hideSidenavMobile,
  openSidenavMobile,
  openMap,
  closeMap,
  showMapButtonBack,
} from "../../components/layouts/layout_helper";

// icons
import { AiOutlineLeft } from "react-icons/ai";
import { IoChevronBack, IoMenuOutline } from "react-icons/io5";

function SewaKendaraan(props) {
  const dispatch = useDispatch();
  const { isMobile } = useBreakpoint();

  const [isLogined, setIsLogined] = useState(
    props.auth.access_token === null ? false : true
  );
  const [payment, setPayment] = useState(false);

  const [isVTypeAvailable, setIsVTypeAvailable] = useState(false);

  const firstComponent = () => {
    return <TabVehicleRent />;
  };
  const secondComponent = () => {
    return <TabService />;
  };
  const thirdComponent = () => {
    return <TabSummary />;
  };
  const ForthComponentLogined = () => {
    return <TabPayment />;
  };
  const ForthComponentUnLogined = () => {
    return <TabAuth />;
  };
  const LastComponent = () => {
    return <PaymentPending />;
  };

  const [steps, setSteps] = useState([
    {
      key: "1",
      title: "Sewa Kendaraan",
      isDone: true,
      component: firstComponent,
    },
    {
      key: "2",
      title: "Pilih Layanan Anda",
      isDone: false,
      component: secondComponent,
    },
    {
      key: "3",
      title: "Rangkuman",
      isDone: false,
      component: thirdComponent,
    },
    {
      key: "4",
      title: "",
      isDone: false,
      component: ForthComponentUnLogined,
    },
    {
      key: "5",
      title: "",
      isDone: false,
      component: ForthComponentLogined,
    },
    {
      key: "6",
      title: "",
      isDone: false,
      component: LastComponent,
    },
  ]);
  const [activeStep, setActiveStep] = useState(steps[0]);

  if (isVTypeAvailable) {
    message.error("Armada kendaraan tidak tersedia untuk wilayah tersebut!");
  }

  useEffect(() => {
    if (props.auth.access_token) {
      if (!isLogined) {
        setIsLogined(true);
        setActiveStep(steps[2]);
      }
    } else {
      if (isLogined) {
        setIsLogined(false);
      }
    }
  }, [props.auth, isLogined, steps]);

  const handleFirstPage = async () => {
    if (
      props.sewaKendaraanReducer.startDate &&
      props.sewaKendaraanReducer.endDate &&
      props.sewaKendaraanReducer.lokasi &&
      props.sewaKendaraanReducer.namaPenerima &&
      props.sewaKendaraanReducer.nomorTelepon
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleSecondPage = async () => {
    if (props.selectedVType.id && props.selectedPartnerService.id) {
      return true;
    } else {
      return false;
    }
  };

  const handlePaymentPage = async () => {
    if (props.payment_method_id) {
      return true;
    } else {
      return false;
    }
  };

  const getPaymentList = async () => {
    if (props.paymentListReducer.data.length === 0) {
      dispatch(gePaymentList());
      return true;
    } else {
      return false;
    }
  };

  const getVType = async () => {
    try {
      props.dispatch(
        updatePickupDetail({ vType: { ...props.vType, isLoading: true } })
      );
      const orderRentApi = new OrderRentApi();
      let order_province = "";
      let order_regency = "";
      let order_district = "";
      let order_village = "";

      for (const iterator of props.sewaKendaraanReducer.detailMap) {
        if (iterator && iterator.types && iterator.types.length > 0) {
          switch (iterator.types[0]) {
            case "administrative_area_level_1":
              order_province = iterator.long_name;
              break;
            case "administrative_area_level_2":
              order_regency = iterator.long_name;
              break;
            case "administrative_area_level_3":
              order_district = iterator.long_name;
              break;
            case "administrative_area_level_4":
              order_village = iterator.long_name;
              break;
            default:
              break;
          }
        }
      }
      let data = {
        pickup_time: props.sewaKendaraanReducer.startDate.toISOString(),
        return_time: props.sewaKendaraanReducer.endDate.toISOString(),
        order_category: props.sewaKendaraanReducer.tipe,
        order_province,
        order_regency,
        order_district,
        order_village,
        order_detail_address: props.sewaKendaraanReducer.lokasi,
        receiver_name: props.sewaKendaraanReducer.namaPenerima,
        receiver_phone_no: `0${props.sewaKendaraanReducer.nomorTelepon}`,
        order_address_note: props.sewaKendaraanReducer.catatan || "-",
        is_address_saved: "-",
        order_location_longitude: props.sewaKendaraanReducer.lng,
        order_location_latitude: props.sewaKendaraanReducer.lat,
      };

      if (props.auth.access_token && props.auth.is_special_customer > 0) {
        data["customer_id"] = props.auth.id;
      }

      const result = await orderRentApi.getVType(data);
      if (result.status === "success" && result.data) {
        props.dispatch(
          updatePickupDetail({
            vType: { data: result.data, isLoading: false },
            partnerService: { data: [], isLoading: false },
            selectedVType: {},
            selectedPartnerService: {},
            totalPrice: {
              rent_day: 0,
              vehicle_price: 0,
              driver_price: 0,
              helper_price: 0,
              total_price: 0,
              final_price: 0,
            },
            detailOrder: {
              driver_availability_rent: 0,
              helper_availability_send: 0,
              helper_availability_rent: 0,
              driver_price_rent: 0,
              helper_price_rent_send: 0,
              helper_price_rent: 0,
              driver_availability_rent_selected: false,
              helper_availability_send_selected: false,
              helper_availability_rent_selected: false,
              total_helper_requested: 1,
              total_price: 0,
            },
          })
        );
        if (result.data.length > 0) {
          return true;
        }
        return false;
      } else {
        props.dispatch(
          updatePickupDetail({
            vType: { data: [], isLoading: false },
            partnerService: { data: [], isLoading: false },
            selectedVType: {},
            selectedPartnerService: {},
            totalPrice: {
              rent_day: 0,
              vehicle_price: 0,
              driver_price: 0,
              helper_price: 0,
              total_price: 0,
              final_price: 0,
            },
            detailOrder: {
              driver_availability_rent: 0,
              helper_availability_send: 0,
              helper_availability_rent: 0,
              driver_price_rent: 0,
              helper_price_rent_send: 0,
              helper_price_rent: 0,
              driver_availability_rent_selected: false,
              helper_availability_send_selected: false,
              helper_availability_rent_selected: false,
              total_helper_requested: 1,
              total_price: 0,
            },
          })
        );
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  const checkToken = async () => {
    try {
      const accountApi = new AccountApi();
      const result = await accountApi.checkToken(props.auth.refresh_token);
      if (result.status === "success") {
        setIsLogined(true);
        return true;
      } else {
        message.info("Silahkan Login terlebih dahulu");
        props.dispatch(resetAuth());
        return false;
      }
    } catch (error) {
      props.dispatch(resetAuth());
      return false;
    }
  };

  const createNewOrder = async () => {
    try {
      const orderRentApi = new OrderRentApi();
      let order_province = "";
      let order_regency = "";
      let order_district = "";
      let order_village = "";
      let order_postal_code = "";

      for (const iterator of props.sewaKendaraanReducer.detailMap) {
        if (iterator && iterator.types && iterator.types.length > 0) {
          switch (iterator.types[0]) {
            case "administrative_area_level_1":
              order_province = iterator.long_name;
              break;
            case "administrative_area_level_2":
              order_regency = iterator.long_name;
              break;
            case "administrative_area_level_3":
              order_district = iterator.long_name;
              break;
            case "administrative_area_level_4":
              order_village = iterator.long_name;
              break;
            case "postal_code":
              order_postal_code = iterator.long_name;
              break;
            default:
              break;
          }
        }
      }

      // TODO : catatan -> order_note, patokan -> order_address_note
      let data = {
        pickup_time: props.sewaKendaraanReducer.startDate.toISOString(),
        return_time: props.sewaKendaraanReducer.endDate.toISOString(),
        order_category: props.sewaKendaraanReducer.tipe,
        order_province,
        order_regency,
        order_district,
        order_village,
        order_postal_code,
        order_detail_address: props.sewaKendaraanReducer.lokasi,
        receiver_name: props.sewaKendaraanReducer.namaPenerima,
        receiver_phone_no: `0${props.sewaKendaraanReducer.nomorTelepon}`,
        order_note: props.sewaKendaraanReducer.catatan || "-",
        order_address_note: props.sewaKendaraanReducer.patokan || "-",
        is_address_saved: "0",
        order_location_longitude: props.sewaKendaraanReducer.lng,
        order_location_latitude: props.sewaKendaraanReducer.lat,
        vehicle_subtype_id: props.selectedVType.id,
        partner_service_id: props.selectedPartnerService.id,
        driver: props.detailOrder.driver_availability_rent_selected ? 1 : 0,
        helper: props.detailOrder.helper_availability_rent_selected
          ? props.detailOrder.total_helper_requested
          : 0,
        payment_method_id: `${props.payment_method_id}`,
      };

      data["customer_id"] = props.auth.id;
      data["is_special"] = props.sewaKendaraanReducer.isSpecialPartnerSelected
        ? 1
        : 0;

      if (props.custom_phone_number) {
        data["custom_phone_number"] = props.custom_phone_number;
      }

      const result = await orderRentApi.createOrder(
        data,
        props.auth.access_token
      );
      if (result.status === "success") {
        props.dispatch(setActivePaymentList({ data: { ...result.data } }));
        message.success("Order berhasil dibuat.");
        return true;
      } else {
        message.error(result.message);
        return false;
      }
    } catch (error) {
      message.error("Gagal membuat order.");
      return false;
    }
  };

  const handleNext = async () => {
    let isTokenValid = false;
    if (steps[steps.length - 1].key === activeStep.key) {
      alert("You have completed all steps.");
      return;
    }

    let allowNextStep = false;
    switch (activeStep.key) {
      case "1":
        allowNextStep = await handleFirstPage();
        break;
      case "2":
        allowNextStep = await handleSecondPage();
        break;
      case "3":
        allowNextStep = true;
        break;
      case "4":
        allowNextStep = true;
        break;
      case "5":
        allowNextStep = await handlePaymentPage();
        break;
      default:
        allowNextStep = true;
        break;
    }

    if (allowNextStep) {
      let allowToPassed = false;
      switch (activeStep.key) {
        case "1":
          allowToPassed = await getVType();
          if (!allowToPassed) {
            setIsVTypeAvailable(true);
          }
          break;
        case "2":
          if (props.selectedVType.id && props.selectedPartnerService.id) {
            allowToPassed = true;
          } else {
            message.error(
              "Silahkan pilih tipe kendaraan dan partner penyedia kendaraan"
            );
          }
          break;
        case "3":
          await getPaymentList();
          isTokenValid = await checkToken();
          allowToPassed = true;
          break;
        case "5":
          if (props.payment_method_id) {
            allowToPassed = true;
          } else {
            message.error("Silahkan pilih metode pembayaran");
          }
          break;
        default:
          allowToPassed = true;
          break;
      }
      if (allowToPassed) {
        const index = steps.findIndex((x) => x.key === activeStep.key);
        setSteps((prevStep) =>
          prevStep.map((x) => {
            if (x.key === activeStep.key) x.isDone = true;
            return x;
          })
        );
        let targetIndex = index + 1;
        if (isLogined) {
          targetIndex =
            activeStep.key === "3" && isTokenValid ? 4 : targetIndex;
        }
        setActiveStep(steps[targetIndex]);
      }
      setIsVTypeAvailable(false);
    } else {
      switch (activeStep.key) {
        case "1":
          message.error("Mohon lengkapi data!");
          break;
        case "2":
          message.error(
            "Silahkan pilih tipe kendaraan dan partner penyedia kendaraan"
          );
          break;
        case "5":
          message.error("Silahkan pilih metode pembayaran");
          break;
        default:
          break;
      }
    }
  };

  const handleBack = () => {
    const index = steps.findIndex((x) => x.key === activeStep.key);
    if (index === 0) return;

    setSteps((prevStep) =>
      prevStep.map((x) => {
        if (x.key === activeStep.key) x.isDone = false;
        return x;
      })
    );
    let targetIndex = index - 1;
    if (isLogined) {
      targetIndex = activeStep.key === "5" ? 2 : targetIndex;
    }
    setActiveStep(steps[targetIndex]);
  };

  const footerButton = () => {
    let footer = [];
    if (steps[steps.length - 1].key !== activeStep.key) {
      if (activeStep.key === "5" && isLogined) {
        footer.push(
          <Card.Footer className="bg-0 shadow-top border-top-0">
            <div className="d-grid">
              <Button
                size="sm"
                className="py-2 fw-bold rounded-pill"
                onClick={async () => {
                  if (props.payment_method_id) {
                    const result = await createNewOrder();
                    if (result) {
                      setPayment(true);
                    }
                  } else {
                    message.error("Silahkan pilih metode pembayaran");
                  }
                }}
              >
                Sewa Kendaraan
              </Button>
            </div>
            <h6 className="text-center mt-3 mx-2 text-muted">
              dengan memesan Pengiriman ini, saya menyetujui{" "}
              <a href="/#">syarat &amp; ketentuan elog</a>
            </h6>
          </Card.Footer>
        );
      } else {
        footer.push(
          <Card.Footer
            className={
              activeStep.key === "4" && !isLogined
                ? "d-none"
                : "d-grid border-0 bg-0 mb-2"
            }
          >
            <Button
              size="sm"
              className="fw-bold py-2 rounded-pill"
              onClick={handleNext}
            >
              Selanjutnya
            </Button>
          </Card.Footer>
        );
      }
    }
    return footer;
  };

  useEffect(() => {
    if (isMobile) {
      hideSidenavMobile();
    }
  }, [isMobile]);

  return (
    <>
      <div style={{ height: "100vh", width: "100%" }} hidden={isMobile}>
        {payment ? (
          <PaymentPending />
        ) : (
          <Card
            className={
              activeStep.key === "4" && !isLogined
                ? "card-absolute height-auto"
                : "card-absolute"
            }
          >
            <Card.Body className="pb-0 flex-i">
              <h3 className="fw-bold">
                <IoChevronBack
                  onClick={handleBack}
                  className={
                    steps[0].key === activeStep.key ? "d-none" : "icon-back"
                  }
                />
                {activeStep.title}
              </h3>
            </Card.Body>
            {activeStep.component()}
            {footerButton()}
          </Card>
        )}
        <Gmap hideRoute />
      </div>
      {isMobile && (
        <div style={{ height: "100vh", width: "100%" }}>
          <div className="w-100 p-2 head-menu shadow-md">
            <div className="d-flex mt-auto mb-2 align-items-center w-100 px-2">
              <div className="d-flex">
                {activeStep.key === "1" ? (
                  <IoMenuOutline
                    onClick={openSidenavMobile}
                    className="text-secondary pointer me-2"
                    style={{ fontSize: 25 }}
                  />
                ) : (
                  <IoChevronBack
                    onClick={handleBack}
                    style={{ fontSize: 25 }}
                    className="pointer me-2"
                  />
                )}
                <h4 className="mt-1">
                  {activeStep.key === "4"
                    ? "Login"
                    : activeStep.key === "5"
                    ? "Metode Pembayaran"
                    : activeStep.title}
                </h4>
              </div>
              {activeStep.key < 4 && (
                <h4
                  className="ms-auto mt-1 text-primary underline"
                  onClick={() => {
                    openMap();
                    showMapButtonBack();
                  }}
                >
                  Lihat Rute
                </h4>
              )}
            </div>
          </div>
          {payment ? (
            <PaymentPending />
          ) : (
            <Card
              className={
                "card-mobile"
                // activeStep.key === "4" && !isLogin
                //   ? "card-mobile height-auto"
                //   : "card-mobile"
              }
            >
              {/* <Card.Body className="pb-0 flex-i">
                  <h3 className="fw-bold">
                    <IoChevronBack
                      onClick={handleBack}
                      className={
                        steps[0].key === activeStep.key ? "d-none" : "icon-back"
                      }
                    />
                    {activeStep.title}
                  </h3>
                </Card.Body> */}
              {activeStep.component()}
              {footerButton()}
            </Card>
          )}
          <Button
            className="rounded-circle bg-white shadow-md btn-back-map"
            variant="light"
            size="sm"
            onClick={() => {
              closeMap();
            }}
          >
            <AiOutlineLeft />
          </Button>

          <Gmap />
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  map: state.map,
  sewaKendaraanReducer: state.sewaKendaraanReducer,
  vType: state.sewaKendaraanReducer.vType,
  partnerService: state.sewaKendaraanReducer.partnerService,
  auth: state.authReducer,
  selectedVType: state.sewaKendaraanReducer.selectedVType,
  selectedPartnerService: state.sewaKendaraanReducer.selectedPartnerService,
  paymentListReducer: state.paymentListReducer,
  detailOrder: state.sewaKendaraanReducer.detailOrder,
  payment_method_id: state.sewaKendaraanReducer.payment_method_id,
  custom_phone_number: state.paymentListReducer.custom_phone_number,
});
export default connect(mapStateToProps)(SewaKendaraan);
