import React, { useState, useRef } from "react";
import {
  Modal,
  Card,
  Button,
} from "react-bootstrap";
import {
  getAccountList,
} from "../../../redux/action/usermanagement_action";
import { useDispatch, useSelector } from "react-redux"; //erick (tambah use Selector)
import UserAccountForm from "./UserAccountForm";
import { resetAuth } from "../../../redux/action/auth_action";
import UserMgtApi from "../../../api/user-management.api";
import { message } from "antd";

const UserAccountInput = () => {
  const dispatch = useDispatch();
  const childRef = useRef(null);
  const auth = useSelector((st) => st.authReducer);

  const [show, setShow] = useState(false);
  const [dataForm, setDataForm] = useState({
    id: '',
    role_id: '',
    name: '',
    email: '',
    phone: '',
    password: '',
    photo: '',
    isEdit: false
  });

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setDataForm({
      id: '',
      role_id: '',
      name: '',
      email: '',
      phone: '',
      password: '',
      photo: '',
      isEdit: false
    });
    setShow(true);
  }

  const handleInput = async () => {
    childRef.current.handleInput();
  };

  const submit = (data) => {
    delete data['id'];
    delete data['isEdit'];
    
    createAccount(data);
  };

  const createAccount = async (data) => {
    try {
      const api = new UserMgtApi();
      let fd = new FormData();

      for (const key in data) {
        fd.append(key, data[key]);
      }
      const response = await api.createAccount(auth.access_token, fd);

      if (response.status === 401) {
        dispatch(resetAuth());
      } else if (response.status === "success") {
        message.success("Akun Berhasil Dibuat");
        handleClose();
        dispatch(getAccountList(auth.access_token));
      }

    } catch (error) {
      console.log(error?.response);
    }
  };

  return (
    <>
      <Button
        size="sm"
        className="float-end rounded-pill px-3"
        onClick={handleShow}
      >
        Tambah Akun
      </Button>

      <Modal
        contentClassName=""
        show={show}
        onHide={handleClose}
        className=""
        centered
      >
        <Modal.Header closeButton className="bg-white">
          <div>
            <h4 className="fw-bold">Tambah Akun</h4>
            <h6>Silahkan tambah akun untuk mengatur kebutuhan anda.</h6>
          </div>
        </Modal.Header>

        <Card className="rounded-card bg-white p-4 mb-3 border-0">
          <UserAccountForm ref={childRef} submit={submit} dataForm={dataForm} />
        </Card>

        <Modal.Footer className="border-top-0 mx-1 bg-ice">
          <Button
            size="sm"
            variant="primary"
            className="float-end rounded-pill px-3"
            onClick={() => {
              handleInput();
            }}
          >
            Tambah Akun
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default UserAccountInput;
