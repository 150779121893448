import React, { useState } from "react";
import {
  Modal,
  Button
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { BsArrowLeftRight } from "react-icons/bs";
import { message } from "antd";
import Info from "../../../../../components/info";

const ChangeReceiptPayment = ({show, setShow, onSubmit, file, setFile}) => {
  const detail = useSelector((st) => st.documentPaymentReducer.detail);

  const handleClose = () => setShow(false)

  const handleSubmit = async() => {
    onSubmit(file)
    setFile(null)
    handleClose()
  }

  const onSelectFile = (e) => {
    // console.log(e.target.files)
    if (!e.target.files || e.target.files.length === 0) {
      setFile(null)
      return;
    }
    if (e.target.files[0].size > 5000000) {
      message.error("Ukuran Maksimal foto adalah 5Mb");
    } else {
      setFile(e.target.files[0])
    }
  };

  console.log(file)


  return(
  <>    
    <Modal
      contentClassName="rounded-card p-2"
      show={show}
      onHide={handleClose}
      centered
    >
        <Modal.Header closeButton className="bg-white">
            <strong>{file && "Konfirmasi"} Ubah Bukti</strong>
        </Modal.Header>
        <div className="p-4">
          {file ? 
          <>
            <h6 className="mb-2">Apakah anda yakin akan merubah bukti pembayaran yang sebelumnya?</h6>
            <div className="my-4">
              <div className="row">
                <div className="col-4">
                  <h6 className="m-0">Bukti Sebelumnya</h6>
                </div>
                <div className="col">
                  <h6 className={`m-0 ${!detail?.customer_payment_confirmation_receipt ? "text-muted" : "fw-bold"}`}>
                    {detail?.customer_payment_confirmation_receipt?.length > 32 ? `${detail?.customer_payment_confirmation_receipt.substring(0,32)}...` : detail?.customer_payment_confirmation_receipt}
                    {!detail?.customer_payment_confirmation_receipt && "Bukti belum belum tersedia"}
                  </h6>
                </div>
              </div>

              <BsArrowLeftRight className="my-3 fw-bold" />

              <div className="row">
                <div className="col-4">
                  <h6 className="m-0">Bukti Baru</h6>
                </div>
                <div className="col d-flex justify-content-between align-items-center">
                  <h6 className="m-0 fw-bold">
                    {file?.name.length > 32 ? file?.name.substring(0,32) : file?.name}
                  </h6>
                  <h6 className="m-0 text-primary underline pointer" onClick={()=> setFile(null)}>
                    Ganti Bukti
                  </h6>
                </div>
              </div>
            </div>
          </>
          :
          <label className="w-100" >
            <div className="rounded d-flex justify-content-center align-items-center pointer p-2" style={{border:"1px dotted #FF1D8C"}}>
              <h5 className="m-0 text-primary">Upload Bukti Pembayaran</h5>
            </div>
            <input
              type="file"
              style={{ visibility: "hidden" }}
              onChange={onSelectFile}
            />
          </label>
        }
          <Info info="Pastikan bukti pembayaran yang baru tidak memiliki kesalahan saat eLOG melakukan pengecekan pembayaran anda."/>
        </div>
        <Modal.Footer>
            <Button
              disabled={file ? false : true}
              size="sm"
              className="fw-bold py-1 h-25 px-4 rounded-pill text-capitalize"
              style={{backgroundColor:`${file ? "#CE1671" : "#BBBBBB"}`, border:"none"}}
              onClick={handleSubmit}
            >
              Konfirmasi Lanjut
            </Button>
        </Modal.Footer>
    </Modal>
  </>
  )
}

export default ChangeReceiptPayment;
