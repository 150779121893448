import { BaseApiRequest } from "../base-api";
import axios from "axios";

export default class PaymentApi extends BaseApiRequest {
  constructor() {
    super(process.env.REACT_APP_API_END_POINT);
  }

  async getPaymentTenure(token, params) {
    let result;
    try {
      const response = await axios.get(
        `${this.endpoint}/master/payment-tenure`,
        {
          params,
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      result = response.data;
    } catch (e) {
      // message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }
}
