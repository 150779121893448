const initialState = {
  in_procurement: {
    filter: {},
    data: [],
    meta: {},
  },
  in_contract: {
    data: [],
    meta: {},
  },
  filter: {
    data: [],
    meta: {},
  },
  contract_list_detail: {
    data: [],
    meta: {},
  },
  master_area: [],
  loading: false,
};

export const procurementReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "SET_PROCUREMENT":
      return { ...state, ...payload };
    case "RESET_PROCUREMENT_IN":
      return { ...state, in_procurement: { data: [], meta: {} } };
    case "RESET_PROCUREMENT_CONTRACT":
      return { ...state, in_contract: { data: [], meta: {} } };
    case "SET_MASTER_AREA":
      return { ...state, master_area: payload };
    default:
      return state;
  }
};
