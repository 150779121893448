import React, { useEffect, useState } from "react";

// Third party
import { Row, Col, Card, Tabs, Tab } from "react-bootstrap";
import { FiChevronLeft } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { FiRotateCcw} from "react-icons/fi"

// services
import { 
  getBillingTransactionById, 
  setAdditional, 
  patchConfirmBillingById,
  patchFinishCheckBillingById,
  patchFinishPaymentBillingById,
  getPaymentList,
  updateDocumentById,
  updateAdditionalDataById,
  deleteDocumentById,
  deleteAdditionalDataById,
  patchBulkAppropriateDocument,
  patchBulkRejectDocument,
  patchResetDocument,
  patchReceiptPaymentBill
} from "../../../../redux/action/Finance/document_payment_action";

// Components
// Card
import ActivityBilling from "../Components/Card/ActivityBilling"
import InfoBilling from "../Components/Card/InfoBilling";
import SummaryBilling from "../Components/Card/SummaryBilling";
import InfoPayment from "../Components/Card/InfoPayment";
// Modal
import ConfirmAcceptBilling from "../Components/Modal/ConfirmAcceptBilling";
import ConfirmFinishChecking from "../Components/Modal/ConfirmFinishChecking";
import SelectPaymentMethod from "../Components/Modal/SelectPaymentMethod";
import RequestDocumentData from "../Components/Modal/RequestDocumentData";
import ProofPayment from "../Components/Modal/ProofPayment";
import StatusPayment from "../Components/Modal/StatusPayment";
import ChangeReceiptPayment from "../Components/Modal/ChangeReceiptPayment";
import ChangeRequestDocument from "../Components/Modal/ChangeRequestDocument";
import RequestReject from "../Components/Modal/RequestReject"
import RequestAppropriate from "../Components/Modal/RequestAppropriate"
import ProcessPayment from "../Components/Modal/ProcessPayment";
import ShowDokumen from "../Components/Modal/ShowDokumen";

// helper
import { formatRupiah } from "./../../../../helper/formatCurrency"
import { message } from "antd";


const DetailDocumentPayment = () => {  
  /*======================== Hooks ======================== */

  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = params

  /*======================== Redux ======================== */
  
  const auth = useSelector((st) => st.authReducer);
  const detail = useSelector((st) => st.documentPaymentReducer.detail);
  const document = useSelector((st) => st.documentPaymentReducer.detailPartnerDocument);
  const additionalData = useSelector((st) => st.documentPaymentReducer.detailAdditionalData);
  console.log("DETAIL ===> ",detail)

  /*======================== UseState ======================== */
  
  const [payloadBayarTagihan, setPayloadBayarTagihan] = useState({
    receipt: null,
    customer_payment_method: "",
    customer_payment_logo: "",
    customer_payment_account_name: "",
    customer_payment_account_number: "",
  })

  const [detailTransaction, setDetailTransaction] = useState(null);
  const [detailTransactionIndex, setDetailTransactionIndex] = useState(0);
  const [tab, setTab] = useState("dokumen partner");
  const [receipt, setReceipt] = useState(false)
  const [selectAll, setSelectAll] = useState(false)

  const [modalPaymentMethod, setModalPaymentMethod] = useState(false)
  const [modalProcessPayment, setModalProcessPayment] = useState(false)
  const [modalStatusPayment, setModalStatusPayment] = useState(false)
  const [modalProofPayment, setModalProofPayment] = useState(false)
  const [modalChangeReceipt, setModalChangeReceipt] = useState(false)
  const [modalDetaiPaymentBilling, setModalDetailPaymentBilling] = useState(false)
  const [modalShowDokumen, setModalShowDokumen] = useState(false)
  
  const [imgDetailTransaction, setImgDetailTransaction] = useState("")
  
  const [array, setArray] = useState([])
  const [arrayName, setArrayName] = useState([])
  const [rejectionReason, setRejectionReason] = useState('')

  const [file, setFile] = useState(null)

  // console.log("id ===> " ,array)
  // console.log("name ===> ",arrayName)

  /*======================== Handler ======================== */

  const handeCeklishDocument = (id, name) => {
    const getIndex = array.findIndex(e => e === id)

    if(getIndex !== -1){
      const temp = array.filter(e => e !== id)
      setArray(temp)
      // console.log(2,array)

      const tempName = arrayName.filter(e => e !== name)
      setArrayName(tempName)

    }else{
      const temp = [...array]
      temp.push(id)
      setArray(temp)

      const tempName = [...arrayName]
      tempName.push(name)
      setArrayName(tempName)
    }
  }

  const handleSelectAll = () => {
    setSelectAll(!selectAll)
    // let newDocument
    if(!selectAll){
      setArray(document.filter(e => e.status !== 1 && e.status !== 2 ).map(e => e.id))
      setArrayName(document.filter(e => e.status !== 1 && e.status !== 2).map(e => e.name))
      // newDocument = document.map(e => ({...e, isActive:true}))
    }else{
      setArray([])
      setArrayName([])
      // newDocument = document.map(e => ({...e, isActive:false}))
    }
    // dispatch(setPartnerDocument(newDocument))
  }

  const handleSubmitReject = () => {
    const payload = { ids: array, rejection_reason: rejectionReason}
    dispatch(patchBulkRejectDocument(auth.access_token, detailTransaction.id, payload, id, detailTransactionIndex))
    setArray([])
    setArrayName([])
    setSelectAll(false)
  }
  
  const handleSubmitAppropriate = () => {
    const payload = { ids: array}
    dispatch(patchBulkAppropriateDocument(auth.access_token, detailTransaction.id, payload, id, detailTransactionIndex))
    setArray([])
    setArrayName([])
    setSelectAll(false)
  }

  const handleSubmitReset = (idDoc) => {
    dispatch(patchResetDocument(auth.access_token, idDoc, id, detailTransactionIndex))
  }

  const handleSubmitChangeReceipt = async() => {
    const payload = new FormData()
    const extension = await file?.name?.split('.').pop()
    if(extension === "pdf" || extension === "jpg" || extension === "jpeg" || extension === "png"){
      payload.append('receipt',file)
      dispatch(patchReceiptPaymentBill(auth.access_token, id, payload))
      setFile(null)
    }else{
      message.error("Extension File harus (pdf, jpg, jpeg, png)")
    }
  }
  
  const handleBack = () => {
    history.push("/dokumen-pembayaran", {
      section: "tagihan transaksi",
    });
  };

  const handleChange = (type, value) => {
    switch (type) {
      case "tab":
        setTab(value);
        break;
      default:
        break;
    }
  };

  const handleRenderColorDocPartner = (val) => {
    let backgroundColor = "#F1F3F4"
    if(val === 1) backgroundColor = "#0FAA4B"
    if(val === 2) backgroundColor = "#FF4949"
    return {backgroundColor}
  }

  const handleSelectDetailTransaction = (item, i) => {
    dispatch(setAdditional(item))
    setDetailTransaction(item)
    setDetailTransactionIndex(i)
  }
  
  const handlePaymentBilling = (val) => {
    if(val){
      setPayloadBayarTagihan({
        ...payloadBayarTagihan,
        customer_payment_method: val.name,
        customer_payment_logo: val.logo,
        customer_payment_account_name: val.account_name,
        customer_payment_account_number: val.account_number,
      })
      if(val.account_name && val.account_number){
        setModalProcessPayment(true)
      }
    }
  }

  const handleUpdateDeleteDocument = (idDocument, payload, param) => {
    if(param==='update') dispatch(updateDocumentById(auth.access_token, idDocument, payload, id, detailTransactionIndex))
    if(param==='delete') dispatch(deleteDocumentById(auth.access_token, idDocument, id, detailTransactionIndex))
  }
  
  const handleUpdateDeleteAdditionalData = (idDocument, payload, param) => {
    if(param==='update') dispatch(updateAdditionalDataById(auth.access_token, idDocument, payload, id, detailTransactionIndex))
    if(param==='delete') dispatch(deleteAdditionalDataById(auth.access_token, idDocument, id, detailTransactionIndex))
  }

  const handleSubmit = async () => {
    if(detail?.status === 1){
      console.log("confirm")
      dispatch(patchConfirmBillingById(auth.access_token, id))
    }
    if(detail?.status === 2){
      console.log("finishCheck")
      dispatch(patchFinishCheckBillingById(auth.access_token, id))
    }
    if(detail?.status === 3){
      console.log("finishPayment")
      let newPayload
      if(!payloadBayarTagihan.receipt){ // jika tidak uplad photo
        delete payloadBayarTagihan.receipt
        newPayload = payloadBayarTagihan
      }else{
        const extension = await payloadBayarTagihan.receipt.name.split('.').pop()
        if(extension === "pdf" || extension === "jpg" || extension === "jpeg" || extension === "png"){
          newPayload = new FormData()
          for (const key in payloadBayarTagihan) {
            newPayload.append(key, payloadBayarTagihan[key])
          }
        }else{
          message.error("Extension File harus (pdf, jpg, jpeg, png)")
          return;
        }
      }
      dispatch(patchFinishPaymentBillingById(auth.access_token, id, newPayload))
      setModalStatusPayment(true)
    }
  };

  /*======================== UseEffect ======================== */
  
  useEffect(() => {
    if (params.id) {
      async function getDetail() {
        dispatch(getBillingTransactionById(auth.access_token, id));
        dispatch(getPaymentList(auth.access_token))
        
      }
      getDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);
  
  useEffect(()=>{
    if(detail && detail?.trx_order_billing?.length){
      setDetailTransaction(detail?.trx_order_billing[0])
    }
  },[detail])
 
  /*======================== other ======================== */
  
  const tabOptions = [
    {
      title: "Dokumen Partner",
      value: "dokumen partner",
    },
    {
      title: "Data Tambahan",
      value: "data tambahan",
    }
  ];

  /*======================== Return ======================== */


  return (
    <div
      style={{ maxHeight: "100vh", width: "100%", padding: "30px", overflow:"auto" }}
      className="bg-ice custom-form"
    >
      {/* Header */}
      <Card
        className="p-4 rounded-card border-0"
        style={{ backgroundColor: "white" }}
      >
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex flex-row align-items-center">
            <FiChevronLeft
              fontSize={25}
              className="pointer me-3"
              onClick={handleBack}
            />
            <div>
              <h1 className="mb-0">Detail Penagihan Transaksi</h1>
              <p className="mb-0">
                Silahkan lakukan cek dokumen di setiap transaksi sebelum pembayaran
              </p>
              <h4 className="mb-0 mt-2">
                {detail?.mst_partner?.name ?? "-"} - {detail?.billing_number}
              </h4>
            </div>
          </div>
          {detail?.status === 1 && 
            <ConfirmAcceptBilling 
              onSubmit={handleSubmit}
            />
          }
          {detail?.status === 2 && 
            <ConfirmFinishChecking 
              onSubmit={handleSubmit}
            />
          }
          {detail?.status === 3 && (detail.payment_status === 0) && 
            <SelectPaymentMethod 
              show={modalPaymentMethod} 
              setShow={setModalPaymentMethod} 
              onSubmit={handlePaymentBilling}
            />
          }
          {detail?.status === 3 && (detail.payment_status === 0) && 
            <ProcessPayment 
              setPayload={setPayloadBayarTagihan} 
              payload={payloadBayarTagihan} 
              show={modalProcessPayment} 
              data={payloadBayarTagihan} 
              setModalPaymentMethod={setModalPaymentMethod} 
              setShow={setModalProcessPayment} onSubmit={handleSubmit}
              setModalProofPayment={setModalProofPayment}
            />
          }
          {detail?.status === 3 && (detail.payment_status === 1) && 
            <StatusPayment 
              show={modalStatusPayment} 
              setShow={setModalStatusPayment}
              file={file} 
              setFile={setFile}
              setModalProofPayment={setModalProofPayment}
              onSubmit={handleSubmitChangeReceipt}
            />
          }
          <ProofPayment
            show={modalProofPayment}
            setShow={setModalProofPayment} 
            setModalChangeReceipt={setModalChangeReceipt}
            payload={payloadBayarTagihan}
          />
          <ChangeReceiptPayment 
            show={modalChangeReceipt} 
            setShow={setModalChangeReceipt}
            file={file}
            setFile={setFile}
            onSubmit={handleSubmitChangeReceipt}
          />
          <ShowDokumen
            show={modalShowDokumen}
            handleClose={()=> setModalShowDokumen(false)}
            image={imgDetailTransaction}
          />
        </div>
      </Card>

      {/* Body || Content  */}
      <Row className="mt-4">
        {/* Left (Aktivitas Penagihan, Info Penagihan, Jumlah Tagihan, Info Pembayaran) */}
        <Col xs={12} md={4} className="mt-2">
          {/* Aktifitas Penagihan */}
          <ActivityBilling data={detail}/>

          {/* Info Penagihan */}
          <InfoBilling data={detail}/>

          {/* Jumlah Tagihan */}
          <SummaryBilling 
            data={detail} 
            modalDetaiPaymentBilling={modalDetaiPaymentBilling}
            setModalDetailPaymentBilling={setModalDetailPaymentBilling}
          />

          {/* Info Pembayaran */}
          <InfoPayment 
            data={detail} 
            modalProofPayment={modalProofPayment}
            setModalProofPayment={setModalProofPayment}
            setModalChangeReceipt={setModalChangeReceipt}
            payloadBayarTagihan={payloadBayarTagihan}
          />
        </Col>

        <Col xs={12} md={8} className="mt-2">
          <div>
            <h5 className="fw-bold">Transaksi & Lampiran</h5>
            <div className="bg-white rounded-card w-full" style={{height:"100vh"}}>
              <Row className="m-0 p-0" style={{height:"100%"}}>
                
                {/* List Transaksi */}
                <Col xs={4} className="p-3">
                  {detail?.trx_order_billing?.length ? detail?.trx_order_billing?.map((item, i)=>
                    <div key={i} onClick={()=>handleSelectDetailTransaction(item, i)} className="d-flex align-items-center">
                      {item.is_flag_for_customer && <span className="text-primary me-1">&bull;</span>}
                      <div
                        className="p-2 rounded mb-2 pointer w-100" 
                        style={{
                          backgroundColor:`${detailTransactionIndex === i ? '#F3F8F8':''}`, 
                          border:`1px solid ${detailTransactionIndex === i ? '#2BBECB':'#DEE2E6'}`
                        }}
                      >
                        <div className="d-flex justify-content-between mb-1">
                          <p className="m-0 underline" style={{color:"#FF1D8C", fontSize:"9px"}}>
                            {item?.trx_order?.order_number}
                          </p>
                          <p className="m-0 fw-bold " style={{fontSize:"9px"}}>
                            {formatRupiah(item?.trx_order.total_customer_paid)}
                          </p>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p className="m-0" style={{fontSize:"10px"}}>
                            {item?.trx_order?.trx_booking_send?.mst_vehicle?.plate_number} - {item?.trx_order?.mst_vehicle_subtype?.name}
                          </p>
                          <div className="d-flex align-items-center">
                            <h6 className="m-0" style={{fontSize:"9px", color:`${item?.total_document_confirmed === 0 ? "#E2E5E8" : "#333333"}`}}>
                              {item?.total_document_confirmed}
                            </h6>
                            <h6 className="m-0" style={{fontSize:"9px", color:`${item?.total_document === 0 ? "#E2E5E8" : "#333333"}`}}>
                              /{item?.total_document}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  ): <h6 className="text-muted text-center mt-5">Data Transaksi tidak ada</h6>}
                </Col>
                
                {/* Detail Transaksi */}
                <Col xs={8} className="m-0 p-0 position-relative" style={{borderLeft:'1px solid #E5E5E5', height:"100%"}}>
                  <div className="p-3 d-flex justify-content-between">
                    <div>
                      <h2 className="mb-1">Detail Transaksi</h2>
                      <p className="text-muted label mb-0">ID Transaksi: {detailTransaction?.trx_order?.order_number ?? "-"}</p>
                    </div>
                    {detail?.status === 2 && <RequestDocumentData data={detailTransaction} index={detailTransactionIndex}/>}
                  </div>

                  {/* Tab */}
                  <Tabs
                    activeKey={tab}
                    className="tabs-trx mb-2 border-bottom-2"
                    onSelect={(value) => handleChange("tab", value)}
                  >
                    {tabOptions.map((item, index) => (
                      <Tab key={index} eventKey={item.value} title={item.title} />
                    ))}
                  </Tabs>
                  <div style={{maxHeight:"75%", overflow:"auto"}}>
                    {/* Dokumen Partner */}
                    {tab === "dokumen partner" && (
                      <div className="p-3">
                        {document?.length ? document?.map((item, i)=>
                          <div key={i} className="mb-2 d-flex align-items-center">
                            {item.is_new === 1 ? <span className="text-primary me-1">&bull;</span> : null}
                            <div className="border rounded w-100">
                              <div className="d-flex justify-content-between align-items-center p-0" style={handleRenderColorDocPartner(item?.status)}>
                                <div className="d-flex align-items-center gap-1 p-2">
                                  {item.is_customer_request ? <p className={`fw-bold m-0 ${item.status && "text-white"}`}>(Request)</p> :  null }
                                  <p className={`m-0 text ${item.status && "text-white"}`}>{item?.name?.length > 20 ? `${item.name.substring(0, 20)}..` : !item.name ? "-" : item?.name}</p>
                                </div>
                                {detail?.status === 2 &&
                                  <div className="d-flex">
                                    {item?.is_customer_request === 1 && item?.status === 0 &&
                                      <div className="m-0 p-2 bg-white" style={{border:"1px solid #F1F3F4"}}>
                                        <ChangeRequestDocument data={item} onSubmit={handleUpdateDeleteDocument}/>
                                      </div>
                                    }
                                    {/* {item?.is_customer_request === 0 && item?.status !== 1 && */}
                                    {item?.status === 0 && item?.url_file &&
                                      <div className="m-0 p-2 bg-white" style={{border:"1px solid #F1F3F4"}}>
                                        <input
                                          type="checkbox" 
                                          className="m-0 p-0" 
                                          checked={array.includes(item.id)} 
                                          onChange={()=>handeCeklishDocument(item.id, item.name)} 
                                        />
                                      </div>
                                    }
                                    { (item?.status === 1 || item?.status === 2) &&
                                    <div className="d-flex align-items-center pointer" onClick={()=> handleSubmitReset(item.id)}>
                                        <FiRotateCcw className="pointer" style={{color:"white"}} />
                                        <p className="m-0 underline pointer ms-1 text-white mx-2">Reset</p>
                                        {/* <BsFillInfoCircleFill className="ms-2 pointer"/> */}
                                    </div>
                                    }
                                  </div>
                                }
                              </div>
                              <div className="p-3">
                                <div className="d-flex justify-content-between">
                                  <p className="text-muted label m-0">File Dokumen</p>
                                  <h6>Update: {item?.updated_at ? moment(item?.updated_at).format('DD/MM/YYYY HH:mm') : "-" }</h6>
                                </div>
                                <p 
                                  className={`m-0 my-2 ${item?.url_file && 'underline pointer'}`} 
                                  style={{color:`${item?.url_file ? "#FF1D8C" : ""}`}}
                                  onClick={() => {setModalShowDokumen(true); setImgDetailTransaction(item?.url_file)}}
                                >
                                  {item?.url_file?.length > 50 ? `${item?.url_file.substring(0, 50)}..` : item?.url_file}
                                </p>
                                {!item?.url_file && <p style={{color:`#FFAC14`}}> Menunggu Partner</p>}
                              </div>
                            </div>
                          </div>
                        ): <h6 className="text-muted text-center mt-5">Dokumen Partner tidak ada</h6>}
                        
                      
                      </div>
                    )}
                    {/* End - Dokumen Parnter */}
                    
                    {/* Data Tambahan */}
                    {tab === "data tambahan" && (
                      <div className="p-3">
                        {additionalData?.length ? additionalData?.map((item,i)=>
                          <div className="mb-2 d-flex align-items-center">
                            {item.is_new === 1 ? <span className="text-primary me-1">&bull;</span> : null}
                            <div className="border rounded d-flex justify-content-between align-items-center w-100">
                              <div className="d-flex justify-content-between align-items-center w-100 px-3">
                                <div className="py-1">
                                  <div className="d-flex align-items-center gap-1">
                                    {item.is_customer_request ? <p className="fw-bold m-0">(Request)</p> :  null }
                                    <p className="text-muted label m-0">{item?.name} {item.is_new === 1 && `(Baru)`}</p>
                                  </div>
                                  <p className="m-0 mt-2" style={{color:`${!item?.value && "#FFAC14"}`}}>{item?.value ? item?.value :  "Menunggu Partner"}</p>
                                </div>
                                <h6 style={{marginTop:"-15px"}}>Update: {item?.updated_at ? moment(item?.updated_at).format("DD/MM/YYYY HH:mm") : "-" }</h6>
                              </div>
                              {detail?.status === 2 && item?.is_customer_request === 1 &&
                                <div className="m-0 p-3 bg-white" style={{border:"1px solid #F1F3F4"}}>
                                  <ChangeRequestDocument 
                                    title="Data" 
                                    data={item} 
                                    onSubmit={handleUpdateDeleteAdditionalData}
                                  />
                                </div>
                              }
                            </div>
                          </div>
                        ) : <h6 className="text-muted text-center mt-5">Data tambahan tidak ada</h6>}
                      </div>
                    )}
                    {/* End - Data Tambahan */}

                  </div>
                  {/* Footer Detail Transaksi */}
                  {detail?.status === 2 && tab === "dokumen partner" &&
                    <div className="d-flex justify-content-between px-3 py-2 shadow-top border-top position-absolute bottom-0 w-100">
                        <div className="d-flex align-items-center">
                          <input type="checkbox" className="me-2" checked={selectAll} onChange={()=> handleSelectAll()} /> 
                          <h6 className="m-0">Pilih Semua</h6>
                        </div>
                        <div>
                          <RequestReject
                            onSubmit={handleSubmitReject} 
                            data={arrayName} 
                            rejectionReason={rejectionReason} 
                            setRejectionReason={setRejectionReason}
                          />
                          <RequestAppropriate 
                            onSubmit={handleSubmitAppropriate} 
                            data={arrayName}
                          />
                        </div>
                    </div>
                  }
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DetailDocumentPayment;
