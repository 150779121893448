import { Row, Col, Accordion } from "react-bootstrap";
import icon_truck from "../../../../assets/images/icon-truck.svg";
import ModelKendaraan from "./ModelKendaraan";

const TipeKendaraan = (props) => {
  const { data } = props;

  /*======================== Hooks ======================== */

  /*======================== UseState ======================== */

  /*======================== Handler ======================== */

  /*======================== UseEffect ======================== */

  /*======================== Others ======================== */

  const renderKendaraan = () => {
    let component = [];
    if (data && data.data && data.data.length > 0) {
      data.data.forEach((item, i) => {
        component.push(
          <Col xs={12} sm={6} md={4} key={i} style={{ padding: '5px' }}>
            <ModelKendaraan data={item} />
          </Col>
        );
      });
    } else {
      return null;
    }
    return component;
  };

  const getImageKendaraan = (items) => {
    if (items && items.length > 0) {
      let image = ""

      if (items[0].mst_vehicle_subtype?.icon) {
        image = items[0].mst_vehicle_subtype.icon
      } else {
        try {
          const obj = JSON.parse(items[0].vehicle_picture) 
          image = obj[0]
        } catch (e) {
          console.log(e)
        }
      }

      return image;
    } else {
      return icon_truck;
    }
  };

  /*======================== Return ======================== */

  return (
    <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <Row>
            <Col xs={2}>
              <img
                className="mt-2"
                src={getImageKendaraan(data.data)}
                width="100%"
                alt="vehicle"
                style={{
                  borderRadius: "10px",
                }}
              />
            </Col>
            <Col xs={10} className="ps-4">
              <p className="mb-1"> {data.type}</p>
              <p className="mb-1"> {data.data.length} Armada terdaftar</p>
            </Col>
          </Row>
        </Accordion.Header>
        <Accordion.Body>
          <Row>{renderKendaraan()}</Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default TipeKendaraan;
