import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Card, Button } from "react-bootstrap";
import Gmap from "../../components/gMap";
import { message } from "antd";
import useBreakpoint from "../../components/responsiveUtils/useBreakpoint";
import {
  hideSidenavMobile,
  openSidenavMobile,
  openMap,
  closeMap,
  showMapButtonBack,
} from "../../components/layouts/layout_helper";
import axios from "axios";

// component
import TabRegister from "./TabRegister";
import InfoPhone from "../../components/modalIntegrasiApi/InfoPhone";

// icons
import { IoMenuOutline } from "react-icons/io5";
import { AiOutlineLeft } from "react-icons/ai";

function IntegrasiApi(props) {
  const { isMobile } = useBreakpoint();
  const auth = useSelector((st) => st.authReducer);
  const [dataIntegrasi, setDataIntegrasi] = useState()

  useEffect(()=>{
    getData()
    // eslint-disable-next-line
  },[])

  const getData = async () => {
    try{
      const result = await axios.get(`${process.env.REACT_APP_API_END_POINT}/customer/integration_api`, {
        headers: { authorization: `Bearer ${auth.access_token}`,
        },
      });
      if(result.data.status === "success"){
        setDataIntegrasi(result.data.data)
      }
    }catch(err){
      console.log("Error ===>",err.response)
    }
  }
  
  // const handleEditSendOrder = async () => {
  //   let send_status
  //   if(dataIntegrasi.send_status === 0){
  //     send_status = 1
  //   }else{
  //     send_status = 0
  //   }
  //   const dataPayload = { "send_status" : send_status}
  //   try{
  //     const result = await axios.patch(`${process.env.REACT_APP_API_END_POINT}/customer/integration_api/service`,dataPayload, {
  //       headers: { authorization: `Bearer ${auth.access_token}` },
  //     });
  //     if(result.data.status === "success"){
  //       getData()
  //       message.success(result.data.message);
  //     }
  //   }catch(err){
  //       console.log("Error ===>",err.response)
  //       message.error(err.response.data.message);
  //   }
  // }

  const registrasiIntegrasiApi = async() => {
    try{
      const result = await axios.post(`${process.env.REACT_APP_API_END_POINT}/customer/integration_api`, {}, {
          headers: { authorization: `Bearer ${auth.access_token}`,
        },
      }); 
      if(result.status === 200){
        window.location.href = result.data.redirect_url;
      }
    }catch(err){
      console.log("Error ===>",err.response)
      message.error(err.response.data.message);
    }
  }

  useEffect(() => {
    if (isMobile) {
      hideSidenavMobile();
    }
  }, [isMobile]);

    return (
          <>
            <div style={{ height: "100vh", width: "100%" }} hidden={isMobile}>
              <Card className="card-absolute" >
                <Card.Body className="pb-0 flex-i">
                  <h3 className="fw-bold">
                    Integrasi API
                  </h3>
                </Card.Body>
                <TabRegister 
                  dataIntegrasi={dataIntegrasi}
                  // handleEditSendOrder={handleEditSendOrder}
                />
                <Card.Footer className="d-grid border-0 bg-0 mb-2">
                  <div className="d-grid">
                    {!dataIntegrasi && 
                    <Button
                      size="sm"
                      className="fw-bold py-2 rounded-pill"
                      onClick={() => registrasiIntegrasiApi()}
                    >
                      Aktifkan Integrasi API
                    </Button>
                    }
                    <InfoPhone/>
                  </div>
                </Card.Footer>
              </Card>
              <Gmap />
            </div>

            {isMobile && (
              <div style={{ height: "100vh", width: "100%"}}>
                <div className="w-100 p-2 head-menu shadow-md">
                    <div className="d-flex mt-auto mb-2 align-items-center w-100 px-2">
                      <div className="d-flex">
                        <IoMenuOutline
                          onClick={openSidenavMobile}
                          className="text-secondary pointer me-2"
                          style={{ fontSize: 25 }}
                        />
                        <h4 className="mt-1">
                          Integrasi API
                        </h4>
                      </div>
                      <h4
                        className="ms-auto mt-1 text-primary underline"
                        onClick={() => {
                            openMap();
                            showMapButtonBack();
                        }}
                        >
                        Lihat Rute
                        </h4>
                    </div>
                </div>
                <Card
                  className={"card-mobile"}
                  style={{marginTop:"14px"}}
                >
                  <TabRegister 
                    dataIntegrasi={dataIntegrasi}
                    // handleEditSendOrder={handleEditSendOrder}
                  />
                <Card.Footer
                  className="d-grid border-0 bg-0 mb-2"
                >
                  <div className="d-grid">
                    <Button
                      size="sm"
                      className="fw-bold py-2 rounded-pill"
                      onClick={() => registrasiIntegrasiApi()}
                    >
                      Aktifkan Integrasi API
                    </Button>
                    <InfoPhone/>
                  </div>
                </Card.Footer>
                </Card>
                <Button
                  className="rounded-circle bg-white shadow-md btn-back-map"
                  variant="light"
                  size="sm"
                  onClick={() => { closeMap(); }}
                >
                  <AiOutlineLeft />
                </Button>
                <Gmap />
              </div>
            )}
          </>
    )
}

const mapStateToProps = (state) => ({
    map: state.map
})

export default connect(mapStateToProps)(IntegrasiApi)