import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Card,
  Row,
  Col
} from "react-bootstrap";
import useBreakpoint from "../../../components/responsiveUtils/useBreakpoint";
import {
  getAccountList,
  getRoleDropList,
} from "../../../redux/action/usermanagement_action";
import { resetAuth } from "../../../redux/action/auth_action";
import UserMgtApi from "../../../api/user-management.api";
import { message } from "antd";
import UserAccountForm from "./UserAccountForm";
import UserAccountList from "./UserAccountList";

const UserAccountContent = () => {
  const dispatch = useDispatch();
  const childRef = useRef(null);
  const auth = useSelector((st) => st.authReducer);

  const { isMobile, isTablet } = useBreakpoint();
  const [dataForm, setDataForm] = useState({
    id: '',
    role_id: '',
    name: '',
    email: '',
    phone: '',
    password: '',
    photo: '',
    isEdit: false
  });

  const handleClick = () => {
    childRef.current.handleInput();
  };

  const submit = (data) => {
    delete data['id'];
    delete data['isEdit'];

    createAccount(data);
  };

  const createAccount = async (data) => {
    try {
      const api = new UserMgtApi();
      let fd = new FormData();

      for (const key in data) {
        fd.append(key, data[key]);
      }
      const response = await api.createAccount(auth.access_token, fd);

      if (response.status === 401) {
        dispatch(resetAuth());
      } else if (response.status === "success") {
        message.success("Akun Berhasil Dibuat");

        setDataForm({
          id: '',
          role_id: '',
          name: '',
          email: '',
          phone: '',
          password: '',
          photo: '',
          isEdit: false
        });
      }

    } catch (error) {
      console.log(error?.response);
    }
  };

  useEffect(() => {
    dispatch(getAccountList(auth.access_token));
  });

  useEffect(() => {
    dispatch(getRoleDropList(auth.access_token));
  });

  return (
    <>
      {isMobile ? (
        <Row style={{ marginTop: "150px" }}>
          <Col md={12} sm={12}>
            <UserAccountList />
          </Col>
        </Row>
      )
        : isTablet ? (
          <Row>
            <Col lg={12} md={12} sm={12}>
              <UserAccountList />
            </Col>
          </Row>
        ) : (
          <Row>
            <Col lg={6}>
              <UserAccountList />
            </Col>
            <Col lg={6}>
              <div className="px-3 py-2 mt-2">
                <h3>Tambah Akun</h3>
                <h5>Silahkan tambah Akun untuk mengatur layanan anda.</h5>
              </div>
              <Card className="rounded-card bg-white p-4 mb-3 border-0">
                <UserAccountForm ref={childRef} submit={submit} dataForm={dataForm} />
                <hr />
                <div className="d-flex justify-content-end align-items-end">
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      handleClick();
                    }}>
                    Tambah Akun
                  </button>
                </div>
              </Card>
            </Col>
          </Row>
        )
      }
    </>
  )
}

export default UserAccountContent;
