import React, { useState, useEffect } from "react";
import {
  Modal,
  FormGroup,
  Form,
  Button,
  Card,
  Row,
  Col,
  InputGroup,
  FloatingLabel,
} from "react-bootstrap";
import { Calendar } from "react-modern-calendar-datepicker";
import { MdDateRange, MdOutlineClose } from "react-icons/md";
import moment from "moment";
import useBreakpoint from "../responsiveUtils/useBreakpoint";

const DateRangeTrxBalance = ({ handleDateFilter, clearDateFilter }) => {
  const { isMobile } = useBreakpoint();
  const [show, setShow] = useState(false);
  const [label, setLabel] = useState("");

  const [selectedDayRange, setSelectedDayRange] = useState({
    from: null,
    to: null,
  });

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handlePick = () => {
    let date = {
      from: {
        ...selectedDayRange.from,
        month: selectedDayRange.from?.month - 1,
      },
      to: { ...selectedDayRange.to, month: selectedDayRange.to?.month - 1 },
    };

    let label = `${moment({ ...date.from }).format("DD/MMM/YYYY")} s/d ${moment(
      { ...date.to }
    ).format("DD/MMM/YYYY")}`;
    setLabel(label);
    handleDateFilter({ ...date });
    handleClose();
  };

  const clearLabelAndDateFilter = () => {
    setLabel("");
    clearDateFilter();
  };

  return (
    <>
      <div className="rounded-card border p-2 d-flex align-items-center ">
        <div
          className="d-flex align-items-center pointer w-100"
          onClick={handleShow}
        >
          <MdDateRange className="text-secondary" />
          <h6 className="ms-2" style={{ marginTop: "13px" }}>
            {label ? label : "Tanggal transaksi"}
          </h6>
        </div>
        {label && (
          <MdOutlineClose
            className="ms-auto me-3 pointer"
            onClick={clearLabelAndDateFilter}
          />
        )}
      </div>
      {/* <InputGroup className="border rounded-card">
        <InputGroup.Text
          className="border-0 search-icon-trx rounded-card text-secondary"
          style={{ height: "50px" }}
        >
          <MdDateRange />
        </InputGroup.Text>
        <FloatingLabel
          className="rounded-card w-75"
          label={"Pilih Tanggal Transaksi"}
          style={{ fontSize: "12px", height: "50px" }}
        >
          <Form.Control
            className="border-0 rounded-card"
            type="text"
            style={{ height: "50px" }}
            placeholder={"Pilih Tanggal Transaksi"}
            onClick={handleShow}
          />
        </FloatingLabel>
      </InputGroup> */}
      <Modal
        contentClassName={`${isMobile ? "" : "modal-80w"} p-1`}
        show={show}
        className=""
        onHide={handleClose}
        centere
      >
        <Modal.Header closeButton className="bg-white">
          <strong>Pilih Tanggal Transaksi</strong>
        </Modal.Header>
        <Card.Body>
          <Calendar
            value={selectedDayRange}
            onChange={(data) => {
              setSelectedDayRange(data);
            }}
            colorPrimary="#27C0CD"
            colorPrimaryLight="#F3F8F8"
            shouldHighlightWeekends
          />
        </Card.Body>
        <Modal.Footer className="border-top-0 bg-ice">
          <Button
            size="sm"
            variant="light"
            className="fw-bold me-4"
            onClick={() => handleClose()}
          >
            Batal
          </Button>
          <Button
            size="sm"
            variant="primary"
            className="fw-bold px-3"
            onClick={() => handlePick()}
          >
            Pilih
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DateRangeTrxBalance;
