import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Row, Col, InputGroup } from "react-bootstrap";
import { useSelector } from "react-redux";
import useBreakpoint from "../../../../components/responsiveUtils/useBreakpoint";
import { FaSearch } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import PropTypes from "prop-types";
import OrderSendApi from "../../../../api/order-send-api";
import "../style.scss";
import { message } from "antd";

const RuteList = (props) => {
  /*======================== Hooks ======================== */

  const { isMobile } = useBreakpoint();
  const contractInit = useSelector((st) => st.kirimbarangReducer.contract);
  const routeInit = useSelector((st) => st.kirimbarangReducer.route);
  const auth = useSelector((st) => st.authReducer);

  /*======================== Props ======================== */

  const { contractList, isOpen, actionUpdateRoute, handleClose } = props;

  /*======================== UseState ===================== */

  const [ruteList, setRuteList] = useState([]);
  const [masterRuteList, setMasterRuteList] = useState([]);
  const [search, setSearch] = useState("");
  const [contract, setContract] = useState({});
  const [rute, setRute] = useState({});

  /*======================== Handler ====================== */

  const handleOnSelect = () => {
    if (contract && contract.id !== "1") {
      if (rute && rute.route_code) {
        actionUpdateRoute(rute, contract);
      } else {
        message.error("Anda belum memilih rute");
      }
    } else {
      actionUpdateRoute(null, contract);
    }
  };

  const handleOnClose = () => {
    handleClose();
  };

  const handleClickRoute = (item) => {
    setRute(item);
  };

  const chooseContract = (item) => {
    setContract(item);
  };

  const getRoute = async (item) => {
    const api = new OrderSendApi();
    const data = await api.getRuteByTenderId(item.id, auth.access_token);
    setRuteList(data.data);
    setMasterRuteList(data.data);
  };

  const filterRoute = (value) => {
    if (value) {
      let data = masterRuteList.filter((item) =>
        item.name.toLowerCase().includes(search.toLowerCase())
      );
      setRuteList([...data]);
    }
  };

  const showContract = () => {
    let component = [];
    contractList.forEach((item, i) => {
      component.push(
        <span
          className={item.id === contract.id ? "cc-item selected" : " cc-item"}
          key={i}
          onClick={() => chooseContract(item)}
        >
          {item.name}
        </span>
      );
    });
    return component;
  };

  const showRute = () => {
    let component = [];
    ruteList.forEach((item, i) => {
      component.push(
        <Col
          className="px-1"
          xs={6}
          key={i}
          onClick={() => handleClickRoute(item)}
        >
          <div
            className={
              item.route_code === rute.route_code
                ? "rlc-item selected mb-2"
                : "rlc-item mb-2"
            }
          >
            {item.name}
          </div>
        </Col>
      );
    });
    return component;
  };

  /*======================== UseEffect =====================*/

  useEffect(() => {
    if (contractInit) {
      setContract(contractInit);
    }
  }, [contractInit]);

  useEffect(() => {
    if (routeInit) {
      setRute(routeInit);
    }
  }, [routeInit]);

  useEffect(() => {
    if (contract) {
      if (contract && (contract.id === "1" || !contract.id)) {
        setRute({});
        setRuteList([]);
        setMasterRuteList([]);
        setSearch("");
      } else {
        getRoute(contract);
      }
    }
  }, [contract]);

  useEffect(() => {
    if (search) {
      filterRoute(search);
    } else {
      setRuteList([...masterRuteList]);
    }
  }, [search]);

  /*======================== Return ======================== */

  return (
    <Modal
      contentClassName="rounded-card p-2"
      show={isOpen}
      onHide={handleOnClose}
      className="p-3"
      centered
    >
      <div className="d-flex p-2 border-bottom">
        <h5 className="fw-bold">Rute Pengiriman</h5>
        <MdClose className="ms-auto me-2 pointer" onClick={handleOnClose} />
      </div>
      <Modal.Body>
        <div className="contract">
          <h5 className="fw-bold">Pilih Kontrak Mengikat</h5>
          <div className="contract-content">{showContract()}</div>
        </div>

        {contract && contract.id !== "1" && (
          <div className="rute-list mt-4">
            <h5 className="fw-bold">Pilih Rute Pengiriman</h5>
            <InputGroup className="border rounded-input">
              <InputGroup.Text className="border-0 rounded-input">
                <FaSearch />
              </InputGroup.Text>
              <Form.Control
                type="text"
                className="border-0 rounded-input pointer"
                placeholder="Masukkan Lokasi"
                onChange={(ev) => {
                  setSearch(ev.target.value);
                }}
                style={{ height: "50px" }}
              ></Form.Control>
            </InputGroup>
            <div className="rute-list-content mt-2">
              <Row>{showRute()}</Row>
            </div>
          </div>
        )}
      </Modal.Body>

      <Modal.Footer>
        <div className="w-50 float-end">
          <Button
            size="sm"
            className="w-100 fw-bold rounded-pill text-capitalize mt-2"
            onClick={handleOnSelect}
          >
            Submit Rute
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

RuteList.propTypes = {
  contractList: PropTypes.array,
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
};

export default RuteList;
