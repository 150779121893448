import { v4 as uuidv4 } from "uuid";

const initState = {
  vType: {
    isLoading: false,
    data: [],
  },
  partnerService: {
    isLoading: false,
    data: [],
  },
  selectedVType: {},
  selectedPartnerService: {},
  tipe: "INSTANT",
  date: null,
  dateLabel: "",
  isOptimize: false,
  dragLogs: [],
  detailOrder: {
    driver_availability_rent: 0,
    helper_availability_send: 0,
    helper_availability_rent: 0,
    driver_price_rent: 0,
    helper_price_rent_send: 0,
    helper_price_rent: 0,
    driver_availability_rent_selected: false,
    helper_availability_send_selected: false,
    helper_availability_rent_selected: false,
    total_helper_requested: 0,
    insurance_fee: null,
    insurance_fee_id: null,
    insurance_desc: "",
    is_insurance_checked: false,
    eta: 0, // estimated time in minutes
    total_km: 0,
  },
  detail_price: {}, // BACKEND RESPONSE FOR SUMMARY
  pengambilan_new: [
    {
      catatan: "",
      isCod: false,
      isDetailFilled: false,
      lat: null,
      lng: null,
      lokasi: null,
      address_name: null,
      place_id: null,
      namaPenerima: "",
      name: "",
      nominalCod: null,
      nomorTelepon: "",
      patokan: "",
      detailMap: [],
      item_type: "",
    },
  ],
  pengambilan: {
    catatan: "",
    isCod: false,
    isDetailFilled: false,
    lat: null,
    lng: null,
    lokasi: null,
    address_name: null,
    place_id: null,
    namaPenerima: "",
    name: "",
    nominalCod: null,
    nomorTelepon: "",
    patokan: "",
    detailMap: [],
    item_type: "",
  },
  pengiriman: [
    {
      id: `item-${uuidv4()}`,
      content: `item ${uuidv4()}`,
      catatan: "",
      isCod: false,
      isDetailFilled: false,
      lat: null,
      lng: null,
      lokasi: null,
      namaPenerima: "",
      name: "",
      nominalCod: null,
      nomorTelepon: "",
      patokan: "",
      detailMap: [],
      key: 0,
      address_name: null,
      place_id: null,
      dropoff_reference: [],
      titikBalik: false,
    },
  ],
  penagihan: {
    catatan: "",
    isCod: false,
    isDetailFilled: false,
    lat: null,
    lng: null,
    lokasi: null,
    namaPenerima: "",
    name: "",
    nominalCod: null,
    nomorTelepon: "",
    patokan: "",
    detailMap: [],
  },
  backToPickupAddress: {
    catatan: "",
    isCod: false,
    isDetailFilled: false,
    lat: null,
    lng: null,
    lokasi: null,
    address_name: null,
    place_id: null,
    namaPenerima: "",
    name: "",
    nominalCod: null,
    nomorTelepon: "",
    patokan: "",
    detailMap: [],
    item_type: "",
    toggled: false,
  },
  contract: {
    id: "1",
    name: "Tanpa Kontrak",
  },
  deliveryProcurementRoute: {},
  route: null,
  additionalData: {
    noTF: "",
    file: [],
  },
};

export const kirimbarangReducer = (state = initState, action) => {
  switch (action.type) {
    case "UPDATE_DETAIL":
      return { ...state, ...action.payload };
    case "UPDATE_PENAGIHAN_KIRIM_BARANG_DETAIL":
      return {
        ...state,
        penagihan: { ...state.penagihan, ...action.payload },
      };
    case "UPDATE_PENGAMBILAN_KIRIM_BARANG_DETAIL": // gak kepake posisinya
      let returnState = {
        ...state,
        pengambilan: { ...state.pengambilan, ...action.payload },
      };

      if (state.backToPickupAddress.toggled) {
        returnState = {
          ...returnState,
          backToPickupAddress: {
            ...state.pengambilan,
            ...action.payload,
            toggled: true,
          },
        };
      }

      return {
        ...returnState,
      };
    case "ADD_NEW_PENGAMBILAN_NEW_DETAIL": // eRick
      return {
        ...state,
        pengambilan_new: [
          ...state.pengambilan_new,
          {
            catatan: "",
            isCod: false,
            isDetailFilled: false,
            lat: null,
            lng: null,
            lokasi: null,
            address_name: null,
            place_id: null,
            namaPenerima: "",
            name: "",
            nominalCod: null,
            nomorTelepon: "",
            patokan: "",
            detailMap: [],
            item_type: "",
          },
        ],
      };
    case "ADD_NEW_PENGIRIMAN_DETAIL":
      return {
        ...state,
        pengiriman: [
          ...state.pengiriman,
          {
            id: `item-${uuidv4()}`,
            content: `item ${uuidv4()}`,
            catatan: "",
            isCod: false,
            isDetailFilled: false,
            lat: null,
            lng: null,
            lokasi: null,
            namaPenerima: "",
            name: "",
            nominalCod: null,
            nomorTelepon: "",
            patokan: "",
            detailMap: [],
            address_name: null,
            place_id: null,
            key: state.pengiriman[state.pengiriman.length - 1].key + 1,
            dropoff_reference: [],
            titikBalik: false,
          },
        ],
      };
    case "UPDATE_PENGAMBILAN_NEW_DETAIL":
      let existingpengambilan = [...state.pengambilan_new];
      existingpengambilan[action.index] = {
        ...existingpengambilan[action.index],
        ...action.payload,
      };
      return { ...state, pengambilan_new: existingpengambilan };
    case "UPDATE_PENGIRIMAN_DETAIL":
      let existingpengiriman = [...state.pengiriman];
      existingpengiriman[action.index] = {
        ...existingpengiriman[action.index],
        ...action.payload,
      };
      return { ...state, pengiriman: existingpengiriman };

    case "UPDATE_ALL_PENGIRIMAN_DETAIL":
      return { ...state, pengiriman: action.payload };

    case "REMOVE_PENGAMBILAN_NEW_DETAIL":
      let newPengambilan = [...state.pengambilan_new];
      let itemPickUp = [];
      newPengambilan.map((x, index) => {
        if (index !== parseInt(action.index)) {
          itemPickUp.push(x);
        }
      });
      return { ...state, pengambilan_new: itemPickUp };

    case "REMOVE_PENGIRIMAN_DETAIL":
      let newPengiriman = [...state.pengiriman];
      let item = [];
      newPengiriman.map((x, index) => {
        if (index !== parseInt(action.index)) {
          item.push(x);
        }
      });
      return { ...state, pengiriman: item };
    case "BACK_TO_PICKUP_ON":
      return {
        ...state,
        backToPickupAddress: { ...state.backToPickupAddress, toggled: true },
      };

    case "BACK_TO_PICKUP_OFF":
      return {
        ...state,
        backToPickupAddress: {
          catatan: "",
          isCod: false,
          isDetailFilled: false,
          lat: null,
          lng: null,
          lokasi: null,
          address_name: null,
          place_id: null,
          namaPenerima: "",
          name: "",
          nominalCod: null,
          nomorTelepon: "",
          patokan: "",
          detailMap: [],
          item_type: "",
          toggled: false,
        },
      };
    case "UPDATE_BACK_TO_PICKUP_ADDRESS":
      return {
        ...state,
        backToPickupAddress: { ...action.payload },
      };

    case "RESET_DATA":
      return { ...initState };
    case "SET_CONTRACT":
      return {
        ...state,
        contract: { ...action.payload },
      };
    case "SET_ROUTE":
      return {
        ...state,
        route: { ...action.payload },
      };
    case "SET_ADDITIONAL_DATA":
      return {
        ...state,
        additionalData: { ...action.payload },
      };
    case "SET_DELIVERY_ROUTE":
      return {
        ...state,
        deliveryProcurementRoute: { ...action.payload },
      };
    default:
      return state;
  }
};
