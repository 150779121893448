import { BaseApiRequest } from "../base-api";
import { message } from "antd";
import axios from "axios";

export default class InvoiceApi extends BaseApiRequest {
  constructor() {
    super(process.env.REACT_APP_API_END_POINT);
  }

  async getInvoiceList(token, params) {
    let result;
    try {
      const response = await axios.get(
        `${this.endpoint}/customer/invoice/delivery/list`,
        {
          params,
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      result = response.data;
    } catch (e) {
      // message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async getInvoiceAll(token, params) {
    let result;
    try {
      const response = await axios.get(
        `${this.endpoint}/customer/invoice/delivery/list-search`,
        {
          params,
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      result = response.data;
    } catch (e) {
      // message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async getInvoiceDetail(token, id) {
    let result;
    try {
      const response = await axios.get(
        `${this.endpoint}/customer/invoice/delivery/detail/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      result = response.data;
    } catch (e) {
      // message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async createInvoice(token, payload) {
    let result;
    try {
      const response = await axios.post(
        `${this.endpoint}/customer/invoice/delivery`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      message.success("Invoice anda berhasil dibuat.");
      // return response;
      result = response.data;
    } catch (e) {
      // message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async updateInvoice(token, payload) {
    let result;
    const { id, ...others } = payload;
    try {
      const response = await axios.patch(
        `${this.endpoint}/customer/invoice/delivery`,
        others,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      result = response.data;
      message.success("Invoice anda berhasil diedit.");
    } catch (e) {
      // message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async deleteInvoice(token, route_ids) {
    let result;
    try {
      const response = await axios.delete(
        `${this.endpoint}/customer/invoice/delivery`,
        {
          headers: { Authorization: `Bearer ${token}` },
          data: {
            route_ids,
          },
        }
      );

      result = response.data;
    } catch (e) {
      // message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async assignInvoiceChecker(token, payload) {
    const { id, ...others } = payload;
    try {
      const response = await axios.patch(
        `${this.endpoint}/customer/invoice/delivery/assign-checker/${id}`,
        others,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    } catch (e) {
      // message.error(e?.response?.data?.message);
      throw e.response
    }
  }

  
  async createNegotiationLog(token, payload) {
    try {
      const response = await axios.post(
        `${this.endpoint}/customer/invoice/delivery/create/negotiation-log`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data;
    } catch (e) {
      // message.error(e?.response?.data?.message);
      throw e.response;
    }
  }

  async requestNewDoc(token, idDoc) {
    try {
      const response = await axios.patch(
        `${this.endpoint}/customer/invoice/delivery/update/document/${idDoc}`,
        null,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    } catch (e) {
      throw e.response
    }
  }

  async makePayment(token, payload) {
    const { id, ...others } = payload;
    try {
      const response = await axios.patch(
        `${this.endpoint}/customer/invoice/delivery/${id}/make-payment`,
        others,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    } catch (e) {
      // message.error(e?.response?.data?.message);
      throw e.response.data.message
    }
  }

  async updateCheckingStatus(token, payload) {
    const { id, ...others } = payload;
    try {
      const response = await axios.patch(
        `${this.endpoint}/customer/invoice/delivery/update/checking-status/${id}`,
        others,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    } catch (e) {
      // message.error(e?.response?.data?.message);
      throw e.response
    }
  }
}
