export const updateDetail = (payload) => ({
  type: "UPDATE_DETAIL",
  payload,
});

export const updatePickupDetail = (payload) => ({
  type: "UPDATE_PENGAMBILAN_KIRIM_BARANG_DETAIL",
  payload,
});

export const addNewPickUpDetail = () => ({ // eRICK
  type: "ADD_NEW_PENGAMBILAN_NEW_DETAIL",
});

export const addNewShipmentDetail = () => ({
  type: "ADD_NEW_PENGIRIMAN_DETAIL",
});

export const removePickUpDetail = (index) => ({
  type: "REMOVE_PENGAMBILAN_NEW_DETAIL",
  index,
});

export const removeShipmentDetail = (index) => ({
  type: "REMOVE_PENGIRIMAN_DETAIL",
  index,
});

export const updateAllShipmentDetail = (payload) => ({
  type: "UPDATE_ALL_PENGIRIMAN_DETAIL",
  payload,
});

export const updateSpesificPickUpDetail = (payload, index) => ({
  type: "UPDATE_PENGAMBILAN_NEW_DETAIL",
  payload,
  index,
});

export const updateSpesificShippmentDetail = (payload, index) => ({
  type: "UPDATE_PENGIRIMAN_DETAIL",
  payload,
  index,
});

export const resetKirimBarang = () => ({
  type: "RESET_DATA",
});

export const updateSpesificPenagihanDetail = (payload) => ({
  type: "UPDATE_PENAGIHAN_KIRIM_BARANG_DETAIL",
  payload,
});

export const setBackToPickupAddress = (condition) => ({
  type: "BACK_TO_PICKUP_" + condition,
});

export const updateBackToPickupAddress = (payload) => ({
  type: "UPDATE_BACK_TO_PICKUP_ADDRESS",
  payload
});

export const setContract = (payload) => ({
  type: "SET_CONTRACT",
  payload
});

export const setRoute = (payload) => ({
  type: "SET_ROUTE",
  payload
});

export const setAdditionalData = (payload) => ({
  type: "SET_ADDITIONAL_DATA",
  payload
});

export const setDeliveryRoute = (payload) => ({
  type: "SET_DELIVERY_ROUTE",
  payload
});

