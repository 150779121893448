import React, { useState } from "react";
import { Modal } from "react-bootstrap";

// icons
import { FiInfo } from "react-icons/fi";
import { MdClose } from "react-icons/md";

function InfoModalNormal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <FiInfo className="lipstick pointer" onClick={handleShow} />

      <Modal
        contentClassName="modal-100 p-3"
        show={show}
        onHide={handleClose}
        className=""
        centered
      >
        <div>
          <p className="fw-bold mb-2">
            <MdClose
              size={16}
              className="pointer"
              onClick={handleClose}
              style={{ marginTop: "-4px" }}
            />{" "}
            Pilihan Layanan Tambahan
          </p>
          <p className="mb-0">
            eLOG menyediakan Layanan Tambahan untuk memenuhi semua kebutuhan
            Anda.
          </p>
          <ul className="list-unstyled mb-0">
            <li className="bg-ice my-2 p-2 br-on">
              <p className="fw-bold mb-0">• Bantuan Supir</p>
              <span className="fs-13">
                Bantuan Supir sudah termasuk ke dalam harga/unit. Layanan
                Tambahan ini merupakan SOP dasar di eLOG.
              </span>
            </li>
            <li className="bg-ice mt-2 p-2 br-on">
              <p className="fw-bold mb-0">• Helper</p>
              <span className="fs-13">
                Helper adalah Layanan Tambahan dengan harga tambahan yang
                memiliki fungsi bantu bongkar/muat tanpa batasan jarak dan
                berat.
              </span>
            </li>
          </ul>
        </div>
      </Modal>
    </>
  );
}

export default InfoModalNormal;
