import React from "react";
import { Container } from "react-bootstrap";
import Sidebar from "./Sidebar";
import MetaTags from "react-meta-tags";

class MainLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSidebar: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick() {
    this.setState({
      activeSidebar: !this.state.activeSidebar,
    });
  }
  render() {
    const { children } = this.props;
    return (
      <>
        <MetaTags>
          <title>{this.props.metaTitle}</title>
          <meta name="description" content={this.props.metaDesc} />
          <meta property="og:title" content={this.props.metaTitle} />
          <meta property="og:image" content="path/to/image.jpg" />
        </MetaTags>
        <main>
          <div className="d-flex align-items-stretch">
            <Sidebar
              activeSidebar={this.state.activeSidebar}
              handleClick={() => {
                this.handleClick();
              }}
            />
            <div id="content" className="page-holder w-100">
              <Container
                fluid
                className="px-0 height-100"
                style={{ minHeight: "100vh" }}
              >
                {children}
              </Container>
            </div>
          </div>
        </main>
      </>
    );
  }
}

export default MainLayout;
