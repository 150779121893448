/* eslint-disable react-hooks/exhaustive-deps */
import { message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { Button as ButtonAntd } from "antd";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  confirmProcurement,
  createProcurementDocument,
  createProcurementHeader,
  createProcurementRoute,
  getProcurementContractDetail,
  getProcurementDocumentDetail,
  getProcurementHeaderDetail,
  updateProcurementDocument,
  updateProcurementHeader,
  updateProcurementRoute,
} from "../../../redux/action/Management/procurement_action";
import InformasiUtama from "./section/InformasiUtama";
import DokumenKontrak from "./section/DokumenKontrak";
import PenawaranKontrak from "./section/PenawaranKontrak";
import "./styles.scss";
import Preview from "./section/Preview";
import BatalkanProcurement from "./Modal/Batalkan";
import KonfirmasiProcurement from "./Modal/Konfirmasi";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import { draftToMarkdown, markdownToDraft } from "markdown-draft-js";
import { getRuteAll } from "../../../redux/action/RuteAlamat/rute_action";
import { getVehicleSubtype } from "../../../redux/action/Master/vehicle_action";

const PenawaranForm = () => {
  /*======================== Hooks ======================== */

  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  /*======================== Redux ======================== */

  const routeData = useSelector((st) => st.ruteReducer.all);
  const vehicleData = useSelector((st) => st.vehicleReducer.subtype.data);
  const partnerData = useSelector((st) => st.partnerReducer.selectable.data);

  /*======================== UseState ======================== */

  // container
  const [section, setSection] = useState(1);
  const [openBatalkan, setOpenBatalkan] = useState(false);
  const [openKonfirmasi, setOpenKonfirmasi] = useState(false);

  // Informasi Utama
  const [formValue, setFormValue] = useState({
    name: "",
    start_offering_date: "",
    end_offering_date: "",
    start_contract_date: "",
    end_contract_date: "",
    partners: [],
    areas: [],
    contract_description: "",
    payment_tenure_id: "",
    payment_tenure_name: "",
  });
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  // document
  const [document, setDocument] = useState([
    {
      id: 1,
      document_path: "",
      document_file_name: "",
      document_name: "",
      is_must_filled_by_partner: false,
    },
  ]);
  const [deleteDocument, setDeleteDocument] = useState([]);

  // kontrak
  const [kontrakItems, setKontrakItems] = useState([]);
  const [deleteKontrak, setDeleteKontrak] = useState([]);

  /*======================== Handler ======================== */

  const handleBack = () => {
    history.push("/penawaran", {
      section: "penawaran anda",
    });
  };

  const handleNextSection = () => {
    if (section === 4) {
      setOpenKonfirmasi(true);
    } else {
      setSection((old) => (old += 1));
    }
  };

  const handleBackSection = () => {
    switch (section) {
      case 1:
        handleBack();
        break;
      case 4:
        setOpenBatalkan(true);
        break;

      default:
        setSection((old) => (old -= 1));
        break;
    }
  };

  const handleSaveAsDraft = async ({ confirm }) => {
    if (params.id) {
      let responseHeader = await dispatch(
        updateProcurementHeader(handlePayloadHeader())
      );
      if (responseHeader?.status === "success") {
        let responseDocument = await dispatch(
          updateProcurementDocument({
            ...handlePayloadDocument(),
            id: params.id,
          })
        );
        let responseKontrak = await dispatch(
          updateProcurementRoute({
            ...handlePayloadKontrak(),
            id: params.id,
          })
        );

        if (
          responseKontrak.status === "success" &&
          responseDocument.status === "success"
        ) {
          if (confirm) {
            await dispatch(confirmProcurement(params.id));
            message.success("Procurement berhasil disimpan");
            handleBack();
          } else {
            message.success("Procurement berhasil disimpan sebagai draft");
            handleBack();
          }
        }
      }
    } else {
      let responseHeader = await dispatch(
        createProcurementHeader(handlePayloadHeader())
      );
      if (responseHeader?.status === "success") {
        let responseDocument = await dispatch(
          createProcurementDocument({
            documents: handlePayloadDocument(),
            delivery_procurement_id: responseHeader.data.id,
          })
        );
        let responseKontrak = await dispatch(
          createProcurementRoute({
            ...handlePayloadKontrak(),
            delivery_procurement_id: responseHeader.data.id,
          })
        );

        if (
          responseKontrak.status === "success" &&
          responseDocument.status === "success"
        ) {
          message.success("Procurement berhasil disimpan sebagai draft");
          handleBack();
        }
      }
    }
  };
  const handleDeleteDocument = (value) => {
    // setDeleteDocument([...deleteDocument, value]);
    setDeleteDocument((old) => [...old, value]);
  };

  const handleDeleteKontrak = (value) => {
    setDeleteKontrak((old) => [...old, value]);
  };

  const handlePayloadHeader = () => {
    const payload = {
      id: params?.id || "",
      name: formValue.name,
      start_offering_date: moment(formValue.start_offering_date).format(
        "YYYY-MM-DD"
      ),
      end_offering_date: moment(formValue.end_offering_date).format(
        "YYYY-MM-DD"
      ),
      start_contract_date: moment(formValue.start_contract_date).format(
        "YYYY-MM-DD"
      ),
      end_contract_date: moment(formValue.end_contract_date).format(
        "YYYY-MM-DD"
      ),
      partners: [],
      areas: [],
      contract_description: formValue.contract_description,
      tnc: draftToMarkdown(convertToRaw(editorState.getCurrentContent())),
      payment_tenure_id: formValue.payment_tenure_id,
    };
    if (!params.id) {
      delete payload.id;
    }
    if (formValue.partners.length > 0) {
      formValue.partners.forEach((item) => {
        payload.partners.push(item.trx_id);
      });
    }
    // else {
    //   partnerData.forEach((item) => {
    //     payload.partners.push(item.trx_id);
    //   });
    // }
    formValue.areas.forEach((item) => {
      payload.areas.push(item.id);
    });
    return payload;
  };
  const handlePayloadDocument = () => {
    if (params.id) {
      const payload = {
        id: params.id,
        removed_data: deleteDocument,
        updated_data: [],
        new_data: [],
      };
      document.forEach((item) => {
        if (item.document_path && item.document_name) {
          if (item.is_update) {
            payload.updated_data.push({
              id: item.id,
              document_path: item.document_path,
              document_name: item.document_name,
              file_name: item.document_file_name,
              is_must_filled_by_partner: item.is_must_filled_by_partner,
            });
          } else if (item.is_new) {
            payload.new_data.push({
              document_path: item.document_path,
              document_name: item.document_name,
              file_name: item.document_file_name,
              is_must_filled_by_partner: item.is_must_filled_by_partner,
            });
          }
        }
      });
      return payload;
    } else {
      const payload = [];
      document.forEach((item) => {
        payload.push({
          document_path: item.document_path,
          document_name: item.document_name,
          file_name: item.document_file_name,
          is_must_filled_by_partner: item.is_must_filled_by_partner,
        });
      });
      return payload;
    }
  };
  const handlePayloadKontrak = () => {
    if (params.id) {
      const payload = {
        id: params.id,
        removed_data: deleteKontrak,
        updated_data: [],
        new_data: [],
      };
      kontrakItems.forEach((item) => {
        if (item.is_new) {
          payload.new_data.push({
            route_id: item.route.route_id,
            route_name: `${item.route.pick_up_name} - ${item.route.delivery_name}`,
            route_code: item.route.route_code,
            route_budget: item.total_budget,
            vehicles: [
              ...item.vehicles.map((_) => ({
                id_vehicle_type: _.vehicle.type_id,
                id_vehicle_sub_type: _.vehicle.id,
                name: _.vehicle.name,
                tonage: _.vehicle.max_weight,
                volume: _.vehicle.volume,
                quantity: Number(_.quantity),
                budget: Number(_.budget),
                vehicle_subtype_code: _.vehicle.vehicle_subtype_code
              })),
            ],
          });
        } else if (item.is_update) {
          payload.updated_data.push({
            id: item.id,
            route_id: item.route.route_id,
            route_name: `${item.route.pick_up_name} - ${item.route.delivery_name}`,
            route_code: item.route.route_code,
            route_budget: item.total_budget,
            vehicles: [
              ...item.vehicles.map((_) => ({
                id_vehicle_type: _.vehicle.type_id,
                id_vehicle_sub_type: _.vehicle.id,
                name: _.vehicle.name,
                tonage: _.vehicle.max_weight,
                volume: _.vehicle.volume,
                quantity: Number(_.quantity),
                budget: Number(_.budget),
                vehicle_subtype_code: _.vehicle.vehicle_subtype_code
              })),
            ],
          });
        }
      });
      return payload;
    } else {
      const payload = {
        routes: kontrakItems.map((item) => ({
          route_id: item.route.route_id,
          route_name: `${item.route.pick_up_name} - ${item.route.delivery_name}`,
          route_code: item.route.route_code,
          route_budget: item.total_budget,
          vehicles: [
            ...item.vehicles.map((_) => ({
              id_vehicle_type: _.vehicle.type_id,
              id_vehicle_sub_type: _.vehicle.id,
              name: _.vehicle.name,
              tonage: _.vehicle.max_weight,
              volume: _.vehicle.volume,
              quantity: Number(_.quantity),
              budget: Number(_.budget),
              vehicle_subtype_code: _.vehicle.vehicle_subtype_code
            })),
          ],
        })),
      };
      return payload;
    }
  };
  const handleSubmitProcurement = async () => {
    try {
      if (params.id) {
        handleSaveAsDraft({ confirm: true });
        // await dispatch(confirmProcurement(params.id));
        // message.success("Procurement berhasil disimpan");
        // handleBack();
      } else {
        let responseHeader = await dispatch(
          createProcurementHeader(handlePayloadHeader())
        );
        if (responseHeader?.status === "success") {
          let responseDocument = await dispatch(
            createProcurementDocument({
              documents: handlePayloadDocument(),
              delivery_procurement_id: responseHeader.data.id,
            })
          );
          let responseKontrak = await dispatch(
            createProcurementRoute({
              ...handlePayloadKontrak(),
              delivery_procurement_id: responseHeader.data.id,
            })
          );

          if (
            responseKontrak.status === "success" &&
            responseDocument.status === "success"
          ) {
            await dispatch(confirmProcurement(responseHeader.data.id));
            message.success("Procurement berhasil disimpan");
            handleBack();
          }
        }
      }
    } catch (error) {
      message.error("Gagal mengkonfirmasi procurement");
    }
  };

  const handleDisabledNext = () => {
    if (section === 1) {
      if (
        !formValue.name ||
        !formValue.start_offering_date ||
        !formValue.end_offering_date ||
        !formValue.start_contract_date ||
        !formValue.end_contract_date ||
        formValue.partners.length < 1 ||
        formValue.areas.length < 1 ||
        !formValue.payment_tenure_id
      ) {
        return true;
      }
      return false;
    } else if (section === 2) {
      if (
        document.some(
          (item) => item.document_path !== "" && item.document_name !== ""
        )
      ) {
        return false;
      }
      return true;
    } else if (section === 3) {
      if (kontrakItems.length < 1) {
        return true;
      }
      return false;
    }

    return false;
  };
  /*======================== UseEffect ======================== */

  useEffect(() => {
    dispatch(
      getRuteAll({
        page: 1,
        limit: 9999,
        sort: "DESC",
      })
    );
    dispatch(
      getVehicleSubtype({
        page: 1,
        limit: 9999,
      })
    );
  }, []);

  useEffect(() => {
    if (
      params.id &&
      routeData.route_address?.length > 0 &&
      vehicleData.length > 0
    ) {
      async function getHeaderDetail() {
        const header = await dispatch(getProcurementHeaderDetail(params.id));
        if (header) {
          const dataHeader = {
            id: header.id,
            name: header.name,
            start_offering_date: header.start_offering_date,
            end_offering_date: header.end_offering_date,
            start_contract_date: header.start_contract_date,
            end_contract_date: header.end_contract_date,
            partners: header.partners.map((item) => ({
              trx_id: item.id || "",
              trx_name: item.name || "",
            })),
            areas: [],
            contract_description: header.contract_description,
            payment_tenure_id: header.payment_tenure_id,
            payment_tenure_name: header.payment_tenure_data?.tenure || 0,
          };
          if (header.areas.length > 0) {
            header.areas.map((item) => {
              dataHeader.areas.push(
                ...item.area.map((_) => ({
                  id: _.id,
                  name: _.name,
                  mst_province: {
                    id: _.province_id,
                    name: _.province_name,
                  },
                }))
              );
            });
          }

          const rawData = markdownToDraft(header.tnc);
          const contentState = convertFromRaw(rawData);
          const newEditorState = EditorState.createWithContent(contentState);
          setEditorState(newEditorState);
          setFormValue(dataHeader);
        }
      }
      async function getDocumentDetail() {
        const resDocument = await dispatch(
          getProcurementDocumentDetail(params.id)
        );
        if (resDocument?.length > 0) {
          const dataDocument = [];
          resDocument.map((item) => {
            dataDocument.push({
              id: item.id,
              document_path: item.document_path,
              document_file_name: item.file_name,
              document_name: item.document_name,
              is_must_filled_by_partner: item.is_must_filled_by_partner,
            });
          });
          setDocument(dataDocument);
        }
      }
      async function getContractDetail() {
        const resKontrak = await dispatch(
          getProcurementContractDetail(params.id)
        );
        if (resKontrak) {
          if (routeData.route_address?.length > 0) {
            const dataKontrak = resKontrak.map((item) => ({
              id: item.id,
              total_budget: item.route_budget,
              route:
                routeData.route_address.find(
                  (fnd) => fnd.route_id === item.route_id
                ) || {},
              vehicles:
                item.vehicles?.map((_) => ({
                  vehicle: {
                    type_id: _.id_vehicle_type,
                    id: _.id_vehicle_sub_type,
                    name:
                      vehicleData.find(
                        (fnd) => fnd.type_id === _.id_vehicle_type
                      ).name || {},
                    max_weight: _.tonage,
                    volume: _.volume,
                  },
                  budget: _.budget.toString(),
                  quantity: _.quantity,
                })) || [],
            }));
            setKontrakItems(dataKontrak);
          }
        }
      }

      getHeaderDetail();
      getDocumentDetail();
      getContractDetail();
    }
  }, [params, routeData, vehicleData]);

  /*======================== Return ======================== */

  return (
    <div className="procurement-form">
      <div
        style={{ width: "100%", padding: "30px" }}
        className="bg-ice custom-form procurement-body pb-2 height-100"
      >
        <Card
          className="p-4 rounded-card border-0"
          style={{ backgroundColor: "white" }}
        >
          {section < 4 && (
            <div className="d-flex flex-row align-items-center">
              <FiChevronLeft
                fontSize={25}
                className="pointer me-3"
                onClick={handleBack}
              />
              <div>
                <h1>Tambah Penawaran</h1>
                <p className="mb-0">
                  Buat penawaran untuk kebutuhan jangka panjang anda
                </p>
                <div className="d-flex align-items-center form-section">
                  <Button
                    className={`${section >= 1 ? "active" : ""} ps-0`}
                    variant="link"
                    style={{ cursor: "default" }}
                    // onClick={() => setSection(1)}
                  >
                    Informasi Utama
                  </Button>
                  <span
                    className={`separator ${section >= 2 ? " active" : ""}`}
                  >
                    ------
                  </span>
                  <Button
                    className={`${section >= 2 ? "active" : ""}`}
                    variant="link"
                    style={{ cursor: "default" }}
                    // onClick={() => setSection(2)}
                  >
                    Dokumen Kontrak
                  </Button>
                  <span
                    className={`separator ${section >= 3 ? " active" : ""}`}
                  >
                    ------
                  </span>
                  <Button
                    className={`${section >= 3 ? "active" : ""}`}
                    variant="link"
                    style={{ cursor: "default" }}
                    // onClick={() => setSection(3)}
                  >
                    Penawaran Kontrak
                  </Button>
                </div>
              </div>
            </div>
          )}
          {section === 4 && (
            <div className="d-flex flex-row align-items-center justify-content-between">
              <div>
                <h1>Konfirmasi Penawaran Anda</h1>
                <p className="mb-0">
                  Silahkan cek penawaran yang anda buat sebelum ditawarkan ke
                  partner
                </p>
              </div>
              <div className="d-flex flex-row gap-4">
                <Button
                  onClick={() => setSection(1)}
                  style={{ background: "transparent", color: "#ce1771" }}
                  className="rounded-pill"
                >
                  Ubah Penawaran
                </Button>
                <Button
                  onClick={handleSaveAsDraft}
                  className="fw-bold py-2 px-4 rounded-pill text-capitalize"
                >
                  Save As Draft
                </Button>
              </div>
            </div>
          )}
        </Card>

        <div style={{paddingBottom: "100px" }}>
          {section === 1 && (
            <InformasiUtama
              {...{
                formValue,
                setFormValue,
                editorState,
                setEditorState,
              }}
            />
          )}
          {section === 2 && (
            <DokumenKontrak
              {...{ document, setDocument, handleDeleteDocument }}
            />
          )}
          {section === 3 && (
            <PenawaranKontrak
              {...{
                kontrakItems,
                setKontrakItems,
                handleDeleteKontrak,
              }}
            />
          )}
          {section === 4 && (
            <Preview
              {...{ document, editorState }}
              header={formValue}
              kontrak={kontrakItems}
            />
          )}
        </div>
      </div>

      <div className="procurement-footer">
        <ButtonAntd
          size="sm"
          className="fw-bold py-2 px-4 rounded-pill text-capitalize me-2 custom-btn-primary2"
          onClick={handleBackSection}
        >
          {section === 1 || section === 4 ? "Batalkan" : "Kembali"}
        </ButtonAntd>

        <ButtonAntd
          size="sm"
          className="fw-bold py-2 px-4 rounded-pill text-capitalize custom-btn-primary2"
          disabled={handleDisabledNext()}
          onClick={handleNextSection}
        >
          {section === 4 ? "Simpan & Kirim" : "Selanjutnya"}
        </ButtonAntd>
      </div>
      <BatalkanProcurement
        isOpen={openBatalkan}
        handleClose={() => setOpenBatalkan(false)}
      />
      <KonfirmasiProcurement
        isOpen={openKonfirmasi}
        handleClose={() => setOpenKonfirmasi(false)}
        handleConfirm={handleSubmitProcurement}
      />
    </div>
  );
};

export default PenawaranForm;
