import PaymentApi from "../../api/payment-api";

export function gePaymentList() {
  return async (dispatch) => {
    dispatch(setPaymentList({ isLoading: true }));
    const api = new PaymentApi();
    const result = await api.getPaymentList();
    if (result.status === "success" && result.data && result.data.length > 0) {
      dispatch(setPaymentList({ isLoading: false, data: result.data }));
    } else {
      dispatch(setPaymentList({ isLoading: false, data: [] }));
    }
    return result.data;
  };
}

export const setPaymentList = (payload) => ({
  type: "SET_PAYMENT_LIST",
  payload,
});

export const setActiveEventKey = (payload) => ({
  type: "SET_ACTIVE_EVENT_KEY",
  payload,
});

export const setCustomPhoneNumber = (payload) => ({
  type: "SET_CUSTOM_PHONE_NUMBER",
  payload,
});

export const resetPaymentList = () => ({
  type: "RESET_PAYMENT_LIST",
});
