import React, { useState, useRef } from "react";
import {
  Modal,
  Form,
  Card,
  Col,
  Row,
  Button,
} from "react-bootstrap";
import {
  getAccountList,
} from "../../../redux/action/usermanagement_action";
import { useDispatch, useSelector } from "react-redux"; //erick (tambah use Selector)
import useBreakpoint from "../../responsiveUtils/useBreakpoint";
import icon_detail_MR_userManagment from "../../../assets/images/icon_detail_MR_userManagment.svg";
import { resetAuth } from "../../../redux/action/auth_action";
import UserMgtApi from "../../../api/user-management.api";
import { message } from "antd";
import UserAccountForm from "./UserAccountForm";

const UserAccountDetail = ({ detail }) => {
  const dispatch = useDispatch();
  const childRef = useRef(null);
  const { isMobile } = useBreakpoint();
  const auth = useSelector((st) => st.authReducer);

  const [show, setShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [dataForm, setDataForm] = useState({
    id: '',
    name: '',
    role_id: '',
    phone: '',
    email: '',
    password: '',
    photo: ''
  });

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setIsEdit(false);
    setShow(true);
  }

  const editData = () => {
    setDataForm({
      id: detail.id,
      name: detail.name,
      role_id: detail.role_id ? detail.role_id : '',
      phone: detail.phone ? initPhoneNumber(detail.phone) : '',
      email: detail.email,
      password: '',
      photo: detail.profile_picture,
      isEdit: true
    });

    setIsEdit(true);
  };

  const initPhoneNumber =  (item) => {
    if (item.charAt(0) === '0') {
      return item.substring(1);
    } else if (item.charAt(0) === '6') {
      return item.substring(2);
    } else {
      return item;
    }
  };

  const handleInput = async () => {
    childRef.current.handleInput();
  };

  const submit = (data) => {
    updateAccount(data)
  };

  const updateAccount = async (data) => {
    try {
      const api = new UserMgtApi();
      let fd = new FormData();

      for (const key in data) {
        fd.append(key, data[key]);
      }
      const response = await api.updateAccount(auth.access_token, data.id, fd);

      if (response.status === 401) {
        dispatch(resetAuth());
      } else if (response.status === "success") {
        message.success("Akun Berhasil Diupdate");
        handleClose();
        dispatch(getAccountList(auth.access_token));
      }

    } catch (error) {
      console.log(error?.response);
    }
  };

  return (
    <>
      {isMobile ?
        <img src={icon_detail_MR_userManagment} width="50" alt="detail" onClick={handleShow} style={{ cursor: "pointer" }} />
        :
        (<Button
          size="sm"
          className="float-end px-3"
          onClick={handleShow}
        >
          Lihat Detail
        </Button>)
      }

      <Modal
        contentClassName=""
        show={show}
        onHide={handleClose}
        className=""
        centered
      >
        <Modal.Header closeButton className="bg-white">
          <div>
            <h4 className="fw-bold">
              {isEdit ? 'Edit Akun' : 'Detail Akun'}
            </h4>
            <h6>
              {isEdit ? 'Ubah detail akun yang sudah anda buat.' : 'Detail Akun yang sudah Anda buat.'}
            </h6>
          </div>
        </Modal.Header>

        {
          isEdit ? (
            <Card className="rounded-card bg-white p-4 mb-1 border-0">
              <UserAccountForm ref={childRef} submit={submit} dataForm={dataForm} />
            </Card>
          )
            :
            (
              <Card className="rounded-card bg-white p-4 border-0">
                <Row>
                  <Col md={12}>
                    <div className="w-50 h-100 bg-danger rounded overflow-hidden">
                      {
                        detail && detail.profile_picture ? (
                          <img src={detail.profile_picture} alt="" className="w-100 h-100" />
                        ): (
                          <></>
                        )
                      }
                    </div>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3 mt-3" controlId="exampleForm.ControlInput1">
                      <h6 style={{ color: "#999999" }}>Nama Role</h6>
                      <h5>{detail.role_name ? detail.role_name : '-'}</h5>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3 mt-3" controlId="exampleForm.ControlInput1">
                      <h6 style={{ color: "#999999" }}>Nama Lengkap</h6>
                      <h5>{detail.name ? detail.name : '-'}</h5>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <h6 style={{ color: "#999999" }}>Email</h6>
                      <h5>{detail.email ? detail.email : '-'}</h5>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <h6 style={{ color: "#999999" }}>No Handphone</h6>
                      <h5>{detail.phone ? detail.phone : '-'}</h5>
                    </Form.Group>
                  </Col>
                </Row>
              </Card>
            )
        }

        <Modal.Footer className="border-top-0 mx-1 bg-ice">
          {
            isEdit ? (
              <Button
                size="sm"
                variant="primary"
                className="float-end rounded-pill px-3"
                onClick={() => {
                  handleInput();
                }}
              >
                Simpan
              </Button>

            )
              : (
                <Button
                  size="sm"
                  variant="outline-primary"
                  className="float-end rounded-pill py-2 px-4"
                  onClick={() => {
                    editData();
                  }}
                >
                  Edit Akun
                </Button>
              )
          }
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default UserAccountDetail;
