/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { FiChevronLeft, FiTrash2, FiPlus, FiEdit } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import ModalError from "../../../../components/modalError/modalError";
import { getAlamatAll } from "../../../../redux/action/RuteAlamat/alamat_action";
import {
  createRute,
  deleteRute,
  getRuteDetail,
  updateRute,
} from "../../../../redux/action/RuteAlamat/rute_action";
import RuteAlamatPengambilan from "./Modal/RuteAlamatPengambilan";
import RuteAlamatPengiriman from "./Modal/RuteAlamatPengiriman";

const LengkapiRuteForm = () => {
  /*======================== Hooks ======================== */

  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();

  /*======================== Redux ======================== */

  const loading = useSelector((st) => st.ruteReducer.loading);
  const deliveryList = useSelector(
    (st) => st.alamatReducer.alamat_pengiriman.all
  );
  const pickingList = useSelector((st) => st.alamatReducer.alamat_anda.all);

  /*======================== UseState ======================== */

  const [dataDetail, setDataDetail] = useState(undefined);
  const [isEdit, setIsEdit] = useState(true);
  const [openAlamatPengambilan, setOpenAlamatPengambilan] = useState(false);
  const [editPengambilan, setEditPengambilan] = useState(false);
  const [alamatPengambilan, setAlamatPengambilan] = useState([]);
  const [openRuteAlamat, setOpenRuteAlamat] = useState(false);
  const [ruteAlamat, setRuteAlamat] = useState([]);
  const [formValue, setFormValue] = useState({
    route_code: "",
    name: "",
  });
  const [editPengiriman, setEditPengiriman] = useState(false);
  const [initialValues, setInitialValues] = useState({
    route_code: "",
    name: "",
  });
  const [modalError, setModalError] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    title: "",
    message: "",
  });
  const [deleteDeliv, setDeleteDeliv] = useState([]);
  const [route_address, setRoute_address] = useState([]);

  /*======================== Handler ======================== */

  const handleBack = () => {
    history.push("/pengaturan/rute-alamat", {
      section: "rute anda",
    });
  };

  const handleAddAlamatPengambilan = () => {
    setOpenAlamatPengambilan(true);
    setEditPengambilan(false);
  };

  const handleEditAlamatPengambilan = () => {
    setOpenAlamatPengambilan(true);
    setEditPengambilan(true);
  };

  const handleCloseAlamatPengambilan = () => {
    setOpenAlamatPengambilan(false);
    setEditPengambilan(false);
  };

  const handleRemoveAlamatPengambilan = (value) => {
    const temp = alamatPengambilan.filter((item) => item.id !== value.id);
    setAlamatPengambilan([...temp]);
    if (temp.length === 0) {
      setRuteAlamat([]);
    }
    if (ruteAlamat.length > 0) {
      const exist = ruteAlamat.find(
        (item) => value.id === item.alamatPengambilan?.id
      );
      if (exist) {
        if (exist.route_id && exist.route_id !== "create-new-route") {
          setDeleteDeliv((oldValue) => [...oldValue, exist.route_id]);
        }
        setRuteAlamat((oldValue) => [
          ...oldValue.filter((item) => item.alamatPengambilan.id !== value.id),
        ]);
      }
    }
  };

  const handleAddRuteAlamat = () => {
    setOpenRuteAlamat(true);
    setEditPengiriman(false);
  };

  const handleEditRuteAlamat = () => {
    setOpenRuteAlamat(true);
    setEditPengiriman(true);
  };

  const handleCloseRuteAlamat = () => {
    setOpenRuteAlamat(false);
    setEditPengiriman(false);
  };

  const handleRemoveRuteAlamat = (value) => {
    if (value.route_id && value.route_id !== "create-new-route") {
      setDeleteDeliv((oldValue) => [...oldValue, value.route_id]);
    }
    setRuteAlamat((oldValue) => [
      ...oldValue.filter(
        (item) => item.alamatPengiriman.id !== value.alamatPengiriman.id
      ),
    ]);
  };

  const handleDataAlamat = (type, value) => {
    if (type === "pengambilan") {
      setAlamatPengambilan(value);
    } else {
      setRuteAlamat(value);
    }
  };

  const handleChange = (type, value) => {
    setFormValue({
      ...formValue,
      [type]: value,
    });
  };

  const handleSubmit = async () => {
    const payload = {
      ...formValue,
      route_address: [],
    };
    let response;
    if (params.id) {
      let responseDelete;
      // delete first if delete item is > 0
      if (deleteDeliv.length > 0) {
        responseDelete = await dispatch(deleteRute(deleteDeliv));
      }
      if (deleteDeliv.length === 0 || responseDelete.status === "success") {
        // update
        ruteAlamat.forEach((item) => {
          payload.route_address.push({
            route_id: item.route_id || "create-route",
            pick_up_address: item.alamatPengambilan.id,
            delivery_address: item.alamatPengiriman.id,
            is_updated: item.is_updated ? true : false,
          });
        });
        delete payload.created_at;
        response = await dispatch(updateRute(payload));
      }
    } else {
      // create
      ruteAlamat.forEach((item) => {
        payload.route_address.push({
          pick_up_address: item.alamatPengambilan.id,
          delivery_address: item.alamatPengiriman.id,
        });
      });
      response = await dispatch(createRute(payload));
    }
    if (response.status === "success") {
      handleBack();
    }
    if (
      response.status === 500 &&
      response.data.message.includes("Rute sudah pernah didaftarkan")
    ) {
      setModalError(true);
      setErrorMessage({
        title: "Rute sudah tersedia",
        message: response.data.message,
      });
    }
  };

  const handleDisabled = () => {
    const someNoPickingData = ruteAlamat.some(
      (item) => !item.alamatPengambilan
    );
    let unusedPengambilan = 0;
    if (alamatPengambilan.length > 0 && ruteAlamat.length > 0) {
      alamatPengambilan.forEach((item) => {
        const find = ruteAlamat.find((rute) => {
          if (rute.alamatPengambilan) {
            if (item.id === rute.alamatPengambilan.id) {
              return rute;
            }
          }
        });
        if (!find) {
          unusedPengambilan += 1;
        }
      });
    }
    if (
      !formValue.route_code ||
      !formValue.name ||
      ruteAlamat?.length === 0 ||
      someNoPickingData ||
      alamatPengambilan.length === 0 ||
      unusedPengambilan > 0
    ) {
      return true;
    }
    return false;
  };

  const handleTotalAddress = () => {
    let total = 0;
    const tempPickup = [];
    // counting pickup add
    route_address.forEach((item) => {
      if (
        tempPickup.findIndex((itemIdx) => itemIdx === item.pick_up_address) ===
        -1
      ) {
        tempPickup.push(item.pick_up_address);
      }
    });
    // counting deliv add
    route_address.forEach((item) => {
      if (
        tempPickup.findIndex((itemIdx) => itemIdx === item.delivery_address) ===
        -1
      ) {
        tempPickup.push(item.delivery_address);
      }
    });
    total += tempPickup.length;
    return total + " Alamat";
  };

  const handleUsedAlamatPengambilan = (item) => {
    if (ruteAlamat.length > 0) {
      const findUsed = ruteAlamat.find((rute) => {
        if (rute.alamatPengambilan) {
          if (item.id === rute.alamatPengambilan.id) {
            return rute;
          }
        }
      });
      if (findUsed) {
        return true;
      }
    }
    return false;
  };
  /*======================== UseEffect ======================== */

  useEffect(() => {
    dispatch(getAlamatAll({ address_type: "PICKING", sort_by: "created_at" }));
    dispatch(getAlamatAll({ address_type: "DELIVERY", order_by: "DESC" }));
  }, []);

  useEffect(() => {
    if (params.id) {
      async function getDetail() {
        const response = await dispatch(getRuteDetail({ code: params.id }));
        const data = {
          route_code: response.route_code,
          name: response.name,
          created_at: response.created_at,
        };
        const pickup = [];
        const rute = [];
        response.route_address.forEach((item) => {
          const findPickup = pickup.find(
            (pickup) => pickup.id === item.pick_up_address
          );
          if (!findPickup) {
            pickup.push({
              id: item.pick_up_address,
              code: item.pick_up_code,
              name: item.pick_up_name,
            });
          }
          rute.push({
            route_id: item.route_id,
            alamatPengiriman: {
              id: item.delivery_address,
              code: item.delivery_code,
              name: item.delivery_name,
            },
            alamatPengambilan: {
              id: item.pick_up_address,
              code: item.pick_up_code,
              name: item.pick_up_name,
            },
          });
        });
        setDataDetail(response);
        setAlamatPengambilan(pickup);
        setRuteAlamat(rute);
        setFormValue(data);
        setInitialValues(data);
        setRoute_address(response.route_address);
      }

      getDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  /*======================== Return ======================== */

  return (
    <div
      style={{ minHeight: "100vh", width: "100%", padding: "30px" }}
      className="bg-ice custom-form rute-form"
    >
      <Card
        className="p-4 rounded-card border-0"
        style={{ backgroundColor: "white" }}
      >
        <div className="d-flex flex-row align-items-center">
          <FiChevronLeft
            fontSize={25}
            className="pointer me-3"
            onClick={() => history.push("/pengaturan/rute-alamat")}
          />
          <div>
            <h1>Lengkapi Rute Anda</h1>
            <p className="mb-0">
              Lengkapi rute anda untuk memudahkan anda melakukan order
            </p>
          </div>
        </div>
      </Card>
      <Row className="mt-4">
        <Col xs={12} md={6} className="mt-2">
          <h5 className="fw-bold">Informasi Rute</h5>
          <div className="bg-white rounded-10 p-3" style={{ height: "78px" }}>
            <Row>
              <Col xs={6}>
                <div className="px-2">
                  <p className="text-muted label mb-0">kode rute</p>
                  <Form.Control
                    disabled={params.id || loading}
                    className="border-0 ps-0 pt-1"
                    placeholder="Tulis kode rute"
                    onChange={(event) => {
                      handleChange("route_code", event.target.value);
                    }}
                    defaultValue={formValue.route_code}
                  ></Form.Control>
                </div>
              </Col>
              <Col xs={6}>
                <div className="px-2">
                  <p className="text-muted label mb-0">nama rute</p>
                  <Form.Control
                    disabled={params.id && !isEdit}
                    className="border-0 ps-0 pt-1"
                    placeholder="Tulis nama rute"
                    onChange={(event) => {
                      handleChange("name", event.target.value);
                    }}
                    defaultValue={formValue.name}
                  ></Form.Control>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        {params.id && !isEdit && route_address.length > 0 && (
          <Col xs={12} md={6} className="mt-2">
            <div className="bg-white rounded-10 p-3 mt-4">
              <Row>
                <Col xs={6}>
                  <div className="px-2">
                    <p className="text-muted label mb-0">Total Titik Alamat</p>
                    <h5 className="mt-1">
                      {handleTotalAddress(initialValues.route_address)}
                    </h5>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        )}
      </Row>
      <Row>
        <Col xs={12} md={6} className="mt-2">
          <h5 className="fw-bold mt-3">Alamat Pengambilan</h5>
          {alamatPengambilan.length > 0 && (
            <div className="alamat-dipilih custom-scroll">
              {alamatPengambilan.map((item, idx) => (
                <div
                  // key={item.id}
                  key={"pick-up-" + idx}
                  className={`bg-white rounded-10 alamat-card mb-2${
                    handleUsedAlamatPengambilan(item) ? "" : " border-red"
                  }`}
                  style={{ height: "78px" }}
                >
                  <div className="ms-4 overflow-hidden">
                    <p className="text-muted label mb-1">Alamat Anda</p>
                    <h5
                      className={`text-ellipsis${
                        handleUsedAlamatPengambilan(item) ? "" : " red"
                      }`}
                    >
                      {item.code} - {item.name}
                    </h5>
                  </div>
                  {(!params.id || (params.id && isEdit)) && (
                    <div className="height-100 d-flex">
                      <div
                        className="rute-icon edit"
                        onClick={handleEditAlamatPengambilan}
                      >
                        <FiEdit className="icon-edit" />
                      </div>
                      <div
                        className="rute-icon delete rounded-end-10 border-start"
                        onClick={() => handleRemoveAlamatPengambilan(item)}
                      >
                        <FiTrash2 className="icon-trash" />
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
          {(!params.id || (params.id && isEdit)) && (
            <div
              className="bg-white rounded-10 alamat-card pointer"
              style={{ height: "78px" }}
              onClick={handleAddAlamatPengambilan}
            >
              <div className="ms-4">
                <p className="text-muted mb-1">
                  Tambahkan dari Alamat Tersimpan Anda
                </p>
              </div>
              <div className="rute-icon add rounded-end-10">
                <FiPlus className="icon-plus" />
              </div>
            </div>
          )}
        </Col>
        <Col xs={12} md={6} className="mt-2">
          <h5 className="fw-bold mt-3">Alamat Pengiriman</h5>
          {ruteAlamat.length > 0 && (
            <div className="alamat-dipilih custom-scroll rute-dipilih">
              {ruteAlamat.map((item, idx2) => (
                <div
                  // key={item.alamatPengiriman.id}
                  key={"delivery-" + idx2}
                  className={`bg-white rounded-10 alamat-card mb-2${
                    item.alamatPengambilan ? "" : " border-red"
                  }`}
                  style={{ height: "78px" }}
                >
                  <div className="ms-4 d-flex justify-content-between width-100 overflow-hidden">
                    <div style={{ width: "50%" }}>
                      <p className="text-muted label mb-1">Alamat Pengiriman</p>
                      <h5 className="text-ellipsis">
                        {item.alamatPengiriman.code} -{" "}
                        {item.alamatPengiriman.name}
                      </h5>
                    </div>
                    <div style={{ width: "50%" }}>
                      <p className="text-muted label mb-1">
                        Pengiriman dari Alamat
                      </p>
                      <h5
                        className={`text-ellipsis${
                          item.alamatPengambilan ? "" : " red"
                        }`}
                      >
                        {item.alamatPengambilan
                          ? `${item.alamatPengambilan.code} - ${item.alamatPengambilan.name}`
                          : "Pilih Alamat Pengambilan"}
                      </h5>
                    </div>
                  </div>
                  {(!params.id || (params.id && isEdit)) && (
                    <div className="height-100 d-flex">
                      <div
                        className="rute-icon edit"
                        onClick={handleEditRuteAlamat}
                      >
                        <FiEdit className="icon-edit" />
                      </div>
                      <div
                        className="rute-icon delete rounded-end-10 border-start"
                        onClick={() => handleRemoveRuteAlamat(item)}
                      >
                        <FiTrash2 className="icon-trash" />
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
          {(!params.id || (params.id && isEdit)) && (
            <>
              {alamatPengambilan.length > 0 ? (
                <div
                  className="bg-white rounded-10 alamat-card pointer"
                  style={{ height: "78px" }}
                  onClick={handleAddRuteAlamat}
                >
                  <div className="ms-4">
                    <p className="text-muted mb-0">
                      Tambahkan Alamat Pengiriman
                    </p>
                  </div>
                  <div className="rute-icon add rounded-end-10">
                    <FiPlus className="icon-plus" />
                  </div>
                </div>
              ) : (
                <div
                  className="bg-white rounded-10 alamat-card pointer disabled"
                  style={{ height: "78px" }}
                >
                  <div className="ms-4">
                    <p className="text-muted mb-0">
                      Tambahkan Alamat Pengiriman
                    </p>
                  </div>
                </div>
              )}
            </>
          )}
        </Col>
      </Row>
      <Button
        size="sm"
        className="fw-bold py-2 px-4 rounded-pill text-capitalize float-end mt-4"
        disabled={handleDisabled()}
        onClick={handleSubmit}
      >
        Simpan rute
      </Button>
      {openAlamatPengambilan && (
        <RuteAlamatPengambilan
          {...{ alamatPengambilan, editPengambilan, pickingList }}
          isOpen={openAlamatPengambilan}
          handleClose={handleCloseAlamatPengambilan}
          handleSubmit={(value) => handleDataAlamat("pengambilan", value)}
        />
      )}
      {openRuteAlamat && (
        <RuteAlamatPengiriman
          {...{
            alamatPengambilan,
            ruteAlamat,
            editPengiriman,
            deliveryList,
          }}
          isOpen={openRuteAlamat}
          handleClose={handleCloseRuteAlamat}
          handleSubmit={(value) => handleDataAlamat("pengiriman", value)}
        />
      )}
      <ModalError
        errorMessage={errorMessage}
        isOpen={modalError}
        handleClose={() => setModalError(false)}
      />
    </div>
  );
};

export default LengkapiRuteForm;
