import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";

const useBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState({
    isDesktop: false,
    isTablet: false,
    isMobile: false,
  }); //

  const isDesktop = useMediaQuery({
    query: "(min-width: 1024px)",
  });
  const isTablet = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1024px)",
  });
  const isMobile = useMediaQuery({
    query: "(min-width: 320px) and (max-width: 767px)",
  });

  useEffect(() => {
    let map = {};
    map["isDesktop"] = isDesktop;
    map["isTablet"] = isTablet;
    map["isMobile"] = isMobile;
    setBreakpoint({ ...map });
  }, [isDesktop, isTablet, isMobile]);

  return breakpoint;
};

export default useBreakpoint;
