import React from "react";
import { Row } from "react-bootstrap";
import PartnerListCard from "./PartnerListCard";
import PropTypes from "prop-types";
import CustomPagination from "../../../../components/pagination/customPagination";
import { useState } from "react";
import "../../style.scss";

const PartnerList = (props) => {
  /*======================== Props ======================== */

  const { data, handleLoad, canSave, service } = props;

  /*======================== Handler ======================== */

  const reload = () => {
    handleLoad();
  };

  /*======================== Return ======================== */

  return (
    <>
      <Row className="partner-list custom-scroll">
        {data.map((item, index) => (
          <PartnerListCard
            key={index}
            {...{ item }}
            canSave={canSave}
            reload={reload}
            service={service}
          />
        ))}
      </Row>
      <CustomPagination {...props} />
    </>
  );
};

PartnerList.propTypes = {
  handleChange: PropTypes.func,
  limit: PropTypes.number,
  page: PropTypes.number,
  loading: PropTypes.bool,
  data: PropTypes.array,
};

export default PartnerList;
