import React, { useState } from "react";
import {
  Modal,
  Button
} from "react-bootstrap";

const RequestAppropriate = ({onSubmit, data}) => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const handleSubmit = async() => {
    onSubmit()
    handleClose()
  }

  return(
  <>
    <Button
      disabled={data.length ? false : true}
      size="sm"
      className="fw-bold py-1 px-4 rounded-pill text-capitalize" 
      onClick={handleShow}
    >
      Sesuai
    </Button>
    
    <Modal
      contentClassName="rounded-card p-2"
      show={show}
      onHide={handleClose}
      centered
    >
      <Modal.Header closeButton className="bg-white">
        <strong>Dokumen Sesuai</strong>
      </Modal.Header>
      <div className="py-4">
          <h6 className="mb-3 px-4">Apakah anda yakin dokumen yang anda pilih sesuai dengan kebutuhan anda?</h6>
          <div className="px-4" style={{maxHeight:"175px", overflowY:"scroll"}}>
            {data?.map((item, i)=>
              <div key={i} className="border rounded p-2 px-3 mb-2" style={{backgroundColor:"#F1F3F4", color:"#666666", fontSize:"12px"}}>
                {item}
              </div>
            )}
          </div>
      </div>
      <Modal.Footer>
        <Button
          size="sm"
          className="fw-bold py-1 h-25 px-4 rounded-pill text-capitalize"
          onClick={handleSubmit}
        >
          Konfirmasi Sesuai
        </Button>
      </Modal.Footer>
    </Modal>
  </>
  )
}

export default RequestAppropriate;
