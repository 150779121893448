import { useState, useEffect } from "react";
import { Row, Modal } from "react-bootstrap";
import { BsWhatsapp } from "react-icons/bs";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { BiCopyAlt } from "react-icons/bi";

// redux
import { useSelector } from "react-redux";

const ContactModal = ({
  showShareTrackingLinkModal,
  closeShareTrackingLinkModal,
}) => {
  const trxDetail = useSelector((st) => st.trxHistoryReducer?.trxDetail);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    return () => {
      setCopied(false);
    };
  }, []);

  return (
    <Modal
      contentClassName="p-1"
      show={showShareTrackingLinkModal}
      onHide={closeShareTrackingLinkModal}
      className=""
      centered
    >
      <Modal.Header closeButton className="bg-white">
        <strong>Bagikan Live Tracking</strong>
      </Modal.Header>
      <Row>
        <div className="d-flex align-items-center my-2 justify-content-center ">
          <div>
            <a
              rel="noreferrer"
              target="_blank"
              href={`https://wa.me/?text=${process.env.REACT_APP_WEBSITE_URL}/tracking/${trxDetail?.data?.id}`}
            >
              <div className="bg-ice pointer d-flex align-items-center justify-content-center p-4 rounded">
                <BsWhatsapp
                  className="text-success mb-2"
                  style={{ fontSize: 50 }}
                />
              </div>
            </a>
            <p className="text-center mt-2">Whatsapp</p>
          </div>
          {/* <h5 className="fw-bold text-center ms-4">
            {trxDetail?.data?.mst_driver?.wa_number}
          </h5> */}
        </div>
        <div className="px-4 w-100 py-1">
          <p className="fw-bold">Web Live Tracking Link</p>
          <div className="d-flex w-100 align-items-center">
            <div className="px-2 py-1 rounded bg-ice">
              <h6 className="text-muted">
                {`${process.env.REACT_APP_WEBSITE_URL}/tracking/${trxDetail?.data?.id}`}
              </h6>
            </div>
            <CopyToClipboard
              text={`${process.env.REACT_APP_WEBSITE_URL}/tracking/${trxDetail?.data?.id}`}
              onCopy={() => setCopied(true)}
            >
              <BiCopyAlt
                className="text-muted pointer"
                style={{ fontSize: "18px", marginLeft: "10px" }}
              />
            </CopyToClipboard>
            {copied ? <span className="copied">Copied!</span> : null}
          </div>
        </div>
      </Row>
    </Modal>
  );
};

export default ContactModal;
