import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Card, Button } from "react-bootstrap";
import Gmap from "../../components/gMap";
import TabAuth from "../../components/tabAuth";
import TabPayment from "../../components/tabPayment";
import PaymentPending from "../../components/tabPayment/PaymentPending";
import TabSendGoods from "./TabSendGoods";
import TabService from "./TabService";
import TabSummary from "./TabSummary";
import { message } from "antd";
import { updateDetail } from "../../redux/action/kirimbarang_action";
import OrderSendApi from "../../api/order-send-api";
import { gePaymentList } from "../../redux/action/payment_list_action";
import { setActivePaymentList } from "../../redux/action/active_payment_action";
import AccountApi from "../../api/auth-api";
import { resetAuth } from "../../redux/action/auth_action";
import useBreakpoint from "../../components/responsiveUtils/useBreakpoint";
import {
  hideSidenavMobile,
  openSidenavMobile,
  openMap,
  closeMap,
  showMapButtonBack,
} from "../../components/layouts/layout_helper";

// icons
import { IoChevronBack, IoMenuOutline } from "react-icons/io5";
import { AiOutlineLeft } from "react-icons/ai";

const firstComponent = () => {
  return <TabSendGoods />;
};
const secondComponent = () => {
  return <TabService />;
};
const thirdComponent = () => {
  return <TabSummary />;
};
const ForthComponentLogined = () => {
  return <TabPayment />;
};
const ForthComponentUnLogined = () => {
  return <TabAuth />;
};
const LastComponent = () => {
  return <PaymentPending />;
};

function KirimBarang(props) {
  const { isMobile } = useBreakpoint();
  const dispatch = useDispatch();
  const [isLogin, setIsLogin] = useState(
    props.auth.access_token === null ? false : true
  );
  const [isVTypeAvailable, setIsVTypeAvailable] = useState(false);

  const [steps, setSteps] = useState([
    {
      key: "1",
      title: "Kirim Barang",
      isDone: true,
      component: firstComponent,
    },
    {
      key: "2",
      title: "Pilih Layanan Anda",
      isDone: false,
      component: secondComponent,
    },
    {
      key: "3",
      title: "Rangkuman Pengiriman",
      isDone: false,
      component: thirdComponent,
    },
    {
      key: "4",
      title: "",
      isDone: false,
      component: ForthComponentUnLogined,
    },
    {
      key: "5",
      title: "",
      isDone: false,
      component: ForthComponentLogined,
    },
    {
      key: "6",
      title: "",
      isDone: false,
      component: LastComponent,
    },
  ]);

  if (isVTypeAvailable) {
    message.error("Armada kendaraan tidak tersedia untuk wilayah tersebut!");
  }

  const [activeStep, setActiveStep] = useState(steps[0]);
  useEffect(() => {
    if (props.auth.access_token) {
      if (!isLogin) {
        setIsLogin(true);
        setActiveStep(steps[2]);
      }
    } else {
      if (isLogin) {
        setIsLogin(false);
      }
    }
  }, [props.auth, isLogin, steps]);
  // if (props.auth.access_token) {
  //   if (!isLogin) {
  //     setIsLogin(true);
  //     setActiveStep(steps[4]);
  //   }
  // }

  const [payment, setPayment] = useState(false);

  const getSubtypes = async () => {
    const api = new OrderSendApi();

    let payload = {};
    // arrange payload
    payload["send_category"] = props.tipe;
    payload["date"] =
      props.tipe === "INSTANT" ? new Date().toISOString() : props.date;
    payload["is_return"] = props.backToPickupAddress.toggled ? 1 : 0;
    payload["send_destination"] = [];

    // pengambilan
    for (let i = 0; i < props.pengambilan_new.length; i++) {
      let pickupPayload = {};
      pickupPayload["destination_type"] = "PICKUP";
      pickupPayload["destination_order"] = i;
      pickupPayload["longitude"] = props.pengambilan_new[i].lng;
      pickupPayload["latitude"] = props.pengambilan_new[i].lat;
      pickupPayload["address"] = props.pengambilan_new[i].lokasi;
      pickupPayload["location_description"] = props.pengambilan_new[i].patokan;
      pickupPayload["cod_amount"] = null;
      pickupPayload["note"] = props.pengambilan_new[i].catatan;
      pickupPayload["contact_name"] = props.pengambilan_new[i].namaPenerima;
      pickupPayload[
        "contact_phone_no"
      ] = `0${props.pengambilan_new[i].nomorTelepon}`;
      pickupPayload["item_type"] = props.pengambilan_new[i].item_type;
      pickupPayload["place_id"] = props.pengambilan_new[i].place_id;
      pickupPayload["address_name"] = props.pengambilan_new[i].address_name;
      payload["send_destination"].push(pickupPayload);
    }

    // pengiriman
    props.pengiriman.forEach((eachData, i) => {
      let dropoffPayload = {};
      dropoffPayload["destination_type"] = "DROPOFF";
      dropoffPayload["destination_order"] = i + props.pengambilan_new.length;
      dropoffPayload["longitude"] = eachData.lng;
      dropoffPayload["latitude"] = eachData.lat;
      dropoffPayload["address"] = eachData.lokasi;
      dropoffPayload["location_description"] = eachData.patokan;
      dropoffPayload["cod_amount"] = eachData.nominalCod;
      dropoffPayload["note"] = eachData.catatan;
      dropoffPayload["contact_name"] = eachData.namaPenerima;
      dropoffPayload["contact_phone_no"] = `0${eachData.nomorTelepon}`;
      dropoffPayload["item_type"] = eachData.item_type;
      dropoffPayload["place_id"] = eachData.place_id;
      dropoffPayload["address_name"] = eachData.address_name;
      dropoffPayload["dropoff_reference"] = eachData.dropoff_reference;
      payload["send_destination"].push(dropoffPayload);
    });

    // back to pickup address
    if (props.backToPickupAddress.toggled) {
      let backAddressPayload = {};
      backAddressPayload["destination_type"] = "DROPOFF";
      backAddressPayload["destination_order"] =
        payload["send_destination"].length;
      backAddressPayload["longitude"] = props.backToPickupAddress.lng;
      backAddressPayload["latitude"] = props.backToPickupAddress.lat;
      backAddressPayload["address"] = props.backToPickupAddress.lokasi;
      backAddressPayload["location_description"] =
        props.backToPickupAddress.patokan;
      backAddressPayload["cod_amount"] = props.backToPickupAddress.nominalCod;
      // backAddressPayload["note"] = props.backToPickupAddress.catatan; // tidak perlu
      backAddressPayload["contact_name"] =
        props.backToPickupAddress.namaPenerima;
      backAddressPayload[
        "contact_phone_no"
      ] = `0${props.backToPickupAddress.nomorTelepon}`;
      // backAddressPayload["item_type"] = props.backToPickupAddress.item_type; // tidak perlu
      backAddressPayload["place_id"] = props.backToPickupAddress.place_id;
      backAddressPayload["address_name"] =
        props.backToPickupAddress.address_name;
      payload["send_destination"].push(backAddressPayload);
    }

    // const { data } = await api.validateDestination(payload);
    const { data: subtypes } = await api.getAvailableVehicleSubtype(payload);

    props.dispatch(
      updateDetail({
        vType: { data: subtypes, isLoading: false },
      })
    );
    // console.log("SUBTYPES", subtypes);
    // console.log("data payload === >",payload)
    return subtypes;
  };

  const getSubtypeWithTender = async () => {
    const api = new OrderSendApi();
    const { data: subtypes } = await api.getSubtypeWithTender(
      props.contract.id,
      props.route.route_id,
      props.auth.access_token
    );
    props.dispatch(
      updateDetail({
        vType: { data: subtypes, isLoading: false },
      })
    );
    return subtypes;
  };

  const getPaymentList = async () => {
    if (props.paymentListReducer.data.length === 0) {
      dispatch(gePaymentList());
      return true;
    } else {
      return false;
    }
  };

  const createNewOrderSendGoods = async () => {
    try {
      const api = new OrderSendApi();

      let payload = {};
      // arrange payload
      payload["send_category"] = props.tipe;
      payload["date"] =
        props.tipe === "INSTANT" ? new Date().toISOString() : props.date;
      payload["is_return"] = props.backToPickupAddress.toggled ? 1 : 0;
      payload["send_destination"] = [];

      // pengambilan
      for (let i = 0; i < props.pengambilan_new.length; i++) {
        let pickupPayload = {};
        pickupPayload["destination_type"] = "PICKUP";
        pickupPayload["destination_order"] = i;
        pickupPayload["longitude"] = props.pengambilan_new[i].lng;
        pickupPayload["latitude"] = props.pengambilan_new[i].lat;
        pickupPayload["address"] = props.pengambilan_new[i].lokasi;
        pickupPayload["location_description"] =
          props.pengambilan_new[i].patokan;
        pickupPayload["cod_amount"] = null;
        pickupPayload["note"] = props.pengambilan_new[i].catatan;
        pickupPayload["contact_name"] = props.pengambilan_new[i].namaPenerima;
        pickupPayload[
          "contact_phone_no"
        ] = `0${props.pengambilan_new[i].nomorTelepon}`;
        pickupPayload["item_type"] = props.pengambilan_new[i].item_type;
        pickupPayload["place_id"] = props.pengambilan_new[i].place_id;
        pickupPayload["address_name"] = props.pengambilan_new[i].address_name;
        payload["send_destination"].push(pickupPayload);
      }

      // pengiriman
      props.pengiriman.forEach((eachData, i) => {
        let dropoffPayload = {};
        dropoffPayload["destination_type"] = "DROPOFF";
        dropoffPayload["destination_order"] = i + props.pengambilan_new.length;
        dropoffPayload["longitude"] = eachData.lng;
        dropoffPayload["latitude"] = eachData.lat;
        dropoffPayload["address"] = eachData.lokasi;
        dropoffPayload["location_description"] = eachData.patokan;
        dropoffPayload["cod_amount"] = eachData.nominalCod;
        dropoffPayload["note"] = eachData.catatan;
        dropoffPayload["contact_name"] = eachData.namaPenerima;
        dropoffPayload["contact_phone_no"] = `0${eachData.nomorTelepon}`;
        dropoffPayload["item_type"] = eachData.item_type;
        dropoffPayload["place_id"] = eachData.place_id;
        dropoffPayload["address_name"] = eachData.address_name;
        dropoffPayload["dropoff_reference"] = eachData.dropoff_reference;
        payload["send_destination"].push(dropoffPayload);
      });

      // back to pickup address
      if (props.backToPickupAddress.toggled) {
        let backAddressPayload = {};
        backAddressPayload["destination_type"] = "DROPOFF";
        backAddressPayload["destination_order"] =
          payload["send_destination"].length;
        backAddressPayload["longitude"] = props.backToPickupAddress.lng;
        backAddressPayload["latitude"] = props.backToPickupAddress.lat;
        backAddressPayload["address"] = props.backToPickupAddress.lokasi;
        backAddressPayload["location_description"] =
          props.backToPickupAddress.patokan;
        backAddressPayload["cod_amount"] = props.backToPickupAddress.nominalCod;
        // backAddressPayload["note"] = props.backToPickupAddress.catatan;
        backAddressPayload["contact_name"] =
          props.backToPickupAddress.namaPenerima;
        backAddressPayload[
          "contact_phone_no"
        ] = `0${props.backToPickupAddress.nomorTelepon}`;
        // backAddressPayload["item_type"] = props.backToPickupAddress.item_type;
        backAddressPayload["place_id"] = props.backToPickupAddress.place_id;
        backAddressPayload["address_name"] =
          props.backToPickupAddress.address_name;
        payload["send_destination"].push(backAddressPayload);
      }

      payload["sub_type_id"] = props.selectedVType.id;
      payload["partner_service_id"] = props.selectedPartnerService.id;
      payload["total_helper"] = props.detailOrder.total_helper_requested;
      payload["insurance_fee"] = props.detailOrder?.insurance_fee;
      payload["insurance_fee_id"] = props.detailOrder?.insurance_fee_id;
      if (props.contract && props.contract.id === "1") {
        payload["payment_method_id"] = props.activePayment.payment_method_id;
      } else {
        payload["payment_method_id"] = "48";
      }

      if (props.custom_phone_number) {
        payload["custom_phone_number"] = props.custom_phone_number;
      }

      if (props.contract && props.contract.id !== "1") {
        payload["tender_id"] = props.contract.id;
        payload["partner_id"] = props.selectedPartnerService.partner_id;
        payload["delivery_procurement_route_id"] = props.deliveryProcurementRoute.id;

        props.dispatch(
          setActivePaymentList({
            payment_type: '7',
            payment_name: 'Tempo',
          })
        );
      }

      if (props.additionalData && props.additionalData.noTF) {
        payload["dispatch_note"] = {
          noTF: props.additionalData.noTF,
          file: getAdditionalFilePayload(props.additionalData.file),
        };
        payload['customer_no_shipment'] = props.additionalData.noTF
      }
      
      // if (props.auth.is_special_customer > 0) {
      //   payload["customer_id"] = props.auth.id;
      //   // payload["is_special"] = 1;
      // }
      const result = await api.createOrder(payload, props.auth.access_token);
      
      if (result.status === "success") {
        props.dispatch(setActivePaymentList({ data: { ...result.data } }));
        message.success("Order berhasil dibuat.");
        return true;
      } else {
        message.error(result.message);
        return false;
      }
    } catch (error) {}
  };

  const getAdditionalFilePayload = (items) => {
    let data = [];
    items.forEach((item, i) => {
      data.push({
        document_name: item.document_name,
        document_path: item.document_path,
      });
    });

    return data;
  };

  const checkToken = async () => {
    try {
      const accountApi = new AccountApi();
      const result = await accountApi.checkToken(props.auth.refresh_token);
      if (result.status === "success") {
        setIsLogin(true);
        return true;
      } else {
        message.info("Silahkan Login terlebih dahulu");
        props.dispatch(resetAuth());
        return false;
      }
    } catch (error) {
      props.dispatch(resetAuth());
      return false;
    }
  };

  const handlePaymentPage = async () => {
    if (props.activePayment.payment_method_id) {
      return true;
    } else {
      return false;
    }
  };

  const handleNext = async () => {
    let isTokenValid = false;

    if (steps[steps.length - 1].key === activeStep.key) {
      alert("You have completed all steps.");
      return;
    }
    let allowNextStep = false;
    let subtypes;
    switch (activeStep.key) {
      case "1":
        if (props.contract.id === "1") {
          const resSubtype = await getSubtypes();
          subtypes = resSubtype;

          if (subtypes?.length > 0) {
            allowNextStep = true;
          } else {
            allowNextStep = false;
          }
        } else {
          const resSubtype = await getSubtypeWithTender();
          subtypes = resSubtype;

          if (subtypes?.length > 0) {
            allowNextStep = true;
          } else {
            allowNextStep = false;
          }
        }

        break;
      case "2":
        if (props.contract.id === "1") { 
          allowNextStep = true;
        } else {
          if ((props.selectedVType && props.selectedVType.id) && (props.selectedPartnerService && props.selectedPartnerService.id)) {
            allowNextStep = true;
          } else {
            allowNextStep = false;
          }
        }
       
        break;
      case "3":
        allowNextStep = true;
        break;
      case "4":
        allowNextStep = true;
        break;
      case "5":
        // allowNextStep = false;
        allowNextStep = await handlePaymentPage();
        break;
      default:
        allowNextStep = true;
        break;
    }

    if (allowNextStep) {
      let allowToPassed = false;
      switch (activeStep.key) {
        case "1":
          allowToPassed = true;
          break;
        case "2":
          allowToPassed = true;
          break;
        case "3":
          if (props.contract.id === "1") {
            await getPaymentList();
            isTokenValid = await checkToken();
            allowToPassed = true;
            break;
          } else {
            const result = await createNewOrderSendGoods();
            if (result) {
              setPayment(true);
              allowToPassed = true;
            }
            break;
          }

        case "5":
          if (props.activePayment.payment_method_id) {
            allowToPassed = true;
          } else {
            message.error("Silahkan pilih metode pembayaran");
          }
          break;
        default:
          allowToPassed = true;
          break;
      }
      if (allowToPassed) {
        const index = steps.findIndex((x) => x.key === activeStep.key);
        setSteps((prevStep) =>
          prevStep.map((x) => {
            if (x.key === activeStep.key) x.isDone = true;
            return x;
          })
        );
        let targetIndex = 0;
        if (props.contract && props.contract.id !== "1") {
          if (activeStep.key === "3") {
            targetIndex = 5;
          } else {
            targetIndex = index + 1;
          }
        } else {
          targetIndex = index + 1;
        }

        if (isLogin) {
          targetIndex =
            activeStep.key === "3" && isTokenValid ? 4 : targetIndex;
        }
        setActiveStep(steps[targetIndex]);
      }
      setIsVTypeAvailable(false);
    } else {
      switch (activeStep.key) {
        case "1":
          if (subtypes?.length === 0) {
            message.error("Kendaraan tidak tersedia di daerah anda");
          } else {
            const allow = handleFirstPage();
            if (!allow) {
              message.error("Mohon lengkapi data!");
            }
          }

          // }
          break;
        case "2":
          message.error(
            "Silahkan pilih tipe kendaraan dan partner penyedia kendaraan"
          );
          break;

        case "5":
          if (!props.activePayment.payment_method_id) {
            message.error("Silahkan pilih metode pembayaran");
          } else {
          }
          break;
        default:
          break;
      }
    }
  };

  const handleFirstPage = async () => {
    const pengiriman = [...props.pengiriman];
    let isPengirimanCompleted = false;

    for (const iterator of pengiriman) {
      if (
        iterator.lokasi &&
        iterator.namaPenerima &&
        iterator.nomorTelepon &&
        iterator.catatan
      ) {
        isPengirimanCompleted = true;
      }
    }
  };

  const handleSecondPage = async () => {
    if (
      props.kirimbarangReducer.vType.data.length > 0 &&
      props.kirimbarangReducer.partnerService.data.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleBack = () => {
    const index = steps.findIndex((x) => x.key === activeStep.key);
    if (index === 0) return;
    setSteps((prevStep) =>
      prevStep.map((x) => {
        if (x.key === activeStep.key) x.isDone = false;
        return x;
      })
    );

    // with logined assumption
    let targetIndex = index - 1;
    if (isLogin) {
      targetIndex = activeStep.key === "5" ? 2 : targetIndex;
    }
    setActiveStep(steps[targetIndex]);
  };

  const footerButton = () => {
    let footer = [];
    if (steps[steps.length - 1].key !== activeStep.key) {
      if (activeStep.key === "5" && isLogin === true) {
        footer.push(
          <Card.Footer className="bg-0 shadow-top border-top-0">
            <div className="d-grid">
              <Button
                size="sm"
                className="py-2 fw-bold rounded-pill"
                onClick={async () => {
                  if (props.activePayment.payment_method_id) {
                    const result = await createNewOrderSendGoods();
                    if (result) {
                      setPayment(true);
                    }
                  } else {
                    message.error("Silahkan pilih metode pembayaran");
                  }
                }}
              >
                Pesan Pengiriman
              </Button>
            </div>
          </Card.Footer>
        );
      } else {
        footer.push(
          <Card.Footer
            className={
              activeStep.key === "4" && isLogin === false
                ? "d-none"
                : "d-grid border-0 bg-0 mb-2"
            }
          >
            <div className="d-grid">
              <Button
                size="sm"
                className="fw-bold py-2 rounded-pill"
                onClick={handleNext}
              >
                Selanjutnya
              </Button>
            </div>
          </Card.Footer>
        );
      }
    }
    return footer;
  };

  useEffect(() => {
    if (isMobile) {
      hideSidenavMobile();
    }
  }, [isMobile]);

  const [showMap, setShowMap] = useState(false);

  return (
    <>
      <div style={{ height: "100vh", width: "100%" }} hidden={isMobile}>
        {payment ? (
          <PaymentPending />
        ) : (
          <Card
            className={
              activeStep.key === "4" && !isLogin
                ? "card-absolute height-auto"
                : "card-absolute"
            }
          >
            <Card.Body className="pb-0 flex-i">
              <h3 className="fw-bold">
                <IoChevronBack
                  onClick={handleBack}
                  className={
                    steps[0].key === activeStep.key ? "d-none" : "icon-back"
                  }
                />
                {activeStep.title}
              </h3>
            </Card.Body>
            {activeStep.component()}
            {footerButton()}
          </Card>
        )}
        <Gmap />
      </div>
      {isMobile && (
        <div style={{ height: "100vh", width: "100%" }}>
          <div className="w-100 p-2 head-menu shadow-md">
            <div className="d-flex mt-auto mb-2 align-items-center w-100 px-2">
              <div className="d-flex">
                {activeStep.key === "1" ? (
                  <IoMenuOutline
                    onClick={openSidenavMobile}
                    className="text-secondary pointer me-2"
                    style={{ fontSize: 25 }}
                  />
                ) : (
                  <IoChevronBack
                    onClick={handleBack}
                    style={{ fontSize: 25 }}
                    className="pointer me-2"
                  />
                )}
                <h4 className="mt-1">
                  {activeStep.key === "4"
                    ? "Login"
                    : activeStep.key === "5"
                    ? "Metode Pembayaran"
                    : activeStep.title}
                </h4>
              </div>
              {activeStep.key < 4 && (
                <h4
                  className="ms-auto mt-1 text-primary underline"
                  onClick={() => {
                    openMap();
                    showMapButtonBack();
                  }}
                >
                  Lihat Rute
                </h4>
              )}
            </div>
          </div>
          {payment ? (
            <PaymentPending />
          ) : (
            <Card
              className={
                "card-mobile"
                // activeStep.key === "4" && !isLogin
                //   ? "card-mobile height-auto"
                //   : "card-mobile"
              }
            >
              {/* <Card.Body className="pb-0 flex-i">
                  <h3 className="fw-bold">
                    <IoChevronBack
                      onClick={handleBack}
                      className={
                        steps[0].key === activeStep.key ? "d-none" : "icon-back"
                      }
                    />
                    {activeStep.title}
                  </h3>
                </Card.Body> */}
              {activeStep.component()}
              {footerButton()}
            </Card>
          )}
          <Button
            className="rounded-circle bg-white shadow-md btn-back-map"
            variant="light"
            size="sm"
            onClick={() => {
              closeMap();
            }}
          >
            <AiOutlineLeft />
          </Button>

          <Gmap />
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  map: state.map,
  pengambilan: state.kirimbarangReducer.pengambilan,
  pengambilan_new: state.kirimbarangReducer.pengambilan_new, // erick
  pengiriman: state.kirimbarangReducer.pengiriman,
  kirimbarangReducer: state.kirimbarangReducer,
  date: state.kirimbarangReducer.date,
  tipe: state.kirimbarangReducer.tipe,
  auth: state.authReducer,
  paymentListReducer: state.paymentListReducer,
  activePayment: state.activePaymentReducer,
  selectedVType: state.kirimbarangReducer.selectedVType,
  selectedPartnerService: state.kirimbarangReducer.selectedPartnerService,
  detailOrder: state.kirimbarangReducer.detailOrder,
  backToPickupAddress: state.kirimbarangReducer.backToPickupAddress,
  custom_phone_number: state.paymentListReducer.custom_phone_number,
  contract: state.kirimbarangReducer.contract,
  route: state.kirimbarangReducer.route,
  additionalData: state.kirimbarangReducer.additionalData,
  deliveryProcurementRoute: state.kirimbarangReducer.deliveryProcurementRoute
});
export default connect(mapStateToProps)(KirimBarang);
