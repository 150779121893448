import React from "react";
import {
  Modal,
  Button
} from "react-bootstrap";

const ConfirmDeleteDocument = ({title, show, setShow, onSubmitDelete}) => {
  const handleClose = () => setShow(false);

  return(
    <>
      <Modal
        contentClassName="rounded-card p-2"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton className="bg-white">
          <strong>Konfirmasi Hapus {title}</strong>
        </Modal.Header>
        <div className="p-4">
          <h6>Apakah anda yakin akan menghapus {title} yang anda request ke partner?</h6>
        </div>
        <Modal.Footer>
          <Button
              size="sm"
              className="fw-bold py-1 h-25 px-4 rounded-pill text-capitalize"
              onClick={()=> {handleClose(); onSubmitDelete('delete')}}
            >
            Konfirmasi Hapus
          </Button>
        </Modal.Footer>
      </Modal>
    </>
    )
  }

export default ConfirmDeleteDocument;
