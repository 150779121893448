import { message } from "antd";
import React, { useState } from "react";
import { Button, Col, Dropdown, Modal, Row } from "react-bootstrap";
import { FiPlus, FiTrash, FiTrash2 } from "react-icons/fi";
import { MdClose } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { assignInvoiceChecker } from "../../redux/action/Invoice/invoice_action";

const AddInvoice = (props) => {
  /*======================== Props ======================== */

  const { isOpen, handleClose, idInvoice } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const roleList = useSelector((st) => st.userMgtReducer.roleList.data);

  /*======================== UseState ======================== */

  const [pengecek, setPengecek] = useState([{}]);

  /*======================== Handler ======================== */

  const handleOnClose = () => {
    handleClose();
  };
  const handlePengecek = (index, item) => {
    let temp = pengecek;
    temp[index] = item;
    setPengecek([...temp]);
  };
  const handlePengecekAvailable = (role) => { 
    const found = pengecek.find(item => item.id === role.id)
    if (found) {
      return false
    }
    return true;
  }
  const handleAddPengecek = () => {
    setPengecek((old) => [...old, { value: "" }]);
  };
  const handleRemovePengecek = (index) => {
    const temp = pengecek.filter((_, idx) => idx !== index);
    setPengecek([...temp]);
  };

  const handleDisableSubmit = () => {
    const someIsEmpty = pengecek.some((item) => !item.name);
    return someIsEmpty;
  };
  const handleSubmit = async () => {
    // prepar pyaload 
    const checkers = pengecek.map(el => {
      return {
        role_id: el.id,
        role_name: el.name,
        status: 'WAITING'
      }
    })
    const payload = {
      id: idInvoice,
      checkers
    }
    try {
      await dispatch(assignInvoiceChecker(payload))
      history.push(`/detail-invoice/${idInvoice}`)
  
    } catch (error) {
      message.error('Gagal assign checker')
    }
  };

  /*======================== UseEffect ======================== */

  /*======================== Return ======================== */

  return (
    <Modal
      contentClassName="rounded-card p-2"
      show={isOpen}
      onHide={handleOnClose}
      className="p-3"
      centered
    >
      <div className="d-flex p-2 border-bottom">
        <h2 className="fw-bold">Atur Pengecek</h2>
        <MdClose className="ms-auto me-2 pointer" onClick={handleOnClose} />
      </div>
      <Modal.Body>
        {/* <Modal.Body className="overflow-auto" style={{ maxHeight: "30vh" }}> */}
        {pengecek.map((item, index) => (
          <div key={index} className="mb-2">
            <h4 className="fw-bold">Pengecek {index + 1}</h4>
            <div className="d-flex flex-wrap" style={{ gap: "1rem" }}>
              <div
                className="custom-dropdown card rounded-10"
                style={{ flex: 1 }}
              >
                <Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-autoclose-true"
                    size="lg"
                    style={{ padding: ".5rem" }}
                  >
                    {item.name || "Pilih Rute Anda"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu rounded-10 custom-scroll">
                    {roleList?.length > 0 &&
                      roleList.map((opt, idx) => handlePengecekAvailable(opt) && (
                        <div key={idx}>
                          <Dropdown.Item
                            href="#"
                            onClick={() => {
                              handlePengecek(index, opt);
                            }}
                          >
                            {opt.name}
                          </Dropdown.Item>
                          {idx !== roleList.length - 1 && (
                            <hr className="mx-3 my-2" />
                          )}
                        </div>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              {index === pengecek.length - 1 ? (
                <Button className="rounded-3" onClick={handleAddPengecek}>
                  <FiPlus />
                </Button>
              ) : (
                <button
                  className="btn rounded-3 border bg-transparent shadow-none"
                  style={{}}
                  onClick={() => {
                    handleRemovePengecek(index);
                  }}
                >
                  <FiTrash2 color="#bbb" />
                </button>
              )}
            </div>
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer>
        {/* <Button
          size="sm"
          className="fw-bold py-2 px-4 rounded-pill text-capitalize"
          // disabled={handleDisabledNext()}
          onClick={handleNextSection}
        >
          {section === 4 ? "Simpan & Kirim" : "Selanjutnya"}
        </Button> */}
        <Button
          size="sm"
          className="fw-bold py-2 px-4 rounded-pill text-capitalize custom-btn-primary"
          style={{ padding: "0.375rem 0.75rem" }}
          // className="w-100 fw-bold py-2 px-4 rounded-pill text-capitalize mt-2"
          disabled={handleDisableSubmit()}
          onClick={handleSubmit}
        >
          Simpan Pengecek Invoice
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddInvoice;
