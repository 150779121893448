import { message } from "antd";
import ProcurementApi from "../../../api/Management/procurement-api";
import { resetAuth } from "../auth_action";

export const getProcurementList = (params) => async (dispatch, state) => {
  try {
    const api = new ProcurementApi();

    dispatch({
      type: "SET_PROCUREMENT",
      payload: { loading: true },
    });

    const response = await api.getProcurementList(
      state().authReducer.access_token,
      params
    );
    if (response.status === 401) {
      dispatch(resetAuth());
    }
    if (response.status === "success") {
      const { data, ...meta } = response;
      if (params.is_contract) {
        dispatch({
          type: "SET_PROCUREMENT",
          payload: { in_contract: { data, meta } },
        });
      } else {
        dispatch({
          type: "SET_PROCUREMENT",
          payload: { in_procurement: { data, meta, filter: params } },
        });
      }
    }
  } catch (error) {
    console.log(error?.response);
  } finally {
    dispatch({
      type: "SET_PROCUREMENT",
      payload: { loading: false },
    });
  }
};

export const getProcurementFilter = (params) => async (dispatch, state) => {
  try {
    const api = new ProcurementApi();

    dispatch({
      type: "SET_PROCUREMENT",
      payload: { loading: true },
    });

    const response = await api.getProcurementList(
      state().authReducer.access_token,
      params
    );
    if (response.status === 401) {
      dispatch(resetAuth());
    }
    if (response.status === "success") {
      const { data, ...meta } = response;
      dispatch({
        type: "SET_PROCUREMENT",
        payload: { filter: { data, meta } },
      });
    }
  } catch (error) {
    console.log(error?.response);
  } finally {
    dispatch({
      type: "SET_PROCUREMENT",
      payload: { loading: false },
    });
  }
};

export const getProcurementHeaderDetail = (id) => async (dispatch, state) => {
  try {
    const api = new ProcurementApi();

    dispatch({
      type: "SET_PROCUREMENT",
      payload: { loading: true },
    });

    const response = await api.getProcurementHeaderDetail(
      state().authReducer.access_token,
      id
    );
    if (response.status === 401) {
      dispatch(resetAuth());
    }
    if (response.status === "success") {
      return response.data;
    }
  } catch (error) {
    console.log(error?.response);
  } finally {
    dispatch({
      type: "SET_PROCUREMENT",
      payload: { loading: false },
    });
  }
};
export const getProcurementDocumentDetail = (id) => async (dispatch, state) => {
  try {
    const api = new ProcurementApi();

    dispatch({
      type: "SET_PROCUREMENT",
      payload: { loading: true },
    });

    const response = await api.getProcurementDocumentDetail(
      state().authReducer.access_token,
      id
    );
    if (response.status === 401) {
      dispatch(resetAuth());
    }
    if (response.status === "success") {
      return response.data;
    }
  } catch (error) {
    console.log(error?.response);
  } finally {
    dispatch({
      type: "SET_PROCUREMENT",
      payload: { loading: false },
    });
  }
};
export const getProcurementContractDetail = (id) => async (dispatch, state) => {
  try {
    const api = new ProcurementApi();

    dispatch({
      type: "SET_PROCUREMENT",
      payload: { loading: true },
    });

    const response = await api.getProcurementContractDetail(
      state().authReducer.access_token,
      { id, page: 1, limit: 9999, sort_by: "created_at", order_by: "DESC" }
    );
    if (response.status === 401) {
      dispatch(resetAuth());
    }
    if (response.status === "success") {
      return response.data;
    }
  } catch (error) {
    console.log(error?.response);
  } finally {
    dispatch({
      type: "SET_PROCUREMENT",
      payload: { loading: false },
    });
  }
};

export const getProcurementContractRouteDetail =
  (id) => async (dispatch, state) => {
    try {
      const api = new ProcurementApi();

      dispatch({
        type: "SET_PROCUREMENT",
        payload: { loading: true },
      });

      const response = await api.getProcurementContractRouteDetail(
        state().authReducer.access_token,
        id
      );
      if (response.status === 401) {
        dispatch(resetAuth());
      }
      if (response.status === "success") {
        return response.data;
      }
    } catch (error) {
      console.log(error?.response);
    } finally {
      dispatch({
        type: "SET_PROCUREMENT",
        payload: { loading: false },
      });
    }
  };

export const getProcurementContractDetailRoutePartner =
  (params) => async (dispatch, state) => {
    try {
      const api = new ProcurementApi();

      dispatch({
        type: "SET_PROCUREMENT",
        payload: { loading: true },
      });

      const response = await api.getProcurementContractDetailRoutePartner(
        state().authReducer.access_token,
        params
      );
      if (response.status === 401) {
        dispatch(resetAuth());
      }
      if (response.status === "success") {
        return response.data;
      }
    } catch (error) {
      console.log(error?.response);
    } finally {
      dispatch({
        type: "SET_PROCUREMENT",
        payload: { loading: false },
      });
    }
  };

export const getProcurementContractDetailRoutePartnerWaiting =
  (params) => async (dispatch, state) => {
    try {
      const api = new ProcurementApi();

      dispatch({
        type: "SET_PROCUREMENT",
        payload: { loading: true },
      });

      const response =
        await api.getProcurementContractDetailRoutePartnerWaiting(
          state().authReducer.access_token,
          params
        );
      if (response.status === 401) {
        dispatch(resetAuth());
      }
      if (response.status === "success") {
        return response.data;
      }
    } catch (error) {
      console.log(error?.response);
    } finally {
      dispatch({
        type: "SET_PROCUREMENT",
        payload: { loading: false },
      });
    }
  };

export const getProcurementContractListDetail =
  (id) => async (dispatch, state) => {
    try {
      const api = new ProcurementApi();

      dispatch({
        type: "SET_PROCUREMENT",
        payload: { loading: true },
      });

      const response = await api.getProcurementContractDetail(
        state().authReducer.access_token,
        id
      );
      if (response.status === 401) {
        dispatch(resetAuth());
      }
      if (response.status === "success") {
        const { data, ...meta } = response;
        dispatch({
          type: "SET_PROCUREMENT",
          payload: { contract_list_detail: { data, meta } },
        });
        return response.data;
      }
    } catch (error) {
      console.log(error?.response);
    } finally {
      dispatch({
        type: "SET_PROCUREMENT",
        payload: { loading: false },
      });
    }
  };

export const createProcurementHeader = (payload) => async (dispatch, state) => {
  try {
    const api = new ProcurementApi();

    dispatch({
      type: "SET_PROCUREMENT",
      payload: { loading: true },
    });

    const response = await api.createProcurementHeader(
      state().authReducer.access_token,
      payload
    );

    if (response.status === 401) {
      dispatch(resetAuth());
    }
    return response;
  } catch (error) {
    console.log(error?.response);
  } finally {
    dispatch({
      type: "SET_PROCUREMENT",
      payload: { loading: false },
    });
  }
};

export const confirmProcurement = (payload) => async (dispatch, state) => {
  try {
    const api = new ProcurementApi();

    dispatch({
      type: "SET_PROCUREMENT",
      payload: { loading: true },
    });

    const response = await api.confirmProcurement(
      state().authReducer.access_token,
      payload
    );

    if (response.status === 401) {
      dispatch(resetAuth());
    }
    return response;
  } catch (error) {
    throw error;
  } finally {
    dispatch({
      type: "SET_PROCUREMENT",
      payload: { loading: false },
    });
  }
};

export const changeOfferStatus = (payload, id) => async (dispatch, state) => {
  try {
    const api = new ProcurementApi();

    dispatch({
      type: "SET_PROCUREMENT",
      payload: { loading: true },
    });

    const response = await api.updatePartnerOffer(
      state().authReducer.access_token,
      id,
      payload
    );

    if (response.status === 401) {
      dispatch(resetAuth());
    }
    return response;
  } catch (error) {
    throw error;
  } finally {
    dispatch({
      type: "SET_PROCUREMENT",
      payload: { loading: false },
    });
  }
};

export const updateProcurementHeader = (payload) => async (dispatch, state) => {
  try {
    const api = new ProcurementApi();

    dispatch({
      type: "SET_PROCUREMENT",
      payload: { loading: true },
    });

    const response = await api.updateProcurementHeader(
      state().authReducer.access_token,
      payload
    );

    if (response.status === 401) {
      dispatch(resetAuth());
    }
    return response;
  } catch (error) {
    console.log(error?.response);
  } finally {
    dispatch({
      type: "SET_PROCUREMENT",
      payload: { loading: false },
    });
  }
};

export const createProcurementRoute = (payload) => async (dispatch, state) => {
  try {
    const api = new ProcurementApi();

    dispatch({
      type: "SET_PROCUREMENT",
      payload: { loading: true },
    });

    const response = await api.createProcurementRoute(
      state().authReducer.access_token,
      payload
    );

    if (response.status === 401) {
      dispatch(resetAuth());
    }
    return response;
  } catch (error) {
    console.log(error?.response);
  } finally {
    dispatch({
      type: "SET_PROCUREMENT",
      payload: { loading: false },
    });
  }
};

export const updateProcurementRoute = (payload) => async (dispatch, state) => {
  try {
    const api = new ProcurementApi();

    dispatch({
      type: "SET_PROCUREMENT",
      payload: { loading: true },
    });

    const response = await api.updateProcurementRoute(
      state().authReducer.access_token,
      payload
    );

    if (response.status === 401) {
      dispatch(resetAuth());
    }
    return response;
  } catch (error) {
    console.log(error?.response);
  } finally {
    dispatch({
      type: "SET_PROCUREMENT",
      payload: { loading: false },
    });
  }
};

export const createProcurementDocument =
  (payload) => async (dispatch, state) => {
    try {
      const api = new ProcurementApi();

      dispatch({
        type: "SET_PROCUREMENT",
        payload: { loading: true },
      });

      const response = await api.createProcurementDocument(
        state().authReducer.access_token,
        payload
      );

      if (response.status === 401) {
        dispatch(resetAuth());
      }
      return response;
    } catch (error) {
      console.log(error?.response);
    } finally {
      dispatch({
        type: "SET_PROCUREMENT",
        payload: { loading: false },
      });
    }
  };

export const updateProcurementDocument =
  (payload) => async (dispatch, state) => {
    try {
      const api = new ProcurementApi();

      dispatch({
        type: "SET_PROCUREMENT",
        payload: { loading: true },
      });

      const response = await api.updateProcurementDocument(
        state().authReducer.access_token,
        payload
      );

      if (response.status === 401) {
        dispatch(resetAuth());
      }
      return response;
    } catch (error) {
      console.log(error?.response);
    } finally {
      dispatch({
        type: "SET_PROCUREMENT",
        payload: { loading: false },
      });
    }
  };

export const getMasterArea = (payload) => async (dispatch, state) => {
  try {
    const api = new ProcurementApi();

    dispatch({
      type: "SET_PROCUREMENT",
      payload: { loading: true },
    });

    const response = await api.getMasterArea(
      state().authReducer.access_token,
      payload
    );

    if (response.status === 401) {
      dispatch(resetAuth());
    }

    dispatch({
      type: "SET_MASTER_AREA",
      payload: response?.data,
    });

    return response;
  } catch (error) {
    console.log(error?.response);
  } finally {
    dispatch({
      type: "SET_PROCUREMENT",
      payload: { loading: false },
    });
  }
};

export const updateProcurementStatus = (payload) => async (dispatch, state) => {
  try {
    const api = new ProcurementApi();

    dispatch({
      type: "SET_PROCUREMENT",
      payload: { loading: true },
    });

    const response = await api.updateProcurementStatus(
      state().authReducer.access_token,
      payload
    );

    if (response.status === 401) {
      dispatch(resetAuth());
    }
    if (response.status === "success") {
      message.success("Procurement berhasil dibatalkan");
    }
    return response;
  } catch (error) {
    console.log(error?.response);
  } finally {
    dispatch({
      type: "SET_PROCUREMENT",
      payload: { loading: false },
    });
  }
};
