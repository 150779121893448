import { Modal, Row, Col } from "react-bootstrap";
import { MdClose } from "react-icons/md";

const Rute = (props) => {
  /*======================== Hooks ======================== */

  /*======================== Props ======================== */

  const { isOpen, data, handleClose } = props;

  /*======================== UseState ======================== */

  /*======================== Handler ======================== */
  const handleOnClose = () => {
    handleClose();
  };

  const renderData = () => {
    return (
        <>
            <div>
                <h5 className=""> Nama Rute </h5>
                <h4 className="fw-bold"> {data.name} </h4>
            </div>
            <div className="mt-4">
                <h5 className=""> Alamat Pengambilan </h5>
                <h4 className="fw-bold"> {data.mst_address_pick_up.name} </h4>
                <h5 className=""> {data.mst_address_pick_up.complete_address} </h5>
            </div>
            <div className="mt-4">
                <h5 className=""> Alamat Pengiriman </h5>
                <h4 className=" fw-bold"> {data.mst_address_delivery.name} </h4>
                <h5 className=""> {data.mst_address_delivery.complete_address} </h5>
            </div>
        </>
    )
  };

  /*======================== UseEffect ======================== */

  /*======================== Return ======================== */

  return (
    <Modal
      contentClassName="rounded-card p-2"
      show={isOpen}
      onHide={handleOnClose}
      className="p-3"
      size="md"
      centered
    >
      <div className="d-flex p-2 border-bottom">
        <h2 className="fw-bold">Detail Rute</h2>
        <MdClose className="ms-auto me-2 pointer" onClick={handleOnClose} />
      </div>
      <Modal.Body>{renderData()}</Modal.Body>
    </Modal>
  );
};

export default Rute;
