import { BaseApiRequest } from "./base-api";
import { OpResult } from "../helper/operation_result";

export default class PaymentApi extends BaseApiRequest {
  constructor() {
    super(process.env.REACT_APP_API_END_POINT);
  }

  async getPaymentList() {
    let result = OpResult.failed("Failed getPaymentList");
    try {
      let resApi = await this.get("/payment/list");
      if (resApi.status) {
        result = resApi;
      }
    } catch (e) {
      result = OpResult.failed("Internal Error");
    }
    return result;
  }
}
