// mostly used for mobile responsive view

export const openSidenavMobile = () => {
  const el = document.querySelector(".mobile#sidebar");
  const overlay = document.querySelector(".sidebar-overlay ");
  el?.classList?.remove("hidden");
  overlay?.classList?.remove("hidden");
};

export const hideSidenavMobile = () => {
  const el = document.querySelector(".mobile#sidebar");
  const overlay = document.querySelector(".sidebar-overlay ");
  el?.classList?.add("hidden");
  overlay?.classList?.add("hidden");
};

export const openMap = () => {
  const card = document.querySelector(".card-mobile");
  const btnBack = document.querySelector(".btn-back-map");
  const headMenu = document.querySelector(".head-menu");
  card.style.zIndex = 0;
  btnBack.style.zIndex = 1;
  headMenu.style.visibility = "hidden";
};

export const closeMap = () => {
  const card = document.querySelector(".card-mobile");
  const btnBack = document.querySelector(".btn-back-map");
  const headMenu = document.querySelector(".head-menu");
  card.style.zIndex = 1;
  btnBack.style.zIndex = 0;
  headMenu.style.visibility = "visible";
};

export const hideMapButtonBack = () => {
  const btn = document.querySelector(".btn-back-map");
  btn.classList.add("dis-none");
};

export const showMapButtonBack = () => {
  const btn = document.querySelector(".btn-back-map");
  btn.classList.remove("dis-none");
};

// without overlay
export const openSidenavMobileDashboard = () => {
  const el = document.querySelector(".mobile#sidebar");
  el?.classList?.remove("hidden");
};

export const changeTextContent = (qSelector, toChangeText) => {
  let text = document.querySelector(qSelector);
  text.textContent = toChangeText;
};

export const toggleMarginTopNav = () => {
  let el = document.querySelector(".mt-18rem");
  el.classList.toggle("mt-25rem");
};
