import { result } from "lodash";
import { geocodeByAddress, geocodeByPlaceId, getLatLng } from "react-places-autocomplete";

export function getCurrentLocation() {
  return (dispatch) => {
    navigator.geolocation.getCurrentPosition(function (position) {
      dispatch(
        setMap({
          center: {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          },
        })
      );
    });
  };
}

export function getMapSearch(payload) {
  let data = null;
  return async (dispatch) => {
    // await geocodeByAddress(payload) //before
    await geocodeByPlaceId(payload)
      .then((results) => {
        console.log("results", results);
        data = results[0].address_components;
        return getLatLng(results[results.length - 1]);
      })
      .then(({ lat, lng }) => {
        console.log(lat, lng);
        dispatch(
          setMap({
            center: { lat, lng },
            address: payload,
            data,
          })
        );
      });
  };
}

export const setMap = (objValue) => ({
  type: "SET_MARKER",
  payload: objValue,
});

export const resetMap = (objValue) => ({
  type: "RESET_MAP",
  payload: objValue,
});
