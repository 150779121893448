import React from "react";
import { Button } from "react-bootstrap";
import { Result } from "antd";
const NotFound = () => {
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      style={{ marginTop: "12vh" }}
    />
  );
};

export default NotFound;
