import React, { Component } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { Checkbox, message } from "antd";
import PickFleet from "../../components/modalFleet/PickFleet";
import PickHelper from "../../components/modalPartner/PickHelper";
import InfoModalNormal from "../../components/modalInfo/infoNormal";
import { connect } from "react-redux";
import PickDriver from "../../components/modalPartner/PickDriver";
import util from "../../helper/util";

import {
  updatePickupDetail,
  findTotalPrice,
} from "../../redux/action/sewakendaraan_action";
import OrderRentApi from "../../api/order-rent-api";

class TabService extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    this.updateState();
  }

  async componentDidUpdate(prevProps, prevStates) {
    if (prevProps.selectedVType.id !== this.props.selectedVType.id) {
      await this.getPartnerService();
    }
    if (
      prevProps.selectedPartnerService.id !==
      this.props.selectedPartnerService.id
    ) {
      this.updateState();
    }
  }

  updateState() {
    if (this.props.selectedPartnerService.id) {
      let total_price = this.handleInitialPrice(
        this.props.sewaKendaraanReducer.periodType,
        this.props.selectedPartnerService.estimated_price,
        "lo"
      );
      this.props.dispatch(
        updatePickupDetail({
          detailOrder: {
            ...this.props.detailOrder,
            driver_availability_rent:
              this.props.selectedPartnerService?.mst_partner_service_pricing
                ?.driver_availability_rent,
            helper_availability_send:
              this.props.selectedPartnerService?.mst_partner_service_pricing
                ?.helper_availability_send,
            helper_availability_rent:
              this.props.selectedPartnerService?.mst_partner_service_pricing
                ?.helper_availability_rent,
            driver_price_rent:
              this.props.selectedPartnerService?.mst_partner_service_pricing
                ?.driver_price_rent,
            helper_price_rent_send:
              this.props.selectedPartnerService?.mst_partner_service_pricing
                ?.helper_price_rent_send,
            helper_price_rent:
              this.props.selectedPartnerService?.mst_partner_service_pricing
                ?.helper_price_rent,
            total_price:
              this.props.detailOrder.total_price === 0
                ? total_price
                : this.props.detailOrder.total_price,
          },
        })
      );
    } else {
      this.props.dispatch(
        updatePickupDetail({ detailOrder: { ...this.props.detailOrder } })
      );
    }
  }
  handleInitialPrice = (periodType, estimated_price, type) => {
    let value = 0;
    try {
      switch (periodType) {
        case "DAILY":
          value =
            type === "lo" ? estimated_price.lo_daily : estimated_price.hi_daily;
          break;
        case "WEEKLY":
          value =
            type === "lo"
              ? estimated_price.lo_weekly
              : estimated_price.hi_weekly;
          break;
        case "MONTHLY":
          value =
            type === "lo"
              ? estimated_price.lo_monthly
              : estimated_price.hi_monthly;
          break;
        default:
          value = 0;
          break;
      }
      return value;
    } catch (e) {
      return null;
    }
  };
  getPartnerService = async () => {
    this.props.dispatch(
      updatePickupDetail({
        partnerService: { ...this.props.partnerService, isLoading: true },
      })
    );
    const orderRentApi = new OrderRentApi();
    let order_province = "";
    let order_regency = "";
    let order_district = "";
    let order_village = "";

    for (const iterator of this.props.sewaKendaraanReducer.detailMap) {
      if (iterator && iterator.types && iterator.types.length > 0) {
        switch (iterator.types[0]) {
          case "administrative_area_level_1":
            order_province = iterator.long_name;
            break;
          case "administrative_area_level_2":
            order_regency = iterator.long_name;
            break;
          case "administrative_area_level_3":
            order_district = iterator.long_name;
            break;
          case "administrative_area_level_4":
            order_village = iterator.long_name;
            break;

          default:
            break;
        }
      }
    }
    let data = {
      pickup_time: this.props.sewaKendaraanReducer.startDate.toISOString(),
      return_time: this.props.sewaKendaraanReducer.endDate.toISOString(),
      order_category: this.props.sewaKendaraanReducer.tipe,
      order_province,
      order_regency,
      order_district,
      order_village,
      order_detail_address: this.props.sewaKendaraanReducer.lokasi,
      receiver_name: this.props.sewaKendaraanReducer.namaPenerima,
      receiver_phone_no: `0${this.props.sewaKendaraanReducer.nomorTelepon}`,
      order_address_note: this.props.sewaKendaraanReducer.catatan || "-",
      is_address_saved: "-",
      vehicle_subtype_id: this.props.selectedVType.id,
      order_location_longitude: this.props.sewaKendaraanReducer.lng,
      order_location_latitude: this.props.sewaKendaraanReducer.lat,
    };

    let officialPartner = { data: [] };
    if (this.props.auth.access_token && this.props.auth.is_special_customer) {
      data["customer_id"] = this.props.auth.id;
    }

    const result = await orderRentApi.getPartnerService(data);
    if (result.status === "success" && result.data) {
      let detailPickup = {
        partnerService: { data: result.data, isLoading: false },
        selectedPartnerService: {},
        totalPrice: {
          rent_day: 0,
          vehicle_price: 0,
          driver_price: 0,
          helper_price: 0,
          total_price: 0,
          final_price: 0,
        },
        detailOrder: {
          driver_availability_rent: 0,
          helper_availability_send: 0,
          helper_availability_rent: 0,
          driver_price_rent: 0,
          helper_price_rent_send: 0,
          helper_price_rent: 0,
          driver_availability_rent_selected: false,
          helper_availability_send_selected: false,
          helper_availability_rent_selected: false,
          total_helper_requested: 1,
          total_price: 0,
        },
      };

      //if customer special, update the oficial partner service
      if (this.props.auth.access_token && this.props.auth.is_special_customer) {
        officialPartner = await orderRentApi.getPartnerServiceOfficial(
          data,
          this.props.auth.access_token
        );
        detailPickup["officialPartnerService"] = {
          data: officialPartner.data,
          isLoading: false,
        };
      }
      this.props.dispatch(updatePickupDetail({ ...detailPickup }));
    } else {
      this.props.dispatch(
        updatePickupDetail({ partnerService: { data: [], isLoading: false } })
      );
    }
  };

  render() {
    return (
      <>
        <Card.Body className="pt-0 scrollable">
          <h5 className="fw-light mb-2">Pilih Jenis Kendaraan</h5>
          <div className="rc-ice p-2 mb-2">
            <PickFleet />
          </div>
          <h5 className="fw-light mt-3 mb-2">
            Pilih Partner Penyedia Kendaraan
          </h5>
          <div className="rc-ice p-2 mb-2">
            <PickDriver />
          </div>
          <h5 className="fw-light mt-3 mb-2">
            Pilih Layanan Tambahan <InfoModalNormal />
          </h5>
          <div className="rc-ice p-2 mb-2">
            <Card className="border-0 mb-1">
              <Row className="card-body py-2">
                <Col xs={1} className="align-self-center text-center">
                  <Checkbox
                    disabled={
                      !this.props.selectedPartnerService
                        ?.mst_partner_service_pricing
                        ? true
                        : this.props.detailOrder.driver_availability_rent === 0
                        ? true
                        : false
                    }
                    checked={
                      this.props.detailOrder.driver_availability_rent_selected
                    }
                    onClick={() => {
                      let totalPricePayload = {
                        vehicle_subtype_id: this.props.selectedVType.id,
                        partner_service_id:
                          this.props.selectedPartnerService.id,
                        pickup_time:
                          this.props.sewaKendaraanReducer.startDate.toISOString(),
                        return_time:
                          this.props.sewaKendaraanReducer.endDate.toISOString(),
                        driver: !this.props.detailOrder
                          .driver_availability_rent_selected
                          ? 1
                          : 0,
                        // driver_helper: this.props.detailOrder
                        // .helper_availability_send ? 1 : 0,
                        helper: this.props.detailOrder.helper_availability_rent
                          ? 1
                          : 0,
                      };
                      if (this.props.isSpecialPartnerSelected) {
                        totalPricePayload["customer_id"] = this.props.auth.id;
                      }
                      this.props.dispatch(findTotalPrice(totalPricePayload));

                      let total_price = this.props.detailOrder.total_price;
                      if (
                        !this.props.detailOrder
                          .driver_availability_rent_selected
                      ) {
                        total_price =
                          total_price +
                          (this.props.detailOrder.driver_price_rent || 0);
                      } else {
                        total_price =
                          total_price -
                          (this.props.detailOrder.driver_price_rent || 0);
                      }
                      this.props.dispatch(
                        updatePickupDetail({
                          detailOrder: {
                            ...this.props.detailOrder,
                            driver_availability_rent_selected:
                              !this.props.detailOrder
                                .driver_availability_rent_selected,
                            helper_availability_send_selected:
                              !this.props.detailOrder
                                .driver_availability_rent_selected,
                            total_price,
                          },
                        })
                      );
                    }}
                  />
                </Col>
                <Col xs={7} className="align-self-center">
                  <p className="fw-bold mb-0">Layanan Supir</p>
                </Col>
                <Col xs={4} className="align-self-center text-end">
                  <p className="mb-0 fw-bold">
                    Rp{" "}
                    {(this.props.detailOrder.driver_price_rent || 0)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                    <span className="fw-light">/hari</span>
                  </p>
                </Col>
              </Row>
            </Card>
            <Card className="border-0 mb-1">
              <Row className="card-body py-2">
                <Col xs={1} className="align-self-center text-center">
                  <Checkbox
                    disabled={
                      !this.props.selectedPartnerService
                        ?.mst_partner_service_pricing
                        ? true
                        : this.props.detailOrder.helper_availability_send === 0
                        ? true
                        : false
                    }
                    checked={
                      this.props.detailOrder.helper_availability_send_selected
                    }
                    onClick={() => {
                      const totalPricePayload = {
                        vehicle_subtype_id: this.props.selectedVType.id,
                        partner_service_id:
                          this.props.selectedPartnerService.id,
                        pickup_time:
                          this.props.sewaKendaraanReducer.startDate.toISOString(),
                        return_time:
                          this.props.sewaKendaraanReducer.endDate.toISOString(),
                        driver: this.props.detailOrder
                          .driver_availability_rent_selected
                          ? 1
                          : 0,
                        // driver_helper: !this.props.detailOrder
                        // .helper_availability_send_selected ? 1 : 0,
                        helper: this.props.detailOrder.helper_availability_rent
                          ? 1
                          : 0,
                      };
                      if (this.props.isSpecialPartnerSelected) {
                        totalPricePayload["customer_id"] = this.props.auth.id;
                      }
                      this.props.dispatch(findTotalPrice(totalPricePayload));
                      let total_price = this.props.detailOrder.total_price;
                      if (
                        !this.props.detailOrder
                          .helper_availability_send_selected
                      ) {
                        total_price =
                          total_price +
                          (this.props.detailOrder.helper_price_rent_send || 0);
                      } else {
                        total_price =
                          total_price -
                          (this.props.detailOrder.helper_price_rent_send || 0);
                      }
                      this.props.dispatch(
                        updatePickupDetail({
                          detailOrder: {
                            ...this.props.detailOrder,
                            helper_availability_send_selected:
                              !this.props.detailOrder
                                .helper_availability_send_selected,
                            total_price,
                          },
                        })
                      );
                    }}
                  />
                </Col>
                <Col xs={7} className="align-self-center">
                  <p className="fw-bold mb-0">Bantuan Supir</p>
                  <h6 className="mb-0 text-muted">
                    (Membantu bongkar muatan maks 20m)
                  </h6>
                </Col>
                <Col xs={4} className="align-self-center text-end">
                  <p className="mb-0 fw-bold">
                    Rp{" "}
                    {(this.props.detailOrder.helper_price_rent_send || 0)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                    <span className="fw-light">/hari</span>
                  </p>
                </Col>
              </Row>
            </Card>
            <PickHelper
              disabled={
                !this.props.selectedPartnerService?.mst_partner_service_pricing
                  ? true
                  : false
              }
              // disabled={() => {
              //   if (
              //     !this.props.selectedPartnerService.mst_partner_service_pricing
              //   )
              //     return true;
              //   // return this.props.detailOrder.helper_availability_send === 0;
              // }}
              helper_availability_rent={
                this.props.detailOrder.helper_availability_rent
              }
              helper_availability_rent_selected={
                this.props.detailOrder.helper_availability_rent_selected
              }
              helper_price_rent={this.props.detailOrder.helper_price_rent}
              total_helper_requested={
                this.props.detailOrder.total_helper_requested
              }
              handleChecklist={() => {
                let total_price = this.props.detailOrder.total_price;
                let total_helper_requested =
                  this.props.detailOrder.total_helper_requested;
                if (!this.props.detailOrder.helper_availability_rent_selected) {
                  total_price =
                    total_price +
                    this.props.detailOrder.helper_price_rent *
                      total_helper_requested;
                } else {
                  total_price =
                    total_price -
                    this.props.detailOrder.helper_price_rent *
                      total_helper_requested;
                  total_helper_requested = 1;
                }
                const totalPricePayload = {
                  vehicle_subtype_id: this.props.selectedVType.id,
                  partner_service_id: this.props.selectedPartnerService.id,
                  pickup_time:
                    this.props.sewaKendaraanReducer.startDate.toISOString(),
                  return_time:
                    this.props.sewaKendaraanReducer.endDate.toISOString(),
                  driver: this.props.detailOrder
                    .driver_availability_rent_selected
                    ? 1
                    : 0,
                  // driver_helper: this.props.detailOrder
                  // .helper_availability_send_selected ? 1 : 0,
                  helper: !this.props.detailOrder
                    .helper_availability_rent_selected
                    ? total_helper_requested
                    : 0,
                };
                if (this.props.isSpecialPartnerSelected) {
                  totalPricePayload["customer_id"] = this.props.auth.id;
                }
                this.props.dispatch(findTotalPrice(totalPricePayload));

                this.props.dispatch(
                  updatePickupDetail({
                    detailOrder: {
                      ...this.props.detailOrder,
                      helper_availability_rent_selected:
                        !this.props.detailOrder
                          .helper_availability_rent_selected,
                      total_price,
                      total_helper_requested,
                    },
                  })
                );
              }}
              handlePrice={(
                total_helper_requested,
                previous_total_helper_requested
              ) => {
                let total_price = this.props.detailOrder.total_price;
                total_price =
                  total_price +
                  this.props.detailOrder.helper_price_rent *
                    total_helper_requested -
                  this.props.detailOrder.helper_price_rent *
                    (previous_total_helper_requested === 1
                      ? 0
                      : previous_total_helper_requested);

                const totalPricePayload = {
                  vehicle_subtype_id: this.props.selectedVType.id,
                  partner_service_id: this.props.selectedPartnerService.id,
                  pickup_time:
                    this.props.sewaKendaraanReducer.startDate.toISOString(),
                  return_time:
                    this.props.sewaKendaraanReducer.endDate.toISOString(),
                  driver: this.props.detailOrder
                    .driver_availability_rent_selected
                    ? 1
                    : 0,
                  // driver_helper: this.props.detailOrder
                  // .helper_availability_send_selected ? 1 : 0,
                  helper: this.props.detailOrder
                    .helper_availability_rent_selected
                    ? total_helper_requested
                    : 0,
                };
                if (this.props.isSpecialPartnerSelected) {
                  totalPricePayload["customer_id"] = this.props.auth.id;
                }
                this.props.dispatch(findTotalPrice(totalPricePayload));

                this.props.dispatch(
                  updatePickupDetail({
                    detailOrder: {
                      ...this.props.detailOrder,
                      helper_availability_rent_selected: true,
                      total_price,
                      total_helper_requested,
                    },
                  })
                );
              }}
            />
          </div>
        </Card.Body>
        <Card.Footer className="bg-0 shadow-top border-top-0">
          <div className="px-1 mt-3 d-flex align-items-center justify-content-between">
            <h4>Harga</h4>
            <h4 className="fw-bold">
              Rp{" "}
              {util.thousandSeparatorPure(
                Math.ceil(this.props.totalPrice.final_price)
              ) || "0"}
            </h4>
          </div>
          {/* <Row className="px-1 mt-2">
            <Col md={6}>
              <h4>Harga</h4>
            </Col>
            <Col md={6} className="text-end">
              <h4 className="fw-bold">
                Rp{" "}
                {parseInt(this.props.totalPrice.final_price || 0)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
              </h4>
            </Col>
          </Row> */}
        </Card.Footer>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  map: state.map,
  vType: state.sewaKendaraanReducer.vType,
  selectedVType: state.sewaKendaraanReducer.selectedVType,
  selectedPartnerService: state.sewaKendaraanReducer.selectedPartnerService,
  partnerService: state.sewaKendaraanReducer.partnerService,
  sewaKendaraanReducer: state.sewaKendaraanReducer,
  detailOrder: state.sewaKendaraanReducer.detailOrder,
  totalPrice: state.sewaKendaraanReducer.totalPrice,
  isSpecialPartnerSelected: state.sewaKendaraanReducer.isSpecialPartnerSelected,
  auth: state.authReducer,
});
export default connect(mapStateToProps)(TabService);
