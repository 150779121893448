import moment from "moment/moment";
import React, { useState } from "react";
import {
  Modal,
  Col,
  Row,
  useAccordionButton,
  Accordion
} from "react-bootstrap";
import { ReactComponent as ICWaitPayment } from '../../../assets/images/payment_done_send.svg';
import Countdown, { zeroPad } from "react-countdown";
import payment_helper from "../../../helper/payment_helper";

const WaitPayment = (props) => {
  const {show, onHide, data} = props

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return (
        <span className="text-muted fw-medium p">Sesi Waktu Habis!</span>
      );
    } else {
      // Render a countdown
      return (
        <span className="lipstick fw-bold mb-0 h2">
          {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
        </span>
      );
    }
  };

  const handleContent = (payment_method_id) => {
    let component = [];
    const result = [...payment_helper.getContent(payment_method_id)];
    let id = 0;
    for (const iterator of result) {
      let content = [];
      [...iterator.step].map((x, index) => {
        content.push(<li key={index}>{x}</li>);
      });
      component.push(
        <Accordion.Item eventKey={id}>
          <Accordion.Header>
            <p className="mb-0 fw-medium">{iterator.type}</p>
          </Accordion.Header>
          <Accordion.Body>
            <ul className="mb-0 text-muted h6">{content}</ul>
          </Accordion.Body>
        </Accordion.Item>
      );
      id++;
    }
    if (result.length === 0) {
      component.push(<div></div>);
      return component;
    } else {
      return <Accordion defaultActiveKey="0">{component}</Accordion>;
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => onHide(!show)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='rounded-pill'
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <div className="d-flex 
          flex-column 
          justify-content-center 
          align-items-center 
          p-4"
        >
          <ICWaitPayment className="mb-3" />
          <h3 className="font-weight-bold">
            Selesaikan Pembayaranmu
          </h3>
          <p className="text-center text-muted mb-4">
            Silahkan selesaikan pembayaran anda dengan
            melakukan pembayaran ke rekening eLOG
            yang tertera di bawah
          </p>
          <p className="mb-1">Batas Akhir Pembayaran</p>
          <p>{data ? moment(data.payment_expired_time).format('dddd, DD MMM YYYY HH:mm:ss') : ''}</p>

          <div className="rc-ice p-3">
            <Countdown
              renderer={renderer}
              date={data.payment_expired_time}
            />  
          </div>

        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex flex-column">
        <div className="px-2 w-100">
          <Row>
            <Col xs={5}>
              <p>Metode Pembayaran</p>
            </Col>
            <Col xs={7}>
              <p><b>: {data.trx_payment ? data.trx_payment.payment_method_name : '-'}</b></p>
            </Col>
            
            {
              data.trx_payment && data.trx_payment.account_name && (
                <>
                  <Col xs={5}>
                    <p>Nama Penerima</p>
                  </Col>
                  <Col xs={7}>
                    <p><b>: {data.trx_payment ? data.trx_payment.account_name : '-'}</b></p>
                  </Col>
                </>
              )
            }

            {
              data.trx_payment && data.trx_payment.va_number && (
                <>
                  <Col xs={5}>
                    <p>Nomor Rekening</p>
                  </Col>
                  <Col xs={7}>
                    <p><b>: {data.trx_payment ? data.trx_payment.va_number : '-'}</b></p>
                  </Col>
                </>
              )
            }

            {
              data.trx_payment && data.trx_payment.payment_code && (
                <>
                  <Col xs={5}>
                    <p>Kode Pembayaran</p>
                  </Col>
                  <Col xs={7}>
                    <p><b>: {data.trx_payment ? data.trx_payment.payment_code : '-'}</b></p>
                  </Col>
                </>
              )
            }

            {
              data.trx_payment && 
              (data.trx_payment.deeplink || data.trx_payment.desktop_url) && (
                <>
                  <Col xs={5}>
                    <p>Link Pembayaran</p>
                  </Col>
                  <Col xs={7}>
                    <a
                      href={
                        data.trx_payment.deeplink ||
                        data.trx_payment.desktop_url
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      <p className="fw-bold mb-0">: Klik Disini</p>
                    </a>
                  </Col>
                </>
              )
            }

            {
              data.trx_payment && 
              (data.trx_payment.qr_code && !data.trx_payment.deeplink && !data.trx_payment.desktop_url) && (
                <Col xs={12}>
                  <p className="mb-0" align="center"><b>Kode QR</b></p>
                  <p align="center">
                    <img
                      src={data.trx_payment.qr_code}
                      alt={data.trx_payment.qr_code}
                      width="50%"
                    />
                  </p>
                </Col>
              )
            }
            
          </Row>
        </div>

        <div className="w-100 px-2">
          <Accordion defaultActiveKey="0">
            <Accordion.Item>
              <Accordion.Header>
              <p className="mb-0 fw-medium">Lihat Panduan Pembayaran</p>
              </Accordion.Header>
              <Accordion.Body>
                <div className="w-100">
                  {[
                    ...payment_helper.getContent(
                      data?.trx_payment?.payment_method_id
                    ),
                  ].length > 0
                    ? handleContent(data?.trx_payment?.payment_method_id)
                    : null}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>

      </Modal.Footer>
    </Modal>
  )
}

function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey, () =>
    console.log('totally custom!', eventKey),
  );

  return (
    <button
      type="button"
      className="accordion-header"
      style={{ border: 'none' }}
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
}

export default WaitPayment;