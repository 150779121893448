import { Col, Row, Badge } from "react-bootstrap";

const DokumenKontrakCard = (props) => {
  const { data } = props;

  return (
    <div className="bg-white rounded-card w-full pt-3 ps-4 pb-3" style={{border: '1px solid #E2E5E8'}}>
      <Row>
        <Col xs={7}>
          <div>
            <p className="text-muted label mb-0">Nama Dokumen</p>
            <p className="mb-2">{data.document_name}</p>
          </div>
        </Col>
        <Col xs={5}>
          {data.is_must_filled_by_partner && (
            <Badge pill bg="primary" className="float-end me-2 mt-2">
              <span style={{ fontSize: "10px" }}>Perlu diisi partner</span>
            </Badge>
          )}
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <div
            className="bg-white rounded document-card"
            style={{ borderTop: "1px solid #E2E5E8" }}
          >
            <div className="mt-2 w-100">
              <p className="text-muted mb-1"> File Dokumen</p>
              <a
                className="underline text-ellipsis"
                style={{
                  color: "#CE1771",
                  fontSize: "13px",
                  cursor: "pointer",
                  maxWidth: '65%'
                }}
                href={data.document_path}
                target="_blank"
                rel="noreferrer"
                // download={data.document_name}
              >
                {data.document_name}
              </a>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DokumenKontrakCard;
