import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Avatar } from "antd";
import useBreakpoint from "../../../components/responsiveUtils/useBreakpoint";
import {
  hideSidenavMobile,
  openSidenavMobile,
  toggleMarginTopNav,
} from "../../../components/layouts/layout_helper";
import { getPartnerProfile } from "../../../redux/action/Partner/partner_action";
import _ from "lodash";

// icons
import { MdChevronRight } from "react-icons/md";
// import { AiOutlineUser } from "react-icons/ai";
import { IoMenuOutline, IoChevronDown, IoChevronUp } from "react-icons/io5";

const SideNav = () => {
  const profile = useSelector(
    (st) => st.partnerManagementReducer.partnerProfile
  );
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { isMobile, isTablet, isDesktop } = useBreakpoint();
  const [toggleMenu, setToggleMenu] = useState(true);
  const [imageError, setImageError] = useState(false);

  const getActiveSideInfoMenu = (pathParams) => {
    if (window.location.pathname.includes(pathParams))
      return "side-info-menu-active";

    return null;
  };

  const getInital = (value) => {
    if (value) {
      const fullName = value.split(" ");
      const initials =
        fullName[0].charAt(0) +
        (fullName[1] ? fullName[1].charAt(0) : "") +
        (fullName[2] ? fullName[2].charAt(0) : "");
      return initials.toUpperCase();
    } else {
      return null;
    }
  };

  const getVehicleContract = () => {
    if (
      profile &&
      profile.transaction_delivery_partner_offer &&
      profile.transaction_delivery_partner_offer.length > 0
    ) {
      let total = 0;
      profile.transaction_delivery_partner_offer.forEach((item) => {
        if (item.vehicle_quantity) {
          total += item.vehicle_quantity;
        }
      });
      return total;
    } else {
      return 0;
    }
  };

  const handleImageError = () => {
    setImageError(true);
  };

  useEffect(() => {
    if (isMobile) hideSidenavMobile();
  }, [isMobile]);

  useEffect(() => {
    if (profile.id !== id) {
      dispatch(getPartnerProfile(id));
    }
  }, []);

  const headSection = () => {
    if (isDesktop)
      return (
        <>
          <div className="d-flex justify-content-center w-100 position-relative">
            {profile.profile_picture ? (
              imageError ? (
                <Avatar
                  size={300}
                  shape="square"
                  className="rounded-card avatar-blue"
                >
                  <span style={{ fontSize: "48px", fontWeight: "bold" }}>
                    {getInital(profile.name)}
                  </span>
                </Avatar>
              ) : (
                <Avatar
                  size={300}
                  shape="square"
                  className="rounded-card avatar-blue"
                  src={profile?.profile_picture}
                >
                  <img
                    src={profile?.profile_picture}
                    alt="avatar"
                    style={{ visibility: 'hidden' }}
                    onError={handleImageError}
                  />
                </Avatar>
              )
            ) : (
              <Avatar
                size={300}
                shape="square"
                className="rounded-card avatar-blue"
              >
                <span style={{ fontSize: "48px", fontWeight: "bold" }}>
                  {getInital(profile.name)}
                </span>
              </Avatar>
            )}
          </div>
          <div className="text-center w-100 mt-3">
            <h6 className="text-muted">Partner Official</h6>
            <h2 className="fw-bold">{profile.name}</h2>
            <p className="text" style={{ color: "#1EAF56" }}>
              {" "}
              {/* {getVehicleContract()} Kendaraan Diterima */}
              {profile?.vehicle_rented_count} Kendaraan Disewa
            </p>
          </div>
        </>
      );

    if (isTablet)
      return (
        <>
          <Row className="">
            <Col
              md={4}
              className="d-flex justify-content-center position-relative"
            >
              {profile.profile_picture ? (
                imageError ? (
                  <Avatar
                    size={150}
                    shape="square"
                    className="rounded-card avatar-blue"
                  >
                    <span style={{ fontSize: "48px", fontWeight: "bold" }}>
                      {getInital(profile.name)}
                    </span>
                  </Avatar>
                ) : (
                  <Avatar
                    size={150}
                    shape="square"
                    className="rounded-card avatar-blue"
                    src={profile?.profile_picture}
                  >
                    <img
                      src={profile?.profile_picture}
                      alt="avatar"
                      style={{ visibility: 'hidden' }}
                      onError={handleImageError}
                    />
                  </Avatar>
                )
              ) : (
                <Avatar
                  size={150}
                  shape="square"
                  className="rounded-card avatar-blue"
                >
                  <span style={{ fontSize: "48px", fontWeight: "bold" }}>
                    {getInital(profile.name)}
                  </span>
                </Avatar>
              )}
            </Col>
            <Col className="flex-column justify-content-end d-flex w-100 mt-3">
              <div className="w-100 mt-3">
                <h6 className="text-muted">Partner Official</h6>
                <h2 className="fw-bold">{profile.name}</h2>
                <p className="text" style={{ color: "#1EAF56" }}>
                  {" "}
                  {/* {getVehicleContract()} Kendaraan Diterima */}
                  {profile?.vehicle_rented_count} Kendaraan Disewa
                </p>
              </div>
            </Col>
          </Row>
        </>
      );

    if (isMobile)
      return (
        <>
          <div className="d-flex mt-4 ms-2">
            <IoMenuOutline
              onClick={openSidenavMobile}
              className="text-secondary pointer me-2"
              style={{ fontSize: 25 }}
            />

            <h4 className="mt-1">Partner Official</h4>
          </div>
          <Row className="mt-2">
            <Col
              xs={5}
              className="d-flex justify-content-center position-relative"
            >
              {profile.profile_picture ? (
                imageError ? (
                  <Avatar
                    size={100}
                    shape="square"
                    className="rounded-card avatar-blue"
                  >
                    <span style={{ fontSize: "48px", fontWeight: "bold" }}>
                      {getInital(profile.name)}
                    </span>
                  </Avatar>
                ) : (
                  <Avatar
                    size={100}
                    shape="square"
                    className="rounded-card avatar-blue"
                    src={profile?.profile_picture}
                  >
                    <img
                      src={profile?.profile_picture}
                      alt="avatar"
                      style={{ visibility: 'hidden' }}
                      onError={handleImageError}
                    />
                  </Avatar>
                )
              ) : (
                <Avatar
                  size={100}
                  shape="square"
                  className="rounded-card avatar-blue"
                >
                  <span style={{ fontSize: "48px", fontWeight: "bold" }}>
                    {getInital(profile.name)}
                  </span>
                </Avatar>
              )}
            </Col>
            <Col className="flex-column justify-content-end d-flex w-100 mt-3">
              <div className="w-100 mt-3">
                <h6 className="text-muted">Partner Official</h6>
                <h2 className="fw-bold">{profile.name}</h2>
                <p className="text" style={{ color: "#1EAF56" }}>
                  {" "}
                  {/* {getVehicleContract()} Kendaraan Diterima */}
                  {profile?.vehicle_rented_count} Kendaraan Disewa
                </p>
              </div>
            </Col>
          </Row>
        </>
      );
  };

  return (
    <div
      className={`${isMobile && "head-nav-settings-mobile"
        } rounded-card bg-white p-2`}
    >
      {headSection()}
      <hr className="mt-2 text-muted" />
      <div hidden={isMobile ? toggleMenu : false}>
        <div
          className={`d-flex align-items-center pointer px-3 p-2 side-info-menu ${getActiveSideInfoMenu(
            "/partner/profile"
          )} `}
          onClick={() => history.push("/partner/profile/" + id)}
        >
          <h6 className="text-muted ">Profil</h6>
          <div
            className="d-flex ms-auto align-items-center "
            style={{ marginTop: "-10px" }}
          >
            <MdChevronRight className="" />
          </div>
        </div>
        <div
          className={`d-flex align-items-center pointer px-3 p-2 side-info-menu ${getActiveSideInfoMenu(
            "/partner/kendaraan"
          )}`}
          onClick={() => history.push("/partner/kendaraan/" + id)}
        >
          <h6 className="text-muted ">Kendaraan Partner</h6>
          <div
            className="d-flex ms-auto align-items-center "
            style={{ marginTop: "-10px" }}
          >
            <MdChevronRight className="" />
          </div>
        </div>
        <div
          className={`d-flex align-items-center pointer px-3 p-2 side-info-menu ${getActiveSideInfoMenu(
            "/partner/penawaran"
          )}`}
          onClick={() => history.push("/partner/penawaran/" + id)}
        >
          <h6 className="text-muted ">Daftar Tawaran</h6>
          <div
            className="d-flex ms-auto align-items-center "
            style={{ marginTop: "-10px" }}
          >
            <MdChevronRight className="" />
          </div>
        </div>
      </div>
      <div
        hidden={!isMobile}
        className=""
        onClick={() => {
          setToggleMenu(!toggleMenu);
          toggleMarginTopNav();
        }}
      >
        <h4 className="text-primary  text-center">
          Tampilkan Lebih {toggleMenu ? "Banyak" : "Sedikit"}{" "}
          <span>{toggleMenu ? <IoChevronDown /> : <IoChevronUp />}</span>
        </h4>
      </div>
    </div>
  );
};

export default SideNav;
