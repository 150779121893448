import moment from "moment";
import React from "react";
import { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FiPlusCircle, FiTrash2 } from "react-icons/fi";
import { Button, Checkbox } from "antd";

const PreviewDokumenCard = (props) => {
  const { item } = props;

  return (
    <div className="bg-white rounded-card w-full pt-3 ps-4 pb-3">
      <Row>
        <Col xs={7}>
          <div>
            <p className="text-muted label mb-0">Nama Dokumen</p>
            <Form.Control
              disabled
              className="border-0 ps-0 pt-1"
              placeholder="Tulis nama dokumen"
              value={item.document_name}
            ></Form.Control>
          </div>
        </Col>
        {item.is_must_filled_by_partner && (
          <Col xs={5}>
            <span className="diisi-partner rounded-pill py-1 px-3">
              Perlu diisi partner
            </span>
          </Col>
        )}
      </Row>
      <Row>
        <Col xs={12}>
          <div
            className="bg-white rounded"
            style={{ borderTop: "1px solid #E2E5E8" }}
          >
            <div className="mt-2 w-100">
              <p className="text-muted mb-1"> File Dokumen</p>
              {item.document_path && (
                <a
                  className="underline text-ellipsis"
                  style={{
                    color: "#CE1771",
                    fontSize: "13px",
                    cursor: "pointer",
                    maxWidth: "65%",
                  }}
                  href={item.document_path}
                  target="_blank"
                  rel="noreferrer"
                >
                  {item.document_file_name}
                </a>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PreviewDokumenCard;
