import React, { useState } from "react";
import {
  Modal,
  Form,
  InputGroup,
  FloatingLabel,
  Button,
} from "react-bootstrap";
import { Upload, message } from "antd";
import { previewImage } from "../../helper/imgPreview";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { resetAuth, setAuth } from "../../redux/action/auth_action";
import ProfileApi from "../../api/profile-api";
import AccountApi from "../../api/auth-api";
import InfoBar from "../infoBar/InfoBar";
import SectionSuccess from "./SectionSuccess";

// icons
import icon_ktp from "../../assets/images/icon_ktp.svg";
import icon_camera_drag from "../../assets/images/icon_camera_drag.svg";
import icon_docs from "../../assets/images/icon-docs.svg";

import { MdChevronRight, MdClose } from "react-icons/md";

import { Checkbox } from "antd";
const Npwp = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const auth = useSelector((st) => st.authReducer);
  const [show, setShow] = useState(false);
  const [previewNpwp, setpreviewNpwp] = useState(null);
  const [body, setBody] = useState({
    npwp: "",
    npwp_uploaded_file: "",
  });

  const steps = [
    {
      id: 1,
      name: "NPWP",
      desc: "Masukkan Foto dan Nomor NPWP",
    },
  ];

  const [activeStep, setActiveStep] = useState({
    id: 1,
    name: "NPWP",
    desc: "Masukkan Foto dan Nomor NPWP",
  });

  const openModal = () => {
    setShow(true);
  };

  const closeModal = () => {
    setShow(false);
  };

  const handleNext = () => {
    setActiveStep(steps[1]);
  };

  const handleBack = () => {
    setActiveStep(steps[0]);
  };

  // drag n drop upload
  const { Dragger } = Upload;

  const props = {
    name: "file",
    multiple: false,
    maxCount: 1,
    accept: "image/*",
    beforeUpload() {
      return false;
    },
    onChange(info) {
      if (activeStep.id === 1) {
        setBody({ ...body, npwp_uploaded_file: info.file });
        setpreviewNpwp(previewImage(info.file));
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const getPreviewImage = () => {
    if (activeStep.id === 1) {
      return previewNpwp
        ? previewNpwp
        : auth?.npwp_uploaded_file
        ? auth?.npwp_uploaded_file
        : icon_camera_drag;
    }

    return null;
  };

  const getPreviewClass = () => {
    if (activeStep.id === 1) {
      return previewNpwp || auth?.npwp_uploaded_file ? "h-100 w-100" : "";
    }

    return null;
  };

  const handleSubmit = async () => {
    try {
      const accountApi = new AccountApi();
      const profileApi = new ProfileApi();

      const fd = new FormData();

      for (const key in body) {
        if (body[key]) {
          fd.append(key, body[key]);
        }
      }

      const result = await accountApi.checkToken(auth.refresh_token);
      if (result.status === "success") {
        const profileRes = await profileApi.updateNpwp(fd, auth.access_token);

        if (profileRes.status === "success") {
          const { data } = await accountApi.getDetailCustomer(
            auth.access_token
          );
          console.log(profileRes.message);
          message.success(profileRes.message);
          dispatch(setAuth({ ...auth, ...data }));

          // setIsSubmit(true);
          closeModal();
        } else {
          closeModal();
          // message.error(profileRes.message);
        }
      } else {
        history.push("/login");
        dispatch(resetAuth());
      }
    } catch (error) {
      console.log(error.response);
    }
  };

  const getNpwpDisplay = () => {
    if (!auth.npwp_uploaded_file) {
      return <h5>Data NPWP</h5>;
    } else {
      return (
        <>
          <div className="d-flex align-items-center mt-3 border-bottom-1 gap-2">
            <img
              src={auth.npwp_uploaded_file}
              width={150}
              height={75}
              alt=""
              className="img-fluid mb-2"
            />
          </div>
          <div className="">
            <p>Nomor NPWP </p>
            <span className="text-end fw-bold">{auth?.npwp}</span>
          </div>
        </>
      );
    }
  };

  return (
    <>
      <div
        className="bg-white rounded-card w-full p-3 pointer mt-2"
        onClick={openModal}
      >
        <div className="d-flex align-items-center   px-2">
          <img src={icon_ktp} width={25} height={25} alt="" />

          <div className="ms-3">
            <h6 className="text-muted ">Data NPWP</h6>
            {getNpwpDisplay()}
          </div>
          <div
            className="d-flex ms-auto align-items-center"
            style={{ marginTop: "-10px" }}
          >
            <MdChevronRight className="" />
          </div>
        </div>
      </div>
      <Modal
        contentClassName="rounded-card p-2"
        show={show}
        onHide={closeModal}
        className="p-3"
        centered
      >
        <div className="d-flex p-2">
          <p className="fw-bold">Data NPWP</p>
          <MdClose className="ms-auto me-2 pointer" onClick={closeModal} />
        </div>
        <Modal.Body>
          <h6>{activeStep.desc}</h6>
          <div className="">
            <Dragger
              showUploadList={false}
              {...props}
              className="p-4"
              style={{ backgroundColor: "white" }}
            >
              <img
                src={getPreviewImage()}
                alt=""
                className={getPreviewClass()}
              />
              <p className="text-primary mt-3">Browse File</p>
            </Dragger>
            <InputGroup className="border rounded-input mt-2">
              <InputGroup.Text
                className="border-0 rounded-input"
                style={{ backgroundColor: "#f8f9fa" }}
              >
                <img src={icon_docs} alt="" />
              </InputGroup.Text>
              <FloatingLabel
                className="rounded-input"
                label="Nomor NPWP"
                style={{ fontSize: "12px", width: "85%" }}
              >
                <Form.Control
                  type={"number"}
                  className="border-0 rounded-input"
                  placeholder="m"
                  onChange={(ev) => {
                    setBody({ ...body, npwp: ev.target.value });
                  }}
                  defaultValue={auth?.npwp}
                  style={{ height: "50px" }}
                ></Form.Control>
              </FloatingLabel>
            </InputGroup>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <p className="text-primary pointer me-4" onClick={closeModal}>
            Batalkan
          </p>
          <Button
            size="sm"
            variant="primary"
            className="float-end rounded-pill px-4 py-2"
            onClick={() => {
              handleSubmit();
            }}
          >
            Simpan
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Npwp;
