import { BaseApiRequest } from "../base-api";
import { message } from "antd";
import axios from "axios";
import qs from "query-string";
export default class AlamatApi extends BaseApiRequest {
  constructor() {
    super(process.env.REACT_APP_API_END_POINT);
  }

  async getAlamatList(token, params) {
    let result;
    try {
      const response = await axios.get(`${this.endpoint}/address`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });

      result = response.data;
    } catch (e) {
      // message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async getAlamatListSewa(token, params) {
    let result;
    try {
      const response = await axios.get(`${this.endpoint}/customer/pma/address`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });

      result = response.data;
    } catch (e) {
      // message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async getAlamatDetail(token, id) {
    let result;
    try {
      const response = await axios.get(`${this.endpoint}/address/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      result = response.data;
    } catch (e) {
      // message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async getAlamatDetailSewa(token, id) {
    let result;
    try {
      const response = await axios.get(`${this.endpoint}/customer/pma/address/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      result = response.data;
    } catch (e) {
      // message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async createAlamat(token, payload) {
    let result;
    try {
      const response = await axios.post(`${this.endpoint}/address`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (payload.address_type === "PICKING") {
        message.success("Alamat anda berhasil dibuat.");
      } else {
        message.success("Alamat pengiriman berhasil dibuat.");
      }
      // return response;
      result = response.data;
    } catch (e) {
      message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async updateAlamat(token, payload) {
    let result;
    const { id, ...others } = payload;
    try {
      const response = await axios.patch(
        `${this.endpoint}/address/${id}`,
        others,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      result = response.data;
      if (payload.address_type === "PICKING") {
        message.success("Alamat anda berhasil diedit.");
      } else {
        message.success("Alamat pengiriman berhasil diedit.");
      }
    } catch (e) {
      message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async updateAlamatSewa(token, payload) {
    let result;
    const { id, ...others } = payload;
    try {
      const response = await axios.put(
        `${this.endpoint}/customer/pma/address/${id}`,
        others,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      result = response.data;
      if (payload.address_type === "PICKING") {
        message.success("Alamat anda berhasil diedit.");
      } else {
        message.success("Alamat pengiriman berhasil diedit.");
      }
    } catch (e) {
      message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }
}
