import React from "react";
import { Modal } from "react-bootstrap";
import error from "../../assets/images/error.png";

// icons
import { MdClose } from "react-icons/md";

const ModalError = (props) => {
  const { isOpen, handleClose, errorMessage } = props;

  return (
    <>
      <Modal
        contentClassName="modal-100 p-3"
        show={isOpen}
        onHide={handleClose}
        className=""
        centered
      >
        <div>
          <div className="p-3 d-flex justify-content-end">
            <MdClose
              size={24}
              className="ms-auto me-2 pointer"
              onClick={handleClose}
            />
          </div>

          <Modal.Body className="text-center p-5">
            <img src={error} alt="error-img" />
            <h1 className="my-3">{errorMessage.title}</h1>
            <p style={{ fontSize: "14px" }}>{errorMessage.message}</p>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default ModalError;
