import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Modal,
  Form,
  Row,
  Col,
  InputGroup,
  FloatingLabel,
} from "react-bootstrap";
import { Checkbox, Divider } from "antd";
import useBreakpoint from "../../../../components/responsiveUtils/useBreakpoint";
import { FaSearch, FaTrashAlt } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import PropTypes from "prop-types";

const Partner = (props) => {
  /*======================== Hooks ======================== */

  const { isMobile } = useBreakpoint();

  /*======================== Props ======================== */

  const { isOpen, handleClose, handleSubmit, formValue, data } = props;

  /*======================== UseState ======================== */

  const [active, setActive] = useState([]);
  const [partner, setPartner] = useState([]);
  const [search, setSearch] = useState(undefined);
  const [checkAll, setCheckAll] = useState(false);

  /*======================== Handler ======================== */

  const handleActive = (value) => {
    let temp = active;
    if (temp && temp.length > 0) {
      let find = temp.find((item) => item.trx_id === value.trx_id);
      if (!find) {
        setActive([...active, value]);
      }
    } else {
      setActive([...active, value]);
    }
  };

  const handleDeleteActive = (value) => {
    let elementToRemove = value;
    setActive((prev) => [
      ...prev.filter((active) => active.trx_id !== elementToRemove.trx_id),
    ]);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleOnClose = () => {
    handleClose();
  };

  const handleOnSelect = () => {
    handleSubmit(active);
  };

  const onChangeSelectAll = (e) => {
    let selectall = [];
    if (e.target.checked) {
      data.forEach((item) => {
        selectall.push(item);
      });
    }

    setActive(selectall);
    setCheckAll(e.target.checked);
  };

  /*======================== UseEffect ======================== */

  useEffect(() => {
    if (search) {
      setPartner(
        data.filter((item) =>
          item.trx_name.toLowerCase().includes(search.toLowerCase())
        )
      );
    } else {
      setPartner(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (formValue.partners && formValue.partners.length > 0) {
      setActive(formValue.partners);
    } else {
      setActive([]);
    }
  }, [formValue]);

  useEffect(() => {
    setCheckAll(active.length === partner.length);
  }, [active]);

  /*======================== Return ======================== */

  return (
    <Modal
      contentClassName="rounded-card p-2"
      show={isOpen}
      onHide={handleOnClose}
      className="p-3"
      size="lg"
      centered
    >
      <div className="d-flex p-2 border-bottom">
        <h2 className="fw-bold">Pilih Partner Kontrak</h2>
        <MdClose className="ms-auto me-2 pointer" onClick={handleOnClose} />
      </div>
      <Modal.Body>
        <Row>
          <Col
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className={!isMobile ? "pe-3 border-end" : "mb-4"}
          >
            <InputGroup className="border rounded-10">
              <FloatingLabel
                className="rounded-10 w-75"
                label={"Cari kode / nama alamat anda"}
                style={{ width: "", fontSize: "12px", height: "50px" }}
              >
                <Form.Control
                  className="border-0 rounded-10"
                  type="text"
                  style={{ height: "50px" }}
                  placeholder="."
                  onChange={(event) => handleSearch(event)}
                />
              </FloatingLabel>
              <InputGroup.Text
                className="border-0 search-icon-trx rounded-10 ms-auto"
                style={{ height: "50px" }}
              >
                <FaSearch />
              </InputGroup.Text>
            </InputGroup>

            <Divider className="my-3" />
            <p className="fw-bold mb-2 width-45">Partner Tersedia</p>
            <div className="jenis-barang-opts mb-1 custom-scroll">
              {partner.map((item, index) => (
                <Card
                  key={index}
                  className={`rounded-10 pointer${
                    active.find((val) => val.trx_id === item.trx_id)
                      ? " active"
                      : ""
                  }`}
                  onClick={() => handleActive(item)}
                >
                  <Card.Body>{item.trx_name}</Card.Body>
                </Card>
              ))}
            </div>
          </Col>

          <Col
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className={!isMobile ? "ps-3 custom-scroll" : "mt-4 custom-scroll"}
          >
            <div className="jenis-barang-opts" style={{ maxHeight: "480px" }}>
              {active && active.length > 0 ? (
                active.map((item, index) => (
                  <InputGroup
                    className="rounded-10 pointer"
                    size="lg"
                    key={index}
                  >
                    <Form.Control
                      value={item.trx_name}
                      readOnly={true}
                      className="rounded-10"
                      style={{
                        fontSize: "16px",
                        backgroundColor: "#fff",
                        height: "50px",
                      }}
                    />
                    <InputGroup.Text onClick={() => handleDeleteActive(item)}>
                      <FaTrashAlt style={{ width: "16px", color: "#bbb" }} />
                    </InputGroup.Text>
                  </InputGroup>
                ))
              ) : (
                <Form.Control
                  value="Belum Memilih Partner"
                  readOnly={true}
                  className="rounded-10"
                  style={{
                    fontSize: "16px",
                    backgroundColor: "#fff",
                    height: "50px",
                  }}
                />
              )}
            </div>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: "space-between" }}>
        <div className="w-100">
          <Row>
            <Col lg={6} md={6} sm={6} xs={12}>
              <div className="w-100 mt-2 float-start">
                <Checkbox onChange={onChangeSelectAll} checked={checkAll}>
                  Pilih Semua
                </Checkbox>
              </div>
            </Col>
            <Col lg={6} md={6} sm={6} xs={12}>
              <Button
                size="sm"
                className="w-100 fw-bold rounded-pill text-capitalize mt-2"
                onClick={handleOnSelect}
              >
                Pilih Partner Kontrak ({active.length})
              </Button>
            </Col>
          </Row>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

Partner.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  formValue: PropTypes.object,
};

export default Partner;
