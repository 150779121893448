import { Row, Col, Button, Modal, Form } from "react-bootstrap";
import { BsFillTelephoneFill } from "react-icons/bs";
import { AiOutlineUser, AiFillPhone, AiOutlineFileText } from "react-icons/ai";

// redux
import { useSelector } from "react-redux";

const ClaimInsurance = ({ showClaimInsurance, closeClaimInsurance }) => {
  const trxDetail = useSelector((st) => st.trxHistoryReducer?.trxDetail);

  return (
    <Modal
      contentClassName="modal-80w p-1"
      show={showClaimInsurance}
      onHide={closeClaimInsurance}
      className=""
      centered
    >
      <Modal.Header closeButton className="bg-white">
        <strong>Klaim Asuransi</strong>
      </Modal.Header>
      <Row>
        <div className="d-flex align-items-center my-4">
          <AiOutlineFileText className="ms-4 " />
          <a
            href={trxDetail?.data?.trx_booking_send?.insurance_policy_file}
            target="_blank"
            rel="norefferer"
            hidden={!trxDetail?.data?.trx_booking_send?.insurance_policy_file}
            className="ms-2 fw-bold pointer text-dark"
            // style={{ fontSize: "11px" }}
          >
            Lihat Lampiran Klaim Asuransi
          </a>
        </div>
      </Row>
    </Modal>
  );
};

export default ClaimInsurance;
