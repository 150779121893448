import React from "react";

/*======================== Third Party ======================== */
import { Row, Col } from "react-bootstrap";
import moment from "moment";

/*======================== Utils ======================== */
import util from "../../../../helper/util";
import useBreakpoint from "../../../../components/responsiveUtils/useBreakpoint";


const CardContent = ({ eachData, setCollapsibleElement, splitAddres, getFleet }) => {
    const { isMobile, isDesktop } = useBreakpoint();
    
    /*======================== Return ======================== */


    return(
        <div ref={setCollapsibleElement}>
            <Row className="px-2">

                {/* SEND GOODS || Kirim Barang  */}
                {eachData?.trx_booking_send &&
                <>
                <Col lg={2} hidden={!isDesktop}>
                    <p style={{marginBottom:"3px"}}>Alamat Pickup:</p>
                    <p>{splitAddres(eachData, "PICKUP")}</p>
                </Col>
                <Col lg={2} hidden={!isDesktop}>
                    <p style={{marginBottom:"3px"}}>Alamat Dropoff:</p>
                    <p>{splitAddres(eachData, "DROPOFF")}</p>
                </Col>
                <Col md={3} xs={6} hidden={isDesktop} className={`${isMobile && 'mb-3' }`}>
                    <p style={{marginBottom:"3px"}}>Total Alamat:</p>
                    <p style={{marginBottom:"3px"}}>{eachData?.trx_booking_send?.trx_booking_send_destination.filter(e => e.destination_type === "PICKUP").length} Alamat Pickup</p>
                    <p className="m-0">{eachData?.trx_booking_send?.trx_booking_send_destination.filter(e => e.destination_type === "DROPOFF").length} Alamat Dropoff</p>
                    <p className="m-0" style={{color:"#0FAA4B"}}>{eachData?.trx_booking_send?.is_cod === 1 && "(COD)"}</p>
                </Col>
                <Col lg={2} md={3} xs={6} className={`${isMobile && 'mb-3' }`}>
                    <p style={{marginBottom:"3px"}}>Waktu Pickup:</p>
                    <p className="m-0">
                    {moment(eachData?.trx_booking_send?.order_date).format("DD MMM YYYY HH:mm")}
                    </p>
                </Col>
                <Col lg={2} md={3} hidden={!isDesktop}>
                    <p style={{marginBottom:"3px"}}>Pesanan COD:</p>
                    <div className="gap-2">
                    {eachData.trx_booking_send.is_cod === 0 ?
                    <p style={{color:"#BBBBBB"}}>Tidak aktif</p>
                    :
                    <>
                    <p className="m-0" style={{color:"#0FAA4B"}}>Aktif</p>
                    <p className="m-0">Rp. {util.thousandSeparatorPure(eachData?.trx_booking_send?.total_cod_amount)}</p>
                    </>
                    }
                    </div>
                </Col>
                </>
                }

                {/* RENT || SEWA KENDARAAN */}
                {eachData?.trx_booking_rent &&
                <>
                    <Col lg={4} md={3} xs={6}>
                    <p style={{marginBottom:"3px"}}>Alamat Pengambilan:</p>
                    <p>{`${eachData?.mst_driver?.name ? eachData?.mst_driver?.name.length > 7 ? eachData?.mst_driver?.name.substr(0,7) +" - " : eachData?.mst_driver?.name+" - " : "" } ${eachData?.trx_booking_rent?.pickup_address}`}</p>
                    </Col>
                    <Col lg={2} md={3} xs={6} hidden={!isDesktop} className={`${isMobile && 'mb-3' }`}>
                    <p style={{marginBottom:"3px"}}>Waktu Pengambilan:</p>
                    <p className="m-0">
                        {moment(eachData?.trx_booking_rent?.pickup_time).format("DD MMM YYYY HH:mm")}
                    </p>
                    </Col>
                    <Col lg={2} md={3} xs={6} hidden={!isDesktop} className={`${isMobile && 'mb-3' }`}>
                    <p style={{marginBottom:"3px"}}>Waktu Pengembalian:</p>
                    <p className="m-0">
                        {moment(eachData?.trx_booking_rent?.date_to_return).format("DD MMM YYYY HH:mm")}
                    </p>
                    </Col>
                    <Col lg={2} md={3} xs={6} hidden={isDesktop} className={`${isMobile && 'mb-3' }`}>
                    <p style={{marginBottom:"3px"}}>Waktu Pesanan:</p>
                    <p className="m-0">{moment(eachData?.trx_booking_rent?.pickup_time).format("DD MMM YYYY")} (Ambil)</p>
                    <p className="m-0">{moment(eachData?.trx_booking_rent?.date_to_return).format("DD MMM YYYY")} (Kembali)</p>
                    </Col>
                </>
                }

                {/* Partner & Armada && Total Transaksi outside condition send n rent */}
                {/* Partner & Armada */}
                <Col lg={2} md={3} xs={6}>
                <p style={{marginBottom:"3px"}}>Partner & Armada:</p>
                <p className="m-0">
                    {eachData?.mst_driver?.name} - {getFleet()}
                </p>
                <p className="m-0" style={{color:"#BBBBBB"}}>{eachData?.mst_partner_service?.mst_partner?.name}</p>
                </Col>

                {/* Total Transaksi */}
                <Col lg={2} md={3} xs={6}>
                <p style={{marginBottom:"3px"}}>Total Transaksi:</p>
                <p className="m-0">
                    Rp. {util.thousandSeparatorPure(eachData?.trx_payment?.amount)}
                </p>
                </Col>
                
            </Row>
            <hr style={{color:"#ADADAD", height:"1px"}}/>
        </div>
    );
}

export default CardContent;