import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Tabs,
  Tab,
  InputGroup,
  FloatingLabel,
  Form,
  Dropdown,
} from "react-bootstrap";
import useBreakpoint from "../../../components/responsiveUtils/useBreakpoint";
import { FaSearch } from "react-icons/fa";
import { IoMenuOutline } from "react-icons/io5";
import { openSidenavMobile } from "../../../components/layouts/layout_helper";
import Transaction from "./Transaction"
import Vehicle from "./Vehicle"

// import RentAlamatAnda from "./Rent/AlamatAnda"

import { useHistory, useLocation } from "react-router-dom";
import useDebounce from "../../../hooks/useDebounce";

const DocumentPayment = () => {
  /*======================== Hooks ======================== */

  const { isMobile } = useBreakpoint();
  const location = useLocation();
  const history = useHistory();

  /*======================== UseState ======================== */

  const [activeStatusFilter, setActiveStatusFilter] = useState("Semua");
  const [tab, setTab] = useState("tagihan transaksi");
  const [layanan, setLayanan] = useState(1);
  const [search, setSearch] = useState(undefined);
  const debounceSearch = useDebounce(search, 300);

  /*======================== Handler ======================== */

  const handleChange = (type, value) => {
    switch (type) {
      case "tab":
        setTab(value);
        break;
      case "layanan":
        setLayanan(parseInt(value));
        break;

      default:
        break;
    }
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  /*======================== UseEffect ======================== */

  useEffect(() => {
    if (location.state?.section) {
      setTab(location.state.section);
      if(location.state?.service){
        setLayanan(location.state?.service)
      }
      history.replace({ pathname: location.pathname, state: {} });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*======================== Others ======================== */

  const tabOptions = [
    {
      title: "Tagihan Transaksi",
      value: "tagihan transaksi",
    },
    // {
    //   title: "Tagihan Kendaraan",
    //   value: "tagihan kendaraan",
    // }
  ];

  /*======================== Return ======================== */

  return (
    <div
      style={{ minHeight: "100vh", width: "100%", padding: "30px" }}
      className="bg-ice height-100"
    >
      {!isMobile ? (
        <Card
          className="p-4 rounded-card border-0"
          style={{ backgroundColor: "white" }}
        >
          <h1>Management Penagihan</h1>
          <Row className="card-body">
            <Col>
              <Tabs
                // defaultActiveKey={tab}
                activeKey={tab}
                className="tabs-trx mb-2 border-bottom-2"
                onSelect={(value) => handleChange("tab", value)}
              >
                {tabOptions.map((item, index) => (
                  <Tab key={index} eventKey={item.value} title={item.title} />
                ))}
              </Tabs>
            </Col>
          </Row>
          <Row className="ps-4 align-items-center">
            {/* sementara hanya kirim barang saja */}
            <Col md={5} lg={4}>
              <InputGroup className="border rounded-card">
                <FloatingLabel
                  className="rounded-card w-75 text-muted"
                  // label={"Cari " + tab}
                  label={"Cari Penagihan / Partner"}
                  style={{ width: "", fontSize: "12px", height: "50px" }}
                >
                  <Form.Control
                    className="border-0 rounded-card"
                    type="text"
                    style={{ height: "50px" }}
                    // placeholder={"Cari " + tab}
                    placeholder={"Cari Penagihan / Partner"}
                    onChange={(event) => handleSearch(event)}
                  />
                </FloatingLabel>
                <InputGroup.Text
                  className="border-0 search-icon-trx rounded-card ms-auto"
                  style={{ height: "50px" }}
                >
                  <FaSearch />
                </InputGroup.Text>
              </InputGroup>
            </Col>
          </Row>
        </Card>
      ) : (
        <div className="p-2 head-menu trx w-100 shadow-md">
        </div>
      )}
      {tab === "tagihan transaksi" && <Transaction search={debounceSearch} />}
      {tab === "tagihan kendaraan" && layanan === 1 && <Vehicle search={debounceSearch} />}
     </div>
  );
};

export default DocumentPayment;
