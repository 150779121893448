import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Dropdown, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import noData from "../../assets/images/no-data.png";
import { useHistory, useLocation } from "react-router-dom";
import { Card, Spin } from "antd";
import ProcurementList from "./ProcurementList/ProcurementList";
import { getProcurementList } from "../../redux/action/Management/procurement_action";

const InContract = (props) => {
  /*======================== Props ======================== */

  const { search } = props;

  /*======================== Hooks ======================== */

  const dispatch = useDispatch();

  /*======================== Redux ======================== */

  const meta = useSelector((st) => st.procurementReducer.in_contract.meta);
  const data = useSelector((st) => st.procurementReducer.in_contract.data);
  const loading = useSelector((st) => st.procurementReducer.loading);

  /*======================== UseState ======================== */

  const [params, setParams] = useState({
    limit: 10,
    page: 1,
    search: search || undefined,
    sort_by: "created_at",
    order_by: "DESC",
    is_contract: true,
    active_contract_only: true,
  });
  const [sort, setSort] = useState("Terbaru");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  /*======================== Handler ======================== */

  const handleChange = (type, value) => {
    switch (type) {
      case "sort":
        setPage(1);
        setSort(value);
        setParams({
          ...params,
          sort_by: "created_at",
          order_by: value === "Terbaru" ? "DESC" : "ASC",
          page: 1,
        });
        break;
      case "page":
        setPage(value);
        setParams({ ...params, page: value });
        break;
      case "limit":
        setPage(1);
        setLimit(value);
        setParams({ ...params, limit: value, page: 1 });
        break;

      default:
        break;
    }
  };

  /*======================== UseEffect ======================== */

  useEffect(() => {
    if (search) {
      setPage(1);
      dispatch(getProcurementList({ ...params, search, page: 1 }));
    } else {
      dispatch(getProcurementList({ ...params }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, search]);

  /*======================== Others ======================== */

  const sortOptions = [
    {
      title: "Terbaru",
    },
    {
      title: "Terlama",
    },
  ];

  /*======================== Return ======================== */

  return (
    <>
      <div className="mt-4">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h2 className="fw-bold text-capitalize">
              Kontrak Berlangsung - Kirim Barang
            </h2>
            <p>Daftar kontrak anda yang sedang berlangsung dengan partner </p>
          </div>
          <div className="d-flex align-items-center">
            <h4 className="mt-2 me-2" style={{ fontWeight: "600" }}>
              Urutkan Berdasarkan :
            </h4>
            <Dropdown className="d-inline mx-2">
              <Dropdown.Toggle
                id="dropdown-autoclose-true"
                size="sm"
                variant="light"
                className="bg-white text-secondary px-3 border shadow-sm rounded"
              >
                <strong>{sort}</strong>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {sortOptions.map((item, index) => (
                  <Dropdown.Item
                    key={index}
                    href="#"
                    onClick={() => {
                      handleChange("sort", item.title);
                    }}
                  >
                    {item.title}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
      {data.length > 0 && (
        <ProcurementList
          {...{
            handleChange,
            limit,
            page,
            loading,
            data,
          }}
          total_data={meta?.total_data}
        />
      )}
      {data.length < 1 &&
        (!loading ? (
          <div
            className="d-flex flex-column align-items-center justify-content-center"
            style={{ marginTop: "5rem" }}
          >
            <img src={noData} alt="no-data" />
            <p className="mt-1">Anda belum memiliki kontrak aktif</p>
          </div>
        ) : (
          <Card
            className="p-4 rounded-card border-0 d-flex align-items-center justify-content-center mt-4"
            style={{
              height: "350px",
            }}
          >
            <Spin />
          </Card>
        ))}
    </>
  );
};

InContract.propTypes = {
  search: PropTypes.string,
};

export default InContract;
