import UserMgtApi from "../../api/user-management.api";
import { resetAuth } from "./auth_action";

export const getRoleList = (token, sort = "desc") => async (dispatch) => {
  try {
    const api = new UserMgtApi();

    dispatch({
      type: "SET_ROLE_LIST",
      payload: { loading: true, data: [] },
    });

    let response = await api.getRoleList(token, sort);

    if (response.status === 401) {
      dispatch(resetAuth());
    }

    dispatch({
      type: "SET_ROLE_LIST",
      payload: { loading: false, data: response?.data },
    });
  } catch (error) {
    console.log(error?.response);
  }
};

export const getRoleListByName = (token, name) => async (dispatch) => {
  try {
    const api = new UserMgtApi();

    dispatch({
      type: "SET_ROLE_LIST",
      payload: { loading: true, data: [] },
    });

    let response = await api.getRolebyName(token, name);

    if (response.status === 401) {
      dispatch(resetAuth());
    }

    dispatch({
      type: "SET_ROLE_LIST",
      payload: { loading: false, data: response?.data },
    });
  } catch (error) {
    console.log(error?.response);
  }
};

export const getAccessRoleList = (token) => async (dispatch) => {
  try {
    const api = new UserMgtApi();

    dispatch({
      type: "SET_ACCESS_ROLE_LIST",
      payload: { loading: true, data: [] },
    });

    let response = await api.getAccessRoleList(token);

    if (response.status === 401) {
      dispatch(resetAuth());
    }

    dispatch({
      type: "SET_ACCESS_ROLE_LIST",
      payload: { loading: false, data: response?.data },
    });
  } catch (error) {
    console.log(error?.response);
  }
};

export const setSelectedRole = (payload) => ({
  type: "SET_SELECTED_ROLE",
  payload,
});

export const getRoleDropList = (token, sort = "desc") => async (dispatch) => {
  try {
    const api = new UserMgtApi();

    dispatch({
      type: "SET_DROPLIST_ROLE",
      payload: { loading: true, data: [] },
    });

    let response = await api.getRoleList(token, sort);

    if (response.status === 401) {
      dispatch(resetAuth());
    }

    dispatch({
      type: "SET_DROPLIST_ROLE",
      payload: { data: response?.data },
    });
  } catch (error) {
    console.log(error?.response);
  }
};

export const getAccountList = (token, sort = "desc") => async (dispatch) => {
  try {
    const api = new UserMgtApi();

    dispatch({
      type: "SET_ACCOUNT_LIST",
      payload: { loading: true, data: [] },
    });

    let response = await api.getAccountList(token, sort);

    if (response.status === 401) {
      dispatch(resetAuth());
    }

    dispatch({
      type: "SET_ACCOUNT_LIST",
      payload: { loading: false, data: response?.data },
    });
  } catch (error) {
    console.log(error?.response);
  }
};

export const getAccountListByName = (token, name) => async (dispatch) => {
  try {
    const api = new UserMgtApi();

    dispatch({
      type: "SET_ACCOUNT_LIST",
      payload: { loading: true, data: [] },
    });

    let response = await api.getAccountbyName(token, name);

    if (response.status === 401) {
      dispatch(resetAuth());
    }

    dispatch({
      type: "SET_ACCOUNT_LIST",
      payload: { loading: false, data: response?.data },
    });
  } catch (error) {
    console.log(error?.response);
  }
};

