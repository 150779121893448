import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  Form,
  Tabs,
  Tab,
  Card,
} from "react-bootstrap";
import {
  Divider,
  Avatar,
  Badge,
  Tag,
  Dropdown,
  Steps,
  Radio,
  Space,
} from "antd";
import {
  getStatusDesc,
  getStatusDescDetail,
} from "../../helper/trx_status_helper";
import moment from "moment";

const StatusOrderModal = ({
  showOrderStatusModal,
  closeOrderStatusModal,
  log_booking_status,
  trxDetail,
}) => {
  return (
    <Modal
      contentClassName="p-1"
      show={showOrderStatusModal}
      onHide={closeOrderStatusModal}
      className=""
      centered
    >
      <Modal.Header closeButton className="bg-white">
        <strong>Status Order</strong>
      </Modal.Header>
      <Row className="p-4">
        {log_booking_status?.length > 0 &&
          [...log_booking_status]?.reverse()?.map((el, i) => (
            <>
              <Col xs={3}>
                <h6 className="float-end">
                  {moment(el?.crated_at).format("DD MMM YYYY")}
                </h6>
                <h6 className="float-end fw-bold">
                  {moment(el?.created_at).format("HH : mm")} WIB
                </h6>
              </Col>
              <Col xs={9}>
                <Steps
                  progressDot
                  status={i == 0 ? "process" : "wait"}
                  direction="vertical"
                >
                  <Steps.Step
                    title={getStatusDesc(el?.order_status)}
                    description={
                      <>
                        <p>{getStatusDescDetail(el?.order_status)}</p>
                        {(el?.order_status === "GO_TO_PICKUP_LOCATION" ||
                          el?.order_status === "GO_TO_RETURN_LOCATION") && (
                          <>
                            <p>Nama : {trxDetail?.data?.mst_driver?.name}</p>
                            <p>
                              Nomor Ponsel : +
                              {trxDetail?.data?.mst_driver?.wa_number}
                            </p>
                          </>
                        )}
                      </>
                    }
                  />
                  {i < log_booking_status.length - 1 && <Divider />}
                </Steps>
              </Col>
            </>
          ))}
      </Row>
    </Modal>
  );
};

export default StatusOrderModal;
