import React, { useState } from "react";
import { Row, Col, Card, Modal } from "react-bootstrap";

// icons
import { IoChevronDown, IoCheckmark } from "react-icons/io5";

function PickupService() {
  const [pickup, setPickup] = useState([
    {
      key: "1",
      title: "Layanan Pickup 1 Jam",
      content:
        "Estimasi armada tiba maksimal dalam 1 jam. (Wording disesuaikan dengan tipe unit kendaraan yang lain)",
      isSelect: true,
    },
    {
      key: "2",
      title: "Layanan Pickup 3 Jam",
      content:
        "Estimasi armada tiba maksimal 3 jam. (Wording disesuaikan dengan tipe unit kendaraan yang lain)",
      isSelect: false,
    },
  ]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Card className="border-0 pointer" onClick={handleShow}>
        <Row className="card-body py-1">
          <Col xs={11} className="align-self-center">
            <p className="fw-bold mb-0 w-100">Layanan Pickup 1 Jam</p>
          </Col>
          <Col xs={1} className="align-self-center">
            <IoChevronDown />
          </Col>
        </Row>
      </Card>

      <Modal
        contentClassName="modal-100 p-1"
        show={show}
        onHide={handleClose}
        className=""
        centered
      >
        <Modal.Header closeButton className="bg-white">
          <strong>Pilih Waktu Pickup</strong>
        </Modal.Header>
        {pickup.map((data, i) => (
          <Card
            key={i}
            className={
              data.isSelect ? "border-fleet border-secondary" : "border-fleet"
            }
          >
            <Row className="card-body py-2">
              <Col xs={11} className="align-self-center">
                <p className="fw-bold mb-0">{data.title}</p>
                <h6 className="mb-0 text-muted">{data.content}</h6>
              </Col>
              <Col xs={1} className="align-self-center">
                {data.isSelect ? (
                  <IoCheckmark className="text-secondary" />
                ) : null}
              </Col>
            </Row>
          </Card>
        ))}
      </Modal>
    </>
  );
}

export default PickupService;
