import React, { Component } from "react";
import { Card } from "react-bootstrap";
import Gmap from "../../components/gMap";
import { connect } from "react-redux";
import TabOtp from "../../components/tabAuth/otp";

class OtpPage extends Component {
  render() {
    return (
      <div style={{ height: "100vh", width: "100%" }}>
        {this.props.authReducer.access_token === null ? (
          <Card className="card-absolute height-auto">
            <Card.Body className="pb-0 flex-i">
              <TabOtp />
            </Card.Body>
          </Card>
        ) : null}
        <Gmap />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
});
export default connect(mapStateToProps)(OtpPage);
