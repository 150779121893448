import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  InputGroup,
  FloatingLabel,
  Button,
} from "react-bootstrap";
import InfoBar from "../infoBar/InfoBar";
import PinInput from "react-pin-input";
import Countdown from "react-countdown";
import { Upload, message, Tag } from "antd";
import { previewImage } from "../../helper/imgPreview";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { resetAuth, setAuth } from "../../redux/action/auth_action";
import ProfileApi from "../../api/profile-api";
import AccountApi from "../../api/auth-api";
import OtpEngine from "../../api/otp-engine"
import useBreakpoint from "../responsiveUtils/useBreakpoint";

import icon_phone from "../../assets/images/icon-phone.svg";
import { MdChevronRight, MdClose, MdCheck } from "react-icons/md";
import { BsFillCheckCircleFill } from "react-icons/bs";

import icon_phone_circle_blue from "../../assets/images/icon_phone_circle_blue.svg";

const PhoneNumber = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [show, setShow] = useState(false);
  const auth = useSelector((st) => st.authReducer);
  const [countdown, setCountdown] = useState(Date.now() + 30000);
  const [body, setBody] = useState({
    old_wa_number: "",
    new_wa_number: "",
  });
  const { isMobile, isDesktop, isTablet } = useBreakpoint();

  const [id, setId] = useState(null)
  const [otpProcess, setOtpProcess] = useState(false)
  const [otpSuccess, setOtpSuccess] = useState(false)
  const [counter, setCounter] = useState(0);

  const openModal = () => {
    setShow(true);
  };

  const closeModal = () => {
    setShow(false);
  };

  const handleSubmit = async () => {
    try {
      const accountApi = new AccountApi();
      const profileApi = new ProfileApi();

      let payload = {};
      payload["wa_number_new"] = `0${body?.new_wa_number}`;
      payload["otp_id"] = id;

      const result = await accountApi.checkToken(auth.refresh_token);
      if (result.status === "success") {
        const profileRes = await profileApi.submitChangeNewWaNumber(
          { ...payload },
          auth.access_token
        );

        if (profileRes.status === "success") {
          setActiveStep(steps[0])
          setBody({
            old_wa_number: "",
            new_wa_number: "",
          })
          message.success(profileRes.message);
          const { data } = await accountApi.getDetailCustomer(
            auth.access_token
          );

          dispatch(setAuth({ ...auth, ...data }));

          // setIsSubmit(true);
          handleNext();
        }
      } else {
        history.push("/login");
        dispatch(resetAuth());
      }
    } catch (error) {
      console.log(error.response);
    }
  };

  const steps = [
    {
      id: 0,
      title: "Apakah anda yakin ingin merubah nomor Ponsel ?",
    },
    {
      id: 1,
      title: "Ubah Nomor Ponsel",
    },
    // {
    //   id: 2,
    //   title: "Ubah Nomor Ponsel",
    // }
  ];

  const [activeStep, setActiveStep] = useState({
    id: 0,
    title: "Apakah anda yakin ingin merubah nomor Ponsel ?",
  });

  const handleNext = () => {
    let nextIndex = activeStep.id + 1;

    if (nextIndex > steps.length - 1) return;

    setActiveStep(steps[nextIndex]);
  };

  const handleBack = () => {
    let backIndex = activeStep.id - 1;
    if (backIndex === 0) setActiveStep(steps[backIndex]);
    if (backIndex < 0) closeModal()
  };
  
  const handleOtpEngine = async() => {
    if(activeStep.id === 1){
      if(!body.new_wa_number || body.new_wa_number.length < 8){
        message.error("Input Nomor baru terlebih dahulu, minimal 9 angka dan di mulai dari 8")
        return;
      }
    }
    setCounter(10)
    setOtpProcess(true)

    const payload = {
      data_user_id: -1,
      // data_no_hp: state.user,
      data_no_hp: activeStep.id === 0 ? auth?.wa_number : `62${body?.new_wa_number}`,
      data_destination: 1,
      data_type: 4
    }

   
    const otpEngine = new OtpEngine();
    const response = await otpEngine.otpRequest(payload)

    if (response?.status === "success"){
      setId(response?.data?.id)
      window.open(response?.data?.wa_me,'_blank');
    }
  }

  const cekOtpEngine = async() => {
    const otpEngine = new OtpEngine();
    const response = await otpEngine.otpCheck(id)
    if (response?.status === "success"){
      if(activeStep.id === 0){
        console.log("handle next")
        setOtpSuccess(true)
        handleNext()
      }else if(activeStep.id ===1){
        // console.log("handle submit")
        handleSubmit()
        closeModal();
      }
    }
  }

  useEffect(()=>{
    counter > 0 && setTimeout(()=> setCounter(counter-1),1000)
    if(counter === 0 && otpProcess){
      cekOtpEngine()
      setOtpProcess(false)
    } 
  },[counter])

  console.log(activeStep)

  const renderSection = () => {
    console.log("call gak nih")
    if (activeStep.id === 0) {
      return (
        <>
          <h6 className="text-muted">Nomor anda akan dipakai untuk : </h6>
          <div className="ms-2">
            <p>
              <span>
                <BsFillCheckCircleFill className="text-secondary me-2" />
              </span>
              Login ke akun anda
            </p>
            <p>
              <span>
                <BsFillCheckCircleFill className="text-secondary me-2" />
              </span>
              Verifikasi penarikan saldo anda
            </p>
            <p>
              <span>
                <BsFillCheckCircleFill className="text-secondary me-2" />
              </span>
              Terima SMS dan Telepon dari driver / Mita eLOG
            </p>
          </div>
        </>
      );
    }

    if (activeStep.id === 1) {
      return (
        <>
          <InputGroup className="border rounded-input mt-2">
            <InputGroup.Text
              className="border-0 rounded-input"
              style={{ backgroundColor: "#f8f9fa" }}
            >
              <img src={icon_phone} alt="" />
            </InputGroup.Text>
            {/* <InputGroup.Text className="border-0 rounded-input">
              +62
            </InputGroup.Text> */}
            <FloatingLabel
              className="rounded-input"
              label="Nomor Ponsel Lama"
              style={{ fontSize: "12px", width: isMobile ? "85%" : "92.5%" }}
            >
              <Form.Control
                type={"number"}
                disabled
                value={auth?.wa_number}
                className="border-0 rounded-input"
                placeholder="m"
                style={{ height: "50px" }}
              ></Form.Control>
            </FloatingLabel>
          </InputGroup>
          <InputGroup className="border rounded-input mt-2">
            <InputGroup.Text
              className="border-0 rounded-input"
              style={{ backgroundColor: "#f8f9fa" }}
            >
              <img src={icon_phone} alt="" />
            </InputGroup.Text>
            <InputGroup.Text className="border-0 rounded-input">
              +62
            </InputGroup.Text>
            <FloatingLabel
              className="rounded-input"
              label="Nomor Ponsel Baru"
              style={{ fontSize: "12px", width: isMobile ? "70%" : "80%" }}
            >
              <Form.Control
                type={"number"}
                className="border-0 rounded-input"
                placeholder="m"
                value={body.new_wa_number.replace(/^0+/, "")}
                onChange={(ev) =>
                  setBody({ ...body, new_wa_number: ev.target.value })
                }
                style={{ height: "50px" }}
              ></Form.Control>
            </FloatingLabel>
          </InputGroup>
        </>
      );
    }

  };

  const disButton = (id) => {
    if (id === 1) {
      return body?.new_wa_number ? false : true;
    }
    if (id === 2) {
      return body?.new_wa_number ? false : true;
      // return otp_code ? false : true;
    }

    return false;
  };

  return (
    <>
      <div
        className="d-flex align-items-center pointer  px-2"
        onClick={openModal}
      >
        <img src={icon_phone} width={25} height={25} alt="" />
        <div className="ms-3">
          <h6 className="text-muted ">Nomor Ponsel</h6>
          <h5>+{auth.wa_number}</h5>
        </div>
        <div
          className="d-flex ms-auto align-items-center "
          style={{ marginTop: "-10px" }}
        >
          {auth?.verified_wa_number > 0 && (
            <Tag color="green" icon={isMobile ? <MdCheck /> : null}>
              {isMobile ? "" : "Terverifikasi"}
            </Tag>
          )}

          <MdChevronRight className="" />
        </div>
      </div>
      <Modal
        contentClassName="rounded-card p-2"
        show={show}
        onHide={closeModal}
        className="p-3"
        centered
      >
        <div className="d-flex p-2">
          <p className="fw-bold">Ubah Nomor Ponsel fff</p>
          <MdClose className="ms-auto me-2 pointer" onClick={closeModal} />
        </div>
        <Modal.Body>
          <div className="">
            <img src={icon_phone_circle_blue} className="mb-2" alt="" />
            <h4 className="fw-bold">{activeStep.title}</h4>
            {renderSection()}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <p className="text-primary pointer me-4" onClick={handleBack}>
            Batalkan
          </p>
          {!otpProcess &&
          <Button
            disabled={disButton(activeStep.id)}
            size="sm"
            variant="primary"
            className="float-end rounded-pill px-4 py-2"
            onClick={() => {
              if (activeStep.id === 0) {
                handleOtpEngine();
              } else if (activeStep.id === 1) {
                handleOtpEngine();
              } else if (activeStep.id === 1) {
                handleSubmit();
              }
            }}
          >
            {activeStep.id === 0 && "Request OTP"}
            {activeStep.id === 1 && "Submit"}
          </Button>
          }
          {
          otpProcess && 
          // !otpSuccess && 
          counter !== 0 &&
          <div
            className="d-flex justify-content-center align-item-center"
            style={{
              backgroundColor:"#E9ECEF",
              padding:"8px 0",
              width:"100px",
              borderRadius:"20px",
              fontSize:"14px",
              color:"#545454"
            }}
          >
            {counter}
          </div>
          }
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PhoneNumber;
