export const setAuth = (payload) => ({
  type: "SET_AUTH",
  payload,
});

// SSO
export const setTokenSSO = (payload) => ({
  type: "SET_SSO",
  payload,
});
// END - SSO

export const resetAuth = () => ({
  type: "RESET_AUTH",
});

export const setNotifId = (payload) => ({
  type: "SET_NOTIF_ID",
  payload,
});
