import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  getRekeningList,
  getDisbursementMethods,
} from "../../redux/action/balance_action";
// import AddBankAccount from "../../components/modalRekening/AddBankAccount";
import AddBankAccount from "../../components/modalRekening/AddBankAccountOtp";
import AddBankSuccess from "../../components/modalRekening/AddBankSuccess";
import OtpBankAccount from "../../components/modalRekening/OtpBankAccount";
import SideInfo from "./SideInfo";
import BalanceApi from "../../api/balance-api";
import { resetAuth } from "../../redux/action/auth_action";
import { message, Popconfirm } from "antd";
import useBreakpoint from "../../components/responsiveUtils/useBreakpoint";

const BankAccount = () => {
  const dispatch = useDispatch();
  const auth = useSelector((st) => st.authReducer);
  const bankAccounts = useSelector((st) => st.balanceReducer.bankAccounts);
  const { isMobile, isTablet, isDesktop } = useBreakpoint();

  const [showModal, setShowModal] = useState({
    addBank: false,
    otp: false,
    success: false,
  });

  useEffect(() => {
    dispatch(getRekeningList(auth.access_token));
    dispatch(getDisbursementMethods());
  }, []);

  const closeModal = () => {
    setShowModal({});
  };

  const deleteBankAccount = async (id) => {
    try {
      const api = new BalanceApi();

      let response = await api.deleteBankAccount(auth.access_token, id);

      if (response.status === 401) {
        dispatch(resetAuth());
      }

      if (response.status === "success") {
        dispatch(getRekeningList(auth.access_token));

        message.success(response.message);
      }
    } catch (error) {
      console.log(error.response);
    }
  };

  return (
    <div
      style={{ width: "100%", padding: "30px" }}
      className="bg-ice min-vh-100"
    >
      <Row className="">
        <Col xs={12} lg={4}>
          <SideInfo />
        </Col>
        <Col className={`${isMobile && "mt-18rem"}`}>
          <div className="d-flex align-items-center">
            <div>
              <h3 className="fw-bold mt-4">Info Rekening</h3>
              <h6 className="text-muted">
                Akun Rekening Bank yang dapat anda daftarkan maksimal berjumlah
                3 (tiga) buah.
              </h6>
            </div>
            <Button
              disabled={bankAccounts?.data.length === 3}
              className="ms-auto rounded-pill"
              onClick={() => setShowModal({ addBank: true })}
              size="sm"
            >
              {isMobile ? "Tambah" : "+ Tambah Rekening"}
            </Button>
          </div>
          {bankAccounts?.data?.length === 0 && (
            <div className="rounded-card bg-white w-100 p-4 mt-2">
              <h6 className="text-muted">Belum ada data rekening</h6>
            </div>
          )}
          {bankAccounts?.data?.map((el) => (
            <Row className="rounded-card bg-white w-100 p-4 mt-2">
              <Col xs={1} className="d-flex align-items-center">
                <img src={el.logo} className="img-fluid" alt="" />
              </Col>
              <Col>
                <h5 className="fw-bold">{el.bank}</h5>
                <h6 className="">{el.account_number}</h6>
                <h6>a/n {el.account_name}</h6>
              </Col>
              <Col xs={1} className="d-flex align-items-center">
                <Popconfirm
                  title="Apakah anda yakin ?"
                  cancelText="Batalkan"
                  onConfirm={() => deleteBankAccount(el.id)}
                  //  onConfirm={confirm}
                  onVisibleChange={() => console.log("visible change")}
                >
                  <p
                    className="pointer text-primary"
                    // onClick={() => deleteBankAccount(el.id)}
                  >
                    Hapus
                  </p>
                </Popconfirm>
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
      <AddBankAccount
        show={showModal.addBank}
        setShowModal={setShowModal}
        closeModal={closeModal}
      />
      <OtpBankAccount
        show={showModal.otp}
        setShowModal={setShowModal}
        closeModal={closeModal}
      />
      <AddBankSuccess show={showModal.success} closeModal={closeModal} />
    </div>
  );
};

export default BankAccount;
