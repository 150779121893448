import { Row, Col } from "react-bootstrap";
import useBreakpoint from "../../../components/responsiveUtils/useBreakpoint";
import SideNav from "../components/SideNav";
import ListKendaraan from "./ListKendaraan";

const PartnerKendaraan = () => {
  /*======================== Hooks ======================== */
  const { isMobile } = useBreakpoint();

  /*======================== UseState ======================== */

  /*======================== Handler ======================== */

  /*======================== UseEffect ======================== */

  /*======================== Others ======================== */

  /*======================== Return ======================== */

  return (
    <div
      style={{ minHeight: "100vh", width: "100%", padding: "30px" }}
      className="bg-ice height-100"
    >
      <Row className="">
        <Col xs={12} lg={3}>
          <SideNav />
        </Col>
        <Col xs={12} lg={9} className={`${isMobile && "mt-18rem"}`}>
          <ListKendaraan />
        </Col>
      </Row>
    </div>
  );
};

export default PartnerKendaraan;
