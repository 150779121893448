import { useState, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Tabs,
  Tab,
  Form,
  InputGroup,
  FloatingLabel
} from "react-bootstrap";

// icons
import { FaSearch } from "react-icons/fa";

const DekstopHeader = ({ handleChangeTab, getRoleByName, getAccountByName, eventKey }) => {

  const [role, setRole] = useState('');
  const [account, setAccount] = useState('');

  const handleSearch = (event) => {
    setRole(event.target.value);
    getRoleByName(event.target.value);
  }

  const handleAccountSearch = (event) => {
    setAccount(event.target.value);
    getAccountByName(event.target.value);
  }

  useEffect(() => {
    if (eventKey) {
      setRole('');
      setAccount('');
    }
  }, [eventKey]);

  return (
    <Card
      className="p-4 rounded-card border-0"
      style={{ backgroundColor: "white" }}
    >
      <h1>Management Role & User</h1>
      <Row className="card-body">
        <Col>
          <Tabs
            defaultActiveKey={eventKey}
            className="tabs-trx mb-2 border-bottom-2"
            onSelect={handleChangeTab}
          >
            <Tab eventKey="1" title="Role User"></Tab>
            <Tab eventKey="2" title="Akun User"></Tab>
            {/* <Tab eventKey="3" title="Pengemudi"></Tab> */}
          </Tabs>
        </Col>
      </Row>
      <Row className="ps-4">
        <Col sm={12} md={6} lg={4}>
          {
            eventKey === 1 ? (
              <InputGroup className="border rounded-card">
                <FloatingLabel
                  className="rounded-card w-75"
                  label="Cari Role yang tersedia"
                  style={{ width: "", fontSize: "12px", height: "50px" }}
                >
                  <Form.Control
                    id="roleForm"
                    className="border-0 rounded-card"
                    type="text"
                    style={{ height: "50px" }}
                    placeholder="Cari Role yang tersedia"
                    onChange={(ev) => handleSearch(ev)}
                    value={role}
                  />

                </FloatingLabel>
                <InputGroup.Text
                  className="border-0 search-icon-trx rounded-card ms-auto"
                  style={{ height: "50px" }}
                >
                  <FaSearch />
                </InputGroup.Text>
              </InputGroup>
            ) : (
              <InputGroup className="border rounded-card">
                <FloatingLabel
                  className="rounded-card w-75"
                  label="Cari Akun yang tersedia"
                  style={{ width: "", fontSize: "12px", height: "50px" }}
                >
                  <Form.Control
                    id="accountForm"
                    className="border-0 rounded-card"
                    type="text"
                    style={{ height: "50px" }}
                    placeholder="Cari Akun yang tersedia"
                    onChange={(ev) => handleAccountSearch(ev)}
                    value={account}
                  />

                </FloatingLabel>
                <InputGroup.Text
                  className="border-0 search-icon-trx rounded-card ms-auto"
                  style={{ height: "50px" }}
                >
                  <FaSearch />
                </InputGroup.Text>
              </InputGroup>
            )
          }
        </Col>
      </Row>
    </Card>
  )
}

export default DekstopHeader;
