import { message } from "antd";
import { set } from "lodash";
import React, { useState } from "react";
import { useEffect } from "react";
import {
  Modal,
  Button,
  Accordion,
  Col,
  Card,
  Row,
  useAccordionButton,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { ReactComponent as ICArrowRight } from "../../../assets/images/ic_arrow_right.svg";
import { ReactComponent as ICCheck } from "../../../assets/images/ic_check_success.svg";
import { makePayment } from "../../../redux/action/Invoice/invoice_action";
import { gePaymentList } from "../../../redux/action/payment_list_action";
import { FaCheckCircle } from "react-icons/fa";
import { formatRupiah } from "../../../helper/formatCurrency";
import _ from "lodash";

const KonfirmasiPembayaran = ({
  show,
  onHide,
  onSuccess,
  idInvoice,
  total,
}) => {
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [totalPayment, setTotalPayment] = useState(0);

  const getListPaymentMethodDispatch = async () => {
    try {
      const resp = await dispatch(gePaymentList());
      let groupData = _.groupBy(resp, (item) => item.type);

      let newArray = [];
      for (const key in groupData) {
        newArray.push({
          id: key,
          name: getTypeName(key),
          list_payment: groupData[key],
        });
      }

      setData([...newArray]);
    } catch (error) {
      console.log(error);
    }
  };

  const setPaymentMethod = (item) => {
    setSelectedPayment(item);
    setIsEdit(false);
  };

  const getTypeName = (type) => {
    switch (type) {
      case "1":
        return "Virtual Account";
      case "2":
        return "Credit Card";
      case "3":
        return "E-Wallet";
      case "4":
        return "QRIS";
      case "5":
        return "Retail Outlet";
      case "6":
        return "Cardless/Paylater";
      case "7":
        return "Paylater (Special Customer)";
      default:
        return type;
    }
  };

  const handleMakePayment = async () => {
    try {
      const payload = {
        id: idInvoice,
        payment_method_id: selectedPayment.id,
      };
      const resp = await dispatch(makePayment(payload));
      if (resp.status === 'success') {
        onSuccess();
      }
    } catch (error) {
      message.error(error);
    }
  };

  const showPaymentCategoryList = () => {
    if (data && data.length > 0 && (!selectedPayment || isEdit)) {
      let component = [];
      data.forEach((list) => {
        component.push(
          <Card key={list.id} className="mb-2">
            <Card.Header className="cus-header">
              <CustomToggle eventKey={list.id}>
                {list.name}
                <ICArrowRight />
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={list.id}>
              <Card.Body className="cus-body py-1">
                <ul className="px-2">{showPaymentMethodList(list.list_payment)}</ul>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        );
      });
      return component;
    }
  };

  const showPaymentMethodList = (item) => {
    let component = [];
    if (item && item.length > 0) {
      item.forEach((row) => {
        component.push(
          <Button
            className="rounded mx-auto btn-block w-100 mt-3"
            key={row.id}
            onClick={() => setPaymentMethod(row)}
            variant="info"
            style={
              selectedPayment && selectedPayment.id === row.id
                ? { backgroundColor: "#E2E9F6" }
                : { backgroundColor: "#FFFFFF" }
            }
          >
            <div className="d-flex justify-content-between py-1">
              <span>{row.name}</span>
              {selectedPayment && selectedPayment.id === row.id && (
                <FaCheckCircle
                  className="mt-1"
                  style={{ fontSize: "15px", color: "#209AB7" }}
                />
              )}
            </div>
          </Button>
        );
      });
      return component;
    }
  };

  useEffect(() => {
    getListPaymentMethodDispatch();
  }, []);

  useEffect(() => {
    if (selectedPayment && selectedPayment.id) {
      let totalAmount = 0;
      if (selectedPayment.admin_fee_fixed) {
        totalAmount = total + selectedPayment.admin_fee_fixed;
      } else {
        let percent = Math.ceil(total * (selectedPayment.admin_fee_percent/100));
        if (percent > selectedPayment.max_price) {
          totalAmount = total + selectedPayment.max_price;
        } else {
          totalAmount = total + percent;
        }
      } 
      setTotalPayment(totalAmount);
    } else {
      setTotalPayment(total);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPayment]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="rounded-pill"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h2>Konfirmasi Pembayaran</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-muted mb-2">
          Pastikan anda sudah melakukan pengecekan secara keseluruhan.
        </p>
        <p className="text-muted">
          Anda akan melakukan pembayaran invoice dengan metode pembayaran:
        </p>
        <Col xs={12} className="mt-6">
          <Accordion className="mt-3">
            {selectedPayment && (
              <>
                <h5>{isEdit ? "Pembayaran Terpilih" : ""}</h5>
                <Card className="mb-3 position-relative">
                  <div className="separator-small mt-2" />
                  <div className="d-flex align-items-center gap-2 py-2 px-3">
                    <img
                      src={selectedPayment.logo}
                      alt={selectedPayment.name}
                      style={{
                        width: "54px",
                        height: "43px",
                        // objectFit: 'cover'
                      }}
                    />
                    <div
                      className="ms-2 d-flex justify-content-between align-items-center"
                      style={{ width: "100%" }}
                    >
                      <span>{selectedPayment.name}</span>
                      <ICCheck className="align-items-end" />
                    </div>
                  </div>
                </Card>
              </>
            )}
            {selectedPayment && !isEdit && (
              <p
                className="text-primary underline pointer"
                align="right"
                onClick={() => setIsEdit(true)}
              >
                Ubah Metode Pembayaran
              </p>
            )}

            <h5 className="mt-3">
              {selectedPayment
                ? isEdit
                  ? "Pembayaran Lain"
                  : ""
                : "Pilihan Pembayaran"}
            </h5>
            {showPaymentCategoryList()}
          </Accordion>
        </Col>
      </Modal.Body>
      <Modal.Footer
        className="pb-2 px-0"
        style={{ backgroundColor: "#E2E5E8", paddingTop: "1px" }}
      >
        <div
          className="d-flex justify-content-between w-100 py-4 px-4 mx-0"
          style={{ backgroundColor: "#FFFFFF" }}
        >
          <b>Total Pembayaran</b>
          <b>{formatRupiah(totalPayment)}</b>
        </div>
        <Button
          className="mt-3 rounded-pill mx-auto btn-block w-75"
          onClick={() => handleMakePayment()}
        >
          Lakukan Pembayaran
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey, () =>
    console.log("totally custom!", eventKey)
  );

  return (
    <button
      type="button"
      className="accordion-header"
      style={{ border: "none" }}
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
}

export default KonfirmasiPembayaran;
