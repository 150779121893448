const initialState = {
  trxOngoing: { loading: false, data: [] },
  trxDone: { loading: false, data: [] },
  trxDetail: { loading: false, data: {} },
  partnerDetail: { loading: false, data: {} },
};

export const trxHistoryReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "SET_TRX_STATE":
      return { ...state, ...payload };
    case "RESET_TRX_STATE":
      return { ...initialState };
    default:
      return state;
  }
};
