import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Row, Col, Form, InputGroup } from "react-bootstrap";
import { useSelector } from "react-redux";
import { message } from "antd";
import useBreakpoint from "../../responsiveUtils/useBreakpoint";
import icon_upload_profil from "../../../assets/images/icon_upload_profil.svg";
import { FiEye, FiEyeOff, FiCheckCircle } from "react-icons/fi";

const UserAccountForm = forwardRef(({ dataForm, submit }, ref) => {
  const { isMobile } = useBreakpoint();
  const roleListForAccount = useSelector(
    (st) => st.userMgtReducer.roleListForAccount.data
  );
  const [preview, setPreview] = useState();
  const [showPass, setShowPass] = useState(false);
  const [validasiTelepon, setValidasiTelepon] = useState(false);
  const [validasiPassword, setValidasiPassword] = useState({
    length: false,
    character: false,
  });
  const [validasiEmail, setValidasiEmail] = useState(false);
  const [form, updateForm] = useState({
    id: dataForm.id,
    roleId: dataForm.role_id,
    name: dataForm.name,
    email: dataForm.email,
    phone: dataForm.phone,
    password: dataForm.password,
    photo: dataForm.photo,
  });

  useImperativeHandle(ref, () => ({
    handleInput() {
      if (
        form.roleId === "" ||
        form.name === "" ||
        form.email === "" ||
        form.phone === "" ||
        (form.password === "" && !dataForm.isEdit)
      ) {
        message.error(
          "Role, Nama, Email, Nomor Telepon dan Pasword harus diisi"
        );
      } else if (validasiEmail) {
        message.error("Format email salah.");
      } else if (validasiTelepon) {
        message.error(
          'Nomor Telepon dimulai angka "8". Minimal 8 Karakter Maximal 14 Karakter.'
        );
      } else if (!dataForm.isEdit && (!validasiPassword.length || !validasiPassword.character)) {
        message.error(
          "Password harus memiliki minimal 8 karakter, huruf besar, huruf kecil, angka dan symbol"
        );
      } else {
        let data = {
          id: form.id,
          role_id: form.roleId,
          name: form.name,
          email: form.email,
          phone: "0" + form.phone,
          password: form.password,
          photo: form.photo,
        };
        submit(data);
      }
    },
  }));

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      updateForm({
        ...form,
        photo: undefined,
      });
      return;
    }
    if (e.target.files[0].size > 5000000) {
      message.error("Ukuran Maksimal foto adalah 5Mb");
    } else {
      updateForm({
        ...form,
        photo: e.target.files[0],
      });
    }
  };

  const getDropListValue = () => {
    let component = [];
    roleListForAccount.forEach((item) => {
      component.push(
        <option value={item.id} key={item.id}>
          {item.name}
        </option>
      );
    });

    return component;
  };

  useEffect(() => {
    updateForm({
      id: dataForm.id,
      roleId: dataForm.role_id,
      name: dataForm.name,
      email: dataForm.email,
      phone: dataForm.phone,
      password: dataForm.password,
      photo: dataForm.photo,
    });
  }, [dataForm]);

  useEffect(() => {
    if (!form.photo) {
      setPreview(undefined);
      return;
    } else if (form.photo && typeof form.photo === "string") {
      setPreview(form.photo);
      return;
    }

    const objectUrl = URL.createObjectURL(form.photo);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [form.photo]);

  useEffect(() => {
    var regexTelepon = new RegExp(/(^8)(\d{7,13})$/);
    var result = regexTelepon.test(form.phone);
    if (result) {
      setValidasiTelepon(false);
    } else {
      setValidasiTelepon(true);
    }
  }, [form.phone]);

  useEffect(() => {
    var regexEmail = new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    var result = regexEmail.test(form.email);
    if (result) {
      setValidasiEmail(false);
    } else {
      setValidasiEmail(true);
    }
  }, [form.email]);

  useEffect(() => {
    var minLengthRegex = new RegExp(/^.{8,}$/);
    var characterRegex = new RegExp(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,20}$/
    );

    var length = minLengthRegex.test(form.password);
    var character = characterRegex.test(form.password);

    setValidasiPassword({
      length: length,
      character: character,
    });
  }, [form.password]);

  return (
    <>
      <Row>
        <Col lg={6} md={6} sm={12}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInputRole">
            <Form.Label>
              <strong>Pilih Role*</strong>
            </Form.Label>
            {/* <Form.Control type="text" className="w-100" /> */}
            <Form.Select
              value={form?.roleId}
              aria-label="Default select example"
              onChange={(e) => {
                updateForm({
                  ...form,
                  roleId: e.target.value,
                });
              }}
            >
              <option value="">Pilih Role</option>
              {getDropListValue()}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col lg={6} md={6} sm={12}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInputName">
            <Form.Label>
              <strong>Nama Akun*</strong>
            </Form.Label>
            <Form.Control
              value={form?.name}
              type="text"
              className="w-100"
              onChange={(e) => {
                updateForm({
                  ...form,
                  name: e.target.value,
                });
              }}
            />
          </Form.Group>
        </Col>
        <Col lg={6} md={6} sm={12}>
          <Form.Group
            className="mb-3"
            controlId="exampleForm.ControlInputEmail"
          >
            <Form.Label>
              <strong>Email*</strong>
            </Form.Label>
            <Form.Control
              value={form?.email}
              type="email"
              className="w-100"
              onChange={(e) => {
                updateForm({
                  ...form,
                  email: e.target.value,
                });
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col lg={6} md={6} sm={12}>
          <Form.Group
            className="mb-3"
            controlId="exampleForm.ControlInputPhone"
          >
            <Form.Label>
              <strong>No. Handphone*</strong>
            </Form.Label>
            <InputGroup>
              <InputGroup.Text
                id="inputGroupPrepend"
                style={{ fontSize: "12px" }}
              >
                +62
              </InputGroup.Text>
              <Form.Control
                value={form?.phone}
                type="number"
                placeholder="8xxx"
                aria-describedby="inputGroupPrepend"
                required
                onChange={(e) => {
                  updateForm({
                    ...form,
                    phone: e.target.value,
                  });
                }}
              />
            </InputGroup>
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlInputPwd">
            <Form.Label>
              <strong>Password*</strong>
              {dataForm.isEdit && (
                <p className="text-muted mb-0" style={{ fontSize: "10px" }}>
                  *Isi input jika ingin mengganti password
                </p>
              )}
            </Form.Label>
            <InputGroup>
              <Form.Control
                value={form?.password}
                type={showPass ? "text" : "password"}
                aria-describedby="inputGroupAppend"
                onChange={(e) => {
                  updateForm({
                    ...form,
                    password: e.target.value,
                  });
                }}
              />
              <InputGroup.Text
                id="inputGroupAppend"
                style={{ fontSize: "12px" }}
                onClick={() => setShowPass(!showPass)}
              >
                {showPass ? <FiEyeOff /> : <FiEye />}
              </InputGroup.Text>
            </InputGroup>
            <p
              className="mt-1 mb-0"
              style={
                validasiPassword.length
                  ? { color: "#0FAA4B", fontSize: "10px" }
                  : { color: "#CED4DA", fontSize: "10px" }
              }
            >
              <FiCheckCircle /> Password memiliki minimal <b>8 Karakter</b>
            </p>
            <p
              className="mb-0"
              style={
                validasiPassword.character
                  ? { color: "#0FAA4B", fontSize: "10px" }
                  : { color: "#CED4DA", fontSize: "10px" }
              }
            >
              <FiCheckCircle /> Password harus berisi{" "}
              <b>Huruf Besar, Huruf Kecil, Angka, & Simbol </b>
            </p>
          </Form.Group>
        </Col>
        <Col lg={6} md={6} sm={12}>
          <h4>
            <strong>Foto Profil (Optional)</strong>
          </h4>
          {isMobile ? (
            <>
              <div
                style={{
                  border: "1px solid #CED4DA",
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h4 className="mx-3 mt-2">
                  {form && form.photo
                    ? form.photo.name
                      ? form.photo.name
                      : "file.jpg"
                    : ""}
                </h4>
                <label
                  className="w-25 d-flex align-items-center justify-content-center"
                  style={{ borderLeft: "1px solid #CED4DA", cursor: "pointer" }}
                >
                  <h5
                    style={{
                      marginLeft: "35%",
                      marginTop: "10px",
                      color: "#FF2B93",
                    }}
                  >
                    {form.photo ? "Ubah" : "Upload"}
                  </h5>
                  <input
                    type="file"
                    style={{ visibility: "hidden" }}
                    onChange={onSelectFile}
                  ></input>
                </label>
              </div>

              {preview && (
                <div className="w-100 d-flex flex-column justify-content-center align-items-center rounded mt-2">
                  <img
                    src={preview}
                    alt="preview"
                    style={{
                      cursor: "pointer",
                      width: "100%",
                      maxWidth: "200px",
                    }}
                  />
                </div>
              )}
            </>
          ) : preview ? (
            <div className="w-100 d-flex flex-column justify-content-center align-items-center rounded">
              <img
                src={preview}
                alt="preview"
                style={{ cursor: "pointer", width: "100%", maxWidth: "200px" }}
              />
            </div>
          ) : (
            <div
              className="w-100 d-flex flex-column justify-content-center align-items-center rounded"
              style={{ height: "240px", border: "1px dotted #BBBBBB" }}
            >
              <label
                className="d-flex flex-column  align-items-center justify-content-center mt-3"
                style={{ cursor: "pointer" }}
              >
                <img
                  src={icon_upload_profil}
                  alt=""
                  style={{ cursor: "pointer" }}
                />
                <h6
                  className="mt-2"
                  style={{ color: "#FF1DBC", cursor: "pointer" }}
                >
                  Upload Foto Profil
                </h6>
                <input
                  type="file"
                  style={{ visibility: "hidden" }}
                  onChange={onSelectFile}
                ></input>
              </label>
            </div>
          )}
          <h6 className="mt-2" style={{ color: "#666666" }}>
            *Upload foto maksimal 5 Mb
          </h6>
          {!isMobile && preview && (
            <>
              <label
                className="underline"
                style={{
                  color: "#CE1771",
                  fontSize: "13px",
                  cursor: "pointer",
                }}
              >
                Ganti Foto Profil
                <input
                  type="file"
                  style={{ visibility: "hidden" }}
                  onChange={onSelectFile}
                ></input>
              </label>
            </>
          )}
        </Col>
      </Row>
    </>
  );
});

export default UserAccountForm;
