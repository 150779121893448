import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import SideInfo from "./SideInfo";
import AccountApi from "../../api/auth-api";
import { message } from "antd";
import { useHistory } from "react-router-dom";
import { resetAuth, setAuth } from "../../redux/action/auth_action";
import useBreakpoint from "../../components/responsiveUtils/useBreakpoint";

// modals
import Email from "../../components/modalProfile/Email";
// import PhoneNumber from "../../components/modalProfile/PhoneNumber";
import PhoneNumber from "../../components/modalProfile/PhoneNumberOtp";
import Password from "../../components/modalProfile/Password";
import Birthdate from "../../components/modalProfile/Birthdate";
import IdentityCard from "../../components/modalProfile/IdentityCard";
import CompanyData from "../../components/modalProfile/CompanyData";
import Address from "../../components/modalProfile/Address";

// icon
import { MdChevronRight, MdEmail } from "react-icons/md";
import icon_user from "../../assets/images/icon-user.svg";
import icon_pin_colored from "../../assets/images/icon_pin_colored.svg";
import Gender from "../../components/modalProfile/Gender";
import Name from "../../components/modalProfile/Name";
import Npwp from "../../components/modalProfile/Npwp";

const Profile = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const auth = useSelector((st) => st.authReducer);
  const { isMobile, isTablet, isDesktop } = useBreakpoint();

  const getUserInfo = async () => {
    try {
      const accountApi = new AccountApi();

      const result = await accountApi.checkToken(auth.refresh_token);
      if (result.status === "success") {
        const { data } = await accountApi.getDetailCustomer(auth.access_token);

        dispatch(setAuth({ ...auth, ...data }));
      } else {
        history.push("/login");
        dispatch(resetAuth());
      }
    } catch (error) {
      console.log(error.response);
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <div
      style={{ width: "100%", padding: "30px" }}
      className="bg-ice min-vh-100"
    >
      <Row className="">
        <Col xs={12} lg={4}>
          <SideInfo />
        </Col>
        <Col className={`${isMobile && "mt-18rem"}`}>
          <h3 className="fw-bold mt-4">Profile</h3>
          <h6 className="text-muted">
            Atur informasi akun, profile dan verifikasi akun kamu sendiri
          </h6>
          <h5 className="fw-bold mt-4">Informasi Akun</h5>
          <div className="bg-white rounded-card w-full p-3">
            <Email />
            <hr className="my-2 text-muted" />
            <PhoneNumber />
            <hr className="my-2 text-muted" />
            <Password />
          </div>
          <h5 className="fw-bold mt-4">Data Diri</h5>
          <div className="bg-white rounded-card w-full p-3">
            <Name />
            <hr className="my-2 text-muted" />
            <Address />
            <hr className="my-2 text-muted" />
            <Birthdate />
            <hr className="my-2 text-muted" />
            <Gender />
          </div>
          <h5 className="fw-bold mt-4">Dokumen Identitas</h5>
          <IdentityCard />
          <Npwp />
          <CompanyData />
        </Col>
      </Row>
    </div>
  );
};

export default Profile;
