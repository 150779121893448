import React, { useState, useEffect } from "react";
import {
  Card,
  FormGroup,
  Form,
  InputGroup,
  Button,
  FloatingLabel,
} from "react-bootstrap";
import { message } from "antd";
import { useHistory } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import AuthApi from "../../api/auth-api";
import { setAuth } from "../../redux/action/auth_action";
import PinInput from "react-pin-input";
import Countdown from "react-countdown";

const TabOtp = () => {
  const auth = useSelector((st) => st.authReducer);
  const [countdown, setCountdown] = useState(Date.now() + 30000);
  const [otp_code, setOtpCode] = useState("");
  const history = useHistory();

  const Completionist = () => (
    <h6
      className="text-primary underline pointer"
      onClick={() => handleResendOtp()}
    >
      Kirim ulang kode
    </h6>
  );

  // Renderer callback with condition
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <h6 className="text-muted">Kirim ulang kode dalam {seconds} detik</h6>
      );
    }
  };

  const handleResendOtp = async () => {
    const api = new AuthApi();

    const otpRes = await api.otpWithRequestToken();

    if (otpRes.status === "success") {
      message.success(otpRes.message);

      setCountdown(Date.now() + 30000);
    } else {
      message.error(otpRes.message);
    }
  };

  const validateOtp = async () => {
    const api = new AuthApi();

    let payload = {};
    payload["otp_code"] = otp_code;

    const otpRes = await api.validateOtpWithRequestToken({ ...payload });
    if (otpRes.status === "success") {
      message.success("Verification success");
      history.push("/login");
    } else {
      message.error("error send otp");
    }
  };

  return (
    <>
      {auth.access_token === null ? (
        <Card.Body className="pt-0">
          <strong className="h2 fw-bold">Verifikasi Kode Otp</strong>
          <h3 className="mt-2">Masukkan kode Otp yang dikirim ke nomor Anda</h3>
          <div className="d-flex justify-content-center align-items-center flex-column">
            <PinInput
              length={6}
              initialValue=""
              secret={false}
              onChange={(value, index) => {
                setOtpCode(value);
              }}
              type="numeric"
              inputMode="number"
              style={{ padding: "10px" }}
              inputStyle={{ borderColor: "#adb5bd", borderRadius: "10px" }}
              inputFocusStyle={{ borderColor: "#27c0cd" }}
              onComplete={(value, index) => {}}
              autoSelect={true}
              regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
            />
            <Countdown key={countdown} date={countdown} renderer={renderer} />
          </div>
          <Button
            size="sm"
            variant="primary"
            className="w-100 mt-3 rounded-pill px-4 py-2"
            onClick={validateOtp}
          >
            Submit
          </Button>
        </Card.Body>
      ) : null}
    </>
  );
};

export default TabOtp;
