import React from "react";

/*======================== Third Party ======================== */
import {
  Form,
  InputGroup,
  FloatingLabel,
} from "react-bootstrap";
import { FaSearch } from "react-icons/fa";

/*======================== Utils ======================== */
import useBreakpoint from "../../../components/responsiveUtils/useBreakpoint";


const SearchByNoOrder = ({handleSearch}) => {
    const { isDesktop } = useBreakpoint();

    return(
        <div style={{width:`${isDesktop? "27%" : "85%"}`}}>
            <InputGroup className="border rounded-card overflow-hidden">
                <FloatingLabel
                    className="rounded-card w-75"
                    label={"Cari No. Transaksi Disini"}
                    style={{ width: "", fontSize: "12px", height: "50px" }}
                >
                    <Form.Control
                    className="border-0"
                    type="text"
                    style={{ height: "50px" }}
                    placeholder={"Cari No. Transaksi Disini"}
                    onChange={(ev) => handleSearch(ev)}
                    />
                </FloatingLabel>
                <InputGroup.Text
                    className="border-0 search-icon-trx ms-auto"
                    style={{ height: "50px" }}
                >
                    <FaSearch />
                </InputGroup.Text>
            </InputGroup>
        </div>
    );
}

export default SearchByNoOrder;
