import React from "react";
import { Card } from "react-bootstrap";
import EWallet from "./components/EWallet";
import Retail from "./components/Retail";
import QRIS from "./components/QRIS";
import VA from "./components/VA";
import OnAccount from "./components/OnAccount";
import OnAccountV2 from "./components/OnAccountV2";
import { connect } from "react-redux";
import useBreakpoint from "../responsiveUtils/useBreakpoint";

function PaymentPending(props) {
  const showActivePayment = () => {
    let result = [];
    switch (props.activePaymentReducer.payment_type) {
      case 1:
        result.push(
          <div>
            <VA />
          </div>
        );
        break;
      case 2:
        result.push(
          <div>
            <VA />
          </div>
        );
        break;
      case 3:
        result.push(
          <div>
            <EWallet />
          </div>
        );
        break;
      case 4:
        result.push(
          <div>
            <QRIS />
          </div>
        );
        break;
      case 5:
        result.push(
          <div>
            <Retail />
          </div>
        );
        break;
      case 6:
        result.push(<div></div>);
        break;
      case 7:
        if (props.contract && props.contract === "1") {
          result.push(
            <div className="">
              <OnAccount />
            </div>
          );
        } else {
          result.push(
            <div className="">
              <OnAccountV2 />
            </div>
          );
        }
        break;
      default:
        result.push(
          <div>
            <OnAccountV2 />
          </div>
        );
        break;
    }
    return result;
  };

  const { isMobile } = useBreakpoint();

  return (
    <Card className={`${isMobile ? "card-mobile" : "card-absolute"}`}>
      <Card.Body className="scrollable">{showActivePayment()}</Card.Body>
    </Card>
  );
}

const mapStateToProps = (state) => ({
  map: state.map,
  activePaymentReducer: state.activePaymentReducer,
  contract: state.kirimbarangReducer.contract,
});
export default connect(mapStateToProps)(PaymentPending);
