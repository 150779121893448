import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  InputGroup,
  Button,
  Row,
  Badge,
  FloatingLabel,
  Col,
  OverlayTrigger,
  Tooltip,
  Popover 
} from "react-bootstrap";
import { Switch, Checkbox } from "antd";
import { MdOutlineEditNote } from "react-icons/md";
import { FiInfo } from "react-icons/fi";
import util from "../../helper/util";
import { setBackToPickupAddress, updateBackToPickupAddress } from "../../redux/action/kirimbarang_action";
import { useDispatch, useSelector } from "react-redux"; //erick (tambah use Selector)
import useBreakpoint from "../responsiveUtils/useBreakpoint";
import { message } from "antd";

// images
import iconHome from "../../assets/images/icon-home.svg";
import iconUser from "../../assets/images/icon-user.svg";
import iconPhone from "../../assets/images/icon-phone.svg";
import iconDocs from "../../assets/images/icon-docs.svg";
import iconRP from "../../assets/images/icon-rp.svg";
import iconLocation from "../../assets/images/location.svg";
import iconParagraph from "../../assets/images/paragraph.svg";
import iconArrowRight from "../../assets/images/arrowRight.svg";
import iconDot from "../../assets/images/dot.svg";


function SearchAddress(props) {
  // console.log(props,"===> ini data props halamn detail address")
  const dispatch = useDispatch();
  const { isMobile } = useBreakpoint();
  const [show, setShow] = useState(false);
  const [cod, setCod] = useState(props.value.isCod);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    if (!props.disabled) {
      setShow(true);
    }
  };

  const address = useSelector(state=> state.kirimbarangReducer.pengambilan_new)
  const backToPickupAddress = useSelector(state=> state.kirimbarangReducer.backToPickupAddress)
  const [valDropOff, setValDropOff] = useState(props.value.dropoff_reference)
  const [allPickUpAddres, setAllPickUpAddres] = useState(true)
  const [detail, updateDetail] = useState({
    namaPenerima: props.value.namaPenerima,
    catatan: props.value.catatan,
    nomorTelepon: props.value.nomorTelepon,
    patokan: props.value.patokan,
    isCod: props.value.isCod,
    nominalCod: props.value.nominalCod,
    item_type: props.value.item_type,
    dropoff_reference: props.value.dropoff_reference, // erick
    titikBalik: props.value.titikBalik
  });
  const [turningPoints, setTurningPoints] = useState(backToPickupAddress)
  const [validasiTelepon, setValidasiTelepon] = useState(false)
  
  const handleInput = () => {
    if(validasiTelepon){ // cek nomor telphone
      message.error('Nomor Telepon dimulai angka "8". Minimal 8 Karakter Maximal 14 Karakter.')
    }else if(!detail.item_type && props.title === "Detail Pengambilan" && props.order === "SEND"){ // item type
      message.error('Silahkan input jenis barang terlebih dahulu..')
    }else{
      // cek ada COD atau tidak
      if(detail.isCod){
        turningPoints['toggled'] = detail.isCod // tambah 1 propeti untuk prose di file index keirim barang
        dispatch(updateBackToPickupAddress(turningPoints)) // copy data pengambilan dan paste di bactopickupAddress
      }else{
        setTurningPoints({}) // reset state turning points
      }
      // End cek ada COD atau tidak

      if (props.updateProps) {
        props.updateProps(detail);
      }
      handleClose();
    }
  
  };

  const itemTypeSuggestions = [
    {
      id: 1,
      name: "Elektronik",
    },
    {
      id: 2,
      name: "Makanan & Minuman",
    },
    {
      id: 3,
      name: "Pakaian & Aksesoris",
    },
    {
      id: 4,
      name: "Kesehatan",
    },
    {
      id: 5,
      name: "Otomotif",
    },
    {
      id: 6,
      name: "Alat Musik",
    },
    {
      id: 7,
      name: "Kecantikan",
    },
    {
      id: 8,
      name: "Konstruksi",
    },
    {
      id: 9,
      name: "Lainnya",
    },
  ];

  const handleOnClickSuggestion = (value) => {
    let itemTypeStr = detail.item_type;
    itemTypeStr = `${value}`;
    updateDetail({
      ...detail,
      item_type: itemTypeStr,
    });
  };

  const handlePickUpAllAddres = () => {
    var dropOff
    if(allPickUpAddres){
      setAllPickUpAddres(false)
      dropOff = []
    }else{
      dropOff = []
      address.forEach((e,i)=>{
        dropOff.push(i)
      })
      setAllPickUpAddres(true)
    }
    updateDetail({
      ...detail,
      dropoff_reference: dropOff
    });
    setValDropOff(dropOff)      
  }
  
  const handlePickUpAddres =(index) => {
    // for add data to dropoff_reference 
    var dropOff = detail.dropoff_reference
    let trigger =  false
    for(let i=0; i<dropOff.length; i++){
      if(dropOff[i] === index){
        trigger = true
        dropOff.splice(i,1)
        break;
      }
    }
    if(!trigger){
      dropOff.push(index)
    }
    updateDetail({
      ...detail,
      dropoff_reference: dropOff
    });
    // End add data to dropoff_reference
  }

  useEffect(()=>{
    var regexTelepon = new RegExp(/(^8)(\d{7,13})$/)
    var result = regexTelepon.test(detail.nomorTelepon)
    if(result){
      setValidasiTelepon(false)
    }else{
      setValidasiTelepon(true)
    }
  },[detail.nomorTelepon])

  const popover = (
    <Popover id="popover-basic">
      {/* <Popover.Header as="h3">Titik Balik COD</Popover.Header> */}
      <Popover.Body 
        style={{
          // color:"#CE1771",
          color:"#808080",
        }}
      >
        COD sudah di aktifkan di alamat pengiriman lain. <br></br><strong>(Non aktifkan terlebih dahulu COD tersebut)</strong> untuk mengaktifkan COD di alamat pengiriman ini.
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      <Form.Group className="form-group" onClick={handleShow}>
        {props.value.namaPenerima === "" ? (
          <Form.Control
            type="text"
            placeholder={props.placeholder}
            className="form-white"
            readOnly
          />
        ) : (
          <div
            className={`${
              props.disabled ? "filled-disabled" : "filled"
            } bot d-flex justify-content-between align-items-end`}
          >
            <div style={{marginRight:"-5px"}}>
              <img src={iconParagraph} className="icon-form" alt="" />
            </div>
            <div className="text-muted">
              <p>
                {props.value.namaPenerima.length > 10 ? `${props.value.namaPenerima.substr(0,10)}...` : props.value.namaPenerima}{" "}
              </p>
            </div>
            <div className="text-muted">
              <img src={iconDot} className="icon-form" alt="" />
            </div>
            <div className="text-muted">
              <p>
                +62 {props.value.nomorTelepon}
              </p>
            </div>
            {props.value.item_type && 
            <div className="text-muted">
              <img src={iconDot} className="icon-form" alt="" />
            </div>
            }
            {!props.addressPickUp ? (
              <div className="text-muted">
                <p>
                  {props.value.item_type && props.value.item_type.length > 10 ? `${props.value.item_type.substr(0,10)}...` : props.value.item_type}{" "}
                </p>
              </div>
            ) :
            (
              <div className="text-muted">
                <p>
                Tagih: {props.value.nominalCod ? `Rp. ${props.value.nominalCod}` : '-'}
                </p>
              </div>
            )}
            
            <div className="opacity-50">
              {/* <MdOutlineEditNote /> */}
              <img src={iconArrowRight} className="icon-form" alt="" />
            </div>
          </div>
        )}
      </Form.Group>

      <Modal
        contentClassName=""
        show={show}
        onHide={handleClose}
        className=""
        centered
      >
        <Modal.Header closeButton className="bg-white">
          <strong>{props.title}</strong>
        </Modal.Header>

        {/* Pengambilan dari Alamat */}
        {props.addressPickUp && (
        <InputGroup className="cstm-input"> {/* ERrick */}
          <InputGroup.Text className="border-0">
            <img src={iconLocation} className="icon-form" alt="" />
          </InputGroup.Text>
          <FloatingLabel
            label="Pengambilan dari Alamat"
            style={{ width: isMobile ? "88.5%" : "92.2%", fontSize: "12px"}}
          >
            <div style={{marginTop:'30px', marginBottom:'15px', marginLeft:"8px"}}>
            
            { address.length > 1 && 
            <button onClick={()=>handlePickUpAllAddres()} 
                    style={{border:`2px solid ${valDropOff && valDropOff.length === address.length ? '#27C0CD' : '#D5D6D6'}`,
                    color:`${valDropOff && valDropOff.length === address.length ? '#27C0CD' : '#D5D6D6'}`,
                    backgroundColor:`${valDropOff && valDropOff.length === address.length ? 'white' : '#F0F0'}`,
                    borderRadius:"20px",
                    marginRight:"6px",
                    padding:"3px 10px"}}>
              Semua Alamat
            </button> }
            { address.map((data, index) => 
            (<button
              onClick={()=> handlePickUpAddres(index)}
              style={{border:`2px solid ${valDropOff && valDropOff.includes(index) ? '#27C0CD' : '#D5D6D6'}`,
                      color:`${valDropOff && valDropOff.includes(index) ? '#27C0CD' : '#D5D6D6'}`, 
                      backgroundColor:`${valDropOff && valDropOff.includes(index) ? 'white' : '#F0F0'}`, 
                      borderRadius:"20px", 
                      marginRight:"6px", 
                      padding:"3px 10px", 
                      marginTop:'8px'}}
              >
              Alamat {index + 1}
            </button>)
            )
            }
            </div>
          </FloatingLabel>
        </InputGroup>
        )}
        {/* End Pengambilan dari Alamat */}

        <InputGroup className="cstm-input border-top-1">
          <InputGroup.Text className="border-0">
            <img src={iconUser} className="icon-form" alt="" />
          </InputGroup.Text>
          <FloatingLabel
            label="Nama (Maximal 25 Karakter)"
            style={{ width: isMobile ? "88.5%" : "92.2%", fontSize: "12px" }}
          >
            <Form.Control
              className="border-0"
              type="text"
              placeholder="Nama"
              onChange={(e) => {
                updateDetail({
                  ...detail,
                  namaPenerima: e.target.value,
                });
              }}
              value={detail.namaPenerima}
            />
          </FloatingLabel>
        </InputGroup>
        <InputGroup className="cstm-input border-top-1">
          <InputGroup.Text className="border-0">
            <img src={iconPhone} className="icon-form" alt="" />
            <span className="ms-2 fw-bold fs-13">+62</span>
          </InputGroup.Text>
          <FloatingLabel
            label={validasiTelepon ? "Nomor Telepon (Minimal 8 Maximal 14 Karakter)" : "Nomor Telepon"}
            style={{ width: isMobile ? "79%" : "85.7%", fontSize: "12px" }}
          >
            <Form.Control
              className="border-0"
              type="number"
              placeholder="Nomor Telepon"
              value={detail.nomorTelepon.replace(/^0+/, "")}
              onChange={(e) => {
                let value = e.target.value + "";
                if (value.length <= 15) {
                  updateDetail({
                    ...detail,
                    nomorTelepon: e.target.value,
                  });
                }
              }}
            />
          </FloatingLabel>
        </InputGroup>

        <InputGroup className="cstm-input border-top-1">
          <InputGroup.Text className="border-0">
            <img src={iconHome} className="icon-form" alt="" />
          </InputGroup.Text>
          <FloatingLabel
            label="Detail lokasi (Opsional)"
            style={{ width: isMobile ? "88.5%" : "92.2%", fontSize: "12px" }}
          >
            <Form.Control
              className="border-0"
              as="textarea"
              placeholder="Detail lokasi (Opsional)"
              style={{ height: "60px" }}
              value={detail.patokan}
              onChange={(e) => {
                updateDetail({
                  ...detail,
                  patokan: e.target.value,
                });
              }}
            />
          </FloatingLabel>
        </InputGroup>
        <InputGroup className="cstm-input border-top-1">
          <InputGroup.Text className="border-0">
            <img src={iconDocs} className="icon-form" alt="" />
          </InputGroup.Text>
          <FloatingLabel
            label="Catatan (Opsional)"
            style={{ width: isMobile ? "88.5%" : "92.2%", fontSize: "12px" }}
          >
            <Form.Control
              className="border-0"
              type="text"
              placeholder="Catatan (Opsional)"
              value={detail.catatan}
              onChange={(e) => {
                updateDetail({
                  ...detail,
                  catatan: e.target.value,
                });
              }}
            />
          </FloatingLabel>
        </InputGroup>
        {props.bill && address[0].lokasi ? (
          <div
            className="border-top-2 px-2 py-2 bg-white"
            style={{ height: "100%" }}
          >
            <span className="fs-13">Tagih Pembayaran Barang ke Penerima</span>
            <div className="float-end">
              {!props.value.titikBalik && props.checkTitikBalik.length ?
                (<OverlayTrigger trigger="click" placement="right" overlay={popover}>
                  <a>
                    <FiInfo className="lipstick pointer"/>
                  </a>
                </OverlayTrigger>)
              : null}{` `}
              <Switch
                disabled={props.value.titikBalik ? false : !props.checkTitikBalik.length ? false : true}
                onChange={() => {
                  setCod(!detail.isCod);
                  updateDetail({
                    ...detail,
                    isCod: !detail.isCod,
                  });
                  if (!detail.isCod) {
                    dispatch(setBackToPickupAddress("ON"));
                    updateDetail({...detail, isCod:true, titikBalik: true})
                  } else {
                    dispatch(setBackToPickupAddress("OFF"));
                    updateDetail({...detail, nominalCod: null, isCod:false, titikBalik: false})
                  }
                }}
                defaultChecked={detail.isCod}
              />
            </div>
          </div>
        ) : null}
        {cod ? (
          <>
            {/* Alamat Titik Balik COD */}
            <InputGroup className="cstm-input"> {/* ERrick */}
              <InputGroup.Text className="border-0">
                <img src={iconLocation} className="icon-form" alt="" />
              </InputGroup.Text>
              <FloatingLabel
                label="Alamat Titik Balik COD"
                style={{ width: isMobile ? "88.5%" : "92.2%", fontSize: "12px" }}
              >
                <Form.Control
                  disabled
                  style={{backgroundColor:"white"}}
                  className="border-0"
                  type="text"
                  placeholder="Alamat Titik Balik COD"
                  onChange={(e) => {
                    updateDetail({
                      ...detail,
                      alamaTitikBalik: e.target.value,
                    });
                  }}
                  value={detail.alamaTitikBalik}
                />
                <div style={{marginTop:'-25px', marginBottom:'15px', marginLeft:"8px"}}>
                  {  address.map((data, index) => 
                    (
                      <button
                        onClick={()=> setTurningPoints(data)}
                        // #27C0CD #D5D6D6 #F0F0 white
                        style={{border:`2px solid ${JSON.stringify(turningPoints) === JSON.stringify(data) ? '#27C0CD' : '#D5D6D6'}`,
                                color:`${JSON.stringify(turningPoints) === JSON.stringify(data) ? '#27C0CD' : '#D5D6D6'}`, 
                                backgroundColor:`#F0F0`, 
                                borderRadius:"20px", 
                                marginRight:"6px", 
                                padding:"3px 10px", 
                                marginTop:'8px'}}
                      >
                        Alamat {index + 1}
                      </button>
                    ))
                  }
                </div>
              </FloatingLabel>
            </InputGroup>
            {/* End Alamat Titik Balik COD */}

            {/* Nominal yang di Tagih */}
            <InputGroup className="cstm-input border-top-1">
              <InputGroup.Text className="border-0">
                <img src={iconRP} className="icon-form" alt="" />
                <span className="ms-2 fw-bold fs-13">Rp</span>
              </InputGroup.Text>
              <FloatingLabel
                label="Nominal yang ditagih"
                style={{ width: isMobile ? "82%" : "86.9%", fontSize: "12px" }}
              >
                <Form.Control
                  className="border-0"
                  type="text"
                  placeholder="Nominal yang ditagih"
                  value={util.currencySeparator(detail.nominalCod)}
                  onChange={(e) => {
                    let value = e.target.value + "";
                    const removeNonNumeric = (num) =>
                      num.toString().replace(/[^0-9]/g, "");
                    if (removeNonNumeric(value).length <= 9) {
                      updateDetail({
                        ...detail,
                        nominalCod: removeNonNumeric(value),
                      });
                    }
                  }}
                />
              </FloatingLabel>
            </InputGroup>
            {/* End Nominal yang di Tagih */}
          </>
        ) : null}
        {props.showItemType && (
          <InputGroup className="cstm-input border-top-1">
            <InputGroup.Text className="border-0">
              <img src={iconDocs} className="icon-form" alt="" />
            </InputGroup.Text>
            <FloatingLabel
              label="Jenis Barang"
              style={{ width: isMobile ? "88.5%" : "92.2%", fontSize: "12px" }}
            >
              <Form.Control
                className="border-0"
                type="text"
                placeholder="Jenis Barang"
                value={detail.item_type}
                onChange={(e) => {
                  updateDetail({
                    ...detail,
                    item_type: e.target.value,
                  });
                }}
              />
            </FloatingLabel>
          </InputGroup>
        )}
        {props.showItemType && detail.item_type && (
          <Col className="ps-4 pe-4 gx-4 bg-white border-top-1 pt-1 pb-1">
            {itemTypeSuggestions.map((each, i) => (
              <div
                className="px-2 border-1 d-inline-block rounded pointer mx-1 my-1 suggestion-pill"
                onClick={() => handleOnClickSuggestion(each.name)}
              >
                {each.name}
              </div>
            ))}
          </Col>
        )}
        <Modal.Footer className="border-top-0 justify-content-between mx-1 bg-ice">
          <Checkbox>Simpan alamat</Checkbox>
          <Button
            size="sm"
            variant="primary"
            className="float-end rounded-pill px-3"
            onClick={() => {
              handleInput();
            }}
          >
            Simpan
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SearchAddress;
