import { BaseApiRequest } from "../base-api";
import { message } from "antd";
import axios from "axios";

export default class ParterManagementApi extends BaseApiRequest {
  constructor() {
    super(process.env.REACT_APP_API_END_POINT);
  }

  async getPartnerList(token, params) {
    let result;
    try {
      const response = await axios.get(`${this.endpoint}/customer/partner`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });

      result = response.data;
    } catch (e) {
      // message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async getAvailablePartnerList(token, params) {
    let result;
    try {
      // const response = await axios.get(`${this.endpoint}/customer/partner`, {
      const response = await axios.get(`${this.endpoint}/customer/partner/available`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });

      result = response.data;
    } catch (e) {
      // message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async getPartnerProfile(token, id) {
    let result;
    try {
      const response = await axios.get(
        `${this.endpoint}/customer/partner/${id}/profile`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      result = response.data;
    } catch (e) {
      // message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async getPartnerVehicle(token, id, area_id) {
    let result;
    try {
      const response = await axios.get(
        `${this.endpoint}/customer/partner/${id}/vehicles?limit=1000&page=1&sort_by=id&order_by=ASC`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      result = response.data;
    } catch (e) {
      // message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }
  
  async getPartnerVehicleRented(token, id, area_id) {
    let result;
    try {
      const response = await axios.get(
        `${this.endpoint}/customer/partner/${id}/vehicles/rented?limit=1000&page=1&sort_by=id&order_by=ASC`,
        // `${this.endpoint}/customer/vehicle?page=1&limit=1000&unit_type=0`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      result = response.data;
    } catch (e) {
      // message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async getPartnerVehicleContract(token, id, customer_id) {
    let result;
    try {
      const response = await axios.get(
        `${this.endpoint}/customer/partner/${id}/vehicles?limit=1000&page=1&sort_by=id&order_by=ASC&only_have_procurement_with_customer_id=${customer_id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      result = response.data;
    } catch (e) {
      // message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async getDetailVehicle(token, id, id_vehicle) {
    let result;
    try {
      const response = await axios.get(
        `${this.endpoint}/customer/partner/${id}/vehicles/${id_vehicle}/detail`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      result = response.data;
    } catch (e) {
      // message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async savePartner(token, body) {
    let result;

    try {
      let resApi = await axios.post(
        `${this.endpoint}/customer/partner/save-partner`,
        body,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      result = resApi.data;
    } catch (e) {
      console.log(e.response);
      message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async unsavePartner(token, id) {
    let result;

    try {
      let resApi = await axios.delete(
        `${this.endpoint}/customer/partner/unsave-partner`,
        {
          headers: { Authorization: `Bearer ${token}` },
          data: {
            partnerId: id,
          },
        }
      );

      result = resApi.data;
    } catch (e) {
      console.log(e.response);
      message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async getProcurementPerPartner(token, params, id) {
    let result;
    try {
      const response = await axios.get(
        `${this.endpoint}/partner-management/partner-offer/partner/${id}/list-procurement`,
        {
          params,
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      result = response.data;
    } catch (e) {
      // message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async getLogNegotiationOffer(token, params, id_offer) {
    try {
      const response = await axios.get(
        `${this.endpoint}/partner-management/partner-offer/${id_offer}/negotiation-offer-log`,
        {
          params,
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    } catch (e) {
      throw e.response.data.message;
      // message.error(e?.response?.data?.message);
    }
  }

  async getLogNegotiationDocument(token, params, id_procurement, id_partner) {
    try {
      const response = await axios.get(
        `${this.endpoint}/partner-management/partner-offer/${id_procurement}/partner/${id_partner}/negotiation-document-log`,
        {
          params,
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data;
    } catch (e) {
      throw e.response?.data?.message;
      // message.error(e?.response?.data?.message);
    }
  }

  async getListDocument(token, idProcurement, idPartner) {
    let result;
    try {
      const response = await axios.get(
        `${this.endpoint}/partner-management/partner-offer/${idPartner}/procurement/${idProcurement}/list-document`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      result = response.data;
    } catch (e) {
      // message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async getHeaderInformation(token, id_partner_offer_header) {
    let result;
    try {
      const response = await axios.get(
        `${this.endpoint}/procurement/delivery/header/detail/${id_partner_offer_header}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      result = response.data;
    } catch (e) {
      // message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async getOfferListPerProcurement(token, params, id, id_procurement) {
    try {
      const response = await axios.get(
        `${this.endpoint}/partner-management/partner-offer/partner/${id}/list-route/procurement/${id_procurement}`,
        {
          params,
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data;
    } catch (e) {
      throw e.response.data;
    }
  }

  async getDetailPartnerOffer(token, id) {
    try {
      const response = await axios.get(
        `${this.endpoint}/procurement/delivery/routes/detail-partner-offer/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    } catch (e) {
      return e?.response?.data?.message;
      // message.error(e?.response?.data?.message);
    }
  }

  async getDetailPartnerOfferVehicle(token, id) {
    try {
      const response = await axios.get(
        `${this.endpoint}/partner-management/partner-offer/${id}/vehicle-offer`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    } catch (e) {
      throw e?.response?.data?.message;
      // message.error(e?.response?.data?.message);
    }
  }

  async requestUpdatePartnerOffer(token, body) {
    let result;

    try {
      let resApi = await axios.post(
        `${this.endpoint}/procurement/delivery/partner-offer/request-update-offer`,
        body,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      result = resApi.data;
    } catch (e) {
      console.log(e.response);
      message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async createLogNegotiationDocument(token, body) {
    try {
      let resApi = await axios.post(
        `${this.endpoint}/partner-management/partner-offer/document-log`,
        body,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return resApi.data;
    } catch (e) {
      throw e?.response?.data?.message;
    }
  }

  async createLogNegotiationOffer(token, body) {
    let result;

    try {
      let resApi = await axios.post(
        `${this.endpoint}/partner-management/partner-offer/offer-log`,
        body,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      result = resApi.data;
    } catch (e) {
      console.log(e.response);
      message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async requestUpdateDocument(token, idPartner, idProcurement) {
    try {
      let resApi = await axios.patch(
        `${this.endpoint}/partner-management/partner-offer/${idPartner}/procurement/${idProcurement}/update-doc/`,
        null,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return resApi.data;
    } catch (e) {
      console.log(e, "i");
      throw e?.response?.data?.message;
    }
  }

  async updatePartnerOffer(token, id_offer, body) {
    let result;
    try {
      let resApi = await axios.patch(
        `${this.endpoint}/procurement/delivery/routes/update-offer-status/${id_offer}`,
        body,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      result = resApi.data;
    } catch (e) {
      console.log(e.response);
      message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async getVehicleTypeList(token, id_procurement) {
    try {
      const response = await axios.get(
        `${this.endpoint}/partner-management/partner-offer/vehicle/list/${id_procurement}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    } catch (e) {
      throw e?.response?.data?.message;
    }
  }
}