import React, {useState} from "react";
import { Col } from "react-bootstrap";
import { Avatar } from "antd";
import { AiOutlineUser } from "react-icons/ai";
import{ MdImageNotSupported } from "react-icons/md";
import { BiChevronDown, BiChevronUp } from 'react-icons/bi'


const PartnerKendaraan = ({title, trxDetail, partnerDetail}) => {

    const [optVehicle, setOptVehicle] = useState(false)

    const partner = [
        {
            value: trxDetail?.data?.mst_driver?.name ? trxDetail?.data?.mst_driver?.name : "Menunggu Driver"
        },
        {
            value: trxDetail?.data?.mst_driver?.wa_number ? trxDetail?.data?.mst_driver?.wa_number : "-"
        },
    ]

    const kendaraan = [
        {
            value: trxDetail?.data?.trx_booking_rent?.trx_booking_rent_vehicle?.mst_vehicle?.plate_number ? trxDetail?.data?.trx_booking_rent?.trx_booking_rent_vehicle?.mst_vehicle?.plate_number : "-"
        },
        {
            value: trxDetail?.data?.trx_booking_rent?.trx_booking_rent_vehicle?.mst_vehicle?.mst_vehicle_subtype?.name ? trxDetail?.data?.trx_booking_rent?.trx_booking_rent_vehicle?.mst_vehicle?.mst_vehicle_subtype?.name : "-"
        },
    ]

    const mapingStatusKendaraan = (val) => {
        if(val === 0){
            return "Normal"
        } else{
            return "Pengganti"
        }       
    }

    const mapingColorStatusKendaraan = (val) => {
        if(val === 0){
            return {color:"#0FAA4B", backgroundColor:"#DBF2E4"}
        } else{
            return {color:"#FFAC14", backgroundColor:"#FFF3DC"}
        }       
    }

    const image = title === "Partner" ? 
        trxDetail?.data?.mst_driver?.profile_picture : 
        trxDetail?.data?.trx_booking_rent?.trx_booking_rent_vehicle?.mst_vehicle?.vehicle_picture_url[0]


    return(
        <Col className="rounded-card mb-1 mt-3 me-3 bg-white">
            <div className="pt-3 pb-2 border-bottom d-flex justify-content-between align-items-start">
                <h5 className="fw-bold">{title}</h5>
                { title === "Kendaraan" && 
                    <h6 className="px-2 py-1 m-0 rounded-pill" style={mapingColorStatusKendaraan(trxDetail?.data.trx_booking_rent?.trx_booking_rent_vehicle?.unit_type)}>{mapingStatusKendaraan(trxDetail?.data.trx_booking_rent?.trx_booking_rent_vehicle?.unit_type)}</h6>
                }
            </div>
            <div className="d-flex align-items-center my-3">
                {/* <div className="border me-2 rounded d-flex justify-content-center align-items-center" style={{width:"65px", height:"65px"}}>
                    EM
                </div> */}
                <Avatar
                    className="me-2 border rounded overflow-hidden"
                    style={{objectFit:"fill"}}
                    shape="square"
                    size={65}
                    src={image}
                    icon={
                        !image && <MdImageNotSupported />
                     }
                ></Avatar>
                <div>
                    <h6 className="text-muted">{title === "Partner" ? "Info Driver" : "Info Kendaraan"}</h6>

                    {title === "Partner" ?
                        partner.map((item,i)=>(
                            <h6 key={i}>{item.value}</h6>
                        )) :
                        kendaraan.map((item,i)=>(
                            <h6 key={i}>{item.value}</h6>
                        ))
                    }
                </div>
            </div>
            <div 
                className={`border-top pt-2 pb-1 d-flex justify-content-between position-relative ${title === "Kendaraan" && "pointer"}`}
                onClick={()=> setOptVehicle(!optVehicle)}
            >
                { title === "Partner" ?
                    <h6 className="fw-bold">{partnerDetail?.data?.name}</h6> :
                    <h6 className="fw-bold">{trxDetail?.data?.trx_booking_rent?.trx_booking_rent_vehicle?.mst_vehicle?.mst_vehicle_brand?.name}</h6>
                }
                {/* {title==="Kendaraan" && 
                    <BiChevronDown/>
                } */}
                {/* {optVehicle &&
                <div 
                    className="w-100 border m-0 p-0 position-absolute bg-white px-3 py-2" 
                    style={{
                        top:42, borderRadius:"15px", 
                    // boxShadow:"1px 1px 1px 1px #888888"
                }}
                > 
                    <h6 className="m-0 mb-2">Avanza</h6>
                    <h6 className="m-0 mb-2">Jaguar</h6>
                    <h6 className="m-0 mb-2">Ferari</h6>
                </div>
                } */}
            </div>
        </Col>
    );
}

export default PartnerKendaraan;
