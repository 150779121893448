import {
  Card,
  Row,
  Col,
} from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import useBreakpoint from "../../../components/responsiveUtils/useBreakpoint";
import {
  getRoleList,
  getAccessRoleList,
} from "../../../redux/action/usermanagement_action";
import { useDispatch, useSelector } from "react-redux";
import { resetAuth } from "../../../redux/action/auth_action";
import UserMgtApi from "../../../api/user-management.api";
import { message } from "antd";
import RoleList from "./RoleList";
import RoleInputForm from "./RoleInputForm";

const RoleContent = () => {
  const dispatch = useDispatch();
  const childRef = useRef(null);
  const auth = useSelector((st) => st.authReducer);

  const { isMobile, isTablet } = useBreakpoint();
  const [dataForm, setDataForm] = useState({
    id: '',
    name: '',
    role_code: '',
    type: 'CUSTOMER',
    description: '',
    access: '',
  });

  const handleClick = () => {
    childRef.current.handleInput();
  }

  const submit = (data) => {
    createRole(data);
  }

  const createRole = async (data) => {
    try {
      const api = new UserMgtApi()
      const response = await api.createRole(auth.access_token, data);

      if (response.status === 401) {
        dispatch(resetAuth());
      } else if (response.status === "success") {
        message.success("Role Berhasil Dibuat");

        setDataForm({
          id: '',
          name: '',
          role_code: '',
          type: 'CUSTOMER',
          description: '',
          access: []
        });
      }

    } catch (error) {
      console.log(error?.response);
    }
  };


  useEffect(() => {
    dispatch(getRoleList(auth.access_token));
  });

  useEffect(() => {
    dispatch(getAccessRoleList(auth.access_token));
  });

  return (
    <>
      {isMobile ? (
        <Row style={{ marginTop: "150px" }}>
          <Col lg={12} md={12} sm={12}>
            <RoleList />
          </Col>
        </Row>
      )
        : isTablet ? (
          <Row>
            <Col lg={12} md={12} sm={12}>
              <RoleList />
            </Col>
          </Row>
        ) : (
          <Row>
            <Col lg={6}>
              <RoleList />
            </Col>
            <Col lg={6}>
              <div className="px-3 py-2 mt-2">
                <h3>Tambah Role</h3>
                <h5>Silahkan tambah role sesuai kebutuhan Perusahaan Anda.</h5>
              </div>
              <Card className="rounded-card bg-white p-4 mb-3 border-0">
                <RoleInputForm ref={childRef} submit={submit} dataForm={dataForm} />
                <hr />
                {/* justify-content-between align-items-end */}
                <div className="d-flex flex-row-reverse">
                  <button className="btn btn-primary rounded-pill float-end"
                    onClick={() => {
                      handleClick();
                    }}>
                    Tambah Role
                  </button>
                </div>
              </Card>
            </Col>
          </Row>
        )
      }
    </>
  )
}

export default RoleContent;
