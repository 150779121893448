/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Button, Dropdown, Tab, Tabs, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getProcurementContractListDetail } from "../../../redux/action/Management/procurement_action";
import noData from "../../../assets/images/no-data.png";
import CustomTable from "../../../components/table/customTable";
import { Spin } from "antd";
import { formatThousand } from "../../../helper/formatCurrency";

const PenawaranKontrakListDetail = (props) => {
  /*======================== Props ======================== */

  const { search } = props;

  /*======================== Hooks ======================== */

  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  /*======================== Redux ======================== */

  const loading = useSelector((st) => st.procurementReducer.loading);
  const data = useSelector(
    (st) => st.procurementReducer.contract_list_detail.data
  );
  const meta = useSelector(
    (st) => st.procurementReducer.contract_list_detail.meta
  );

  /*======================== Form ======================== */

  /*======================== UseState ======================== */

  const [params, setParams] = useState({
    id,
    limit: 10,
    page: 1,
    search: search || undefined,
    sort_by: "created_at",
    order_by: "DESC",
  });
  const [sort, setSort] = useState("Terbaru");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [vehicle, setVehicle] = useState("");

  /*======================== Handler ======================== */

  const handleLoad = () => {
    if (search) {
      setPage(1);
      dispatch(
        getProcurementContractListDetail({ ...params, search, page: 1 })
      );
    } else {
      dispatch(getProcurementContractListDetail({ ...params }));
    }
  };

  const handleChange = (type, value) => {
    switch (type) {
      case "sort":
        setPage(1);
        setSort(value);
        setParams({
          ...params,
          sort_by: "created_at",
          order_by: value === "Terbaru" ? "DESC" : "ASC",
          page: 1,
        });
        break;
      case "page":
        setPage(value);
        setParams({ ...params, page: value });
        break;
      case "limit":
        setPage(1);
        setLimit(value);
        setParams({ ...params, limit: value, page: 1 });
        break;

      case "vehicle":
        setPage(1);
        setVehicle(value);
        setParams({ ...params, vehicle: value ? value : undefined, page: 1 });
        break;

      default:
        break;
    }
  };

  /*======================== UseEffect ======================== */

  useEffect(() => {
    handleLoad();
  }, [params, search]);

  /*======================== Others ======================== */

  const vehicleOptions = [
    {
      title: "Semua",
      value: "",
    },
    {
      title: "Tronton",
      value: "Tronton",
    },
    {
      title: "CDD Engkel Bak",
      value: "CDD Engkel Bak",
    },
    {
      title: "Small Box",
      value: "Small Box",
    },
  ];
  const sortOptions = [
    {
      title: "Terbaru",
    },
    {
      title: "Terlama",
    },
  ];

  const tableProps = {
    bordered: false,
    expandable: false,
    title: undefined,
    showHeader: true,
    rowSelection: false,
  };
  const tableColumns = [
    {
      title: "No",
      dataIndex: "no",
      sorter: false,
      className: "text-center px-3",
    },
    {
      title: "Kode Rute",
      dataIndex: "route_code",
      sorter: false,
      className: "px-3",
    },
    {
      title: "Rute Anda",
      dataIndex: "route_name",
      sorter: false,
      className: "px-3",
    },
    {
      title: "Kebutuhan Kendaraan",
      sorter: false,
      className: "px-3",
      render: (_, { vehicles }) => {
        return (
          <>
            <p className="mb-0">{vehicles[0].name}</p>
            <span>
              {vehicles[0].volume} m3 - {vehicles[0].tonage} kg
            </span>
            {vehicles.length > 1 && (
              <p className="label-more mb-0">+{vehicles.length - 1} Lainnya</p>
            )}
          </>
        );
      },
    },
    {
      title: "Total Kebutuhan",
      dataIndex: "vehicle_quantity_needed",
      sorter: false,
      className: "px-3",
      render: (_, { vehicle_quantity_needed }) => {
        return <p className="mb-0">{vehicle_quantity_needed} Kendaraan</p>;
      },
    },
    {
      title: "Total Budget",
      dataIndex: "route_budget",
      sorter: false,
      className: "px-3",
      render: (_, { route_budget }) => {
        return <p className="mb-0">Rp. {formatThousand(route_budget)}</p>;
      },
    },
    {
      title: "Partner Terpilih",
      dataIndex: "phone_number",
      sorter: false,
      className: "px-3",
      render: (_, { selected_partner }) => {
        return (
          <>
            {selected_partner?.length > 0 ? (
              <div className="selected_partner">
                <p className="mb-0">{selected_partner[0].name}</p>
                {selected_partner.length > 1 && (
                  <span className="label-more">
                    +{selected_partner.length - 1} Lainnya
                  </span>
                )}
              </div>
            ) : (
              <p className="mb-0 label-gray">Belum Terpilih</p>
            )}
          </>
        );
      },
    },
    {
      title: "Total Penawaran",
      dataIndex: "total_offer",
      sorter: false,
      className: "px-3",
      render: (_, { total_offer }) => {
        return <p className="mb-0">{total_offer} Tawaran</p>;
      },
    },
    {
      title: "Action",
      key: "action",
      sorter: false,
      className: "px-3",
      render: (_, { id }) => {
        return (
          <Button
            size="sm"
            className="fw-bold px-4 text-capitalize"
            onClick={() => history.push(location.pathname + "/rute/" + id)}
          >
            Detail
          </Button>
        );
      },
    },
  ];

  /*======================== Return ======================== */

  return (
    <div className="mt-4">
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <h2 className="fw-bold text-capitalize">Daftar Penawaran</h2>
          <p>List rute dan kendaraan yang anda tawarkan ke partner</p>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between mb-3">
        {/* <div className="d-flex" style={{ gap: "1rem" }}>
          <span className="fw-bold" style={{ width: "auto" }}>
            Tampilkan:
          </span>
          <Tabs
            defaultActiveKey={vehicle}
            className="tabs-rute mb-2 border-0"
            style={{ gap: "10px" }}
            onSelect={(value) => handleChange("vehicle", value)}
          >
            {vehicleOptions.map((item, index) => (
              <Tab key={index} eventKey={item.value} title={item.title} />
            ))}
          </Tabs>
        </div> */}
        <div className="d-flex">
          <h4 className="mt-2 me-2" style={{ fontWeight: "600" }}>
            Urutkan Berdasarkan :
          </h4>
          <Dropdown className="d-inline mx-2">
            <Dropdown.Toggle
              id="dropdown-autoclose-true"
              size="sm"
              variant="light"
              className="bg-white text-secondary px-3 border shadow-sm rounded"
            >
              <strong>{sort}</strong>
            </Dropdown.Toggle>

            <Dropdown.Menu className="dropdown-menu">
              {sortOptions.map((item, index) => (
                <Dropdown.Item
                  key={index}
                  href="#"
                  onClick={() => {
                    handleChange("sort", item.title);
                  }}
                >
                  {item.title}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      {data.length > 0 && (
        <div className="mt-2">
          <CustomTable
            classProps="table-alamat"
            {...{
              tableProps,
              tableColumns,
              handleChange,
              limit,
              page,
              loading,
              data,
            }}
            total_data={meta.total_data}
          />
        </div>
      )}
      {data.length < 1 &&
        (!loading ? (
          <div
            className="d-flex flex-column align-items-center justify-content-center"
            style={{ marginTop: "5rem" }}
          >
            <img src={noData} alt="no-data" />
            <p className="mt-1">Anda belum memiliki penawaran kontrak</p>
          </div>
        ) : (
          <Card
            className="p-4 rounded-card border-0 d-flex align-items-center justify-content-center mt-4"
            style={{
              height: "350px",
            }}
          >
            <Spin />
          </Card>
        ))}
    </div>
  );
};

export default PenawaranKontrakListDetail;
