/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Modal, Button, Card } from "react-bootstrap";
import { Calendar, utils } from "react-modern-calendar-datepicker";
import { MdDateRange } from "react-icons/md";
import moment from "moment";
import useBreakpoint from "../../../../components/responsiveUtils/useBreakpoint";
import { useEffect } from "react";

const DateRangePickerPenawaran = ({
  handleDateFilter,
  clearDateFilter,
  type,
  minDate,
  initialStart,
  initialEnd,
}) => {
  const { isMobile } = useBreakpoint();
  const [show, setShow] = useState(false);
  const [selectedDayRange, setSelectedDayRange] = useState({
    from: null,
    to: null,
  });

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handlePick = () => {
    let date = {
      from: {
        ...selectedDayRange.from,
        month: selectedDayRange.from?.month - 1,
      },
      to: { ...selectedDayRange.to, month: selectedDayRange.to?.month - 1 },
    };
    handleDateFilter({ ...date });
    handleClose();
  };

  const handleConvertDate = (date) => {
    return moment({ y: date.year, M: date.month - 1, d: date.day }).format(
      "DD/MMM/YYYY"
    );
  };
  const handleDisabledSubmit = () => {
    if (!selectedDayRange.from || !selectedDayRange.to) {
      return true;
    }
    return false;
  };
  useEffect(() => {
    if (initialStart && initialEnd) {
      setSelectedDayRange({ from: initialStart, to: initialEnd });
    }
  }, [initialStart, initialEnd]);

  return (
    <>
      <div className="d-flex">
        {type === "contract" && !minDate ? (
          <div className="d-flex w-100" style={{ cursor: "no-drop" }}>
            <h6 className="me-4 text-uppercase" style={{ marginTop: "13px" }}>
              {selectedDayRange.from
                ? handleConvertDate(selectedDayRange.from)
                : "DD/MMM/YYYY"}{" "}
              -{" "}
              {selectedDayRange.to
                ? handleConvertDate(selectedDayRange.to)
                : "DD/MMM/YYYY"}
            </h6>
            <MdDateRange className="text-primary mt-2" />
          </div>
        ) : (
          <div className="d-flex pointer w-100" onClick={handleShow}>
            <h6 className="me-4 text-uppercase" style={{ marginTop: "13px" }}>
              {selectedDayRange.from
                ? handleConvertDate(selectedDayRange.from)
                : "DD/MMM/YYYY"}{" "}
              -{" "}
              {selectedDayRange.to
                ? handleConvertDate(selectedDayRange.to)
                : "DD/MMM/YYYY"}
            </h6>
            <MdDateRange className="text-primary mt-2" />
          </div>
        )}
      </div>

      <Modal
        contentClassName={`${isMobile ? "" : "modal-80w"} p-1`}
        show={show}
        className=""
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton className="bg-white">
          <strong>Pilih Tanggal</strong>
        </Modal.Header>
        <Card.Body>
          <Calendar
            value={selectedDayRange}
            onChange={(data) => {
              console.log("data", data);
              setSelectedDayRange(data);
            }}
            colorPrimary="#27C0CD"
            colorPrimaryLight="#F3F8F8"
            shouldHighlightWeekends
            minimumDate={minDate}
          />
        </Card.Body>
        <Modal.Footer className="border-top-0 bg-ice">
          <Button
            size="md"
            variant="primary"
            className="fw-bold px-3 w-100"
            disabled={handleDisabledSubmit()}
            onClick={() => handlePick()}
          >
            Pilih Tanggal
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DateRangePickerPenawaran;
