import React from "react";

/*======================== Utils ======================== */
import useBreakpoint from "../../../../components/responsiveUtils/useBreakpoint";

/*======================== Componnents ======================== */
import { Button } from "react-bootstrap";
import { BsChevronDown, BsChevronUp } from "react-icons/bs"

/*======================== icon ======================== */
import iconDetail from "../../../../assets/images/icon_detail_MR_userManagment.svg";


const CardFooter = ({eachData, toggle, progress, goToPage}) => {
  const { isMobile } = useBreakpoint();

  /*======================== Return ======================== */

  return(
    <>
      <div className="d-flex px-2 justify-content-between align-items-center">
        <div className="d-flex skyblue pointer me-2">
          <h5 className="skyblue pointer me-2" onClick={toggle}>Tampilkan Info</h5>
          {progress === 1 ? <BsChevronUp/> : <BsChevronDown/> }
        </div>
        <Button
          hidden={isMobile}
          size="sm"
          className="fw-bold text-white py-2 px-3"
          style={{borderRadius:"10px"}}
          onClick={() => goToPage(eachData?.id)}
        >
          Detail Transaksi
        </Button>
        <img
          hidden={!isMobile}
          src={iconDetail}
          onClick={() => 
            goToPage(eachData?.id)
          }
          width="50" 
          alt="detail" 
          style={{ cursor: "pointer" }}
        />
      </div>
    </>
  );
}

export default CardFooter;