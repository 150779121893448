import { Row, Col } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import icon_truck from "../../../../assets/images/icon-truck.svg";
import { FaChevronRight } from "react-icons/fa";
import "../../style.scss";

const ModelKendaraan = (props) => {
  const { data } = props;

  /*======================== Hooks ======================== */
  const history = useHistory();
  const { id } = useParams();

  /*======================== UseState ======================== */

  /*======================== Handler ======================== */

  /*======================== UseEffect ======================== */

  /*======================== Others ======================== */

  const getImageKendaraan = () => {
    if (data.vehicle_picture) {
      let images = JSON.parse(data.vehicle_picture);

      return images[0];
    } else {
      return icon_truck;
    }
  };

  const getStatus = (value) => {
    switch (value) {
      case "WAITING_APPROVAL":
        return "WAITING APPROVAL";
      case "IN_PROGRESS":
        return "IN PROGRESS";
      default:
        return value;
    }
  };

  const getStatusColor = (value) => {
    switch (value) {
      case "WAITING_APPROVAL":
        return "#ffc82c";
      case "AVAILABLE":
        return "#1eaf56";
      case "IN_PROGRESS":
        return "#27c0cd";
      case "UPCOMING":
        return "#005158";
      case "UNAVAILABLE":
        return "#bbbbbb";
      case "REJECTED":
        return "#ff4949";
      case "SUSPENDED":
        return "#EE028C";
      default:
        return '#000';
    }
  };

  /*======================== Return ======================== */

  return (
    <div
      className="kendaraan-card"
      onClick={() => history.push("/partner/kendaraan/" + id + "/" + data.id)}
    >
      <Row>
        <Col xs={3}>
          <img
            className="mt-2"
            src={getImageKendaraan()}
            width="100%"
            alt="vehicle"
            style={{
              borderRadius: "10px",
            }}
          />
        </Col>
        <Col xs={9}>
          <p className="mb-0 title"> {data.mst_vehicle_brand?.name} </p>
          <p className="mb-2 subtitle gray"> {data.plate_number} </p>
          <p
            className="subtitle mb-1"
            style={{"color": getStatusColor(data.status)}}
          >
            {" "}
            {getStatus(data.status)}
          </p>
        </Col>
      </Row>
      <span className="icon-top-right">
        <FaChevronRight style={{ fontSize: "10px" }} />
      </span>
      <span className="icon-bottom-right subtitle gray">{data.year}</span>
    </div>
  );
};

export default ModelKendaraan;
