const initState = {
  center: {
    lat: -6.2087634,
    lng: 106.845599,
  },
  address: null,
  activeMenu: "KirimBarang",
  data: [],
};

export const map = (state = initState, action) => {
  switch (action.type) {
    case "SET_MARKER":
      return { ...state, ...action.payload };
    case "RESET_MAP":
      return { ...initState, ...action.payload };
    default:
      return state;
  }
};
