import { BaseApiRequest } from "./base-api";
import { message } from "antd";
import axios from "axios";

export default class UserMgtApi extends BaseApiRequest {
  constructor() {
    super(process.env.REACT_APP_API_END_POINT);
  }

  async getRoleList(token, sortBy = 'asc') {
    let result;
    try {
      let resApi = await axios.get(`${this.endpoint}/role/list?page=1&limit=500&sort=${sortBy}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      result = resApi.data;
    } catch (e) {
      console.log(e.response);
      message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async getAccessRoleList(token) {
    let result;
    try {
      let resApi = await axios.get(`${this.endpoint}/role/access/list`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      result = resApi.data;
    } catch (e) {
      console.log(e.response);
      message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async getRolebyName(token, name) {
    let result;
    try {
      let resApi = await axios.get(`${this.endpoint}/role?name=${name}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      result = resApi.data;
    } catch (e) {
      console.log(e.response);
      message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async createRole(token, body) {
    let result;
    try {
      let resApi = await axios.post(
        `${this.endpoint}/role`,
        {
          role_code: body.role_code,
          name: body.name,
          description: body.description,
          access_ids: String(body.access)
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      result = resApi.data;
    } catch (e) {
      console.log(e.response);
      message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async updateRole(token, body) {
    let result;
    try {
      let resApi = await axios.patch(
        `${this.endpoint}/role/${body.id}`,
        {
          role_code: body.role_code,
          name: body.name,
          type: body.type,
          description: body.description,
          access_ids: String(body.access)
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      result = resApi.data;
    } catch (e) {
      console.log(e.response);
      message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async getAccountList(token, sortBy = 'asc') {
    let result;
    try {
      let resApi = await axios.get(`${this.endpoint}/user/list?page=1&limit=500&sort=${sortBy}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      result = resApi.data;
    } catch (e) {
      console.log(e.response);
      message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async getAccountbyName(token, name) {
    let result;
    try {
      let resApi = await axios.get(`${this.endpoint}/user?name=${name}&sort=desc&page=1&limit=10`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      result = resApi.data;
    } catch (e) {
      console.log(e.response);
      message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async createAccount(token, body) {
    let result;

    try {
      let resApi = await axios.post(
        `${this.endpoint}/user`,body,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      result = resApi.data;
    } catch (e) {
      console.log(e.response);
      message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async updateAccount(token, id, body) {
    let result;
    try {
      let resApi = await axios.patch(
        `${this.endpoint}/user/${id}`, body,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      result = resApi.data;
    } catch (e) {
      console.log(e.response);
      message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }
}
