/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React from "react";
import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FiChevronRight } from "react-icons/fi";
import DateRangePickerPenawaran from "../Modal/DateRangePickerPenawaran";
import Partner from "../Modal/Partner";
import Payment from "../Modal/Payment";
import Area from "../Modal/Area";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getPaymentTenure } from "../../../../redux/action/Master/payment_action";
import { getPartnerSelectable } from "../../../../redux/action/Master/partner_action";
import { useParams } from "react-router-dom";

const InformasiUtama = (props) => {
  /*======================== Props ======================== */

  const {
    formValue,
    setFormValue,
    editorState,
    setEditorState,
  } = props;

  /*======================== Hooks ======================== */

  const params = useParams();
  const dispatch = useDispatch();

  /*======================== Redux ======================== */

  const tenureData = useSelector((st) => st.paymentReducer.tenure.data);
  const partnerData = useSelector((st) => st.partnerReducer.selectable.data);

  /*======================== Form ======================== */

  /*======================== UseState ======================== */

  const [openPartner, setOpenPartner] = useState(false);
  const [openArea, setOpenArea] = useState(false);
  const [openPayment, setOpenPayment] = useState(false);

  /*======================== Handler ======================== */

  const handleDateFilterOffer = (date) => {
    let dateQuery = {
      start_offering_date: moment({ ...date.from }).format('YYYY-MM-DD'),
      end_offering_date: moment({ ...date.to }).format('YYYY-MM-DD'),
    };

    setFormValue({ ...formValue, ...dateQuery });
  };

  const clearDateFilterOffer = () => {
    setFormValue({
      ...formValue,
      start_offering_date: undefined,
      end_offering_date: undefined,
    });
  };

  const handleDateFilterContract = (date) => {
    let dateQuery = {
      start_contract_date: moment({ ...date.from }).format('YYYY-MM-DD'),
      end_contract_date: moment({ ...date.to }).format('YYYY-MM-DD'),
    };
    setFormValue({ ...formValue, ...dateQuery });
  };

  const clearDateFilterContract = () => {
    setFormValue({
      ...formValue,
      start_contract_date: undefined,
      end_contract_date: undefined,
    });
  };

  const handleOpenPartner = () => {
    setOpenPartner(!openPartner);
  };

  const handleOpenArea = () => {
    setOpenArea(!openArea);
  };

  const handleOpenPayment = () => {
    setOpenPayment(!openPayment);
  };

  const handlePayment = (value) => {
    setOpenPayment(false);
    let payment = {
      payment_method_id:
        value.payment && value.payment.id ? value.payment.id : "",
      payment_method_name:
        value.payment && value.payment.name ? value.payment.name : "",
      payment_tenure_id: value.tenure && value.tenure.id ? value.tenure.id : "",
      payment_tenure_name:
        value.tenure && value.tenure.tenure ? value.tenure.tenure : "",
    };

    setFormValue({ ...formValue, ...payment });
  };

  const handlePartner = (value) => {
    setOpenPartner(false);
    setFormValue({ ...formValue, partners: value });
  };

  const handleArea = (value) => {
    setOpenArea(false);
    setFormValue({ ...formValue, areas: value });
  };

  const updateTextDescription = async (state) => {
    await setEditorState(state);
  };

  /*======================== UseEffect ======================== */

  useEffect(() => {
    dispatch(
      getPaymentTenure({
        page: 1,
        limit: 99,
        sort_by: "tenure",
        order_by: "DESC",
      })
    );
    dispatch(
      getPartnerSelectable({
        page: 1,
        limit: 9999,
        sort_by: "name",
        order_by: "ASC",
      })
    );
  }, []);

  /*======================== Others ======================== */

  const defaultStartDate = {
    year: parseInt(moment().format("YYYY")),
    month: parseInt(moment().format("MM")),
    day: parseInt(moment().format("DD")),
  };

  const editorToolbar = {
    options: [
      "inline",
      "blockType",
      "fontSize",
      "list",
      "textAlign",
      "link",
      "remove",
      "history",
    ],
    inline: {
      options: ["bold", "italic", "underline", "strikethrough", "monospace"],
    },
    list: {
      options: ["unordered", "ordered"],
    },
  };

  /*======================== Return ======================== */

  return (
    <>
      <Row className="mt-4 procurement-content">
        <Col xs={12} md={6} className="mt-2">
          <div className="mb-4">
            <h5 className="fw-normal">Informasi Umum</h5>
            <div className="bg-white rounded-card w-full p-3">
              <Row>
                <Col xs={6}>
                  <div className="px-2">
                    <p className="text-muted label mb-0">Nama Penawaran</p>
                    <Form.Control
                      className="border-0 ps-0 pt-1"
                      placeholder="Tulis nama penawaran"
                      onChange={(event) => {
                        setFormValue({
                          ...formValue,
                          name: event.target.value,
                        });
                      }}
                      value={formValue.name}
                    ></Form.Control>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="mb-4">
            <h5 className="fw-normal">Tanggal Penawaran & Kontrak</h5>
            <div className="bg-white rounded-card w-full p-3">
              <Row>
                <Col xs={6}>
                  <div className="px-2">
                    <p className="text-muted label mb-0">Rentang Penawaran</p>
                    <DateRangePickerPenawaran
                      type="offer"
                      handleDateFilter={handleDateFilterOffer}
                      clearDateFilter={clearDateFilterOffer}
                      minDate={defaultStartDate}
                      initialStart={
                        formValue?.start_offering_date && {
                          day: Number(
                            moment(formValue.start_offering_date).format("DD")
                          ),
                          month: Number(
                            moment(formValue.start_offering_date).format("MM")
                          ),
                          year: Number(
                            moment(formValue.start_offering_date).format("YYYY")
                          ),
                        }
                      }
                      initialEnd={
                        formValue?.end_offering_date && {
                          day: Number(
                            moment(formValue.end_offering_date).format("DD")
                          ),
                          month: Number(
                            moment(formValue.end_offering_date).format("MM")
                          ),
                          year: Number(
                            moment(formValue.end_offering_date).format("YYYY")
                          ),
                        }
                      }
                    />
                  </div>
                </Col>
                <Col xs={6}>
                  <div className="px-2">
                    <p className="text-muted label mb-0">
                      Rentang Tanggal Kontrak
                    </p>
                    <DateRangePickerPenawaran
                      type="contract"
                      minDate={
                        formValue?.end_offering_date && {
                          day: Number(
                            moment(formValue.end_offering_date).format("DD")
                          ),
                          month: Number(
                            moment(formValue.end_offering_date).format("MM")
                          ),
                          year: Number(
                            moment(formValue.end_offering_date).format("YYYY")
                          ),
                        }
                      }
                      initialStart={
                        formValue?.start_contract_date && {
                          day: Number(
                            moment(formValue.start_contract_date).format("DD")
                          ),
                          month: Number(
                            moment(formValue.start_contract_date).format("MM")
                          ),
                          year: Number(
                            moment(formValue.start_contract_date).format("YYYY")
                          ),
                        }
                      }
                      initialEnd={
                        formValue?.end_contract_date && {
                          day: Number(
                            moment(formValue.end_contract_date).format("DD")
                          ),
                          month: Number(
                            moment(formValue.end_contract_date).format("MM")
                          ),
                          year: Number(
                            moment(formValue.end_contract_date).format("YYYY")
                          ),
                        }
                      }
                      handleDateFilter={handleDateFilterContract}
                      clearDateFilter={clearDateFilterContract}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="mb-4">
            <h5 className="fw-normal mt-3">Partner Kontrak</h5>
            <div className="bg-white rounded-card w-full p-3">
              <Row>
                <Col xs={6}>
                  <div
                    className="d-flex align-items-center pointer px-2"
                    onClick={handleOpenPartner}
                  >
                    <div>
                      <p className="text-muted label mb-2">Partner Kontrak</p>
                      <h6>
                        {" "}
                        {formValue && formValue.partners.length > 0
                          ? formValue.partners.length + " Partner Kontrak"
                          : "Pilih Partner Kontrak"}
                      </h6>
                    </div>
                    <div className="d-flex ms-auto align-items-center">
                      <FiChevronRight className="" />
                    </div>
                  </div>
                </Col>
                <Col xs={6}>
                  <div
                    className="d-flex align-items-center pointer px-2"
                    onClick={handleOpenArea}
                  >
                    <div>
                      <p className="text-muted label mb-2">
                        Area Kebutuhan Kontrak
                      </p>
                      <h6>
                        {" "}
                        {formValue && formValue.areas.length > 0
                          ? formValue.areas.length + " Area Kontrak"
                          : "Pilih Area Kontrak"}
                      </h6>
                    </div>
                    <div className="d-flex ms-auto align-items-center">
                      <FiChevronRight className="" />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="mb-4">
            <h5 className="fw-normal mt-3">Metode Pembayaran</h5>
            <div className="bg-white rounded-card w-full p-3">
              <Row>
                <Col xs={6}>
                  <div
                    className="d-flex align-items-center pointer px-2"
                    onClick={handleOpenPayment}
                  >
                    <div>
                      <p className="text-muted label mb-2">Jenis Pembayaran</p>
                      {/* <h5>{formValue.type_of_goods}</h5> */}
                      <h6>
                        {" "}
                        {formValue.payment_tenure_name
                          ? "Tempo (" + formValue.payment_tenure_name + " Hari)"
                          : "Pilih Jenis Pembayaran"}
                      </h6>
                    </div>
                    <div className="d-flex ms-auto align-items-center">
                      <FiChevronRight className="" />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="mb-4">
            <h5 className="fw-normal mt-3">Deskripsi Kontrak (optional)</h5>
            <div className="bg-white rounded-card w-full p-3">
              <div className="px-2">
                <p className="text-muted label mb-0">Tulis Deskripsi kontrak</p>
                <Form.Control
                  as="textarea"
                  className="border-0 ps-0 pt-1"
                  onChange={(event) => {
                    setFormValue({
                      ...formValue,
                      contract_description: event.target.value,
                    });
                  }}
                  value={formValue.contract_description}
                  style={{ height: "120px" }}
                ></Form.Control>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={12} md={6} className="mt-2">
          <div className="mb-4">
            <h5 className="fw-normal mt-0">Syarat dan Ketentuan (optional)</h5>
            <div className="bg-white rounded-card w-full p-3">
              <div className="px-2">
                <Editor
                  // toolbarHidden
                  toolbar={editorToolbar}
                  editorState={editorState}
                  toolbarClassName="syarat-toolbar"
                  wrapperClassName="syarat-wrapper"
                  editorClassName="syarat-editor"
                  onEditorStateChange={updateTextDescription}
                />
                {/* <textarea
                  disabled
                  value={
                    editorState &&
                    draftToMarkdown(
                      convertToRaw(editorState.getCurrentContent())
                    )
                  }
                /> */}
              </div>
            </div>
          </div>
        </Col>
      </Row>
      {openPayment && (
        <Payment
          data={tenureData}
          formValue={formValue}
          isOpen={openPayment}
          handleClose={handleOpenPayment}
          handleSubmit={handlePayment}
        />
      )}

      {openPartner && (
        <Partner
          data={partnerData}
          formValue={formValue}
          isOpen={openPartner}
          handleClose={handleOpenPartner}
          handleSubmit={handlePartner}
        />
      )}

      {openArea && (
        <Area
          formValue={formValue}
          isOpen={openArea}
          handleClose={handleOpenArea}
          handleSubmit={handleArea}
        />
      )}
    </>
  );
};

export default InformasiUtama;
