import { Modal, Button, Form } from "react-bootstrap";
import { useState } from "react";
import { MdClose } from "react-icons/md";

const TerimaPenawaran = (props) => {
  /*======================== Hooks ======================== */

  /*======================== Props ======================== */

  const { isOpen, data, handleClose, handleAcceptOffer } = props;

  /*======================== UseState ======================== */

  /*======================== Handler ======================== */
  const handleOnClose = () => {
    handleClose();
  };

  /*======================== UseEffect ======================== */

  /*======================== Return ======================== */

  return (
    <Modal
      contentClassName="rounded-card p-2"
      show={isOpen}
      onHide={handleOnClose}
      className="p-3"
      size="md"
      centered
    >
      <div className="d-flex p-2">
        <h2 className="fw-nornal">Terima Partner</h2>
        <MdClose className="ms-auto me-2 pointer" onClick={handleOnClose} />
      </div>
      <Modal.Body>
        <div className="px-2">
          <p className="fw mb-3">Pastikan anda sudah melakukan cek data yang sudah di update oleh partner</p>
          <p className="fw mb-0">Jika sudah, tekan tombol dibawah untuk menerima partner sebagai partner terpilih untuk rute ini</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size="sm"
          className="rounded-10 text-capitalize w-25 float-end"
          onClick={() => handleAcceptOffer()}
        >
          Terima Partner
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TerimaPenawaran;
