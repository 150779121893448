import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import {
  Row,
  Col,
  Form
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { Checkbox, message } from "antd";

const RoleInputForm = forwardRef(({ dataForm, submit }, ref) => {
  const accessRoleList = useSelector(
    (st) => st.userMgtReducer.accessRoleList.data
  );

  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [form, updateForm] = useState({
    id: dataForm.id,
    roleName: dataForm.name,
    roleCode: dataForm.role_code,
    type: dataForm.type,
    description: dataForm.description,
    accessRole: dataForm.access,
  });

  useImperativeHandle(ref, () => ({
    handleInput() {
      if (form.roleName === '' || form.roleCode === '' ) {
        message.error('Nama Role dan Kode Role harus diisi');
      } else if (!form.accessRole || (form.accessRole && form.accessRole.length === 0)) {
        message.error('Akses Role belum dipilih');
      } else if ( form.roleCode &&  form.roleCode.length !== 2 ) {
        message.error('Panjang Kode Role harus 2');
      } else {
        let data = {
          id: form.id,
          name: form.roleName,
          role_code: form.roleCode,
          type: form.type,
          description: form.description,
          access: form.accessRole
        }
        submit(data);
      }
    }
  }));
  
  const onChange = (checkedValues) => {
    updateForm({
      ...form,
      accessRole: checkedValues,
    });

    setIndeterminate(!!checkedValues.length && checkedValues.length < accessRoleList.length);
    setCheckAll(checkedValues.length === accessRoleList.length);
  }

  const onChangeSelectAll = (e) => {
    let selectall = [];
    if (e.target.checked) {
      accessRoleList.forEach(item => {
        selectall.push(item.id)
      });
    };

    updateForm({
      ...form,
      accessRole: selectall,
    });
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const setCheckbox = () => {
    let component = [];
    accessRoleList.forEach(item => {
      const checked = form.accessRole && form.accessRole.length > 0 ? form.accessRole.find(val => val === item.id) : false;
      if (form.accessRole && form.accessRole.length > 0 && checked) {
        component.push(
          <Col lg={6} key={item.id}>
            <div className="border rounded mb-2 p-2 px-3" style={{ backgroundColor: "#F3F8F8", border: '2px solid #89C9D7' }}>
              <Checkbox value={item.id} checked={checked.id}>{item.name}</Checkbox>
            </div>
          </Col>
        )
      } else {
        component.push(
          <Col lg={6} key={item.id}>
            <div className="border rounded mb-2 p-2 px-3">
              <Checkbox value={item.id} checked={false}>{item.name}</Checkbox>
            </div>
          </Col>
        )
      }
    })
    return component;
  }

  useEffect(() => {
    updateForm({
      id: dataForm.id,
      roleName: dataForm.name,
      roleCode: dataForm.role_code,
      type: dataForm.type,
      description: dataForm.description,
      accessRole: dataForm.access,
    });

    if (dataForm.access && dataForm.access.length > 0) {
      setIndeterminate(!!dataForm.access.length && dataForm.access.length < accessRoleList.length);
      setCheckAll(dataForm.access.length === accessRoleList.length);
    } else {
      setCheckAll(false);
      setIndeterminate(false);
    }
  }, [dataForm, accessRoleList]);

  return (
    <>
      <Row>
        <Col lg={4} md={6} sm={6} xs={12}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label><strong>Kode Role*</strong></Form.Label>
            <Form.Control type="text" className="w-100"
              value={form?.roleCode}
              onChange={(e) => {
                updateForm({
                  ...form,
                  roleCode: e.target.value,
                });
              }}
            />
          </Form.Group>
        </Col>
        <Col lg={6} md={6} sm={6} xs={12}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
            <Form.Label><strong>Nama Role*</strong></Form.Label>
            <Form.Control type="text" className="w-100"
              value={form?.roleName}
              onChange={(e) => {
                updateForm({
                  ...form,
                  roleName: e.target.value,
                });
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      <Form.Group className="mb-4" controlId="exampleForm.ControlTextarea1">
        <Form.Label><strong>Deskripsi Role (Optional)</strong></Form.Label>
        <Form.Control as="textarea" rows={3}
          value={form?.description}
          onChange={(e) => {
            updateForm({
              ...form,
              description: e.target.value,
            });
          }}
        />
      </Form.Group>
      <div className="d-flex justify-content-between mb-2">
        <div >
          <h5 className="d-inline fw-bold me-2">Akses Role</h5>
          <h6 className="d-inline" style={{ color: "#89C9D7" }}>({form?.accessRole?.length} Terpilih)</h6>
        </div>
        <Checkbox
          onChange={onChangeSelectAll}
          checked={checkAll}
        > Pilih Semua </Checkbox>
      </div>
      <div className="mb-3">
        <Row>
          <Checkbox.Group
            style={{
              width: '100%',
            }}
            value={form.accessRole}
            onChange={onChange}
          >
            <Row>
              {setCheckbox()}
            </Row>
          </Checkbox.Group>
        </Row>
      </div>
    </>
  )
});

export default RoleInputForm;
