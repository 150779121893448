import React from "react";
import { Col } from "react-bootstrap";
import moment from "moment";


const MetodePembayaran = ({trxDetail}) => {

    const dataMetodePembayaran = [
        {
            title: "Metode Pembayaran",
            value: trxDetail?.data?.trx_payment?.payment_method_name ? trxDetail?.data?.trx_payment?.payment_method_name : "-",
            style: {}
        },
        {
            title: "Tanggal Pembayaran",
            value: moment(trxDetail?.data?.trx_payment?.paid_date).format("DD MMM YYYY HH:mm"),
            style: {color:"#0FAA4B"}
        },
    ]

  return(
    <Col className="rounded-card bg-white my-2 me-3 col-lg-3">
        <div className="pt-3 pb-2 border-bottom">
            <h5 className="fw-bold">Metode Pembayaran</h5>
        </div>
        {dataMetodePembayaran.map((item, i)=> (
            <div key={i} className="my-3">
                <h6 className="mb-1 text-muted">
                   {item.title}
                </h6>
                <h6 className="fw-bold" style={item.style}>
                    {item.value}
                </h6>
            </div>
        ))}
    </Col>  
  );
}

export default MetodePembayaran;
