import React, { Component } from "react";
import { Card } from "react-bootstrap";
import Gmap from "../../components/gMap";
import TabAuth from "../../components/tabAuth";
import { connect, useSelector } from "react-redux";
import useBreakpoint from "../../components/responsiveUtils/useBreakpoint";
import { useHistory } from "react-router-dom";

// icons
import { IoChevronBack } from "react-icons/io5";

const Login = () => {
  const { isMobile } = useBreakpoint();
  const authReducer = useSelector((st) => st.authReducer);
  const history = useHistory();

  return (
    <div style={{ height: "100vh", width: "100%" }}>
      {authReducer.access_token === null && (
        <>
          {isMobile && (
            <div className="w-100 p-2 head-menu shadow-md">
              <div className="d-flex mt-auto mb-2 align-items-center w-100 px-2">
                <div className="d-flex">
                  <IoChevronBack
                    onClick={() => history.goBack()}
                    style={{ fontSize: 25 }}
                    className="pointer me-2"
                  />

                  <h4 className="mt-1" id="head-menu-text-login">
                    {"Login"}
                  </h4>
                </div>
              </div>
            </div>
          )}
          <Card
            className={`${
              isMobile ? "card-mobile" : " card-absolute height-auto"
            }`}
          >
            <Card.Body className="pb-0 flex-i">
              <TabAuth />
            </Card.Body>
          </Card>
        </>
      )}
      {!isMobile && <Gmap />}
    </div>
  );
};

export default Login;

// class Login extends Component {
//   render() {
//     return (
//       <div style={{ height: "100vh", width: "100%" }}>
//         {authReducer.access_token === null ? (
//           <Card className="card-absolute height-auto">
//             <Card.Body className="pb-0 flex-i">
//               <TabAuth />
//             </Card.Body>
//           </Card>
//         ) : null}
//         <Gmap />
//       </div>
//     );
//   }
// }

// const mapStateToProps = (state) => ({
//   authReducer: state.authReducer,
// });
// export default connect(mapStateToProps)(Login);
