export const disbursementHelper = {
  getTitle: (type) => {
    const mathcer = {
      SEND_GOODS: "Pengiriman Barang",
      RENT: "Sewa Kendaraan",
      DISBURSEMENT: "Penarikan Dana",
      DISBURSEMENT_REVERT: "Refund",
      CANCEL_ORDER: "Pembatalan Pesanan",
      REFERRAL: "Referral",
    };

    return mathcer[type];
  },

  getDescription: (type) => {},

  getStatus: (type) => {
    const mathcer = {
      SEND_GOODS: "out",
      RENT: "in",
      DISBURSEMENT: "out",
      DISBURSEMENT_REVERT: "in",
      CANCEL_ORDER: "in",
      REFERRAL: "in",
    };

    return mathcer[type];
  },
};
