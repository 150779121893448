const payment_helper = {
  getContent: (payment_id) => {
    let result = [];
    switch (payment_id) {
      case 6:
        // Khusus "BCA Virtual Account"
        result.push({
          type: "Mobile Banking / M-BCA",
          step: [
            "Buka Aplikasi M-BCA",
            "Pilih 'm-Transfer' dan pilih 'BCA Virtual Account'",
            "Masukkan nomor BCA Virtual Account yang ada dalam Invoice, lalu pilih 'OK'",
            "Anda dapat memeriksa dan memverifikasi detail pembayaran Anda termasuk Nomor Rekening Anda & Nomor BCA Virtual Account tujuan yang ada di halaman konfirmasi rekening",
            "Pilih 'OK' pada halaman konfirmasi pembayaran",
            "Masukkan PIN BCA Anda untuk mengotorisasi pembayaran",
            "Pembayaran Anda berhasil",
          ],
        });

        result.push({
          type: "Klik BCA / Internet Banking BCA",
          step: [
            "Pilih menu Transfer Dana.",
            "Pilih 'Transfer ke BCA Virtual Account'",
            "Masukkan nomor BCA Virtual Account yang ada dalam Invoice",
            "Anda dapat memeriksa dan memverifikasi detail pembayaran Anda, jika informasi benar Klik 'lanjutkan'",
            "Masukkan KEYBCA APPLI 1 yang muncul pada Token BCA Anda, lalu Klik tombol 'kirim'",
            "Pembayaran Anda berhasil",
          ],
        });

        result.push({
          type: "ATM BCA",
          step: [
            "Pada menu utama, Pilih Transaksi Lainnya",
            "Pilih 'Transfer'",
            "Pilih ke Rekening 'BCA Virtual Account' ",
            "Masukkan nomor Virtual Account tujuan lalu tekan 'benar'",
            "Anda dapat memeriksa dan memverifikasi detail pembayaran Anda, jika informasi benar Klik 'YA'",
          ],
        });
        break;

      case 4:
        // "BRI Virtual Account"
        result.push({
          type: "Mobile Banking BRI",
          step: [
            "Masuk ke dalam aplikasi BRI Mobile, pilih Mobile Banking BRI",
            "Pilih Pembayaran, lalu pilih BRIVA",
            "Masukkan nomor BRIVA",
            "Jumlah pembayaran, nomor pembayaran, dan nama merchant akan muncul pada halaman konfirmasi pembayaran. Jika informasi yang dicantumkan benar, pilih Continue",
            "Masukkan Mobile Banking BRI PIN, pilih Ok. 6. Pembayaran telah selesai. Simpan notifikasi sebagai bukti pembayaran",
          ],
        });

        result.push({
          type: "Internet Banking BRI",
          step: [
            "Masuk pada Internet Banking BRI",
            "Pilih menu Pembayaran & Pembelian",
            "Pilih sub menu BRIVA",
            "Masukkan nomor BRIVA",
            "Jumlah pembayaran, nomor pembayaran, dan nama merchant akan muncul pada halaman konfirmasi pembayaran. Jika informasi yang dicantumkan benar, pilih Continue",
            "Masukan password dan mToken, pilih Kirim",
            "Pembayaran telah selesai. Untuk mencetak bukti transaksi, pilih cetak.",
          ],
        });

        result.push({
          type: "ATM BRI",
          step: [
            "Masukkan kartu ATM dan pilih 'Bahasa Indonesia'",
            "Pilih 'Transaksi Lain' dan pilih 'Pembayaran'",
            "Pilih menu 'Lainnya' dan pilih 'Briva'",
            "Masukkan nomor Virtual account",
            "Untuk open amount, Masukkan nominal yang ingin dibayarkan",
            "Periksa data transaksi dan tekan 'YA'",
            "Simpan bukti pembayaran.",
            "Transaksi anda sudah selesai",
          ],
        });
        break;

      case 1:
        // "Mandiri Virtual Account"
        result.push({
          type: "Mobile Banking Livin' by Mandiri",
          step: [
            "Unduh dan Masuk ke Livin' by Mandiri",
            "Pada menu utama, pilih “BAYAR”",
            "Klik “Buat Pembayaran Baru”",
            "Pilih “Multi payment”",
            "Pilih Penyedia Jasa 'Midtrans 70012'",
            "Masukkan Nomor Virtual Account, Klik Lanjut",
            "Lakukan Konfirmasi Nama Pembayaran",
            "Untuk open amount, Masukkan Nominal dan Klik Lanjut",
            "Lakukan Konfirmasi Pembayaran, Klik Konfirmasi",
            "Simpan Bukti Pembayaran",
            "Transaksi Anda sudah Selesai",
          ],
        });

        result.push({
          type: "Internet Banking Mandiri",
          step: [
            "Kunjungi website Mandiri Internet Banking: (https://ibank.bankmandiri.co.id/retail3/)",
            "Login dengan memasukkan USER ID dan PIN",
            "Pilih 'Pembayaran'",
            "Pilih 'Multi Payment'",
            "Pilih 'No Rekening Anda'",
            "Pilih Penyedia Jasa 'Midtrans 70012'",
            "Pilih 'No Virtual Account'",
            "Masukkan nomor Virtual Account anda",
            "Untuk open amount, Masukkan Nominal pembayaran",
            "Lakukan Konfirmasi Pembayaran",
            "Simpan Bukti Pembayaran",
            "Transaksi anda sudah selesai",
          ],
        });

        result.push({
          type: "ATM Mandiri",
          step: [
            "Pada menu utama, pilih Bayar/Beli",
            "Pilih Lainnya",
            "Pilih Multi Payment",
            "Masukkan 70012 (kode perusahaan Midtrans) lalu tekan Benar",
            "Masukkan Kode Pembayaran Anda lalu tekan Benar",
            "Pada halaman konfirmasi akan muncul detail pembayaran Anda. Jika informasi telah sesuai tekan Ya.",
          ],
        });
        break;

      case 2:
        // "BNI Virtual Account"
        result.push({
          type: "Mobile Banking BNI",
          step: [
            "Buka Aplikasi BNI Mobile Banking dan Login",
            "Pilih menu 'Transfer' lalu Pilih menu 'Virtual Account Billing'",
            "Pilih Rekening debit yang akan digunakan",
            "Pilih menu 'Input Baru' dan masukkan 16 digit nomor Virtual Account",
            "Anda dapat memeriksa dan memverifikasi detail pembayaran Anda, jika informasi benar, masukkan Password Transaksi dan Klik Lanjut",
            "Pembayaran Anda berhasil",
          ],
        });

        result.push({
          type: "ATM BNI",
          step: [
            "Pada menu utama, Pilih 'Menu Lainnya'",
            "Pilih 'Transfer', lalu Pilih jenis rekening yang akan Anda gunakan (contoh: 'Dari Rekening Tabungan')",
            "Pilih 'Virtual Account Billing' ",
            "Masukkan nomor Virtual Account tujuan",
            "Anda dapat memeriksa dan memverifikasi detail pembayaran Anda, jika informasi benar Pilih 'Lanjutkan'",
            "Pembayaran Anda berhasil",
          ],
        });
        break;

      case 5:
        // "Permata Virtual Account"
        result.push({
          type: "Mobile Banking Permata",
          step: [
            "Buka Aplikasi Permata Mobile Internet",
            "Pilih 'Pembayaran Tagihan' lalu Pilih 'Virtual Account'",
            "Masukkan 16 digit nomor Virtual Account tujuan, lalu tekan 'Benar'",
            "Masukkan nominal pembayaran sesuai Tagihan",
            "Anda dapat memeriksa dan memverifikasi detail pembayaran Anda, jika informasi benar Klik 'Konfirmasi'",
            "Masukkan otentikasi transaksi/token",
            "Pembayaran Anda berhasil",
          ],
        });

        result.push({
          type: "ATM Permata",
          step: [
            "Pada menu utama, Pilih 'Transaksi Lainnya'",
            "Pilih 'Pembayaran', lalu Pilih 'Pembayaran Lainnya'",
            "Pilih 'Virtual Account' ",
            "Masukkan 16 digit nomor Virtual Account tujuan, lalu tekan 'Benar'",
            "Anda dapat memeriksa dan memverifikasi detail pembayaran Anda, jika informasi benar tekan 'Benar'",
            "Pilih rekening pembayaran Anda dan tekan 'Benar'",
            "Pembayaran Anda berhasil",
          ],
        });
        break;

      case 23:
        // "Alfamart"
        result.push({
          type: "Cara Melakukan Pembayaran di Alfamart",
          step: [
            "Kunjungi gerai Alfamart/Alfamidi/Lawson/DAN+DAN terdekat sebelum Kode Pembayaran kedaluwarsa",
            "Informasikan ke Kasir untuk melakukan Pembayaran ke Produk/Servis Anda dengan menyatakan nama Brand Anda",
            "Berikan informasi Kode Pembayaran yang tertera di Aplikasi/Website pada Kasir",
            "Jika Anda memiliki Barcode/QR Code, Anda dapat memberikan Barcode/QR Code tersebut pada Kasir untuk di-scan",
            "Pastikan Jumlah Pembayaran di tempat Anda berbelanja sama dengan Jumlah yang diberitahukan oleh Kasir, lalu Konfirmasi Pembayaran",
            "Simpan bukti pembayaran Anda dari Alfamart",
            "Pembayaran Anda berhasil",
          ],
        });
        break;

      case 24:
        // "Indomart"
        result.push({
          type: "Cara Melakukan Pembayaran di Indomart",
          step: [
            "Kunjungi gerai Indomaret terdekat sebelum Kode Pembayaran kedaluwarsa",
            "Informasikan ke Kasir untuk melakukan Pembayaran ke Produk/Servis Anda dengan menyatakan nama Brand Anda",
            "Berikan informasi Kode Pembayaran yang tertera di Aplikasi/Website pada Kasir",
            "Jika Anda memiliki Barcode/QR Code, Anda dapat memberikan Barcode/QR Code tersebut pada Kasir untuk di-scan",
            "Pastikan Jumlah Pembayaran di tempat Anda berbelanja sama dengan Jumlah yang diberitahukan oleh Kasir, lalu Konfirmasi Pembayaran",
            "Simpan bukti pembayaran Anda dari Alfamart",
            "Pembayaran Anda berhasil",
          ],
        });
        break;

      case 17:
        // "GoPay"
        result.push({
          type: "Cara Melakukan Pembayaran Menggunakan GoPay",
          step: [
            "Pilih GoPay sebagai metode pembayaran",
            "Lanjutkan checkout sampai Anda diarahkan ke aplikasi Gojek-GoPay",
            "Klik 'Bayar', setelah memeriksa dan memverifikasi detail pembayaran Anda",
            "Anda akan diminta untuk memasukkan PIN Keamanan untuk menyelesaikan transaksi Anda",
          ],
        });
        break;

      case 19:
        // "QR"
        result.push({
          type: "Cara Melakukan Pembayaran Menggunakan Kode QR",
          step: [
            "Buka aplikasi QR yang terpasang di ponsel Anda",
            "Gunakan fitur 'Scan to Pay' atau 'Pindai untuk Bayar' untuk memindai kode QR",
            "Klik 'Bayar', setelah memeriksa dan memverifikasi detail pembayaran Anda",
            "Anda akan diminta untuk memasukkan PIN Keamanan untuk menyelesaikan transaksi Anda",
          ],
        });
        break;

      case 47:
        // "OVO"
        result.push({
          type: "Cara Melakukan Pembayaran Menggunakan OVO",
          step: [
            "Pilih OVO sebagai metode pembayaran",
            "Masukkan nomor ponsel Anda",
            "Pembayaran Anda akan diproses",
            "Anda akan menerima Notifikasi pada aplikasi OVO",
            "Anda dapat memeriksa dan memverifikasi detail pembayaran Anda",
            "Pembayaran Anda berhasil",
          ],
        });
        break;

      case 13:
        // "DANA"
        result.push({
          type: "Cara Melakukan Pembayaran Menggunakan DANA",
          step: [
            "Pilih DANA sebagai metode pembayaran",
            "Lanjutkan checkout sampai Anda diarahkan ke aplikasi DANA dan Anda akan diminta untuk memasukkan nomor ponsel",
            "Anda akan diminta untuk memasukkan PIN DANA sebagai verifikasi",
            "Anda akan diminta untuk memasukkan PIN Keamanan/Kode OTP DANA pada transaksi pertama",
            "Anda dapat memeriksa dan memverifikasi detail pembayaran Anda",
            "Pembayaran Anda berhasil",
            "Anda akan diarahkan ke Merchant setelah pembayaran selesai",
          ],
        });
        break;

      case 15:
        // "LinkAja"
        result.push({
          type: "Cara Melakukan Pembayaran Menggunakan LinkAja",
          step: [
            "Pilih LinkAja sebagai metode pembayaran, kemudian Anda akan diarahkan ke Halaman/Aplikasi LinkAja",
            "Anda akan diminta untuk memasukkan PIN LinkAja serta Kode OTP untuk melakukan pembayaran",
            "Anda akan menerima SMS berisi Kode OTP dari LinkAja",
            "Pembayaran Anda berhasil, setelah itu Anda akan diarahkan ke Halaman Merchant",
          ],
        });
        break;

      case 14:
        // "ShopeePay"
        result.push({
          type: "Cara Melakukan Pembayaran Menggunakan ShopeePay",
          step: [
            "Pilih ShopeePay sebagai metode pembayaran kemudian Anda akan diarahkan ke aplikasi ShopeePay",
            "ShopeePay akan menampilkan Total Pembayaran yang harus diselesaikan",
            "Anda akan diminta untuk memasukkan PIN Keamanan untuk menyelesaikan transaksi Anda",
            "Klik 'Lanjutkan' untuk melakukan pembayaran",
            "Pembayaran Anda berhasil",
          ],
        });
        break;

      default:
        break;
    }
    return result;
  },
};

export default payment_helper;
