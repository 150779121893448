import { Row, Col, Card } from "react-bootstrap";
import { useState } from "react";
import { useSelector } from "react-redux";
import { FaChevronRight } from "react-icons/fa";
import AdditionalDataForm from "./Modal/AdditionalDataForm";
import icon_docs from "../../../assets/images/icon-docs.svg";

const AdditionalData = () => {
  /*======================== Hooks ======================== */

  const additionalData = useSelector(
    (st) => st.kirimbarangReducer.additionalData
  );

  /*======================== UseState ===================== */

  const [openForm, setOpenForm] = useState(false);

  /*======================== Handler ====================== */

  /*======================== UseEffect ==================== */

  /*======================== Return ======================= */

  return (
    <div className="mb-2">
      <Card className="ice mb-2">
        <Card.Body>
          {additionalData && additionalData.noTF ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                background: " #F3F8F8",
              }}
              onClick={() => setOpenForm(true)}
            >
              <div style={{ fontSize: "13px", color: "#209AB7" }}>
                <img src={icon_docs} alt="icon_docs" />
                <span className="ms-4"> Terdapat Data Tambahan </span>
              </div>
              <FaChevronRight style={{ cursor: "pointer" }} />
            </div>
          ) : (
            <div
              style={{ display: "flex", justifyContent: "space-between" }}
              onClick={() => setOpenForm(true)}
            >
              <div style={{ fontSize: "13px" }}>
                <img src={icon_docs} alt="icon_docs" />
                <span className="ms-4"> Data Tambahan </span>
              </div>
              <FaChevronRight style={{ cursor: "pointer" }} />
            </div>
          )}
        </Card.Body>
      </Card>

      {openForm && (
        <AdditionalDataForm
          isOpen={openForm}
          handleClose={() => setOpenForm(false)}
        />
      )}
    </div>
  );
};

export default AdditionalData;
