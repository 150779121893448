import React, { useState, useEffect } from "react";
import { Col, Card, Modal } from "react-bootstrap";
import util from "../../helper/util";

// icons
import { IoClose } from "react-icons/io5";
import { FiChevronRight } from "react-icons/fi";
import { connect } from "react-redux";

function PriceDetail(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // console.log("detailOrder", props.detailPriceKirimBarang);
  // console.log("total price sewa", props.detailPriceKirimBarang);

  return (
    <>
      <Col md={6} className="text-end pointer" onClick={handleShow}>
        <h4 className="fw-bold">
          Rp{" "}
          {props.activeMenu === "SewaKendaraan"
            ? parseInt(
                props.totalPrice.final_price_with_admin_fee
                  ? props.totalPrice?.final_price_with_admin_fee
                  : props.totalPrice?.final_price || 0
              )
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            : Math.ceil(
                +props.detailPriceKirimBarang.final_price_with_admin_fee
                  ? props.detailPriceKirimBarang.final_price_with_admin_fee
                  : props.detailPriceKirimBarang.final_price || 0
              )
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
          <FiChevronRight />
        </h4>
      </Col>

      <Modal
        contentClassName=""
        show={show}
        onHide={handleClose}
        className=""
        centered
      >
        <Card>
          <Card.Body>
            <h4>
              <IoClose
                onClick={handleClose}
                className="pointer me-2"
                style={{ marginTop: "-2px" }}
              />
              Detail Biaya
            </h4>
            <hr className="mt-1" />
            <div>
              <p className="mb-2">
                {props.activeMenu === "KirimBarang"
                  ? "Tarif Dasar"
                  : "Tarif Sewa Unit"}
                <span className="float-end fw-medium">
                  Rp
                  {props.activeMenu === "SewaKendaraan"
                    ? parseInt(props.totalPrice.vehicle_price || 0)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                    : Math.ceil(props.detailPriceKirimBarang?.base_pricing_send)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                </span>
              </p>

              {props.activeMenu === "KirimBarang" && (
                <p className="mb-2">
                  Tarif Kenaikan ({props.detailOrderKirimBarang.total_km} KM)
                  <span className="float-end fw-medium">
                    Rp
                    {util.thousandSeparatorPure(
                      props.detailPriceKirimBarang.total_per_km_price
                    ) || 0}
                  </span>
                </p>
              )}
              <ul className="addtional">
                Layanan Tambahan
                {/* { ADDITIONAL RENT SERVICES - START  } */}
                {props.detailOrderSewaKendaraan
                  .helper_availability_send_selected ? (
                  <li>
                    Layanan Supir
                    <span className="float-end fw-medium">
                      Rp
                      {props.activeMenu === "SewaKendaraan"
                        ? parseInt(props.totalPrice?.driver_price || 0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                        : null}
                    </span>
                  </li>
                ) : undefined}
                {props.detailOrderSewaKendaraan
                  .driver_availability_rent_selected ? (
                  <li>
                    Bantuan Supir
                    <span className="float-end fw-medium">
                      Rp
                      {props.activeMenu === "SewaKendaraan"
                        ? parseInt(0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                        : null}
                    </span>
                  </li>
                ) : undefined}
                {props.detailOrderSewaKendaraan
                  .helper_availability_rent_selected ? (
                  <li>
                    Helper / Kenek (
                    {props.activeMenu === "SewaKendaraan"
                      ? props.detailOrder.total_helper_requested
                      : 0}{" "}
                    orang)
                    <span className="float-end fw-medium">
                      Rp
                      {props.activeMenu === "SewaKendaraan"
                        ? parseInt(props.totalPrice?.helper_price || 0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                        : null}
                    </span>
                  </li>
                ) : undefined}
                {/* {props.totalPrice.admin_fee ? (
                  <li>
                    Admin Fee
                    <span className="float-end fw-medium">
                      Rp
                      {props.activeMenu === "SewaKendaraan"
                        ? parseInt(props.totalPrice.admin_fee || 0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                        : null}
                    </span>
                  </li>
                ) : undefined} */}
                {/* { ADDITIONAL RENT SERVICES - END  } */}
                {/* { ADDITIONAL SEND SERVICES - START  } */}
                {props.activeMenu === "KirimBarang" && (
                  <>
                    <li>
                      Tenaga Supir
                      <span className="float-end fw-medium">Rp 0</span>
                    </li>
                    {props.detailPriceKirimBarang?.total_helper > 0 && (
                      <li>
                        Helper ( {props.detailPriceKirimBarang?.total_helper} )
                        orang
                        <span className="float-end fw-medium">
                          Rp{" "}
                          {parseInt(
                            props.detailPriceKirimBarang?.total_helper_price ||
                              0
                          )
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                        </span>
                      </li>
                    )}
                    {props.detailOrderKirimBarang?.is_insurance_checked && (
                      <li>
                        Asuransi
                        <span className="float-end fw-medium">
                          Rp{" "}
                          {parseInt(
                            props.detailOrderKirimBarang?.insurance_fee || 0
                          )
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                        </span>
                      </li>
                    )}
                  </>
                )}
                {/* { ADDITIONAL SEND SERVICES - END  } */}
              </ul>
              {props.activeMenu === "SewaKendaraan" && (
                <p className="mb-2">
                  Biaya Deposit
                  <span className="float-end fw-medium">
                    Rp
                    {parseInt(props.totalPrice?.deposit || 0)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                  </span>
                </p>
              )}
              {props.activeMenu === "KirimBarang" ? (
                <p className="mb-2">
                  Pajak
                  <span className="float-end fw-medium">
                    Rp
                    {parseInt(
                      props.detailPriceKirimBarang?.customer_vat +
                        props.detailPriceKirimBarang.customer_service_fee || 0
                    )
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                  </span>
                </p>
              ) : (
                <p className="mb-2">
                  Pajak
                  <span className="float-end fw-medium">
                    Rp
                    {parseInt(
                      props.totalPrice?.customer_vat +
                        props.totalPrice.customer_service_fee || 0
                    )
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                  </span>
                </p>
              )}
              <p className="mb-2">
                Admin Fee
                <span className="float-end fw-medium">
                  Rp
                  {props.activeMenu === "SewaKendaraan"
                    ? parseInt(props.totalPrice?.admin_fee || 0)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                    : parseInt(props.detailPriceKirimBarang?.admin_fee || 0)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                </span>
              </p>
              <ul className="addtional">
                Potongan
                {props.activeMenu === "KirimBarang" ? (
                  <li>
                    Diskon
                    <span className="float-end fw-medium">
                      (Rp{" "}
                      {util.thousandSeparatorPure(
                        props.detailPriceKirimBarang.elog_discount +
                          props.detailPriceKirimBarang.partner_discount
                      ) || 0}
                      )
                    </span>
                  </li>
                ) : (
                  <li>
                    Diskon
                    <span className="float-end fw-medium">
                      (Rp{" "}
                      {util.thousandSeparatorPure(
                        props.totalPrice.elog_discount +
                          props.totalPrice.partner_discount
                      ) || 0}
                      )
                    </span>
                  </li>
                )}
              </ul>
            </div>
            <hr className="mt-1" />
            <h4 className="fw-bold">
              Total Biaya
              <span className="float-end">
                Rp{" "}
                {props.activeMenu === "SewaKendaraan"
                  ? parseInt(
                      props.totalPrice.final_price_with_admin_fee
                        ? props.totalPrice.final_price_with_admin_fee
                        : props.totalPrice.final_price || 0
                    )
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                  : Math.ceil(
                      +props.detailPriceKirimBarang.final_price_with_admin_fee
                        ? props.detailPriceKirimBarang
                            .final_price_with_admin_fee
                        : props.detailPriceKirimBarang.final_price || 0
                    )
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
              </span>
            </h4>
          </Card.Body>
        </Card>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => ({
  activeMenu: state.map.activeMenu,
  detailOrderSewaKendaraan: state.sewaKendaraanReducer.detailOrder,
  detailOrderKirimBarang: state.kirimbarangReducer.detailOrder,
  detailPriceKirimBarang: state.kirimbarangReducer.detail_price,
  auth: state.authReducer,
  totalPrice: state.sewaKendaraanReducer.totalPrice,
  detailOrder: state.sewaKendaraanReducer.detailOrder,
});
export default connect(mapStateToProps)(PriceDetail);
