import React, { Component } from "react";
import TabPayment from "./SelectPayment";

export default class index extends Component {
  render() {
    return (
      <>
        <TabPayment />
      </>
    );
  }
}
