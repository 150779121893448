import React, { useState, useEffect } from "react";
import { Row, Modal, Form, Button } from "react-bootstrap";
import { message, Radio, Space } from "antd";
import TrxListApi from "../../api/trx_list_api";
import { useSelector } from "react-redux";

const CancelOrderModal = ({ showCancelOrderModal, closeCancelOrderModal }) => {
  const auth = useSelector((st) => st.authReducer);
  const trxDetail = useSelector((st) => st.trxHistoryReducer.trxDetail);
  const [radioValue, setRadioValue] = useState(1);
  const [rejectionReason, setRejectionReason] = useState("");
  const [textAreaState, setTextAreaState] = useState("");

  const onChangeRadio = (ev) => {
    const value = ev.target.value;
    setRadioValue(value);

    const helper = {
      1: "Salah membuat order",
      2: "Salah memilih jenis layanan",
      3: "Harga yang ditawarkan terlalu mahal",
      4: textAreaState,
    };

    setRejectionReason(helper[value]);
  };

  const handleCancelOrder = async () => {
    try {
      const api = new TrxListApi();
      let result = null;
      const orderType = localStorage.getItem("type");
      const token = auth.access_token;
      const id = trxDetail.data.id;

      if (orderType === "RENT") {
        result = await api.cancelRentOrder(token, rejectionReason, id);
      } else if (orderType === "SEND_GOODS") {
        result = await api.cancelSendOrder(token, rejectionReason, id);
      }

      if (result.status) {
        message.success("Transaksi dibatalkan");
      } else {
        message.error(result.message);
      }

      console.log(result);
    } catch (error) {
      console.log(error.response);
    }
    closeCancelOrderModal();
  };

  return (
    <Modal
      contentClassName="modal-100 p-1"
      show={showCancelOrderModal}
      onHide={closeCancelOrderModal}
      className=""
      centered
    >
      <Modal.Header closeButton className="bg-white">
        <strong>Pembatalan Order</strong>
      </Modal.Header>
      <Row className="p-4">
        <h5>Alasan Pembatalan Order</h5>
        {/* {JSON.stringify(trxDetail)} */}
        <Radio.Group onChange={onChangeRadio} value={radioValue}>
          <Space direction="vertical">
            <Radio value={1}>Salah Membuat Order</Radio>
            <Radio value={2}>Salah Memilih Jenis Layanan</Radio>
            <Radio value={3}>Harga Yang ditawarkan terlalu mahal</Radio>
            <Radio value={4}>Lainnya</Radio>
          </Space>
        </Radio.Group>
        <Form.Control
          className="ms-4 mt-1"
          as="textarea"
          onChange={(ev) => {
            setRejectionReason(ev.target.value);
            setTextAreaState(ev.target.value);
          }}
          style={{ width: "90%" }}
        />
      </Row>
      <Modal.Footer className="border-top-0 mx-1">
        <Button
          size="sm"
          variant="primary"
          className="float-end rounded-pill px-4 py-2"
          onClick={() => {
            handleCancelOrder();
          }}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CancelOrderModal;
