import { Row, Col, Badge } from "react-bootstrap";
import moment from "moment";

const InfoKendaraan = (props) => {
  const { detail } = props;
  /*======================== Hooks ======================== */

  /*======================== UseState ======================== */

  /*======================== Handler ======================== */

  /*======================== UseEffect ======================== */

  /*======================== Others ======================== */

  const renderPhoto = () => {
    let component = [];
    let i = 1;
    let photo = detail.vehicle_picture
      ? JSON.parse(detail.vehicle_picture)
      : null;
    if (photo) {
      for (const key in photo) {
        if (photo[key]) {
          component.push(
            <Col xs={12} sm={6}>
              <img src={photo[key]} alt="img-vehicle" width="100%" />
            </Col>
          );
        }
      }
    } else {
      return null;
    }

    return component;
  };

  /*======================== Return ======================== */

  return (
    <Row>
      <Col xs={12} sm={12} md={6}>
        <h5 className="fw-normal mb-2">Foto Kendaraan</h5>
        <Row>{renderPhoto()}</Row>
        <h5 className="fw-normal mt-4 mb-2">Kapasitas Kendaraan</h5>
        <div className="bg-white rounded-card w-full py-2 ps-3 mb-4">
          <div>
            <p className="text-muted mb-0"> Dimensi Kendaraan</p>
            <p className="mb-0">
              {" "}
              {detail.mst_vehicle_subtype?.dimension_l} cm x{" "}
              {detail.mst_vehicle_subtype?.dimension_w} cm x{" "}
              {detail.mst_vehicle_subtype?.dimension_h} cm{" "}
            </p>
          </div>
          <hr className="my-2 text-muted" />
          <div>
            <p className="text-muted mb-0"> Tonase Kendaraan</p>
            <p className="mb-0"> {detail.mst_vehicle_subtype?.max_weight} kg</p>
          </div>
          <hr className="my-2 text-muted" />
          <div>
            <p className="text-muted mb-0"> Volume Kendaraan</p>
            <p className="mb-0"> {detail.mst_vehicle_subtype?.volume} m3</p>
          </div>
        </div>
      </Col>
      <Col xs={12} sm={12} md={6}>
        <h5 className="fw-normal mt-4 mb-2">Data Kendaraan</h5>
        <div className="bg-white rounded-card w-full py-2 ps-3 mb-4">
          <Row>
            <Col xs={12} sm={6}>
              <p className="text-muted mb-0"> Plat Nomor</p>
              <p className="mb-0">{detail.plate_number}</p>
            </Col>
            <Col xs={12} sm={6}>
              <p className="text-muted mb-0"> Tahun Kendaraan</p>
              <p className="mb-0"> {detail.year} </p>
            </Col>
          </Row>
          <hr className="my-2 text-muted" />
          <Row>
            <Col xs={12}>
              <p className="text-muted mb-0"> Area Layanan</p>
              <p className="mb-0">
                {" "}
                {detail.mst_partner_service?.mst_regency?.name}{" "}
              </p>
            </Col>
          </Row>
        </div>
        <h5 className="fw-normal mt-4 mb-2">Kategori Kendaraan</h5>
        <div className="bg-white rounded-card w-full py-2 ps-3 mb-4">
          <Row>
            <Col xs={12} sm={6}>
              <div>
                <p className="text-muted mb-0"> Jenis Kendaraan</p>
                <p className="mb-0"> {detail.mst_vehicle_subtype?.category} </p>
              </div>
              <hr className="my-2 text-muted" />
              <div>
                <p className="text-muted mb-0"> Tipe Kendaraan</p>
                <p className="mb-0">
                  {" "}
                  {detail.mst_vehicle_subtype?.mst_vehicle_type?.name}
                </p>
              </div>
              <hr className="my-2 text-muted" />
              <div>
                <p className="text-muted mb-0"> Merek Kendaraan</p>
                <p className="mb-0"> {detail.mst_vehicle_brand?.name} </p>
              </div>
              <hr className="my-2 text-muted" />
              <div>
                <p className="text-muted mb-0"> Model Kendaraan</p>
                <p className="mb-0"> {detail.mst_vehicle_model?.name} </p>
              </div>
            </Col>
            <Col xs={12} sm={6}>
              <div>
                <p className="text-muted mb-0"> Isi Silinder</p>
                <p className="mb-0"> {detail.mst_vehicle_model?.cc}CC</p>
              </div>
              <hr className="my-2 text-muted" />
              <div>
                <p className="text-muted mb-0"> Kapasitas Duduk</p>
                <p className="mb-0">
                  {" "}
                  {detail.mst_vehicle_model?.seats} Orang{" "}
                </p>
              </div>
              <hr className="my-2 text-muted" />
              <div>
                <p className="text-muted mb-0"> Bahan Bakar</p>
                <p className="mb-0"> {detail.mst_vehicle_model?.fuel} </p>
              </div>
              <hr className="my-2 text-muted" />
              <div>
                <p className="text-muted mb-0"> Transmisi</p>
                <p className="mb-0">
                  {" "}
                  {detail.mst_vehicle_model?.transmission}{" "}
                </p>
              </div>
            </Col>
          </Row>
        </div>

        <h5 className="fw-normal mt-4 mb-2">Dokumen Kendaraan</h5>
        <div className="bg-white rounded-card w-full py-2 ps-3 mb-4">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <p className="text-muted mb-0"> Masa Berlaku STNK</p>
              <p className="mb-0">
                {" "}
                {detail.stnk_expired
                  ? moment(detail.stnk_expired).format("DD MMM YYYY")
                  : "-"}
              </p>
            </div>
            {detail.stnk && (
              <a
                className="underline mt-2 me-4"
                style={{
                  color: "#CE1771",
                  fontSize: "13px",
                  cursor: "pointer",
                }}
                href={JSON.parse(detail.stnk)[0]}
                target="_blank"
                rel="noreferrer"
              >
                Lihat Dokumen
              </a>
            )}
          </div>
          <hr className="my-2 text-muted" />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <p className="text-muted mb-0"> Masa Berlaku KIR</p>
              <p className="mb-0">
                {" "}
                {detail.kir_expired
                  ? moment(detail.kir_expired).format("DD MMM YYYY")
                  : "-"}
              </p>
            </div>
            {detail.kir_document && (
              <a
                className="underline mt-2 me-4"
                style={{
                  color: "#CE1771",
                  fontSize: "13px",
                  cursor: "pointer",
                }}
                href={detail.kir_document}
                target="_blank"
                rel="noreferrer"
              >
                Lihat Dokumen
              </a>
            )}
          </div>
          <hr className="my-2 text-muted" />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <p className="text-muted mb-0"> Nomor BPKB</p>
              <p className="mb-0"> {detail?.no_bpkb || "-"} </p>
            </div>
            {detail.bpkb && detail.no_bpkb && (
              <a
                className="underline mt-2 me-4"
                style={{
                  color: "#CE1771",
                  fontSize: "13px",
                  cursor: "pointer",
                }}
                href={JSON.parse(detail.bpkb)[0]}
                target="_blank"
                rel="noreferrer"
              >
                Lihat Dokumen
              </a>
            )}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default InfoKendaraan;
