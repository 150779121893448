import React, { useState, useEffect } from "react";
import {
  Modal,
  Tabs,
  Tab,
  Row,
  Col
} from "react-bootstrap";
import { Avatar } from "antd";
import { MdImageNotSupported } from "react-icons/md";
import { getInfoPengantaranMutasi } from "../../../../redux/action/sewakendaraan_action";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";


const ModalInfoPengantaranMutasi = ({trxDetail}) => {
  const dispatch = useDispatch()
  const infoPengantaranMutasi = useSelector((st)=> st.sewaKendaraanReducer.infoPengantaranMutasi)
  const { id } = useParams();
  // console.log("info Pesanansssssssssssssssss ===>",infoPengantaranMutasi)
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true)

  const [eventKey, setEventKey] = useState(1)
  const [dataMaping, setDataMaping] = useState({})

  const handleChangeTab = async (key) => {
    setEventKey(parseInt(key))
  }
  
  
  useEffect(()=>{
    setEventKey(1)
  },[])

  useEffect(()=>{
    let findIndex = infoPengantaranMutasi[eventKey-1]
    setDataMaping({
      tipeMobil: trxDetail?.data?.trx_booking_rent?.trx_booking_rent_vehicle?.mst_vehicle?.mst_vehicle_subtype?.name,
      mobil: trxDetail?.data?.trx_booking_rent?.trx_booking_rent_vehicle?.mst_vehicle?.mst_vehicle_brand?.name,
      platNumber: trxDetail?.data?.trx_booking_rent?.trx_booking_rent_vehicle?.mst_vehicle?.plate_number,
      nama: findIndex?.received_by ? findIndex?.received_by : "-",
      penerima_photo: findIndex?.received_by ? findIndex?.receiver_photo : "-",
      jarak: findIndex?.odometer ? findIndex?.odometer+" Km" : "-",
      bensin: findIndex?.fuel ? findIndex?.fuel+" Bar" : "-",
      odometer: findIndex?.photo_odometer,
      eksterior: findIndex?.photo_exterior,
      interior: findIndex?.photo_interior,
    })
  },[eventKey, infoPengantaranMutasi])

  useEffect(()=>{
    dispatch(getInfoPengantaranMutasi(id))
  },[])
  


    return (
    <>
      <h6
        className="fw-bold"
        style={{color:"#FF1DBC", textDecoration:"underline", fontSize:"11px", cursor:"pointer"}}
        onClick={()=>handleShow()}
    >
        Info Mutasi
    </h6>
      
       <Modal
        contentClassName="rounded-card p-2 overflow-hidden"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton className="bg-white border-0">
          <strong>Kondisi Kendaraan</strong>
        </Modal.Header>
        <Tabs
          defaultActiveKey={eventKey}
          className="tabs-trx mb-2 border-bottom-2"
          style={{
            marginTop:"-10px"
          }}
          onSelect={handleChangeTab}
        >
            {infoPengantaranMutasi?.map((item, i)=>
                <Tab key={i} eventKey={`${i+1}`} title={`Alamat Mutasi ${i+1}`}></Tab>
            )}
        </Tabs>
        <div
          style={{
            maxHeight: "500px",
            overflowY:"scroll", 
            overflowX:"hidden"
          }}
        >
          <Row className="border-bottom" style={{padding:"20px 30px", marginTop:"-10px"}}>
            <h6 className="m-0 p-0 fw-bold mb-2">Kendaraan</h6>
            <h6 className="m-0 p-0">{dataMaping.tipeMobil} - {dataMaping.mobil} ({dataMaping.platNumber})</h6>
          </Row>
          <Row className="border-bottom" style={{padding:"20px 30px"}}>
            <Col className="m-0 p-0 fw-bold">
                <h6 className="m-0 p-0 fw-bold mb-2">Nama Penerima</h6>
                <h6 className="m-0 p-0">{dataMaping.nama}</h6>
            </Col>
            <Col className="m-0 p-0 fw-bold">
                <h6 className="m-0 p-0 fw-bold mb-2">Foto Penerima</h6>
                <Avatar
                    className="me-2 border rounded overflow-hidden"
                    style={{objectFit:"fill"}}
                    shape="square"
                    size={65}
                    src={dataMaping.penerima_photo}
                    icon={
                        !dataMaping.penerima_photo && <MdImageNotSupported />
                    }
                ></Avatar>
            </Col>
          </Row>
          <Row className="border-bottom" style={{padding:"20px 30px"}}>
            <Col className="m-0 p-0 fw-bold">
              <h6 className="m-0 p-0 fw-bold mb-2">Jarak Tempuh Kendaraan</h6>
              <h6 className="m-0 p-0 mb-3">{dataMaping.jarak}</h6>
              
              <h6 className="m-0 p-0 fw-bold mb-2">Bar Bensin</h6>
              <h6 className="m-0 p-0">{dataMaping.bensin}</h6>
            </Col>
            <Col className="m-0 p-0 fw-bold">
                <h6 className="m-0 p-0 fw-bold mb-2">Foto Odometer</h6>
                <Avatar
                    className="me-2 border rounded overflow-hidden"
                    style={{objectFit:"fill"}}
                    shape="square"
                    size={65}
                    src={dataMaping.odometer}
                    icon={
                        !dataMaping.odometer && <MdImageNotSupported />
                    }
                ></Avatar>
            </Col>
          </Row>
          <Row className="border-bottom" style={{padding:"20px 30px"}}>
            <Col className="m-0 p-0 fw-bold">
              <h6 className="m-0 p-0 fw-bold mb-2">Foto Eksterior</h6>
              <div className="d-flex flex-wrap gap-3">
                {dataMaping.eksterior?.length ? dataMaping.eksterior.map((item,i)=>(
                  <Avatar
                    className="me-2 border rounded overflow-hidden"
                    style={{objectFit:"fill"}}
                    shape="square"
                    size={65}
                    src={item}
                  ></Avatar>
                )) :
                  <h6 className="text-muted mt-3" style={{fontSize:"9px"}}>Foto eksterior tidak tersedia</h6>
                }
              </div>
            </Col>
            <Col className="m-0 p-0 fw-bold">
              <h6 className="m-0 p-0 fw-bold mb-2">Foto Interior</h6>
              <div className="d-flex flex-wrap gap-3">
                {dataMaping.interior?.length ? dataMaping.interior.map((item, i)=>(
                  <Avatar
                    className="me-2 border rounded overflow-hidden"
                    style={{objectFit:"fill"}}
                    shape="square"
                    size={65}
                    src={item}
                ></Avatar>
                )) :
                  <h6 className="text-muted mt-3" style={{fontSize:"9px"}}>Foto interior tidak tersedia</h6>
                }
              </div>
            </Col>
          </Row>
        </div>
        
      </Modal>
    </>
    )
}

export default ModalInfoPengantaranMutasi;
