import React, { Component, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import GoogleMapReact from "google-map-react";
import { useLocation } from "react-router-dom";

const ProfileMap = ({}) => {
  const [state, setState] = useState({
    center: [-6.2087634, 106.845599],
    zoom: 13,
    draggable: true,
    gmapRefs: null,
    gmapServices: null,
    mapOptions: {
      zoomControl: false,
      scaleControl: false,
      fullscreenControl: false,
      mapId: "4ef51c620988c579",
      styles: undefined,
    },
  });

  const handleApiLoaded = async (map, maps) => {
    setState({ ...state, gmapRefs: map, gmapServices: maps });
  };

  return (
    <GoogleMapReact
      draggable={state.draggable}
      options={state.mapOptions}
      center={state.center}
      zoom={state.zoom}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={async ({ map, maps }) => {
        await handleApiLoaded(map, maps);
      }}
    ></GoogleMapReact>
  );
};

export default ProfileMap;
