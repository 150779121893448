import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  InputGroup,
  Card,
  Row,
  Col,
  FloatingLabel,
  FormControl,
  Dropdown,
  Button,
} from "react-bootstrap";
import Geocode from "react-geocode";
import useBreakpoint from "../responsiveUtils/useBreakpoint";
import { openMap, closeMap, hideMapButtonBack } from "../layouts/layout_helper";

import PlacesAutocomplete from "react-places-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { getMapSearch } from "../../redux/action/map_action";

// icons
import { FaSearch, FaMapMarkerAlt } from "react-icons/fa";
import { IoCheckmark } from "react-icons/io5";
import { AiOutlineAim, AiOutlineLeft } from "react-icons/ai";
import { MdOutlineEditLocationAlt, MdClose } from "react-icons/md";
import { FiChevronDown } from "react-icons/fi";

function SearchAddress(props) {
  const dispatch = useDispatch();
  const { isMobile } = useBreakpoint();
  const [favorite, setFavorite] = useState([
    {
      key: "1",
      title: "Superman",
      phone: "+6212 3456 7891",
      content:
        "Modernland, Jl. Honoris Raya No.6, RT.001/RW.006, Klp. Indah, Kec. Tangerang, Kota Tangerang, Banten 15117",
      isSelect: true,
    },
    {
      key: "2",
      title: "Batman",
      phone: "+6212 3456 7891",
      content:
        "Modernland, Jl. Honoris Raya No.6, RT.001/RW.006, Klp. Indah, Kec. Tangerang, Kota Tangerang, Banten 15117",
      isSelect: false,
    },
  ]);

  const [address, setaddress] = useState("");
  const [show, setShow] = useState(false);
  const [showInput, setShowInput] = useState(true); // for mobile

  const handleClose = () => {
    if (isMobile) {
      setShowInput(false);
      closeMap();
    }
    setShow(false);
  };
  const handleShow = () => {
    if (!props.disabled) {
      if (isMobile) setShowInput(true);

      // modalDisclosure.open();
      setShow(true);
    }
  };

  const handleChange = (address) => {
    setaddress(address);
  };

  // disabling modal overlay
  useEffect(() => {
    const mdBackdrop = document.querySelector(".modal-backdrop");
    const modal = document.querySelector(".modal");

    if (isMobile && mdBackdrop) {
      mdBackdrop.style.display = "none";

      if (props.address) {
        modal.style.height = "150px";
        modal.style.overflowY = "hidden";
      }

      if (showInput) {
        modal.style.height = "100%";
        modal.style.overflowY = "visible";
      }
    }
  }, [show, props.address, showInput]);

  const handleSelect = async (address, placeId, suggestion) => {
    // await dispatch(getMapSearch(address)); //before
    await dispatch(getMapSearch(placeId));
    if (props.getPropsValue) {
      await props.getPropsValue(address, placeId, suggestion);
    }
    if (isMobile) {
      setShowInput(false);
    } else {
      setShow(false);
    }
    setaddress(address)
  };

  const searchOptions = {
    componentRestrictions: { country: ["idn"] },
  };

  const getLocation = () => {
    if (!navigator.geolocation) {
      console.log("Geolocation is not supported by your browser");
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_API_KEY);
          Geocode.setLanguage("id");
          Geocode.fromLatLng(
            position.coords.latitude,
            position.coords.longitude
          ).then(
            async (response) => {
              const address = response.results[0].formatted_address;
              await dispatch(getMapSearch(address));
              if (props.getPropsValue) {
                await props.getPropsValue(address);
              }
              if (isMobile) {
                setShowInput(false);
              } else {
                setShow(false);
              }
            },
            (error) => {
              console.error(error);
            }
          );
        },
        () => {
          console.log("Unable to retrieve your location");
        }
      );
    }
  };

  return (
    <>
      <Form.Group
        className="form-group"
        onClick={() => {
          handleShow();
          if (isMobile) {
            openMap();
            hideMapButtonBack();
          }
        }}
      >
        {props.address === null ? (
          <Form.Control
            type="text"
            placeholder={props.placeholder}
            className="form-white"
            readOnly
          />
        ) : (
          <div
            className={`top d-flex ${
              props.disabled ? "filled-disabled" : "filled"
            }`}
          >
            <div className="w-100">
              <p className="fw-bold">{props.address && props.address.split(",")[0]}</p>
              <h6 className="text-muted">
                {props.address && props.address.replace(/^[^,]+, */, "")}
              </h6>
            </div>
            {/* <div className="opacity-50 mt-2">
              <MdOutlineEditLocationAlt />
            </div> */}
          </div>
        )}
      </Form.Group>
      {/* except mobile, render this modal */}
      <Modal
        contentClassName={` ${
          !isMobile ? "bg-ice" : " border-0 shadow-md p-1 px-2"
        }`}
        style={{ top: isMobile ? 50 : undefined }}
        show={show}
        onHide={handleClose}
        className="p-3"
        centered={!isMobile ? true : false}
      >
        {!isMobile ? (
          <Modal.Header closeButton className="bg-white">
            <strong>{props.title}</strong>
          </Modal.Header>
        ) : (
          <>
            {props.address === null ? (
              <div className="d-flex p-2">
                <h4 className="">{props.title} </h4>
                <h6
                  className="text-primary underline ms-auto mt-1"
                  onClick={handleClose}
                >
                  Kembali
                </h6>
                {/* <MdClose
                  className="ms-auto me-2 pointer"
                  onClick={handleClose}
                /> */}
              </div>
            ) : (
              <div
                className={`top d-flex p-2`}
                onClick={() => setShowInput((prev) => !prev)}
              >
                <div className="w-100">
                  <p className="fw-bold">{props.address.split(",")[0]}</p>
                  <h6 className="text-muted" style={{ marginTop: -10 }}>
                    {props.address.replace(/^[^,]+, */, "")}
                  </h6>
                </div>
                <h6
                  className="text-primary underline ms-auto mt-1"
                  onClick={handleClose}
                >
                  Kembali
                </h6>
                {/* <div className="mt-1">
                  <FiChevronDown />
                </div> */}
              </div>
            )}
          </>
        )}

        {showInput && (
          <PlacesAutocomplete
            searchOptions={searchOptions}
            value={address}
            onChange={handleChange}
            onSelect={handleSelect}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div>
                <InputGroup className="cstm-input ">
                  <InputGroup.Text className="border-0">
                    <FaSearch />
                  </InputGroup.Text>
                  <FloatingLabel
                    label={props.placeholder}
                    style={{
                      width: isMobile ? "87%" : "92%",
                      fontSize: "12px",
                    }}
                  >
                    <Form.Control
                      {...getInputProps({
                        placeholder: props.placeholder,
                        className: "location-search-input",
                      })}
                      className="border-0"
                      type="text"
                      placeholder={props.placeholder}
                    />
                  </FloatingLabel>
                </InputGroup>
                <div
                  className="pointer fs-13 py-2 ms-2 fw-medium"
                  onClick={getLocation}
                >
                  <AiOutlineAim /> Gunakan lokasi saat ini
                </div>
                {/* {console.log(suggestions)} */}
                <div className="fs-13">
                  {loading && <div>Loading...</div>}
                  {suggestions.map((suggestion, i) => {
                    const className = suggestion.active ? "p-2 active" : "p-2";
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? { backgroundColor: "#fafafa", cursor: "pointer" }
                      : { backgroundColor: "#ffffff", cursor: "pointer" };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                        key={i}
                      >
                        <div>
                          <FaMapMarkerAlt className="text-muted" />{" "}
                          {suggestion.description}
                        </div>
                      </div>
                    );
                  })}
                </div>
                {/* {suggestions.length === 0 ? (
                <>
                  <hr className="my-1" />
                  <div className="scrollable-xs">
                    <p className="ms-1 my-1">Alamat Favorit</p>
                    {favorite.map((data, i) => (
                      <Card
                        key={i}
                        className={
                          data.isSelect
                            ? "border-fleet border-secondary"
                            : "border-fleet"
                        }
                      >
                        <Row className="card-body py-2">
                          <Col xs={11} className="align-self-center">
                            <p className="fw-bold mb-0">{data.title}</p>
                            <p className="fw-medium mb-0">{data.phone}</p>
                            <h6 className="mb-0 text-muted">{data.content}</h6>
                          </Col>
                          <Col xs={1} className="align-self-center">
                            {data.isSelect ? (
                              <IoCheckmark className="text-secondary" />
                            ) : null}
                          </Col>
                        </Row>
                      </Card>
                    ))}
                  </div>
                </>
              ) : null} */}
              </div>
            )}
          </PlacesAutocomplete>
        )}
      </Modal>
      {/* {isMobile && <Modal show={show} className=""></Modal>} */}
    </>
  );
}

export default SearchAddress;
