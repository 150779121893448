import React, { useState } from "react";
import {
  Modal,
  Form,
  InputGroup,
  FloatingLabel,
  Button,
} from "react-bootstrap";
import { Checkbox, message } from "antd";
import BalanceApi from "../../api/balance-api";
import { resetAuth } from "../../redux/action/auth_action";
import { useSelector, useDispatch } from "react-redux";
import useBreakpoint from "../responsiveUtils/useBreakpoint";

// icon
import icon_key from "../../assets/images/icon-key.svg";
import { MdChevronRight, MdClose } from "react-icons/md";
import icon_lock_circle_blue from "../../assets/images/icon_lock_circle_blue.svg";

const PasswordWithdraw = ({
  show,
  closeModal,
  setShowModal,
  amountDisburse,
}) => {
  const { isMobile } = useBreakpoint();
  const dispatch = useDispatch();
  const bankAccounts = useSelector((st) => st.balanceReducer.bankAccounts);
  const auth = useSelector((st) => st.authReducer);
  const selectedBankAccount = useSelector(
    (st) => st.balanceReducer.selectedBankAccount
  );

  const [inputState, setInputState] = useState({ password: "" });
  const [showPassword, setShowPassword] = useState(false);

  const getMainAccounts = () => {
    if (selectedBankAccount.id) {
      return selectedBankAccount;
    }
    return bankAccounts?.data?.find((account) => account.is_main > 0);
  };

  const createDisbursement = async () => {
    try {
      const api = new BalanceApi();

      let body = {};
      body["amount"] = amountDisburse;
      body["password"] = inputState.password;
      body["rekening_id"] = getMainAccounts()?.id;

      console.log(body);

      const response = await api.createDisbursement(auth.access_token, body);

      if (response.status === "success") {
        message.success(response.message);
        setShowModal({ success: true });
      }

      if (response.status === 401) {
        dispatch(resetAuth());
      }
    } catch (error) {
      console.log(error?.response);
    }
  };

  return (
    <Modal
      contentClassName="rounded-card p-2"
      show={show}
      onHide={closeModal}
      className="p-3"
      centered
    >
      <div className="d-flex p-2">
        <p className="fw-bold">Verifikasi</p>
        <MdClose className="ms-auto me-2 pointer" onClick={closeModal} />
      </div>
      <Modal.Body>
        <div className="">
          <img src={icon_lock_circle_blue} className="mb-2" alt="" />
          <h4 className="fw-bold">Masukan Kata Sandi</h4>
          <h6 className="text-muted">
            untuk Keamanan kamu, Masukan Kata sandi kamu untuk dapat melanjutkan
            Penarikan dana
          </h6>
          <InputGroup className="border rounded-input">
            <InputGroup.Text
              className="border-0 rounded-input"
              style={{ backgroundColor: "#f8f9fa" }}
            >
              <img src={icon_key} alt="" />
            </InputGroup.Text>
            <FloatingLabel
              className="rounded-input"
              label="Masukkan kata sandi"
              style={{ fontSize: "12px", width: isMobile ? "75%" : "85%" }}
            >
              <Form.Control
                type={showPassword ? "text" : "password"}
                className="border-0 rounded-input"
                placeholder="m"
                // value={
                //   activeStep.id === 1
                //     ? body.old_password
                //     : body.new_password
                // }
                style={{ height: "50px" }}
                onChange={(ev) =>
                  setInputState({ ...inputState, password: ev.target.value })
                }
              ></Form.Control>
            </FloatingLabel>
          </InputGroup>
          <Checkbox
            checked={showPassword}
            onChange={() => setShowPassword((prev) => !prev)}
            className="ms-2 mt-2"
          >
            Tampilkan Password
          </Checkbox>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <p
          className="text-primary pointer me-4"
          onClick={() => setShowModal({ ensure: true })}
        >
          Kembali
        </p>
        <Button
          disabled={inputState.password ? false : true}
          size="sm"
          variant="primary"
          className="float-end rounded-pill px-4 py-2"
          onClick={createDisbursement}
        >
          Verifikasi
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PasswordWithdraw;
