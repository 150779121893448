import React from "react";
import { Col } from "react-bootstrap";
import util from "../../../../helper/util";
import ModalRincianPembayaran from "../Modal/ModalRincianPembayaran";


const RincianPembayaran = ({trxDetail}) => {
    return(
        <Col className="rounded-card bg-white my-2">
            <div className="pt-3 pb-2 border-bottom d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-end">
                    <h5 className="fw-bold me-2">Rincian Pembayaran</h5>
                    {/* <h6 className="px-2 py-1 rounded-pill" style={{color:"#FF4949", backgroundColor:"#FFE4E4", fontSize:"10px"}}>Kurang Bayar</h6> */}
                </div>
                <ModalRincianPembayaran trxDetail={trxDetail}/>
            </div>
            <div className="mt-3 mb-1">
                <h6 className="fw-bold mb-3">
                    Harga Layanan
                </h6>
                <div className="d-flex justify-content-between">
                    <h6 className="text-muted">Tarif Dasar Layanan</h6>
                    <h6 className="text-muted">Rp {util.thousandSeparatorPure(trxDetail?.data?.service_price)}</h6>
                </div>
            </div>
            <div className="border-top pt-3 pb-1 d-flex justify-content-between align-items-center">
                <h6 className="fw-bold" style={{fontSize:"16px"}}>TOTAL PEMBAYARAN</h6>
                <h6 className="fw-bold" style={{fontSize:"16px", color:"#2BBECB"}}>
                    {trxDetail?.data?.total_customer_paid ? "Rp"+util.thousandSeparatorPure(trxDetail?.data?.total_customer_paid) : 0 }
                </h6>
            </div>
        </Col>
    );
}

export default RincianPembayaran;
