import { message } from "antd";
import React, {
  useEffect,
  useRef,
  useState
} from "react";
import {
  Card,
  Row,
  Col,
  Modal,
  Button
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { requestNewDoc } from "../../redux/action/Invoice/invoice_action";

const Document = (props) => {
  const dispatch = useDispatch()

  const { data, setData, handleLoad } = props;
  const [modal, setModal] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState(null);

  const handleSubmit = async (idDoc) => {
    try {
      await dispatch(requestNewDoc(idDoc))
      setModal(false)
      await handleLoad()
      message.success('Berhasil request document baru')
    } catch (error) {
      message.error('Gagal request document baru')
    }
  }
  return (
    <Col xs={12} className='my-4'>
      <h5 className="fw-bold">Dokumen Partner</h5>
      {data?.map(row =>
        <div className="bg-white rounded-10 p-3 mb-3" key={row?.id}>
          <Row className="align-items-center">
            <Col xs={10}>
              <div className="px-2">
                <p className="text-muted label mb-0">Nama Dokumen</p>
                <p className="mb-0">{row?.document_name}</p>
              </div>
            </Col>
            <Col xs={2}>
              <div className="px-2">
                <p className="mb-0">{row?.created_at?.split('T')[0]}</p>
              </div>
            </Col>
            <Col xs={12} className="border border-light my-3" />
            <Col xs={9}>
              <div className="px-2">
                <p className="text-muted label mb-0">File Dokumen</p>
                <a href={row?.document_path} className="mb-0 text-primary">{row?.file_name}</a>
              </div>
            </Col>
            {!row?.need_update && (
              <Col xs={3}>
                <div className="px-2">
                  <p 
                    className="mb-0 text-primary" 
                    onClick={() => {
                      setSelectedDoc(row?.id)
                      setModal(!modal)
                    }}
                  >
                    Request Ubah Dokumen
                  </p>
                </div>
              </Col>
            )}
          </Row>
        </div>
      )}

      {/* {data.length === 2 && (
        <>
          <h5 className="fw-bold mt-6">Update Dokumen</h5>
          <div className="bg-white rounded-10 p-3 mb-3">
            <Col xs={12}>
              <div className="px-2">
                <p className="text-muted label mb-0 text-center">Tidak ada update dokumen</p>
              </div>
            </Col>
          </div>
        </>
      )} */}


      {modal && (
        <Modal
          show={modal}
          onHide={() => setModal(!modal)}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className='rounded-pill'
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h2>Request Dokumen</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <small className="text-muted">Pastikan anda sudah melakukan obrolan dengan partner untuk mengupload dokumen baru.</small>
          </Modal.Body>
          <Modal.Footer>
            <Button className="rounded-pill mx-auto" onClick={() => handleSubmit(selectedDoc)}>Request Dokumen Baru</Button>
          </Modal.Footer>
        </Modal>
      )}

    </Col>
  )
}

export default Document;