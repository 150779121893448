const initialState = {
  data: [],
  meta: {},
  all: [],
  filter: {},
  loading: false,
};

export const invoiceReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "SET_INVOICE":
      return { ...state, ...payload };
    case "RESET_INVOICE":
      return { ...state, data: [], meta: {}, all: [] };
    default:
      return state;
  }
};
