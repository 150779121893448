import React from 'react'
import { Row, Col } from 'react-bootstrap'
import moment from 'moment'


const ActivityBilling = ({data}) => {

  const handleMapingStatus = (param,val) => {
    let result = {
      status: "-",
      color: ""
    }
    if(val === 1){
      result.status = "Menunggu Konfirmasi"
      result.color = "#FFAC14"
    }
    if(val === 2){
      result.status = "Proses Pengecekan"
      result.color = "#209AB7"
    }
    if(val === 3){
      result.status = "Proses Pembayaran"
      result.color = "#FF1D8C"
    }
    if(val === 4){
      result.status = "Penagihan Selesai"
      result.color = "#0FAA4B"
    }
    if(val === 5){
      result.status = "Penagihan Batal"
      result.color = "#FF4949"
    }
    
    if(param === 'status') return result.status
    if(param === 'color') return result.color
  }
  
  return (
    <div className="mb-3">
      <h5 className="fw-bold">Aktivitas Penagihan</h5>
      <div className="bg-white rounded-card w-full p-3">
        <Row>
          <Col xs={6}>
            <div className="px-2">
              <p className="text-muted label mb-1">Status Penagihan</p>
              <p className="mb-0" style={{color: handleMapingStatus('color', data?.status)}}>{handleMapingStatus('status',data?.status)}</p>
            </div>
          </Col>
          <Col xs={6}>
            <div className="px-2">
              <p className="text-muted label mb-1">Perubahan Terakhir</p>
              <p className="mb-0">{data?.updated_at ? moment(data?.updated_at).format('DD/MM/YYYY HH:mm') : '-'}</p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default ActivityBilling