import { Modal, Button, Col } from "react-bootstrap";
import { MdClose } from "react-icons/md";

const RequestDocument = (props) => {
  /*======================== Hooks ======================== */

  /*======================== Props ======================== */

  const { isOpen, data, handleClose, handleRequestDoc } = props;

  /*======================== UseState ======================== */

  /*======================== Handler ======================== */
  const handleOnClose = () => {
    handleClose();
  };

  /*======================== UseEffect ======================== */

  /*======================== Return ======================== */

  return (
    <Modal
      contentClassName="rounded-card p-2"
      show={isOpen}
      onHide={handleOnClose}
      className="p-3"
      size="sm"
      centered
    >
      <div className="d-flex p-2 border-bottom">
        <h2 className="fw-nornal">Request Dokumen</h2>
        <MdClose className="ms-auto me-2 pointer" onClick={handleOnClose} />
      </div>
      <Modal.Body>
        <div>
          <p className="text-muted">
            Pastikan anda sudah melakukan obrolan dengan partner untuk
            mengupload dokumen baru
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size="md"
          className="rounded-10 text-capitalize w-100"
          onClick={() => handleRequestDoc()}
        >
          Request Dokumen Baru
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RequestDocument;
