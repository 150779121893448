import React from "react";

const InfoIntegrasi = ({ infoBarClassName, text, type }) => {
  return (
    <div className="position-relative">
        <div className="position-absolute" style={{ left: "9%", top: "40%" }}>
            {text}
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          // width="100%"
          // height="46.812"
          viewBox="0 0 566.312 46.812"
          className={infoBarClassName}
        >
            <defs>
                <linearGradient
                id="linear-gradient"
                x1="0.047"
                y1="1.692"
                x2="1"
                gradientUnits="objectBoundingBox"
                >
                  {/* D7EDF0 F3F8F8 */}
                  {/* 7FD0A0 E9F4f0 */}
                  {/* EE7777 F2DCDC */}
                <stop offset="0" stop-color={type === "info" ? "#D7EDF0" : type === "success" ? "#7FD0A0" : "#EE7777"} /> 
                <stop offset="1" stop-color={type === "info" ? "#F3F8F8" : type === "success" ? "#E9F4f0" : "#F2DCDC"} />
                </linearGradient>
            </defs>
            <g
            id="Group_3836"
            data-name="Group 3836"
            transform="translate(-31 -375)"
          >
            <g
              id="Group_2447"
              data-name="Group 2447"
              transform="translate(31 375)"
            >
              <rect
                id="Rectangle_994"
                data-name="Rectangle 994"
                width="566.312"
                height="46.811"
                rx="10"
                transform="translate(0)"
                fill="url(#linear-gradient)"
              />
              {/* #209AB7 */}
              {/* #52C17D */}
              {/* #EC4C4C */}
              <path
                id="Intersection_11"
                data-name="Intersection 11"
                d="M10,46.812a10,10,0,0,1-10-10V10A10,10,0,0,1,10,0H33.353A35.849,35.849,0,0,1,38.17,18a35.956,35.956,0,0,1-14.4,28.807Z"
                transform="translate(0 0)"
                fill={type === "info" ? "#209AB7" : type === "success" ? "#52C17D" : "#EC4C4C"}
                opacity="0.27"
              />
            </g>
            <path
              id="Intersection_12"
              data-name="Intersection 12"
              d="M0,0H40.544A20.893,20.893,0,0,1,20.273,15.844,20.894,20.894,0,0,1,0,0Z"
              transform="translate(41.695 375)"
              fill={type === "info" ? "#209AB7" : type === "success" ? "#52C17D" : "#EC4C4C"}
              opacity="0.08"
            />
            <path
              id="Path_2026"
              data-name="Path 2026"
              d="M-17243.875,7517.78v6.44"
              transform="translate(17295.355 -7121.854)"
              fill="none"
              stroke="#fff"
              stroke-linecap="round"
              stroke-width="4"
            />
            <path
              id="Path_2027"
              data-name="Path 2027"
              d="M-17243.875,7517.856v-.076"
              transform="translate(17295.355 -7126.945)"
              fill="none"
              stroke="#fff"
              stroke-linecap="round"
              stroke-width="4"
            />
          </g>
        </svg>
    </div>
 )
}

export default InfoIntegrasi;
