import React, { useState, useContext, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Accordion,
  useAccordionButton,
  AccordionContext,
  InputGroup,
  FloatingLabel,
  Form,
} from "react-bootstrap";
import PriceDetail from "../modalPayment/PriceDetail";
import {
  setActiveEventKey,
  setCustomPhoneNumber,
} from "../../redux/action/payment_list_action";
import {
  updatePickupDetail,
  findTotalPrice,
} from "../../redux/action/sewakendaraan_action";
import OrderSendApi from "../../api/order-send-api";
import { updateDetail } from "../../redux/action/kirimbarang_action";
import { setActivePaymentList } from "../../redux/action/active_payment_action";
import util from "../../helper/util";
import useBreakpoint from "../responsiveUtils/useBreakpoint";

import { connect, useDispatch, useSelector } from "react-redux";
import payment_helper from "../../helper/payment_helper";
// icons
import {
  IoAdd,
  IoCheckmarkCircle,
  IoChevronUpOutline,
  IoChevronDownOutline,
} from "react-icons/io5";

function ContextAwareToggle({
  eventKey,
  callback,
  image,
  title,
  child,
  disabled,
  maxPrice,
}) {
  // const { activeEventKey } = useContext(AccordionContext);
  // const [activeEventKey, setActiveEventKey] = useState(null);
  const activeEventKey = useSelector(
    (st) => st.paymentListReducer.activeEventKey
  );
  // console.log({ activeEventKey });
  const dispatch = useDispatch();

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  const renderPhoneField = () => {
    const ids = [13, 14, 47]; // dana, spay, ovo
    // alert("here");
    return isCurrentEventKey && ids.some((el) => eventKey === el)
      ? true
      : false;
  };

  return (
    <div
      className={
        isCurrentEventKey
          ? "pointer payment-list active"
          : disabled
          ? "cursor-not-allowed bg-ice payment-list"
          : "pointer payment-list"
      }
      onClick={() => {
        if (!disabled) {
          dispatch(setActiveEventKey(eventKey));
          decoratedOnClick();
        }
      }}
    >
      <Row className="card-body py-2">
        {child ? (
          <>
            <Col xs={11} className="align-self-center">
              <p className="fw-bold mb-0">{title}</p>
            </Col>
            <Col xs={1} className="align-self-center">
              {isCurrentEventKey ? (
                <IoChevronUpOutline />
              ) : (
                <IoChevronDownOutline />
              )}
            </Col>
          </>
        ) : (
          <>
            <Col xs={8} className="align-self-center">
              <Row>
                <Col xs={3} className="align-self-center">
                  <img
                    src={image}
                    alt=""
                    className="img-fluid"
                    style={{ height: "35px" }}
                  />
                </Col>
                <Col xs={9} className="align-self-center">
                  <p className="fw-bold mb-0">{title}</p>
                </Col>
              </Row>
            </Col>
            <Col xs={4} className="align-self-center text-end">
              {isCurrentEventKey ? (
                <h6 className="fw-bold mb-0 text-secondary">
                  <IoCheckmarkCircle /> TERPILIH
                </h6>
              ) : disabled ? (
                <h6 className="mb-0">
                  Limit Maksimum Rp.{util.thousandSeparatorPure(maxPrice)}
                </h6>
              ) : null}
            </Col>
          </>
        )}
        {renderPhoneField() && (
          <InputGroup
            className="border rounded-input mt-2"
            style={{ width: "100%" }}
          >
            <FloatingLabel
              className="rounded-input"
              label={`Nomor ${title}`}
              style={{ fontSize: "12px", width: "100%" }}
            >
              <Form.Control
                type="text"
                className="border-0 rounded-input"
                placeholder="m"
                onChange={(ev) => {
                  dispatch(setCustomPhoneNumber(ev.target.value));
                }}
                //  defaultValue={auth?.name}
                style={{ height: "50px" }}
              ></Form.Control>
            </FloatingLabel>
          </InputGroup>
        )}
      </Row>
    </div>
  );
}

function SelectPayment(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      // dispatch(setCustomPhoneNumber(null));
      dispatch(setActiveEventKey(null));
    };
  }, []);

  const handleContent = (payment_id) => {
    let component = [];
    const result = [...payment_helper?.getContent(payment_id)];
    for (const iterator of result) {
      let content = [];
      [...iterator.step].map((x, index) => {
        content.push(<li key={index}>{x}</li>);
      });
      component.push(
        <Accordion defaultActiveKey="0">
          <Card className="my-2" key={iterator.type}>
            <ContextAwareToggle
              title={iterator.type}
              eventKey={iterator.type}
              child
            />
            <Accordion.Collapse eventKey={iterator.type}>
              <Card.Body>
                <ul className="mb-0 text-muted h6">{content}</ul>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      );
    }
    if (result.length === 0) {
      component.push(<div></div>);
    }
    return component;
  };

  const groupPayment = (type) => {
    let array = [];

    [...props.paymentListReducer.data].map((data) => {
      if (data.type === type && (data.status === 1 || data.status === 2)) {
        array.push(data);
      }
    });
    return array;
  };

  const doCheckMaximumPayment = (paymentData) => {
    if (props.activeMenu === "SewaKendaraan") {
      if (
        props.totalPrice.final_price_with_admin_fee <= paymentData.max_price ||
        props.totalPrice.final_price <= paymentData.max_price
      ) {
        return false;
      }
      return true;
    } else if (props.activeMenu === "KirimBarang") {
      if (
        props.kirimBarang.detail_price?.final_price <= paymentData.max_price
      ) {
        return false;
      }
      return true;
    }
  };

  return (
    <Accordion defaultActiveKey="0">
      {groupPayment(1).length !== 0 ? (
        <p className="mb-2 mt-3 fw-medium">Virtual Account</p>
      ) : null}
      {groupPayment(1).map((data, i) => (
        <Card
          className="border-0"
          key={i}
          hidden={data.status > 2}
          onClick={() => {
            let isMaximum = doCheckMaximumPayment(data);

            if (!isMaximum) {
              if (data.status === 1) {
                props.handlePaymentMethod(
                  data.id,
                  data.logo,
                  data.type,
                  data.name
                );
              }
            }
          }}
        >
          <ContextAwareToggle
            image={data.logo}
            title={data.name}
            eventKey={data.id}
            disabled={doCheckMaximumPayment(data)}
            maxPrice={data.max_price}
          />
          {/* <Accordion.Collapse eventKey={data.id}>
            <Card.Body>{handleContent(data.id)}</Card.Body>
          </Accordion.Collapse> */}
        </Card>
      ))}
      {groupPayment(2).length !== 0 ? (
        <p className="mb-2 mt-3 fw-medium">Credit Card</p>
      ) : null}
      {groupPayment(2).map((data, i) => (
        <Card
          className="border-0"
          key={i}
          hidden={data.status > 2}
          onClick={() => {
            let isMaximum = doCheckMaximumPayment(data);

            if (!isMaximum) {
              if (data.status === 1) {
                props.handlePaymentMethod(
                  data.id,
                  data.logo,
                  data.type,
                  data.name
                );
              }
            }
          }}
        >
          <ContextAwareToggle
            image={data.logo}
            title={data.name}
            eventKey={data.id}
            disabled={doCheckMaximumPayment(data)}
            maxPrice={data.max_price}
          />
          {/* <Accordion.Collapse eventKey={data.id}>
            <Card.Body>{handleContent(data.id)}</Card.Body>
          </Accordion.Collapse> */}
        </Card>
      ))}
      {groupPayment(3).length !== 0 ? (
        <p className="mb-2 mt-3 fw-medium">E-Wallet</p>
      ) : null}
      {groupPayment(3).map((data, i) => (
        <Card
          className="border-0"
          key={i}
          hidden={data.status > 2}
          onClick={() => {
            let isMaximum = doCheckMaximumPayment(data);

            if (!isMaximum) {
              if (data.status === 1) {
                props.handlePaymentMethod(
                  data.id,
                  data.logo,
                  data.type,
                  data.name
                );
              }
            }
          }}
        >
          <ContextAwareToggle
            image={data.logo}
            title={data.name}
            eventKey={data.id}
            disabled={doCheckMaximumPayment(data)}
            maxPrice={data.max_price}
          />
          {/* <Accordion.Collapse eventKey={data.id}>
            <Card.Body>{handleContent(data.id)}</Card.Body>
          </Accordion.Collapse> */}
        </Card>
      ))}
      {groupPayment(4).length !== 0 ? (
        <p className="mb-2 mt-3 fw-medium">QRIS</p>
      ) : null}
      {groupPayment(4).map((data, i) => (
        <Card
          className="border-0"
          key={i}
          hidden={data.status > 2}
          onClick={() => {
            let isMaximum = doCheckMaximumPayment(data);

            if (!isMaximum) {
              if (data.status === 1) {
                props.handlePaymentMethod(
                  data.id,
                  data.logo,
                  data.type,
                  data.name
                );
              }
            }
          }}
        >
          <ContextAwareToggle
            image={data.logo}
            title={data.name}
            eventKey={data.id}
            disabled={doCheckMaximumPayment(data)}
          />
          {/* <Accordion.Collapse eventKey={data.id}>
            <Card.Body>{handleContent(data.id)}</Card.Body>
          </Accordion.Collapse> */}
        </Card>
      ))}
      {groupPayment(5).length !== 0 ? (
        <p className="mb-2 mt-3 fw-medium">Retail Outlet</p>
      ) : null}
      {groupPayment(5).map((data, i) => (
        <Card
          className="border-0"
          key={i}
          hidden={data.status > 2}
          onClick={() => {
            let isMaximum = doCheckMaximumPayment(data);

            if (!isMaximum) {
              if (data.status === 1) {
                props.handlePaymentMethod(
                  data.id,
                  data.logo,
                  data.type,
                  data.name
                );
              }
            }
          }}
        >
          <ContextAwareToggle
            image={data.logo}
            title={data.name}
            eventKey={data.id}
            disabled={doCheckMaximumPayment(data)}
            maxPrice={data.max_price}
          />
          {/* <Accordion.Collapse eventKey={data.id}>
            <Card.Body>{handleContent(data.id)}</Card.Body>
          </Accordion.Collapse> */}
        </Card>
      ))}
      {groupPayment(6).length !== 0 ? (
        <p className="mb-2 mt-3 fw-medium">Cardless/Paylater</p>
      ) : null}
      {groupPayment(6).map((data, i) => (
        <Card
          className="border-0"
          key={i}
          hidden={data.status > 2}
          onClick={() => {
            let isMaximum = doCheckMaximumPayment(data);

            if (!isMaximum) {
              if (data.status === 1) {
                props.handlePaymentMethod(
                  data.id,
                  data.logo,
                  data.type,
                  data.name
                );
              }
            }
          }}
        >
          <ContextAwareToggle
            image={data.logo}
            title={data.name}
            eventKey={data.id}
            disabled={doCheckMaximumPayment(data)}
            maxPrice={data.max_price}
          />
          {/* <Accordion.Collapse eventKey={data.id}>
            <Card.Body>{handleContent(data.id)}</Card.Body>
          </Accordion.Collapse> */}
        </Card>
      ))}

      {props.auth.is_special_customer > 0 && groupPayment(7).length !== 0 ? (
        <p className="mb-2 mt-3 fw-medium">Paylater</p>
      ) : null}
      {props.auth.is_special_customer > 0 &&
        groupPayment(7).map((data, i) => (
          <Card
            className="border-0"
            key={i}
            hidden={data.status > 2}
            onClick={() => {
              // let isMaximum = doCheckMaximumPayment(data);

              // if (!isMaximum) {
              if (data.status === 1) {
                props.handlePaymentMethod(
                  data.id,
                  data.logo,
                  data.type,
                  data.name
                );
                // }
              }
            }}
          >
            <ContextAwareToggle
              image={data.logo}
              title={data.name}
              eventKey={data.id}
              // disabled={doCheckMaximumPayment(data)}
              maxPrice={data.max_price}
            />
            {/* <Accordion.Collapse eventKey={data.id}>
            <Card.Body>{handleContent(data.id)}</Card.Body>
          </Accordion.Collapse> */}
          </Card>
        ))}
    </Accordion>
  );
}

function TabPayment(props) {
  const { isMobile } = useBreakpoint();

  // console.log(props.activeMenu);

  const dispatch = useDispatch();
  const [favoritPayment, setFavoritPayment] = useState([
    {
      key: "1",
      title: "BCA Virtual Account",
      type: 1,
      id: 29,
      content: [
        "Transaksi ini akan otomatis menggantikan tagihan  BCA virtual akun yang belum terbayar",
        "Dapatkan kode & panduan Pembayaran setelah menyelesai- kan Pesanan",
      ],
      isSelect: true,
      image:
        "https://logos-download.com/wp-content/uploads/2017/03/BCA_logo_Bank_Central_Asia.png",
    },
    {
      key: "2",
      title: "Shopee Pay",
      type: 2,
      id: 14,
      content: [
        "Transaksi ini akan otomatis menggantikan tagihan  BCA virtual akun yang belum terbayar",
        "Dapatkan kode & panduan Pembayaran setelah menyelesai- kan Pesanan",
      ],
      isSelect: false,
      image:
        "https://1.bp.blogspot.com/-EmJLucvvYZw/X0Gm1J37spI/AAAAAAAAC0s/Dyq4-ko9Eecvg0ostmowa2RToXZITkbcQCLcBGAsYHQ/s400/Logo%2BShopeePay.png",
    },
  ]);
  const [isFavorite, setIsFavorite] = useState(true);

  const findTotalPriceWithPaymentMethod = (payment_method_id) => {
    let totalPricePayload = {
      vehicle_subtype_id: props.selectedVType.id,
      partner_service_id: props.selectedPartnerService.id,
      pickup_time: props.sewaKendaraanReducer.startDate.toISOString(),
      return_time: props.sewaKendaraanReducer.endDate.toISOString(),
      driver: props.detailOrder.driver_availability_rent_selected ? 1 : 0,
      helper: props.detailOrder.helper_availability_rent_selected
        ? props.detailOrder.total_helper_requested
        : 0,
      payment_method_id,
    };
    if (
      props.sewaKendaraanReducer.isSpecialPartnerSelected &&
      props.auth.is_special_customer > 0
    ) {
      totalPricePayload["customer_id"] = props.auth.id;
    }
    // alert("here");
    dispatch(findTotalPrice(totalPricePayload));
  };

  const hitSummaryWithPaymentMethod = async (payment_method_id) => {
    const api = new OrderSendApi();
    let payload = {};
    // arrange payload
    payload["send_category"] = props.kirimBarang.tipe;
    payload["date"] =
      props.kirimBarang.tipe === "INSTANT"
        ? new Date().toISOString()
        : props.kirimBarang.date;
    payload["is_return"] = 0;
    payload["send_destination"] = [];

    // pengambilan
    for(let i = 0; i<props.kirimBarang.pengambilan_new.length; i++){
      let pickupPayload = {};
      pickupPayload["destination_type"] = "PICKUP";
      pickupPayload["destination_order"] = i;
      pickupPayload["longitude"] = props.kirimBarang.pengambilan_new[i].lng;
      pickupPayload["latitude"] = props.kirimBarang.pengambilan_new[i].lat;
      pickupPayload["address"] = props.kirimBarang.pengambilan_new[i].lokasi;
      pickupPayload["location_description"] = props.kirimBarang.pengambilan_new[i].patokan;
      pickupPayload["cod_amount"] = null;
      pickupPayload["note"] = props.kirimBarang.pengambilan_new[i].catatan;
      pickupPayload["contact_name"] = props.kirimBarang.pengambilan_new[i].namaPenerima;
      pickupPayload["contact_phone_no"] = `0${props.kirimBarang.pengambilan_new[i].nomorTelepon}`;
      pickupPayload["item_type"] = props.kirimBarang.pengambilan_new[i].item_type;
      pickupPayload["place_id"] = props.kirimBarang.pengambilan_new[i].place_id;
      pickupPayload["address_name"] = props.kirimBarang.pengambilan_new[i].address_name;
      payload["send_destination"].push(pickupPayload);
    }

    // pengiriman
    props.kirimBarang.pengiriman.forEach((eachData, i) => {
      let dropoffPayload = {};
      dropoffPayload["destination_type"] = "DROPOFF";
      dropoffPayload["destination_order"] = i + props.kirimBarang.pengambilan_new.length;
      dropoffPayload["longitude"] = eachData.lng;
      dropoffPayload["latitude"] = eachData.lat;
      dropoffPayload["address"] = eachData.lokasi;
      dropoffPayload["location_description"] = eachData.patokan;
      dropoffPayload["cod_amount"] = eachData.nominalCod;
      dropoffPayload["note"] = eachData.catatan;
      dropoffPayload["contact_name"] = eachData.namaPenerima;
      dropoffPayload["contact_phone_no"] = `0${eachData.nomorTelepon}`;
      dropoffPayload["item_type"] = eachData.item_type;
      dropoffPayload["place_id"] = eachData.place_id;
      dropoffPayload["address_name"] = eachData.address_name;
      dropoffPayload["dropoff_reference"] = eachData.dropoff_reference;
      payload["send_destination"].push(dropoffPayload);
    });

    //back to pickup address
    if (props.kirimBarang.backToPickupAddress.toggled) {
      let backAddressPayload = {};
      backAddressPayload["destination_type"] = "DROPOFF";
      backAddressPayload["destination_order"] = payload["send_destination"].length;
      backAddressPayload["longitude"] = props.kirimBarang.backToPickupAddress.lng;
      backAddressPayload["latitude"] = props.kirimBarang.backToPickupAddress.lat;
      backAddressPayload["address"] = props.kirimBarang.backToPickupAddress.lokasi;
      backAddressPayload["location_description"] = props.kirimBarang.backToPickupAddress.patokan;
      backAddressPayload["cod_amount"] = props.kirimBarang.backToPickupAddress.nominalCod;
      // backAddressPayload["note"] = props.kirimBarang.backToPickupAddress.catatan;
      backAddressPayload["contact_name"] = props.kirimBarang.backToPickupAddress.namaPenerima;
      backAddressPayload["contact_phone_no"] = `0${props.kirimBarang.backToPickupAddress.nomorTelepon}`;
      // backAddressPayload["item_type"] = props.kirimBarang.backToPickupAddress.item_type;
      backAddressPayload["place_id"] = props.kirimBarang.backToPickupAddress.place_id;
      backAddressPayload["address_name"] = props.kirimBarang.backToPickupAddress.address_name;
      payload["send_destination"].push(backAddressPayload);
    }

    payload["sub_type_id"] = props.kirimBarang.selectedVType.id;
    payload["partner_service_id"] = props.kirimBarang.selectedPartnerService.id;
    payload["total_helper"] =
      props.kirimBarang.detailOrder.total_helper_requested;
    payload["insurance_fee"] = props.kirimBarang.detailOrder?.insurance_fee;
    payload["insurance_fee_id"] =
      props.kirimBarang.detailOrder?.insurance_fee_id;
    payload["payment_method_id"] = payment_method_id;
    const { data: summary } = await api.getOrderSummary(payload);
    dispatch(
      updateDetail({
        detailOrder: {
          ...props.kirimBarang.detailOrder,
          total_km: summary?.total_km,
          eta: summary?.eta,
        },
        detail_price: { ...summary?.detail_price },
      })
    );
  };

  return (
    <>
      <Card.Body className="py-0 scrollable">
        {!isMobile && <h3 className="fw-bold">Metode Pembayaran</h3>}
        {isFavorite ? (
          <div></div>
        ) : // <p className="mb-3">Metode Pembayaran yang terakhir digunakan</p>
        null}
        <div className="pb-3">
          {/* {isFavorite ? (
            <>
              {favoritPayment.map((data, i) => (
                <Card
                  key={i}
                  className={
                    data.id === props.payment_method_id
                      ? "border-fleet border-secondary"
                      : "border-fleet"
                  }
                >
                  <Row className="card-body py-2">
                    <Col
                      xs={11}
                      className="align-self-center"
                      onClick={(e) => {
                        props.dispatch(
                          updatePickupDetail({ payment_method_id: data.id })
                        );
                        props.dispatch(
                          setActivePaymentList({
                            payment_method_id: data.id,
                            logo: data.image,
                            payment_type: data.type,
                            payment_name: data.title,
                          })
                        );
                      }}
                    >
                      <Row>
                        <Col xs={2} className="align-self-center">
                          <img src={data.image} alt="" className="img-fluid" />
                        </Col>
                        <Col xs={10} className="align-self-center">
                          <p className="fw-bold mb-0">{data.title}</p>
                        </Col>
                      </Row>
                      <hr className="my-2" />
                      <ul className="mb-0 text-muted h6">
                        {data.content.map((item, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                    </Col>
                    <Col xs={1} className="align-self-center">
                      {data.id === props.payment_method_id ? (
                        <IoCheckmarkCircle className="text-secondary" />
                      ) : null}
                    </Col>
                  </Row>
                </Card>
              ))}
              <div className="d-grid mt-3">
                <Button
                  variant="outline-primary"
                  size="sm"
                  className="py-2 shadow-none"
                  onClick={() => setIsFavorite(false)}
                >
                  <IoAdd /> Metode Pembayaran Lainnya
                </Button>
              </div>
            </>
          ) : ( */}
          <SelectPayment
            auth={props.auth}
            activeMenu={props.activeMenu}
            kirimBarang={props.kirimBarang}
            paymentListReducer={props.paymentListReducer}
            payment_method_id={props.payment_method_id}
            totalPrice={props.totalPrice}
            handlePaymentMethod={(
              payment_method_id,
              logo,
              payment_type,
              payment_name
            ) => {
              if (props.activeMenu === "SewaKendaraan") {
                findTotalPriceWithPaymentMethod(payment_method_id);
              }
              if (props.activeMenu === "KirimBarang") {
                hitSummaryWithPaymentMethod(payment_method_id);
              }
              props.dispatch(updatePickupDetail({ payment_method_id }));
              props.dispatch(
                setActivePaymentList({
                  payment_method_id,
                  logo,
                  payment_type: payment_type,
                  payment_name: payment_name,
                })
              );
            }}
          />
          {/* // )} */}
        </div>
      </Card.Body>
      <Card.Footer className="bg-0 shadow-top border-top-0">
        {isMobile ? (
          <div className="px-1 mt-3 d-flex align-items-center justify-content-between">
            <h4>Harga</h4>
            <h4 className="fw-bold">
              <PriceDetail />
            </h4>
          </div>
        ) : (
          <Row className="px-1 mt-2 mb-3">
            <Col xs={6}>
              <h4>Harga</h4>
            </Col>
            <PriceDetail />
          </Row>
        )}
      </Card.Footer>
    </>
  );
}

const mapStateToProps = (state) => ({
  sewaKendaraanReducer: state.sewaKendaraanReducer,
  paymentListReducer: state.paymentListReducer,
  payment_method_id: state.sewaKendaraanReducer.payment_method_id,
  activeMenu: state.map.activeMenu,
  selectedVType: state.sewaKendaraanReducer.selectedVType,
  selectedPartnerService: state.sewaKendaraanReducer.selectedPartnerService,
  detailOrder: state.sewaKendaraanReducer.detailOrder,
  totalPrice: state.sewaKendaraanReducer.totalPrice,
  kirimBarang: state.kirimbarangReducer,
  auth: state.authReducer,
});
export default connect(mapStateToProps)(TabPayment);
