import { Row, Col } from "react-bootstrap";
import SideNav from "../components/SideNav";
import DetailPenawaranHeader from "./DetaiPenawaranHeader";

const PartnerDetailPenawaran = () => {
  /*======================== Hooks ======================== */

  /*======================== UseState ======================== */

  /*======================== Handler ======================== */

  /*======================== UseEffect ======================== */

  /*======================== Others ======================== */

  /*======================== Return ======================== */

  return (
    <div
      style={{ minHeight: "100vh", width: "100%", padding: "30px" }}
      className="bg-ice height-100"
    >
      <Row className="">
        <Col xs={12} lg={3}>
          <SideNav />
        </Col>
        <Col xs={12} lg={9}>
          <DetailPenawaranHeader />
        </Col>
      </Row>
    </div>
  );
};

export default PartnerDetailPenawaran;
