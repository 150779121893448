import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { resetMap } from "../../redux/action/map_action";
import { resetKirimBarang } from "../../redux/action/kirimbarang_action";
import { resetSewaKendaraan } from "../../redux/action/sewakendaraan_action";
import MetaTags from "react-meta-tags";
import { useMediaQuery } from "react-responsive";
import Breakpoint from "../responsiveUtils/Breakpoint";

const LayoutRoute = ({
  withoutLogin,
  requireLogin,
  metaTitle,
  metaDesc,
  component: Component,
  layout: Layout,
  ...rest
}) => {
  if (requireLogin) {
    if (!rest.auth.access_token) {
      return <Redirect to="/login" />;
    }
  }

  if (withoutLogin) {
    if (rest.auth.access_token) {
      return <Redirect to="/" />;
    }
  }

  if (rest.menuName !== rest.activeMenu) {
    rest.dispatch(resetMap({ activeMenu: rest.menuName }));
    rest.dispatch(resetKirimBarang());
    rest.dispatch(resetSewaKendaraan());
  }
  return (
    <>
      <Route
        {...rest}
        render={(props) => (
          <>
            <Layout metaTitle={metaTitle} metaDesc={metaDesc}>
              <Component {...props} />
            </Layout>
          </>
        )}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  activeMenu: state.map.activeMenu,
  auth: state.authReducer,
});
export default connect(mapStateToProps)(LayoutRoute);
