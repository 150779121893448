import React, { useState, useEffect } from "react";
import {
  Modal,
  Tabs,
  Tab,
  Row,
  Col
} from "react-bootstrap";
import ModalTandaTangan from "./ModalTandaTangan"
import { Avatar } from "antd";
import { AiOutlineUser } from "react-icons/ai";
import { MdImageNotSupported } from "react-icons/md";


const ModalKondisiKendaraan = ({trxDetail}) => {
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [eventKey, setEventKey] = useState(1)
    const [dataMaping, setDataMaping] = useState({})

    const handleChangeTab = async (key) => {
      setEventKey(parseInt(key))
    }
    
    useEffect(()=>{
      setEventKey(1)
    },[])

    useEffect(()=>{
      if(eventKey === 1){
        setDataMaping({
          tipeMobil: trxDetail?.data?.trx_booking_rent?.trx_booking_rent_vehicle?.mst_vehicle?.mst_vehicle_subtype?.name,
          mobil: trxDetail?.data?.trx_booking_rent?.trx_booking_rent_vehicle?.mst_vehicle?.mst_vehicle_brand?.name,
          platNumber: trxDetail?.data?.trx_booking_rent?.trx_booking_rent_vehicle?.mst_vehicle?.plate_number,
          nama: trxDetail?.data?.trx_booking_rent?.trx_booking_rent_vehicle?.received_by ? trxDetail?.data?.trx_booking_rent?.trx_booking_rent_vehicle?.received_by : "-",
          signature: trxDetail?.data?.trx_booking_rent?.trx_booking_rent_vehicle?.signature_on_receive,
          jarak: trxDetail?.data?.trx_booking_rent?.trx_booking_rent_vehicle?.odometer_on_receive ? trxDetail?.data?.trx_booking_rent?.trx_booking_rent_vehicle?.odometer_on_receive+" Km" : "-",
          bensin: trxDetail?.data?.trx_booking_rent?.trx_booking_rent_vehicle?.fuel_on_receive ? trxDetail?.data?.trx_booking_rent?.trx_booking_rent_vehicle?.fuel_on_receive+" Bar" : "-",
          odometer: trxDetail?.data?.trx_booking_rent?.trx_booking_rent_vehicle?.photo_odometer_on_receive ,
          eksterior: trxDetail?.data?.trx_booking_rent?.trx_booking_rent_vehicle?.photo_exterior_on_receive_url,
          interior: trxDetail?.data?.trx_booking_rent?.trx_booking_rent_vehicle?.photo_interior_on_receive_url,
          kerusakan: trxDetail?.data?.trx_booking_rent?.trx_booking_rent_vehicle?.photo_damage_url
          
        })
      }
      if(eventKey === 2){
        setDataMaping({
          tipeMobil: trxDetail?.data?.trx_booking_rent?.trx_booking_rent_vehicle?.mst_vehicle?.mst_vehicle_subtype?.name,
          mobil: trxDetail?.data?.trx_booking_rent?.trx_booking_rent_vehicle?.mst_vehicle?.mst_vehicle_brand?.name,
          platNumber: trxDetail?.data?.trx_booking_rent?.trx_booking_rent_vehicle?.mst_vehicle?.plate_number,
          nama: trxDetail?.data?.trx_booking_rent?.trx_booking_rent_vehicle?.received_by ? trxDetail?.data?.trx_booking_rent?.trx_booking_rent_vehicle?.received_by : "-",
          signature: trxDetail?.data?.trx_booking_rent?.trx_booking_rent_vehicle?.signature_on_finish,
          jarak: trxDetail?.data?.trx_booking_rent?.trx_booking_rent_vehicle?.odometer_on_finish ? trxDetail?.data?.trx_booking_rent?.trx_booking_rent_vehicle?.odometer_on_finish+" Km" : "-",
          bensin: trxDetail?.data?.trx_booking_rent?.trx_booking_rent_vehicle?.fuel_on_finish ? trxDetail?.data?.trx_booking_rent?.trx_booking_rent_vehicle?.fuel_on_finish+" Bar" : "-",
          odometer: trxDetail?.data?.trx_booking_rent?.trx_booking_rent_vehicle?.photo_odometer_on_finish,
          eksterior: trxDetail?.data?.trx_booking_rent?.trx_booking_rent_vehicle?.photo_exterior_on_finish_url,
          interior: trxDetail?.data?.trx_booking_rent?.trx_booking_rent_vehicle?.photo_interior_on_finish_url,
          kerusakan: trxDetail?.data?.trx_booking_rent?.trx_booking_rent_vehicle?.photo_damage_url
        })
      }
    },[eventKey])


    return (
    <>
      <h6
        className="fw-bold"
        style={{color:"#FF1DBC", textDecoration:"underline", fontSize:"11px", cursor:"pointer"}}
        onClick={()=>handleShow()}
    >
        Lihat Kondisi Kendaraan
    </h6>
      
       <Modal
        contentClassName="rounded-card p-2 overflow-hidden"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton className="bg-white border-0">
          <strong>Kondisi Kendaraan</strong>
        </Modal.Header>
        <Tabs
          defaultActiveKey={eventKey}
          className="tabs-trx mb-2 border-bottom-2"
          style={{
            marginTop:"-10px"
          }}
          onSelect={handleChangeTab}
        >
          <Tab eventKey="1" title="Kondisi Pengantaran"></Tab>
          <Tab eventKey="2" title="Kondisi Pengembalian"></Tab>
        </Tabs>
        <div
          style={{
            maxHeight: "500px",
            overflowY:"scroll", 
            overflowX:"hidden"
          }}
        >
          <Row className="border-bottom" style={{padding:"20px 30px", marginTop:"-10px"}}>
            <h6 className="m-0 p-0 fw-bold mb-2">Kendaraan</h6>
            <h6 className="m-0 p-0">{dataMaping.tipeMobil} - {dataMaping.mobil} ({dataMaping.platNumber})</h6>
          </Row>
          <Row className="border-bottom" style={{padding:"20px 30px"}}>
            <Col className="m-0 p-0 fw-bold">
              <h6 className="m-0 p-0 fw-bold mb-2">Nama Pengirim</h6>
              <h6 className="m-0 p-0">{dataMaping.nama}</h6>
            </Col>
            <Col className="m-0 p-0 fw-bold">
              <h6 className="m-0 p-0 fw-bold mb-2">Tanda Tangan Pengirim</h6>
              <ModalTandaTangan dataMaping={dataMaping}/>
            </Col>
          </Row>
          <Row className="border-bottom" style={{padding:"20px 30px"}}>
            <Col className="m-0 p-0 fw-bold">
              <h6 className="m-0 p-0 fw-bold mb-2">Jarak Tempuh Kendaraan</h6>
              <h6 className="m-0 p-0 mb-3">{dataMaping.jarak}</h6>
              <h6 className="m-0 p-0 fw-bold mb-2">Foto Odometer</h6>
              <Avatar
                className="me-2 border rounded overflow-hidden"
                style={{objectFit:"fill"}}
                shape="square"
                size={65}
                src={dataMaping.odometer}
                icon={
                    !dataMaping.odometer && <MdImageNotSupported />
                  }
                ></Avatar>
            </Col>
            <Col className="m-0 p-0 fw-bold">
              <h6 className="m-0 p-0 fw-bold mb-2">Bar Bensin</h6>
              {/* <h6 className="m-0 p-0">{dataMaping.bensin > 0 ? dataMaping.bensin : 0 }</h6> */}
              <h6 className="m-0 p-0">{dataMaping.bensin}</h6>
            </Col>
          </Row>
          <Row className="border-bottom" style={{padding:"20px 30px"}}>
            <Col className="m-0 p-0 fw-bold">
              <h6 className="m-0 p-0 fw-bold mb-2">Foto Eksterior</h6>
              <div className="d-flex flex-wrap gap-3">
                {dataMaping.eksterior?.length ? dataMaping.eksterior.map((item,i)=>(
                  <Avatar
                    className="me-2 border rounded overflow-hidden"
                    style={{objectFit:"fill"}}
                    shape="square"
                    size={65}
                    src={item}
                  ></Avatar>
                )) :
                  <h6 className="text-muted mt-3" style={{fontSize:"9px"}}>Foto eksterior tidak tersedia</h6>
                }
              </div>
            </Col>
            <Col className="m-0 p-0 fw-bold">
              <h6 className="m-0 p-0 fw-bold mb-2">Foto Interior</h6>
              <div className="d-flex flex-wrap gap-3">
                {dataMaping.interior?.length ? dataMaping.interior.map((item, i)=>(
                  <Avatar
                    className="me-2 border rounded overflow-hidden"
                    style={{objectFit:"fill"}}
                    shape="square"
                    size={65}
                    src={item}
                ></Avatar>
                )) :
                  <h6 className="text-muted mt-3" style={{fontSize:"9px"}}>Foto interior tidak tersedia</h6>
                }
              </div>
            </Col>
          </Row>
          {eventKey === 2 &&
            <Row style={{padding:"20px 30px"}}>
              <Col className="m-0 p-0 fw-bold">
                <h6 className="m-0 p-0 fw-bold mb-2">Foto Kerusakan Kendaraan (Bila ada)</h6>
                <div className="d-flex gap-3">
                  {dataMaping?.kerusakan?.length ? dataMaping?.kerusakan.map((item, i)=>{
                    <Avatar
                      className="me-2 border rounded overflow-hidden"
                      style={{objectFit:"fill"}}
                      shape="square"
                      size={65}
                      src={item}
                    ></Avatar>
                  }) :
                  <h6 className="text-muted mt-3" style={{fontSize:"9px"}}>Foto kerusakan kendaraan tidak tersedia</h6>
                  }               
                </div>
              </Col>
              
            </Row>
          }
        </div>
        
      </Modal>
    </>
    )
}

export default ModalKondisiKendaraan;
