import React, { useState } from "react";
import { Row, Col, Card, Modal } from "react-bootstrap";
import { updatePickupDetail } from "../../redux/action/sewakendaraan_action";
import { connect, useDispatch } from "react-redux";

// icons
import { IoChevronDown, IoCheckmark } from "react-icons/io5";

// images
import iconTruck from "../../assets/images/icon-truck.svg";

function PickFleet(props) {
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Card
        className="border-0 mb-1 pointer"
        onClick={() => {
          if (props.vType.data.length > 0) {
            handleShow();
          }
        }}
      >
        {props.vType.isLoading ? (
          <span className="h6 mt-2 ms-2 text-muted">Sedang memuat data...</span>
        ) : props.vType.data.length === 0 ? (
          <span className="h6 mt-2 ms-2 text-muted">Data </span>
        ) : !props.selectedVType.id ? (
          <div className="d-flex p-2">
            <div className="choose-type w-100">
              <img src={iconTruck} alt="" className="me-2" /> Pilih Tipe
              Kendaraan
            </div>
            <div>
              <IoChevronDown />
            </div>
          </div>
        ) : (
          <Row className="card-body py-1">
            <Col xs={3} className="align-self-center">
              <img
                src={props.selectedVType.icon}
                alt=""
                className="img-fluid"
              />
            </Col>
            <Col xs={8} className="align-self-center">
              <p className="fw-bold mb-0">{props.selectedVType.name}</p>
              <h6 className="mb-0 text-muted">
                Dimensi Kargo : {props.selectedVType.dimension_w}cm x{" "}
                {props.selectedVType.dimension_l}cm x{" "}
                {props.selectedVType.dimension_h}cm
              </h6>
              <h6>
                {" "}
                Vol : {props.selectedVType.volume}m, Maks Berat{" "}
                {props.selectedVType.max_weight}kg
              </h6>
              <p className="mb-0">{props.selectedVType.description}</p>
            </Col>
            <Col xs={1} className="align-self-center">
              <IoChevronDown />
            </Col>
          </Row>
        )}
      </Card>

      <Modal
        contentClassName="modal-100 p-1"
        show={show}
        onHide={handleClose}
        className=""
        centered
      >
        <Modal.Header closeButton className="bg-white">
          <strong>Pilih Armada Pengiriman</strong>
        </Modal.Header>
        <div className="mdl-fleet-ctn">
          {props.vType.data.map((data, i) => (
            <Card
              key={data.id}
              className={
                props.selectedVType.id && props.selectedVType.id === data.id
                  ? "border-fleet border-secondary"
                  : "border-fleet"
              }
              onClick={(e) => {
                dispatch(
                  updatePickupDetail({
                    selectedVType: data,
                    selectedPartnerService: {},
                    totalPrice: {
                      rent_day: 0,
                      vehicle_price: 0,
                      driver_price: 0,
                      helper_price: 0,
                      total_price: 0,
                      final_price: 0,
                    },
                    detailOrder: {
                      driver_availability_rent: 0,
                      helper_availability_send: 0,
                      helper_availability_rent: 0,
                      driver_price_rent: 0,
                      helper_price_rent_send: 0,
                      helper_price_rent: 0,
                      driver_availability_rent_selected: false,
                      helper_availability_send_selected: false,
                      helper_availability_rent_selected: false,
                      total_helper_requested: 1,
                      total_price: 0,
                    },
                  })
                );
                setShow(false);
              }}
            >
              <Row className="card-body py-2">
                <Col xs={3} className="align-self-center">
                  <img src={data.icon} alt="" className="img-fluid" />
                </Col>
                <Col xs={8} className="align-self-center">
                  <p className="fw-bold mb-0">{data.name}</p>
                  <h6 className="mb-0 text-muted">
                    Dimensi Kargo : {data.dimension_w}cm x {data.dimension_l}cm
                    x {data.dimension_h}cm
                  </h6>
                  <h6 className="mb-0 text-muted">
                    Vol: {data.volume}m, Maks Berat {data.max_weight}kg
                  </h6>
                  <p className="mb-0">{data.description}</p>
                </Col>
                <Col xs={1} className="align-self-center">
                  {data.isSelect ? (
                    <IoCheckmark className="text-secondary" />
                  ) : null}
                </Col>
              </Row>
            </Card>
          ))}
        </div>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => ({
  map: state.map,
  vType: state.sewaKendaraanReducer.vType,
  selectedVType: state.sewaKendaraanReducer.selectedVType,
});
export default connect(mapStateToProps)(PickFleet);
