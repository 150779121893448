import React, { Component } from "react";
import { Card, Button, Form } from "react-bootstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Checkbox, Switch } from "antd";
import { connect } from "react-redux";
import SearchAddress from "../../components/modalInput/SearchAddress";
import DetailAddress from "../../components/modalInput/DetailAddress";
import DateTime from "../../components/modalDatePicker/DateTime";
import InfoModalSimple from "../../components/modalInfo/infoSimple";
import KirimBarangRute from "./components/Rute";

// images
import iconCircle from "../../assets/images/icon-circle.svg";
import iconLocationFuscia from "../../assets/images/locationFuscia.svg";
import iconTimes from "../../assets/images/icon-Times.svg";

// Icons
import { AiFillPlusCircle } from "react-icons/ai";
import { MdDragIndicator, MdClose } from "react-icons/md";
import _ from "lodash";

import {
  // updatePickupDetail,
  addNewPickUpDetail, // eRICK (untuk tambah array of object pengambilan)
  addNewShipmentDetail,
  updateSpesificPickUpDetail, // eRick (dipake buat addres and detail addres)
  updateSpesificShippmentDetail,
  removePickUpDetail, // eRICK (untuk delet array of object pengambilan)
  removeShipmentDetail,
  updateAllShipmentDetail,
  updateDetail,
  updateSpesificPenagihanDetail,
  setBackToPickupAddress,
  updateBackToPickupAddress,
  setContract,
  setRoute,
  setDeliveryRoute
} from "../../redux/action/kirimbarang_action";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  let result = Array.from(list);
  const valueSource = result[startIndex];
  result[startIndex] = result[endIndex];
  result[endIndex] = valueSource;

  return result;
};

const grid = 2;

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : "",
});

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "" : "",

  // styles we need to apply on draggables
  ...draggableStyle,
});

class TabSendGoods extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleBackToPickup: false,
    };
    this.onDragEnd = this.onDragEnd.bind(this);
    // console.log(props,"========> ini data props")
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.props.pengiriman,
      result.source.index,
      result.destination.index
    );

    let newValDragLogs = [...this.props.dragLogs, new Date()];
    this.props.dispatch(updateDetail({ dragLogs: [...newValDragLogs] }));

    this.props.dispatch(updateAllShipmentDetail(items));
  }

  removeItemPickUp = (index) => {
    if (this.props.pengambilan_new.length !== 1) {
      this.props.dispatch(removePickUpDetail(index));
    }
  };

  removeItem = (index) => {
    if (this.props.pengiriman.length !== 1) {
      this.props.dispatch(removeShipmentDetail(index));
    }
  };

  addNewPickUpAddress() {
    if (this.props.pengambilan_new.length < 10) {
      this.props.dispatch(addNewPickUpDetail());
    }
  }

  addNewShipperAddress() {
    if (this.props.pengiriman.length < 50) {
      this.props.dispatch(addNewShipmentDetail());
    }
  }

  hidePenagihan() {
    let result = true;
    for (const iterator of [...this.props.pengiriman]) {
      if (iterator.isCod) {
        result = false;
      }
    }
    // this.setState({
    //   ...this.state,
    //   toggleBackToPickup: result,
    // });
    return result;
  }

  // setPickupAddress(address, placeId, suggestion) {
  //   const map = this.props.map;
  //   this.props.dispatch(
  //     updatePickupDetail({
  //       detailMap: map.data,
  //       lat: map.center.lat,
  //       lng: map.center.lng,
  //       lokasi: address,
  //       place_id: placeId,
  //       address_name: suggestion?.formattedSuggestion?.mainText,
  //     })
  //   );
  // }

  setPenagihanAdress(address) {
    const map = this.props.map;
    this.props.dispatch(
      updateSpesificPenagihanDetail({
        detailMap: map.data,
        lat: map.center.lat,
        lng: map.center.lng,
        lokasi: address,
      })
    );
  }

  updateDetailPenagihan(payload) {
    this.props.dispatch(updateSpesificPenagihanDetail({ ...payload }));
  }

  updateTipeKirimBarang(tipe) {
    if (tipe === "SHEDULED") {
      this.props.dispatch(updateDetail({ tipe }));
    } else if (tipe === "INSTANT") {
      this.props.dispatch(updateDetail({ tipe, dateLabel: null }));
      this.setContract({
        id: "1",
        name: "Tanpa Kontrak",
      })
      this.props.dispatch(setRoute(null));
      this.props.dispatch(setDeliveryRoute({}));
    }
  }

  // updateDetailPickup(payload) {
  //   this.props.dispatch(updatePickupDetail({ ...payload }));
  // }

  updateOriginDetailPickUp(payload, index) {
    this.props.dispatch(updateSpesificPickUpDetail({ ...payload }, index));
  }

  updateOriginDetail(payload, index) {
    this.props.dispatch(updateSpesificShippmentDetail({ ...payload }, index));
  }

  setBackToPickupAddress() {
    if (!this.state.toggleBackToPickup) {
      this.props.dispatch(setBackToPickupAddress("ON"));
    }
    if (this.state.toggleBackToPickup) {
      this.props.dispatch(setBackToPickupAddress("OFF"));
    }
    this.setState({
      ...this.state,
      toggleBackToPickup: !this.state.toggleBackToPickup,
    });
  }

  setOriginAddressPickUp(address, index, placeId, suggestion) {
    const map = this.props.map;

    this.props.dispatch(
      updateSpesificPickUpDetail(
        {
          lat: map.center.lat,
          lng: map.center.lng,
          lokasi: address,
          detailMap: map.data,
          place_id: placeId,
          address_name: suggestion?.formattedSuggestion?.mainText,
        },
        index
      )
    );
  }

  setOriginAddress(address, index, placeId, suggestion) {
    const map = this.props.map;

    this.props.dispatch(
      updateSpesificShippmentDetail(
        {
          lat: map.center.lat,
          lng: map.center.lng,
          lokasi: address,
          detailMap: map.data,
          place_id: placeId,
          address_name: suggestion?.formattedSuggestion?.mainText,
        },
        index
      )
    );
  }

  setOptimizeRoute() {
    let value = { isOptimize: !this.props.isOptimize };
    if (
      this.props.pengambilan_new[0].lat &&
      this.props.pengambilan_new[0].lng &&
      this.props.pengiriman[0].lat &&
      this.props.pengiriman[0].lng
    ) {
      let pengiriman = this.props.isOptimize
        ? this.handleUnoptimizedListPengiriman()
        : this.handleOptimizedListPengiriman();
      value = { isOptimize: !this.props.isOptimize, pengiriman };
    }
    this.props.dispatch(updateDetail({ ...value }));
  }

  handleOptimizedListPengiriman() {
    let pengiriman = [...this.props.pengiriman];

    const rad = function (x) {
      return (x * Math.PI) / 180;
    };

    const getDistance = function (p1, p2) {
      const R = 6378137; // Earth’s mean radius in meter
      const dLat = rad(p2.lat - p1.lat);
      const dLong = rad(p2.lng - p1.lng);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(rad(p1.lat)) *
          Math.cos(rad(p2.lat)) *
          Math.sin(dLong / 2) *
          Math.sin(dLong / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const d = R * c;
      return d; // returns the distance in meter
    };

    pengiriman.map((x, index) => {
      const getDistanceValue = getDistance(
        {
          lat: x.lat,
          lng: x.lng,
        },
        {
          lat: this.props.pengambilan_new[0].lat,
          lng: this.props.pengambilan_new[0].lng,
        }
      );
      x.getDistanceValue = getDistanceValue;
    });

    return [...pengiriman].sort(function (a, b) {
      return a.getDistanceValue - b.getDistanceValue;
    });
  }

  handleUnoptimizedListPengiriman() {
    return [...this.props.pengiriman].sort(function (a, b) {
      return a.key - b.key;
    });
  }

  checkTitikBalik() {
    var temp = [];
    var temp2 = [];
    this.props.pengiriman.map((e) => {
      temp.push(e.titikBalik);
    });
    for (let i = 0; i < temp.length; i++) {
      if (temp[i] === true) {
        temp2.push("failed");
      }
    }
    return temp2;
  }

  setContract(item) {
    this.props.dispatch(setContract({ ...item }));
  }

  updateRoute(item) {
    if (item) {
      this.props.dispatch(setRoute({ ...item }));
      this.props.dispatch(setDeliveryRoute(item.delivery_procurement_route[0]));
    } else {
      this.props.dispatch(setRoute(null));
      this.props.dispatch(setDeliveryRoute({}));
    }
    
    this.props.dispatch(setBackToPickupAddress("OFF"));
    this.props.dispatch(updateDetail({ selectedVType: {} }));
    this.props.dispatch(updateDetail({ selectedPartnerService: {} }));
    this.props.dispatch(updateBackToPickupAddress({
      catatan: "",
      isCod: false,
      isDetailFilled: false,
      lat: null,
      lng: null,
      lokasi: null,
      address_name: null,
      place_id: null,
      namaPenerima: "",
      name: "",
      nominalCod: null,
      nomorTelepon: "",
      patokan: "",
      detailMap: [],
      item_type: "",
      toggled: false,
    }));

    let pengambilanTemp = _.cloneDeep(this.props.pengambilan_new);
    let pengirimanTemp = _.cloneDeep(this.props.pengiriman);

    for (let i = pengambilanTemp.length; i > 1; i--) {
      this.props.dispatch(removePickUpDetail(i - 1));
    }

    for (let i = pengirimanTemp.length; i > 1; i--) {
      this.props.dispatch(removeShipmentDetail(i - 1));
    }

    if (item && item.pick_up_address && item.delivery_address) {
      setTimeout(() => {
        this.setPickupAddressByRoute(item.pick_up_address);
        this.setDeliveryAddressByRoute(item.delivery_address);
      }, 500);
    }
  }

  setPickupAddressByRoute(items) {
    items.forEach((item, i) => {
      if (i > 0) {
        this.addNewPickUpAddress();
        this.props.dispatch(
          updateSpesificPickUpDetail(
            {
              lat: item.latitude,
              lng: item.longitude,
              lokasi: item.name,
              detailMap: null,
              place_id: null,
              address_name: item.name,
              isDetailFilled: true,
              namaPenerima: item.pic,
              nomorTelepon: this.getPhoneNumber(item.phone_number),
              patokan: item.detail_location,
              catatan: item.notes_for_driver,
              item_type: item.type_of_goods,
            },
            i
          )
        );
      } else {
        this.props.dispatch(
          updateSpesificPickUpDetail(
            {
              lat: item.latitude,
              lng: item.longitude,
              lokasi: item.name,
              detailMap: null,
              place_id: null,
              address_name: item.name,
              isDetailFilled: true,
              namaPenerima: item.pic,
              nomorTelepon: this.getPhoneNumber(item.phone_number),
              patokan: item.detail_location,
              catatan: item.notes_for_driver,
              item_type: item.type_of_goods,
            },
            i
          )
        );
      }
    });
  }

  getPhoneNumber(value) {
    if (value) {
      if (value.substring(0, 1) === "+") {
        return value.substring(3);
      } else if (value.substring(0, 1) === "6") {
        return value.substring(2);
      } else if (value.substring(0, 1) === "0") {
        return value.substring(1);
      } else {
        return value;
      }
    } else {
      return "";
    }
  }

  setDeliveryAddressByRoute(items) {
    items.forEach((item, i) => {
      if (i > 0) {
        this.addNewPickUpAddress();
        this.props.dispatch(
          updateSpesificShippmentDetail(
            {
              lat: item.latitude,
              lng: item.longitude,
              lokasi: item.name,
              detailMap: null,
              place_id: null,
              address_name: item.name,
              isDetailFilled: true,
              namaPenerima: item.pic,
              patokan: item.detail_location,
              catatan: item.notes_for_driver,
              nomorTelepon: this.getPhoneNumber(item.phone_number),
            },
            i
          )
        );
      } else {
        this.props.dispatch(
          updateSpesificShippmentDetail(
            {
              lat: item.latitude,
              lng: item.longitude,
              lokasi: item.name,
              detailMap: null,
              place_id: null,
              address_name: item.name,
              isDetailFilled: true,
              namaPenerima: item.pic,
              patokan: item.detail_location,
              catatan: item.notes_for_driver,
              nomorTelepon: this.getPhoneNumber(item.phone_number),
            },
            i
          )
        );
      }
    });
  }

  showPickUpAddressList() {
    let components = [];
    components.push(
      <div>
        {this.props.pengambilan_new.map((item, index) => (
          <Draggable key={item.id} draggableId={item.id} index={index}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                style={getItemStyle(
                  snapshot.isDragging,
                  provided.draggableProps.style
                )}
              >
                <Card className="ice mb-2">
                  <Card.Body>
                    <img
                      src={iconCircle}
                      alt=""
                      style={{ marginTop: "-4px" }}
                    />{" "}
                    <span className="ms-2 fs-13">
                      Alamat Pengambilan {index + 1}
                    </span>
                    <div className="float-end">
                      {/* <Button
                        size="sm"
                        variant="link"
                        {...provided.dragHandleProps}
                      >
                        <MdDragIndicator />
                      </Button> */}
                      <Button
                        size="sm"
                        variant="link"
                        style={{ color: "#27C0CD" }}
                        onClick={() => {
                          this.removeItemPickUp(index);
                        }}
                      >
                        <img src={iconTimes} alt="" />
                      </Button>
                    </div>
                    <div className="d-flex mt-2">
                      <div className="br-dash" />
                      <div className="w-100 ms-2">
                        <Form>
                          <fieldset className="input-group-vertical">
                            <SearchAddress
                              disabled={(this.props.contract && this.props.contract.id !== "1")}
                              address={this.props.pengambilan_new[index].lokasi}
                              getPropsValue={(address, placeId, suggestion) => {
                                this.setOriginAddressPickUp(
                                  address,
                                  index,
                                  placeId,
                                  suggestion
                                );
                              }}
                              placeholder="Masukkan Alamat Pengambilan"
                              title="Alamat Pengambilan"
                            />
                            {this.props.pengambilan_new[index].lokasi && (
                              <DetailAddress
                                placeholder="Masukkan Detail Alamat Pengambilan"
                                updateProps={(data) => {
                                  this.updateOriginDetailPickUp(data, index);
                                }}
                                value={this.props.pengambilan_new[index]}
                                title="Detail Pengambilan"
                                order="SEND"
                                showItemType
                              />
                            )}
                          </fieldset>
                        </Form>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            )}
          </Draggable>
        ))}
      </div>
    );
    return components;
  }

  showShipmentAddressList() {
    let components = [];
    components.push(
      <div>
        {this.props.pengiriman.map((item, index) => (
          <Draggable key={item.id} draggableId={item.id} index={index}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                style={getItemStyle(
                  snapshot.isDragging,
                  provided.draggableProps.style
                )}
              >
                <Card className="ice mb-2">
                  <Card.Body>
                    <img
                      src={iconLocationFuscia}
                      alt=""
                      style={{
                        marginLeft: "-1px",
                        width: "18px",
                      }}
                    />
                    {/* <span className="mark-number">{index + 1}</span> */}
                    <span className="ms-2 fs-13">
                      Alamat Pengiriman {index + 1}{" "}
                      {item.isCod && "- Titik Balik COD"}
                    </span>
                    <div className="float-end">
                      <Button
                        size="sm"
                        variant="link"
                        {...provided.dragHandleProps}
                        title="Hold and Drag "
                      >
                        <MdDragIndicator style={{ color: "#707070" }} />
                      </Button>
                      <Button
                        size="sm"
                        variant="link"
                        onClick={() => {
                          this.removeItem(index);
                        }}
                      >
                        <img src={iconTimes} alt="" />
                      </Button>
                    </div>
                    <div className="d-flex mt-2">
                      <div className="br-dash" />
                      <div className="w-100 ms-2">
                        <Form>
                          <fieldset className="input-group-vertical">
                            <SearchAddress
                              disabled={(this.props.contract && this.props.contract.id !== "1")}
                              address={this.props.pengiriman[index].lokasi}
                              getPropsValue={(address, placeId, suggestion) => {
                                this.setOriginAddress(
                                  address,
                                  index,
                                  placeId,
                                  suggestion
                                );
                              }}
                              placeholder="Masukkan Alamat Pengiriman"
                              title="Alamat Pengiriman"
                            />
                            {this.props.pengiriman[index].lokasi && (
                              <DetailAddress
                                addressPickUp
                                bill
                                placeholder="Masukkan Detail Alamat Pengiriman"
                                updateProps={(data) => {
                                  this.updateOriginDetail(data, index);
                                }}
                                value={this.props.pengiriman[index]}
                                title="Detail Pengiriman"
                                order="SEND"
                                checkTitikBalik={this.checkTitikBalik()}
                              />
                            )}
                          </fieldset>
                        </Form>
                      </div>
                    </div>
                    {/* <div
                      className="mt-2 ms-3"
                      hidden={
                        this.props.pengiriman.length - 1 !== index ||
                        this.props.pengiriman.length === 10 ||
                        (this.props.pengiriman.length === 9 &&
                          !this.hidePenagihan())
                      }
                    >
                      <Button
                        variant="link"
                        size="sm"
                        className="decor-0"
                        onClick={() => {
                          this.addNewShipperAddress();
                        }}
                      >
                        <AiFillPlusCircle /> Tambah Alamat Pengiriman
                      </Button>
                    </div> */}
                  </Card.Body>
                </Card>
              </div>
            )}
          </Draggable>
        ))}
      </div>
    );
    return components;
  }

  render() {
    return (
      <Card.Body className="pt-0">
        <h5 className="fw-light mb-2 mt-4-mobile">Jenis Pengiriman</h5>
        <div className="rc-ice p-2">
          <div className="gr-btn-select d-grid d-flex">
            <Button
              variant="light"
              className={
                this.props.tipe === "INSTANT"
                  ? "br-color active w-50"
                  : "br-color w-50"
              }
              onClick={() => {
                this.updateTipeKirimBarang("INSTANT");
              }}
            >
              <p className="fw-bold mb-0">Kirim Sekarang</p>
              <h6 className="mb-0 fw-light">Ambil dibawah 6 jam</h6>
            </Button>
            <DateTime
              tipe={this.props.tipe}
              updateTipeKirimBarang={() => {
                this.updateTipeKirimBarang("SCHEDULED");
              }}
            />
          </div>
        </div>
        <div
          className="mb-2 mt-3"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div>
            <span className="h5 fw-light mb-0">Rute Pengiriman</span>
            <p className="fw-light">Rute Maksimal 10 Perhentian</p>
          </div>
          <div>
            <InfoModalSimple />{" "}
            <span className="me-2 fw-lighter fs-12">Optimalkan Rute</span>
            <Switch
              disabled={this.props.pengiriman.length < 2}
              checked={this.props.isOptimize}
              onChange={() => {
                this.setOptimizeRoute();
              }}
            />
          </div>
        </div>

        <div className="scrollable-min mb-2" style={{height:"50vh"}}>
          {this.props.tipe !== "INSTANT" && (
            <KirimBarangRute
              contract={this.contract}
              route={this.route}
              updateRoute={(item) => {
                this.updateRoute(item);
              }}
            />
          )}
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="droppablePickUp">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {this.showPickUpAddressList()}

                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <div
            className="ms-2 mb-2"
            style={{ marginTop: "-8px" }}
            // hidden={
            //   this.props.pengiriman.length - 1 !== index ||
            //   this.props.pengiriman.length === 10 ||
            //   (this.props.pengiriman.length === 9 &&
            //     !this.hidePenagihan())
            // }
          >
            { this.props.contract && this.props.contract.id === "1" && (
              <Button
                variant="link"
                size="sm"
                className="decor-0"
                onClick={() => {
                  this.addNewPickUpAddress();
                }}
                // style={{color:"#27C0CD"}}
              >
                <AiFillPlusCircle /> Tambah Alamat Pengambilan
              </Button>
            )}
          </div>
          {/* Pengiriman */}
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {this.showShipmentAddressList()}

                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <div
            className="ms-2"
            style={{ marginTop: "-8px" }}
            // hidden={
            //   this.props.pengiriman.length - 1 !== index ||
            //   this.props.pengiriman.length === 10 ||
            //   (this.props.pengiriman.length === 9 &&
            //     !this.hidePenagihan())
            // }
          >
            { this.props.contract && this.props.contract.id === "1" && (
              <Button
                variant="link"
                size="sm"
                className="decor-0"
                onClick={() => {
                  this.addNewShipperAddress();
                }}
              >
                <AiFillPlusCircle /> Tambah Alamat Pengiriman
              </Button>
            )}
          </div>

          <Card
            className="ice mb-2"
            // hidden={this.state.toggleBackToPickup ? false : true}
            hidden={!this.props.backToPickupAddress.toggled}
          >
            <Card.Body>
              <span>
                <img
                  src={iconLocationFuscia}
                  alt=""
                  style={{ marginTop: "-4px", width: "18px" }}
                />{" "}
                {/* <span className="mark-number">
                  {[...this.props.pengiriman].length + 1}
                </span> */}
              </span>
              <span className="fs-13">Alamat Titik Balik</span>
              <div className="d-flex mt-2">
                <div className="w-100 ms-2">
                  <Form>
                    <fieldset className="input-group-vertical">
                      <SearchAddress
                        address={this.props.backToPickupAddress.lokasi}
                        getPropsValue={(address, placeId, suggestion) => {
                          this.setPenagihanAdress(address);
                        }}
                        placeholder="Masukkan Alamat Kirim Balik"
                        disabled
                        title="Alamat Titik Balik"
                      />
                    </fieldset>
                  </Form>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </Card.Body>
    );
  }
}

const mapStateToProps = (state) => ({
  map: state.map,
  address: state.map.address,
  pengambilan: state.kirimbarangReducer.pengambilan,
  pengambilan_new: state.kirimbarangReducer.pengambilan_new, // erick
  pengiriman: state.kirimbarangReducer.pengiriman,
  penagihan: state.kirimbarangReducer.penagihan,
  backToPickupAddress: state.kirimbarangReducer.backToPickupAddress,
  tipe: state.kirimbarangReducer.tipe,
  isOptimize: state.kirimbarangReducer.isOptimize,
  dragLogs: state.kirimbarangReducer.dragLogs,
  contract: state.kirimbarangReducer.contract,
  route: state.kirimbarangReducer.route,
});
export default connect(mapStateToProps)(TabSendGoods);
