import React, { useState, useEffect } from "react";
import { Row, Col, Card, Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { updateDetail } from "../../redux/action/kirimbarang_action";
// images
import blindVan from "../../assets/images/fleet-blind-van.png";
import iconTruck from "../../assets/images/icon-truck.svg";

// icons
import { IoChevronDown, IoCheckmark } from "react-icons/io5";

// api
import OrderSendApi from "../../api/order-send-api";

function PickFleetGoods() {
  const dispatch = useDispatch();
  const fleet = useSelector((st) => st.kirimbarangReducer.vType.data);
  const loading = useSelector((st) => st.kirimbarangReducer.vType.isLoading);
  const pengambilan = useSelector((st) => st.kirimbarangReducer.pengambilan);
  const pengambilan_new = useSelector((st) => st.kirimbarangReducer.pengambilan_new);
  const pengiriman = useSelector((st) => st.kirimbarangReducer.pengiriman);
  const kirimBarangReducer = useSelector((st) => st.kirimbarangReducer);
  const selectedVType = useSelector(
    (st) => st.kirimbarangReducer.selectedVType
  );
  const backToPickupAddress = useSelector(
    (st) => st.kirimbarangReducer.backToPickupAddress
  );
  const tipe = useSelector((st) => st.kirimbarangReducer.tipe);
  const auth = useSelector((st) => st.authReducer);
  const contract = useSelector((st) => st.kirimbarangReducer.contract);
  const deliveryProcurementRoute = useSelector((st) => st.kirimbarangReducer.deliveryProcurementRoute);


  // for temporary variable
  const [fleetSelected, setFleetSelected] = useState({});

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getPartnerAfterFleetSelected = async () => {
    const api = new OrderSendApi();

    let payload = {};
    // arrange payload
    payload["send_category"] = tipe;
    payload["date"] =
      tipe === "INSTANT" ? new Date().toISOString() : kirimBarangReducer.date;
    payload["is_return"] = backToPickupAddress.toggled ? 1 : 0;
    payload["send_destination"] = [];
    payload["delivery_procurement_route_id"] = deliveryProcurementRoute.id;

    // pengambilan
    for(let i = 0; i<pengambilan_new.length; i++){
      let pickupPayload = {};
      pickupPayload["destination_type"] = "PICKUP";
      pickupPayload["destination_order"] = i;
      pickupPayload["longitude"] = pengambilan_new[i].lng;
      pickupPayload["latitude"] = pengambilan_new[i].lat;
      pickupPayload["address"] = pengambilan_new[i].lokasi;
      pickupPayload["location_description"] = pengambilan_new[i].patokan;
      pickupPayload["cod_amount"] = null;
      pickupPayload["note"] = pengambilan_new[i].catatan;
      pickupPayload["contact_name"] = pengambilan_new[i].namaPenerima;
      pickupPayload["contact_phone_no"] = `0${pengambilan_new[i].nomorTelepon}`;
      pickupPayload["item_type"] = pengambilan_new[i].item_type;
      pickupPayload["place_id"] = pengambilan_new[i].place_id;
      pickupPayload["address_name"] = pengambilan_new[i].address_name;
      payload["send_destination"].push(pickupPayload);
    }


    // pengiriman
    pengiriman.forEach((eachData, i) => {
      let dropoffPayload = {};
      dropoffPayload["destination_type"] = "DROPOFF";
      dropoffPayload["destination_order"] = i + pengambilan_new.length;
      dropoffPayload["longitude"] = eachData.lng;
      dropoffPayload["latitude"] = eachData.lat;
      dropoffPayload["address"] = eachData.lokasi;
      dropoffPayload["location_description"] = eachData.patokan;
      dropoffPayload["cod_amount"] = eachData.nominalCod;
      dropoffPayload["note"] = eachData.catatan;
      dropoffPayload["contact_name"] = eachData.namaPenerima;
      dropoffPayload["contact_phone_no"] = `0${eachData.nomorTelepon}`;
      dropoffPayload["item_type"] = eachData.item_type;
      dropoffPayload["place_id"] = eachData.place_id;
      dropoffPayload["address_name"] = eachData.address_name;
      dropoffPayload["dropoff_reference"] = eachData.dropoff_reference;
      payload["send_destination"].push(dropoffPayload);
    });
    // back to pickup address
    if (backToPickupAddress.toggled) {
      let backAddressPayload = {};
      backAddressPayload["destination_type"] = "DROPOFF";
      backAddressPayload["destination_order"] = payload["send_destination"].length;
      backAddressPayload["longitude"] = backToPickupAddress.lng;
      backAddressPayload["latitude"] = backToPickupAddress.lat;
      backAddressPayload["address"] = backToPickupAddress.lokasi;
      backAddressPayload["location_description"] = backToPickupAddress.patokan;
      backAddressPayload["cod_amount"] = backToPickupAddress.nominalCod;
      // backAddressPayload["note"] = backToPickupAddress.catatan;
      backAddressPayload["contact_name"] = backToPickupAddress.namaPenerima;
      backAddressPayload["contact_phone_no"] = `0${backToPickupAddress.nomorTelepon}`;
      // backAddressPayload["item_type"] = backToPickupAddress.item_type;
      backAddressPayload["place_id"] = backToPickupAddress.place_id;
      backAddressPayload["address_name"] = backToPickupAddress.address_name;
      payload["send_destination"].push(backAddressPayload);
    }

    dispatch(
      updateDetail({
        partnerService: { data: [], isLoading: true },
      })
    );

    payload["sub_type_id"] = fleetSelected.id;

    if (contract.id !== '1') {
      payload["tender_id"] = contract.id;
    }

    const { data: partners } = await api.getAvailablePartnerServices(payload);
    dispatch(
      updateDetail({
        partnerService: { data: partners, isLoading: false },
      })
    );
  };

  const onSelectFleet = async (value) => {
    setFleetSelected({ ...value });
    dispatch(updateDetail({ selectedVType: { ...value } }));
    handleClose();
  };

  useEffect(() => {
    (async () => {
      if (fleetSelected.id) {
        await getPartnerAfterFleetSelected();
      }
    })();
  }, [fleetSelected]);

  return (
    <>
      <Card className="border-0 mb-1 pointer" onClick={handleShow}>
        {loading ? (
          <span className="h6 mt-2 ms-2 text-muted">Sedang memuat data...</span>
        ) : fleet?.length === 0 ? (
          <span className="h6 mt-2 ms-2 text-muted">Data </span>
        ) : !selectedVType.id ? (
          <div className="d-flex p-2">
            <div className="choose-type w-100">
              <img src={iconTruck} alt="" className="me-2" /> Pilih Tipe
              Kendaraan
            </div>
            <div>
              <IoChevronDown />
            </div>
          </div>
        ) : (
          <Row className="card-body py-1">
            <Col xs={3} className="align-self-center">
              <img src={selectedVType?.icon} alt="" className="img-fluid" />
            </Col>
            <Col xs={8} className="align-self-center">
              <p className="fw-bold mb-0">{selectedVType?.name}</p>
              <h6 className="mb-0 text-muted">
                Dimensi Kargo : {selectedVType?.dimension_w}cm x{" "}
                {selectedVType?.dimension_l}
                cm x {selectedVType?.dimension_h}cm
              </h6>
              <h6 className="mb-0 text-muted">
                Vol : {selectedVType?.volume} m, Maks Berat{" "}
                {selectedVType?.max_weight}
                kg
              </h6>
            </Col>
            <Col xs={1} className="align-self-center">
              <IoChevronDown />
            </Col>
          </Row>
        )}
      </Card>

      <Modal
        contentClassName="modal-100 p-1"
        show={show}
        onHide={handleClose}
        className=""
        centered
      >
        <Modal.Header closeButton className="bg-white">
          <strong>Pilih Armada Pengiriman</strong>
        </Modal.Header>
        <div className="mdl-fleet-ctn">
          {fleet.map((data, i) => (
            <Card
              key={i}
              className={
                data.isSelect ? "border-fleet border-secondary" : "border-fleet"
              }
              onClick={() => onSelectFleet(data)}
            >
              <Row className="card-body py-0">
                <Col xs={3} className="align-self-center">
                  <img src={data.icon} alt="" className="img-fluid" />
                </Col>
                <Col xs={8} className="align-self-center">
                  <p className="fw-bold mb-0">{data.name}</p>
                  <h6 className="mb-0 text-muted">
                    Dimensi Kargo : {data.dimension_w}cm x {data.dimension_l}cm
                    x {data.dimension_h}cm
                  </h6>
                  <h6 className="mb-0 text-muted">
                    Vol : {data.volume} m, Maks Berat {data.max_weight}kg
                  </h6>
                </Col>
                <Col xs={1} className="align-self-center">
                  {data.isSelect ? (
                    <IoCheckmark className="text-secondary" />
                  ) : null}
                </Col>
              </Row>
            </Card>
          ))}
        </div>
      </Modal>
    </>
  );
}

export default PickFleetGoods;
