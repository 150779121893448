import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import { Switch, Avatar } from "antd";

// icons
import icon_data_IntegrasiAPI from "../../assets/images/icon_data_IntegrasiAPI.svg"
import icon_layanan_integrasiApi from "../../assets/images/icon_layanan_integrasiApi.svg"
import icon_status_integrasiApi from "../../assets/images/icon_status_integrasiApi.svg"

// component
import InfoIntegrasi from "../../components/infoBar/InfoIntegrasi"

const TabRegister = ({dataIntegrasi, handleEditSendOrder}) => {

  const style = {
    text: {
      color: "#666666"
    }
  }

  return (
      <Card.Body className="pt-0 mt-2 overflow-auto mb-2">
        <div className="rc-ice p-2">

          {/* Status */}
          <div className="gr-btn-select">
            <div className="bg-white p-1" style={{width:'99%'}}>
              <img className="py-3 px-2" src={icon_status_integrasiApi} alt="" style={{ marginTop: "-4px" }} />{" "}
              <h5 className="fw-bold d-inline">Status</h5>
              <div className="text-mute" style={{marginLeft:"30px", marginTop:"-5px"}}>           
                <Row className="px-2">
                  <Col xs={4}><h6 style={style.text}>Status Aktifasi</h6></Col>
                  {/* ========== belum di maping ========== */}
                  <Col>
                    {
                      dataIntegrasi ? 
                        dataIntegrasi.send === 1 ? 
                          (<h6 style={{color:"orange"}}>Menunggu Konfirmasi</h6>) :
                        (<h6 style={{color:"#1EAF56"}}>Aktivasi Diterima</h6>) :
                      (<h6>-</h6>)
                    }
                  </Col>
                </Row>
                <Row className="px-2 mb-1">
                  <Col xs={4}><h6 style={style.text}>Status Integrasi</h6></Col>
                  <Col>
                    {
                      dataIntegrasi &&
                        dataIntegrasi.client_status === "ACTIVE" ?
                          (<h6 style={{color:"#1EAF56"}}>Aktif</h6>) :
                        (<h6>Tidak Aktif</h6>)
                    }
                  </Col>
                </Row>
              </div>
            </div>
          </div>

          {/* Data Integrasi API */}
          <div className="gr-btn-select mt-1 d-grid d-flex">
            <div className="bg-white p-1" style={{width:'99%'}}>
            <img className="py-3 px-2" src={icon_data_IntegrasiAPI} alt="" style={{ marginTop: "-4px" }} />{" "}
              <h5 className="fw-bold d-inline">Data Integrasi API</h5>
              <div className="text-mute" style={{marginLeft:"30px", marginTop:"-5px"}}>                 
                <Row className="px-2">
                  <Col xs={4}><h6 style={style.text}>Username</h6></Col>
                  <Col><h6>{dataIntegrasi ? dataIntegrasi.name : "-"}</h6></Col>
                </Row>
                <Row className="px-2">
                  <Col xs={4}><h6 style={style.text}>Prefix</h6></Col>
                  <Col><h6>{dataIntegrasi ? dataIntegrasi.app_code  ? dataIntegrasi.app_code : "-" : "-"}</h6></Col>
                </Row>
                <Row className="px-2">
                  <Col xs={4}><h6 style={style.text}>URL Callback</h6></Col>
                  <Col><h6>{dataIntegrasi ? dataIntegrasi.callback_url : "-"}</h6></Col>
                </Row>
                {dataIntegrasi && dataIntegrasi.whitelisted.map((data,i)=>(
                <Row className="px-2">
                  <Col xs={4}><h6 style={style.text}>{i=== 0 ? 'Domain URL' : null}</h6></Col>
                  <Col style={{marginTop:"-2px", display:"flex", justifyContent:"space-between"}}>
                      <h6 key={i} className="d-inline">{data.domain_address}</h6>
                  </Col>
                </Row>
                ))}
                <Row className="px-2 mb-1">
                  <Col xs={4}><h6 style={style.text}>Icon Brand</h6></Col>
                  <Col>
                    {
                      dataIntegrasi ? 
                      (<Avatar 
                        src={dataIntegrasi.application_logo}
                        size={45}
                        className="border-1"
                        shape="square"
                      />
                      )
                      :
                      "-"
                    }
                  </Col>
                </Row>
              </div>
            </div>
          </div>

          {/* Layanan */}
          <div className="gr-btn-select mt-1 d-grid d-flex">
            <div className="bg-white p-1" style={{width:'99%'}}>
              <img className="py-3 px-2" src={icon_layanan_integrasiApi} alt="" style={{ marginTop: "-4px" }} />{" "}
              <h5 className="fw-bold d-inline">Layanan</h5> 
              <div className="text-mute" style={{marginLeft:"30px", marginTop:"-5px"}}>           
                <Row className="px-2 mb-2">
                  {/* ========== belum di maping ========== */}
                  <Col xs={5}><h6 style={style.text}>Kirim Barang</h6></Col>
                  {dataIntegrasi && dataIntegrasi.client_status === "ACTIVE" ? 
                  (<Col style={{marginTop:"-5px"}}>
                    <Switch
                      disable={dataIntegrasi ? false : true}
                      style={{
                        backgroundColor: `${dataIntegrasi && dataIntegrasi.send_status === 1 ? '#27C0CD':'#BFBFBF'}`, border:"none"}
                      }
                      // onChange={() => {
                      //   handleEditSendOrder()
                      // }}
                      checked={dataIntegrasi && dataIntegrasi.send_status === 1 ? true : false}
                    />
                    {
                      dataIntegrasi && dataIntegrasi.send_status === 1 ? 
                        (<h6 className="d-inline mx-2 fw-bold" style={{color:"#27C0CD"}}>Aktif</h6>) :
                      (<h6 className="d-inline mx-2">Tidak Aktif</h6>)
                    }
                  </Col>)
                  :
                  (<Col style={{marginTop:"-5px"}}>
                  <Switch
                    disabled
                    style={{backgroundColor: '#BFBFBF', border:"none"}}
                  />
                  <h6 className="d-inline mx-2">Tidak Aktif</h6>
                </Col>)
                  }
                </Row>
                <Row 
                  className="px-2 mt-1"
                  style={{display:"none"}}
                >
                  <Col xs={5}><h6>Sewa Kendaraan</h6></Col>
                  <Col style={{marginTop:"-5px"}}>
                    <Switch
                      style={{backgroundColor:"#BFBFBF", border:"none"}}
                      onChange={() => {
                        console.log('switch sewa kendaraan')
                      }}
                      defaultChecked={false}
                    />
                    <h6 className="d-inline mx-2">Tidak Aktif</h6>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>

        {/* Info Bar */}
        {/* ========== belum di maping ========== */}
        {dataIntegrasi ? 
        (<InfoIntegrasi
          type={dataIntegrasi ? 
                dataIntegrasi.send === 1 ? "info" :
                // dataIntegrasi.activation_status === "ACTIVE" ? "success" :
                // "danger" : null
                "success" : null
              }
          infoBarClassName={"mt-2"}
          text={
            dataIntegrasi ? 
              dataIntegrasi.send === 1 ?
                (<p style={{ marginTop: "-8px", fontSize:"11px"}} >
                  "Pihak eLOG akan menghubungi anda untuk Integrasi API. Silahkan tunggu maksimal <strong>2x24</strong> jam"
                </p>) :
              dataIntegrasi.activation_status === "ACTIVE" ? 
                (<p style={{ marginTop: "-8px", fontSize:"11px"}} >
                  "Anda Berhasil melakukan Aktivasi API. Pilih layanan eLOG yang akan terintegrasi dengan anda"
                </p>) :
                (<p style={{ marginTop: "-8px", fontSize:"11px"}} >
                    "Aktivasi Integrasi API anda gagal. Silahkan ubah data atau hubungi eLOG untuk pertanyaan lainnya"
                  </p>
                  ) : null
          }
        />
        )
        : null
        }
        {/* <p style={{ marginTop: "-8px", fontSize:"11px"}} >
          "Anda Berhasil melakukan Aktivasi API. Pilih layanan eLOG yang akan terintegrasi dengan anda"
        </p>
        <p style={{ marginTop: "-8px", fontSize:"11px"}} >
          "Aktivasi Integrasi API anda gagal. Silahkan ubah data atau hubungi eLOG untuk pertanyaan lainnya"
        </p> */}
        
      </Card.Body>
  )
}

export default TabRegister;
