import React, { useState } from "react";
import {
  Modal,
  Button
} from "react-bootstrap";
import { FaHeadset } from "react-icons/fa"

// images
import icon_gagang_telephone from "../../assets/images/icon_gagang_telphone.svg"

//style
const style = {
  button : {
    border:"none",
    marginTop:"6px",
    PointerEvent: "none"
  }
}

const InfoPhone = () => {
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
    <>
      <Button
        size="sm"
        variant="outline-primary"
        style={style.button}
        className="fw-bold py-2 rounded-pill"
        onClick={handleShow}
      >
        <FaHeadset/>{` `}
        Hubungi eLOG
      </Button>
      
       <Modal
        contentClassName="rounded-card p-2"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton className="bg-white">
          <strong>Hubungi eLOG</strong>
        </Modal.Header>
        <div className="d-flex justify-content-center align-items-center" style={{ height:"100px"}}>
          <img src={icon_gagang_telephone} className="icon-form d-inline" alt="" width={35} style={{marginLeft:"-20px", marginRight:"20px"}}/>
          <h3 className="d-inline">+62 815-4647-6969</h3>
        </div>
      </Modal>
    </>
    )
}

export default InfoPhone;
