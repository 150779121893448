import React from "react";
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import payment_done_rent from "../../../assets/images/payment_done_rent.svg";
import payment_done_send from "../../../assets/images/payment_done_send.svg";

const OnAccount = () => {
  const history = useHistory();
  const auth = useSelector((st) => st.authReducer);
  const map = useSelector((st) => st.map);
  const activePayment = useSelector((st) => st.activePaymentReducer);

  const handleClickButton = () => {
    if (map.activeMenu === "SewaKendaraan") {
      history.push({
        pathname: `/daftar-transaksi-sewa/${activePayment?.data?.order_id}`,
        state: { type: "RENT" },
      });
      // localStorage.setItem("type", "RENT");
    } else if (map.activeMenu === "KirimBarang") {
      history.push({
        pathname: `/daftar-transaksi-kirim/${activePayment?.data?.order_id}`,
        state: { type: "SEND_GOODS" },
      });
      // localStorage.setItem("type", "SEND_GOODS");
    }
  };

  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center text-center p-4"
      style={{ height: "80vh" }}
    >
      <img
        src={
          map.activeMenu === "SewaKendaraan"
            ? payment_done_rent
            : payment_done_send
        }
        alt=""
      />
      <h2>Transaksi Berhasil</h2>
      <h5 className="text-muted">
        Mitra eLOG siap menjalankan pesanan Anda, silahkan cek status
        pemesananmu di daftar transaksi
      </h5>
      <Button
        variant="outline-primary"
        className="rounded-pill mt-4 px-4"
        onClick={handleClickButton}
      >
        Lihat status pesananmu
      </Button>
    </div>
  );
};

export default OnAccount;
