
import React from "react";
import {
    Modal
  } from "react-bootstrap";


const ModalMulaiMutasiPartnerConfirm = ({
    show,
    handleClose,
    submit
}) => {

  return(
    <>  
        <Modal
            contentClassName="rounded-card p-2"
            show={show}
            onHide={handleClose}
            centered
            size="sm"
        >

            <div className="py-3 px-4">
                <h6>Apakah anda yakin pengantaran mutasi kendaraan ke alamat mutasi akan dilakukan oleh Partner? </h6>
                <div className="p-2 mt-2 d-flex justify-content-around">
                    <div className="d-flex align-items-center pointer" onClick={handleClose}>
                        <h6 className="m-0 text-muted">Batalkan</h6>
                    </div>  
                    <div className="rounded-pill bg-primary d-flex align-items-center pointer" onClick={()=> submit()}>
                        <h6 className="m-0" style={{color:"white", padding:"9px 12px"}}>Submit & Selesai</h6>
                    </div>     
                </div>
            </div>

        </Modal>
    </>
   
  );
}

export default ModalMulaiMutasiPartnerConfirm;
