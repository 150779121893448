import React, { useState } from "react";
import { Pagination } from "antd";
import { Col, Dropdown, Row } from "react-bootstrap";
import PropTypes from "prop-types";

const CustomPagination = (props) => {
  /*======================== Props ======================== */

  const {
    data,
    limit: initLimit,
    page: initPage,
    loading,
    handleChange,
    total_data,
  } = props;

  /*======================== UseState ======================== */

  const [limit, setLimit] = useState(initLimit);
  const [page, setPage] = useState(initPage);

  /*======================== Handler ======================== */

  const limitOpts = [10, 20, 30, 50];

  const handlePage = (value) => {
    handleChange("page", value);
    setPage(value);
  };

  const handleLimit = (value) => {
    handleChange("limit", value);
    setLimit(value);
    setPage(1);
  };

  const handlePageOf = () => {
    const start = limit * (page - 1) + 1;
    let end = data.length;
    if (limit <= data.length) {
      if (limit * page <= data.length) {
        end = limit * page;
      }
    }
    return `${start} - ${end} of ${total_data ? total_data : data.length}`;
  };

  /*======================== UseEffect ======================== */

  /*======================== Return ======================== */

  return (
    <Row className="custom-footer mt-3 align-items-center">
      <Col>
        <div className="d-flex align-items-center">
          <p className="mb-0 label">Show: </p>
          <Dropdown className="d-inline mx-2">
            <Dropdown.Toggle
              id="dropdown-autoclose-true"
              size="sm"
              variant="light"
              className="bg-white text-secondary px-3 border shadow-sm rounded"
            >
              <strong>{limit}</strong>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {limitOpts.map((item) => (
                <Dropdown.Item
                  key={item}
                  onClick={() => {
                    handleLimit(item);
                  }}
                >
                  {item}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Col>
      <Col sm={6} className="d-flex justify-content-center">
        <Pagination
          showSizeChanger={false}
          disabled={loading}
          showLessItems
          onChange={handlePage}
          current={page}
          pageSize={limit}
          total={total_data || data.length}
        />
      </Col>
      <Col>
        <p className="mb-0 label float-end">{handlePageOf()}</p>
      </Col>
    </Row>
  );
};

CustomPagination.propTypes = {
  data: PropTypes.array.isRequired,
  limit: PropTypes.number,
  total_data: PropTypes.number,
  loading: PropTypes.bool,
  handleChange: PropTypes.func,
};

export default CustomPagination;
