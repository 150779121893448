import OrderRentApi from "../../api/order-rent-api";

export function findTotalPrice(payload, token) {
  return async (dispatch) => {
    const api = new OrderRentApi();
    const result = await api.findTotalPrice(payload, token);
    if (result.status === "success" && result.data) {
      dispatch(updatePickupDetail({ totalPrice: result.data }));
    } else {
      dispatch(
        updatePickupDetail({
          totalPrice: {
            rent_day: 0,
            vehicle_price: 0,
            driver_price: 0,
            helper_price: 0,
            total_price: 0,
            final_price: 0,
          },
        })
      );
    }
  };
}

export function startMutation(id, payload, token, getOrderDetail) {
  return async (dispatch) => {
    const api = new OrderRentApi();
    const result = await api.startMutation(id, payload, token);
    if (result.status === 200) {
      getOrderDetail()
    }
  };
}

export function finishMutation(id, payload, token, getOrderDetail) {
  return async (dispatch) => {
    const api = new OrderRentApi();
    const result = await api.finishMutation(id, payload, token);
    if (result.status === 200) {
      getOrderDetail()
    }
  };
}

export function getHistoryPO(id) {
  return async (dispatch) => {
    const api = new OrderRentApi();
    const result = await api.getHistoryPO(id);
    // console.log("result PO ===> ", result)
    dispatch(setHistoryPO(result.data));
  };
}

export function getInfoPengantaranMutasi(id) {
  return async (dispatch) => {
    const api = new OrderRentApi();
    const result = await api.getInfoPengantaranMutasi(id);
    // console.log("result info pengantaran mutasi ===> ", result.data)
    dispatch(setInfoPengantaranMutasi(result.data));
  };
}

export const updatePickupDetail = (payload) => ({
  type: "UPDATE_PENGAMBILAN_SEWA_KENDARAAN_DETAIL",
  payload,
});

export const resetSewaKendaraan = () => ({
  type: "RESET_DATA",
});

export const setHistoryPO = (payload) => ({
  type: "GET_HISTORY_PO",
  payload
});

export const setInfoPengantaranMutasi = (payload) => ({
  type: "GET_INFO_PENGANTARAN_MUTASI",
  payload
});
