import { Badge } from "antd";
import PropTypes from "prop-types";
import moment from "moment";
import React from "react";
import { useState, useEffect } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { FiMoreVertical } from "react-icons/fi";
import { MdBookmark, MdBookmarkBorder } from "react-icons/md";
import { useHistory, useLocation } from "react-router-dom";
import { resetAuth } from "../../../../redux/action/auth_action";
import ParterManagementApi from "../../../../api/Partner/partner-api";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import { storage } from "../../../../redux/local_storage";

const PartnerListCard = (props) => {
  /*======================== Props ======================== */

  const { item, reload, canSave, service } = props;

  /*======================== Hooks ======================== */

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const auth = useSelector((st) => st.authReducer);

  /*======================== UseState ======================== */

  const [imageError, setImageError] = useState(false);

  /*======================== Handler ======================== */

  const handleClick = () => {
    history.push(`/partner/profile/${item.id}`);
    storage.store("selected-partner-service", service)
  };

  const getInital = (value) => {
    if (value) {
      const fullName = value.split(" ");
      const initials =
        fullName[0].charAt(0) +
        (fullName[1] ? fullName[1].charAt(0) : "") +
        (fullName[2] ? fullName[2].charAt(0) : "");
      return initials.toUpperCase();
    } else {
      return null;
    }
  };

  const savePartner = async (item) => {
    try {
      const api = new ParterManagementApi();

      const response = await api.savePartner(auth.access_token, {
        partnerId: item.id,
      });

      if (response.status === 401) {
        dispatch(resetAuth());
      } else if (response.status === "success") {
        message.success("Berhasil menyimpan partner");
        reload();
      }
    } catch (error) {
      console.log(error?.response);
    }
  };

  const unsavePartner = async (item) => {
    try {
      const api = new ParterManagementApi();

      const response = await api.unsavePartner(auth.access_token, item.id);

      if (response.status === 401) {
        dispatch(resetAuth());
      } else if (response.status === "success") {
        message.success("Berhasil menghapus partner");
        reload();
      }
    } catch (error) {
      console.log(error?.response);
    }
  };

  const getVehilcleContract = () => {
    if (
      item &&
      item.transaction_delivery_partner_offer &&
      item.transaction_delivery_partner_offer.length > 0
    ) {
      let total = 0;
      item.transaction_delivery_partner_offer.forEach((item) => {
        if (item.vehicle_quantity) {
          total += item.vehicle_quantity;
        }
      });
      return total;
    } else {
      return 0;
    }
  };

  const renderVehicle = () => {
    let component = [];
    if (item.mst_vehicle && item.mst_vehicle.length) {
      item.mst_vehicle.forEach((item, i) => {
        if (i < 2) {
          component.push(
            <li className="text text-capitalize" key={i}>
              {" "}
              {item.mst_vehicle_subtype?.name}{" "}
            </li>
          );
        }
      });
      if (item && item.mst_vehicle.length > 2) {
        component.push(
          <li className="text text-capitalize" key="2">
            {" "}
            + {item.mst_vehicle.length - 2} Lainnya{" "}
          </li>
        );
      }
    } else {
      return <li className="text">Belum Ada Kendaraan </li>;
    }
    return component;
  };

  const handleImageError = () => {
    setImageError(true);
  };

  /*======================== UseEffect ======================== */

  /*======================== Return ======================== */

  return (
    <Col xs={12} md={6}>
      <div className="bg-white partner-card w-full py-2 ps-3 mb-2">
        <Row>
          <Col xs={12} sm={6} md={4} lg={2}>
            {item.profile_picture ? (
              imageError ? (
                <div className="partner-card-avatar info">
                  <div className="content">{getInital(item.name)}</div>
                </div>
              ) : (
                <div className="partner-card-avatar">
                  <img
                    src={item.profile_picture}
                    alt="profile"
                    style={{ width: "100%", borderRadius: "10px" }}
                    onError={handleImageError}
                  />
                </div>
              )
            ) : (
              <div className="partner-card-avatar info">
                <div className="content">{getInital(item.name)}</div>
              </div>
            )}
          </Col>
          <Col xs={12} sm={6} md={8} lg={10}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h4 className="fw-bold text-capitalize">{item.name}</h4>
              {canSave && !item.master_customer_saved_partner ? (
                <span
                  className="me-4 text"
                  style={{
                    fontSize: "12px",
                    cursor: "pointer",
                    color: "#BBBBBB",
                  }}
                  onClick={() => savePartner(item)}
                >
                  <MdBookmarkBorder /> Simpan
                </span>
              ) : canSave && item.master_customer_saved_partner ? (
                <span
                  className="me-4 text"
                  style={{
                    fontSize: "12px",
                    cursor: "pointer",
                    color: "#FFBC03",
                  }}
                  onClick={() => unsavePartner(item)}
                >
                  <MdBookmark /> Tersimpan
                </span>
              ) : null}
            </div>
            <Row>
              {service == "null" ? <Col xs={12} sm={6} md={6} lg={3}>
                <p className="text-muted text mb-1">Kendaraan Tersedia</p>
                <ul className="ps-3">{renderVehicle()}</ul>
              </Col> : <Col xs={12} sm={6} md={6} lg={3}>
                <p className="text-muted text mb-1">Kendaraan Disewa</p>
                <p className="text" style={{ fontWeight: "bold" }}>{item.vehicle_rented_count ?? 0} Kendaraan</p>
              </Col>}
              {service == "null" ? <Col xs={12} sm={6} md={6} lg={3}>
                <p className="text-muted text mb-1">Penawaran diterima</p>
                <p className="text">
                  {item.transaction_delivery_partner_offer?.length} Penawaran
                </p>
              </Col> : <Col xs={12} sm={6} md={6} lg={3}>
                <p className="text-muted text mb-1">Kendaraan Tersedia</p>
                <p className="text" style={{ fontWeight: "bold" }}>
                  {item.vehicle_count  ?? 0} Kendaraan
                </p>
              </Col>}
              {service == "null" ? <Col xs={12} sm={6} md={6} lg={3}>
                <p className="text-muted text mb-1">Riwayat Kontrak</p>
                <p className="text" style={{ color: "#0FAA4B" }}>
                  {" "}
                  {getVehilcleContract()} Kendaraan disewa
                  {/* {item.vehicle_rented_count} Kendaraan disewa */}
                </p>
              </Col> : <Col xs={12} sm={6} md={6} lg={3}>
                <p className="text-muted text mb-1">Area Layanan</p>
                <p className="text" style={{ fontWeight: "bold" }}>
                  {item.partner_service_count  ?? 0} Area
                </p>
              </Col>}
              <Col xs={12} sm={6} md={6} lg={3} className="pe-4">
                <Button
                  size="sm"
                  className="rounded-10 text-white text-capitalize w-100"
                  onClick={handleClick}
                >
                  Lihat Detail
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Col>
  );
};

PartnerListCard.propTypes = {
  item: PropTypes.object,
};

export default PartnerListCard;
