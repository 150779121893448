import React, { Component, useState, useEffect } from "react";
import {
  Card,
  FormGroup,
  Form,
  InputGroup,
  Button,
  FloatingLabel,
} from "react-bootstrap";
import { message } from "antd";
import { withRouter, useHistory } from "react-router-dom";
import AuthApi from "../../api/auth-api";
import { setAuth } from "../../redux/action/auth_action";
import OneSignal from "react-onesignal";
import { useDispatch, useSelector } from "react-redux";
import useBreakpoint from "../responsiveUtils/useBreakpoint";
import { changeTextContent } from "../layouts/layout_helper";

// images
import iconUser from "../../assets/images/icon-user.svg";
import iconKey from "../../assets/images/icon-key.svg";

// icons
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import { hideSidenavMobile } from "../layouts/layout_helper";
import OtpEngine from "../../api/otp-engine";

const TabAuth = () => {
  const { isMobile } = useBreakpoint();
  const history = useHistory();
  const dispatch = useDispatch();
  const authReducer = useSelector((st) => st.authReducer);
  // add for otp engine
  const [otpProcess, setOtpProcess] = useState(false)
  const [counter, setCounter] = useState(0);
  // end add for otp engine
  const [state, setState] = useState({
    hidePwd: true,
    user: "",
    password: "",
    otp_id: "" //add for otp engine
  });
  const [toggleResetPassword, setToggleResetPassword] = useState(false);

  useEffect(() => {
    if (isMobile) {
      hideSidenavMobile();
    }
  }, [isMobile]);

  const loginCustomer = async () => {
    const authAPI = new AuthApi();
    const response = await authAPI.loginCustomer(
      state.user,
      state.password,
      authReducer.notif_id,
      state.otp_id //add for otp engine
    );
    if (response && response.status === "success" && response.data) {
      const detailCustomer = await authAPI.getDetailCustomer(
        response.data.access_token
      );
      dispatch(setAuth({ ...response.data, ...detailCustomer.data }));
      message.success("Login Berhasil!");
    } else {
      if (response?.data?.error === "ERR_WA_UNVERIFIED") {
        localStorage.setItem(
          "token_otp_request",
          response?.data?.data?.token_otp_request
        );
        localStorage.setItem("customer_id", response?.data?.data?.customer_id);

        const otpRes = await authAPI.otpWithRequestToken();

        if (otpRes.status === "success") {
          message.success(otpRes.message);
        } else {
          message.error("error send otp");
        }

        history.push("/otp");
      }
      console.log(response);
    }
  };

  const handleLogin = async () => {
    if (state.user !== "" && state.password !== "") {
      await loginCustomer();
    } else {
      message.error("Email/No. Telpon dan Password tidak boleh kosong");
    }
  };

  const handleForgotPassword = async () => {
    const authAPI = new AuthApi();
    if (!state.user)
      return message.error("Email/No. Telpon dan Password tidak boleh kosong");

    const response = await authAPI.forgotPassword({ user: state.user });
    if (response.status === "success")
      return message.success(
        "Request berhasil, silahkan cek email atau whatsapp anda."
      );

    // console.log("RESPONSE", response);
  };

  //add for otp engine
  const handleOtpEngine = async() => {
    if(!state.user || !state.password){
      message.error("Input User dan Password Terlebih dahulu")
      return;
    }

    const emaiRegex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
    const checkUserType = emaiRegex.test(state.user)

    if(checkUserType){
      handleLogin()
      return;
    }

    setCounter(15)
    setOtpProcess(true)

    const body = {
      data_user_id: -1,
      // data_no_hp: state.user,
      data_no_hp: state.user,
      data_destination: 1,
      data_type: 1
    }

    const otpEngine = new OtpEngine();
    const response = await otpEngine.otpRequest(body)

    if (response?.status === "success"){
      setState({...state, otp_id: response?.data?.id})
      window.open(response?.data?.wa_me,'_blank');
    }
  }

  const cekOtpEngine = async() => {
    // console.log(state)
    const otpEngine = new OtpEngine();
    const response = await otpEngine.otpCheck(state.otp_id)
    if (response?.status === "success"){
      // console.log(1223123123,response)
      handleLogin()
    }
  }

  useEffect(()=>{
    counter > 0 && setTimeout(()=> setCounter(counter-1),1000)
    if(counter === 0 && otpProcess){
      cekOtpEngine()
      setOtpProcess(false)
    } 
  },[counter])
  // end add for otp engine


  return (
    <>
      {authReducer.access_token === null ? (
        <Card.Body
          className={`pt-0 d-flex flex-column ${isMobile && "card-body-auth"} `}
        >
          {!isMobile && (
            <strong className="h2 fw-bold">
              {toggleResetPassword ? "Lupa Kata Sandi" : "Login"}
            </strong>
          )}
          <h3 className={`${isMobile ? "mt-4" : "mt-2"}`}>
            {toggleResetPassword
              ? "Masukkan nomor ponsel atau email anda, untuk proses atur ulang kata sandi."
              : "Masuk dengan akun eLOG/eDOT anda untuk melakukan transaksi."}
          </h3>
          <div className="rc-ice p-2 mb-2 mt-4">
            <Form>
              <FormGroup className="form-group my-1 d-flex">
                <img src={iconUser} alt="" className="icon-form-o" />
                <FloatingLabel
                  label="Alamat Email/No. Telpon"
                  style={{ width: "92.2%", fontSize: "12px" }}
                >
                  <Form.Control
                    type="text"
                    placeholder="Alamat Email/No. Telpon"
                    className="form-white border-0 py-3"
                    onChange={(e) => {
                      setState({
                        ...state,
                        user: e.target.value,
                      });
                    }}
                  />
                </FloatingLabel>
              </FormGroup>

              <FormGroup
                className={`${
                  toggleResetPassword ? "d-none" : ""
                } form-group my-1 d-flex `}
              >
                <img src={iconKey} alt="" className="icon-form-o" />
                <FloatingLabel
                  label="Kata Sandi"
                  style={{ width: "92.2%", fontSize: "12px" }}
                >
                  <Form.Control
                    type={state.hidePwd ? "password" : "text"}
                    placeholder="Kata Sandi"
                    className="form-white border-0 py-3"
                    onChange={(e) => {
                      setState({
                        ...state,
                        password: e.target.value,
                      });
                    }}
                  />
                </FloatingLabel>
                <InputGroup.Text
                  className="border-0 pointer"
                  onClick={() => {
                    setState({
                      ...state,
                      hidePwd: !state.hidePwd,
                    });
                  }}
                >
                  {state.hidePwd ? <VscEye /> : <VscEyeClosed />}
                </InputGroup.Text>
              </FormGroup>
              <div className="mt-2 px-4">
                <h6 className="mb-0 text-muted">
                  {toggleResetPassword ? "" : "Lupa Kata Sandi ?"}{" "}
                  <h6
                    className="float-end underline text-primary pointer"
                    onClick={() => {
                      setToggleResetPassword(!toggleResetPassword);
                      if (isMobile)
                        changeTextContent(
                          "#head-menu-text-login",
                          toggleResetPassword ? "Login" : "Lupa Kata Sandi"
                        );
                    }}
                  >
                    {toggleResetPassword ? "Kembali" : "Atur Ulang Kata Sandi"}{" "}
                  </h6>
                </h6>
              </div>
            </Form>
          </div>

          <h5 className="fw-light mb-2 text-center mt-auto">
            Belum memiliki Akun eLOG/eDOT?{" "}
            <a
              href={`${process.env.REACT_APP_WEBSITE_URL}/daftar-pengguna?type=personal`}
              className="decor-0"
              target="_blank"
              rel="noreferrer"
            >
              Daftar Sekarang
            </a>
          </h5>
          <div className="mt-4 d-grid">
            {/* remove conditional rendering if otp engine not use any more */}
            {!otpProcess &&
              <Button
                size="sm"
                className="fw-bold py-2 rounded-pill"
                onClick={async () => {
                  if (toggleResetPassword) await handleForgotPassword();
                  else await handleOtpEngine(); //change for otp engine
                  // else await handleLogin();
                }}
              >
                {toggleResetPassword ? "Submit" : "Login"}
              </Button>
            }
            {/* add for otp engine */}
            {otpProcess && counter !== 0 &&
            <div
                className="d-flex w-100 justify-content-center align-item-center"
                style={{
                    backgroundColor:"#E9ECEF",
                    padding:"8px 0",
                    borderRadius:"20px",
                    fontSize:"14px",
                    color:"#545454"
                }}
            >
                {counter}
            </div>
            }
            {/* end add for otp engine */}
          </div>
        </Card.Body>
      ) : null}
    </>
  );
};

export default TabAuth;

// LEGACY CODE

// class TabAuth extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       hidePwd: true,
//       user: "",
//       password: "",
//     };
//   }

// async loginCustomer() {
//   const authAPI = new AuthApi();
//   const response = await authAPI.loginCustomer(
//     this.state.user,
//     this.state.password,
//     this.props.authReducer.notif_id
//   );
//   if (response && response.status === "success" && response.data) {
//     const detailCustomer = await authAPI.getDetailCustomer(
//       response.data.access_token
//     );
//     this.props.dispatch(
//       setAuth({ ...response.data, ...detailCustomer.data })
//     );
//     message.success("Login Berhasil!");
//   } else {
//     if (response?.data?.error === "ERR_WA_UNVERIFIED") {
//       localStorage.setItem(
//         "token_otp_request",
//         response?.data?.data?.token_otp_request
//       );
//       localStorage.setItem("customer_id", response?.data?.data?.customer_id);

//       const otpRes = await authAPI.otpWithRequestToken();

//       if (otpRes.status === "success") {
//         message.success(otpRes.message);
//       } else {
//         message.error("error send otp");
//       }

//       this.props.history.push("/otp");
//     }
//     console.log(response);
//   }
// }

//   async handleLogin() {
//     if (this.state.user !== "" && this.state.password !== "") {
//       await this.loginCustomer();
//     } else {
//       message.error("Email/No. Telpon dan Password tidak boleh kosong");
//     }
//   }

//   render() {
//     return (
//       <>
//         {this.props.authReducer.access_token === null ? (
//           <Card.Body className="pt-0">
//             <strong className="h2 fw-bold">Login</strong>
//             <h3 className="mt-2">
//               Masuk dengan akun eLOG/eDOT untuk menyelesaikan proses pemesanan
//               Anda.
//             </h3>
//             <div className="rc-ice p-2 mb-2 mt-4">
//               <Form>
//                 <FormGroup className="form-group my-1 d-flex">
//                   <img src={iconUser} alt="" className="icon-form-o" />
//                   <FloatingLabel
//                     label="Alamat Email/No. Telpon"
//                     style={{ width: "92.2%", fontSize: "12px" }}
//                   >
//                     <Form.Control
//                       type="text"
//                       placeholder="Alamat Email/No. Telpon"
//                       className="form-white border-0 py-3"
//                       onChange={(e) => {
//                         this.setState({
//                           user: e.target.value,
//                         });
//                       }}
//                     />
//                   </FloatingLabel>
//                 </FormGroup>
//                 <Form.Group className="form-group my-1 d-flex">
//                   <img src={iconKey} alt="" className="icon-form-o" />
//                   <FloatingLabel
//                     label="Kata Sandi"
//                     style={{ width: "92.2%", fontSize: "12px" }}
//                   >
//                     <Form.Control
//                       type={this.state.hidePwd ? "password" : "text"}
//                       placeholder="Kata Sandi"
//                       className="form-white border-0 py-3"
//                       onChange={(e) => {
//                         this.setState({
//                           password: e.target.value,
//                         });
//                       }}
//                     />
//                   </FloatingLabel>
//                   <InputGroup.Text
//                     className="border-0 pointer"
//                     onClick={() => {
//                       this.setState({
//                         hidePwd: !this.state.hidePwd,
//                       });
//                     }}
//                   >
//                     {this.state.hidePwd ? <VscEye /> : <VscEyeClosed />}
//                   </InputGroup.Text>
//                 </Form.Group>
//               </Form>
//             </div>
//             <div className="mt-4" hidden>
//               <p className="mb-0 text-muted">
//                 Lupa Password?{" "}
//                 <a href="/#" className="float-end">
//                   Reset Password
//                 </a>
//               </p>
//             </div>
//             <h5 className="fw-light mb-2 text-center mt-4">
//               Belum memiliki Akun eLOG/eDOT?{" "}
//               <a
//                 href={`${process.env.REACT_APP_WEBSITE_URL}/daftar-pengguna?type=personal`}
//                 className="decor-0"
//                 target="_blank"
//                 rel="noreferrer"
//               >
//                 Daftar Sekarang
//               </a>
//             </h5>
//             <div className="mt-4 d-grid">
//               <Button
//                 size="sm"
//                 className="fw-bold py-2 rounded-pill"
//                 onClick={async () => {
//                   await this.handleLogin();
//                 }}
//               >
//                 Login
//               </Button>
//             </div>
//           </Card.Body>
//         ) : null}
//       </>
//     );
//   }
// }

// const mapStateToProps = (state) => ({
//   authReducer: state.authReducer,
// });
// export default connect(mapStateToProps)(withRouter(TabAuth));
