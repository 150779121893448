import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Tabs, Tab, Button, Dropdown } from "react-bootstrap";
import SidebarNotifItem from "./SidebarNotifItem";
import { useSelector, useDispatch } from "react-redux";
import { getNotification, setTabKey } from "../../redux/action/notif_action";
import { FiChevronRight, FiChevronDown } from "react-icons/fi";
import qs from "query-string";
import NotifApi from "../../api/notif-api";
import useBreakpoint from "../responsiveUtils/useBreakpoint";

// icon

const SidebarNotification = ({ show, handleClose, handleShow }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const notif = useSelector((st) => st.notifReducer.notification);
  const tabKey = useSelector((st) => st.notifReducer.tabKey);
  const auth = useSelector((st) => st.authReducer);
  const { isMobile } = useBreakpoint();

  const [query, setQuery] = useState({ limit: 999 });

  useEffect(() => {
    const stringified = qs.stringify(query);
    if (auth.access_token) {
      dispatch(getNotification(stringified, auth.access_token));
    }
  }, [query]);

  const handleChangeTab = (key) => {
    if (+key === 1) {
      setQuery({ ...query, filter: undefined });
    }
    if (+key === 2) {
      setQuery({ ...query, filter: "transaction" });
    }
    if (+key === 3) {
      setQuery({ ...query, filter: "offer" });
    }
    if (+key === 4) {
      setQuery({ ...query, filter: "invoice" });
    }
    if (+key === 5) {
      setQuery({ ...query, filter: "other" });
    }

    dispatch(setTabKey(+key));
  };

  const markAllRead = async () => {
    try {
      const api = new NotifApi();
      await api.markAllRead(auth.access_token);
    } catch (error) {
      console.log(error?.response);
    }
  };

  return (
    <>
      <Dropdown.Menu
        className=""
        style={{
          zIndex: 100,
          width: isMobile ? "95vw" : "450px",
          left: isMobile ? "-100px" : undefined,
          top: isMobile ? "40px" : undefined,
        }}
      >
        {/* <div
          className="bg-white border rounded shadow-md"
          style={{ zIndex: 100, width: "450px" }}
        > */}
        <div className="w-100  px-4 py-3">
          <p
            className="float-end pointer text-primary underline fw-bold"
            size="sm"
            onClick={markAllRead}
          >
            Tandai Dibaca
          </p>
          <h3 className="fw-bold text-start">Notifikasi</h3>
          <Tabs
            defaultActiveKey={tabKey}
            activeKey={tabKey}
            className="tabs-trx mb-2 border-bottom-2"
            onSelect={handleChangeTab}
            style={{flexWrap: "nowrap" }}
          >
            <Tab eventKey="1" title="Semua"></Tab>
            <Tab eventKey="2" title="Pesanan"></Tab>
            <Tab eventKey="3" title="Penawaran"></Tab>
            <Tab eventKey="4" title="Invoice"></Tab>
            <Tab eventKey="5" title="Lainnya"></Tab>
          </Tabs>
        </div>
        <div className="scrollable">
          {notif?.data?.map((el, index) => (
            <SidebarNotifItem key={index} data={el} />
          ))}
        </div>

        <p
          className="text-primary pointer text-center mt-2"
          onClick={() => history.push("/pengaturan/notifikasi")}
        >
          Lihat Semua
        </p>
        {/* </div> */}
      </Dropdown.Menu>
    </>
  );
};

export default SidebarNotification;
