import React, { useState, useEffect } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import { FiChevronDown } from "react-icons/fi";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { updateProcurementStatus } from "../../../../redux/action/Management/procurement_action";

const DeleteProcurement = (props) => {
  /*======================== Props ======================== */

  const { isOpen, handleClose, data, handleLoad } = props;
  const dispatch = useDispatch();

  /*======================== UseState ======================== */

  /*======================== Handler ======================== */

  const handleOnClose = () => {
    handleClose();
  };

  const handleDelete = async () => {
    const response = await dispatch(
      updateProcurementStatus({
        status: "CANCEL",
        id: data.id,
      })
    );
    if (response.status === "success") {
      handleLoad();
    }
    handleClose();
  };

  /*======================== UseEffect ======================== */

  /*======================== Return ======================== */

  return (
    <Modal
      contentClassName="rounded-card p-2"
      show={isOpen}
      onHide={handleOnClose}
      className="p-3"
      centered
    >
      <div className="d-flex p-2 border-bottom">
        <h2 className="fw-bold">Konfirmasi</h2>
        <MdClose className="ms-auto me-2 pointer" onClick={handleOnClose} />
      </div>
      <Modal.Body>
        <div className="jenis-barang-opts mt-1">
          <p className="my-5">
            Apakah anda yakin untuk membatalkan penawaran <b>{data.name}</b> ?
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size="sm"
          className="w-100 fw-bold py-2 px-4 rounded-pill text-capitalize mt-2"
          onClick={handleDelete}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteProcurement;
