import { Row, Col } from "react-bootstrap";
import DokumenKontrakCard from "./component/DokumenKontrakCard";

import "./styles.scss";

const DokumenKontrakDetail = (props) => {
  /*======================== Props ======================== */

  const { data } = props;

  /*======================== Hooks ======================== */

  /*======================== State ======================== */

  /*======================== Handler ======================== */

  /*======================== Return ======================== */

  return (
    <Row className="mt-4">
      {data.length > 0
        ? data.map((item, index) => (
            <Col xs={12} md={6} className="mt-2" key={item.id}>
              <DokumenKontrakCard data={item} />
            </Col>
          ))
        : null}
    </Row>
  );
};

export default DokumenKontrakDetail;
