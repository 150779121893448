import { Modal, Row, Col } from "react-bootstrap";
import { MdClose } from "react-icons/md";

const Partner = (props) => {
  /*======================== Hooks ======================== */

  /*======================== Props ======================== */

  const { isOpen, data, handleClose } = props;

  /*======================== UseState ======================== */

  /*======================== Handler ======================== */
  const handleOnClose = () => {
    handleClose();
  };

  const renderData = () => {
    let component = [];
    data.forEach((item, index) => {
      component.push(
        <Col lg={6} md={6} sm={12} xs={12} key={index}>
          <p>{item.name}</p>
        </Col>
      );
    });
    return component;
  };

  /*======================== UseEffect ======================== */

  /*======================== Return ======================== */

  return (
    <Modal
      contentClassName="rounded-card p-2"
      show={isOpen}
      onHide={handleOnClose}
      className="p-3"
      size="md"
      centered
    >
      <div className="d-flex p-2 border-bottom">
        <h2 className="fw-bold">Partner Kontrak</h2>
        <MdClose className="ms-auto me-2 pointer" onClick={handleOnClose} />
      </div>
      <Modal.Body>
        <div>
          <h5 className="fw-bold"> Partner Terpilih </h5>
          <Row className="pe-3 custom-scroll">{renderData()}</Row>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Partner;
