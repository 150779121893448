import { useState, useEffect } from "react";
import {
  Row,
  Col,
  Tabs,
  Tab,
  Form,
  InputGroup,
  FloatingLabel
} from "react-bootstrap";

// icons
import { FaSearch } from "react-icons/fa";
import { IoMenuOutline } from "react-icons/io5";

const MobileHeader = ({ handleChangeTab, openSidenavMobile, getRoleByName, getAccountByName, eventKey }) => {

  const [role, setRole] = useState('');
  const [account, setAccount] = useState('');

  const handleSearch = (event) => {
    setRole(event.target.value);
    getRoleByName(event.target.value);
  }

  const handleAccountSearch = (event) => {
    setAccount(event.target.value);
    getAccountByName(event.target.value);
  }

  useEffect(() => {
    if (eventKey) {
      setRole('');
      setAccount('');
    }
  }, [eventKey]);

  return (
    <div className="p-2 head-menu trx w-100 shadow-md">
      <div
        className="d-flex mb-2 w-100 justify-content-center px-2 flex-column"
        style={{ marginTop: "1.7em" }}
      >
        <div className="d-flex">
          <IoMenuOutline
            onClick={openSidenavMobile}
            className="text-secondary pointer me-2"
            style={{ fontSize: 25 }}
          />

          <h4 className="mt-1">Management Role & User</h4>
        </div>
        <Col>
          <Tabs
            defaultActiveKey={eventKey}
            className="tabs-trx mb-2 border-bottom-2"
            onSelect={handleChangeTab}
          >
            <Tab eventKey="1" title="Role User"></Tab>
            <Tab eventKey="2" title="Akun User"></Tab>
            {/* <Tab eventKey="3" title="Pengemudi"></Tab> */}
          </Tabs>
        </Col>
        <Row>
          <Col xs={12}>
            {
              eventKey === 1 ? (
                <InputGroup className="border rounded-card w-100 flex-nowrap">
                  <FloatingLabel
                    className=""
                    label={eventKey === 1 ? "Cari Role yang tersedia" : "Cari Akun yang tersedia"}
                    style={{ fontSize: "12px", height: "50px" }}
                  >

                    <Form.Control
                      className="border-0 rounded-card"
                      type="text"
                      style={{ height: "50px" }}
                      placeholder="Cari Role yang tersedia"
                      onChange={(ev) => handleSearch(ev)}
                      value={role}
                    />

                  </FloatingLabel>
                  <InputGroup.Text
                    className="border-0 search-icon-trx rounded-card ms-auto"
                    style={{ height: "50px" }}
                  >
                    <FaSearch />
                  </InputGroup.Text>
                </InputGroup>
              ) : (
                <InputGroup className="border rounded-card w-100 flex-nowrap">
                  <FloatingLabel
                    className=""
                    label="Cari Akun yang tersedia"
                    style={{ fontSize: "12px", height: "50px" }}
                  >

                    <Form.Control
                      className="border-0 rounded-card"
                      type="text"
                      style={{ height: "50px" }}
                      placeholder="Cari Akun yang tersedia"
                      onChange={(ev) => handleAccountSearch(ev)}
                      value={account}
                    />

                  </FloatingLabel>
                  <InputGroup.Text
                    className="border-0 search-icon-trx rounded-card ms-auto"
                    style={{ height: "50px" }}
                  >
                    <FaSearch />
                  </InputGroup.Text>
                </InputGroup>
              )
            }
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default MobileHeader;
