import React from "react";

/*======================== Third Party ======================== */
import {
  Dropdown
} from "react-bootstrap";


const SortByDate = ({sort, sortOptions, handleSort}) => {
    return(
        <div className="pointer d-flex position-absolute mt-2" style={{right:15}}>
            <div className="d-flex align-items-center">
                <h4 className="mt-2 me-2" style={{ fontWeight: "600" }}>
                    Urutkan Berdasarkan:
                </h4>
                <Dropdown className="d-inline mx-2">
                    <Dropdown.Toggle
                    id="dropdown-autoclose-true"
                    size="sm"
                    variant="light"
                    className="bg-white text-secondary px-3 border shadow-sm rounded"
                    >
                    <strong>{sort}</strong>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                    {sortOptions.map((item, index) => (
                        <Dropdown.Item
                        key={index}
                        href="#"
                        onClick={() => {
                            handleSort(item.title);
                        }}
                        >
                        {item.title}
                        </Dropdown.Item>
                    ))}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    );
}

export default SortByDate;
