import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateDetail } from "../../redux/action/kirimbarang_action";
import { Modal, Button, Card, Row, Col } from "react-bootstrap";
import { Calendar, utils } from "react-modern-calendar-datepicker";
import { message, TimePicker } from "antd";
import moment from "moment";
import "moment/locale/id";
import useBreakpoint from "../responsiveUtils/useBreakpoint";

moment.locale("id");

function DateTime(props) {
  const dispatch = useDispatch();
  const defaultValue = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
  };

  const [selectedDay, setSelectedDay] = useState(defaultValue);
  const [show, setShow] = useState(false);
  const [labelValue, setLabelValue] = useState("");
  const [showLabel, setShowLabel] = useState(false);
  const currentTime = moment().format("HH:mm");
  const [selectedTime, setSeletedTime] = useState(currentTime);
  const tipe = useSelector((st) => st.kirimbarangReducer.tipe);

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
  };

  const updateDateDetail = () => {
    let date = moment(
      `${selectedDay.year}/${selectedDay.month}/${selectedDay.day} ${selectedTime}`
    ).format();

    let label = { ...selectedDay, month: selectedDay.month - 1 };
    label =
      moment(label).format("dddd") + ", " + moment(label).format("DD/MMM/YYYY");

    label += ` ${selectedTime}`;
    setLabelValue(label);
    dispatch(updateDetail({ date, tipe: "SCHEDULED" }));
  };

  const { isMobile } = useBreakpoint();

  return (
    <>
      <Button
        variant="light"
        onClick={handleShow}
        className={
          props.tipe === "SCHEDULED" ? "br-color active w-50" : "br-color w-50"
        }
      >
        <p className="fw-bold mb-0">Kirim Terjadwal</p>
        {tipe === "INSTANT" ? (
          <h6 className="mb-0 fw-light">Ambil diatas 6 jam</h6>
        ) : (
          <h6 className="mb-0 fw-light">{labelValue}</h6>
        )}
      </Button>
      <Modal
        contentClassName={`${!isMobile && "modal-80w"} p-1`}
        show={show}
        className=""
        centered
        onHide={handleClose}
      >
        <Modal.Header closeButton className="bg-white rounded-header-modal">
          <strong>Pengiriman Terjadwal</strong>
        </Modal.Header>
        <Modal.Body>
          <Calendar
            calendarSelectedDayClassName="date-select"
            value={selectedDay}
            onChange={setSelectedDay}
            shouldHighlightWeekends
            minimumDate={utils().getToday()}
          />
          <Row>
            <Col xs={8} className="align-self-center text-end">
              <span className="h5">Pilih Jam Pengiriman:</span>
            </Col>
            <Col xs={4} className="align-self-center">
              <TimePicker
                allowClear={false}
                minuteStep={10}
                defaultValue={moment(currentTime, "HH:mm")}
                value={selectedTime ? moment(selectedTime, "HH:mm") : null}
                onChange={(time) => 
                  // ==== di hide sementara
                  // {
                  //   const min = moment().add(6, "hours");
                  //   if (
                  //     moment(
                  //       `${selectedDay.year}/${selectedDay.month}/${selectedDay.day} ${selectedTime}`
                  //     ).isBefore(min)
                  //   ) {
                  //     message.error("Minimal 6 jam dari sekarang");
                  //   } else setSeletedTime(moment(time, "HH:mm").format("HH:mm"));
                  // }
                  setSeletedTime(moment(time, "HH:mm").format("HH:mm"))
                }
                format="HH:mm"
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="border-top-0 bg-ice">
          <Button
            size="sm"
            variant="light"
            className="fw-bold me-4"
            onClick={() => handleClose()}
          >
            Batal
          </Button>
          <Button
            size="sm"
            variant="primary"
            className="fw-bold rounded-pill px-3"
            onClick={() =>
              // === di HIDE sementara 
              // {
              //   const min = moment().add(6, "hours");
              //   if (
              //     moment(
              //       `${selectedDay.year}/${selectedDay.month}/${selectedDay.day} ${selectedTime}`
              //     ).isBefore(min)
              //   ) {
              //     message.error("Minimal 6 jam dari sekarang");
              //   } else {
              //     props.updateTipeKirimBarang();
              //     handleClose();
              //     updateDateDetail();
              //   }
              // }
              {
                props.updateTipeKirimBarang();
                handleClose();
                updateDateDetail();
              }
            }
          >
            Tambah
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DateTime;
