import React, { Component, useState, useEffect } from "react";
import {
  Card,
  FormGroup,
  Form,
  InputGroup,
  Button,
  FloatingLabel,
} from "react-bootstrap";
import { message } from "antd";
import { withRouter, useHistory } from "react-router-dom";
import AuthApi from "../../../api/auth-api";
import { setAuth } from "../../../redux/action/auth_action";
import OneSignal from "react-onesignal";
import { useDispatch, useSelector } from "react-redux";
import useBreakpoint from "../../responsiveUtils/useBreakpoint";
import { changeTextContent } from "../../layouts/layout_helper";

// images
import iconUser from "../../../assets/images/icon-user.svg";
import iconKey from "../../../assets/images/icon-key.svg";
import { RiArrowLeftSLine } from "react-icons/ri";

// icons
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import { hideSidenavMobile } from "../../layouts/layout_helper";

const TabAuth = () => {
  const { isMobile } = useBreakpoint();
  const history = useHistory();
  const dispatch = useDispatch();
  const authReducer = useSelector((st) => st.authReducer);
  const [state, setState] = useState({
    hidePwd: true,
    user: "",
    password: "",
  });
  const [toggleResetPassword, setToggleResetPassword] = useState(false);

  useEffect(() => {
    if (isMobile) {
      hideSidenavMobile();
    }
  }, [isMobile]);

  const loginCustomer = async () => {
    const authAPI = new AuthApi();
    const response = await authAPI.loginCustomer(
      state.user,
      state.password,
      authReducer.notif_id
    );
    if (response && response.status === "success" && response.data) {
      const detailCustomer = await authAPI.getDetailCustomer(
        response.data.access_token
      );
      dispatch(setAuth({ ...response.data, ...detailCustomer.data }));
      message.success("Login Berhasil!");
    } else {
      if (response?.data?.error === "ERR_WA_UNVERIFIED") {
        localStorage.setItem(
          "token_otp_request",
          response?.data?.data?.token_otp_request
        );
        localStorage.setItem("customer_id", response?.data?.data?.customer_id);

        const otpRes = await authAPI.otpWithRequestToken();

        if (otpRes.status === "success") {
          message.success(otpRes.message);
        } else {
          message.error("error send otp");
        }

        history.push("/otp");
      }
      console.log(response);
    }
  };

  const handleLogin = async () => {
    // if (state.user !== "" && state.password !== "") {
    //   await loginCustomer();
    // } else {
    //   message.error("Email/No. Telpon dan Password tidak boleh kosong");
    // }
    window.location.href = (`https://accounts.dev.edot.id/masuk?client_id=628845bafe82d418dda087e5ff18df8a=true&redirect_url=https://dev-customer.elog.co.id/oauth/access_token`)
  };

  const handleForgotPassword = async () => {
    const authAPI = new AuthApi();
    if (!state.user)
      return message.error("Email/No. Telpon dan Password tidak boleh kosong");

    const response = await authAPI.forgotPassword({ user: state.user });
    if (response.status === "success")
      return message.success(
        "Request berhasil, silahkan cek email atau whatsapp anda."
      );

    // console.log("RESPONSE", response);
  };

  return (
    <>
      {authReducer.access_token === null ? (
        <Card.Body
          className={`pt-2 d-flex flex-column ${isMobile && "card-body-auth"} `}
        >
          
          {!isMobile && (
            <>
              <RiArrowLeftSLine
                size={27}
                style={{marginLeft:"-9px", color:"#666666", cursor:"pointer"}}
                onClick={()=> history.push("/")}
              />
              <strong className="h2 mt-3 fw-bold">
                {toggleResetPassword ? "Lupa Kata Sandi" : "Login"}
              </strong>
            </>
          )}
          <h4 className={`${isMobile ? "mt-3" : "mt-2"}`}>
            {toggleResetPassword
              ? "Masukkan nomor ponsel atau email anda, untuk proses atur ulang kata sandi."
              : "Silahkan melakukan login akun eLOG menggunakan akun eDOT Group anda"}
          </h4>
          {/* style={{display:"none"}} */}
          <div className="rc-ice p-2 mb-2 mt-4" style={{display:"none"}}>
            <Form>
              <FormGroup className="form-group my-1 d-flex">
                <img src={iconUser} alt="" className="icon-form-o" />
                <FloatingLabel
                  label="Alamat Email/No. Telpon"
                  style={{ width: "92.2%", fontSize: "12px" }}
                >
                  <Form.Control
                    type="text"
                    placeholder="Alamat Email/No. Telpon"
                    className="form-white border-0 py-3"
                    onChange={(e) => {
                      setState({
                        ...state,
                        user: e.target.value,
                      });
                    }}
                  />
                </FloatingLabel>
              </FormGroup>

              <FormGroup
                className={`${
                  toggleResetPassword ? "d-none" : ""
                } form-group my-1 d-flex `}
              >
                <img src={iconKey} alt="" className="icon-form-o" />
                <FloatingLabel
                  label="Kata Sandi"
                  style={{ width: "92.2%", fontSize: "12px" }}
                >
                  <Form.Control
                    type={state.hidePwd ? "password" : "text"}
                    placeholder="Kata Sandi"
                    className="form-white border-0 py-3"
                    onChange={(e) => {
                      setState({
                        ...state,
                        password: e.target.value,
                      });
                    }}
                  />
                </FloatingLabel>
                <InputGroup.Text
                  className="border-0 pointer"
                  onClick={() => {
                    setState({
                      ...state,
                      hidePwd: !state.hidePwd,
                    });
                  }}
                >
                  {state.hidePwd ? <VscEye /> : <VscEyeClosed />}
                </InputGroup.Text>
              </FormGroup>
              <div className="mt-2 px-4">
                <h6 className="mb-0 text-muted">
                  {toggleResetPassword ? "" : "Lupa Kata Sandi ?"}{" "}
                  <h6
                    className="float-end underline text-primary pointer"
                    onClick={() => {
                      setToggleResetPassword(!toggleResetPassword);
                      if (isMobile)
                        changeTextContent(
                          "#head-menu-text-login",
                          toggleResetPassword ? "Login" : "Lupa Kata Sandi"
                        );
                    }}
                  >
                    {toggleResetPassword ? "Kembali" : "Atur Ulang Kata Sandi"}{" "}
                  </h6>
                </h6>
              </div>
            </Form>
          </div>

          
          <div className="mt-4 mb-5 d-grid">
            <Button
              size="sm"
              className="fw-bold py-2 rounded-pill"
              onClick={async () => {
                if (toggleResetPassword) await handleForgotPassword();
                else await handleLogin();
              }}
            >
              {toggleResetPassword ? "Submit" : "Login akun eDOT Group"}
            </Button>
          </div>
          <h5 className="fw-light mb-2 text-center mt-auto">
            belum memiliki Akun eDOT?{" "}
            <a
              href={`${process.env.REACT_APP_WEBSITE_URL}/daftar-pengguna?type=personal`}
              className="decor-0"
              target="_blank"
              rel="noreferrer"
            >
              Daftar disini
            </a>
          </h5>
        </Card.Body>
      ) : null}
    </>
  );
};

export default TabAuth;

// LEGACY CODE

// class TabAuth extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       hidePwd: true,
//       user: "",
//       password: "",
//     };
//   }

// async loginCustomer() {
//   const authAPI = new AuthApi();
//   const response = await authAPI.loginCustomer(
//     this.state.user,
//     this.state.password,
//     this.props.authReducer.notif_id
//   );
//   if (response && response.status === "success" && response.data) {
//     const detailCustomer = await authAPI.getDetailCustomer(
//       response.data.access_token
//     );
//     this.props.dispatch(
//       setAuth({ ...response.data, ...detailCustomer.data })
//     );
//     message.success("Login Berhasil!");
//   } else {
//     if (response?.data?.error === "ERR_WA_UNVERIFIED") {
//       localStorage.setItem(
//         "token_otp_request",
//         response?.data?.data?.token_otp_request
//       );
//       localStorage.setItem("customer_id", response?.data?.data?.customer_id);

//       const otpRes = await authAPI.otpWithRequestToken();

//       if (otpRes.status === "success") {
//         message.success(otpRes.message);
//       } else {
//         message.error("error send otp");
//       }

//       this.props.history.push("/otp");
//     }
//     console.log(response);
//   }
// }

//   async handleLogin() {
//     if (this.state.user !== "" && this.state.password !== "") {
//       await this.loginCustomer();
//     } else {
//       message.error("Email/No. Telpon dan Password tidak boleh kosong");
//     }
//   }

//   render() {
//     return (
//       <>
//         {this.props.authReducer.access_token === null ? (
//           <Card.Body className="pt-0">
//             <strong className="h2 fw-bold">Login</strong>
//             <h3 className="mt-2">
//               Masuk dengan akun eLOG/eDOT untuk menyelesaikan proses pemesanan
//               Anda.
//             </h3>
//             <div className="rc-ice p-2 mb-2 mt-4">
//               <Form>
//                 <FormGroup className="form-group my-1 d-flex">
//                   <img src={iconUser} alt="" className="icon-form-o" />
//                   <FloatingLabel
//                     label="Alamat Email/No. Telpon"
//                     style={{ width: "92.2%", fontSize: "12px" }}
//                   >
//                     <Form.Control
//                       type="text"
//                       placeholder="Alamat Email/No. Telpon"
//                       className="form-white border-0 py-3"
//                       onChange={(e) => {
//                         this.setState({
//                           user: e.target.value,
//                         });
//                       }}
//                     />
//                   </FloatingLabel>
//                 </FormGroup>
//                 <Form.Group className="form-group my-1 d-flex">
//                   <img src={iconKey} alt="" className="icon-form-o" />
//                   <FloatingLabel
//                     label="Kata Sandi"
//                     style={{ width: "92.2%", fontSize: "12px" }}
//                   >
//                     <Form.Control
//                       type={this.state.hidePwd ? "password" : "text"}
//                       placeholder="Kata Sandi"
//                       className="form-white border-0 py-3"
//                       onChange={(e) => {
//                         this.setState({
//                           password: e.target.value,
//                         });
//                       }}
//                     />
//                   </FloatingLabel>
//                   <InputGroup.Text
//                     className="border-0 pointer"
//                     onClick={() => {
//                       this.setState({
//                         hidePwd: !this.state.hidePwd,
//                       });
//                     }}
//                   >
//                     {this.state.hidePwd ? <VscEye /> : <VscEyeClosed />}
//                   </InputGroup.Text>
//                 </Form.Group>
//               </Form>
//             </div>
//             <div className="mt-4" hidden>
//               <p className="mb-0 text-muted">
//                 Lupa Password?{" "}
//                 <a href="/#" className="float-end">
//                   Reset Password
//                 </a>
//               </p>
//             </div>
//             <h5 className="fw-light mb-2 text-center mt-4">
//               Belum memiliki Akun eLOG/eDOT?{" "}
//               <a
//                 href={`${process.env.REACT_APP_WEBSITE_URL}/daftar-pengguna?type=personal`}
//                 className="decor-0"
//                 target="_blank"
//                 rel="noreferrer"
//               >
//                 Daftar Sekarang
//               </a>
//             </h5>
//             <div className="mt-4 d-grid">
//               <Button
//                 size="sm"
//                 className="fw-bold py-2 rounded-pill"
//                 onClick={async () => {
//                   await this.handleLogin();
//                 }}
//               >
//                 Login
//               </Button>
//             </div>
//           </Card.Body>
//         ) : null}
//       </>
//     );
//   }
// }

// const mapStateToProps = (state) => ({
//   authReducer: state.authReducer,
// });
// export default connect(mapStateToProps)(withRouter(TabAuth));
