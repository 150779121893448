import moment from "moment";
import React from "react";
import { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FiPlusCircle, FiTrash2 } from "react-icons/fi";
import { Checkbox, message } from "antd";
import ProcurementApi from "../../../../../api/Management/procurement-api";

const DokumenCard = (props) => {
  const { item, index, updateDocument, addDocument, deleteDocument, total } =
    props;

  const handleUploadFile = async (ev) => {
    let document = ev.target.files[0];
    try {
      const procurementApi = new ProcurementApi();
      const fd = new FormData();
      fd.append("file", document);

      const uploadFiles = await procurementApi.uploadDocument(fd);

      if (uploadFiles) {
        setDocumentValue(uploadFiles.Location, document.name);
      }
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleAddDocument = () => {
    if (item.document_name === "" || item.document_path === "") {
      message.error("Nama dan File Dokumen harus diisi");
    } else {
      addDocument();
    }
  };

  const onChangeCheckbox = (e) => {
    item.is_must_filled_by_partner = e.target.checked;
    if (item.id.length > 10) {
      item.is_update = true;
    }
    handleUpdateValue();
  };

  const onChangeName = (e) => {
    item.document_name = e.target.value;
    if (item.id.length > 10) {
      item.is_update = true;
    }
    handleUpdateValue();
  };

  const setDocumentValue = (value, name) => {
    item.document_path = value;
    item.document_file_name = name;
    if (item.id.length > 10) {
      item.is_update = true;
    }
    handleUpdateValue();
  };

  const handleDeleteDocument = () => {
    deleteDocument(item);
  };

  const handleUpdateValue = () => {
    let value = {
      index: index,
      value: {
        ...item,
        // is_update: item.id.length > 10 ? true : false,
        is_new: item.id.length > 10 ? false : true,
      },
    };
    updateDocument(value);
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  return (
    <div
      className="bg-white w-full pt-3 ps-4"
      style={{
        borderRadius: "10px",
      }}
    >
      <Row>
        <Col xs={7}>
          <div>
            <p className="text-muted label mb-0">Nama Dokumen</p>
            <Form.Control
              className="border-0 ps-0 pt-1"
              placeholder="Tulis nama dokumen"
              onChange={(ev) => {
                onChangeName(ev);
              }}
              value={item.document_name}
            ></Form.Control>
          </div>
        </Col>
        <Col xs={5}>
          <Checkbox
            className="float-end"
            onChange={onChangeCheckbox}
            checked={item.is_must_filled_by_partner}
          >
            <span style={{ fontSize: "12px" }}> Perlu diisi partner </span>
          </Checkbox>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <div
            className="bg-white document-card pointer"
            style={{
              borderTop: "1px solid #E2E5E8",
              height: "100%",
              borderBottomRightRadius: "10px",
            }}
          >
            <div className="mt-2 w-100 mb-3">
              <p className="text-muted mb-1"> File Dokumen</p>
              {item.document_path ? (
                <>
                  <label
                    className="text-ellipsis"
                    style={{
                      color: "#CE1771",
                      fontSize: "13px",
                      cursor: "pointer",
                      maxWidth: "65%",
                    }}
                  >
                    {item.document_file_name}
                  </label>
                  <label
                    htmlFor={"formFile" + item.id}
                    className="underline float-end me-2"
                    style={{
                      color: "#CE1771",
                      fontSize: "13px",
                      cursor: "pointer",
                    }}
                  >
                    Ubah Dokumen
                  </label>
                  <input
                    hidden
                    type="file"
                    className="form-control"
                    id={"formFile" + item.id}
                    accept=".pdf, .xls, .xlsx, .doc, .docx"
                    onChange={handleUploadFile}
                  />
                </>
              ) : (
                <>
                  <label
                    htmlFor={"formFile" + item.id}
                    className="underline"
                    style={{
                      color: "#CE1771",
                      fontSize: "13px",
                      cursor: "pointer",
                    }}
                  >
                    Upload Dokumen
                  </label>
                  <input
                    hidden
                    type="file"
                    className="form-control"
                    id={"formFile" + item.id}
                    accept=".pdf, .xls, .xlsx, .doc, .docx"
                    onChange={handleUploadFile}
                  />
                </>
              )}
            </div>

            {index < total - 1 ? (
              <div
                className="document-card-icon delete"
                style={{ borderBottomRightRadius: "10px" }}
                onClick={() => handleDeleteDocument()}
              >
                <FiTrash2 className="icon-trash" />
              </div>
            ) : (
              <div
                className="document-card-icon add"
                style={{ borderBottomRightRadius: "10px" }}
                onClick={() => handleAddDocument()}
              >
                <FiPlusCircle className="icon-plus" />
              </div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DokumenCard;
