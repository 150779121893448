const initState = {
  access_token: null,
  company_name: null,
  customer_type: null,
  name: "GUEST",
  refresh_token: null,
  bank: null,
  bank_account_number: null,
  id: "",
  is_active: 1,
  is_email_verified: 0,
  is_special_customer: 0,
  mst_customer_address: [],
  profile_picture: null,
  status: "",
  wa_number: "",
  notif_id: null,
  // SSO
  sso_access_token: null
  // END - SSO
};

// bank: null
// bank_account_number: null
// customer_type: "COMPANY"
// id: "9b4fd56a-c6a2-4267-b0f6-da21b7d979c5"
// is_active: 1
// is_email_verified: 0
// is_special_customer: 0
// mst_customer_address: []
// name: "xiangling"
// profile_picture: null
// status: "WAITING_APPROVAL"
// wa_number: "6281213845309"

export const authReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_AUTH":
      return { ...state, ...action.payload };
    // SSO 
    case "SET_SSO":
      return { ...state, sso_access_token: action.payload.sso_access_token, name: action.payload.name };
    // END - SSO
    case "SET_NOTIF_ID":
      return { ...state, notif_id: action.payload };
    case "RESET_AUTH":
      localStorage.clear();
      return { ...initState, ...action.payload };
    default:
      return state;
  }
};
