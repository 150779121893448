import React from "react";

/*======================== Third Party ======================== */
import { Tag } from "antd";
import moment from "moment";

/*======================== Utils ======================== */
import useBreakpoint from "./../../../../components/responsiveUtils/useBreakpoint";


const CardHeader = ({ eachData, getIcon, getTypeAddress, getStatusOrder }) => {
    const { isMobile } = useBreakpoint();

  /*======================== Return ======================== */
    

  return(
    <>
        <div className={`d-flex ${isMobile ? "flex-column" : "flex-row justify-content-between align-items-center"} `}>
          <div className="d-flex flex-row">
            <img src={getIcon()} className="i-sidebar w-25" alt=""  style={{marginRight:"10px"}}/>
            <div className="d-flex flex-column">
              <h3 className="fw-bold me-2">
                {eachData?.type_order === "SEND_GOODS"
                  ? "Kirim Barang"
                  : "Sewa Kendaraan"}
                  
              </h3>
              <div
                className={`d-flex ${
                  isMobile ? "flex-column" : "align-items-center gap-2 "
                }`}
              >
                <h5 className="text-truncate">
                  {eachData?.order_number} - {moment(eachData?.created_at).format("DD MMM YYYY")}
                </h5>
                <h5 style={{color:"#BBBBBB"}}>
                  { eachData?.trx_booking_send?.send_category === "INSTANT" || 
                  eachData?.trx_booking_rent?.rent_category === "INSTANT" ? " (Sekarang)" : " (Terjadwal)" }
                </h5>
              </div>
              <div hidden={!isMobile}>
                { eachData?.trx_booking_send &&
                  <Tag
                    style={getTypeAddress()}
                    className={`px-3 py-1 rounded-pill border-0 ${isMobile && "ms-auto mb-1"}`}
                  >
                    {getTypeAddress('lable')}
                  </Tag>
                }
                <Tag
                  color={getStatusOrder()}
                  className={`px-3 py-1 rounded-pill border-0 ${isMobile && "ms-auto"}`}
                >
                  {getStatusOrder('lable')}
                </Tag>
              </div>
            </div>
          </div>
          <div hidden={isMobile} style={{display:"flex"}}>
              { eachData?.trx_booking_send &&
                <Tag
                  style={getTypeAddress()}
                  className={`px-3 py-1 rounded-pill border-0 ${isMobile && "ms-auto"}`}
                >
                {getTypeAddress('lable')}
                </Tag>
              } 
              {/* getStatusOrder */}
              <div style={{display:"flex", flexDirection:'column'}}>
                { eachData?.trx_booking_rent && eachData?.trx_booking_rent?.external_id ? 
                  <div className="px-3" style={{display:"flex", justifyContent:"end"}}>
                    <h6>No PO: </h6> 
                    <h6 className="ms-1">{eachData?.trx_booking_rent?.external_id}</h6> 
                  </div>
                  : ""
                }
                
                <Tag
                  color={getStatusOrder()}
                  className={`px-3 py-1 text-center rounded-pill border-0 ${isMobile && "ms-auto"}`}
                >
                  {getStatusOrder('lable')}
                </Tag>
              </div>
          </div>
        </div>
        <hr style={{color:"#ADADAD", height:"1px"}}/>
    </>
  );
}

export default CardHeader;