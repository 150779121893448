/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Button, Dropdown, Tab, Tabs, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getProcurementContractListDetail } from "../../../../redux/action/Management/procurement_action";
import noData from "../../../../assets/images/no-data.png";
import CustomTable from "../../../../components/table/customTable";
import { message, Spin } from "antd";
import { formatThousand } from "../../../../helper/formatCurrency";
import {
  getOfferListPerProcurement,
  getVehicleTypeList,
} from "../../../../redux/action/Partner/partner_action";

const PenawaranKontrakListDetail = (props) => {
  /*======================== Props ======================== */

  const { search } = props;

  /*======================== Hooks ======================== */

  const { procurement: idProcurement, id: idPartner, } = useParams();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  /*======================== Redux ======================== */

  const loading = useSelector((st) => st.procurementReducer.loading);
  // const data = useSelector(
  //   (st) => st.procurementReducer.contract_list_detail.data
  // );
  const meta = useSelector(
    (st) => st.procurementReducer.contract_list_detail.meta
  );

  const vehicleType = useSelector(
    (st) => st.partnerManagementReducer.vehicleType
  );

  /*======================== Form ======================== */

  /*======================== UseState ======================== */

  const [params, setParams] = useState({
    limit: 10,
    page: 1,
    search: search || undefined,
    sort_by: "created_at",
    order_by: "DESC",
  });
  const [sort, setSort] = useState("Terbaru");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [vehicle, setVehicle] = useState("");
  const [data, setData] = useState([]);
  const [vehicleOptions, setVehicleOptions] = useState([]);

  /*======================== Handler ======================== */

  const handleStatusLabel = (status) => {
    switch (status) {
      case "WAITING":
        return "Menunggu Konfirmasi";
      case "NEGOTIATION":
        return "Dalam Negosiasi";
      case "APPROVED":
        return "Penawaran Diterima";
      case "REJECTED":
        return "Penawaran Ditolak";
      default:
        return "";
    }
  };

  const handleStatusColor = (status) => {
    switch (status) {
      case "WAITING":
        return "#FFAC14";
      case "NEGOTIATION":
        return "#209AB7";
      case "APPROVED":
        return "#1EAF56";
      case "REJECTED":
        return "red";
      default:
        return "#1EAF56";
    }
  };

  const handleLoad = async () => {
    try {
      if (search) {
        setPage(1);
        const resp = await dispatch(
          getOfferListPerProcurement(params, idProcurement, idPartner)
        );
        setData(resp)
      } else {
        const resp = await dispatch(getOfferListPerProcurement(params, idProcurement, idPartner));
        setData(resp)
      }
    } catch (error) {
      message.error('Gagal mendapatkan data')
    }
  };

  const handleChange = (type, value) => {
    switch (type) {
      case "sort":
        setPage(1);
        setSort(value);
        setParams({
          ...params,
          sort_by: "created_at",
          order_by: value === "Terbaru" ? "DESC" : "ASC",
          page: 1,
        });
        break;
      case "page":
        setPage(value);
        setParams({ ...params, page: value });
        break;
      case "limit":
        setPage(1);
        setLimit(value);
        setParams({ ...params, limit: value, page: 1 });
        break;

      case "vehicle":
        setPage(1);
        setVehicle(value);
        setParams({ ...params, id_vehicle_sub_type: value ? value : undefined, page: 1 });
        break;

      default:
        break;
    }
  };

  const getVehicleType = async () => {
    await dispatch(getVehicleTypeList(idProcurement));
  };

  const setVehicleList = (items) => {
    let data = [];
    data.push({
      title: "Semua",
      value: "",
    });

    items.forEach((item) => {
      data.push({
        title: item.name,
        value: item.id_vehicle_sub_type,
      });
    });

    setVehicleOptions(data);
  };

  /*======================== UseEffect ======================== */

  useEffect(() => {
    handleLoad();
  }, [params, search]);

  useEffect(() => {
    getVehicleType();
  }, []);

  useEffect(() => {
    setVehicleList(vehicleType);
  }, [vehicleType]);

  /*======================== Others ======================== */

  // dummy
  // const data = [
  //   {
  //     id: "2f481d89-4cce-460b-a2cc-3032e6fd3aa6",
  //     delivery_procurement_id: "958d5acc-ea20-471e-bb5c-5c7823e200ab",
  //     route_id: "4774894f-f760-42fe-b028-dd25eaca0ff5",
  //     route_name: "route 1",
  //     route_budget: 12000000,
  //     vehicles: [
  //       {
  //         budget: 12000000,
  //         quantity: 4,
  //         max_tonage: 1000,
  //         min_tonage: 10,
  //         id_vehicle_type: "07969bda-1098-4632-8561-f51cea8b3eef",
  //       },
  //     ],
  //     total_vehicle: 4,
  //     partner_offering_amount: 10000000,
  //     vehicle_quantity: 10000000,
  //     partner_offer_status: "WAITING",
  //     partner_offer_created_at: "2022-09-24T11:57:05.000Z",
  //   },
  // ];

  // const vehicleOptions = [
  //   {
  //     title: "Semua",
  //     value: "",
  //   },
  //   {
  //     title: "Tronton",
  //     value: "Tronton",
  //   },
  //   {
  //     title: "CDD Engkel Bak",
  //     value: "CDD Engkel Bak",
  //   },
  //   {
  //     title: "Small Box",
  //     value: "Small Box",
  //   },
  // ];
  const sortOptions = [
    {
      title: "Terbaru",
    },
    {
      title: "Terlama",
    },
  ];

  const tableProps = {
    bordered: false,
    expandable: false,
    title: undefined,
    showHeader: true,
    rowSelection: false,
  };
  const tableColumns = [
    // {
    //   title: "No",
    //   dataIndex: "no",
    //   sorter: false,
    //   className: "text-center px-3",
    // },
    // {
    //   title: "Kode Rute",
    //   dataIndex: "route_code",
    //   sorter: false,
    //   className: "px-3",
    // },
    {
      title: "Alamat Anda",
      dataIndex: "route_name",
      sorter: false,
      className: "px-3",
    },
    {
      title: "Kebutuhan Kendaraan",
      sorter: false,
      className: "px-3",
      render: (_, { vehicles }) => {
        return (
          <>
            <p className="mb-0">{vehicles[0].name}</p>
            <span>
              {vehicles[0].volume} m3 - {vehicles[0].max_tonage} kg
            </span>
            {vehicles.length > 1 && (
              <p className="label-more mb-0">+{vehicles.length - 1} Lainnya</p>
            )}
          </>
        );
      },
    },
    {
      title: "Budget & Kebutuhan",
      dataIndex: "vehicle_quantity_needed",
      sorter: false,
      className: "px-3",
      render: (_, { total_vehicle, route_budget }) => {
        return (
          <div>
            <p className="mb-0">Rp. {formatThousand(route_budget)}</p>
            <p className="mb-0">{total_vehicle} Kendaraan</p>
          </div> 
        );
      },
    },
    {
      title: "Penawaran",
      dataIndex: "route_budget",
      sorter: false,
      className: "px-3",
      render: (
        _,
        {
          route_budget,
          partner_offering_amount,
          partner_offer_vehicle_quantity,
        }
      ) => {
        return (
          <div>
            <p
              className={`mb-0 ${
                route_budget < partner_offering_amount
                  ? "text-danger"
                  : "text-success"
              }`}
            >
              Rp. {formatThousand(partner_offering_amount)}
            </p>
            <p className="mb-0">{partner_offer_vehicle_quantity} Kendaraan</p>
          </div>
        );
      },
    },
    // {
    //   title: "Partner Terpilih",
    //   dataIndex: "phone_number",
    //   sorter: false,
    //   className: "px-3",
    //   render: (_, { selected_partner }) => {
    //     return (
    //       <>
    //         {selected_partner?.length > 0 ? (
    //           <div className="selected_partner">
    //             <p className="mb-0">{selected_partner[0].name}</p>
    //             <span>
    //               {selected_partner[0].volume} m3 - {selected_partner[0].tonage}{" "}
    //               kg
    //             </span>
    //             {selected_partner.length > 1 && (
    //               <span className="label-more">
    //                 +{selected_partner.length - 1} Lainnya
    //               </span>
    //             )}
    //           </div>
    //         ) : (
    //           <p className="mb-0 label-gray">Belum Terpilih</p>
    //         )}
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Total Penawaran",
    //   dataIndex: "total_offer",
    //   sorter: false,
    //   className: "px-3",
    //   render: (_, { total_offer }) => {
    //     return <p className="mb-0">{total_offer} Tawaran</p>;
    //   },
    // },
    {
      title: "Status",
      dataIndex: "partner_offer_status",
      sorter: false,
      className: "px-3",
      render: (_, { partner_offer_status }) => {
        return (
          <span style={{ color: handleStatusColor(data?.status) }}>
            {handleStatusLabel(partner_offer_status)}
          </span>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      sorter: false,
      className: "px-3",
      render: (_, { partner_offer_id }) => {
        return (
          <Button
            size="sm"
            className="fw-bold px-4 text-capitalize"
            onClick={() =>
              history.push(location.pathname + "/detail/" + partner_offer_id)
            }
          >
            Detail
          </Button>
        );
      },
    },
  ];

  /*======================== Return ======================== */

  return (
    <div className="mt-4">
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <h2 className="fw-bold text-capitalize">Daftar Penawaran</h2>
          <p>List rute dan kendaraan yang anda tawarkan ke partner</p>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between mb-3">
        <div className="d-flex" style={{ gap: "1rem" }}>
          <span className="fw-bold" style={{ width: "auto" }}>
            Tampilkan:
          </span>
          <Tabs
            defaultActiveKey={vehicle}
            className="tabs-rute mb-2 border-0"
            style={{ gap: "10px" }}
            onSelect={(value) => handleChange("vehicle", value)}
          >
            {vehicleOptions.map((item, index) => (
              <Tab key={index} eventKey={item.value} title={item.title} />
            ))}
          </Tabs>
        </div>
        <div className="d-flex">
          <h4 className="mt-2 me-2" style={{ fontWeight: "600" }}>
            Urutkan Berdasarkan :
          </h4>
          <Dropdown className="d-inline mx-2">
            <Dropdown.Toggle
              id="dropdown-autoclose-true"
              size="sm"
              variant="light"
              className="bg-white text-secondary px-3 border shadow-sm rounded"
            >
              <strong>{sort}</strong>
            </Dropdown.Toggle>

            <Dropdown.Menu className="dropdown-menu">
              {sortOptions.map((item, index) => (
                <Dropdown.Item
                  key={index}
                  href="#"
                  onClick={() => {
                    handleChange("sort", item.title);
                  }}
                >
                  {item.title}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      {data.length > 0 && (
        <div className="mt-2">
          <CustomTable
            classProps="table-alamat"
            {...{
              tableProps,
              tableColumns,
              handleChange,
              limit,
              page,
              loading,
              data,
            }}
            total_data={meta.total_data}
          />
        </div>
      )}
      {data.length < 1 &&
        (!loading ? (
          <div
            className="d-flex flex-column align-items-center justify-content-center"
            style={{ marginTop: "5rem" }}
          >
            <img src={noData} alt="no-data" />
            <p className="mt-1">Anda belum memiliki penawaran kontrak</p>
          </div>
        ) : (
          <Card
            className="p-4 rounded-card border-0 d-flex align-items-center justify-content-center mt-4"
            style={{
              height: "350px",
            }}
          >
            <Spin />
          </Card>
        ))}
    </div>
  );
};

export default PenawaranKontrakListDetail;
