import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  FloatingLabel,
  Form,
  InputGroup,
  Modal,
} from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import PropTypes from "prop-types";

const jenisOpts = [
  "Elektronik",
  "Makanan & Minuman",
  "Pakaian & Aksesoris",
  "Kesehatan",
  "Otomotif",
  "Alat Musik",
  "Kecantikan",
  "Konstruksi",
  "Lainnya",
];
const JenisBarang = (props) => {
  /*======================== Props ======================== */

  const { isOpen, handleClose, handleSubmit, initialValues, trigger } = props;

  /*======================== UseState ======================== */

  const [jenis, setJenis] = useState(jenisOpts);
  const [search, setSearch] = useState(undefined);
  const [active, setActive] = useState("");

  /*======================== Handler ======================== */

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleActive = (value) => {
    setActive(value);
  };

  const handleOnClose = () => {
    handleClose();
    setSearch(undefined);
    if (initialValues.type_of_goods !== "Pilih jenis barang") {
      setActive(initialValues.type_of_goods);
    } else {
      setActive("");
    }
  };

  const handleOnSelect = () => {
    setSearch(undefined);
    handleSubmit(active);
  };

  /*======================== UseEffect ======================== */

  useEffect(() => {
    if (search) {
      setJenis(jenisOpts.filter((item) => item.includes(search)));
    } else {
      setJenis(jenisOpts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (initialValues.type_of_goods !== "Pilih jenis barang") {
      setActive(initialValues.type_of_goods);
    } else {
      setActive("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  /*======================== Return ======================== */

  return (
    <Modal
      contentClassName="rounded-card p-2"
      show={isOpen}
      onHide={handleOnClose}
      className="p-3"
      centered
    >
      <div className="d-flex p-2 border-bottom">
        <h2 className="fw-bold">Pilih Jenis Barang</h2>
        <MdClose className="ms-auto me-2 pointer" onClick={handleOnClose} />
      </div>
      <Modal.Body>
        <InputGroup className="border rounded-card">
          <FloatingLabel
            className="rounded-card w-75"
            label={"Cari jenis-barang"}
            style={{ width: "", fontSize: "12px", height: "50px" }}
          >
            <Form.Control
              className="border-0 rounded-card"
              type="text"
              style={{ height: "50px" }}
              placeholder="."
              onChange={(event) => handleSearch(event)}
            />
          </FloatingLabel>
          <InputGroup.Text
            className="border-0 search-icon-trx rounded-card ms-auto"
            style={{ height: "50px" }}
          >
            <FaSearch />
          </InputGroup.Text>
        </InputGroup>

        <div className="jenis-barang-opts custom-scroll mt-4">
          {jenis.map((item, index) => (
            <Card
              key={index}
              className={`rounded pointer${item === active ? " active" : ""}`}
              onClick={() => handleActive(item)}
            >
              <Card.Body>{item}</Card.Body>
            </Card>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size="sm"
          className="w-100 fw-bold py-2 px-4 rounded-pill text-capitalize mt-2"
          onClick={handleOnSelect}
        >
          Pilih jenis barang
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

JenisBarang.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
};

export default JenisBarang;
