import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useEffect, useState } from "react";
import { FloatingLabel, Form, InputGroup } from "react-bootstrap";
import { FaMapMarkerAlt, FaSearch } from "react-icons/fa";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Geocode from "react-geocode";
import { AiOutlineAim } from "react-icons/ai";
import iconMarker from "../../../../../assets/images/icon-mark.svg";
import { GoogleMap, Marker } from "@react-google-maps/api";

const PinPointMap = (props) => {
  /*======================== Props ======================== */

  const { handleChange, showForm, trigger, initialValues } = props;

  /*======================== Hooks ======================== */

  const { isMobile } = useBreakpoint();

  /*======================== UseState ======================== */

  const [address, setaddress] = useState("");
  const [latLng, setLatLng] = useState({
    lat: 0,
    lng: 0,
  });

  /*======================== Handler ======================== */

  const handleAddress = (address) => {
    setaddress(address);
  };

  const handleSelect = async (address, placeId, suggestion) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        setLatLng({ lat, lng });
        handleChange("latLng", { lat, lng });
      });
    handleOriginAddress(address, placeId, suggestion);
  };

  const handleOriginAddress = (address, placeId, suggestion) => {
    setaddress(address);
  };

  const handleGetLocation = () => {
    if (!navigator.geolocation) {
      console.log("Geolocation is not supported by your browser");
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_API_KEY);
          Geocode.setLanguage("id");
          Geocode.fromLatLng(
            position.coords.latitude,
            position.coords.longitude
          ).then(
            async (response) => {
              const address = response.results[0].formatted_address;
              geocodeByAddress(address)
                .then((results) => getLatLng(results[0]))
                .then(({ lat, lng }) => {
                  setLatLng({ lat, lng });
                  handleChange("latLng", { lat, lng });
                });
              handleOriginAddress(address);
            },
            (error) => {
              console.error(error);
            }
          );
        },
        () => {
          console.log("Unable to retrieve your location");
        }
      );
    }
  };

  /*======================== UseEffect ======================== */

  useEffect(() => {
    setLatLng({
      lat: initialValues.latitude,
      lng: initialValues.longitude,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger, initialValues]);

  /*======================== Others ======================== */

  const searchOptions = {
    componentRestrictions: { country: ["idn"] },
  };

  const mapOptions = {
    zoomControl: false,
    scaleControl: false,
    mapTypeControl: false,
    rotateControl: false,
    fullscreenControl: false,
    clickableIcons: false,
    disableDefaultUI: true,
    draggable: true,
    streetViewControl: false,
    // scrollwheel: true,
  };
  const containerStyle = {
    width: "100%",
    height: "350px",
  };

  /*======================== Return ======================== */

  return (
    <div>
      <GoogleMap
        mapContainerStyle={containerStyle}
        defaultOptions={mapOptions}
        options={mapOptions}
        center={{
          lat: latLng.lat,
          lng: latLng.lng,
        }}
        zoom={15}
      >
        <Marker
          icon={iconMarker}
          position={{
            lat: latLng.lat,
            lng: latLng.lng,
          }}
        />
      </GoogleMap>
      {showForm && (
        <>
          <div
            className="pointer fs-13 py-2 ms-2 fw-medium"
            onClick={handleGetLocation}
          >
            <AiOutlineAim /> Gunakan lokasi saat ini
          </div>
          <PlacesAutocomplete
            searchOptions={searchOptions}
            value={address}
            onChange={handleAddress}
            debounce={500}
            onSelect={handleSelect}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div>
                <InputGroup className="cstm-input flex-nowrap">
                  <InputGroup.Text className="border-0">
                    <FaSearch />
                  </InputGroup.Text>
                  <FloatingLabel
                    label="Masukan alamat"
                    style={{
                      width: isMobile ? "87%" : "92%",
                      fontSize: "12px",
                    }}
                  >
                    <Form.Control
                      {...getInputProps({
                        placeholder: "Masukan alamat",
                        className: "location-search-input",
                      })}
                      className="border-0"
                      type="text"
                      placeholder="Masukan alamat"
                    />
                  </FloatingLabel>
                </InputGroup>
                <div className="fs-13">
                  {loading && <div>Loading...</div>}
                  {suggestions.map((suggestion, i) => {
                    const className = suggestion.active ? "p-2 active" : "p-2";
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? { backgroundColor: "#fafafa", cursor: "pointer" }
                      : { backgroundColor: "#ffffff", cursor: "pointer" };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                        key={i}
                      >
                        <div>
                          <FaMapMarkerAlt className="text-muted" />{" "}
                          {suggestion.description}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        </>
      )}
    </div>
  );
};

export default PinPointMap;
