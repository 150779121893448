import React, { useState, useEffect ,useRef } from "react";

/*======================== icons ======================== */
import { BsSearch } from "react-icons/bs";


const Search = (props) => {
  const { placeholder, handleAddPartnerFilter, dataOptions } = props
  
  /*======================== UseState ======================== */
  const [inputText, setInputText] = useState("");
  const [active, setActive] = useState(false)

  /*======================== UseRef ======================== */
  const btnRef = useRef()


  /*======================== Handle ======================== */
  const handleInput = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
  };

  const filteredData = dataOptions?.filter((el) => {
    if(inputText === "") {
      return el
    }else {
       return  el.name.toLowerCase().includes(inputText)
      }
  })
  
  const handleSeletOptions = (val) => {
    handleAddPartnerFilter(val)
    setInputText("")
    setActive(!active) // Tinggal comment bila ingin modal tidak close bila pilih
  }


  /*======================== UseEffect ======================== */
  // when klick outside element, modal hide
  useEffect(()=>{
    const closeDropDown = e => {
      if(btnRef.current && !btnRef.current.contains(e.target)){
        setActive(false)
      }
    }
    document.body.addEventListener("click",closeDropDown);
    return () => document.body.removeEventListener('click',closeDropDown)
  },[])


  /*======================== Return ======================== */


  return(
    <div className="position-relative" ref={btnRef}>
      {/* Select Header */}
      <div
        
        className="py-2 px-3 mb-2 border d-flex justify-content-between align-items-center pointer"
        style={{borderRadius:"8px"}}
        onClick={()=> setActive(!active)}
      >
        <h5 className="m-0"style={{color:"#BFBFBF"}}>{ placeholder }</h5>
        <BsSearch/>
      </div>
      {/* END - Select Header */}

      {/* Option value */}
      {active &&
        (<div className="py-2 px-2 bg-white border bg-white rounded d-flex flex-column position-absolute" style={{width:"100%", zIndex:"2"}}>
          <input
            type="text"
            placeholder="-- Pilih Partner --"
            style={{
              border: "1px solid #E2E5E8",
              borderRadius:"5px",
              textAlign:"center",
              width: "100%"

            }}
            onChange={handleInput}
            className="mx-auto"
          />
          <div 
            className="d-flex flex-column px-2 mt-3 overflow-auto" 
            style={{height:"120px"}}
            onClick={(e)=> e.stopPropagation()}
          >
            {filteredData.map((opt,i)=>(
              <div key={i}>
                <h6 className="pointer mt-1 ps-1" onClick={(e)=>handleSeletOptions(opt)}>{opt.name}</h6>
                <div className="mb-1" style={{borderBottom:"1px solid #DEE2E6"}}></div>
              </div>
            ))}
          </div>
          <h5 
            className="lipstick pointer text-end mt-1 px-2"
            onClick={()=> setActive(!active)}
          >
            Tutup
        </h5>
        </div>)
      }
      {/* END - Option value */}
    </div>
  ) 
}

export default Search;
