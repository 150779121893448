import React, {
  useEffect,
  useRef,
  useState
} from "react";
import {
  Card,
  Row,
  Col,
  Button,
  Form,
  Modal
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import noData from "../../assets/images/no-data.png";
import { createNegotiationLog } from "../../redux/action/Invoice/invoice_action";

const Activity = (props) => {
  // hooks
  const dispatch = useDispatch()


  const { data, setData, idInvoice, handleLoad } = props;
  const [modal, setModal] = useState(false);
  const [dataForm, setDataForm] = useState({
    desc: ''
  })

  const handleSubmit = async () => {
    const payload = {
      delivery_invoice_header_id: idInvoice,
      description: dataForm.desc,
    }
    try {
      await dispatch(createNegotiationLog(payload))
      await handleLoad()
      setModal(false)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Card className="rounded-card bg-white p-4 mb-3 border-0">
      {data?.length > 0 ? (
        data.map((row, i) => {
          const cond = i % 2 === 0;
          return (
            <Card className="col-xs-12 mb-3 rounded-card " key={i}>
              <Card.Header style={{
                borderTopLeftRadius: '20px',
                borderTopRightRadius: '20px',
                backgroundColor: cond ? '#aed6daab' : '#e0a6c86b', padding: '16px'
              }}>
                <div className="d-flex justify-content-between align-items-center">
                  <p className="mb-0">{row?.created_by}</p>
                  <p className="mb-0">{row?.created_at?.split('T')[0]}</p>
                </div>
              </Card.Header>
              <Card.Body>
                <p className="font-weight-normal">{row.description}</p>
              </Card.Body>
            </Card>
          )
        })
      ) : (
        <div className="d-flex 
          flex-column 
          justify-content-center 
          align-items-center"
          style={{ height: '40vh' }}
        >
          <img
            src={noData}
            alt="no-data"
            className="mx-auto d-block"
            style={{
              width: '300px',
              height: 'auto',
              objectFit: 'cover'
            }}
          />
          <p className="text-muted">
            Tidak ada aktivitas Update dokumen
          </p>
        </div>
      )}
      <hr />
      <div className="d-flex flex-row-reverse">
        <button className="btn btn-primary rounded-pill float-end"
          onClick={() => setModal(!modal)}>
          Buat Obrolan
        </button>
      </div>

      {modal && (
        <Modal
          show={modal}
          onHide={() => setModal(!modal)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className='rounded-pill'
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h2 className="mb-1"> Obrolan Anda</h2>
              <p className="mb-0">Silahkan isi deskripsi apa yang diubah oleh partner</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-4" controlId="exampleForm.ControlTextarea1">
              <Form.Label><strong>Deskripsi Obrolan</strong></Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name='desc'
                value={dataForm.desc}
                onChange={(e) => setDataForm({
                  ...dataForm,
                  desc: e.target.value
                })}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button className="rounded-pill" onClick={handleSubmit}>kirim Obrolan</Button>
          </Modal.Footer>
        </Modal>
      )}
    </Card>
  )
}

export default Activity;