import { BaseApiRequest } from "../base-api";
import { message } from "antd";
import axios from "axios";

export default class ProcurementApi extends BaseApiRequest {
  constructor() {
    super(process.env.REACT_APP_API_END_POINT);
  }

  async getProcurementList(token, params) {
    let result;
    try {
      const response = await axios.get(
        `${this.endpoint}/procurement/delivery/list`,
        {
          params,
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      result = response.data;
    } catch (e) {
      // message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async getProcurementDetail(token, id) {
    let result;
    try {
      const response = await axios.get(
        `${this.endpoint}/procurement/delivery/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      result = response.data;
    } catch (e) {
      // message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async getProcurementHeaderDetail(token, id) {
    let result;
    try {
      const response = await axios.get(
        `${this.endpoint}/procurement/delivery/header/detail/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      result = response.data;
    } catch (e) {
      // message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async getProcurementDocumentDetail(token, id) {
    let result;
    try {
      const response = await axios.get(
        `${this.endpoint}/procurement/delivery/documents/detail/${id}`,
        {
          params: {
            page: 1,
            limit: 9999,
            sort_by: "created_at",
            order_by: "DESC",
          },
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      result = response.data;
    } catch (e) {
      // message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async getProcurementContractDetail(token, params) {
    let result;
    const { id, ...others } = params;
    try {
      const response = await axios.get(
        `${this.endpoint}/procurement/delivery/routes/list-by-procurement/${id}`,
        {
          params: others,
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      result = response.data;
    } catch (e) {
      // message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async getProcurementContractRouteDetail(token, id) {
    let result;
    try {
      const response = await axios.get(
        `${this.endpoint}/procurement/delivery/routes/detail/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      result = response.data;
    } catch (e) {
      // message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async getProcurementContractDetailRoutePartner(token, params) {
    let result;
    const { id, ...others } = params;
    try {
      const response = await axios.get(
        `${this.endpoint}/procurement/delivery/routes/list-partner-offer/${id}`,
        {
          params: others,
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      result = response.data;
    } catch (e) {
      // message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async getProcurementContractDetailRoutePartnerWaiting(token, params) {
    let result;
    const { id, ...others } = params;
    try {
      const response = await axios.get(
        `${this.endpoint}/procurement/delivery/routes/list-partner-offer/${id}?status=WAITING&status=NEGOTIATION`,
        {
          params: others,
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      result = response.data;
    } catch (e) {
      // message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async createProcurementHeader(token, payload) {
    let result;
    try {
      const response = await axios.post(
        `${this.endpoint}/procurement/delivery/header`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      // return response;
      result = response.data;
    } catch (e) {
      message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async confirmProcurement(token, id) {
    try {
      const response = await axios.patch(
        `${this.endpoint}/procurement/delivery/header-confirm/${id}`,
        null,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data;
    } catch (e) {
      throw e.response?.data?.message;
    }
  }

  async updatePartnerOffer(token, id, payload) {
    try {
      const response = await axios.patch(
        `${this.endpoint}/procurement/delivery/routes/update-offer-status/${id}`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data;
    } catch (e) {
      throw e.response?.data?.message;
    }
  }

  async updateProcurementHeader(token, payload) {
    let result;
    const { id, ...others } = payload;
    try {
      const response = await axios.patch(
        `${this.endpoint}/procurement/delivery/header/${id}`,
        others,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      result = response.data;
    } catch (e) {
      message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async createProcurementRoute(token, payload) {
    let result;
    try {
      const response = await axios.post(
        `${this.endpoint}/procurement/delivery/routes`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      // return response;
      result = response.data;
    } catch (e) {
      message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async updateProcurementRoute(token, payload) {
    let result;
    const { id, ...others } = payload;
    try {
      const response = await axios.patch(
        `${this.endpoint}/procurement/delivery/routes/${id}`,
        others,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      result = response.data;
    } catch (e) {
      message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async getMasterArea(token, params) {
    let result;
    try {
      const response = await axios.get(
        `${this.endpoint}/master/area/regency?limit=1000`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      result = response.data;
    } catch (e) {
      // message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async uploadDocument(body) {
    let result = null;
    try {
      let resApi = await this.post("/upload/document", body);
      if (resApi.Location) {
        result = resApi;
      } else {
        message.error(resApi.message);
      }
    } catch (error) {
      console.log(error);
    }
    return result;
  }

  async createProcurementDocument(token, payload) {
    let result;
    try {
      const response = await axios.post(
        `${this.endpoint}/procurement/delivery/documents`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      // return response;
      result = response.data;
    } catch (e) {
      message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async updateProcurementDocument(token, payload) {
    let result;
    const { id, ...others } = payload;
    try {
      const response = await axios.patch(
        `${this.endpoint}/procurement/delivery/documents/${id}`,
        others,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      result = response.data;
    } catch (e) {
      message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async updateProcurementStatus(token, payload) {
    let result;
    const { id, ...others } = payload;
    try {
      const response = await axios.patch(
        `${this.endpoint}/procurement/delivery/procurement-status/${id}`,
        others,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      result = response.data;
    } catch (e) {
      message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }
}
