import React, { Component, useEffect } from "react";
import Router from "./router/Router";
import OneSignal from "react-onesignal";
import { useDispatch, useSelector } from "react-redux";
import { setAuth, setNotifId } from "../src/redux/action/auth_action";
import { socket } from "./services/socket";
import {
  getNotification,
  getCountUnreadNotification,
} from "./redux/action/notif_action";

function App() {
  const dispatch = useDispatch();
  const auth = useSelector((st) => st.authReducer);

  useEffect(() => {
    OneSignal.init({
      appId: process.env.REACT_APP_ONESIGNAL_APP_ID,
    });
  }, []);

  useEffect(() => {
    OneSignal.isPushNotificationsEnabled((isEnabled) => {
      if (isEnabled) {
        OneSignal.getUserId((userId) => {
          console.log("player_id of the subscribed user is : " + userId);

          dispatch(setNotifId(userId));
        });
      }
    });
  }, []);

  useEffect(() => {
    if (auth.access_token) {
      dispatch(getCountUnreadNotification(auth.access_token));
      dispatch(getNotification("limit=999", auth.access_token));
      socket.on("notification", (payload) => {
        if (payload.type === "UPDATE_NOTIF_CUSTOMER") {
          dispatch(getCountUnreadNotification(auth.access_token));
          dispatch(getNotification("limit=999", auth.access_token));
        }
      });

      socket.on("auth", (payload) => {
        socket.emit("auth", {
          token: auth.access_token,
        });
      });
    }
  }, [auth.access_token]);

  return (
    <div className="App">
      <Router />
    </div>
  );
}

export default App;
