import { Modal, Row, Col } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import DokumenKontrakCard from "../component/DokumenKontrakCard";

const ProcurementDokumen = (props) => {
  /*======================== Hooks ======================== */

  /*======================== Props ======================== */

  const { isOpen, data, handleClose } = props;

  /*======================== UseState ======================== */

  /*======================== Handler ======================== */
  const handleOnClose = () => {
    handleClose();
  };

  const renderData = () => {
    let component = [];
    data.forEach((item, index) => {
      component.push(
        <Col xs={12} key={index}>
           <DokumenKontrakCard data={item} />
        </Col>
      );
    });
    return component;
  };

  /*======================== UseEffect ======================== */

  /*======================== Return ======================== */

  return (
    <Modal
      contentClassName="rounded-card p-2"
      show={isOpen}
      onHide={handleOnClose}
      className="p-3"
      size="md"
      centered
    >
      <div className="d-flex p-2 border-bottom">
        <h2 className="fw-bold">Daftar Dokumen Kontrak</h2>
        <MdClose className="ms-auto me-2 pointer" onClick={handleOnClose} />
      </div>
      <Modal.Body>
        <div>
          <Row className="pe-3 custom-scroll">
            {renderData()}
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ProcurementDokumen;
