import React, { useState } from "react";
import {
  Modal,
  Form,
  InputGroup,
  FloatingLabel,
  Button,
} from "react-bootstrap";
import { Checkbox } from "antd";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { resetAuth, setAuth } from "../../redux/action/auth_action";
import ProfileApi from "../../api/profile-api";
import AccountApi from "../../api/auth-api";
import { message } from "antd";
import useBreakpoint from "../responsiveUtils/useBreakpoint";

import SectionSuccess from "./SectionSuccess";

// icon
import icon_key from "../../assets/images/icon-key.svg";
import { MdChevronRight, MdClose } from "react-icons/md";
import icon_lock_circle_blue from "../../assets/images/icon_lock_circle_blue.svg";
import { isWeakPassword } from "../../helper/password_strength_helper";

const Password = () => {
  const dispatch = useDispatch;
  const history = useHistory();
  const auth = useSelector((st) => st.authReducer);
  const { isMobile, isTablet, isDesktop } = useBreakpoint();
  const [show, setShow] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isPasswordWeak, setIsPasswordWeak] = useState(false);
  const [body, setBody] = useState({
    old_password: "",
    new_password: "",
  });

  const openModal = () => {
    setShow(true);
  };

  const closeModal = () => {
    setIsSubmit(false);
    setShow(false);
  };

  const onChangePassword = (ev) => {
    if (activeStep.id === 1) {
      setBody({ ...body, old_password: ev.target.value });
    } else {
      setBody({ ...body, new_password: ev.target.value });
    }
  };

  const handleSubmit = async () => {
    try {
      const accountApi = new AccountApi();
      const profileApi = new ProfileApi();

      const result = await accountApi.checkToken(auth?.refresh_token);
      if (result.status === "success") {
        const profileRes = await profileApi.updatePassword(
          { ...body },
          auth?.access_token
        );

        if (profileRes.status === "success") {
          message.success(profileRes.message);
          const { data } = await accountApi.getDetailCustomer(
            auth?.access_token
          );

          dispatch(setAuth({ ...auth, ...data }));

          setIsSubmit(true);
        } else {
          message.error(profileRes.message);
        }
        closeModal();
      } else {
        history.push("/login");
        dispatch(resetAuth());
      }
    } catch (error) {
      console.log(error.response);
    }
  };

  const steps = [
    {
      id: 1,
      name: "Ubah Kata Sandi",
      desc: "untuk Keamanan kamu, Masukan Kata sandi lama kamu.",
      placeholder: "Kata Sandi Lama",
    },
    {
      id: 2,
      name: "Masukan Kata Sandi Baru",
      desc: "Buat kata sandi baru yang kuat untuk Keamanan akun eLOG Anda.",
      placeholder: "Kata Sandi Baru",
    },
  ];

  const [activeStep, setActiveStep] = useState({
    id: 1,
    name: "Ubah Kata Sandi",
    desc: "untuk Keamanan kamu, Masukan Kata sandi lama kamu.",
    placeholder: "Kata Sandi lama",
  });

  const handleNext = () => {
    setActiveStep(steps[1]);
  };

  const handleBack = () => {
    setActiveStep(steps[0]);
  };

  const disButton = (id) => {
    if (id === 1) {
      return body.old_password ? false : true;
    }

    if (id === 2) {
      return body.new_password ? false : true;
    }

    return false;
  };

  return (
    <>
      <div
        className="d-flex align-items-center pointer  px-2"
        onClick={openModal}
      >
        <img src={icon_key} width={25} height={25} alt="" />
        <div className="ms-3">
          <h6 className="text-muted ">Kata Sandi </h6>
          <h5>Ubah Kata Sandi</h5>
        </div>
        <div
          className="d-flex ms-auto align-items-center"
          style={{ marginTop: "-10px" }}
        >
          <MdChevronRight className="" />
        </div>
      </div>
      <Modal
        contentClassName="rounded-card p-2"
        show={show}
        onHide={closeModal}
        className="p-3"
        centered
      >
        {" "}
        {isSubmit ? (
          <SectionSuccess
            type="PERSONAL"
            title="Kata Sandi baru kamu Berhasil dibuat"
            desc="Jaga kerasahasiaan kata sandi kamu, dan Jangan memberitahukan kata sandi kamu 
          kepada siapaun. "
          />
        ) : (
          <>
            <div className="d-flex p-2">
              <p className="fw-bold">Ubah Kata Sandi</p>
              <MdClose className="ms-auto me-2 pointer" onClick={closeModal} />
            </div>
            <Modal.Body>
              <div className="">
                <img src={icon_lock_circle_blue} className="mb-2" alt="" />
                <h4 className="fw-bold">{activeStep.name}</h4>
                <h6 className="text-muted">{activeStep.desc}</h6>
                <InputGroup className="border rounded-input">
                  <InputGroup.Text
                    className="border-0 rounded-input"
                    style={{ backgroundColor: "#f8f9fa" }}
                  >
                    <img src={icon_key} alt="" />
                  </InputGroup.Text>
                  <FloatingLabel
                    className="rounded-input"
                    label={activeStep.placeholder}
                    style={{
                      fontSize: "12px",
                      width: isMobile ? "75%" : "85%",
                    }}
                  >
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      className="border-0 rounded-input"
                      placeholder="m"
                      value={
                        activeStep.id === 1
                          ? body.old_password
                          : body.new_password
                      }
                      style={{ height: "50px" }}
                      onChange={(ev) => {
                        if (activeStep.id === 2) {
                          setIsPasswordWeak(isWeakPassword(ev.target.value));
                        }
                        onChangePassword(ev);
                      }}
                    ></Form.Control>
                  </FloatingLabel>
                </InputGroup>
                {activeStep.id === 2 && isPasswordWeak && (
                  <h6 className="text-danger">
                    Password terdiri dari minimal 6 karakter, huruf besar dan
                    angka
                  </h6>
                )}
                <Checkbox
                  onChange={() => setShowPassword((prev) => !prev)}
                  className="ms-2 mt-2"
                >
                  Tampilkan Password
                </Checkbox>
              </div>
            </Modal.Body>
          </>
        )}
        <Modal.Footer>
          <p className="text-primary pointer me-4" onClick={handleBack}>
            Kembali
          </p>
          <Button
            disabled={disButton(activeStep.id)}
            size="sm"
            variant="primary"
            className="float-end rounded-pill px-4 py-2"
            onClick={() => {
              if (activeStep.id === 1) {
                handleNext();
              } else {
                handleSubmit();
              }
            }}
          >
            Berikutnya
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Password;
