import React, { useState, useEffect } from "react";
import { Row, Col, Card, Modal, Form, Button } from "react-bootstrap";
import { Checkbox, Dropdown } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { updateDetail } from "../../redux/action/kirimbarang_action";
import MasterInsuranceApi from "../../api/insurance-api";
import util from "../../helper/util";
import useBreakpoint from "../responsiveUtils/useBreakpoint";
// icons
import { IoChevronDown } from "react-icons/io5";
import { AiFillMinusCircle, AiFillPlusCircle } from "react-icons/ai";
import { BsCheck } from "react-icons/bs";

import createPersistedState from "use-persisted-state";
const useCheckedState = createPersistedState("checked");
const useInsuranceState = createPersistedState("insurance");

const PickInsurance = (props) => {
  const { isMobile } = useBreakpoint();
  const dispatch = useDispatch();
  const selectedVType = useSelector(
    (st) => st.kirimbarangReducer.selectedVType
  );
  const detailOrder = useSelector((st) => st.kirimbarangReducer.detailOrder);
  const insuranceAmount = useSelector(
    (st) => st.kirimbarangReducer.detailOrder.insurance_amount
  );
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const [checked, setCheked] = useCheckedState(false);

  const insuranceHelper = (id, obj) => {
    const opt = [
      {
        id: 1,
        desc: "Nilai Barang < Rp 2 Juta",
        price: "+ Rp 2.000",
        isSelect: false,
      },
      {
        id: 2,
        desc: "Nilai Barang Rp 3 Juta < Rp 10 Juta",
        price: "+ Rp 10.000",
        isSelect: false,
      },
      {
        id: 3,
        desc: "Nilai Barang Rp 10 Juta < Rp 50 Juta",
        price: "+ Rp 30.000",
        isSelect: false,
      },
      {
        id: 4,
        desc: "Nilai Barang Rp 50 Juta < Rp 100 Juta",
        price: "+ Rp 45.000",
        isSelect: false,
      },
      {
        id: 5,
        desc: "Nilai Barang Rp 100 Juta < Rp 300 Juta",
        price: "+ Rp 105.000",
        isSelect: false,
      },
    ];

    const foundOpt = opt.find((el) => el.id === +id);
    obj.desc = foundOpt.desc;
    obj.price = foundOpt.price;
    obj.isSelect = foundOpt.isSelect;

    return obj;
  };

  const [insuranceOptions, setInsuranceOptions] = useInsuranceState([{}]);

  const onSelectInsurance = (data) => {
    let updatedInsurance = insuranceOptions.map((el, i) => {
      if (el.id === data.id) {
        data.isSelect = true;
        return data;
      }
      el.isSelect = false;
      return el;
    });

    dispatch(
      updateDetail({
        detailOrder: {
          ...detailOrder,
          insurance_desc: data.desc,
          insurance_fee_id: data.id,
          insurance_fee: data.fee,
          is_insurance_checked: true,
        },
      })
    );

    setCheked(true);

    setInsuranceOptions(updatedInsurance);
  };

  const getInsurance = async () => {
    const api = new MasterInsuranceApi();
    let query = `vehicle_subtype_id=${selectedVType.id}`;

    const insurances = await api.getMasterInsurance(query);
    const insurancesState = insurances.data.map((el) => {
      return insuranceHelper(el.id, el);
    });

    setInsuranceOptions(insurancesState);
  };

  const handleUncheckInsurance = () => {
    let updatedInsurance = insuranceOptions.map((el, i) => {
      el.isSelect = false;
      return el;
    });

    dispatch(
      updateDetail({
        detailOrder: {
          ...detailOrder,
          insurance_desc: null,
          insurance_fee_id: null,
          insurance_fee: null,
          is_insurance_checked: false,
        },
      })
    );

    setCheked(false);

    setInsuranceOptions(updatedInsurance);
  };

  useEffect(() => {
    if (selectedVType.id) {
      getInsurance();
    }
  }, [selectedVType]);

  useEffect(() => {
    setCheked(false);
  }, []);

  useEffect(() => {
    let selectedInsurance = insuranceOptions.find((el) => el.isSelect);
    let insurance_fee = selectedInsurance?.fee;

    props.hitSummary?.({ insurance_fee });
  }, [insuranceOptions]);

  const dropdownOverlay = () => (
    <Row className="card-body py-2 drpdwn-pick bg-white">
      {insuranceOptions.map((data, index) => (
        <Card
          key={index}
          className={
            data.isSelect ? "border-fleet border-secondary" : "border-fleet"
          }
          onClick={() => onSelectInsurance(data)}
        >
          <Row className="card-body py-0 fw-bold">
            <Col xs={7} className="py-2">
              <p>{data.desc}</p>
            </Col>
            <Col className="py-2">
              <p>{util.thousandSeparatorPure(data.fee)}</p>
            </Col>
            <Col hidden={!data.isSelect} xs={1} className="py-1">
              <BsCheck style={{ color: "#00a3cc" }} />
            </Col>
          </Row>
        </Card>
      ))}
    </Row>
  );

  return (
    <>
      {props.disabled ? (
        <Card className="border-0 mb-1 opacity-50">
          <Row className="card-body py-2">
            <Col xs={1} className="align-self-center text-center">
              <Checkbox />
            </Col>
            <Col xs={10} className="align-self-center">
              <p className="fw-bold mb-0">Asuransi Pengiriman</p>
              <h6 className="mb-0 text-muted">
                Lindungi barang anda dengan asuransi
              </h6>
            </Col>
            <Col xs={1} className="align-self-center text-center">
              <h6
                className="mb-0"
                // hidden={props.helper_availability_rent === 0}
              >
                <IoChevronDown className="pointer" />
              </h6>
            </Col>
          </Row>
        </Card>
      ) : (
        <>
          {isMobile ? (
            <>
              <Card className="border-0 mb-1 pointer" onClick={handleShow}>
                <Row className="card-body py-2">
                  <Col xs={1} className="align-self-center text-center">
                    <Checkbox
                      checked={checked}
                      onChange={() => {
                        if (checked) handleUncheckInsurance();
                      }}
                    />
                  </Col>
                  <Col xs={10} className="align-self-center">
                    <p className="fw-bold mb-0">Asuransi Pengiriman</p>
                    <h6 className="mb-0 text-muted">
                      Lindungi barang anda dengan asuransi
                    </h6>
                  </Col>
                  <Col xs={1} className="align-self-center text-center">
                    <h6
                      className="mb-0"
                      // hidden={props.helper_availability_rent === 0}
                    >
                      <IoChevronDown className="pointer" />
                    </h6>
                  </Col>
                </Row>
              </Card>
              <Modal
                contentClassName=""
                show={show}
                onHide={handleClose}
                className=""
                centered
              >
                <Modal.Header closeButton className="bg-white">
                  <strong>Asuransi Pengiriman</strong>
                </Modal.Header>
                <Modal.Body>
                  <Row className="card-body py-2 bg-white">
                    {insuranceOptions.map((data, index) => (
                      <Card
                        key={index}
                        className={
                          data.isSelect
                            ? "border-fleet border-secondary"
                            : "border-fleet"
                        }
                        onClick={() => onSelectInsurance(data)}
                      >
                        <Row className="card-body py-0 fw-bold">
                          <Col xs={7} className="py-2">
                            <p>{data.desc}</p>
                          </Col>
                          <Col className="py-2">
                            <p className="text-end">
                              {util.thousandSeparatorPure(data.fee)}
                            </p>
                          </Col>
                          <Col hidden={!data.isSelect} xs={1} className="py-1">
                            <BsCheck style={{ color: "#00a3cc" }} />
                          </Col>
                        </Row>
                      </Card>
                    ))}
                  </Row>
                </Modal.Body>
                <Modal.Footer className="border-top-0 bg-ice">
                  <Button
                    size="sm"
                    variant="primary"
                    className="float-end rounded-pill py-1 px-4"
                    onClick={handleClose}
                  >
                    Simpan
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
          ) : (
            <Dropdown overlay={dropdownOverlay} trigger={["click"]} show={show}>
              <Card className="border-0 mb-1">
                <Row className="card-body py-2 pointer" onClick={handleShow}>
                  <Col xs={1} className="align-self-center text-center">
                    <Checkbox
                      checked={checked}
                      onChange={() => {
                        if (checked) handleUncheckInsurance();
                      }}
                    />
                  </Col>
                  <Col xs={10} className="align-self-center ">
                    <p className="fw-bold mb-0">Asuransi Pengiriman</p>
                    <h6 className="mb-0 text-muted">
                      Lindungi barang anda dengan asuransi
                    </h6>
                  </Col>
                  <Col xs={1} className="align-self-center text-center">
                    <h6
                      className="mb-0"
                      hidden={props.helper_availability_rent === 0}
                    >
                      <IoChevronDown onClick={handleShow} className="pointer" />
                    </h6>
                  </Col>
                </Row>
              </Card>
            </Dropdown>
          )}
        </>
      )}
    </>
  );
};

export default PickInsurance;
