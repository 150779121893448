import { Row, Col, Badge, Table } from "react-bootstrap";
import { formatRupiah } from "../../../../helper/formatCurrency";
import { storage } from "../../../../redux/local_storage";

const HargaKendaraan = (props) => {
  const { detail } = props;

  /*======================== Hooks ======================== */

  /*======================== UseState ======================== */

  /*======================== Handler ======================== */

  /*======================== UseEffect ======================== */

  /*======================== Others ======================== */

  const getHelperPrice = () => {
    if (
      detail &&
      detail.mst_partner_service &&
      detail.mst_partner_service.mst_partner_service_pricing &&
      detail.mst_partner_service.mst_partner_service_pricing.length > 0
    ) {
      if (
        detail.mst_partner_service.mst_partner_service_pricing[0]
          .helper_price_send
      ) {
        return (
          formatRupiah(
            detail.mst_partner_service.mst_partner_service_pricing[0]
              .helper_price_send
          )
        );
      } else {
        return "-";
      }
    } else {
      return "-";
    }
  };

  const renderPricePerKM = () => {
    let component = [];
    if (
      detail &&
      detail.mst_partner_service &&
      detail.mst_partner_service.mst_partner_service_pricing &&
      detail.mst_partner_service.mst_partner_service_pricing.length > 0
    ) {
      let items = detail.mst_partner_service.mst_partner_service_pricing[0];
      if (items && items.pricing_send_km && items.pricing_send_km.length > 0) {
        items.pricing_send_km.forEach((item, index) => {
          component.push(
            <tr key={index}>
              <td>
                {" "}
                {item.min} - {item.max} KM
              </td>
              <td> {formatRupiah(item.price)}</td>
            </tr>
          );
        });

        return component;
      } else {
        return (
          <tr>
            <td colspan="2"> Tidak Ada Data</td>
          </tr>
        );
      }
    } else {
      return (
        <tr>
          <td colspan="2"> Tidak Ada Data</td>
        </tr>
      );
    }
  };

  /*======================== Return ======================== */

  return (
    <Row>
      <Col xs={12}>
        <h5 className="fw-normal mt-4 mb-2">Harga Dasar Layanan</h5>
        <div className="bg-white rounded-card w-full py-2 ps-3 mb-4">
          <Table responsive>
            <thead>
              <tr>
                <td className="text-muted">Jenis Layanan</td>
                <td className="text-muted">Harga Dasar</td>
              </tr>
            </thead>
            <tbody>
              {storage.get("selected-partner-service") == "RENT" ? <>
                <tr>
                  <td>Sewa Harian</td>
                  <td>
                    {detail?.mst_partner_pricing_rent?.daily
                      ? formatRupiah(detail?.mst_partner_pricing_rent?.daily)
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td>Sewa Mingguan</td>
                  <td>
                    {detail?.mst_partner_pricing_rent?.weekly
                      ? formatRupiah(detail?.mst_partner_pricing_rent?.weekly)
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td>Sewa Bulanan</td>
                  <td>
                    {detail?.mst_partner_pricing_rent?.monthly
                      ? formatRupiah(detail?.mst_partner_pricing_rent?.monthly)
                      : "-"}
                  </td>
                </tr>
              </> : <tr>
                <td>Kirim Barang</td>
                <td>
                  {detail.base_pricing_send
                    ? formatRupiah(detail.base_pricing_send)
                    : "-"}
                </td>
              </tr>}
            </tbody>
          </Table>
        </div>

        <h5 className="fw-normal mt-4 mb-2">Tarif per Kilometer</h5>
        <div className="bg-white rounded-card w-full py-2 ps-3 mb-4">
          <Table responsive>
            <thead>
              <tr>
                <td className="text-muted">Jarak (Km)</td>
                <td className="text-muted">Harga</td>
              </tr>
            </thead>
            <tbody>{renderPricePerKM()}</tbody>
          </Table>
        </div>

        <h5 className="fw-normal mt-4 mb-2">Layanan Tambahan</h5>
        <div className="bg-white rounded-card w-full py-2 ps-3 mb-4">
          <Table responsive>
            <thead>
              <tr>
                <td className="text-muted">Jenis Layanan</td>
                <td className="text-muted">Harga</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td> Helper</td>
                <td> {getHelperPrice()}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Col>
    </Row>
  );
};

export default HargaKendaraan;
