import React from "react";
import { Row } from "react-bootstrap";
import ProcurementItemCard from "./ProcurementItemCard";
import PropTypes from "prop-types";
import CustomPagination from "../../../components/pagination/customPagination";
import { useState } from "react";
import DeleteProcurement from "../Form/Modal/Delete";

const ProcurementList = (props) => {
  /*======================== Props ======================== */

  const { data, is_procurement, handleLoad } = props;
  const [openDelete, setOpenDelete] = useState(false);
  const [dataDelete, setDataDelete] = useState();

  /*======================== Return ======================== */

  return (
    <>
      <Row className="procurement-list custom-scroll">
        {data.map((item, index) => (
          <ProcurementItemCard
            key={index}
            {...{ item, is_procurement, setDataDelete, setOpenDelete }}
            actions={item.status === 'DRAFT' ? ["detail", "edit", "delete"] : ['detail']}
          />
        ))}
      </Row>
      <CustomPagination {...props} />
      {openDelete && (
        <DeleteProcurement
          handleLoad={handleLoad}
          isOpen={openDelete}
          handleClose={() => setOpenDelete(false)}
          data={dataDelete}
        />
      )}
    </>
  );
};

ProcurementList.propTypes = {
  handleChange: PropTypes.func,
  limit: PropTypes.number,
  page: PropTypes.number,
  loading: PropTypes.bool,
  data: PropTypes.array,
  item: PropTypes.object,
  is_procurement: PropTypes.bool,
};

export default ProcurementList;
