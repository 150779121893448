import { message } from "antd";
import ParterManagementApi from "../../../api/Partner/partner-api";
import { resetAuth } from "../auth_action";

export const getPartnerList = (params) => async (dispatch, state) => {
  try {
    const api = new ParterManagementApi();

    dispatch({
      type: "SET_PARTNER_MGT",
      payload: { loading: true },
    });

    const response = await api.getPartnerList(
      state().authReducer.access_token,
      params
    );
    if (response.status === 401) {
      dispatch(resetAuth());
    }
    if (response.status === "success") {
      const { data, ...meta } = response;
      dispatch({
        type: "SET_PARTNER_MGT",
        payload: { my_partner: { data, meta } },
      });
    }
  } catch (error) {
    console.log(error?.response);
  } finally {
    dispatch({
      type: "SET_PARTNER_MGT",
      payload: { loading: false },
    });
  }
};

export const getAvailablePartnerList = (params) => async (dispatch, state) => {
  try {
    const api = new ParterManagementApi();

    dispatch({
      type: "SET_PARTNER_MGT",
      payload: { loading: true },
    });
    const response = await api.getAvailablePartnerList(
      state().authReducer.access_token,
      params
    );
    if (response.status === 401) {
      dispatch(resetAuth());
    }
    if (response.status === "success") {
      const { data, ...meta } = response;
      dispatch({
        type: "SET_PARTNER_MGT",
        payload: { available_partner: { data, meta } },
      });
    }
  } catch (error) {
    console.log(error?.response);
  } finally {
    dispatch({
      type: "SET_PARTNER_MGT",
      payload: { loading: false },
    });
  }
};

export const getPartnerProfile = (id) => async (dispatch, state) => {
  try {
    const api = new ParterManagementApi();

    dispatch({
      type: "SET_PARTNER_MGT",
      payload: { loading: true },
    });

    dispatch({
      type: "SET_PARTNER_PROFILE",
      payload: {},
    });

    const response = await api.getPartnerProfile(
      state().authReducer.access_token,
      id
    );
    if (response.status === 401) {
      dispatch(resetAuth());
    }
    if (response.status === "success") {
      const { data } = response;
      dispatch({
        type: "SET_PARTNER_PROFILE",
        payload: data,
      });
    }
  } catch (error) {
    console.log(error?.response);
  } finally {
    dispatch({
      type: "SET_PARTNER_MGT",
      payload: { loading: false },
    });
  }
};

export const getPartnerVehicle = (id, area_id) => async (dispatch, state) => {
  try {
    const api = new ParterManagementApi();

    dispatch({
      type: "SET_PARTNER_MGT",
      payload: { loading: true },
    });

    const response = await api.getPartnerVehicle(
      state().authReducer.access_token,
      id,
      area_id
    );
    if (response.status === 401) {
      dispatch(resetAuth());
    }
    if (response.status === "success") {
      const { data } = response;
      dispatch({
        type: "SET_PARTNER_VEHICLE",
        payload: data,
      });
    }
  } catch (error) {
    console.log(error?.response);
  } finally {
    dispatch({
      type: "SET_PARTNER_MGT",
      payload: { loading: false },
    });
  }
};

export const getPartnerVehicleRented = (id, area_id) => async (dispatch, state) => {
  try {
    const api = new ParterManagementApi();

    dispatch({
      type: "SET_PARTNER_MGT",
      payload: { loading: true },
    });

    const response = await api.getPartnerVehicleRented(
      state().authReducer.access_token,
      id,
      area_id
    );
    if (response.status === 401) {
      dispatch(resetAuth());
    }
    if (response.status === "success") {
      const { data } = response;
      dispatch({
        type: "SET_PARTNER_VEHICLE",
        payload: data,
      });
    }
  } catch (error) {
    console.log(error?.response);
  } finally {
    dispatch({
      type: "SET_PARTNER_MGT",
      payload: { loading: false },
    });
  }
};

export const getPartnerVehicleContract =
  (id, customer_id) => async (dispatch, state) => {
    try {
      const api = new ParterManagementApi();

      dispatch({
        type: "SET_PARTNER_MGT",
        payload: { loading: true },
      });

      const response = await api.getPartnerVehicleContract(
        state().authReducer.access_token,
        id,
        customer_id
      );
      if (response.status === 401) {
        dispatch(resetAuth());
      }
      if (response.status === "success") {
        const { data } = response;
        dispatch({
          type: "SET_PARTNER_VEHICLE_CONTRACT",
          payload: data,
        });
      }
    } catch (error) {
      console.log(error?.response);
    } finally {
      dispatch({
        type: "SET_PARTNER_MGT",
        payload: { loading: false },
      });
    }
  };

export const getDetailVehicle = (id, vehicle_id) => async (dispatch, state) => {
  try {
    const api = new ParterManagementApi();

    dispatch({
      type: "SET_PARTNER_MGT",
      payload: { loading: true },
    });

    const response = await api.getDetailVehicle(
      state().authReducer.access_token,
      id,
      vehicle_id
    );
    if (response.status === 401) {
      dispatch(resetAuth());
    }
    if (response.status === "success") {
      const { data } = response;
      dispatch({
        type: "SET_PARTNER_VEHICLE_DETAIL",
        payload: data,
      });
    }
  } catch (error) {
    console.log(error?.response);
  } finally {
    dispatch({
      type: "SET_PARTNER_MGT",
      payload: { loading: false },
    });
  }
};

export const getProcurementPerPartner =
  (params, id) => async (dispatch, state) => {
    try {
      const api = new ParterManagementApi();

      dispatch({
        type: "SET_PARTNER_MGT",
        payload: { loading: true },
      });

      const response = await api.getProcurementPerPartner(
        state().authReducer.access_token,
        params,
        id
      );
      if (response.status === 401) {
        dispatch(resetAuth());
      }
      if (response.status === "success") {
        const { data, ...meta } = response;
        dispatch({
          type: "SET_PARTNER_MGT",
          payload: { procurement_list: { data, meta } },
        });
      }
    } catch (error) {
      console.log(error?.response);
    } finally {
      dispatch({
        type: "SET_PARTNER_MGT",
        payload: { loading: false },
      });
    }
  };

export const getProcurementHeaderDetail = (id) => async (dispatch, state) => {
  try {
    const api = new ParterManagementApi();

    dispatch({
      type: "SET_PARTNER_MGT",
      payload: { loading: true },
    });

    const response = await api.getHeaderInformation(
      state().authReducer.access_token,
      id
    );
    if (response.status === 401) {
      dispatch(resetAuth());
    }
    if (response.status === "success") {
      return response.data;
    }
  } catch (error) {
    console.log(error?.response);
  } finally {
    dispatch({
      type: "SET_PARTNER_MGT",
      payload: { loading: false },
    });
  }
};

export const getProcurementDocumentDetail =
  (idProcurement, idPartner) => async (dispatch, state) => {
    try {
      const api = new ParterManagementApi();

      dispatch({
        type: "SET_PARTNER_MGT",
        payload: { loading: true },
      });

      const response = await api.getListDocument(
        state().authReducer.access_token,
        idProcurement,
        idPartner
      );
      if (response.status === 401) {
        dispatch(resetAuth());
      }
      if (response.status === "success") {
        return response.data;
      }
    } catch (error) {
      console.log(error?.response);
    } finally {
      dispatch({
        type: "SET_PARTNER_MGT",
        payload: { loading: false },
      });
    }
  };

export const getLogNegotiationDocument =
  (query, idPartner, idProcurement) => async (dispatch, state) => {
    try {
      const api = new ParterManagementApi();

      dispatch({
        type: "SET_PARTNER_MGT",
        payload: { loading: true },
      });

      const response = await api.getLogNegotiationDocument(
        state().authReducer.access_token,
        query,
        idProcurement,
        idPartner
      );
      if (response.status === 401) {
        dispatch(resetAuth());
      }
      if (response.status === "success") {
        return response.data;
      }
    } catch (error) {
      throw error;
    } finally {
      dispatch({
        type: "SET_PARTNER_MGT",
        payload: { loading: false },
      });
    }
  };

export const sendMessage = (payload) => async (dispatch, state) => {
  try {
    const api = new ParterManagementApi();

    dispatch({
      type: "SET_PARTNER_MGT",
      payload: { loading: true },
    });

    const response = await api.createLogNegotiationDocument(
      state().authReducer.access_token,
      payload
    );
    if (response.status === 401) {
      dispatch(resetAuth());
    }
    if (response.status === "success") {
      return response.data;
    }
  } catch (error) {
    throw error;
  } finally {
    dispatch({
      type: "SET_PARTNER_MGT",
      payload: { loading: false },
    });
  }
};

export const requestNewDoc =
  (idProcurement, idPartner) => async (dispatch, state) => {
    try {
      const api = new ParterManagementApi();

      dispatch({
        type: "SET_PARTNER_MGT",
        payload: { loading: true },
      });

      const response = await api.requestUpdateDocument(
        state().authReducer.access_token,
        idPartner,
        idProcurement
      );
      if (response.status === 401) {
        dispatch(resetAuth());
      }
      if (response.status === "success") {
        return response.data;
      }
    } catch (error) {
      throw error;
    } finally {
      dispatch({
        type: "SET_PARTNER_MGT",
        payload: { loading: false },
      });
    }
  };

export const getOfferListPerProcurement =
  (params, idProcurement, idPartner) => async (dispatch, state) => {
    try {
      const api = new ParterManagementApi();

      dispatch({
        type: "SET_PARTNER_MGT",
        payload: { loading: true },
      });

      const response = await api.getOfferListPerProcurement(
        state().authReducer.access_token,
        params,
        idPartner,
        idProcurement
      );
      if (response.status === 401) {
        dispatch(resetAuth());
      }
      return response.data;
    } catch (error) {
      throw error;
    } finally {
      dispatch({
        type: "SET_PARTNER_MGT",
        payload: { loading: false },
      });
    }
  };

export const getPartnerOfferHeader =
  (idPartnerOffer) => async (dispatch, state) => {
    try {
      const api = new ParterManagementApi();

      dispatch({
        type: "SET_PARTNER_MGT",
        payload: { loading: true },
      });

      const response = await api.getDetailPartnerOffer(
        state().authReducer.access_token,
        idPartnerOffer
      );
      if (response.status === 401) {
        dispatch(resetAuth());
      }
      return response.data;
    } catch (error) {
      throw error;
    } finally {
      dispatch({
        type: "SET_PARTNER_MGT",
        payload: { loading: false },
      });
    }
  };

export const getPartnerOfferVehicle =
  (idPartnerOffer) => async (dispatch, state) => {
    try {
      const api = new ParterManagementApi();

      dispatch({
        type: "SET_PARTNER_MGT",
        payload: { loading: true },
      });

      const response = await api.getDetailPartnerOfferVehicle(
        state().authReducer.access_token,
        idPartnerOffer
      );
      if (response.status === 401) {
        dispatch(resetAuth());
      }
      return response.data;
    } catch (error) {
      throw error;
    } finally {
      dispatch({
        type: "SET_PARTNER_MGT",
        payload: { loading: false },
      });
    }
  };

export const getLogNegotiationOffer =
  (query, id_partner_offer) => async (dispatch, state) => {
    try {
      const api = new ParterManagementApi();

      dispatch({
        type: "SET_PARTNER_MGT",
        payload: { loading: true },
      });

      const response = await api.getLogNegotiationOffer(
        state().authReducer.access_token,
        query,
        id_partner_offer
      );
      if (response.status === 401) {
        dispatch(resetAuth());
      }
      if (response.status === "success") {
        return response.data;
      }
    } catch (error) {
      throw error;
    } finally {
      dispatch({
        type: "SET_PARTNER_MGT",
        payload: { loading: false },
      });
    }
  };

export const sendNegotiateOffer = (payload) => async (dispatch, state) => {
  try {
    const api = new ParterManagementApi();

    dispatch({
      type: "SET_PARTNER_MGT",
      payload: { loading: true },
    });

    const response = await api.createLogNegotiationOffer(
      state().authReducer.access_token,
      payload
    );
    if (response.status === 401) {
      dispatch(resetAuth());
    }
    if (response.status === "success") {
      return response.data;
    }
  } catch (error) {
    throw error;
  } finally {
    dispatch({
      type: "SET_PARTNER_MGT",
      payload: { loading: false },
    });
  }
};

export const setSelectedProcurementName = (name) => (dispatch) => {
  dispatch({
    type: "SET_SELECTED_PROCUREMENT",
    payload: name,
  });
};


export const getVehicleTypeList= (procurement_id) => async (dispatch, state) => {
  try {
    const api = new ParterManagementApi();

    dispatch({
      type: "SET_PARTNER_MGT",
      payload: { loading: true },
    });

    const response = await api.getVehicleTypeList(
      state().authReducer.access_token,
      procurement_id
    );
    if (response.status === 401) {
      dispatch(resetAuth());
    }
    if (response.status === "success") {
      const { data } = response;
      dispatch({
        type: "SET_VEHICLE_TYPE",
        payload: data,
      });
    }
  } catch (error) {
    console.log(error?.response);
  } finally {
    dispatch({
      type: "SET_PARTNER_MGT",
      payload: { loading: false },
    });
  }
};
