import { Row, Col, Tabs, Tab } from "react-bootstrap";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { FiChevronLeft } from "react-icons/fi";
import SemuaKendaraan from "./SemuaKendaraan";
import KendaraanDisewa from "./KendaraanDisewa";
import TerdaftarKontrak from "./TerdaftarKontrak";
import { storage } from "../../../redux/local_storage";

const ListKendaraan = () => {
  /*======================== Hooks ======================== */

  const history = useHistory();

  /*======================== UseState ======================== */

  const [tab, setTab] = useState("semua_kendaraan");

  /*======================== Handler ======================== */

  const handleChange = (type, value) => {
    switch (type) {
      case "tab":
        setTab(value);
        break;

      default:
        break;
    }
  };

  const handleBack = () => {
    history.goBack();
  };

  /*======================== UseEffect ======================== */

  /*======================== Others ======================== */

  const tabOptions = [
    {
      title: "Semua Kendaraan",
      value: "semua_kendaraan",
    },
    storage.get("selected-partner-service") == "RENT" ? {
      title: "Kendaraan Disewa",
      value: "kendaraan_disewa",
    } :
      {
        title: "Terdaftar Kontrak",
        value: "terdaftar_kontrak",
      },
  ];

  /*======================== Return ======================== */

  return (
    <Row>
      <Col xs={12}>
        <div className="bg-white rounded-card w-full pt-2 ps-3">
          <div className="d-flex flex-row align-items-center">
            <FiChevronLeft
              fontSize={25}
              className="pointer me-3"
              onClick={handleBack}
            />
            <div>
              <h1>Kendaraan Partner</h1>
              <p className="text-muted">
                List kendaraan partner yang terdaftar
              </p>
            </div>
          </div>

          <Tabs
            activeKey={tab}
            className="tabs-trx mb-2 border-bottom-2"
            onSelect={(value) => handleChange("tab", value)}
          >
            {tabOptions.map((item, index) => (
              <Tab key={index} eventKey={item.value} title={item.title} />
            ))}
          </Tabs>
        </div>
      </Col>
      <Col xs={12}>
        {tab === "semua_kendaraan" && <SemuaKendaraan />}
        {tab === "terdaftar_kontrak" && <TerdaftarKontrak />}
        {tab === "kendaraan_disewa" && <KendaraanDisewa />}
      </Col>
    </Row>
  );
};

export default ListKendaraan;
