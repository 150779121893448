import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  Modal,
  InputGroup,
  FormControl,
  Button,
} from "react-bootstrap";

// images
import iconPromo from "../../assets/images/icon-promo.svg";

// icons
import { IoChevronDown, IoClose, IoCheckmark } from "react-icons/io5";

function ModalPromo() {
  const [partner, setpartner] = useState([
    {
      key: "1",
      title: "Rp 150.000",
      isSelect: true,
    },
    {
      key: "2",
      title: "Rp 150.000",
      isSelect: false,
    },
    {
      key: "3",
      title: "Rp 150.000",
      isSelect: false,
    },
    {
      key: "4",
      title: "Rp 150.000",
      isSelect: false,
    },
  ]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Card className="my-1 card-promo">
        <Row className="card-body py-2 px-3">
          <Col xs={2} className="align-self-center text-center">
            <img src={iconPromo} alt="" className="img-fluid" />
          </Col>
          <Col xs={9} className="align-self-center">
            <h6 className="mb-0 text-muted">Makin hemat dengan promo</h6>
            {/* <p className="fw-bold mb-0">Lebih Hemat Rp ...</p> */}
          </Col>
          <Col
            xs={1}
            className="align-self-center pointer"
            onClick={handleShow}
          >
            <IoChevronDown />
          </Col>
        </Row>
      </Card>

      <Modal
        contentClassName="modal-100 bg-ice p-1"
        show={show}
        onHide={handleClose}
        className=""
        centered
      >
        <p className="ms-1 my-2">
          <IoClose onClick={handleClose} className="pointer" /> Pakai Kode Promo
        </p>
        <InputGroup className="mt-2">
          <FormControl
            placeholder="Masukan Kode Promo ..."
            aria-label=""
            aria-describedby="basic-addon2"
          />
          <Button size="sm" className="px-4 fw-bold">
            Gunakan
          </Button>
        </InputGroup>
        <hr className="mt-3" />
        <div className="scrollable-min">
          <h6 className="text-muted">Promo</h6>
          {partner.map((data, i) => (
            <Card
              key={i}
              className={
                data.isSelect ? "border-fleet border-secondary" : "border-fleet"
              }
            >
              <Row className="card-body py-2">
                <Col xs={11} className="align-self-center">
                  <p className="fw-bold mb-0">Potongan hingga {data.title}</p>
                  <h6 className="mb-0 text-muted">
                    Potongan hingga Rp 150.000 dengan SiCepat
                  </h6>
                </Col>
                <Col xs={1} className="align-self-center">
                  {data.isSelect ? (
                    <IoCheckmark className="text-secondary" />
                  ) : null}
                </Col>
              </Row>
            </Card>
          ))}
          <hr className="my-3" />
          <h6 className="text-muted">Promo Partner</h6>
          {partner.map((data, i) => (
            <Card
              key={i}
              className={
                data.isSelect ? "border-fleet border-secondary" : "border-fleet"
              }
            >
              <Row className="card-body py-2">
                <Col xs={11} className="align-self-center">
                  <p className="fw-bold mb-0">Potongan hingga {data.title}</p>
                  <h6 className="mb-0 text-muted">
                    Potongan hingga Rp 150.000 dengan SiCepat
                  </h6>
                </Col>
                <Col xs={1} className="align-self-center">
                  {data.isSelect ? (
                    <IoCheckmark className="text-secondary" />
                  ) : null}
                </Col>
              </Row>
            </Card>
          ))}
        </div>
        <Modal.Footer className="border-top-0 justify-content-between">
          <div>
            <h6 className="mb-0 text-muted">Lebih Hemat</h6>
            <p className="fw-bold mb-0">Rp 150.000</p>
          </div>
          <Button size="sm" variant="primary" className="float-end fw-bold">
            Pakai Promo (1)
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalPromo;
