import React, { useState } from "react";
import { Row, Col, Card, Modal, Tabs, Tab } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { updateDetail } from "../../redux/action/kirimbarang_action";
import util from "../../helper/util";

// images
import siCepat from "../../assets/images/partner-anteraja.svg";
import noDriver from "../../assets/images/no-driver.svg";
import iconService from "../../assets/images/icon-service.svg";

// icons
import { IoChevronDown, IoCheckmark, IoStar } from "react-icons/io5";
import { BsShieldFillCheck } from "react-icons/bs";

function PickPartner(props) {
  const dispatch = useDispatch();
  const partners = useSelector(
    (st) => st.kirimbarangReducer.partnerService.data || []
  );
  const dataLoading = useSelector(
    (st) => st.kirimbarangReducer.partnerService.isLoading
  );
  const selectedPartnerService = useSelector(
    (st) => st.kirimbarangReducer.selectedPartnerService
  );

  const selectedVType = useSelector(
    (st) => st.kirimbarangReducer.selectedVType
  );

  const contract = useSelector((st) => st.kirimbarangReducer.contract);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const showAdditionalInfo = (item) => {
    let component = [];
    if (
      item && 
      item.trx_delivery_partner_offer &&
      item.trx_delivery_partner_offer.vehicle_offer[0].additional_fee && 
      item.trx_delivery_partner_offer.vehicle_offer[0].additional_fee.length > 0) {
        item.trx_delivery_partner_offer.vehicle_offer[0].additional_fee.forEach((val, i) => {
          component.push(
            <li key={i} className="text-capitalize">
              {val.type} 
              <span className="float-end">
                Rp.
                { util.thousandSeparatorPure(val.fee) }
              </span>
            </li>
          )
        })
        return component;
      } else {
        return (
          <li> Tidak Ada Layanan Tambahan</li>
        )
      }
  }

  const onSelectPartner = async (value) => {
    dispatch(updateDetail({ selectedPartnerService: { ...value } }));
    await props.hitSummary?.({ partner_service_id: value.id });
    handleClose();
  };
  return (
    <>
      <Card className="border-0 mb-1 pointer" onClick={handleShow}>
        {dataLoading ? (
          <span className="h6 mt-2 ms-2 text-muted">Sedang memuat data...</span>
        ) : partners?.length === 0 ? (
          <span className="h6 mt-2 ms-2 text-muted">
            { selectedVType && selectedVType.id ? 'Partner tidak ditemukan' : 'Mohon pilih jenis kendaraan terlebih dahulu'} 
          </span>
        ) : !selectedPartnerService.id ? (
          <div className="d-flex p-2">
            <div className="choose-type w-100">
              <img src={iconService} alt="" className="me-2" /> Pilih Partner
            </div>
            <div>
              <IoChevronDown />
            </div>
          </div>
        ) : (
          <Row className="card-body py-2">
            <Col xs={3} className="align-self-center">
              <img
                src={
                  selectedPartnerService.mst_partner.profile_picture ||
                  iconService
                }
                alt=""
                className={`img-fluid ${
                  !selectedPartnerService.mst_partner.profile_picture && "w-50"
                } `}
              />
            </Col>
            <Col xs={8} className="align-self-center">
              <div className="d-flex fs-13">
                <span className="fw-bold">
                  {selectedPartnerService?.mst_partner?.name}
                </span>
                <span className="mx-2 opacity-25">|</span>
                <span className="fw-bold" style={{ marginTop: "-2px" }}>
                  <IoStar style={{ color: "#f69e0a" }} />{" "}
                  {selectedPartnerService?.mst_partner?.rating
                    ? selectedPartnerService?.mst_partner?.rating
                    : 0}
                </span>
              </div>
              <h6 className="mb-0 text-muted">
                {selectedPartnerService?.available_vehicle} Armada Tersedia
              </h6>
              { contract && contract.id === "1" && (
                <h6 className="mb-0 text-muted">
                  Estimasi Harga: Rp{" "}
                  {util.thousandSeparatorPure(
                    Math.ceil(selectedPartnerService?.min_price)
                  )}{" "}
                  - Rp{" "}
                  {util.thousandSeparatorPure(
                    Math.ceil(selectedPartnerService?.max_price)
                  )}
                </h6>
              )}
            </Col>
            <Col xs={1} className="align-self-center">
              <IoChevronDown />
            </Col>
          </Row>
        )}
      </Card>

      <Modal
        contentClassName="modal-100 p-1"
        show={show}
        onHide={handleClose}
        className=""
        centered
      >
        <Modal.Header closeButton className="bg-white">
          <strong>Pilih Armada Pengiriman</strong>
        </Modal.Header>

        {contract && contract.id === "1" ? (
          <Tabs defaultActiveKey="1" className="tabs-partner mb-2">
            <Tab eventKey="1" title="Semua">
              <div className="mdl-fleet-ctn">
                {partners?.map((data, i) => (
                  <Card
                    key={i}
                    className={
                      data.isSelect
                        ? "border-fleet border-secondary"
                        : "border-fleet"
                    }
                    onClick={() => onSelectPartner(data)}
                  >
                    <Row className="card-body py-2">
                      <Col xs={2} className="align-self-center">
                        <img
                          src={data.mst_partner.profile_picture || iconService}
                          alt=""
                          className={`img-fluid ${
                            !data.mst_partner.profile_picture && "w-50"
                          } `}
                        />
                      </Col>
                      <Col xs={9} className="align-self-center">
                        <div className="d-flex fs-13">
                          <span className="fw-bold">
                            {data?.mst_partner?.name}
                          </span>
                          <span className="mx-2 opacity-25">|</span>
                          <span
                            className="fw-bold"
                            style={{ marginTop: "-2px" }}
                          >
                            <IoStar style={{ color: "#f69e0a" }} />{" "}
                            {data?.mst_partner?.rating || 0}
                          </span>
                          <span className="ms-2">
                            ({data?.available_vehicle} Armada Tersedia)
                          </span>
                        </div>
                        <h6 className="text-muted">
                          Estimasi Harga:{" "}
                          <span className="fw-bold text-black">
                            Rp{" "}
                            {util.thousandSeparatorPure(
                              Math.ceil(data?.min_price)
                            )}{" "}
                            - Rp{" "}
                            {util.thousandSeparatorPure(
                              Math.ceil(data?.max_price)
                            )}
                          </span>
                        </h6>
                        <h6 className="text-muted">
                          Layanan{" "}
                          <span className="ms-2 text-success">
                            Terproteksi <BsShieldFillCheck />
                          </span>
                        </h6>
                        <ul className="mb-0 fs-12">
                          <li>
                            Bantuan Supir{" "}
                            <span className="float-end text-success">
                              Gratis
                            </span>
                          </li>
                          <li>
                            Tenaga Bantu{" "}
                            <span className="float-end">
                              {" "}
                              + Rp{" "}
                              {
                                data?.mst_partner_service_pricing
                                  ?.helper_price_send
                              }
                            </span>
                          </li>
                        </ul>
                      </Col>
                      <Col xs={1} className="align-self-center">
                        {data.isSelect ? (
                          <IoCheckmark className="text-secondary" />
                        ) : null}
                      </Col>
                    </Row>
                  </Card>
                ))}
              </div>
            </Tab>
            <Tab eventKey="2" title="Favorit">
              <div className="mdl-fleet-ctn text-center">
                <div className="vertical-center">
                  <img src={noDriver} alt="" />
                  <h4 className="fw-bold mt-3">
                    Anda belum memiliki Driver Favorit
                  </h4>
                  <p>
                    Favoritkan pengemudi dari riwayat pemesanan Anda untuk
                    mengaktifkan fitur ini.”
                  </p>
                </div>
              </div>
            </Tab>
          </Tabs>
        ) : (
          <Tabs defaultActiveKey="1" className="tabs-partner mb-2">
            <Tab eventKey="1" title="Partner Official">
              <div className="mdl-fleet-ctn">
                {partners?.map((data, i) => (
                  <Card
                    key={i}
                    className={
                      data.isSelect
                        ? "border-fleet border-secondary"
                        : "border-fleet"
                    }
                    onClick={() => onSelectPartner(data)}
                  >
                    <Row className="card-body py-2">
                      <Col xs={2} className="align-self-center">
                        <img
                          src={data.mst_partner.profile_picture || iconService}
                          alt=""
                          className={`img-fluid ${
                            !data.mst_partner.profile_picture && "w-50"
                          } `}
                        />
                      </Col>
                      <Col xs={9} className="align-self-center">
                        <div className="d-flex fs-13">
                          <span className="fw-bold">
                            {data?.mst_partner?.name}
                          </span>
                          <span className="mx-2 opacity-25">|</span>
                          <span
                            className="fw-bold"
                            style={{ marginTop: "-2px" }}
                          >
                            <IoStar style={{ color: "#f69e0a" }} />{" "}
                            {data?.mst_partner?.rating || 0}
                          </span>
                          <span className="ms-2">
                            ({data?.available_vehicle} Armada Tersedia)
                          </span>
                        </div>
                        <h6 className="text-muted">
                          Layanan{" "}
                          <span className="ms-2 text-success">
                            Terproteksi <BsShieldFillCheck />
                          </span>
                        </h6>
                        <ul className="mb-0 fs-12">
                          {showAdditionalInfo(data)}
                        </ul>
                      </Col>
                      <Col xs={1} className="align-self-center">
                        {data.isSelect ? (
                          <IoCheckmark className="text-secondary" />
                        ) : null}
                      </Col>
                    </Row>
                  </Card>
                ))}
              </div>
            </Tab>
          </Tabs>
        )}
      </Modal>
    </>
  );
}

export default PickPartner;
