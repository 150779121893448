import React, { useState, useEffect, useRef } from "react";

/*======================== icons ======================== */
import { BsChevronDown, BsChevronUp } from "react-icons/bs"


const Select = (props) => {
  const { placeholder, options, handleChecked } = props

  /*======================== UseState ======================== */
  const [itemChoosed, setItemChoosed] = useState(false)
  const [active, setActive] = useState(false)

  /*======================== UseRef ======================== */
  const btnRef = useRef()

  /*======================== UseEffect ======================== */
  // when klick outside element, modal hide
  useEffect(()=>{
    const closeDropDown = e => {
      // console.log(e.path) console.log(e.path[1].id)
      if(
        btnRef.current && !btnRef.current.contains(e.target)
        // && e.path[1].id !== "select-dropdown"
      ){
        setActive(false)
      }
    }
    document.body.addEventListener("click",closeDropDown);
    return () => document.body.removeEventListener('click',closeDropDown)

  },[])

  // for change name and color when option choosed
  useEffect(()=>{
    var optionLength = options.filter(item => item.isActive === true ).length
    optionLength > 0 ? 
    setItemChoosed(optionLength) :
    setItemChoosed(null)
  },[options])
  
  /*======================== Return ======================== */


  return(
    <div className="position-relative" 
    // onClick={(e)=> e.stopPropagation()}
    >
      {/* Select Header */}
      <div
        ref={btnRef}
        className="py-2 px-3 mb-2 d-flex justify-content-between align-items-center pointer"
        style={{
          borderRadius: "8px",
          backgroundColor: `${itemChoosed > 0 ? "#F3F8F8" : "white"}`,
          border: `${itemChoosed > 0 ? "1px solid #2BBECB" : "1px solid #E2E5E8"}`
        }}
        onClick={()=> setActive(!active)}
        >
        <h5
          className="m-0"
          style={{
            color: `${itemChoosed > 0 ? "#2BBECB" : "#BFBFBF"}`
          }}
        >
          { itemChoosed > 0 ?
            `${itemChoosed} ${placeholder === "Pilih Status Transaksi" ? "Status Transaksi" : placeholder === "Pilih Jenis Pesanan" ? "Jenis Pesanan" : "Tipe Alamat"}` :
            placeholder
          }
        </h5>
        { active ? <BsChevronUp/> : <BsChevronDown/> }
      </div>
      {/*END - Select Header */}

      {/* Option Checkbox */}
      {active &&
        (<div id="select-dropdown"
          className="py-2 px-3 bg-white border bg-white rounded d-flex flex-column position-absolute" style={{width:"100%", zIndex:"2"}} 
          onClick={(e)=> e.stopPropagation()}
        >
          {options.map((opt, i)=>
            (<div className="py-1" key={i}>
              <input type="checkbox" className="pointer" onChange={()=> handleChecked(opt.name, placeholder)} checked={opt.isActive}/>
              {` `}<h5 className="d-inline">{ opt.name }</h5>
            </div>)
          )}
          <h5 
            className="lipstick pointer text-end mt-1 px-2"
            onClick={()=> setActive(!active)}
          >
            Tutup
        </h5>
        </div>)
      }
      {/* END - Option Checkbox */}
    </div>
  ) 
}

export default Select;
