import { Row, Col, Badge } from "react-bootstrap";
import icon_user from "../../../assets/images/icon-user.svg";
import icon_user_checked from "../../../assets/images/icon_checked_UM.svg";
import icon_pin_colored from "../../../assets/images/icon_pin_colored.svg";
import icon_date_colored from "../../../assets/images/icon_date_colored.svg";
import icon_mail from "../../../assets/images/icon_mail.svg";
import icon_phone from "../../../assets/images/icon-phone.svg";
import icon_ktp from "../../../assets/images/icon_ktp.svg";
import icon_user_verified from "../../../assets/images/account_verified.svg";
import { FiChevronLeft } from "react-icons/fi";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";

const ProfileDetail = () => {
  /*======================== Hooks ======================== */

  const profile = useSelector(
    (st) => st.partnerManagementReducer.partnerProfile
  );
  const history = useHistory();

  /*======================== UseState ======================== */

  /*======================== Handler ======================== */

  const handleBack = () => {
    history.goBack();
  };

  /*======================== UseEffect ======================== */

  /*======================== Others ======================== */

  /*======================== Return ======================== */

  return (
    <Row>
      <Col xs={12}>
        <div className="bg-white rounded-card w-full py-2 ps-3 mb-4">
          <div className="d-flex flex-row align-items-center">
            <FiChevronLeft
              fontSize={25}
              className="pointer me-3"
              onClick={handleBack}
            />
            <div>
              <h1>Profil</h1>
              <p className="text-muted">Informasi umum dari partner </p>
            </div>
          </div>
        </div>

        <h5 className="fw-normal mb-2">Data Partner</h5>
        <div className="bg-white rounded-card w-full py-2 ps-3 mb-4">
          <div style={{ display: "flex" }}>
            <img
              className="mt-2"
              src={icon_user}
              width={25}
              height={25}
              alt=""
            />
            <div className="ms-4">
              <p className="text-muted mb-0"> Nama Perusahaan</p>
              <p className="mb-0"> {profile?.name || "-"}</p>
            </div>
          </div>
          <hr className="my-2 text-muted" />
          <div style={{ display: "flex" }}>
            <img
              className="mt-2"
              src={icon_pin_colored}
              width={25}
              height={25}
              alt=""
            />
            <div className="ms-4">
              <p className="text-muted mb-0"> Alamat</p>
              <p className="mb-0"> {profile?.address || "-"}</p>
            </div>
          </div>
          <hr className="my-2 text-muted" />
          <div style={{ display: "flex" }}>
            <img
              className="mt-2"
              src={icon_date_colored}
              width={25}
              height={25}
              alt=""
            />
            <div className="ms-4">
              <p className="text-muted mb-0"> Tanggal bergabung</p>
              <p className="mb-0">
                {profile.created_at
                  ? moment(profile.created_at).format("DD MMM YYYY")
                  : "-"}
              </p>
            </div>
          </div>
          <hr className="my-2 text-muted" />
          <div style={{ display: "flex" }}>
            <img
              className="mt-2"
              src={icon_user_verified}
              width={25}
              height={25}
              alt=""
            />
            <div className="ms-4">
              <p className="text-muted mb-0"> Status</p>
              <p className="mb-0">
                {" "}
                {profile.is_verified ? "Verified" : "Not Verified"}
              </p>
            </div>
          </div>
        </div>

        <h5 className="fw-normal mb-2">Kontak Partner</h5>
        <div className="bg-white rounded-card w-full py-2 ps-3 mb-4">
          <div style={{ display: "flex" }}>
            <img
              className="mt-2"
              src={icon_mail}
              width={25}
              height={25}
              alt=""
            />
            <div
              className="ms-4"
              style={{
                width: "95%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                <p className="text-muted mb-0"> Email</p>
                <p className="mb-0">
                  {" "}
                  {profile.mst_user_partner?.email || "-"}{" "}
                </p>
              </div>
              <div className="me-4">
                {profile.is_verified && profile.mst_user_partner?.email ? (
                  <div
                    className="mt-2 px-2"
                    style={{ background: "#b2e4c5", borderRadius: "20px" }}
                  >
                    <span
                      style={{
                        color: "#1EAF56",
                        fontSize: "12px",
                        fontWeight: "700",
                      }}
                    >
                      Terverifikasi
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <hr className="my-2 text-muted" />
          <div style={{ display: "flex" }}>
            <img
              className="mt-2"
              src={icon_phone}
              width={25}
              height={25}
              alt=""
            />
            <div
              className="ms-4"
              style={{
                width: "95%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                <p className="text-muted mb-0"> Phone</p>
                <p className="mb-0">
                  {" "}
                  {profile.mst_user_partner?.wa_number || profile.wa_number || "-"}{" "}
                </p>
              </div>
              <div className="me-4">
                {profile.is_verified && profile.mst_user_partner?.wa_number ? (
                  <div
                    className="mt-2 px-2"
                    style={{ background: "#b2e4c5", borderRadius: "20px" }}
                  >
                    <span
                      style={{
                        color: "#1EAF56",
                        fontSize: "12px",
                        fontWeight: "700",
                      }}
                    >
                      Terverifikasi
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        <h5 className="fw-normal mb-2">Dokumen Partner</h5>
        <div className="bg-white rounded-card w-full py-2 ps-3 mb-2">
          <div style={{ display: "flex" }}>
            <img
              className="mt-2"
              src={icon_ktp}
              width={25}
              height={25}
              alt=""
            />
            <div
              className="ms-4"
              style={{
                width: "95%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                <p className="text-muted mb-0"> Dokumen NPWP</p>

                {profile.is_verified &&
                profile.npwp_uploaded_file &&
                profile.npwp_uploaded_file !== "null" ? (
                  <a
                    className="underline"
                    style={{
                      color: "#CE1771",
                      fontSize: "13px",
                      cursor: "pointer",
                    }}
                    href={profile.npwp_uploaded_file}
                    target="_blank"
                    rel="noreferrer"
                    // download={data.document_name}
                  >
                    Lihat Dokumen NPWP
                  </a>
                ) : (
                  <p className=" mb-1">-</p>
                )}
              </div>
              <div className="me-4">
                {profile.is_verified &&
                profile.npwp_uploaded_file &&
                profile.npwp_uploaded_file !== "null" ? (
                  <div
                    className="mt-2 px-2"
                    style={{ background: "#b2e4c5", borderRadius: "20px" }}
                  >
                    <span
                      style={{
                        color: "#1EAF56",
                        fontSize: "12px",
                        fontWeight: "700",
                      }}
                    >
                      Terverifikasi
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-card w-full py-2 ps-3 mb-2">
          <div style={{ display: "flex" }}>
            <img
              className="mt-2"
              src={icon_ktp}
              width={25}
              height={25}
              alt=""
            />
            <div
              className="ms-4"
              style={{
                width: "95%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                <p className="text-muted mb-0"> Dokumen TDP</p>
                {profile.is_verified &&
                profile.tdp_uploaded_file &&
                profile.tdp_uploaded_file !== "null" ? (
                  <a
                    className="underline"
                    style={{
                      color: "#CE1771",
                      fontSize: "13px",
                      cursor: "pointer",
                    }}
                    href={profile.tdp_uploaded_file}
                    target="_blank"
                    rel="noreferrer"
                    // download={data.document_name}
                  >
                    Lihat Dokumen TDP
                  </a>
                ) : (
                  <p className=" mb-1">-</p>
                )}
              </div>
              <div className="me-4">
                {profile.is_verified &&
                profile.tdp_uploaded_file &&
                profile.tdp_uploaded_file !== "null" ? (
                  <div
                    className="mt-2 px-2"
                    style={{ background: "#b2e4c5", borderRadius: "20px" }}
                  >
                    <span
                      style={{
                        color: "#1EAF56",
                        fontSize: "12px",
                        fontWeight: "700",
                      }}
                    >
                      Terverifikasi
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-card w-full py-2 ps-3 mb-2">
          <div style={{ display: "flex" }}>
            <img
              className="mt-2"
              src={icon_ktp}
              width={25}
              height={25}
              alt=""
            />
            <div
              className="ms-4"
              style={{
                width: "95%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                <p className="text-muted mb-0"> Dokumen SIUP</p>
                {profile.is_verified &&
                profile.siup_uploaded_file &&
                profile.siup_uploaded_file !== "null" ? (
                  <a
                    className="underline"
                    style={{
                      color: "#CE1771",
                      fontSize: "13px",
                      cursor: "pointer",
                    }}
                    href={profile.siup_uploaded_file}
                    target="_blank"
                    rel="noreferrer"
                    // download={data.document_name}
                  >
                    Lihat Dokumen SIUP
                  </a>
                ) : (
                  <p className=" mb-1">-</p>
                )}
              </div>
              <div className="me-4">
                {profile.is_verified &&
                profile.siup_uploaded_file &&
                profile.siup_uploaded_file !== "null" ? (
                  <div
                    className="mt-2 px-2"
                    style={{ background: "#b2e4c5", borderRadius: "20px" }}
                  >
                    <span
                      style={{
                        color: "#1EAF56",
                        fontSize: "12px",
                        fontWeight: "700",
                      }}
                    >
                      Terverifikasi
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default ProfileDetail;
