import {
  Row,
  Col,
  Tabs,
  Tab,
  InputGroup,
  FloatingLabel,
  Form,
  Button,
} from "react-bootstrap";
import { useState, useEffect } from "react";
import { FaSearch } from "react-icons/fa";
import InformasiUtamaDetail from "./InformasiUtamaDetail";
import KendaraanDetail from "./KendaraanDetail";
import AktifitasPenawaran from "./AktivitasPenawaran";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { message } from "antd";
import { getPartnerOfferHeader, getPartnerOfferVehicle } from "../../../redux/action/Partner/partner_action";
import TerimaPenawaran from "./Modal/TerimaPenawaran";
import { changeOfferStatus } from "../../../redux/action/Management/procurement_action";
import { FiChevronLeft } from "react-icons/fi";
import util from "../../../helper/util";

const DetailPenawaranHeader = () => {
  /*======================== Hooks ======================== */
  const params = useParams()
  const dispatch = useDispatch()
  const history = useHistory()

  /*======================== UseState ======================== */

  const [tab, setTab] = useState("informasi_utama");
  const [data, setData] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [vehicleData, setVehicleData] = useState(null);

  /*======================== Handler ======================== */

  const handleStatusLabel = (status) => {
    switch (status) {
      case "WAITING":
        return "Menunggu Konfirmasi";
      case "NEGOTIATION":
        return "Dalam Negosiasi";
      case "APPROVED":
        return "Penawaran Diterima";
      case "REJECTED":
        return "Penawaran Ditolak";
      default:
        return "";
    }
  };

  const handleStatusColor = (status) => {
    switch (status) {
      case "WAITING":
        return "#FFAC14";
      case "NEGOTIATION":
        return "#209AB7";
      case "APPROVED":
        return "#1EAF56";
      case "REJECTED":
        return "red";
      default:
        return "#1EAF56";
    }
  };

  const handleChange = (type, value) => {
    switch (type) {
      case "tab":
        setTab(value);
        break;

      default:
        break;
    }
  };

  const handleAcceptOffer = async () => {
    try {
      await dispatch(changeOfferStatus({status: 'APPROVED'}, params.id_partner_offer))
      setIsOpen(false)
      getPartnerOfferHeaderDispatch()
      getPartnerOFferVehicleDispatch()
    } catch (error) {
      message.error(error);
    }
  }

  const getPartnerOfferHeaderDispatch = async () => {
    try {
      const resp = await dispatch(getPartnerOfferHeader(params.id_partner_offer))
      setData(resp)
    } catch (error) {
      message.error(error);
    }
  }

  const getPartnerOFferVehicleDispatch = async () => {
    try {
      const resp = await dispatch(getPartnerOfferVehicle(params.id_partner_offer))
      setVehicleData(resp)
    } catch (error) {
      if (message) {
        message.error(error);
      }
    }
  }

  const handleBack = () => {
    history.goBack();
  };

  /*======================== UseEffect ======================== */

  useEffect(() => {
    getPartnerOfferHeaderDispatch()
    getPartnerOFferVehicleDispatch()
  }, []);

  /*======================== Others ======================== */

  const tabOptions = [
    {
      title: "Informasi Utama",
      value: "informasi_utama",
    },
    {
      title: "Tawaran Kendaraan",
      value: "tawaran_kendaraan",
    },
    {
      title: "Aktivitas Penawaran",
      value: "aktivitas_penawaran",
    },
  ];

  /*======================== Return ======================== */

  return (
    <>
      <Row>
        <Col xs={12}>
          <div className="bg-white rounded-card w-full pt-2 ps-3">
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-row align-items-center">
                <FiChevronLeft
                  fontSize={25}
                  className="pointer me-3"
                  onClick={handleBack}
                />
                <div>
                  <div className="d-flex">
                    <h1>Penawaran Partner</h1>
                    <h5 className="mt-3 mx-2" style={{color: handleStatusColor(data?.status)}}>
                      {data ? handleStatusLabel(data?.status) : ''}
                    </h5>
                  </div>
                  <p className="text-muted">
                    {data?.route_detail?.route_name} - Rp. { util.thousandSeparatorPure(data?.route_detail?.route_budget) } - {data?.vehicle_offer?.length} Kendaraan
                  </p>
                </div>
              </div>
              {
                data?.status !== 'APPROVED' ?
                <div className="px-5 mt-4">
                  <Button
                    size="sm"
                    className="rounded-10 text-capitalize"
                    onClick={() => setIsOpen(true)}
                  >
                    Terima Tawaran
                  </Button>
                  {
                    tab !== 'aktivitas_penawaran' ?
                    <p 
                      className="mt-2"
                      style={{
                        color: "#CE1771",
                        cursor: "pointer",
                      }}
                      onClick={() => setTab('aktivitas_penawaran')}
                    >
                      Ajukan Perubahan
                    </p>
                    :
                    ''
                  }
                </div>
                :
                ''
              }
            </div>
            <Tabs
              activeKey={tab}
              className="tabs-trx mb-2 border-bottom-2"
              onSelect={(value) => handleChange("tab", value)}
            >
              {tabOptions.map((item, index) => (
                <Tab key={index} eventKey={item.value} title={item.title} />
              ))}
            </Tabs>
          </div>
        </Col>
        <Col xs={12}>
          {tab === "informasi_utama" && data && (
            <InformasiUtamaDetail data={data}/>
          )}
          {tab === "tawaran_kendaraan" && data && (
            <KendaraanDetail data={vehicleData} />
          )}
          {tab === "aktivitas_penawaran" && (
            <AktifitasPenawaran />
          )}
        </Col>
      </Row>
      {isOpen && (
        <TerimaPenawaran
          isOpen={isOpen}
          handleClose={() => setIsOpen(false)}
          handleAcceptOffer={handleAcceptOffer}
        />
      )}
    </>
  );
};

export default DetailPenawaranHeader;
