import moment from "moment";
import React, { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromRaw,
} from "draft-js";
// import draftToMarkdown from "draftjs-to-markdown";
import { draftToMarkdown, markdownToDraft } from "markdown-draft-js";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useState } from "react";
import DokumenCard from "./component/DokumenCard";
import PreviewDokumenCard from "./component/PreviewDokumenCard";
import { formatRupiah } from "../../../../helper/formatCurrency";
const Preview = (props) => {
  const { header, kontrak, document, editorState } = props;
  // const { section1: header, section3: kontrak } = sectionData;

  return (
    <div className="procurement-content" style={{ overflowX: "hidden" }}>
      <Row className="mt-4">
        <Col xs={12} md={6} className="mt-2">
          <div className="mb-4">
            <h5 className="fw-normal">Informasi Umum</h5>
            <div className="bg-white rounded-card w-full p-3">
              <Row>
                <Col xs={6}>
                  <div className="px-2">
                    <p className="text-muted label mb-0">Nama Penawaran</p>
                    <Form.Control
                      disabled
                      className="border-0 ps-0 pt-1"
                      placeholder="Tulis nama penawaran"
                      value={header.name}
                    ></Form.Control>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="mb-4">
            <h5 className="fw-normal">Tanggal Penawaran & Kontrak</h5>
            <div className="bg-white rounded-card w-full p-3">
              <Row>
                <Col xs={6}>
                  <div className="px-2">
                    <p className="text-muted label mb-0">Rentang Penawaran</p>
                    <Form.Control
                      disabled
                      className="border-0 ps-0 pt-1"
                      placeholder="Tulis nama penawaran"
                      value={`${moment(header.start_offering_date).format(
                        "DD/MMM/YYYY"
                      )} - ${moment(header.end_offering_date).format(
                        "DD/MMM/YYYY"
                      )}`}
                    ></Form.Control>
                  </div>
                </Col>
                <Col xs={6}>
                  <div className="px-2">
                    <p className="text-muted label mb-0">
                      Rentang Tanggal Kontrak
                    </p>
                    <Form.Control
                      disabled
                      className="border-0 ps-0 pt-1"
                      placeholder="Tulis nama penawaran"
                      value={`${moment(header.start_contract_date).format(
                        "DD/MMM/YYYY"
                      )} - ${moment(header.end_contract_date).format(
                        "DD/MMM/YYYY"
                      )}`}
                    ></Form.Control>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="mb-4">
            <h5 className="fw-normal mt-3">Partner Kontrak</h5>
            <div className="bg-white rounded-card w-full p-3">
              <Row>
                <Col xs={6}>
                  <div className="d-flex align-items-center px-2">
                    <div>
                      <p className="text-muted label mb-2">Partner Kontrak</p>
                      <h6>
                        {header.partners.length > 0
                          ? header.partners.length + " Partner Kontrak"
                          : "Pilih Partner Kontrak"}
                      </h6>
                    </div>
                  </div>
                </Col>
                <Col xs={6}>
                  <div className="d-flex align-items-center px-2">
                    <div>
                      <p className="text-muted label mb-2">
                        Area Kebutuhan Kontrak
                      </p>
                      <h6>
                        {header.areas.length > 0
                          ? header.areas.length + " Area Kontrak"
                          : "Pilih Area"}
                      </h6>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="mb-4">
            <h5 className="fw-normal mt-3">Metode Pembayaran</h5>
            <div className="bg-white rounded-card w-full p-3">
              <Row>
                <Col xs={6}>
                  <div className="d-flex align-items-center px-2">
                    <div>
                      <p className="text-muted label mb-2">Jenis Pembayaran</p>
                      <h6>
                        {header.payment_tenure_name
                          ? "Tempo (" + header.payment_tenure_name + " Hari)"
                          : "Pilih Jenis Pembayaran"}
                      </h6>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="mb-4">
            <h5 className="fw-normal mt-3">Deskripsi Kontrak (optional)</h5>
            <div className="bg-white rounded-card w-full p-3">
              <div className="px-2">
                <p className="text-muted label mb-0">Tulis Deskripsi kontrak</p>
                <Form.Control
                  as="textarea"
                  disabled
                  className="border-0 ps-0 pt-1"
                  value={header.contract_description}
                  style={{ height: "120px" }}
                ></Form.Control>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={12} md={6} className="mt-2">
          <div className="mb-4">
            <h5 className="fw-normal mt-0">Syarat dan Ketentuan (optional)</h5>
            <div className="bg-white rounded-card w-full p-3">
              <div className="px-2">
                <Editor toolbarHidden readOnly editorState={editorState} />
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="procurement-content mb-4">
        <Col xs={12} className="mt-2">
          <h5 className="fw-normal mb-0">Dokumen Kontrak</h5>
          <Row>
            {document.length > 0 ? (
              document.map((item, index) => item.document_path && item.document_name && (
                <Col xs={12} md={6} className="mt-2" key={item.id}>
                  <PreviewDokumenCard
                    {...{
                      item,
                      index,
                    }}
                    total={document.length}
                  />
                </Col>
              ))
            ) : (
              <p>-</p>
            )}
          </Row>
        </Col>
      </Row>
      <Row className="penawaran-kontrak">
        <h5 className="fw-normal mt-3">Daftar Penawaran Kontrak</h5>
        {kontrak.length > 0 ? (
          kontrak.map((item, index) => (
            <Col key={index} xs={12} md={6} className="mb-2">
              <div className="bg-white add-card">
                <div className="width-100">
                  <div className="d-flex flex-wrap">
                    <Col xs={6}>
                      <p className="text-muted mb-1 label">Rute Anda</p>
                      <h5>
                        {`${item.route.pick_up_name} - ${item.route.delivery_name}`}
                      </h5>
                    </Col>
                    <Col xs={6}>
                      <p className="text-muted mb-1 label">Total Budget</p>
                      <h5>{formatRupiah(item.total_budget)}</h5>
                    </Col>
                    <hr className="divider" />
                    <Col xs={12} className="pe-3">
                      <p className="text-muted mb-1 label">
                        Kebutuhan Kendaraan
                      </p>
                      <h5>
                        {item.vehicles
                          .map((_) => `${_.vehicle.name} (${_.quantity})`)
                          .join(", ")}
                      </h5>
                    </Col>
                  </div>
                </div>
              </div>
            </Col>
          ))
        ) : (
          <p>-</p>
        )}
      </Row>
    </div>
  );
};

export default Preview;
