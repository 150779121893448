/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Dropdown,
  FloatingLabel,
  Form,
  InputGroup,
  Modal,
} from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import PropTypes from "prop-types";

const RuteAlamatPengiriman = (props) => {
  /*======================== Props ======================== */

  const {
    isOpen,
    handleClose,
    handleSubmit,
    alamatPengambilan,
    ruteAlamat,
    editPengiriman,
    deliveryList,
  } = props;

  /*======================== UseState ======================== */

  const [alamat, setAlamat] = useState([]);
  const [search, setSearch] = useState(undefined);
  const [dataSelected, setDataSelected] = useState([]);
  const [listOpts, setListOpts] = useState([]);

  /*======================== Handler ======================== */

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleDataSelected = (value) => {
    const temp = dataSelected.findIndex(
      (item) => item.alamatPengiriman.id === value.alamatPengiriman.id
    );
    if (temp === -1) {
      setDataSelected((old) => [
        ...old,
        { alamatPengiriman: value.alamatPengiriman },
      ]);
    } else {
      setDataSelected((old) => [
        ...old.filter(
          (item) => item.alamatPengiriman.id !== value.alamatPengiriman.id
        ),
      ]);
    }
  };

  const handleShow = (value) => {
    if (ruteAlamat.length < 1) {
      return true;
    } else {
      if (editPengiriman) {
        const temp = ruteAlamat.findIndex(
          (item) => item.alamatPengiriman.id === value.alamatPengiriman.id
        );
        if (temp !== -1) {
          return true;
        }
      } else {
        const temp = ruteAlamat.findIndex(
          (item) => item.alamatPengiriman.id === value.alamatPengiriman.id
        );
        if (temp === -1) {
          return true;
        }
      }
    }
  };

  const handleClassSelected = (value) => {
    const temp = dataSelected.findIndex(
      (item) => item.alamatPengiriman.id === value.alamatPengiriman.id
    );
    if (temp === -1) {
      return "rounded-10 pointer";
    } else {
      return "rounded-10 pointer active";
    }
  };

  const handleOnClose = () => {
    handleClose();
    setSearch(undefined);
    setDataSelected([]);
  };

  const handleOnSelect = () => {
    handleClose();
    setSearch(undefined);
    if (!editPengiriman && ruteAlamat.length > 0) {
      handleSubmit([...ruteAlamat, ...dataSelected]);
    } else {
      handleSubmit(dataSelected);
    }
    setDataSelected([]);
  };

  const handleDisabledDropdown = (value) => {
    const temp = dataSelected.findIndex(
      (item) => item.alamatPengiriman.id === value.alamatPengiriman.id
    );
    if (temp === -1) {
      return true;
    } else {
      return false;
    }
  };

  const handleAlamatPengambilan = (value) => {
    setDataSelected((old) => {
      const findOld = old.find(
        (item) => item.alamatPengiriman.id === value.alamatPengiriman.id
      );
      return [
        ...old.filter(
          (item) => item.alamatPengiriman.id !== value.alamatPengiriman.id
        ),
        {
          route_id: findOld?.route_id || "create-new-route",
          ...value,
          is_updated: findOld?.route_id ? true : false,
        },
      ];
    });
  };

  const handleShowAlamatPengambilan = (value) => {
    const temp = dataSelected.find(
      (item) => item.alamatPengiriman.id === value.alamatPengiriman.id
    );
    if (temp && temp.alamatPengambilan) {
      return `${temp.alamatPengambilan.code} - ${temp.alamatPengambilan.name}`;
    }
    return "Pilih Alamat Pengambilan";
  };

  /*======================== UseEffect ======================== */

  useEffect(() => {
    if (search) {
      setAlamat(
        listOpts.filter(
          (item) =>
            item.alamatPengiriman.name
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            item.alamatPengiriman.code
              .toLowerCase()
              .includes(search.toLowerCase())
        )
      );
    } else {
      setAlamat(listOpts);
    }
  }, [search]);

  useEffect(() => {
    if (ruteAlamat.length > 0 && editPengiriman) {
      setDataSelected(ruteAlamat);
    }
  }, [ruteAlamat, editPengiriman]);

  useEffect(() => {
    const temp = [];
    deliveryList.forEach((item) => {
      temp.push({
        alamatPengiriman: item,
        alamatPengambilan: {},
      });
    });
    setListOpts([...temp]);
    setAlamat([...temp]);
  }, []);

  /*======================== Return ======================== */

  return (
    <Modal
      contentClassName="rounded-10 p-2"
      show={isOpen}
      onHide={handleOnClose}
      className="p-3 rute-pengiriman"
      centered
      size="lg"
    >
      <div className="d-flex align-items-center p-3 border-bottom">
        <h2 className="fw-bold mb-0">Pilih Alamat Pengiriman</h2>
        <MdClose className="ms-auto me-2 pointer" onClick={handleOnClose} />
      </div>
      <Modal.Body>
        <InputGroup className="border rounded-10">
          <FloatingLabel
            className="rounded-10 w-75"
            label={"Cari kode / nama alamat anda"}
            style={{ width: "", fontSize: "12px", height: "50px" }}
          >
            <Form.Control
              className="border-0 rounded-10"
              type="text"
              style={{ height: "50px" }}
              placeholder="."
              onChange={(event) => handleSearch(event)}
            />
          </FloatingLabel>
          <InputGroup.Text
            className="border-0 search-icon-trx rounded-10 ms-auto"
            style={{ height: "50px" }}
          >
            <FaSearch />
          </InputGroup.Text>
        </InputGroup>

        <div>
          <div className="rute-opts custom-scroll mt-4">
            <div className="d-flex justify-content-between mb-1">
              <p className="label-pengiriman width-45">Alamat Pengiriman</p>
              <p className="label-pengiriman width-45">
                Pengiriman dari Alamat
              </p>
            </div>
            {alamat.map((item, index) => {
              if (handleShow(item)) {
                return (
                  <div key={index} className="width-100 d-flex">
                    <div className="width-45 p-0">
                      <Card
                        className={`${handleClassSelected(item)}`}
                        onClick={() => handleDataSelected(item)}
                      >
                        <Card.Body>
                          <span>
                            {item.alamatPengiriman.code} -{" "}
                            {item.alamatPengiriman.name}
                          </span>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="p-0 d-flex align-items-center width-10">
                      <p
                        className="mb-0 px-2"
                        style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                      >
                        ..........................................
                      </p>
                    </div>
                    <div className="width-45 p-0">
                      <Card className="rounded-10">
                        <Card.Body className="p-0">
                          <Dropdown className="width-100">
                            <Dropdown.Toggle
                              disabled={handleDisabledDropdown(item)}
                              id="dropdown-autoclose-true"
                              size="lg"
                            >
                              {handleShowAlamatPengambilan(item)}
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu rounded-10 custom-scroll">
                              {alamatPengambilan.map((pickingAdrs, idx2) => (
                                <div key={pickingAdrs.id}>
                                  <Dropdown.Item
                                    href="#"
                                    onClick={() => {
                                      handleAlamatPengambilan({
                                        alamatPengiriman: item.alamatPengiriman,
                                        alamatPengambilan: pickingAdrs,
                                      });
                                    }}
                                  >
                                    {pickingAdrs.code} - {pickingAdrs.name}
                                  </Dropdown.Item>
                                  {idx2 !== alamatPengambilan.length - 1 && (
                                    <hr className="mx-3 my-2" />
                                  )}
                                </div>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size="sm"
          className="w-100 fw-bold py-2 px-4 rounded-pill text-capitalize mt-2"
          onClick={handleOnSelect}
        >
          Pilih Alamat Pengambilan ({dataSelected.length})
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

RuteAlamatPengiriman.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  alamatPengambilan: PropTypes.array,
  ruteAlamat: PropTypes.array,
  editPengiriman: PropTypes.bool,
  deliveryList: PropTypes.array,
};

export default RuteAlamatPengiriman;
