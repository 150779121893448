import React from "react";

const  LableUploadPhoto = () =>  {
    
    return(
        <div className="px-2 mt-1">
            <div className="p-2 rounded mt-1" style={{background:"#EAF4F6"}}>
                <h6 className="text-muted m-0" style={{fontSize:"10px"}}>Pastikan dokumentasi berupa <strong>file jpg/png</strong> dan ukuran file <strong>maksimal 5mb</strong></h6>                 
            </div>
        </div>
    );
}

export default LableUploadPhoto;
