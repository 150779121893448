import React, { useState } from "react";
import {
  Modal,
  Button
} from "react-bootstrap";

const ConfirmFinishChecking = ({onSubmit}) => {
  const [show, setShow] = useState(false)
  const [disable, setDisable] = useState(true)
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  const handleSubmit = async() => {
    onSubmit()
    handleClose()
  }

  return(
    <>
      <Button
        size="sm"
        className="fw-bold py-1 h-25 px-4 rounded-pill text-capitalize"
        onClick={handleShow}
      >
        Selesai Pengecekan
      </Button>
      
      <Modal
        contentClassName="rounded-card p-2"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton className="bg-white">
          <strong>Konfirmasi Selesai Pengecekan</strong>
        </Modal.Header>
        <div className="p-4">
            <h6 className="m-2">Anda <strong>belum</strong> melakukan konfirmasi ke semua dokumen yang dilampirkan partner.</h6>
            <h6 className="m-2">Jika anda tetap ingin melanjutkan, maka anda harus menyetujui bahwa <strong>dokumen akan dianggap sesuai</strong> semua dan hal-hal yang ada dalam penagihan.</h6>
            <div className="d-flex ms-2 pointer" onClick={()=> setDisable(!disable)}>
              <input type="checkbox" checked={disable ? false : true}/>
              <h6 className="m-2">Saya menyetujui, dan akan melanjutkan</h6>
            </div>
        </div>
        <Modal.Footer>
          <Button
            disabled={disable}
            size="sm"
            className="fw-bold py-1 h-25 px-4 rounded-pill text-capitalize"
            onClick={handleSubmit}
            style={{backgroundColor:`${disable ? "#BBBBBB" : "#CE1671"}`, border:"none"}}
          >
            Konfirmasi Lanjut
          </Button>
        </Modal.Footer>
      </Modal>
    </>
    )
  }

export default ConfirmFinishChecking;
