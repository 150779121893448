import React, { useState, useRef } from "react";
import {
  Modal,
  Card,
  Button,
} from "react-bootstrap";
import {
  getRoleList,
} from "../../../redux/action/usermanagement_action";
import { useDispatch, useSelector } from "react-redux";
import { resetAuth } from "../../../redux/action/auth_action";
import UserMgtApi from "../../../api/user-management.api";
import RoleInputForm from "./RoleInputForm";
import { message } from "antd";

const RoleUserEdit = ({ detail }) => {
  const dispatch = useDispatch();
  const childRef = useRef(null);
  const auth = useSelector((st) => st.authReducer);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [dataForm, setDataForm] = useState({
    id: '',
    name: '',
    type: '',
    role_code: '',
    description: '',
    access: [],
  });

  const handleClickEdit = () => {
    setDataForm({
      id: detail.id,
      name: detail.name,
      type: detail.type,
      role_code: detail.role_code,
      description: detail.description,
      access: setAccessRoleValue(detail.access),
    });

    setShow(true);
  }

  const setAccessRoleValue = (items) => {
    return items.map(item => item.id);
  };

  const handleInput = async () => {
    childRef.current.handleInput();
  }

  const submit = (data) => {
    updateRole(data);
  };

  const updateRole = async (data) => {
    try {
      const api = new UserMgtApi();
      const response = await api.updateRole(auth.access_token, data);

      if (response.status === 401) {
        dispatch(resetAuth());
      } else if (response.status === "success") {
        message.success("Role Berhasil Diupdate");
        handleClose();
        dispatch(getRoleList(auth.access_token));
      }

    } catch (error) {
      console.log(error?.response);
    }
  };

  return (
    <>
      <h6 className="text-primary underline mb-2" onClick={() => handleClickEdit()} style={{ cursor: "pointer" }}>Edit Role</h6>

      <Modal
        contentClassName=""
        show={show}
        onHide={handleClose}
        className=""
        centered
      >
        <Modal.Header closeButton className="bg-white">
          <div>
            <h4 className="fw-bold">Edit Role</h4>
            <h6>Ubah detail role yang sudah anda buat.</h6>
          </div>
        </Modal.Header>

        <Card className="rounded-card bg-white p-4 mb-1 border-0">
          <RoleInputForm ref={childRef} submit={submit} dataForm={dataForm} />
        </Card>

        <Modal.Footer className="border-top-0 mx-1 bg-ice">
          <Button
            size="sm"
            variant="primary"
            className="float-end rounded-pill px-3"
            onClick={() => {
              handleInput();
            }}
          >
            Simpan
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default RoleUserEdit;
