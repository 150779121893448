import React from "react";
import { useMediaQuery } from "react-responsive";

const Breakpoint = ({ children }) => {
  return <>{children}</>;
};

const Dekstop = ({ children }) => {
  const isDesktop = useMediaQuery({
    query: "(min-width: 1024px)",
  });

  return isDesktop ? <>{children}</> : null;
};

const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1023px)",
  });

  return isTablet ? <>{children}</> : null;
};

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({
    query: "(min-width: 320px) and (max-width: 767px)",
  });

  return isMobile ? <>{children}</> : null;
};

Breakpoint.Dekstop = Dekstop;
Breakpoint.Tablet = Tablet;
Breakpoint.Mobile = Mobile;

export default Breakpoint;
