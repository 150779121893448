import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  Stack,
  Button,
  Tabs,
  Tab,
  Modal
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { FiChevronLeft } from "react-icons/fi";
import Activity from "./activity";
import Document from "./document";
import Transaction from "./transaction";
import KonfirmasiPembayaran from "./components/modal_pembayaran";
import { message } from "antd";
import { getInvoiceDetail, updateCheckingStatus } from "../../redux/action/Invoice/invoice_action";
import { sortedIndex } from "lodash";
import WaitPayment from "./components/modal_wait_payment";
import SuccessPayment from "./components/modal_success_payment";
import { formatRupiah } from "../../helper/formatCurrency";
import { Result } from "antd";

const DetailInvoice = () => {
  /*======================== Hooks ======================== */

  const location = useLocation();
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();

  /*======================== Redux ======================== */

  const auth = useSelector((st) => st.authReducer);

  /*======================== UseState ======================== */
  const [chat, setChat] = useState([]);
  const [dokumen, setDokumen] = useState([])
  const [invoiceLine, setInvoiceLine] = useState([])
  const [pill, setPill] = useState("aktifitas");
  const [section, setSection] = useState(1);
  const [nextStep, setNextStep] = useState(false);
  const [konfirmasi, setKonfirmasi] = useState(false);
  const [openKonfirmasi, setOpenKonfirmasi] = useState(false);
  const [paymentSummary, setPaymentSummary] = useState(false)
  const [detail, setDetail] = useState({})
  const tabOptions = [
    {
      title: "Aktivitas Anda",
      value: "aktifitas",
    },
    {
      title: "Dokumen Partner",
      value: "dokumen",
    },
    {
      title: "Transaksi Terlampir",
      value: "transaksi",
    }
  ];


  const handleChange = (type, value) => {
    switch (type) {
      case "tab":
        setPill(value)
        break;
      default:
        break;
    }
  };

  const handleNextSection = () => {
    if (section === (detail.checking_by.length) && detail.checking_by[section -1].status === 'APPROVED') {
      return true
    }
    if (detail.checking_by[section - 1].status === 'APPROVED') {
      setSection(old => old += 1)
      return true
    }
    if (detail.checking_by[section -1] !== 'APPROVED') {
      setOpenKonfirmasi(!openKonfirmasi);
      if (openKonfirmasi) {
        setSection((old) => (old += 1));
      }
    }
  };

  const getDetailinvoiceDispatch = async () => {
    try {
      const resp = await dispatch(getInvoiceDetail(params.id))
      setDetail(resp)
      setChat(resp.invoiceNegotiation)
      setDokumen(resp.invoiceDocument)
      setInvoiceLine(resp.invoiceLine)
    } catch (error) {
      message.error('Gagal mendapatkan detail invoice')
    }
  }

  const handleUpdateChecking = async (index) => {
    try {
      const checkingBy = [...detail.checking_by]
      checkingBy[index].status = 'APPROVED'
      const payload = {
        id: detail.id,
        checkers: checkingBy
      }
      await dispatch(updateCheckingStatus(payload))
      setOpenKonfirmasi(false)
      if (section !== detail.checking_by.length) {
        setSection(section + 1)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handlePaymentSuccess = () => {
    setKonfirmasi(false);
    handleLoad();
  }

  const closePaymentSummary = () => {
    setPaymentSummary(false);
    handleLoad();
  }

  const handleLoad = async () => {
    await getDetailinvoiceDispatch()
  }


  const handleStatusLabel = (checkingBy) => {
    if (checkingBy?.length > 0) {
      if (checkingBy[section - 1].status === "WAITING") {
        return "Sedang Pengecekan";
      } else {
        return "Selesai Pengecekan";
      }
    }
    return '-'
  };

  const handleStatusClass = (checkingBy) => {
    if (checkingBy?.length > 0) {
      if (checkingBy[section - 1].status === "WAITING") {
        return "text-orange";
      } else {
        return "text-info";
      }
    }
    return ''
  };
  const handleDisableNext = (checkingBy) => { 
    if (checkingBy?.length > 0) {
      if (checkingBy.every(item => item.status === 'APPROVED') && section === checkingBy.length) {
        return true;
      }
    }
    return false;
   }

  useState(() => {
    handleLoad()
  }, [])

  if (detail && detail.error === "ERR_NOT_FOUND") {
    return (
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        style={{ marginTop: "12vh" }}
      />
    );
  }
  
  return (
    <div
      style={{ minHeight: "100vh", width: "100%", padding: "30px" }}
      className="bg-ice height-100"
    >
      {detail && !detail.error && (
        <>
          <Card
            className="p-4 rounded-card border-0"
            style={{ backgroundColor: "white" }}
          >
            <Stack direction="horizontal" gap={2}>
              <div className="">
                <FiChevronLeft
                  fontSize={25}
                  className="pointer me-3"
                  onClick={() => history.push("/invoices")}
                />
              </div>
              <div className="d-flex flex-col">
                <Col xs={12}>
                  <h3 className="mb-0">Invoice <span className="text-primary">{detail?.invoice_no}</span></h3>
                  <p className="mt-1 mb-2">{detail?.invoice_date?.split('T')[0] || '-'}</p>
                  <div className="d-flex align-items-center form-section">
                    {
                      detail?.checking_by?.map((checker, index) => (
                        <>
                        {
                          index !== detail?.checking_by?.length - 1 ?
                          <>
                            <Button
                              key={index}
                              className={`${(section > index || detail?.all_checking_approved) ? "active" : ""}`}
                              variant="link"
                              style={{ cursor: "default" }}
                            >
                              {checker?.role_name}
                            </Button>
                            <span
                              className={`separator ${(section > index+1 || detail?.all_checking_approved) ? "active" : ""}`}
                            >
                              ------
                            </span>
                          </>
                          :
                          <Button
                            className={`${(section > index || detail?.all_checking_approved) ? "active" : ""}`}
                            variant="link"
                            style={{ cursor: "default" }}
                          >
                            {checker?.role_name}
                          </Button>
                        }
                        </>
                      ))
                    }
                  </div>
                </Col>
              </div>
              {
                detail?.payment_process && detail.payment_method_id_for_payment && detail.payment_process !== 'CANCELLED' ? 
                <Button
                  variant="outline-primary rounded-pill ms-auto"
                  onClick={() => setPaymentSummary(!paymentSummary)}>
                  Detail Pembayaran
                </Button>
                :
                <Button
                  variant="outline-primary rounded-pill ms-auto"
                  onClick={() => setKonfirmasi(!konfirmasi)}>
                  Mulai Pembayaran
                </Button>
              }
              <Button
                variant="primary rounded-pill"
                disabled={handleDisableNext(detail?.checking_by)}
                onClick={handleNextSection}
              >
                Cek Selanjutnya
              </Button>
            </Stack>
          </Card>
          <Col xs={12}>
            <Row className="mt-2">
              <Col xs={12} md={6}>
                <Col xs={12} className='my-4'>
                  <h5 className="fw-bold">Informasi Rute</h5>
                  <div className="bg-white rounded-10 p-3">
                    <Row>
                      <Col xs={6}>
                        <div className="px-2">
                          <p className="text-muted label mb-0">Nomor Invoice</p>
                          <p className="mb-0">{detail?.invoice_no}</p>
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="px-2">
                          <p className="text-muted label mb-0">Tanggal Invoice</p>
                          <p className="mb-0">{detail?.invoice_date?.split('T')[0]}</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col xs={12} className='my-4'>
                  <h5 className="fw-bold">Status Pengecekan</h5>
                  <div className="bg-white rounded-10 p-3">
                    <Row>
                      <Col xs={6}>
                      <div className="px-2">
                          <p className="text-muted label mb-0">
                            Status Pengecekan
                          </p>
                          <p
                            className={`mb-0 ${handleStatusClass(detail?.checking_by)}`}
                          >
                            {handleStatusLabel(detail?.checking_by)}
                          </p>
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="px-2">
                          <p className="text-muted label mb-0">Pengecekan Oleh</p>
                          <p className="mb-0">{detail?.checking_by ? detail?.checking_by[section - 1].role_name : '-'}</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col xs={12} className='my-4'>
                  <h5 className="fw-bold">Tagihan Invoice</h5>
                  <div className="bg-white rounded-10 p-3">
                    <Row>
                      <Col xs={6}>
                        <div className="px-2">
                          <p className="text-muted label mb-0">Status Pembayaran</p>
                          <p className="mb-0 text-info">{detail?.payment_process && detail?.payment_method_id_for_payment ? detail?.payment_process : ''}</p>
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="px-2">
                          <p className="text-muted label mb-0">Total Tagihan</p>
                          <p className="mb-0">{detail ? formatRupiah(detail?.total_amount_after_disc) : ''}</p>
                        </div>
                      </Col>
                      <Col xs={12} className="border border-light my-2" />
                      <Col xs={12}>
                        <div className="px-2">
                          <p className="text-muted label mb-0">Jenis Pembayaran</p>
                          <p className="mb-0">Tempo ({detail?.deliveryProcurementInvoice?.payment_tenure_data?.tenure} Hari)</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col xs={12} className='my-4'>
                  <h5 className="fw-bold">Nama Kontak</h5>
                  <div className="bg-white rounded-10 p-3">
                    <Row>
                      <Col xs={12}>
                        <div className="px-2">
                          <p className="text-muted label mb-0">Nama Kontak</p>
                          <p className="mb-0 text-info">{detail?.deliveryProcurementInvoice?.name}</p>
                        </div>
                      </Col>
                      <Col xs={12} className="border border-light my-2" />
                      <Col xs={12}>
                        <div className="px-2">
                          <p className="text-muted label mb-0">Jenis Layanaan</p>
                          <p className="mb-0">Kirim Barang</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col xs={12} className='my-4'>
                  <h5 className="fw-bold">Partner & Transaksi</h5>
                  <div className="bg-white rounded-10 p-3">
                    <Row>
                      <Col xs={6}>
                        <div className="px-2">
                          <p className="text-muted label mb-0">Nama Partner</p>
                          <p className="mb-0">{detail?.partner?.name}</p>
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="px-2">
                          <p className="text-muted label mb-0">Total Transaksi</p>
                          <p className="mb-0">{detail?.invoiceLine?.length} Transaksi</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Col>
              <Col xs={12} md={6}>
                <Col xs={12} className='mt-4'>
                  <div div className="d-flex flex-column bg-white rounded-10 px-3 pt-3">
                    <h3 className="mb-1">Lampiran Invoice</h3>
                    <p className="mb-3">Cek lampiran invoice sebelum melakukan pembayaran</p>
                    <Tabs
                      defaultActiveKey={pill}
                      className="tabs-trx border-bottom-2"
                      onSelect={(value) => handleChange("tab", value)}
                    >
                      {tabOptions.map(row =>
                        <Tab eventKey={row.value} title={row.title} />
                      )}
                    </Tabs>
                  </div>
                </Col>
                <Col xs={12} className='mt-3'>
                  {pill == 'aktifitas' ? (
                    <Activity
                      data={chat}
                      setData={(val) => setChat(chat => [...chat, val])}
                      idInvoice={params.id}
                      handleLoad={handleLoad}
                    />
                  ) : (pill == 'dokumen') ? (
                    <Document
                      data={dokumen}
                      setData={(val) => setDokumen(dokumen => [...dokumen, val])}
                      idInvoice={params.id}
                      handleLoad={handleLoad}
                    />
                  ) : (
                    <Transaction params={params.id} data={detail} />
                  )}
                </Col>
              </Col>

              <Modal
                show={openKonfirmasi}
                onHide={() => setOpenKonfirmasi(!openKonfirmasi)}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='rounded-pill'
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h2>Cek Selanjutnya</h2>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <small className="text-muted">Pastikan anda sudah melakukan pengecekan secara benar. Jika sudah, tekan tombol dibawah untuk melanjutkan ke pengecek selanjutnya.</small>
                </Modal.Body>
                <Modal.Footer>
                  <Button className="rounded-pill mx-auto" onClick={() => handleUpdateChecking(section - 1)}>Konfirmasi Pengecekan</Button>
                </Modal.Footer>
              </Modal>

              <KonfirmasiPembayaran
                show={konfirmasi}
                onHide={() => setKonfirmasi(!konfirmasi)}
                onSuccess={() => handlePaymentSuccess()}
                idInvoice={detail?.id}
                total={detail?.total_amount_after_disc}
              />
              
              {
                detail && detail.payment_process && detail.payment_process === 'PAID' ? (
                  <SuccessPayment
                    show={paymentSummary}
                    onHide={() => setPaymentSummary(!paymentSummary)}
                  />
                ) : (
                  <WaitPayment
                    show={paymentSummary}
                    onHide={() => closePaymentSummary()}
                    data={detail}
                  />
                )
              }
              
            </Row>
          </Col>
        </>
      )}
    </div>
  )
};

export default DetailInvoice;
