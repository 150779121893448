import React from "react";
import {
    Modal
} from "react-bootstrap";
import { BsXLg } from "react-icons/bs"
import { RiErrorWarningFill } from "react-icons/ri"


function ErrorUploadBulk({
    show,
    handleClose,
    message
}) {

  return<>  
        <Modal
            contentClassName="rounded-card p-2"
            show={show}
            onHide={handleClose}
            centered
        >
            <div className="text-end px-3 mt-1">
                <BsXLg 
                    className="pointer"
                    onClick={()=> handleClose()}
                />
            </div>
            <div className="text-center">
                <RiErrorWarningFill style={{width:"90px", height:"90px", color:'red'}}/>
                <h3 className="py-3">{message}</h3>
            </div> 
        </Modal>
    </>;
}

export default ErrorUploadBulk;
