import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Tabs,
  Tab,
  InputGroup,
  FloatingLabel,
  Form,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { FaSearch } from "react-icons/fa";
import { IoMenuOutline } from "react-icons/io5";
import { useDebouncedCallback } from "use-debounce";
import { useHistory, useLocation } from "react-router-dom";
import DaftarKendaraanTetap from "./DaftarKendaraanTetap";
import DaftarKendaraanSementara from "./DaftarKendaraanSementara";
import { openSidenavMobile } from "../../components/layouts/layout_helper";
import useBreakpoint from "../../components/responsiveUtils/useBreakpoint";

const Procurement = () => {
  /*======================== Hooks ======================== */

  const { isMobile } = useBreakpoint();
  const location = useLocation();
  const history = useHistory();

  /*======================== Redux ======================== */

  const filter = useSelector(
    (st) => st.procurementReducer.in_procurement.filter
  );

  /*======================== UseState ======================== */

  const [tab, setTab] = useState("kendaraan tetap");
  const [search, setSearch] = useState(undefined);
  const [status, setStatus] = useState(filter?.status || undefined);

  /*======================== Handler ======================== */

  const handleChange = (type, value) => {
    switch (type) {
      case "tab":
        setTab(value);
        break;
      case "status":
        setStatus(value);
        break;

      default:
        break;
    }
  };

  const handleSearch = useDebouncedCallback((event) => {
    setSearch(event.target.value);
    // setQuery({ ...query, search: event.target.value });
  }, 300);

  /*======================== UseEffect ======================== */

  useEffect(() => {
    if (location.state?.section) {
      setTab(location.state.section);
      history.replace({ pathname: location.pathname, state: {} });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*======================== Others ======================== */

  const tabOptions = [
    {
      title: "Kendaraan Tetap",
      value: "kendaraan tetap",
    },
    {
      title: "Kendaraan Sementara",
      value: "kendaraan sementara",
    },
  ];

  // const [rentType, setRentType] = useState("")
  // const statusOptions = [
  //   {
  //     title: "Semua Tipe",
  //     value: "null",
  //   },
  //   {
  //     title: "Normal",
  //     value: 0,
  //   },
  //   {
  //     title: "Ganti Tetap",
  //     value: 1,
  //   }
  // ];

  /*======================== Return ======================== */

  return (
    <div
      style={{ minHeight: "100vh", width: "100%", padding: "30px" }}
      className="bg-ice height-100"
    >
      {!isMobile ? (
        <Card
          className="p-4 rounded-card border-0"
          style={{ backgroundColor: "white" }}
        >
          <h1>Kendaraan Anda</h1>
          <Row className="card-body">
            <Col>
              <Tabs
                activeKey={tab}
                className="tabs-trx mb-2 border-bottom-2"
                onSelect={(value) => handleChange("tab", value)}
              >
                {tabOptions.map((item, index) => (
                  <Tab key={index} eventKey={item.value} title={item.title} />
                ))}
              </Tabs>
            </Col>
          </Row>
          <Row className="ps-4 align-items-center">
            {/* {tab == 'kendaraan tetap' && <Col md={6} lg={8}>
              <div className="d-flex" style={{ gap: "1rem" }}>
                <span className="fw-bold" style={{ width: "auto" }}>
                  Tipe Sewa:
                </span>
                <Tabs
                  defaultActiveKey={status}
                  className="tabs-rute mb-2 border-0"
                  style={{ gap: "10px" }}
                  onSelect={(value) => handleChange("status", value)}
                >
                  {statusOptions.map((item, index) => (
                    <Tab key={index} eventKey={item.value} title={item.title} />
                  ))}
                </Tabs>
              </div>
            </Col>} */}
            <Col md={6} lg={4}>
              <InputGroup className="border rounded-card">
                <FloatingLabel
                  className="rounded-card w-75"
                  label={"Cari " + tab}
                  style={{ width: "", fontSize: "12px", height: "50px" }}
                >
                  <Form.Control
                    className="border-0 rounded-card"
                    type="text"
                    style={{ height: "50px" }}
                    placeholder={"Cari " + tab}
                    onChange={(event) => handleSearch(event)}
                  />
                </FloatingLabel>
                <InputGroup.Text
                  className="border-0 search-icon-trx rounded-card ms-auto"
                  style={{ height: "50px" }}
                >
                  <FaSearch />
                </InputGroup.Text>
              </InputGroup>
            </Col>
          </Row>
        </Card>
      ) : (
        <div className="p-2 head-menu trx w-100 shadow-md">
          <div
            className="d-flex mb-2 justify-content-center px-2 flex-column"
            style={{ marginTop: "1.7em" }}
          >
            <div className="d-flex">
              <IoMenuOutline
                onClick={openSidenavMobile}
                className="text-secondary pointer me-2"
                style={{ fontSize: 25 }}
              />

              <h4 className="mt-1">Management Penawaran</h4>
            </div>
            <Col>
              <Tabs
                defaultActiveKey={"rute anda"}
                className="tabs-trx mb-2 border-bottom-2"
                onSelect={(value) => handleChange("tab", value)}
              >
                {tabOptions.map((item, index) => (
                  <Tab key={index} eventKey={item.value} title={item.title} />
                ))}
              </Tabs>
            </Col>
            {/* </Row> */}
            <Row className="d-flex align-items-center">
              <Col xs={8}>
                <InputGroup className="border rounded-card w-100 flex-nowrap">
                  <FloatingLabel
                    className=""
                    label={"Cari " + tab}
                    style={{ fontSize: "12px", height: "50px" }}
                  >
                    <Form.Control
                      className="border-0 rounded-card"
                      type="text"
                      style={{ height: "50px" }}
                      placeholder={"Cari " + tab}
                      onChange={(event) => handleSearch(event)}
                    />
                  </FloatingLabel>
                  <InputGroup.Text
                    className="border-0 search-icon-trx rounded-card ms-auto"
                    style={{ height: "50px" }}
                  >
                    <FaSearch />
                  </InputGroup.Text>
                </InputGroup>
              </Col>
              {/* </Row> */}
            </Row>
          </div>
        </div>
      )}
      {tab === "kendaraan tetap" && <DaftarKendaraanTetap {...{ search }} status={0} />}
      {tab === "kendaraan sementara" && <DaftarKendaraanSementara {...{ search }} status={1} />}
    </div>
  );
};

export default Procurement;
