import AuthApi from "../../api/auth-api";

export const setTrxState = (payload, refreshToken) => async (dispatch) => {
  const api = new AuthApi();

  try {
    const result = await api.checkToken(refreshToken);

    if (result.status === "success") {
      dispatch({
        type: "SET_TRX_STATE",
        payload,
      });
    } else {
      // message.info("Silahkan Login terlebih dahulu");
      localStorage.clear();
      window.location = "/login";
    }
  } catch (error) {
    console.log(error?.response);
  }
};

export const resetTrxState = () => {
  return {
    type: "RESET_TRX_STATE",
  };
};
