import React, { useState } from "react";
import {
  Modal,
  Button
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { formatRupiah } from "../../../../../helper/formatCurrency";
import { Avatar } from "antd";
import{ MdImageNotSupported } from "react-icons/md";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";


const SelectPaymentMethod = ({show, setShow, onSubmit}) => {

  const paymentList = useSelector((st) => st.paymentListReducer?.data);
  const dataPayment = useSelector((st) => st.documentPaymentReducer.detail);
  
  const [detail, setDetail] = useState(false)
  const [selectPaymentMethod, setSelectPaymentMethod] = useState(null)

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = () => {
    onSubmit(selectPaymentMethod)
    handleClose()
  }

  return(
    <>
      <Button
        size="sm"
        className="fw-bold py-1 h-25 px-4 rounded-pill text-capitalize"
        onClick={handleShow}
      >
        Bayar Penagihan
      </Button>
      
      <Modal
        contentClassName="rounded-card p-2"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton className="bg-white">
          <strong>Pilih Pembayaran</strong>
        </Modal.Header>
        <div className="py-4">
          <h5 className="fw-bold px-4">Bank Transfer</h5>
          <div className="px-4" style={{height:"300px", overflow:"hidden", overflowY:"scroll"}}>
            {paymentList?.map((item, i)=>
              <div key={i} onClick={()=> setSelectPaymentMethod(item)} className="rounded pointer p-2 mb-2 d-flex justify-content-between align-items-center pointer" style={{backgroundColor:`${selectPaymentMethod === item ? "#EAF4F6" : ""}`, border:`1px solid ${selectPaymentMethod === item ? "#209AB7" : "#DEE2E6"}`}}>
                <div className="d-flex justify-content-center align-items-center">
                  <Avatar
                    className="me-2 border rounded overflow-hidden"
                    style={{objectFit:"fill"}}
                    shape="square"
                    size={45}
                    src={item.logo}
                    icon={!item.logo && <MdImageNotSupported/>}
                  />
                  <h6 className="m-0 fw-bold">{item?.name}</h6>
                </div>
                <input type="radio" name="bank" checked={selectPaymentMethod === item ? true : false}/>
              </div>
            )}
          </div>
        </div>
        {detail && 
        <div className="px-3">
          <div className="d-flex justify-content-between mb-2">
            <h6>Subtotal</h6>
            <h6 className="fw-bold">{dataPayment?.payment_amount ? formatRupiah(dataPayment?.payment_amount) : "-"}</h6>
          </div>
          <div className="d-flex justify-content-between mb-2">
            <h6>Potongan Tagihan (Rp)</h6>
            <h6 className="fw-bold">{ dataPayment?.payment_amount - dataPayment?.payment_amount_after_discount > 0 ? formatRupiah(dataPayment?.payment_amount - dataPayment?.payment_amount_after_discount) : 'Rp 0'}</h6>
          </div>
          {/* <div className="d-flex justify-content-between mb-2">
            <h6>Pajak (0%)</h6>
            <h6 className="fw-bold">Rp 0</h6>
          </div> */}
          {/* {dataPayment?.trx_billing_discount?.map((item, i)=>
            <div key={i} className="d-flex justify-content-between">
              <h6 className="">{item?.name}</h6>
              {item?.discount_amount_from_percentage && 
                <h6 className="fw-bold text-right">
                  {formatRupiah(item?.discount_amount_from_percentage)}
                </h6>
              }
              {item?.discount_fix && 
                <h6 className="fw-bold text-right">
                  {formatRupiah(item?.discount_fix)}
                </h6>
              }
              {!item?.discount_amount_from_percentage && !item?.discount_fix &&
                <h6 className="fw-bold">-</h6>
              }
            </div>
            )} */}
        </div>
        }
        <Modal.Footer>
          <div className="w-100 d-flex flex-column">
            <div className="d-flex justify-content-between mb-2">
              <h6>Total Tagihan</h6>
              <div className="d-flex align-items-center">
                <h6 onClick={()=> setDetail(!detail)} className="pointer fw-bold m-0" style={{color:"#2BBECB"}}>{dataPayment?.payment_amount_after_discount ? formatRupiah(dataPayment?.payment_amount_after_discount) : "-"}</h6>
                {detail ?
                  <BiChevronUp className='text-[25px] cursor-pointer' onClick={()=> setDetail(false)}/>
                  :
                  <BiChevronDown className='text-[25px] cursor-pointer' onClick={()=> setDetail(true)}/>
                }
              </div>
            </div>
            <Button
              disabled={!selectPaymentMethod ? true : false}
              size="sm"
              className="fw-bold py-1 h-25 px-4 rounded-pill text-capitalize"
              style={{backgroundColor:`${!selectPaymentMethod ? "#BBBBBB" : "#CE1671"}`, border:"none"}}
              onClick={handleSubmit}
            >
              Lanjutkan Pembayaran
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
    )
  }

export default SelectPaymentMethod;
