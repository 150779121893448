import React, { useState, useEffect, useRef } from "react";

/*======================== Third Party ======================== */
import { Pagination } from "antd";
import { Dropdown } from "react-bootstrap";
import PropTypes from "prop-types";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs"

/*======================== Components ======================== */
import useBreakpoint from "../../../components/responsiveUtils/useBreakpoint";


const CustomPagination = (props) => {
    const { isMobile } = useBreakpoint();

  /*======================== Props ======================== */

  const {
    data,
    limit: initLimit,
    page: initPage,
    loading,
    handleChange,
    total_data,
  } = props;

  /*======================== UseState ======================== */

  const [limit, setLimit] = useState(initLimit);
  const [mobilePagination, setMobilePagination] = useState(false);
  const [optPaginationMobile, setOptPaginationMobile] = useState(null);
  
  /*======================== UseRef ======================== */
  const btnRef = useRef()

  /*======================== Handler ======================== */

  const limitOpts = [10, 20, 30, 50];

  const handlePage = (value) => {
    handleChange("page", value);
  };

  const handleLimit = (value) => {
    handleChange("limit", value);
    setLimit(value);
  };

  const handlePageOf = () => {
    const start = limit * (initPage - 1) + 1;
    let end = data.length;
    if (limit <= data.length) {
      if (limit * initPage <= data.length) {
        end = limit * initPage;
      }
    }
    return `${start} - ${end} of ${total_data ? total_data : data.length}`;
  };

  const backPageMobile = () => {
    if(initPage > 1){
      handleChange("page", initPage-1)
    }
  }
  const nextPageMobile = () => {
    const sisaBagi = Math.ceil(total_data/limit)
    if(sisaBagi > initPage){
      handleChange("page", initPage+1)
      return;
    }
  }
  const chosePageMobile = (param) => {
    handleChange("page", param)
    setMobilePagination(!mobilePagination)
  }


  /*======================== useEffect ======================== */
    // when klick outside element, modal hide
    useEffect(()=>{
      const closeDropDown = e => {
        if(
          btnRef.current && !btnRef.current.contains(e.target)
        ){
          setMobilePagination(false)
        }
      }
      document.body.addEventListener("click",closeDropDown);
      return () => document.body.removeEventListener('click',closeDropDown)
  
    },[])

  // option pagination (only for mobile responsive)
  useEffect(()=>{
    const getPaginationMobile = () => {
        const sisaBagi = Math.ceil(total_data/limit)
        let data = Array.from({length:sisaBagi},(_,i)=> i +1)
        setOptPaginationMobile(data)
    }
    getPaginationMobile()
  },[limit])

  /*======================== Return ======================== */


  return (
    <div className="custom-footer d-flex justify-content-between mt-3 align-items-center position-relative">
      <div>
        <div className="d-flex align-items-center">
          <p className="mb-0 label">Show: </p>
          <Dropdown className="d-inline mx-2">
            <Dropdown.Toggle
              id="dropdown-autoclose-true"
              size="sm"
              variant="light"
              className="bg-white text-secondary px-3 border shadow-sm rounded"
            >
              <strong>{limit}</strong>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {limitOpts.map((item) => (
                <Dropdown.Item
                  key={item}
                  onClick={() => {
                    handleLimit(item);
                  }}
                >
                  {item}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      
      <div className="d-flex justify-content-center mt-1">
        {!isMobile ? 
            (<Pagination
                showSizeChanger={false}
                disabled={loading}
                showLessItems
                onChange={handlePage}
                current={initPage}
                pageSize={limit}
                total={total_data || data.length}
            />)    
        :
            (<>
                {mobilePagination &&
                  <div
                    className="bg-white rounded px-2 py-1 mx-1 shadow-sm fw-bold position-absolute overflow-auto"
                    style={{
                      marginTop:"-5px", 
                      color:"#2BBECB", 
                      fontSize:"14px",
                      zIndex: "99",
                      maxHeight:"100px",
                      bottom:45
                    }}
                    onClick={(e)=> e.stopPropagation()}
                  >
                    {optPaginationMobile.map((i)=>
                      <p className="m-1 mb-1" onClick={()=> chosePageMobile(i)}>{i}</p>
                     )}
                  </div>
                }
                <BsChevronLeft
                  onClick={()=> backPageMobile()}
                  className="pointer"
                  />
                  <div
                    ref={btnRef}
                    className="bg-white rounded px-2 py-1 mx-2 shadow-sm fw-bold pointer"
                    style={{
                      marginTop:"-5px", 
                      color:"#2BBECB", 
                      fontSize:"12px"
                    }}
                    onClick={()=>setMobilePagination(!mobilePagination)}
                  >
                    {initPage}
                  </div>
                <BsChevronRight
                  onClick={()=> nextPageMobile()}
                  className="pointer"
                />
            </>)
        }

      </div>
      <div>
        <p className="mb-0 label float-end">{handlePageOf()}</p>
      </div>
    </div>
  );
};

CustomPagination.propTypes = {
  data: PropTypes.array.isRequired,
  limit: PropTypes.number,
  total_data: PropTypes.number,
  loading: PropTypes.bool,
  handleChange: PropTypes.func,
};

export default CustomPagination;
