import React, { Component } from "react";
import { Card, Form } from "react-bootstrap";
import { connect } from "react-redux";
import SearchAddress from "../../components/modalInput/SearchAddress";
import DetailAddress from "../../components/modalInput/DetailAddress";

// images
import iconDateGet from "../../assets/images/icon-date-get.svg";
import iconDateSent from "../../assets/images/icon-date-sent.svg";
import iconMark from "../../assets/images/icon-marker-o.svg";

import { updatePickupDetail } from "../../redux/action/sewakendaraan_action";
import NestedDateTime from "../../components/modalDatePicker/NestedDateTime";
import DateRangePicker from "../../components/modalDatePicker/DateRangePicker";

class TabVehicleRent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  setPickupAddress(address, detailMap) {
    const map = this.props.map;
    this.props.dispatch(
      updatePickupDetail({
        detailMap: map.data,
        lat: map.center.lat,
        lng: map.center.lng,
        lokasi: address,
      })
    );
  }

  updateDetailPickup(payload) {
    this.props.dispatch(updatePickupDetail(payload));
  }

  render() {
    return (
      <Card.Body className="pt-0">
        <h5 className="fw-light mb-2 mt-4-mobile">Periode Sewa Kendaraan</h5>
        <div className="mb-2">
          <Card className="ice mb-2">
            <Card.Body className="py-2">
              <img src={iconDateGet} alt="" style={{ marginTop: "-4px" }} />{" "}
              <span className="ms-2 fs-13">Waktu Pengambilan</span>
              <div className="d-flex mt-1">
                <div className="br-dash" />
                <div className="w-100 ms-2">
                  <NestedDateTime
                    placeholder="Pilih Waktu dan Tanggal"
                    updateProps={(data) => {
                      this.updateDetailPickup(data);
                    }}
                    value={this.props.sewaKendaraanReducer}
                  />
                </div>
              </div>
            </Card.Body>
          </Card>
          <Card className="ice mb-2">
            <Card.Body className="py-2">
              <img src={iconDateSent} alt="" style={{ marginTop: "-4px" }} />{" "}
              <span className="ms-2 fs-13">Waktu Pengembalian</span>
              <div className="d-flex mt-1">
                <div className="w-100 ms-3">
                  <DateRangePicker
                    placeholder="Pilih Waktu dan Tanggal"
                    updateProps={(data) => {
                      this.updateDetailPickup(data);
                    }}
                    value={this.props.sewaKendaraanReducer}
                  />
                </div>
              </div>
            </Card.Body>
          </Card>
          <h5 className="fw-light mt-3">Lokasi Pertemuan</h5>
          <Card className="ice">
            <Card.Body>
              <img
                src={iconMark}
                alt=""
                style={{
                  marginLeft: "-1px",
                  width: "18px",
                }}
              />
              <span className="ms-2 fs-13">Alamat Pengambilan</span>
              <div className="d-flex mt-2">
                <div className="w-100 ms-3">
                  <Form>
                    <fieldset className="input-group-vertical">
                      <SearchAddress
                        address={this.props.sewaKendaraanReducer.lokasi}
                        getPropsValue={(address, placeId, suggestion) => {
                          this.setPickupAddress(address);
                        }}
                        placeholder="Masukkan Alamat Pengambilan"
                        title="Alamat Pengambilan"
                      />
                      {this.props.sewaKendaraanReducer.lokasi && (
                        <DetailAddress
                          placeholder="Masukkan Detail Alamat Pengambilan"
                          updateProps={(data) => {
                            this.updateDetailPickup(data);
                          }}
                          value={this.props.sewaKendaraanReducer}
                          title="Detail Pengambilan"
                          order="RENT"
                        />
                      )}
                    </fieldset>
                  </Form>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </Card.Body>
    );
  }
}

const mapStateToProps = (state) => ({
  map: state.map,
  address: state.map.address,
  sewaKendaraanReducer: state.sewaKendaraanReducer,
});
export default connect(mapStateToProps)(TabVehicleRent);
