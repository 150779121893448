import { Row, Col, Tabs, Tab } from "react-bootstrap";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { FiChevronLeft } from "react-icons/fi";
import InfoKendaraan from "./Info";
import HargaKendaraan from "./Harga";

const PartnerKendaraanDetailInformasi = (props) => {
  const { detail } = props;
  /*======================== Hooks ======================== */

  const history = useHistory();

  /*======================== UseState ======================== */

  const [tab, setTab] = useState("info_kendaraan");

  /*======================== Handler ======================== */

  const handleChange = (type, value) => {
    switch (type) {
      case "tab":
        setTab(value);
        break;

      default:
        break;
    }
  };

  const handleBack = () => {
    history.goBack();
  };

  const getStatus = (value) => {
    switch (value) {
      case "WAITING_APPROVAL":
        return "WAITING APPROVAL";
      case "IN_PROGRESS":
        return "IN PROGRESS";
      default:
        return value;
    }
  };

  const getStatusColor = (value) => {
    switch (value) {
      case "WAITING_APPROVAL":
        return "#ffc82c";
      case "AVAILABLE":
        return "#1eaf56";
      case "IN_PROGRESS":
        return "#27c0cd";
      case "UPCOMING":
        return "#005158";
      case "UNAVAILABLE":
        return "#bbbbbb";
      case "REJECTED":
        return "#ff4949";
      case "SUSPENDED":
        return "#EE028C";
      default:
        return '#000';
    }
  };

  /*======================== UseEffect ======================== */

  /*======================== Others ======================== */

  const tabOptions = [
    {
      title: "Info Kendaraan",
      value: "info_kendaraan",
    },
    {
      title: "Info Harga",
      value: "harga_kendaraan",
    },
  ];

  /*======================== Return ======================== */

  return (
    <Row>
      <Col xs={12}>
        <div className="bg-white rounded-card w-full pt-2 ps-3 mb-4">
          <div className="d-flex flex-row align-items-center">
            <FiChevronLeft
              fontSize={25}
              className="pointer me-3"
              onClick={handleBack}
            />
            <div style={{ display: "flex" }}>
              <div>
                <h1 className="mb-0">
                  {detail.mst_vehicle_brand?.name} -{" "}
                  {detail.mst_vehicle_subtype?.name}
                </h1>
                <p className="text-muted">{detail.plate_number}</p>
              </div>
              <p
                className="ms-4 mt-2 mb-0"
                style={{"color": getStatusColor(detail.status)}}
              >
                {getStatus(detail.status)}
              </p>
            </div>
          </div>

          <Tabs
            activeKey={tab}
            className="tabs-trx mb-2 border-bottom-2"
            onSelect={(value) => handleChange("tab", value)}
          >
            {tabOptions.map((item, index) => (
              <Tab key={index} eventKey={item.value} title={item.title} />
            ))}
          </Tabs>
        </div>
      </Col>
      <Col xs={12}>
        {tab === "info_kendaraan" && <InfoKendaraan detail={detail} />}
        {tab === "harga_kendaraan" && <HargaKendaraan detail={detail} />}
      </Col>
    </Row>
  );
};

export default PartnerKendaraanDetailInformasi;
