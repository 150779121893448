import { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setTokenSSO } from "../../redux/action/auth_action";
import axios from "axios";
import { message } from "antd";

const Oauth = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory()

    useEffect(()=>{
        const ssoLogin = async () => {
            const searchParams =  new URLSearchParams(location.search);
            const sso_code = await searchParams.get("code");
            try{
                // console.log("GET SSO_CODE === === ===",sso_code)
                const result = await axios.post(`${process.env.REACT_APP_API_END_POINT}/customer/v1/authentication`, { sso_code })
                // console.log("ini data result OUTSIDE ====> ",result)
                if(result.status === 200){
                    // localStorage.setItem('sso_access_token', JSON.stringify(result.data.data))
                    dispatch(setTokenSSO(result.data.data))
                    if(result.data.data.refresh_token){
                        history.push(`/customer/${result.data.data.refresh_token}`)
                        message.success("Login Berhasil.")
                    }else{
                        history.push(`/`)
                        message.success("Silahkan lengkapi akun anda terlebih dahulu.")
                    }
                    // console.log("ini data result INSIDE ====> ",result)
                    // console.log("ini data result INSIDE ====> ",result.data.data.refresh_token)
                }else{
                    history.push(`/login`)
                }
            }catch(err){
                console.log(err,"hello hello bandung")
            }
        }
        ssoLogin()
        // .catch(err => console.log(err))
    // eslint-disable-next-line
    },[])

    return null
}

export default Oauth;
