import React from "react";
import { v4 as uuidv4 } from "uuid";

const InfoBar = ({ infoBarClassName, text, type = "info" }) => {
  return (
    <div className="position-relative">
      <div className="position-absolute" style={{ left: "10%", top: "40%" }}>
        {text}
      </div>
      {type !== "info" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          // width="782"
          // height="62"
          className={infoBarClassName}
          viewBox="0 0 782 200"
        >
          <rect
            id="Rectangle_994"
            data-name="Rectangle 994"
            width="782"
            height="62"
            rx="10"
            fill="#ffe3e3"
          />
          <path
            id="Intersection_11"
            data-name="Intersection 11"
            d="M10,62A10,10,0,0,1,0,52V10A10,10,0,0,1,10,0H46.311A49.778,49.778,0,0,1,53,25,49.875,49.875,0,0,1,36.632,62Z"
            fill="#ff4949"
            opacity="0.27"
          />
          <path
            id="Intersection_12"
            data-name="Intersection 12"
            d="M0,0H56.3A29.011,29.011,0,0,1,28.149,22,29.012,29.012,0,0,1,0,0Z"
            transform="translate(14.851)"
            fill="#ff4949"
            opacity="0.08"
          />
          <path
            id="Path_2026"
            data-name="Path 2026"
            d="M-17243.875,7517.78v8.942"
            transform="translate(17272.313 -7488.722)"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-width="4"
          />
          <path
            id="Path_2027"
            data-name="Path 2027"
            d="M-17243.875,7517.886v-.105"
            transform="translate(17272.313 -7495.793)"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-width="4"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          // width="566.312"
          // height="46.812"
          viewBox="0 0 566.312 46.812"
          className={infoBarClassName}
        >
          <defs>
            <linearGradient
              id="linear-gradient"
              x1="0.047"
              y1="1.692"
              x2="1"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0" stop-color="#d7edf0" />
              <stop offset="1" stop-color="#f3f8f8" />
            </linearGradient>
          </defs>
          <g
            id="Group_3836"
            data-name="Group 3836"
            transform="translate(-31 -375)"
          >
            <g
              id="Group_2447"
              data-name="Group 2447"
              transform="translate(31 375)"
            >
              <rect
                id="Rectangle_994"
                data-name="Rectangle 994"
                width="566.312"
                height="46.811"
                rx="10"
                transform="translate(0)"
                fill="url(#linear-gradient)"
              />
              <path
                id="Intersection_11"
                data-name="Intersection 11"
                d="M10,46.812a10,10,0,0,1-10-10V10A10,10,0,0,1,10,0H33.353A35.849,35.849,0,0,1,38.17,18a35.956,35.956,0,0,1-14.4,28.807Z"
                transform="translate(0 0)"
                fill="#209ab7"
                opacity="0.27"
              />
            </g>
            <path
              id="Intersection_12"
              data-name="Intersection 12"
              d="M0,0H40.544A20.893,20.893,0,0,1,20.273,15.844,20.894,20.894,0,0,1,0,0Z"
              transform="translate(41.695 375)"
              fill="#209ab7"
              opacity="0.08"
            />
            <path
              id="Path_2026"
              data-name="Path 2026"
              d="M-17243.875,7517.78v6.44"
              transform="translate(17295.355 -7121.854)"
              fill="none"
              stroke="#fff"
              stroke-linecap="round"
              stroke-width="4"
            />
            <path
              id="Path_2027"
              data-name="Path 2027"
              d="M-17243.875,7517.856v-.076"
              transform="translate(17295.355 -7126.945)"
              fill="none"
              stroke="#fff"
              stroke-linecap="round"
              stroke-width="4"
            />
          </g>
        </svg>
      )}
    </div>
  );
};

export default InfoBar;
