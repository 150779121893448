import React, { useState, useEffect } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import { FiChevronDown } from "react-icons/fi";
import PropTypes from "prop-types";

const Payment = (props) => {
  /*======================== Props ======================== */

  const { isOpen, handleClose, handleSubmit, formValue, data } = props;

  /*======================== UseState ======================== */

  const [activeTenure, setActiveTenure] = useState({});

  /*======================== Handler ======================== */

  const handleActiveTenure = (value) => {
    setActiveTenure(value);
  };

  const handleOnClose = () => {
    handleClose();
  };

  const handleOnSelect = () => {
    handleSubmit({
      tenure: activeTenure,
    });
  };

  /*======================== UseEffect ======================== */

  useEffect(() => {
    if (formValue.payment_tenure_id !== "") {
      setActiveTenure({
        id: formValue.payment_tenure_id,
        tenure: formValue.payment_tenure_name,
      });
    } else {
      setActiveTenure({});
    }
  }, [formValue]);

  /*======================== Return ======================== */

  return (
    <Modal
      contentClassName="rounded-card p-2"
      show={isOpen}
      onHide={handleOnClose}
      className="p-3"
      centered
    >
      <div className="d-flex p-2 border-bottom">
        <h2 className="fw-bold">Pilih Pembayaran</h2>
        <MdClose className="ms-auto me-2 pointer" onClick={handleOnClose} />
      </div>
      <Modal.Body>
        <div className="jenis-barang-opts mt-1">
          <h3 className="fw-bold">Tempo</h3>
          {data.length > 0 &&
            data.map((item, index) => (
              <Card
                key={index}
                className={`rounded pointer${
                  item.id === activeTenure.id ? " active" : ""
                } mb-2`}
                onClick={() => handleActiveTenure(item)}
              >
                <Card.Body className="py-3">
                  Tempo ({item.tenure} Hari)
                </Card.Body>
              </Card>
            ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size="sm"
          className="w-100 fw-bold py-2 px-4 rounded-pill text-capitalize mt-2"
          onClick={handleOnSelect}
        >
          Pilih Pembayaran
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

Payment.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  formValue: PropTypes.object,
};

export default Payment;
