const initialState = {
  notification: { loading: false, data: [] },
  total_unread: 0,
  tabKey: 1,
};

export const notifReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "SET_NOTIFICATION":
      return { ...state, notification: { ...payload } };
    case "SET_TAB_KEY":
      return { ...state, tabKey: payload };
    case "SET_COUNT_UNREAD":
      return { ...state, total_unread: payload };
    case "RESET_TRX_STATE":
      return { ...initialState };
    default:
      return state;
  }
};
