import { BaseApiRequest } from "./base-api";
import { OpResult } from "../helper/operation_result";
import { message } from "antd";
import axios from "axios";

export default class NotifApi extends BaseApiRequest {
  constructor() {
    super(process.env.REACT_APP_API_END_POINT);
  }

  async getNotification(query, token) {
    let result = OpResult.failed("Failed GET Notification");
    try {
      let resApi = await axios.get(
        this.endpoint + `/customer/notification?${query}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      result = resApi.data;
    } catch (e) {
      console.log(e.response);
      // message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async getUnreadNotification(token) {
    let result = OpResult.failed("Failed Get Unread Notification");
    try {
      let resApi = await axios.get(
        this.endpoint + `/customer/notification/count/unread`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      result = resApi.data;
    } catch (e) {
      console.log(e.response);
      // message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async markAllRead(token, query = "") {
    let result = OpResult.failed("Failed Mark All Read");
    try {
      let resApi = await axios.patch(
        this.endpoint + `/customer/notification/read?${query}`,
        {
          is_read: 1,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      result = resApi.data;
    } catch (e) {
      console.log(e.response);
      // message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async markAsRead(id, token) {
    let result = OpResult.failed("Failed Mark As Read");
    try {
      let resApi = await axios.patch(
        this.endpoint + `/customer/notification/${id}/read`,
        {
          is_read: 1,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      result = resApi.data;
    } catch (e) {
      console.log(e.response);
      // message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }
}
