import { Row, Col, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import moment from "moment/moment";
import noData from "../../../assets/images/no-data.png";
import AjukanPerubahan from "./Modal/AjukanPerubahan";

import "../style.scss";
import { useDispatch } from "react-redux";
import { getLogNegotiationOffer, sendNegotiateOffer } from "../../../redux/action/Partner/partner_action";
import { useParams } from "react-router-dom";
import { message } from "antd";
import { useSelector } from "react-redux";

const AktifitasPenawaran = (props) => {
  /*======================== Props ======================== */

  const { data } = props;

  /*======================== Hooks ======================== */
  const dispatch = useDispatch()
  const params = useParams()

  /*======================== State ======================== */
  const authData = useSelector(state => state.authReducer)
  const [openObrolan, setOpenObrolan] = useState(false);
  const [log, setLog] = useState("");
  const [queryParams] = useState({
    limit: 9999,
    page: 1,
    sort_by: "created_at",
    order_by: "DESC",
  });


  /*======================== Handler ======================== */

  const renderLog = () => {
    let component = [];
    if (log && log.length > 0) {
      log.forEach((item, i) => {
        component.push(
          <div
            className="border-box bg-white rounded-card w-full py-2 px-3 mt-2"
            key={i}
          >
            <div
              className="py-1"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <p className="text-muted mb-0"> {item.created_by === authData.company_name ? 'Anda' : item.created_by}</p>
              <p className="mb-0">
                {" "}
                {moment(item.created_at).format("DD MMM YYYY")}
              </p>
            </div>
            <hr className="my-2 text-muted" />
            <div>
              <p className="text-muted mb-0"> Deskripsi </p>
              <p className="mb-0"> {item.description}</p>
            </div>
          </div>
        );
      });
    } else {
      return (
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{ marginTop: "5rem" }}
        >
          <img src={noData} alt="no-data" />
          <p className="mt-1">Tidak ada aktivitas penawaran</p>
        </div>
      );
    }

    return component;
  };

  const getLogDispatch = async () => {
    try {
      const resp = await dispatch(getLogNegotiationOffer(queryParams, params.id_partner_offer))
      setLog(resp)
    } catch (error) {
      message.error('Gagal mendapatkan log perubahan penawaran')
    }
  }

  const handleSendNegotiatioOffer = async (data) => {
    const payload = {
      ...data,
      delivery_partner_offer_id: params.id_partner_offer,
      partner_id: params.id,
    }
    try {
      await dispatch(sendNegotiateOffer(payload))
      await setOpenObrolan(false)
      await getLogDispatch()
    } catch (error) {
      message.error('Gagal mengirimkan pesan')
    }

  }

  /*======================== useEffect ======================== */

  useEffect(() => {
    getLogDispatch()
  }, []);

  /*======================== Return ======================== */

  return (
    <Row className="mt-4">
      <Col xs={12}>
        <h5 className="fw-normal mb-2">Aktivitas Penawaran</h5>
        <div className="bg-white rounded-card w-full p-1">
          <div className="aktifitas-penawaran" style={{paddingBottom: '4rem'}}>
            <div className="custom-scroll" style={{maxHeight: '65vh'}}>
            {renderLog()}
            </div>
            <div className="aktifitas-penawaran-footer">
              <Button
                size="sm"
                className="rounded-10 text-capitalize w-25 float-end"
                onClick={() => setOpenObrolan(true)}
              >
                Ajukan Perubahan
              </Button>
            </div>
          </div>
        </div>
      </Col>

      {openObrolan && (
        <AjukanPerubahan
          isOpen={openObrolan}
          handleClose={() => setOpenObrolan(false)}
          handleSendNegotiatioOffer={handleSendNegotiatioOffer}
        />
      )}

    </Row>
  );
};

export default AktifitasPenawaran;
