import { Row, Col, Badge, Accordion } from "react-bootstrap";
import icon_truck from "../../../../assets/images/icon-truck.svg";
import KendaraanCard from "./KendaraanCard";

const KendaraanItem = (props) => {
  const { data } = props;

  /*======================== Hooks ======================== */

  /*======================== UseState ======================== */

  /*======================== Handler ======================== */

  /*======================== UseEffect ======================== */

  /*======================== Others ======================== */

  const renderKendaraan = (data) => {
    let component = [];
    if (data && data.length > 0) {
      data.forEach((item, i) => {
        component.push(
          <Col xs={12} sm={6} md={4} key={i}>
            <KendaraanCard data={item}/>
          </Col>
        );
      });
    } else {
      return null;
    }
    return component
  };

  /*======================== Return ======================== */

  return (
    <Accordion defaultActiveKey="0">
      {
        data?.map(item => (
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <Row>
                <Col xs={6}>
                  <img
                    className="mt-2"
                    src={item.vehicle_type_icon}
                    width={80}
                    height={80}
                    alt=""
                  />
                </Col>
                <Col xs={6} className="mt-2">
                  <p className="mb-3"> {item?.vehicle_type_name}</p>
                  <p className="mb-1 text-success"> {item?.total_partner_vehicle} Armada terdaftar</p>
                </Col>
              </Row>
            </Accordion.Header>
            <Accordion.Body>
              <Row>{renderKendaraan(item.vehicle_offer_detail)}</Row>
            </Accordion.Body>
          </Accordion.Item>
        ))
      }
    </Accordion>
  );
};

export default KendaraanItem;
