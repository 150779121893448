import React, { useState } from "react";
import {
  Modal,
  Form,
  InputGroup,
  FloatingLabel,
  Button,
} from "react-bootstrap";
import InfoBar from "../infoBar/InfoBar";
import { useHistory } from "react-router-dom";
import { Tag } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { resetAuth, setAuth } from "../../redux/action/auth_action";
import ProfileApi from "../../api/profile-api";
import AccountApi from "../../api/auth-api";
import useBreakpoint from "../responsiveUtils/useBreakpoint";
import SectionSuccess from "./SectionSuccess";

// icons
import { MdChevronRight, MdClose, MdCheck } from "react-icons/md";
import icon_mail from "../../assets/images/icon_mail.svg";
import icon_email_circle_blue from "../../assets/images/icon_email_circle_blue.svg";
import { message } from "antd";

const Email = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const auth = useSelector((st) => st.authReducer);
  const { isMobile, isTablet, isDesktop } = useBreakpoint();

  const [show, setShow] = useState(false);

  const [body, setBody] = useState({ email: "" });

  const [isSubmit, setIsSubmit] = useState(false);

  const openModal = () => {
    setShow(true);
  };

  const closeModal = () => {
    setIsSubmit(false);
    setShow(false);
  };

  const handleSubmit = async () => {
    try {
      const accountApi = new AccountApi();
      const profileApi = new ProfileApi();

      const result = await accountApi.checkToken(auth.refresh_token);
      if (result.status === "success") {
        const profileRes = await profileApi.updateEmail(
          { ...body },
          auth.access_token
        );

        if (profileRes.status === "success") {
          message.success(profileRes.message);
          const { data } = await accountApi.getDetailCustomer(
            auth.access_token
          );

          dispatch(setAuth({ ...auth, ...data }));

          setIsSubmit(true);
        } else {
          message.error(profileRes.message);
          closeModal();
        }
      } else {
        history.push("/login");
        dispatch(resetAuth());
      }
    } catch (error) {
      console.log(error.response);
    }
  };

  return (
    <>
      <div
        className="d-flex align-items-center pointer px-2"
        onClick={openModal}
      >
        <img src={icon_mail} width={25} height={25} alt="" />

        <div className="ms-3">
          <h6 className="text-muted">Profil</h6>
          <h5>{auth?.email}</h5>
        </div>
        <div
          className="d-flex ms-auto align-items-center"
          style={{ marginTop: "-10px" }}
        >
          {auth?.is_email_verified > 0 && (
            <Tag color="green" icon={isMobile ? <MdCheck /> : null}>
              {isMobile ? "" : "Terverifikasi"}
            </Tag>
          )}
          <MdChevronRight className="" />
        </div>
      </div>
      <Modal
        contentClassName="rounded-card p-2"
        show={show}
        onHide={closeModal}
        className="p-3"
        centered
      >
        {isSubmit ? (
          <SectionSuccess
            type="PERSONAL"
            title="Alamat Email Anda Berhasil diubah!"
            desc="Sekarang semua info mengenai transaksi kamu akan dikirimkan ke email baru kamu, Jangan lupa verifikasi email kamu juga, Ya ! "
          />
        ) : (
          <>
            <div className="d-flex p-2">
              <p className="fw-bold">Ubah Email</p>
              <MdClose className="ms-auto me-2 pointer" onClick={closeModal} />
            </div>
            <Modal.Body>
              <div className="">
                <img src={icon_email_circle_blue} className="mb-2" alt="" />
                <h4 className="fw-bold">Masukkan Email Baru</h4>
                <h6 className="text-muted">
                  Pastikan Email baru kamu aktif. email tersebut akan
                  menggantikan email yang sedang aktif Sekarang terdaftar :{" "}
                </h6>
                <ul className="mt-4">
                  <li className="fw-bold">{auth?.email}</li>
                </ul>
                <InputGroup className="border rounded-input">
                  <InputGroup.Text
                    className="border-0 rounded-input"
                    style={{ backgroundColor: "#f8f9fa" }}
                  >
                    <img src={icon_mail} alt="" />
                  </InputGroup.Text>
                  <FloatingLabel
                    className="rounded-input"
                    label="Masukkan Email Baru"
                    style={{
                      fontSize: "12px",
                      width: isMobile ? "80%" : "90%",
                    }}
                  >
                    <Form.Control
                      type="text"
                      className="border-0 rounded-input"
                      placeholder="m"
                      onChange={(ev) => {
                        setBody({ ...body, email: ev.target.value });
                      }}
                      style={{ height: "50px" }}
                    ></Form.Control>
                  </FloatingLabel>
                </InputGroup>
                <InfoBar
                  infoBarClassName={"mt-2"}
                  text={
                    <>
                      <h6>Harap verifikasi ulang setelah mengubah email</h6>
                    </>
                  }
                />
              </div>
            </Modal.Body>
          </>
        )}
        <Modal.Footer>
          {isSubmit ? (
            <></>
          ) : (
            // <Button
            //   size="sm"
            //   variant="primary"
            //   className="rounded-pill px-4 py-2 w-100"
            // >
            //   Verifikasi Email
            // </Button>
            <Button
              disabled={body.email ? false : true}
              size="sm"
              variant="primary"
              className="float-end rounded-pill px-4 py-2"
              onClick={() => {
                handleSubmit();
              }}
            >
              Simpan
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Email;
