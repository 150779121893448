import { BaseApiRequest } from "./base-api";
import { OpResult } from "../helper/operation_result";
import { message } from "antd";

export default class ProfileApi extends BaseApiRequest {
  constructor() {
    super(process.env.REACT_APP_API_END_POINT);
  }

  async getDetailCustomer(token) {
    let result = OpResult.failed("Failed GET detail customer");
    try {
      let resApi = await this.get(`/customer`, token);
      if (resApi.status) {
        result = resApi;
      }
    } catch (e) {
      result = OpResult.failed("Internal Error");
    }
    return result;
  }

  async updateEmail(body, token) {
    let result = OpResult.failed("Failed");
    try {
      let resApi = await this.patch(
        "/customer/profile/email",
        {
          email: body.email,
        },
        token
      );
      if (resApi.status) {
        result = resApi;
      } else {
        message.error(resApi.message);
      }
    } catch (error) {
      result = OpResult.failed("Internal Error");
    }
    return result;
  }

  async updateName(body, token) {
    let result = OpResult.failed("Failed");
    try {
      let resApi = await this.patch(
        "/customer/profile/name",
        {
          name: body.name,
        },
        token
      );
      if (resApi.status) {
        result = resApi;
      } else {
        message.error(resApi.message);
      }
    } catch (error) {
      result = OpResult.failed("Internal Error");
    }
    return result;
  }

  async updateAddress(body, token) {
    let result = OpResult.failed("Failed");
    try {
      let resApi = await this.patch(
        "/customer/profile/address",
        {
          address: body.address,
        },
        token
      );
      if (resApi.status) {
        result = resApi;
      } else {
        message.error(resApi.message);
      }
    } catch (error) {
      result = OpResult.failed("Internal Error");
    }
    return result;
  }

  async updateBirthDate(body, token) {
    let result = OpResult.failed("Failed");
    try {
      let resApi = await this.patch(
        "/customer/profile/birth-date",
        {
          birth_date: body.birth_date,
        },
        token
      );
      if (resApi.status) {
        result = resApi;
      } else {
        message.error(resApi.message);
      }
    } catch (error) {
      result = OpResult.failed("Internal Error");
    }
    return result;
  }

  async updateGender(body, token) {
    let result = OpResult.failed("Failed");
    try {
      let resApi = await this.patch(
        "/customer/profile/gender",
        {
          gender: body.gender,
        },
        token
      );
      if (resApi.status) {
        result = resApi;
      } else {
        message.error(resApi.message);
      }
    } catch (error) {
      result = OpResult.failed("Internal Error");
    }
    return result;
  }

  async updateKtp(body, token) {
    let result = OpResult.failed("Failed");
    try {
      let resApi = await this.patch("/customer/profile/ktp", body, token);
      if (resApi.status) {
        result = resApi;
      } else {
        message.error(resApi.message);
      }
    } catch (error) {
      result = OpResult.failed("Internal Error");
    }
    return result;
  }

  async updateToCompany(body, token) {
    let result = OpResult.failed("Failed");
    try {
      let resApi = await this.patch(
        "/customer/profile/to-company",
        body,
        token
      );
      if (resApi.status) {
        result = resApi;
      } else {
        message.error(resApi.message);
      }
    } catch (error) {
      result = OpResult.failed("Internal Error");
    }
    return result;
  }

  async updatePassword(body, token) {
    let result = OpResult.failed("Failed");
    try {
      let resApi = await this.patch(
        "/customer/profile/password",
        {
          old_password: body.old_password,
          new_password: body.new_password,
        },
        token
      );
      if (resApi.status) {
        result = resApi;
      } else {
        result = OpResult.failed(resApi.message);
      }
    } catch (error) {
      result = OpResult.failed("Internal Error");
    }
    return result;
  }

  async updateNpwp(body, token) {
    let result = OpResult.failed("Failed");
    try {
      let resApi = await this.patch("/customer/profile/npwp", body, token);
      if (resApi.status) {
        result = resApi;
      } else {
        message.error(resApi.message);
      }
    } catch (error) {
      result = OpResult.failed("Internal Error");
    }
    return result;
  }

  async updatePropic(body, token) {
    let result = OpResult.failed("Failed");
    try {
      let resApi = await this.patch("/customer/profile/picture", body, token);
      if (resApi.status) {
        result = resApi;
      } else {
        message.error(resApi.message);
      }
    } catch (error) {
      result = OpResult.failed("Internal Error");
    }
    return result;
  }

  // update wa number
  async requestOtpUpdateWa(body, token) {
    let result = OpResult.failed("Failed");
    try {
      let resApi = await this.post(
        "/customer/profile/wa/request-otp",
        {
          old_wa_number: body.old_wa_number,
          new_wa_number: body.new_wa_number,
        },
        token
      );
      if (resApi.status) {
        result = resApi;
      } else {
        message.error(resApi.message);
      }
    } catch (error) {
      result = OpResult.failed("Internal Error");
    }
    return result;
  }


  // update wa number
  async submitChangeNewWaNumber(body, token) {
    let result = OpResult.failed("Failed");
    try {
      let resApi = await this.post(
        "/customer/reset-wa/new/validate-otp",
        body,
        token
      );
      if (resApi.status) {
        result = resApi;
      } else {
        message.error(resApi.message);
      }
    } catch (error) {
      result = OpResult.failed("Internal Error");
    }
    return result;
  }

  async resendOtp(body, token) {
    let result = OpResult.failed("Failed");
    try {
      let resApi = await this.post(
        "/customer/profile/wa/resend-otp",
        {},
        token
      );
      if (resApi.status) {
        result = resApi;
      } else {
        message.error(resApi.message);
      }
    } catch (error) {
      result = OpResult.failed("Internal Error");
    }
    return result;
  }

  async validateOtp(body, token) {
    let result = OpResult.failed("Failed");
    try {
      let resApi = await this.post(
        "/customer/profile/wa/validate-otp",
        {
          otp_code: body.otp_code,
        },
        token
      );
      if (resApi.status) {
        result = resApi;
      } else {
        message.error(resApi.message);
      }
    } catch (error) {
      result = OpResult.failed("Internal Error");
    }
    return result;
  }
}
