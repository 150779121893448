import { BaseApiRequest } from "./base-api";
import { OpResult } from "../helper/operation_result";
import axios from "axios";
import { message } from "antd";

const endPoint = process.env.REACT_APP_API_END_POINT

export default class OrderRentApi extends BaseApiRequest {
  constructor() {
    super(process.env.REACT_APP_API_END_POINT);
  }

  async getVType(payload) {
    let result = OpResult.failed("Failed refreshTokenCustomer");
    try {
      let resApi = await this.post(
        "/customer/order/rent/find_subtype",
        payload
      );
      if (resApi.status) {
        result = resApi;
      }
    } catch (e) {
      result = OpResult.failed("Internal Error");
    }
    return result;
  }

  async getPartnerService(payload) {
    let result = OpResult.failed("Failed getPartnerService");
    try {
      let resApi = await this.post(
        "/customer/order/rent/find_partner_service",
        payload
      );
      if (resApi.status) {
        result = resApi;
      }
    } catch (e) {
      result = OpResult.failed("Internal Error");
    }
    return result;
  }

  // customer special
  async getPartnerServiceOfficial(payload, token) {
    let result = OpResult.failed("Failed getPartnerServiceOfficial");
    try {
      let resApi = await this.post(
        "/order_rent/find_special_vehicle",
        payload,
        token
      );
      if (resApi.status) {
        result = resApi;
      }
    } catch (e) {
      result = OpResult.failed("Internal Error");
    }
    return result;
  }

  async findTotalPrice(payload, token) {
    let result = OpResult.failed("Failed findTotalPrice");
    try {
      let resApi = await this.post(
        "/customer/order/rent/find_total_price",
        payload,
        token
      );
      if (resApi.status) {
        result = resApi;
      }
      console.log("total price", result);
    } catch (e) {
      result = OpResult.failed("Internal Error");
    }
    return result;
  }

  async startMutation(id, payload, token) {
    let result = OpResult.failed("Failed startMutation");
    try {
      let res = await axios.put(
        `${endPoint}/customer/order/rent/mutation/${id}/manage`,
        payload,
        {headers: { authorization: `Bearer ${token}` },}
      );
      if (res.status) {
        result = res;
      }
      message.success(result?.data?.message)
    } catch (e) {
      result = OpResult.failed("Internal Error");
    }
    return result;
  }

  async finishMutation(id, payload, token) {
    let result = OpResult.failed("Failed finishMutation");
    try {
      let res = await axios.put(
        `${endPoint}/customer/order/rent/mutation/${id}/finish`,
        payload,
        {headers: { authorization: `Bearer ${token}` },}
      );
      if (res.status) {
        result = res;
      }
      message.success(result?.data?.message)
    } catch (e) {
      result = OpResult.failed("Internal Error");
    }
    return result;
  }

  async getHistoryPO(id) {
    let result = OpResult.failed("Failed getHistoryPO");
    try {
      let resApi = await this.get(
        `/customer/order/rent/${id}/po_history`,
      );
      if (resApi.status) {
        result = resApi;
      }
      // console.log("History PO", result);
    } catch (e) {
      result = OpResult.failed("Internal Error");
    }
    return result;
  }

  async getInfoPengantaranMutasi(id) {
    let result = OpResult.failed("Failed getPengantaranMutasi");
    try {
      let resApi = await this.get(
        `/customer/order/rent/${id}/mutation`,
      );
      if (resApi.status) {
        result = resApi;
      }
      // console.log("Pengantaran Mutasi", result);
    } catch (e) {
      result = OpResult.failed("Internal Error");
    }
    return result;
  }

  async createOrder(payload, token) {
    let result = OpResult.failed("Failed createOrder");
    try {
      let resApi = await this.post(
        "/customer/order/rent/create_order",
        payload,
        token
      );
      if (resApi.status) {
        result = resApi;
      } else {
        result = OpResult.failed(resApi.message);
      }
    } catch (e) {
      result = OpResult.failed("Internal Error");
    }
    return result;
  }
}
