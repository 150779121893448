/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";
import { Dropdown, Button, Tabs, Tab } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import noData from "../../../assets/images/no-data.png";
import Table from "../../../components/table/customTable";
import { getBillingTransactionList, getCountNotifPengecekan, getCountNotifPembayaran } from "../../../redux/action/Finance/document_payment_action";
import { formatRupiah } from "../../../helper/formatCurrency";
import moment from "moment";

const Transaction = (props) => {
  /*======================== Props ======================== */

  const { search } = props;
  /*======================== Hooks  ======================== */

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  /*======================== Redux ======================== */

  const auth = useSelector((st) => st.authReducer);
  const dataBillingTransaction = useSelector((st) => st.documentPaymentReducer.data);
  const dataBillingCountPengecekan = useSelector((st) => st.documentPaymentReducer.countPengecekan);
  const dataBillingCountPembayaran = useSelector((st) => st.documentPaymentReducer.countPembayaran);
  const meta = useSelector((st) => st.documentPaymentReducer.meta);
  const loading = useSelector((st) => st.documentPaymentReducer.loading);

  /*======================== UseState ======================== */

  const [query, setQuery] = useState({
    limit: 10,
    page: 1,
    status: 0,
    sort_by: "created_at",
    order_by: "DESC",
    search:''
  });
  console.log(123,query)
  const [sort, setSort] = useState("Terbaru");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [data, setData] = useState(dataBillingTransaction || []);

  /*======================== Handler ======================== */

  const handleChange = (type, value) => {
    let newQuery = {}
    switch (type) {
      case "order_by":
        newQuery = { ...query, order_by: value === "Terbaru" ? "DESC" : "ASC", page:1 }
        setQuery({ ...query, order_by: value === "Terbaru" ? "DESC" : "ASC" })
        setPage(1);
        setSort(value);
        break;
      case "page":
        newQuery = { ...query, page: value }
        setQuery({ ...query, page: value })
        setPage(value);
        break;
      case "limit":
        newQuery = { ...query, limit: value, page: 1 }
        setQuery({ ...query, limit: value })
        setPage(1);
        setLimit(value);
        break;
      case "status":
        newQuery = { ...query, status: value, page: 1 }
        setQuery({ ...query, status: value })
        setPage(1);
        break;
      default:
        break;
    }
    dispatch(getBillingTransactionList(auth.access_token,newQuery));
  };

  const handleMapingStatus = (param,val) => {
    let result = {
      status: "-",
      color: ""
    }
    if(val === 1){
      result.status = "Menunggu Konfirmasi"
      result.color = "#FFAC14"
    }
    if(val === 2){
      result.status = "Proses Pengecekan"
      result.color = "#209AB7"
    }
    if(val === 3){
      result.status = "Proses Pembayaran"
      result.color = "#FF1D8C"
    }
    if(val === 4){
      result.status = "Penagihan Selesai"
      result.color = "#0FAA4B"
    }
    if(val === 5){
      result.status = "Penagihan Batal"
      result.color = "#FF4949"
    }
    if(param === 'status') return result.status
    if(param === 'color') return result.color
  }

  const handleMapingPaymentStatus = (param,val) => {
    let result = {
      status: "-",
      color: ""
    }
    if(val === 0){
      result.status = "Menunggu Anda"
      result.color = "#FFAC14"
    }
    if(val === 1 || val === 2){
      result.status = "Menunggu eLOG"
      result.color = "#FFAC14"
    }
    if(val === 3){
      result.status = "Berhasil"
      result.color = "#0FAA4B"
    }
    if(param === 'status') return result.status
    if(param === 'color') return result.color
  }

  /*======================== UseEffect ======================== */

  useEffect(() => {
    dispatch(getBillingTransactionList(auth.access_token, query));
    dispatch(getCountNotifPengecekan(auth.access_token));
    dispatch(getCountNotifPembayaran(auth.access_token));
  }, []);

  useEffect(() => {
    dispatch(getBillingTransactionList(auth.access_token, {...query, search, page: 1}));
    
    setQuery({...query, search, page: 1})
    setPage(1)
  }, [search]);

  useEffect(() => {
    // if (dataBillingTransaction.length > 0) {
    //   setData([...dataBillingTransaction.slice((page - 1) * limit, limit * page)]);
    // }
    setData(dataBillingTransaction);
  }, [dataBillingTransaction, query]);

  /*======================== Others ======================== */
  const statusOptions = [
    {
      title: "Semua",
      value: 0,
    },
    {
      title: "Konfirmasi",
      value: 1,
    },
    {
      title: "Proses Pengecekan",
      value: 2,
    },
    {
      title: "Proses Pembayaran",
      value: 3,
    },
    {
      title: "Selesai",
      value: 4,
    },
    {
      title: "Batal",
      value: 5,
    }
  ];
  const sortOptions = [
    {
      title: "Terbaru",
    },
    {
      title: "Terlama",
    },
  ];
  const tableProps = {
    bordered: false,
    expandable: true,
    title: undefined,
    showHeader: true,
    rowSelection: false,
  };
  const tableColumns = [
    {
      title: "Nomor Penagihan",
      sorter: false,
      className: "px-3",
      render: (_, { billing_number, is_flag_for_customer }) => {
        return {
          props: {
            style: { background: is_flag_for_customer && "#FEF5F6" }
          },
          children: <div className="d-flex justify-content-center align-items-center" style={{color:"#CE1771"}}>
            {is_flag_for_customer && <span className="me-1">&bull;</span>}
            #{billing_number}
          </div>
        };
      },
    },
    {
      title: "Tanggal Penagihan",
      sorter: false,
      render: (_, { created_at, is_flag_for_customer }) => {
        return {
          props: {
            style: { background: is_flag_for_customer && "#FEF5F6" }
          },
          children: <span>
            {moment(created_at).format("DD/MM/YYYY")}
          </span>
        };
      },
    },
    {
      title: "Nama Partner",
      sorter: false,
      className: "px-3",
      render: (_, { mst_partner, is_flag_for_customer }) => {
        return {
          props: {
            style: { background: is_flag_for_customer && "#FEF5F6" }
          },
          children: <span>
            {mst_partner.name}
          </span>
        };
      },
    },
    {
      title: "Tagihan Transaksi",
      sorter: false,
      className: "px-3",
      render: (_, { payment_amount_after_discount, trx_order_billing, is_flag_for_customer }) => {
        return {
          props: {
            style: { background: is_flag_for_customer && "#FEF5F6" }
          },
          children: <div className="d-flex flex-column">
            <span className="fw-bold">{formatRupiah(payment_amount_after_discount)}</span>
            <span>{trx_order_billing.length} Transaksi</span>
          </div>
        };
      },
    },
    {
      title: "Info Pembayaran",
      sorter: false,
      className: "px-3",
      render: (_, { payment_status, customer_payment_date , elog_payment_date, is_flag_for_customer  }) => {
        return {
          props: {
            style: { background: is_flag_for_customer && "#FEF5F6" }
          },
          children: <div className="d-flex flex-column">
          {elog_payment_date && payment_status === 3 && <span>{moment(elog_payment_date).format("DD/MM/YYYY HH:mm")}</span>}
          {customer_payment_date && (payment_status === 1 || payment_status === 2) && <span>{moment(customer_payment_date).format("DD/MM/YYYY HH:mm")}</span>}
          <span className="" style={{color: handleMapingPaymentStatus('color',payment_status)}}>{handleMapingPaymentStatus("status",payment_status)}</span>
        </div>
        };
      },
    },
    {
      title: "Status Pengecekan",
      sorter: false,
      className: "px-3",
      render: (_, { status, is_flag_for_customer  }) => {
        return {
          props: {
            style: { background: is_flag_for_customer && "#FEF5F6" }
          },
          children: <div className="d-flex flex-column">
            <span style={{color: handleMapingStatus('color', status)}}>{handleMapingStatus('status',status)}</span>
          </div>
        };
      },
    },
    {
      title: "Action",
      key: "action",
      sorter: false,
      className: "px-3",
      render: (_, { id, is_flag_for_customer }) => {
        return {
          props: {
            style: { background: is_flag_for_customer && "#FEF5F6" }
          },
          children: <div className="d-flex justify-content-end">
          <Button
            size="sm"
            className="fw-bold px-4 text-capitalize text-white"
            onClick={() =>
              history.push(location.pathname + '/' + id)
            }
          >
            Detail
          </Button>
        </div>
        };
      },
    },
  ];

  /*======================== Return ======================== */

  return (
    <>
      <div className="mt-4">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h2 className="fw-bold text-capitalize">Daftar Penagihan</h2>
            <p>Silahkan lakukan pengecekan dckumen dan pembayaran dari penagihan Partner</p>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between mb-3">
          <div className="d-flex align-items-center" style={{ gap: "1rem" }}>
            <span className="fw-bold mb-1" style={{ width: "auto" }}>
              Tampilkan:
            </span>
            <div className="fw-bold d-flex gap-2" style={{fontSize:"12px"}}>
            {statusOptions.map((item, i) => (
              <div 
                key={i}
                className="p-2 px-3 border pointer d-flex align-items-center gap-2" 
                style={{
                  color:`${item.value == query?.status ? "white" : "#666666"}`, 
                  borderRadius:"10px", 
                  backgroundColor:`${item.value == query?.status ? "#27C0CD" : ""}`}}
                onClick={()=> handleChange("status", item.value)}
              >
                {item.title}
                {item.title === "Proses Pengecekan" && dataBillingCountPengecekan ?
                  <div 
                    className="rounded-circle d-flex align-items-center justify-content-center"
                    style={{
                      width:"20px",
                      height:"20px",
                      backgroundColor:"#EB1717",
                    }}
                  >
                    <h6 className="m-0 text-white" style={{fontSize:"10px"}}>{dataBillingCountPengecekan}</h6>
                  </div>
                  :
                  null
                }
                {item.title === "Proses Pembayaran" && dataBillingCountPembayaran ?
                  <div 
                    className="rounded-circle d-flex align-items-center justify-content-center"
                    style={{
                      width:"20px",
                      height:"20px",
                      backgroundColor:"#EB1717",
                    }}
                  >
                    <h6 className="m-0 text-white" style={{fontSize:"10px"}}>{dataBillingCountPembayaran}</h6>
                  </div>
                  :
                  null
                }
              </div>
            ))}
              </div>
            {/* <Tabs
              className="tabs-rute mb-2 border-0"
              style={{ gap: "10px" }}
              onSelect={(value) => handleChange("status", value)}
            >
              {statusOptions.map((item, index) => (
                <Tab key={index} eventKey={item.value} title={item.title} />
              ))}
            </Tabs> */}
          </div>
        
          <div className="d-flex align-items-center">
            <h4 className="mt-2 me-2" style={{ fontWeight: "600" }}>
              Urutkan Berdasarkan :
            </h4>
            <Dropdown className="d-inline mx-2">
              <Dropdown.Toggle
                id="dropdown-autoclose-true"
                size="sm"
                variant="light"
                className="bg-white text-secondary px-3 border shadow-sm rounded"
              >
                <strong>{sort}</strong>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {sortOptions.map((item, index) => (
                  <Dropdown.Item
                    key={index}
                    href="#"
                    onClick={() => {
                      handleChange("order_by", item.title);
                    }}
                  >
                    {item.title}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
      {/* with data */}
      {data.length > 0 ? (
        <div className="mt-2">
          <Table
            classProps="table-rute"
            {...{
              tableProps,
              tableColumns,
              handleChange,
              limit,
              page,
              loading,
              data,
            }}
            total_data={meta.total_data}
          />
        </div>
      ) : (
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{ marginTop: "5rem" }}
        >
          <img src={noData} alt="no-data" />
          <p className="mt-3">
            {search
              ? "data yang anda cari tidak ditemukan"
              : "Anda belum memiliki penagihan dari partner"}
          </p>
        </div>
      )}
    </>
  );
};

Transaction.propTypes = {
  search: PropTypes.string,
};

export default Transaction;
