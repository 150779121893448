import React from "react";
import {
    Modal,
    Row, 
    Col
  } from "react-bootstrap";
import moment from "moment";


const ModalMulaiMutasi = ({
    setPayloadMulai,
    payloadMulai,
    trxDetail,
    handleNextMulai,
    show,
    handleClose,
    handleShow
}) => {

  return(
    <>  
        <div 
            className="d-flex justify-content-center align-items-center rounded"
            style={{backgroundColor:"#FF1D8C", cursor:"pointer", height:"40px", width:"98%"}}
            onClick={()=>handleShow()}
        >
            <h6 className="m-0" style={{color:"#FFFFFF"}}><strong>Mulai Mutasi Kendaraan</strong></h6>
        </div>

        <Modal
            contentClassName="rounded-card p-2"
            show={show}
            onHide={handleClose}
            centered
        >
            <Modal.Header closeButton className="bg-white">
                <strong>Konfirmasi Mutasi Kendaraan</strong>
            </Modal.Header>

            <div className="p-4">
                <div className="">
                    <h6 className="fw-bold mb-3">Harga Layanan</h6>
                    <h6 className="text-muted">{trxDetail?.data?.trx_booking_rent?.trx_booking_rent_vehicle?.mst_vehicle?.mst_vehicle_brand?.name} {trxDetail?.data?.trx_booking_rent?.trx_booking_rent_vehicle?.mst_vehicle?.mst_vehicle_model?.name} ({trxDetail?.data?.trx_booking_rent?.trx_booking_rent_vehicle?.mst_vehicle?.plate_number})</h6>
                </div>
                <div className="border-bottom my-3"></div>
                <div className="">
                    <h6 className="fw-bold mb-3">Alamat Mutasi</h6>
                    <h6 className="text-muted">{trxDetail?.data?.trx_booking_rent?.customer_active_mutation?.return_address}</h6>
                </div>
                <div className="border-bottom my-3"></div>
                <Row>
                    <Col>
                        <h6 className="fw-bold mb-3">Mulai Mutasi</h6>
                        <h6 className="text-muted">{moment(trxDetail?.data?.trx_booking_rent?.customer_active_mutation?.mutation_date).utc().format("DD MMM YYYY")}</h6>
                    </Col>
                    <Col>
                        <h6 className="fw-bold mb-3">Estimasi Sampai</h6>
                        <h6 className="text-muted">{moment(trxDetail?.data?.trx_booking_rent?.customer_active_mutation?.expected_deliver_date).utc().format("DD MMM YYYY")}</h6>
                    </Col>
                </Row>
                <div className="border-bottom my-3"></div>
                <Row>
                    <h6 className="fw-bold mb-3">Pilih Pengantaran Mutasi</h6>
                    <Col className="d-flex">
                        <input type="checkbox" name="type" checked={payloadMulai === 1 ? true : false} onClick={()=> setPayloadMulai(1)} className="me-2" style={{cursor:"pointer", marginTop:"-7px"}}/>
                        <h6 className="text-muted">Pengantaran oleh Anda</h6>
                    </Col>
                    <Col className="d-flex">
                        <input type="checkbox" name="type" checked={payloadMulai === 0 ? true : false} onClick={()=> setPayloadMulai(0)} className="me-2" style={{cursor:"pointer", marginTop:"-7px"}}/>
                        <h6 className="text-muted">Pengantaran oleh Partner</h6>
                    </Col>
                </Row>
                
                <div className="p-2 rounded mt-4" style={{background:"#EAF4F6"}}>
                    <h6 className="text-muted m-0" style={{fontSize:"9px"}}>Silahkan lakukan pengecekan supaya tidak terjadi kesalahan proses mutasi kendaraan.</h6>                    
                </div>
            </div>

            <Modal.Footer className={`d-flex  align-items-center`}>
                <button 
                    className="rounded-pill py-1 px-4 m-0"
                    // status 3 itu masih null (sementara)
                    disabled={payloadMulai === 3 ? true : false}
                    style={payloadMulai === 3 ? 
                        {backgroundColor:"#E2E5E8", cursor:"not-allowed", color:"white", fontSize:"14px", border:"none"}:
                        {backgroundColor:"#FF1D8C", cursor:"pointer", color:"white", fontSize:"14px", border:"none"}
                    }
                    onClick={()=> handleNextMulai()}
                >
                    Selanjutnya
                </button>
            </Modal.Footer>

        </Modal>
    </>
   
  );
}

export default ModalMulaiMutasi;
