const initState = {
    isLoading: false,
    meta: {},
    data: [],
    countPengecekan: 0,
    countPembayaran: 0,
    detail: {},
    detailPartnerDocument: [],
    detailAdditionalData: [],
    paymentList: []
  };
  
  export const documentPaymentReducer = (state = initState, action) => {
    switch (action.type) {
      case "SET_BILLING_TRANSACTION_LIST":
        return { ...state, data: action.payload?.data };
      case "SET_BILLING_COUNT_PENGECEKAN":
        return { ...state, countPengecekan: action.payload?.data };
      case "SET_BILLING_COUNT_PEMBAYARAN":
        return { ...state, countPembayaran: action.payload?.data };
      case "SET_META":
        return { ...state, meta: action.payload?.data };
      case "SET_BILLING_TRANSACTION_DETAIL":
        return { ...state, detail: action.payload?.data };
      case "SET_PARTNER_DOCUMENT":
        return { ...state, detailPartnerDocument: action.payload?.data };
      case "SET_ADDITIONAL_DATA":
        return { ...state, detailAdditionalData: action.payload?.data };
      case "SET_PAYMENT_LIST":
        return { ...state, paymentList: action.payload?.data };
      default:
        return state;
    }
  };
  