import { BaseApiRequest } from "./base-api";
import { OpResult } from "../helper/operation_result";
import { message } from "antd";
import axios from "axios";

export default class BalanceApi extends BaseApiRequest {
  constructor() {
    super(process.env.REACT_APP_API_END_POINT);
  }

  async getRekeningList(token) {
    let result;
    try {
      let resApi = await axios.get(`${this.endpoint}/customer/balance/bank`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      result = resApi.data;
    } catch (e) {
      console.log(e.response);
      // message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async getDisburementMethodList() {
    let result;
    try {
      let resApi = await axios.get(`${this.endpoint}/disbursement/list`, {
        // headers: { Authorization: `Bearer ${token}` },
      });

      result = resApi.data;
    } catch (e) {
      console.log(e.response);
      // message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async getDisbursementHistory(token, query) {
    let result;
    try {
      let resApi = await axios.get(
        `${this.endpoint}/customer/balance/history?${query}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      result = resApi.data;
    } catch (e) {
      console.log(e.response);
      // message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async validateBankAccount(token, body) {
    let result;
    try {
      let resApi = await axios.post(
        `${this.endpoint}/customer/balance/bank/validate`,
        {
          disbursement_method_id: body.disbursement_method_id,
          account_number: body.account_number,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      result = resApi.data;
    } catch (e) {
      console.log(e.response);
      message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async createBankAccount(token, body) {
    let result;
    try {
      let resApi = await axios.post(
        `${this.endpoint}/customer/balance/bank`,
        {
          disbursement_method_id: body.disbursement_method_id,
          account_number: body.account_number,
          is_main: body.is_main,
          otp: body.otp,
          otp_id: body.otp_id // add for otp engine
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      result = resApi.data;
    } catch (e) {
      console.log(e.response);
      message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async deleteBankAccount(token, id) {
    let result;
    try {
      let resApi = await axios.delete(
        `${this.endpoint}/customer/balance/bank/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      result = resApi.data;
    } catch (e) {
      console.log(e.response);
      message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async setMainBankAccount(token, id) {
    let result;
    try {
      let resApi = await axios.patch(
        `${this.endpoint}/customer/balance/bank/${id}/main`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      result = resApi.data;
    } catch (e) {
      console.log(e.response);
      message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async createDisbursement(token, body) {
    let result;
    try {
      let resApi = await axios.post(
        `${this.endpoint}/customer/balance/disburse/`,
        {
          amount: body.amount,
          password: body.password,
          rekening_id: body.rekening_id,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      result = resApi.data;
    } catch (e) {
      console.log(e.response);
      message.error(e?.response?.data?.message);

      result = e.response;
    }
    return result;
  }

  async resendOtpBankAccount(token, body) {
    let result;
    try {
      let resApi = await axios.post(
        `${this.endpoint}/customer/balance/bank/resend-otp`,
        {
          otp: body.otp, // sms/wa
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      result = resApi.data;
    } catch (e) {
      console.log(e.response);
      message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async submitOtpBankAccount(token, body) {
    let result;
    try {
      let resApi = await axios.post(
        `${this.endpoint}/customer/balance/bank/otp`,
        {
          otp: body.otp, // sms/wa
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      result = resApi.data;
    } catch (e) {
      console.log(e.response);
      message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }
}
