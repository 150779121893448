import React, {useState, useEffect} from "react";
import {
    Modal,
    Row, 
    Col
  } from "react-bootstrap";

  import { BsChevronLeft } from "react-icons/bs";


const ModalSelesaiMutasi = ({
    handleNextSelesai,
    handleInstruksiDokumentasi,
    show,
    handleClose,
    handleShow,
    submit
}) => {


  return(
    <>  
        <div 
            className="d-flex justify-content-center align-items-center rounded" 
            style={{border:"2px solid #FF1D8C", cursor:"pointer", height:"40px", width:"98%"}}
            onClick={()=>handleShow()}
        >
            <h6 className="m-0" style={{color:"#FF1D8C"}}><strong>Selesai Mutasi Kendaraan</strong></h6>
        </div>

        <Modal
            contentClassName="rounded-card p-2"
            show={show}
            onHide={handleClose}
            centered
        >
            <Modal.Header closeButton className="bg-white">
                <strong className="me-3">Konfirmasi Selesai Mutasi</strong>
            </Modal.Header>
            
            <div className="p-4">
                <h6 className="text-muted">Sebelum menyelesaikan mutasi, anda akan melakukan input data dan upload dokumen kendaraan sebagai pembaruan kondisi kendaraan saat perpindahan alamat sewa.</h6>
                <div className="p-2 rounded mt-1" style={{background:"#EAF4F6"}}>
                    <h6 className="text-muted m-0" style={{fontSize:"9px"}}>Lanjutkan jika anda sudah memiliki dokumentasi untuk proses upload, pastikan anda sudah melakukan dokumentasi kendaraan sesuai dengan <span onClick={()=>handleInstruksiDokumentasi()} className="fw-bold" style={{color:"#FF1D8C", textDecoration:"underline", cursor:"pointer"}}>Instruksi Dokumentasi</span></h6>                    
                </div>
            </div>

            <Modal.Footer>
                <button 
                    className="py-1 px-3 m-0 fw-bold"
                    style={{backgroundColor:"white", color:"#FF1D8C", cursor:"pointer", fontSize:"14px", border:"none"}}
                    onClick={()=>submit("skip")}
                    >
                    Langsung Selesaikan
                </button>
                <button 
                    className="rounded-pill py-1 px-4 m-0"
                    style={{backgroundColor:"#FF1D8C", cursor:"pointer", color:"white", fontSize:"14px", border:"none"}}
                    onClick={()=>handleNextSelesai('next')}
                    >
                    Mulai Dokumentasi
                </button>
            </Modal.Footer>

        </Modal>
    </>
   
  );
}

export default ModalSelesaiMutasi;
