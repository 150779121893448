import React from 'react'
import { Row, Col } from 'react-bootstrap'
import moment from 'moment'
import ProofPayment from '../Modal/ProofPayment'

const InfoPayment = ({
  data, 
  modalProofPayment, 
  setModalProofPayment, 
  setModalChangeReceipt, 
  payloadBayarTagihan
}) => {
  return (
    <div className="mb-3">
      <h5 className="fw-bold">Info Pembayaran</h5>
      <div className="bg-white rounded-card w-full p-3" style={{borderRadius:"20px 20px 0 0", borderBottom:"1px solid #E5E5E5", position:"relative"}}>
        <Row>
          <Col xs={6}>
            <div className="px-2">
              <p className="text-muted label mb-1">Waktu Pembayaran</p>
              <p className="mb-0">{data?.customer_payment_date ? moment(data?.customer_payment_date).format("DD/MM/YYYY HH:mm") : "-"}</p>
            </div>
          </Col>
          <Col xs={6}>
            <div className="px-2">
              <p className="text-muted label mb-1">Bukti Pembayaran</p>
              <p className="mb-0">
                { data.payment_status >= 1 ?
                  <ProofPayment 
                    button 
                    show={modalProofPayment}
                    setShow={setModalProofPayment} 
                    setModalChangeReceipt={setModalChangeReceipt}
                    payload={payloadBayarTagihan}
                  /> : 
                  "-"
                }
              </p>
            </div>
          </Col>
        </Row>
      </div>
      <div className="bg-white rounded-card w-full p-3" style={{borderRadius:"0 0 20px 20px"}}>
        <Row>
          <Col xs={12}>
            <div className="px-2">
              <p className="text-muted label mb-1">Metode Pembayaran</p>
              <p className="mb-0">{data?.customer_payment_method  ?? "-"}</p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default InfoPayment