import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { resetAuth } from "../../redux/action/auth_action";
import { message } from "antd";
import PinInput from "react-pin-input";
import Countdown from "react-countdown";
import BalanceApi from "../../api/balance-api";
import { getRekeningList } from "../../redux/action/balance_action";

import { MdChevronRight, MdClose } from "react-icons/md";
import { BsFillCheckCircleFill } from "react-icons/bs";
import icon_phone_circle_blue from "../../assets/images/icon_phone_circle_blue.svg";

const OtpBankAccount = ({ show, closeModal, setShowModal }) => {
  const [countdown, setCountdown] = useState(Date.now() + 30000);
  const [otp_code, setOtpCode] = useState("");
  const auth = useSelector((st) => st.authReducer);
  const dispatch = useDispatch();

  const Completionist = () => (
    <h6
      className="text-primary underline pointer"
      onClick={() => handleResendOtp()}
    >
      Kirim ulang kode
    </h6>
  );

  // Renderer callback with condition
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <h6 className="text-muted">Kirim ulang kode dalam {seconds} detik</h6>
      );
    }
  };

  const handleResendOtp = async () => {
    try {
      const api = new BalanceApi();
      let body = {};
      body["otp"] = "wa";
      const response = await api.resendOtpBankAccount(auth.access_token, body);

      if (response.status === 401) {
        dispatch(resetAuth());
      }

      if (response.status === "success") {
        message.success(response.message);
      }

      setCountdown(Date.now() + 30000);
    } catch (error) {
      console.log(error.response);
    }
  };

  const validateOtp = async () => {
    try {
      const api = new BalanceApi();

      let body = {};
      body["otp"] = otp_code;

      const response = await api.submitOtpBankAccount(auth.access_token, body);

      console.log("otp", response);

      if (response.status === 401) {
        dispatch(resetAuth());
      }

      if (response.status === "success") {
        message.success(response.message);
        setShowModal({ success: true });
        dispatch(getRekeningList(auth.access_token));
      }
    } catch (error) {
      console.log(error.response);
    }
  };

  return (
    <Modal
      contentClassName="rounded-card p-2"
      show={show}
      onHide={() => console.log("")}
      className="p-3"
      centered
    >
      <div className="d-flex p-2">
        <p className="fw-bold">Tambah Rekening</p>
        {/* <MdClose className="ms-auto me-2 pointer" onClick={closeModal} /> */}
      </div>
      <Modal.Body>
        <div className="">
          <img src={icon_phone_circle_blue} className="mb-2" alt="" />
          <h4 className="fw-bold">Lakukan Verifikasi OTP</h4>
          <h6 className="text-muted">
            Masukan Kode OTP yang sudah dikirim ke nomor handphone{" "}
            <span className="fw-bold">+{auth.wa_number}</span>
          </h6>
          <div className="d-flex justify-content-center align-items-center flex-column">
            <PinInput
              length={4}
              initialValue=""
              secret={false}
              onChange={(value, index) => {
                setOtpCode(value);
              }}
              type="numeric"
              inputMode="number"
              style={{ padding: "10px" }}
              inputStyle={{ borderColor: "#adb5bd", borderRadius: "10px" }}
              inputFocusStyle={{ borderColor: "#27c0cd" }}
              onComplete={(value, index) => {}}
              autoSelect={true}
              regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
            />
            <Countdown key={countdown} date={countdown} renderer={renderer} />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={otp_code ? false : true}
          size="sm"
          variant="primary"
          className="rounded-pill px-4 py-2"
          onClick={() => {
            validateOtp();
          }}
        >
          Verifikasi
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default OtpBankAccount;
