import React, { useState } from "react";
import { Row, Col, Card, Modal, Tabs, Tab } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import {
  updatePickupDetail,
  findTotalPrice,
} from "../../redux/action/sewakendaraan_action";
import util from "../../helper/util";
import moment from "moment";

// images
import siCepat from "../../assets/images/partner-anteraja.svg";
import noDriver from "../../assets/images/no-driver.svg";
import iconService from "../../assets/images/icon-service.svg";
import iconUser from "../../assets/images/icon-user.svg";

// icons
import { IoChevronDown, IoCheckmark, IoStar } from "react-icons/io5";
import { BsShieldFillCheck } from "react-icons/bs";

const thousandSeparatorPure = (periodType, estimated_price, type) => {
  let value = 0;
  try {
    switch (periodType) {
      case "DAILY":
        value =
          type === "lo" ? estimated_price.lo_daily : estimated_price.hi_daily;
        break;
      case "WEEKLY":
        value =
          type === "lo" ? estimated_price.lo_weekly : estimated_price.hi_weekly;
        break;
      case "MONTHLY":
        value =
          type === "lo"
            ? estimated_price.lo_monthly
            : estimated_price.hi_monthly;
        break;
      default:
        value = 0;
        break;
    }
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  } catch (e) {
    return null;
  }
};

function PickDriver(props) {
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // const totalDate = () => {
  //   var startDate = moment(props.sewaKendaraanReducer.startDate);
  //   var endDate = moment(props.sewaKendaraanReducer.endDate);
  //   var diff = moment.duration(endDate.diff(startDate));
  //   var total =
  //     Math.floor(diff.asWeeks()) + " Minggu, " + (diff.days() % 7) + " Hari.";

  //   return total;
  // };

  const showAdditionalInfo = (mst_partner_service_pricing) => {
    let component = [];
    if (mst_partner_service_pricing) {
      if (mst_partner_service_pricing.driver_availability_rent === 1) {
        component.push(
          <li>
            Layanan Supir{" "}
            <span className="float-end">
              {mst_partner_service_pricing.driver_price_rent > 0
                ? `+ Rp ${parseFloat(
                    mst_partner_service_pricing.driver_price_rent || 0
                  )
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`
                : "Gratis"}
            </span>
          </li>
        );
      }
      if (mst_partner_service_pricing.helper_availability_send === 1) {
        component.push(
          <li>
            Bantuan Supir{" "}
            <span className="float-end">
              {mst_partner_service_pricing.helper_price_rent_send > 0
                ? `+ Rp ${parseFloat(
                    mst_partner_service_pricing.helper_price_rent_send
                  )
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`
                : "Gratis"}
            </span>
          </li>
        );
      }
      if (mst_partner_service_pricing.helper_availability_rent === 1) {
        component.push(
          <li>
            Tenaga Bantu{" "}
            <span className="float-end">
              {mst_partner_service_pricing.helper_price_rent > 0
                ? `+ Rp ${parseFloat(
                    mst_partner_service_pricing.helper_price_rent
                  )
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`
                : "Gratis"}
            </span>
          </li>
        );
      }
    }
    return component;
  };
  return (
    <>
      <Card
        className="border-0 mb-1 pointer"
        onClick={() => {
          if (
            props.partnerService.data.length > 0 ||
            props.officialPartnerService?.data?.length > 0
          ) {
            handleShow();
          }
        }}
      >
        {props.partnerService.isLoading ? (
          <span className="h6 mt-2 ms-2 text-muted">Sedang memuat data...</span>
        ) : props.partnerService.data.length === 0 &&
          props.officialPartnerService?.data?.length === 0 ? (
          <span className="h6 mt-2 ms-2 text-muted">
            Mohon pilih jenis kendaraan terlebih dahulu
          </span>
        ) : !props.selectedPartnerService.id ? (
          <div className="d-flex p-2">
            <div className="choose-type w-100">
              <img src={iconService} alt="" className="me-2" /> Pilih Partner
            </div>
            <div>
              <IoChevronDown />
            </div>
          </div>
        ) : (
          <Row className="card-body py-2">
            <Col xs={3} className="align-self-center">
              <img
                src={
                  props.selectedPartnerService.mst_partner.profile_picture ||
                  iconService
                }
                alt=""
                className={`img-fluid ${
                  !props.selectedPartnerService.mst_partner.profile_picture &&
                  "w-50"
                } `}
              />
            </Col>
            <Col xs={8} className="align-self-center">
              <div className="d-flex fs-13">
                <span className="fw-bold">
                  {props.selectedPartnerService.mst_partner.name}
                </span>
                <span className="mx-2 opacity-25">|</span>
                <span className="fw-bold" style={{ marginTop: "-2px" }}>
                  <IoStar style={{ color: "#f69e0a" }} />{" "}
                  {props.selectedPartnerService.mst_partner.rating || "-"}
                </span>
              </div>
              <h6 className="mb-0 text-muted">
                (Armada{" "}
                {props.selectedPartnerService.mst_partner_service_pricing
                  ?.driver_availability_rent === 1
                  ? "Tersedia"
                  : "tidak Tersedia"}
                )
              </h6>
              <h6 className="mb-0 text-muted">
                Estimasi Harga: Rp{" "}
                {thousandSeparatorPure(
                  props.periodType,
                  props.selectedPartnerService.estimated_price?.lo
                )}{" "}
                - Rp{" "}
                {thousandSeparatorPure(
                  props.periodType,
                  props.selectedPartnerService.estimated_price,
                  "hi"
                )}
              </h6>
            </Col>
            <Col xs={1} className="align-self-center">
              <IoChevronDown />
            </Col>
          </Row>
        )}
      </Card>
      <Modal
        contentClassName="modal-100 p-1"
        show={show}
        onHide={handleClose}
        className=""
        centered
      >
        <Modal.Header closeButton className="bg-white">
          <strong>Pilih Armada Pengiriman</strong>
        </Modal.Header>
        <Tabs defaultActiveKey="1" className="tabs-partner mb-2">
          <Tab eventKey="1" title="Semua">
            <div className="mdl-fleet-ctn">
              {props.partnerService.data?.map((data, i) => (
                <Card
                  key={i}
                  className={
                    props.selectedPartnerService &&
                    props.selectedPartnerService.id === data.id
                      ? "border-fleet border-secondary"
                      : "border-fleet"
                  }
                  onClick={(e) => {
                    dispatch(
                      updatePickupDetail({
                        isSpecialPartnerSelected: false,
                        selectedPartnerService: data,
                        // detailOrder: {
                        //   driver_availability_rent: 0,
                        //   helper_availability_send: 0,
                        //   helper_availability_rent: 0,
                        //   driver_price_rent: 0,
                        //   helper_price_rent_send: 0,
                        //   helper_price_rent: 0,
                        //   driver_availability_rent_selected: false,
                        //   helper_availability_send_selected: false,
                        //   helper_availability_rent_selected: false,
                        //   total_helper_requested: 1,
                        //   total_price: 0,
                        // },
                      })
                    );
                    // TANDAIN NANTI CEK LAGI
                    let totalPricePayload = {
                      vehicle_subtype_id: props.selectedVType.id,
                      partner_service_id: data.id,
                      pickup_time:
                        props.sewaKendaraanReducer.startDate.toISOString(),
                      return_time:
                        props.sewaKendaraanReducer.endDate.toISOString(),
                      driver: 0,
                      // driver_helper: 0,
                      helper: 0,
                    };
                    dispatch(findTotalPrice(totalPricePayload));
                    setShow(false);
                  }}
                >
                  <Row className="card-body py-2">
                    <Col xs={2} className="align-self-center">
                      <img
                        src={data.mst_partner.profile_picture || iconService}
                        alt=""
                        className={`img-fluid ${
                          !data.mst_partner.profile_picture && "w-50"
                        } `}
                      />
                    </Col>
                    <Col xs={9} className="align-self-center">
                      <div className="d-flex fs-13">
                        <span className="fw-bold">{data.mst_partner.name}</span>
                        <span className="mx-2 opacity-25">|</span>
                        <span className="fw-bold" style={{ marginTop: "-2px" }}>
                          <IoStar style={{ color: "#f69e0a" }} />{" "}
                          {data.mst_partner.rating || "-"}
                        </span>
                        <span className="ms-2">
                          (Armada{" "}
                          {data.mst_partner_service_pricing
                            ?.driver_availability_rent === 1
                            ? "Tersedia"
                            : "tidak Tersedia"}
                          )
                        </span>
                      </div>
                      <h6 className="text-muted">
                        Estimasi Harga:{" "}
                        <span className="fw-bold text-black">
                          Rp{" "}
                          {thousandSeparatorPure(
                            props.periodType,
                            data.estimated_price,
                            "lo"
                          )}{" "}
                          - Rp{" "}
                          {thousandSeparatorPure(
                            props.periodType,
                            data.estimated_price,
                            "hi"
                          )}
                        </span>
                      </h6>
                      <h6 className="text-muted">
                        Layanan{" "}
                        <span className="ms-2 text-success">
                          Terproteksi <BsShieldFillCheck />
                        </span>
                      </h6>
                      <ul className="mb-0 fs-12">
                        {showAdditionalInfo(data.mst_partner_service_pricing)}
                      </ul>
                    </Col>
                    <Col xs={1} className="align-self-center">
                      {data.isSelect ? (
                        <IoCheckmark className="text-secondary" />
                      ) : null}
                    </Col>
                  </Row>
                </Card>
              ))}
            </div>
          </Tab>
          <Tab eventKey="2" title="Favorit">
            <div className="mdl-fleet-ctn text-center">
              <div className="vertical-center">
                <img src={noDriver} alt="" />
                <h4 className="fw-bold mt-3">
                  Anda belum memiliki Driver Favorit
                </h4>
                <p>
                  Favoritkan pengemudi dari riwayat pemesanan Anda untuk
                  mengaktifkan fitur ini.”
                </p>
              </div>
            </div>
          </Tab>
          {props.auth.is_special_customer ? (
            <Tab eventKey="3" title="Mitra Resmi">
              <div className="mdl-fleet-ctn">
                {props.officialPartnerService.data?.map((data, i) => (
                  <Card
                    key={i}
                    className={
                      props.selectedPartnerService &&
                      props.selectedPartnerService.id === data.id
                        ? "border-fleet border-secondary"
                        : "border-fleet"
                    }
                    onClick={(e) => {
                      dispatch(
                        updatePickupDetail({
                          isSpecialPartnerSelected: true,
                          selectedPartnerService: data,
                          // detailOrder: {
                          //   driver_availability_rent: 0,
                          //   helper_availability_send: 0,
                          //   helper_availability_rent: 0,
                          //   driver_price_rent: 0,
                          //   helper_price_rent_send: 0,
                          //   helper_price_rent: 0,
                          //   driver_availability_rent_selected: false,
                          //   helper_availability_send_selected: false,
                          //   helper_availability_rent_selected: false,
                          //   total_helper_requested: 1,
                          //   total_price: 0,
                          // },
                        })
                      );
                      let totalPricePayload = {
                        vehicle_subtype_id: props.selectedVType.id,
                        partner_service_id: data.id,
                        pickup_time:
                          props.sewaKendaraanReducer.startDate.toISOString(),
                        return_time:
                          props.sewaKendaraanReducer.endDate.toISOString(),
                        driver: 0,
                        helper: 0,
                        customer_id: props.auth.id,
                      };
                      dispatch(findTotalPrice(totalPricePayload));
                      setShow(false);
                    }}
                  >
                    <Row className="card-body py-2">
                      <Col xs={2} className="align-self-center">
                        <img
                          src={data.mst_partner.profile_picture || iconService}
                          alt=""
                          className={`img-fluid ${
                            !data.mst_partner.profile_picture && "w-50"
                          } `}
                        />
                      </Col>
                      <Col xs={9} className="align-self-center">
                        <div className="d-flex fs-13">
                          <span className="fw-bold">
                            {data.mst_partner.name}
                          </span>
                          <span className="mx-2 opacity-25">|</span>
                          <span
                            className="fw-bold"
                            style={{ marginTop: "-2px" }}
                          >
                            <IoStar style={{ color: "#f69e0a" }} />{" "}
                            {data.mst_partner.rating || "-"}
                          </span>
                          <span className="ms-2">
                            (Armada{" "}
                            {data.mst_partner_service_pricing
                              ?.driver_availability_rent === 1
                              ? "Tersedia"
                              : "tidak Tersedia"}
                            )
                          </span>
                        </div>
                        <h6 className="text-muted">
                          Estimasi Harga:{" "}
                          <span className="fw-bold text-black">
                            Rp{" "}
                            {util.thousandSeparatorPure(
                              data?.estimated_price?.lo_monthly
                            )}{" "}
                            - Rp{" "}
                            {util.thousandSeparatorPure(
                              data.estimated_price?.hi_monthly
                            )}
                          </span>
                        </h6>
                        <h6 className="text-muted">
                          Layanan{" "}
                          <span className="ms-2 text-success">
                            Terproteksi <BsShieldFillCheck />
                          </span>
                        </h6>
                        <ul className="mb-0 fs-12">
                          {showAdditionalInfo(data.mst_partner_service_pricing)}
                        </ul>
                      </Col>
                      <Col xs={1} className="align-self-center">
                        {data.isSelect ? (
                          <IoCheckmark className="text-secondary" />
                        ) : null}
                      </Col>
                    </Row>
                  </Card>
                ))}
              </div>
            </Tab>
          ) : null}
        </Tabs>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => ({
  map: state.map,
  partnerService: state.sewaKendaraanReducer.partnerService,
  periodType: state.sewaKendaraanReducer.periodType,
  selectedVType: state.sewaKendaraanReducer.selectedVType,
  selectedPartnerService: state.sewaKendaraanReducer.selectedPartnerService,
  sewaKendaraanReducer: state.sewaKendaraanReducer,
  auth: state.authReducer,
  officialPartnerService: state.sewaKendaraanReducer.officialPartnerService,
});
export default connect(mapStateToProps)(PickDriver);
