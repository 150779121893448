import React from "react";
import { useSelector } from "react-redux";
import { Button, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import payment_done_rent from "../../../assets/images/payment_done_rent.svg";
import payment_done_send from "../../../assets/images/payment_done_send.svg";

const OnAccountV2 = () => {
  const history = useHistory();
  const auth = useSelector((st) => st.authReducer);
  const map = useSelector((st) => st.map);
  const activePayment = useSelector((st) => st.activePaymentReducer);
  const activePaymentReducer = useSelector((st) => st.activePaymentReducer);

  const handleClickButton = () => {
    if (map.activeMenu === "SewaKendaraan") {
      history.push({
        pathname: `/daftar-transaksi-sewa/${activePayment?.data?.order_id}`,
        state: { type: "RENT" },
      });
      // localStorage.setItem("type", "RENT");
    } else if (map.activeMenu === "KirimBarang") {
      history.push({
        pathname: `/daftar-transaksi-kirim/${activePayment?.data?.order_id}`,
        state: { type: "SEND_GOODS" },
      });
      // localStorage.setItem("type", "SEND_GOODS");
    }
  };

  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center text-center p-4"
      style={{ height: "80vh" }}
    >
      <img
        src={
          map.activeMenu === "SewaKendaraan"
            ? payment_done_rent
            : payment_done_send
        }
        alt=""
      />
      <h2>eLOG siap mengirimkan Paketmu!</h2>
      <h5 className="text-muted">
        Patner pengiriman eLOG dapat segara membantu anda!
      </h5>

      <h5 className="text-muted mt-4">Batas Akhir Pembayaran</h5>

      <h5 className="text-muted">
        Pembayaran akan dilakukan setelah layanan selesai
      </h5>

      <div className="mt-4 w-100 py-2">
        <div
          className="py-2"
          style={{
            display: "flex",
            justifyContent: "space-between",
            borderTop: "1px solid #E2E5E8",
            borderBottom: "1px solid #E2E5E8",
          }}
        >
          <p className="text-muted mb-0">Metode Pembayaran</p>
          <p className="fw-bold mb-0">{activePaymentReducer.payment_name}</p>
        </div>

        <div
          className="pt-2 pb-1"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <p className="text-muted mb-0">Nomor Virtual Accont</p>
          <p className="fw-bold mb-0">-</p>
        </div>

        <div
          className="pb-2 pt-1"
          style={{
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "1px solid #E2E5E8",
          }}
        >
          <p className="text-muted mb-0">Total Pembayaran</p>
          <p className="fw-bold mb-0">
            Rp{" "}
            {parseInt(activePaymentReducer.data.payment.amount || 0)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
          </p>
        </div>
      </div>

      <Button
        size="sm"
        className="mt-4 fw-bold py-2 rounded-pill w-100"
        onClick={handleClickButton}
      >
        Selesai
      </Button>
    </div>
  );
};

export default OnAccountV2;
