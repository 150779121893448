import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  InputGroup,
  FloatingLabel,
  Form,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import SideInfo from "./SideInfo";
import {
  getRekeningList,
  getDisbursementHistory,
} from "../../redux/action/balance_action";
import util from "../../helper/util";
import { disbursementHelper } from "../../helper/disbursement_type_helper";
import useBreakpoint from "../../components/responsiveUtils/useBreakpoint";

// modals
import WithdrawModal from "../../components/modalSaldo/WithdrawModal";
import PickBankAccount from "../../components/modalSaldo/PickBankAccount";
import EnsureWithdraw from "../../components/modalSaldo/EnsureWithdraw";

import icon_widthdraw_button from "../../assets/images/icon_widthdraw_button.svg";
import icon_money_red from "../../assets/images/icon_money_red.svg";
import icon_money_green from "../../assets/images/icon_money_green.svg";
import PasswordWithdraw from "../../components/modalSaldo/PasswordWithdraw";
import WithdrawInProcess from "../../components/modalSaldo/WithdrawInProcess";
import DateRangeTrxBalance from "../../components/modalDatePicker/DateRangeBalanceTrx";
import moment from "moment";

import qs from "query-string";

const Balance = () => {
  const { isMobile } = useBreakpoint();
  const dispatch = useDispatch();
  const auth = useSelector((st) => st.authReducer);
  const [amountDisburse, setAmountDisburse] = useState(null);
  const historyBalance = useSelector(
    (st) => st.balanceReducer.historyBalanceList
  );
  const [query, setQuery] = useState({ limit: 999 });
  const [showModal, setShowModal] = useState({
    withdraw: false,
    pickBank: false,
    ensure: false,
    password: false,
    success: false,
  });

  useEffect(() => {
    dispatch(getRekeningList(auth.access_token));
  }, []);

  useEffect(() => {
    const stringified = qs.stringify(query);

    dispatch(getDisbursementHistory(auth.access_token, stringified));
  }, [query]);

  const closeModal = () => {
    setShowModal({});
  };

  const handleDateFilter = (date) => {
    let dateQuery = {
      start_date: moment({ ...date.from }).format(),
      end_date: moment({ ...date.to }).format(),
    };
    setQuery({ ...query, ...dateQuery });
  };

  const clearDateFilter = () => {
    setQuery({
      ...query,
      start_date: undefined,
      end_date: undefined,
    });
  };

  const getHistoryDescription = (type, data) => {
    switch (type) {
      case "SEND_GOODS":
        return (
          <>
            <h6 className="text-muted">
              Transaksi Pengiriman Barang &bull; {data?.trx_order?.order_number}
            </h6>
          </>
        );
      case "RENT":
        return (
          <>
            <h6 className="text-muted">
              Pengembalian Deposit Sewa Kendaraan &bull;{" "}
              {data?.trx_order?.order_number}
            </h6>
          </>
        );
      case "DISBURSEMENT":
        return (
          <>
            <h6 className="text-muted">
              {data?.trx_disbursement?.disbursement_method_name} &bull;{" "}
              {data?.trx_disbursement?.account_number} &bull;{" "}
              {data?.trx_disbursement?.account_name}
            </h6>
          </>
        );
      case "DISBURSEMENT_REVERT":
        return (
          <>
            <h6 className="text-muted">
              {data?.trx_disbursement?.disbursement_method_name} &bull;{" "}
              {data?.trx_disbursement?.account_number} &bull;{" "}
              {data?.trx_disbursement?.account_name}
            </h6>
          </>
        );
      case "CANCEL_ORDER":
        return (
          <>
            <h6 className="text-muted">
              Pembatalan pesanan{" "}
              {data?.trx_order?.type_order === "RENT"
                ? "Sewa Kendaraan"
                : "Kirim Barang"}{" "}
              &bull; {data?.trx_order?.order_number}
            </h6>
          </>
        );
      case "REFERRAL":
        return (
          <>
            <h6 className="text-muted">Referal</h6>
          </>
        );
      default:
        break;
    }
  };

  const renderList = () => {
    // complex logic
    const render = [];
    let temp = [];

    for (let index = 0; index < historyBalance?.data.length; index++) {
      // if (index !== 0) {
      let formatted1 = moment(historyBalance?.data[index]?.created_at).format(
        "dddd, DD MMMM YYYY"
      );
      let formatted2 = moment(
        historyBalance?.data[index - 1]?.created_at
      ).format("dddd, DD MMMM YYYY");

      const isToday =
        moment().format("DD MMM YYYY") ===
        moment(historyBalance?.data[index - 1]?.created_at).format(
          "DD MMM YYYY"
        );

      if (formatted1 !== formatted2) {
        // loop ketika udah ganti hari
        render.push(
          <h3 className="fw-bold mt-4">{isToday ? "Hari ini" : formatted2}</h3>
        );

        temp.push(
          <div className="rounded-card bg-white w-100 p-4 mt-4">
            <Row>
              <Col xs={3} md={2} lg={1}>
                <img
                  src={
                    disbursementHelper.getStatus(
                      historyBalance?.data[index]?.type
                    ) === "out"
                      ? icon_money_red
                      : icon_money_green
                  }
                  alt=""
                />
              </Col>
              <Col style={{ marginLeft: "-30px" }}>
                <h5 className="fw-bold">
                  {disbursementHelper.getTitle(
                    historyBalance?.data[index]?.type
                  )}
                </h5>
                <h6 className="text-muted">
                  {moment(historyBalance?.data[index]?.created_at).format(
                    "dddd, DD MMMM YYYY, hh : mm"
                  )}
                </h6>
                <hr className="text-muted" />
                {getHistoryDescription(
                  historyBalance?.data[index]?.type,
                  historyBalance?.data[index]
                )}
              </Col>
            </Row>
          </div>
        );

        render.push(temp);
        temp = [];
      } else {
        // loop ketika masih sama hari

        temp.push(
          <div className="rounded-card bg-white w-100 p-4 mt-4">
            <Row>
              <Col xs={3} md={2} lg={1}>
                <img
                  src={
                    disbursementHelper.getStatus(
                      historyBalance?.data[index]?.type
                    ) === "out"
                      ? icon_money_red
                      : icon_money_green
                  }
                  alt=""
                />
              </Col>
              <Col style={{ marginLeft: "-30px" }}>
                <h5 className="fw-bold">
                  {" "}
                  {disbursementHelper.getTitle(
                    historyBalance?.data[index]?.type
                  )}
                </h5>
                <h6 className="text-muted">
                  {" "}
                  {moment(historyBalance?.data[index]?.created_at).format(
                    "dddd, DD MMMM YYYY, hh : mm"
                  )}
                </h6>
                <hr className="text-muted" />
                {getHistoryDescription(
                  historyBalance?.data[index]?.type,
                  historyBalance?.data[index]
                )}
              </Col>
            </Row>
          </div>
        );
      }
      // }
    }

    render.push(temp);

    return render;
  };

  return (
    <div
      style={{ width: "100%", padding: "30px" }}
      className="bg-ice min-vh-100"
    >
      <Row className="">
        <Col xs={12} lg={4}>
          <SideInfo />
        </Col>
        <Col className={`${isMobile && "mt-18rem"}`}>
          <h3 className="fw-bold mt-4">Pengaturan Saldo</h3>
          <h6 className="text-muted">
            Kamu dapat melihat riwayat saldo dan penarkan saldo disini.
          </h6>
          <div className="rounded-card bg-white w-100 p-4">
            <div className="d-flex w-100">
              <div className="">
                <h6>Total Saldo Aktif</h6>
                <h3 className="fw-bold">
                  Rp {util.thousandSeparatorPure(auth.balance)}
                </h3>
              </div>
              <Button
                className="ms-auto rounded-pill"
                onClick={() => setShowModal({ withdraw: true })}
              >
                <img src={icon_widthdraw_button} className="me-2" alt="" />
                Tarik Saldo
              </Button>
            </div>
          </div>
          <div className="rounded-card bg-white w-100 p-4 mt-2">
            <h4 className="fw-bold">Riwayat Transaksi</h4>
            <hr className="text-muted" />
            <Row>
              <Col xs={6} lg={5}>
                <DateRangeTrxBalance
                  handleDateFilter={handleDateFilter}
                  clearDateFilter={clearDateFilter}
                />
              </Col>
              <Col xs={6} lg={5}>
                <InputGroup
                  className="border rounded-card"
                  style={{ width: "90%" }}
                >
                  {/* <FloatingLabel
                    className="rounded-card"
                    label="Jenis Transaksi"
                    style={{ fontSize: "12px", width: "100%" }}
                  > */}
                  <Form.Select
                    type="text"
                    className="border-0 rounded-card"
                    placeholder="m"
                    onChange={(ev) => {
                      setQuery({ ...query, money: ev.target.value });
                    }}
                    // defaultValue={auth?.name}
                    style={{ minHeight: "50px", fontSize: "11px" }}
                  >
                    <option disabled selected>
                      Jenis Transaksi
                    </option>
                    <option value={undefined}>Semua</option>
                    <option value="in">Dana Masuk</option>
                    <option value="out">Dana Keluar</option>
                  </Form.Select>
                  {/* </FloatingLabel> */}
                </InputGroup>
              </Col>
            </Row>
          </div>
          <div className="scrollable" style={{ height: "80vh" }}>
            {renderList()}
          </div>
          {/* <h3 className="fw-bold mt-4">Hari Ini</h3>
          <div className="rounded-card bg-white w-100 p-4 mt-2">
            <Row>
              <Col xs={1}>
                <img src={icon_money_red} alt="" />
              </Col>
              <Col style={{ marginLeft: "-30px" }}>
                <h5 className="fw-bold">Penarikan Dana</h5>
                <h6 className="text-muted">09 Dest 2021, 15 : 30</h6>
                <hr className="text-muted" />
                <h6 className="text-muted">
                  PT.BCA (Bank Central Asia) TBK/BCA
                </h6>
              </Col>
            </Row>
          </div>
          <div className="rounded-card bg-white w-100 p-4 mt-2">
            <Row>
              <Col xs={1}>
                <img src={icon_money_green} alt="" />
              </Col>
              <Col style={{ marginLeft: "-30px" }}>
                <h5 className="fw-bold">Refund</h5>
                <h6 className="text-muted">09 Dest 2021, 15 : 30</h6>
                <hr className="text-muted" />
                <h6 className="text-muted">
                  Pengembalian dana Pengiriman Barang &bull; #RCKSM342324
                </h6>
              </Col>
            </Row>
          </div> */}
        </Col>
      </Row>
      <WithdrawModal
        show={showModal.withdraw}
        closeModal={closeModal}
        setShowModal={setShowModal}
        setAmountDisburse={setAmountDisburse}
        amountDisburse={amountDisburse}
      />
      <PickBankAccount
        show={showModal.pickBank}
        closeModal={closeModal}
        setShowModal={setShowModal}
      />
      <EnsureWithdraw
        show={showModal.ensure}
        closeModal={closeModal}
        setShowModal={setShowModal}
        amountDisburse={amountDisburse}
      />
      <PasswordWithdraw
        show={showModal.password}
        closeModal={closeModal}
        setShowModal={setShowModal}
        amountDisburse={amountDisburse}
      />
      <WithdrawInProcess
        show={showModal.success}
        closeModal={closeModal}
        setShowModal={setShowModal}
      />
    </div>
  );
};

export default Balance;
