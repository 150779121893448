import React, { useState } from "react";
import {
  Modal,
  Form,
  InputGroup,
  FloatingLabel,
  Button,
} from "react-bootstrap";
import { Upload, message } from "antd";
import { previewImage } from "../../helper/imgPreview";
import InfoBar from "../infoBar/InfoBar";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { resetAuth, setAuth } from "../../redux/action/auth_action";
import ProfileApi from "../../api/profile-api";
import AccountApi from "../../api/auth-api";
import useBreakpoint from "../responsiveUtils/useBreakpoint";

import SectionSuccess from "./SectionSuccess";

// icons
import icon_ktp from "../../assets/images/icon_ktp.svg";
import icon_camera_drag from "../../assets/images/icon_camera_drag.svg";
import icon_docs from "../../assets/images/icon-docs.svg";

import { MdChevronRight, MdClose } from "react-icons/md";

import { Checkbox } from "antd";
const IdentityCard = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const auth = useSelector((st) => st.authReducer);
  const { isMobile, isDesktop, isTablet } = useBreakpoint();
  const [show, setShow] = useState(false);
  const [previewKtpImage, setPreviewKtpImage] = useState(null);
  const [previewSelfieKtp, setPreviewSelfieKtp] = useState(null);
  const [body, setBody] = useState({
    no_ktp: "",
    ktp_uploaded_file: "",
    selfie_ktp_uploaded_file: "",
  });

  const steps = [
    {
      id: 1,
      name: "ktp dan nik",
      desc: "Step 1. Masukkan Foto dan Nomor Induk KTP",
    },
    {
      id: 2,
      name: "selfie ktp",
      desc: "Step 2. Masukan Foto selfie dengan KTP",
    },
  ];

  const [activeStep, setActiveStep] = useState({
    id: 1,
    name: "ktp dan nik",
    desc: "Step 1. Masukkan Foto dan Nomor Induk KTP",
  });

  const openModal = () => {
    setShow(true);
  };

  const closeModal = () => {
    setShow(false);
  };

  const handleNext = () => {
    setActiveStep(steps[1]);
  };

  const handleBack = () => {
    setActiveStep(steps[0]);
  };

  // drag n drop upload
  const { Dragger } = Upload;

  const props = {
    name: "file",
    multiple: false,
    maxCount: 1,
    accept: "image/*",
    beforeUpload() {
      return false;
    },
    onChange(info) {
      if (activeStep.id === 1) {
        setBody({ ...body, ktp_uploaded_file: info.file });
        setPreviewKtpImage(previewImage(info.file));
      } else if (activeStep.id === 2) {
        setBody({ ...body, selfie_ktp_uploaded_file: info.file });
        setPreviewSelfieKtp(previewImage(info.file));
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const getPreviewImage = () => {
    if (activeStep.id === 1) {
      return previewKtpImage
        ? previewKtpImage
        : auth?.ktp_uploaded_file
        ? auth?.ktp_uploaded_file
        : icon_camera_drag;
    }

    if (activeStep.id === 2) {
      return previewSelfieKtp
        ? previewSelfieKtp
        : auth?.selfie_ktp_uploaded_file
        ? auth?.selfie_ktp_uploaded_file
        : icon_camera_drag;
    }

    return null;
  };

  const getPreviewClass = () => {
    if (activeStep.id === 1) {
      return auth?.ktp_uploaded_file || previewKtpImage ? "h-100 w-100" : "";
    }

    if (activeStep.id === 2) {
      return auth?.selfie_ktp_uploaded_file || previewSelfieKtp
        ? "h-100 w-100"
        : "";
    }

    return null;
  };

  const handleSubmit = async () => {
    try {
      const accountApi = new AccountApi();
      const profileApi = new ProfileApi();

      const fd = new FormData();

      for (const key in body) {
        if (body[key]) {
          fd.append(key, body[key]);
        }
      }

      const result = await accountApi.checkToken(auth.refresh_token);
      if (result.status === "success") {
        const profileRes = await profileApi.updateKtp(fd, auth.access_token);

        if (profileRes.status === "success") {
          message.success(profileRes.message);
          const { data } = await accountApi.getDetailCustomer(
            auth.access_token
          );

          dispatch(setAuth({ ...auth, ...data }));

          // setIsSubmit(true);
          closeModal();
        } else {
          closeModal();
          message.error(profileRes.message);
        }
      } else {
        history.push("/login");
        dispatch(resetAuth());
      }
    } catch (error) {
      console.log(error.response);
    }
  };

  const getKtpDisplay = () => {
    if (!auth.selfie_ktp_uploaded_file || !auth.ktp_uploaded_file) {
      return (
        <h5>Data KTP diperlukan untuk menggunakan layanan sewa kendaraan</h5>
      );
    } else {
      return (
        <>
          <div
            className={`d-flex ${
              isMobile ? "flex-column" : ""
            } align-items-center mt-3 border-bottom-1 gap-2 mb-2`}
          >
            <img
              src={auth.ktp_uploaded_file}
              width={150}
              height={75}
              alt=""
              className="img-fluid"
            />
            <img
              src={auth.selfie_ktp_uploaded_file}
              width={150}
              height={75}
              alt=""
              className="img-fluid mb-2"
            />
          </div>
          <div className="">
            <p>Nomor KTP </p>
            <span className="text-end fw-bold ">{auth?.no_ktp}</span>
          </div>
        </>
      );
    }
  };

  return (
    <>
      <div
        className="bg-white rounded-card w-full p-3 pointer"
        onClick={openModal}
      >
        <div className="d-flex align-items-center   px-2">
          <img src={icon_ktp} width={25} height={25} alt="" />

          <div className="ms-3">
            <h6 className="text-muted ">Data KTP</h6>
            {getKtpDisplay()}
          </div>
          <div
            className="d-flex ms-auto align-items-center"
            style={{ marginTop: "-10px" }}
          >
            <MdChevronRight className="" />
          </div>
        </div>
      </div>
      <Modal
        contentClassName="rounded-card p-2"
        show={show}
        onHide={closeModal}
        className="p-3"
        centered
      >
        <div className="d-flex p-2">
          <p className="fw-bold">Data KTP</p>
          <MdClose className="ms-auto me-2 pointer" onClick={closeModal} />
        </div>
        <Modal.Body>
          <h6>{activeStep.desc}</h6>
          <div className="">
            <div className="d-flex gap-2 align-items-center mb-2 w-100">
              <div className="rounded-card border-secondary progress-tabs border"></div>
              <div
                className={`rounded-card ${
                  activeStep.id === 2 ? "border-secondary" : "border-muted "
                } progress-tabs border`}
              ></div>
            </div>
            <Dragger
              // onPreview={}
              showUploadList={false}
              {...props}
              className="p-4"
              style={{ backgroundColor: "white" }}
            >
              <img
                src={getPreviewImage()}
                alt=""
                className={getPreviewClass()}
              />
              <p className="text-primary mt-3">Browse file</p>
            </Dragger>
            {activeStep.id === 1 ? (
              <>
                <InputGroup className="border rounded-input mt-2">
                  <InputGroup.Text
                    className="border-0 rounded-input"
                    style={{ backgroundColor: "#f8f9fa" }}
                  >
                    <img src={icon_docs} alt="" />
                  </InputGroup.Text>
                  <FloatingLabel
                    className="rounded-input"
                    label="Nomor KTP"
                    style={{ fontSize: "12px", width: "85%" }}
                  >
                    <Form.Control
                      type={"number"}
                      className="border-0 rounded-input"
                      placeholder="m"
                      onChange={(ev) =>
                        setBody({ ...body, no_ktp: ev.target.value })
                      }
                      defaultValue={auth?.no_ktp}
                      style={{ height: "50px" }}
                    ></Form.Control>
                  </FloatingLabel>
                </InputGroup>
              </>
            ) : (
              <>
                <InfoBar
                  infoBarClassName={"mt-2"}
                  text={
                    <>
                      <h6>Pastikan KTP dan wajah anda terlihat jelas</h6>
                    </>
                  }
                />
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <p className="text-primary pointer me-4" onClick={handleBack}>
            Kembali
          </p>
          <Button
            size="sm"
            variant="primary"
            className="float-end rounded-pill px-4 py-2"
            onClick={() => {
              if (activeStep.id === 1) {
                handleNext();
              } else handleSubmit();
            }}
          >
            {activeStep.id === 1 ? "Berikutnya" : "Simpan"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default IdentityCard;
