import React, { useState, useEffect } from "react";
import { Row, Modal, Card } from "react-bootstrap";

import util from "../../helper/util";
import { Tag } from "antd";

// icons
import { BsFillCircleFill } from "react-icons/bs";
import iconDocs from "../../assets/images/icon-docs.svg";
import iconRP from "../../assets/images/icon-rp.svg";

import icon_info_barang from "../../assets/images/icon_info_barang.svg";
import { MdChevronRight, MdLocationPin } from "react-icons/md";

const RouteDetailModal = ({
  showRouteDetailModal,
  closeRouteDetailModal,
  trxDetail,
}) => {
  return (
    <Modal
      contentClassName="p-1"
      show={showRouteDetailModal}
      onHide={closeRouteDetailModal}
      className="modal-dialog-scrollable"
      centered
      size="lg"
    >
      <Modal.Header closeButton className="bg-white">
        <strong>Detail Rute</strong>
      </Modal.Header>
      <Row className="p-4 scrollable">
        {trxDetail.data?.trx_booking_send?.trx_booking_send_destination?.map(
          (el, i) => (
            <div className="bg-ice p-2 rounded d-flex mb-2">
              <div className="">
                <BsFillCircleFill className="text-secondary" />
                {i !==
                  trxDetail.data?.trx_booking_send?.trx_booking_send_destination
                    ?.length -
                    1 && (
                  <div
                    className="br-dash"
                    style={{
                      height: "87%",
                      marginLeft: "7px",
                      marginTop: "5px",
                    }}
                  />
                )}
              </div>
              <Card className="bg-white ms-2 p-2 w-100">
                <div className="d-flex">
                  <MdLocationPin
                    className={`${
                      el.destination_type === "PICKUP"
                        ? "text-secondary"
                        : "text-primary"
                    }  `}
                  />
                  <h6 className="text-muted">{`${
                    el.destination_type === "PICKUP" ? "Pengirim" : "Penerima"
                  }`}</h6>
                  <Tag
                    className="ms-auto"
                    color="green"
                    hidden={el?.cod_amount === 0}
                  >
                    COD
                  </Tag>
                </div>
                <div className="">
                  <h6 className="ms-3">
                    <span className="fw-bold">{el?.contact_name} </span>
                    &bull; {el?.contact_phone_no}
                  </h6>
                </div>
                <hr />
                <div className="ms-3">
                  <h6 className="fw-bold">{el?.address.split(",")[0]}</h6>
                  <h6>{}</h6>
                  <h6>
                    <span className="me-2"> &bull; </span>
                    {el?.address}
                  </h6>
                </div>
                <div className="bg-light-gray d-flex rounded p-2 align-items-center gap-2">
                  <img src={iconDocs} alt="" className="mb-2" />
                  <h6 className="text-muted">
                    {el?.note || "Tidak ada catatan"}
                  </h6>
                </div>
                {el?.cod_amount > 0 && (
                  <div className="bg-light-gray d-flex rounded p-2 align-items-center gap-2 mt-2">
                    <img src={iconRP} alt="" className="mb-2" />
                    <h6 className="text-muted">
                      Tagih ke Penerima : Rp{" "}
                      {util.thousandSeparatorPure(el?.cod_amount)}
                    </h6>
                  </div>
                )}
                {el?.destination_type === "PICKUP" && (
                  <div className="p-2 mt-2">
                    <div className="d-flex">
                      <img
                        src={icon_info_barang}
                        alt=""
                        className="mb-2 me-2"
                      />
                      <h6>Info Barang</h6>
                    </div>
                    <h6 className="ms-2">{el?.item_type}</h6>
                  </div>
                )}
              </Card>
            </div>
          )
        )}
      </Row>
    </Modal>
  );
};

export default RouteDetailModal;
