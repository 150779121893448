import React, {useState, useEffect} from "react";
import {
    Modal,
    Row, 
    Col
} from "react-bootstrap";

import { BsChevronLeft } from "react-icons/bs";

import carDepan from "./../../../../../src/assets/images/carDepan.svg"
import carBelakang from "./../../../../../src/assets/images/carBelakang.svg"
import carkiri from "./../../../../../src/assets/images/carKiri.svg"
import carKanan from "./../../../../../src/assets/images/carKanan.svg"


function ModalInstruksiDokumentasi({
    handlePrevSelesai,
    show,
    handleClose,
}) {
  return<>  
        <Modal
            contentClassName="rounded-card p-2"
            show={show}
            onHide={handleClose}
            centered
        >
            <Modal.Header closeButton className="bg-white">
                <BsChevronLeft className="me-2 pointer" onClick={()=> handlePrevSelesai()}/>
                <strong>Instruksi Dokumentasi Kendaraan</strong>
            </Modal.Header>

            <div className="py-3 px-4">
                <h6 className="text-muted">Kendaraan anda harus melalui proses dokumentasi untuk update kondisi kendaraan setelah pemindahan lokasi sewa. Anda akan melakukan foto dokumentasi, upload dan input data.)</h6>
                <div className="p-2 rounded mt-1" style={{background:"#EAF4F6"}}>
                    <h6 className="text-muted m-0" style={{fontSize:"10px"}}>Proses <strong>Upload Dokumentasi</strong> dan <strong>Input Data</strong> dilakukan setelah sampai di lokasi mutasi.</h6>                    
                </div>
                <div className="p-2 rounded mt-2" style={{background:"#EAF4F6"}}>
                    <h6 className="text-muted m-0" style={{fontSize:"10px"}}>Pastikan dokumentasi berupa <strong>file jpg/png</strong> dan ukuran file <strong>maksimal 5mb</strong></h6>                 
                </div>
                <div className="border-bottom my-3"></div>
                <h6 className="text-muted">Beberapa dokumen yang harus anda persiapkan untuk proses upload:</h6>
                <div className="mb-3">
                    <h6 className="fw-bold mb-2">1. Penerimaan Kendaraan</h6>
                    <h6 className="text-muted ms-3">Catat nama dan lakukan foto pada penerima yang berdampingan dengan kendaraan sebagai bukti tanda terima.</h6>
                </div>
                <div className="mb-3">
                    <h6 className="fw-bold mb-2">2. Eksterior Kendaraan</h6>
                    <h6 className="text-muted ms-3">Lakukan foto pada eksterior kendaraan dari 4 sisi berbeda.</h6>
                    <div className="d-flex align-items-end">
                        <img src={carDepan} alt="carDepan" className="me-2 ms-3"></img>
                        <img src={carkiri} alt="carDepan" className="ms-3"></img>
                        <img src={carKanan} alt="carDepan" className="ms-3"></img>
                        <img src={carBelakang} alt="carDepan" className="ms-3"></img>
                    </div>
                </div>
                <div className="mb-3">
                    <h6 className="fw-bold mb-2">3. Interior Kendaraan</h6>
                    <h6 className="text-muted ms-3">Lakukan foto pada Interior kendaraan dari berbagai sisi berbeda.</h6>
                </div>
                <div className="mb-3">
                    <h6 className="fw-bold mb-2">4. Odometer dan Bensin</h6>
                    <h6 className="text-muted ms-3">Lakukan foto pada odometer dan catat kilometer beserta bar bensinnya.</h6>
                </div>                
            </div>
        </Modal>
    </>;
}

export default ModalInstruksiDokumentasi;
