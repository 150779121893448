import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Dropdown, Button, Tabs, Tab } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import noData from "../../../assets/images/no-data.png";
import Table from "../../../components/table/customTable";
import { useHistory, useLocation } from "react-router-dom";
import { getAlamatList } from "../../../redux/action/RuteAlamat/alamat_action";
import { Card, Spin } from "antd";

const AlamatAnda = (props) => {
  /*======================== Props ======================== */

  const { search } = props;

  /*======================== Hooks ======================== */

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  /*======================== Redux ======================== */

  const data = useSelector((st) => st.alamatReducer.alamat_anda.data);
  const meta = useSelector((st) => st.alamatReducer.alamat_anda.meta);
  const loading = useSelector((st) => st.alamatReducer.loading);

  /*======================== UseState ======================== */

  const [params, setParams] = useState({
    limit: 10,
    page: 1,
    address_type: "PICKING",
    search: search || undefined,
    sort_by: "created_at",
    order_by: "DESC",
  });
  const [sort, setSort] = useState("Terbaru");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState(undefined)

  /*======================== Handler ======================== */

  const handleChange = (type, value) => {
    switch (type) {
      case "sort":
        setPage(1);
        setSort(value);
        setParams({
          ...params,
          sort_by: "created_at",
          order_by: value === "Terbaru" ? "DESC" : "ASC",
          page: 1,
        });
        break;
      case "page":
        setPage(value);
        setParams({ ...params, page: value });
        break;
      case "limit":
        setPage(1);
        setLimit(value);
        setParams({ ...params, limit: value, page: 1 });
        break;

      case "status":
        setPage(1);
        setStatus(value);
        setParams({ ...params, is_completed: value, page: 1 });
        break;

      default:
        break;
    }
  };

  /*======================== UseEffect ======================== */

  useEffect(() => {
    if (search) {
      setPage(1);
      dispatch(getAlamatList({ ...params, search, page: 1 }));
    } else {
      dispatch(getAlamatList({ ...params, search: undefined }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, search]);

  /*======================== Others ======================== */
  const statusOptions = [
    {
      title: "Semua",
      value: "",
    },
    {
      title: "Lengkap",
      value: 1,
    },
    {
      title: "Belum Lengkap",
      value: 0,
    }
  ];
  const sortOptions = [
    {
      title: "Terbaru",
    },
    {
      title: "Terlama",
    },
  ];
  const tableProps = {
    bordered: false,
    expandable: false,
    title: undefined,
    showHeader: true,
    rowSelection: false,
  };
  const tableColumns = [
    {
      title: "No",
      dataIndex: "no",
      sorter: false,
      className: "text-center px-3",
    },
    {
      title: "Kode Alamat",
      dataIndex: "code",
      sorter: false,
      className: "px-3",
    },
    {
      title: "Nama Alamat",
      dataIndex: "name",
      width: "20%",
      sorter: false,
      className: "px-3",
    },
    {
      title: "Alamat Anda",
      dataIndex: "complete_address",
      sorter: false,
      width: "35%",
      className: "px-3",
      render: (_, { complete_address }) => {
        return <p className="mb-0 complete-address">{complete_address}</p>;
      },
    },
    {
      title: "Nama PIC",
      dataIndex: "pic",
      sorter: false,
      className: "px-3",
    },
    {
      title: "Kontak PIC",
      dataIndex: "phone_number",
      sorter: false,
      className: "px-3",
    },
    {
      title: "Action",
      key: "action",
      sorter: false,
      className: "px-3",
      width:"20%",
      render: (_, { id, is_completed }) => {
        return (
          <div className="d-flex justify-content-end">
            <button
              hidden={is_completed}
              size="sm"
              className="fw-bold px-4 me-2 rounded text-primary btn-outline-primary"
              style={{backgroundColor:"white"}}
              onClick={() =>
                history.push(location.pathname + "/lengkapi-alamat-anda/" + id)
              }
            >
              Lengkapi
            </button>
            <Button
              size="sm"
              className="fw-bold px-4 text-white text-capitalize"
              onClick={() =>
                history.push(location.pathname + "/tambah-alamat-anda/" + id)
              }
            >
              Detail
            </Button>
          </div>
        );
      },
    },
  ];

  /*======================== Return ======================== */

  return (
    <>
      <div className="mt-4">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h2 className="fw-bold text-capitalize">Daftar Alamat Anda</h2>
            <p>List alamat untuk memudahkan anda melakukan order</p>
          </div>
          <div>
            <Button
              size="sm"
              className="fw-bold py-2 px-4 rounded-pill text-capitalize"
              onClick={() =>
                history.push(location.pathname + "/tambah-alamat-anda")
              }
            >
              Tambahkan alamat
            </Button>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between mb-3">
          <div className="d-flex" style={{ gap: "1rem" }}>
            <span className="fw-bold" style={{ width: "auto" }}>
              Tampilkan:
            </span>
            <Tabs
              defaultActiveKey={status}
              className="tabs-rute mb-2 border-0"
              style={{ gap: "10px" }}
              onSelect={(value) => handleChange("status", value)}
            >
              {statusOptions.map((item, index) => (
                <Tab key={index} eventKey={item.value} title={item.title} />
              ))}
            </Tabs>
          </div>
        
          <div className="d-flex align-items-center">
            <h4 className="mt-2 me-2" style={{ fontWeight: "600" }}>
              Urutkan Berdasarkan :
            </h4>
            <Dropdown className="d-inline mx-2">
              <Dropdown.Toggle
                id="dropdown-autoclose-true"
                size="sm"
                variant="light"
                className="bg-white text-secondary px-3 border shadow-sm rounded"
              >
                <strong>{sort}</strong>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {sortOptions.map((item, index) => (
                  <Dropdown.Item
                    key={index}
                    href="#"
                    onClick={() => {
                      handleChange("sort", item.title);
                    }}
                  >
                    {item.title}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
      {data.length > 0 && (
        <div className="mt-2">
          <Table
            classProps="table-alamat"
            {...{
              tableProps,
              tableColumns,
              handleChange,
              limit,
              page,
              loading,
              data,
            }}
            total_data={meta?.total_data}
          />
        </div>
      )}
      {data.length < 1 &&
        (!loading ? (
          <div
            className="d-flex flex-column align-items-center justify-content-center"
            style={{ marginTop: "5rem" }}
          >
            <img src={noData} alt="no-data" />
            <p className="mt-3">
              {search
                ? "Alamat yang anda cari tidak ditemukan"
                : "Anda belum memiliki alamat"}
            </p>
            {!search && (
              <Button
                size="sm"
                className="fw-bold py-2 px-4 rounded-pill text-capitalize"
                onClick={() =>
                  history.push(location.pathname + "/tambah-alamat-anda")
                }
              >
                Tambahkan alamat
              </Button>
            )}
          </div>
        ) : (
          <Card
            className="p-4 rounded-card border-0 d-flex align-items-center justify-content-center mt-4"
            style={{
              height: "350px",
            }}
          >
            <Spin />
          </Card>
        ))}
    </>
  );
};

AlamatAnda.propTypes = {
  search: PropTypes.string,
};

export default AlamatAnda;
