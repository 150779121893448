/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";
import { Dropdown, Button, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import noData from "../../assets/images/no-data.png";
import Table from "../../components/table/customTable";
import { getInvoiceList } from "../../redux/action/Invoice/invoice_action";
import moment from "moment";
import { Tab } from "bootstrap";
import AddInvoice from "./AddInvoice";

const Kontrak = (props) => {
  /*======================== Props ======================== */

  const { search, kontrakOpts } = props;

  /*======================== Hooks  ======================== */

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  /*======================== Redux ======================== */

  const filter = useSelector((st) => st.invoiceReducer.filter);
  const loading = useSelector((st) => st.invoiceReducer.loading);
  const meta = useSelector((st) => st.invoiceReducer.meta);
  const data = useSelector((st) => st.invoiceReducer.data);
  const [selectedId, setSelectedId] = useState(null);

  /*======================== UseState ======================== */

  const [params, setParams] = useState({
    limit: 10,
    page: 1,
    sort_by: "created_at",
    order_by: "DESC",
    // contract_id: "",
    status: filter?.status || undefined,
  });
  const [sort, setSort] = useState("Terbaru");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState(filter?.status || undefined);
  const [contract, setContract] = useState(undefined);
  const [addInvoice, setAddInvoice] = useState(false);

  /*======================== Handler ======================== */

  const handleChange = (type, value) => {
    switch (type) {
      case "sort":
        setPage(1);
        setSort(value);
        setParams({
          ...params,
          sort_by: "created_at",
          order_by: value === "Terbaru" ? "DESC" : "ASC",
          page: 1,
        });
        break;
      case "page":
        setPage(value);
        setParams({ ...params, page: value });
        break;
      case "limit":
        setPage(1);
        setLimit(value);
        setParams({ ...params, limit: value, page: 1 });
        break;
      case "status":
        setPage(1);
        setStatus(value);
        setParams({ ...params, status: value, page: 1 });
        break;
      case "contract":
        setPage(1);
        setContract(value);
        setParams({ ...params, contract_id: value?.id || undefined, page: 1 });
        break;

      default:
        break;
    }
  };
  const handleStatusLabel = (status) => {
    switch (status) {
      case "WAITING":
        return "Menunggu Pengecekan";
      case "CANCEL":
        return "Dibatalkan";
      case "APPROVED":
        return "Selesai Pengecekan";
      case "CHECKING":
        return "Sedang Pengecekan";
      default:
        return "-";
    }
  };
  const handleStatusColor = (status) => {
    switch (status) {
      case "WAITING":
        return "text-orange text-capitalize";
      case "CANCEL":
        return "text-red text-capitalize";
      case "APPROVED":
        return "text-green text-capitalize";
      case "CHECKING":
        return "text-blue text-capitalize";
      default:
        return "sub-label text-capitalize";
    }
  };
  const handleAddInvoice = (id = null) => {
    if (id) {
      setSelectedId(id);
    }
    setAddInvoice(!addInvoice);
  };
  const handleCheckingBy = (checkingBy) => {
    const someIsWaiting = checkingBy.some(item => item.status === 'WAITING')
    const everyIsApproved = checkingBy.every(item => item.status === 'APPROVED')

    if (someIsWaiting) {
      const firstWaiting = []
      checkingBy.forEach(item => {
        if (item.status === 'WAITING' && firstWaiting.length === 0) {
          firstWaiting.push(item.role_name)
        } else {
          return
        }
      })
      return firstWaiting[0]
    }
    if (everyIsApproved) {
      return checkingBy[checkingBy.length-1].role_name
    }
    return '-'
  }
  /*======================== UseEffect ======================== */

  useEffect(() => {
    if (search) {
      setPage(1);
      // dispatch(getInvoiceList({ ...params, search, page: 1 }));
      dispatch(getInvoiceList({ ...params, search }));
    } else {
      dispatch(getInvoiceList({ ...params, search: undefined }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, search]);

  /*======================== Others ======================== */

  const sortOptions = [
    {
      title: "Terbaru",
    },
    {
      title: "Terlama",
    },
  ];
  const statusOptions = [
    {
      title: "Semua",
      value: "",
    },
    {
      title: "Menunggu",
      value: "waiting",
    },
    {
      title: "Pengecekan",
      value: "checking",
    },
    {
      title: "Selesai",
      value: "approved",
    },
  ];
  const tableProps = {
    bordered: false,
    expandable: false,
    title: undefined,
    showHeader: true,
    rowSelection: false,
  };
  const tableColumns = [
    {
      title: "No Invoices",
      dataIndex: "invoice_no",
      sorter: false,
      className: "px-3 text-pink",
    },
    {
      title: "Tanggal Invoice",
      sorter: false,
      className: "px-3",
      render: (_, { invoice_date }) => {
        return <span>{moment(invoice_date).format("DD/MM/YYYY")}</span>;
      },
    },
    {
      title: "Partner",
      dataIndex: "partner_name",
      sorter: false,
      className: "px-3",
    },
    {
      title: "Transaksi",
      sorter: false,
      className: "px-3",
      render: (_, { total_transactions }) => {
        return <span>{total_transactions} Transaksi</span>;
      },
    },
    {
      title: "Proses Pembayaran",
      sorter: false,
      className: "px-3",
      render: (_, { invoice_status }) => {
        return (
          <span className={handleStatusColor(invoice_status)}>
            {handleStatusLabel(invoice_status)}
          </span>
        );
      },
    },
    {
      title: "Pengecekan Oleh",
      // dataIndex: "role_name",
      sorter: false,
      className: "px-3",
      render: (_, { invoice_checking_by }) => {
        return (
          <p>
            {invoice_checking_by && invoice_checking_by.length > 0
              ? handleCheckingBy(invoice_checking_by)
              : "-"}
          </p>
        );
      },
    },
    {
      title: "Status Pengecekan",
      sorter: false,
      className: "px-3",
      render: (_, { payment_process_status }) => {
        return (
          <span className={handleStatusColor(payment_process_status)}>
            {handleStatusLabel(payment_process_status)}
          </span>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      sorter: false,
      className: "px-3",
      render: (
        _,
        {
          route_code,
          payment_process_status,
          invoice_no,
          invoice_id,
          invoice_checking_by,
        }
      ) => {
        return (
          <Button
            size="sm"
            className="fw-bold px-4 text-capitalize"
            onClick={
              invoice_checking_by?.length
                ? () => history.push(`detail-invoice/${invoice_id}`)
                : () => handleAddInvoice(invoice_id)
            }
          >
            {/* Detail */}
            {invoice_checking_by?.length ? "Detail" : "Mulai"}
          </Button>
        );
      },
    },
  ];

  /*======================== Return ======================== */

  return (
    <>
      <div className="mt-4">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h2 className="fw-bold text-capitalize">Daftar Invoice</h2>
            <p>
              Silahkan lakukan invoice & dokumen yang sudah dibuat oleh Partner
            </p>
          </div>
          <div className="d-flex align-items-center">
            <h4 className="mt-2 me-2" style={{ fontWeight: "600" }}>
              Pilihan Kontrak:
            </h4>
            <Dropdown className="d-inline mx-2">
              <Dropdown.Toggle
                id="dropdown-autoclose-true"
                size="sm"
                variant="light"
                className="bg-white text-secondary px-3 border shadow-sm rounded"
              >
                <strong>{contract ? contract.name : "Semua Kontrak"}</strong>
              </Dropdown.Toggle>

              <Dropdown.Menu
                className="custom-scroll"
                style={{ maxHeight: "14rem" }}
              >
                <Dropdown.Item
                  href="#"
                  onClick={() => {
                    handleChange("contract", undefined);
                  }}
                >
                  Semua Kontrak
                </Dropdown.Item>
                {kontrakOpts.map((item, index) => (
                  <Dropdown.Item
                    key={index}
                    href="#"
                    onClick={() => {
                      handleChange("contract", item);
                    }}
                  >
                    {item.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between mb-3">
          <div className="d-flex" style={{ gap: "1rem" }}>
            <span className="fw-bold" style={{ width: "auto" }}>
              Tampilkan:
            </span>
            <Tabs
              defaultActiveKey={status}
              className="tabs-rute mb-2 border-0"
              style={{ gap: "10px" }}
              onSelect={(value) => handleChange("status", value)}
            >
              {statusOptions.map((item, index) => (
                <Tab key={index} eventKey={item.value} title={item.title} />
              ))}
            </Tabs>
          </div>
          <div className="d-flex">
            <h4 className="mt-2 me-2" style={{ fontWeight: "600" }}>
              Urutkan Berdasarkan :
            </h4>
            <Dropdown className="d-inline mx-2">
              <Dropdown.Toggle
                id="dropdown-autoclose-true"
                size="sm"
                variant="light"
                className="bg-white text-secondary px-3 border shadow-sm rounded"
              >
                <strong>{sort}</strong>
              </Dropdown.Toggle>

              <Dropdown.Menu className="dropdown-menu">
                {sortOptions.map((item, index) => (
                  <Dropdown.Item
                    key={index}
                    href="#"
                    onClick={() => {
                      handleChange("sort", item.title);
                    }}
                  >
                    {item.title}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
      {/* with data */}
      {data.length > 0 ? (
        <div className="mt-2">
          <Table
            classProps="table-rute"
            {...{
              tableProps,
              tableColumns,
              handleChange,
              limit,
              page,
              loading,
              data,
            }}
            total_data={meta?.total_data}
          />
        </div>
      ) : (
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{ marginTop: "5rem" }}
        >
          <img src={noData} alt="no-data" />
          <p className="mt-3">
            {search
              ? "Invoice yang anda cari tidak ditemukan"
              : "Anda belum memiliki invoice partner"}
          </p>
        </div>
      )}
      {addInvoice && (
        <AddInvoice
          isOpen={addInvoice}
          handleClose={handleAddInvoice}
          idInvoice={selectedId}
        />
      )}
    </>
  );
};

Kontrak.propTypes = {
  search: PropTypes.string,
};

export default Kontrak;
