import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { formatRupiah } from "./../../../../../helper/formatCurrency"
import DetailBillingPayment from '../Modal/DetailBillingPayment'

const SummaryBilling = ({
  data, 
  modalDetaiPaymentBilling,
  setModalDetailPaymentBilling 
}) => {
  
  return (
    <div className="mb-3">
      <h5 className="fw-bold">Jumlah Tagihan</h5>
      <div className="bg-white rounded-card w-full p-3" style={{borderRadius:"20px 20px 0 0", borderBottom:"1px solid #E5E5E5", position:"relative"}}>
        <Row>
          <Col xs={6}>
            <div className="px-2">
              <p className="text-muted label mb-1">Subtotal</p>
              <p className="mb-0"> {data?.payment_amount ? formatRupiah(data?.payment_amount) : "-"}</p>
            </div>
          </Col>
          <Col xs={6}>
            <div className="px-2">
              <p className="text-muted label mb-1">Potongan Khusus</p>
              <p className="mb-0" style={{color:`${data?.payment_amount - data?.payment_amount_after_discount > 0 && "#0FAA4B"}`}}>{ data?.payment_amount - data?.payment_amount_after_discount > 0 ? formatRupiah(data?.payment_amount - data?.payment_amount_after_discount) : 0}</p>
            </div>
          </Col>
        </Row>
      </div>
      <div className="bg-white rounded-card w-full p-3" style={{borderRadius:"0 0 20px 20px"}}>
        <Row>
          <Col xs={6}>
            <div className="px-2">
              <p className="text-muted label mb-1">Total Tagihan</p>
              <p className="mb-0" style={{color:"#2BBECB"}}>{data?.payment_amount_after_discount ? formatRupiah(data?.payment_amount_after_discount) : "-"}</p>
            </div>
          </Col>
          <Col xs={6}>
            <div className="px-2">
              <p className="text-muted label mb-1">Rincian Biaya Penagihan</p>
              <DetailBillingPayment
                show={modalDetaiPaymentBilling}
                setShow={setModalDetailPaymentBilling}
                data={data}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default SummaryBilling