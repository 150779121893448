const initialState = {
  selectable: {
    data: [],
    meta: {},
  },
  loading: false,
};

export const partnerReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "SET_PARTNER":
      return { ...state, ...payload };
    default:
      return state;
  }
};
