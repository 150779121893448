import React, { useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  Form,
  Tabs,
  Tab,
  Card,
} from "react-bootstrap";
import { Avatar } from "antd";
import icon_kirim_barang_on from "../../assets/images/sidebar-icon/icon_kBarang_on.png";
import icon_sewa_on from "../../assets/images/sidebar-icon/icon_sewa_on.png";
import moment from "moment";

// redux
import { useSelector, useDispatch } from "react-redux";
import { setTrxState } from "../../redux/action/trx_history_action";

// api
import TrxListApi from "../../api/trx_list_api";

// icons
import {
  BsFillCircleFill,
  BsFillStarFill,
  BsFillCheckCircleFill,
} from "react-icons/bs";
import { AiOutlineUser } from "react-icons/ai";

const PartnerDetailModal = ({
  showPartnerDetailModal,
  closePartnerDetailModal,
}) => {
  const dispatch = useDispatch();
  const trxDetail = useSelector((st) => st.trxHistoryReducer.trxDetail);
  const partnerDetail = useSelector((st) => st.trxHistoryReducer.partnerDetail);
  const auth = useSelector((st) => st.authReducer);

  const masterVehicles = [
    {
      id: 1,
      name: "Motor",
      isAvailable: true,
    },
    {
      id: 2,
      name: "Economy",
      isAvailable: true,
    },
    {
      id: 3,
      name: "Blind Van",
      isAvailable: true,
    },
    {
      id: 4,
      name: "Small Pickup",
      isAvailable: false,
    },
    {
      id: 5,
      name: "Small Box",
      isAvailable: false,
    },
    {
      id: 6,
      name: "CDE/Engkel",
      isAvailable: true,
    },
    {
      id: 7,
      name: "CDD/Double Engkel",
      isAvailable: false,
    },
    {
      id: 8,
      name: "Fuso Ringan",
      isAvailable: true,
    },
    {
      id: 9,
      name: "Fuso Berat",
      isAvailable: false,
    },
    {
      id: 10,
      name: "Tronton",
      isAvailable: true,
    },
    {
      id: 11,
      name: "Tronton Wingbox",
      isAvailable: true,
    },
  ];

  const getPartnerDetail = async () => {
    try {
      const api = new TrxListApi();
      const partnerId = trxDetail?.data?.mst_partner_service?.mst_partner?.id;
      const token = auth.access_token;

      dispatch(
        setTrxState(
          { partnerDetail: { data: {}, loading: true } },
          auth.refresh_token
        )
      );

      const { data } = await api.getPartnerDetail(token, partnerId);

      dispatch(
        setTrxState(
          { partnerDetail: { data: { ...data }, loading: false } },
          auth.refresh_token
        )
      );
    } catch (error) {
      dispatch(
        setTrxState(
          { partnerDetail: { data: {}, loading: false } },
          auth.refresh_token
        )
      );

      console.log(error?.response);
    }
  };

  useEffect(() => {
    getPartnerDetail();
  }, []);

  return (
    <Modal
      contentClassName="p-1"
      show={showPartnerDetailModal}
      onHide={closePartnerDetailModal}
      className=""
      centered
      size="lg"
    >
      <Modal.Header closeButton className="bg-white">
        <strong>Info Mitra</strong>
      </Modal.Header>
      <Row className="p-4">
        <Col xs={7}>
          <div className="d-flex align-items-center gap-3">
            <Avatar
              shape="square"
              size={72}
              src={partnerDetail?.data?.profile_picture}
              icon={!partnerDetail?.data?.profile_picture && <AiOutlineUser />}
            >
              {" "}
            </Avatar>
            <div className="">
              <h6 className="fw-bold">{partnerDetail?.data?.name}</h6>
              <h6>Order Selesai : {partnerDetail?.data?.total_order} order</h6>
              <h6>
                Bergabung :{" "}
                {moment(partnerDetail?.data?.created_at).format("MMMM YYYY")}
              </h6>
            </div>
          </div>
        </Col>
        <Col>
          <div className="d-flex align-items-center gap-1">
            <BsFillStarFill className="mb-2" style={{ color: "#ffc107" }} />
            <h6 className="">
              <span className="fw-bold">
                {partnerDetail?.data?.rating || 0}
              </span>{" "}
              / 5.0
            </h6>
          </div>
          {/* <div className="d-flex align-items-center">
            <h6 className="fw-bold">90% Pelanggan merasa puas</h6>
          </div> */}
          <div className="d-flex align-items-center">
            <h6>
              {partnerDetail?.data?.rating_count} rating &bull;{" "}
              {partnerDetail?.data?.review_count} Ulasan
            </h6>
          </div>
        </Col>
        <Tabs
          defaultActiveKey={1}
          className="tabs-trx mb-2 border-bottom-2 mt-2"
        >
          <Tab eventKey="1" title="Layanan"></Tab>
          <Tab eventKey="2" title="Ulasan"></Tab>
        </Tabs>
        <Row className="w-100">
          <h5 className="fw-bold mb-3 mt-2">Layanan yang disediakan</h5>
          {partnerDetail?.data?.delivery_service > 0 && (
            <Col xs={12} md={6}>
              <Card className="p-1 mt-2">
                <div className="d-flex align-items-center">
                  <img
                    src={icon_kirim_barang_on}
                    className="i-sidebar"
                    alt=""
                  />
                  <div className="">
                    <h5 className="fw-bold">Pengiriman Barang</h5>
                    <h6>Sekarang & Terjadwal</h6>
                  </div>
                </div>
              </Card>
            </Col>
          )}
          {partnerDetail?.data?.rent_service > 0 && (
            <Col xs={12} md={6}>
              <Card className="p-1 mt-2">
                <div className="d-flex align-items-center">
                  <img src={icon_sewa_on} className="i-sidebar" alt="" />
                  <div className="">
                    <h5 className="fw-bold">Sewa Kendaraan</h5>
                    <h6>Sekarang & Terjadwal</h6>
                  </div>
                </div>
              </Card>
            </Col>
          )}
        </Row>
        <Row>
          <h5 className="fw-bold mb-3 mt-4">Armada yang disediakan</h5>
          {partnerDetail?.data?.mst_vehicle_subtype?.map((each, i) => (
            <Col xs={6} md={3} key={each.id}>
              <Card className="p-2 mt-2" style={{ height: "60px" }}>
                <div className="d-flex align-items-center gap-2">
                  {each.name ? (
                    <BsFillCheckCircleFill
                      style={{
                        width: "30px",
                        height: "30px",
                        color: "#198754",
                      }}
                    />
                  ) : (
                    <BsFillCircleFill
                      style={{
                        width: "30px",
                        height: "30px",
                        color: "#adb5bd",
                      }}
                    />
                  )}
                  <div className="">
                    <h5 className={"fw-bold"}>{each.name}</h5>
                  </div>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </Row>
    </Modal>
  );
};

export default PartnerDetailModal;
