import { useState, useEffect } from "react";
import { Row, Col, Accordion } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import TrxListApi from "../../api/trx_list_api";
import { message } from "antd";

const DokumenKendala = () => {
  /*======================== Hooks ======================== */

  // const dispatch = useDispatch();
  const auth = useSelector((st) => st.authReducer);
  const { id } = useParams();

  /*======================== UseState ======================== */

  const [data, setData] = useState({});

  /*======================== Handler ======================== */

  const getDocumentObstacle = async () => {
    try {
      let api = new TrxListApi();
      let data = await api.getSendObstacleDocument(auth.access_token, id);
      
      if (data && data.data) {
        setData(data); 
      } else {
        message.error(data.message)
      }
    } catch (error) {
      console.log(error)
    }
  };

  const showObstacle = (items) => {
    let component = [];
    if (items && items.length > 0) {
      items.forEach((item, index) => {
        component.push(<li key={index}>{item}</li>);
      });
      return component;
    } else {
      return null;
    }
  };

  const showDocumentation = (items) => {
    let component = [];
    if (items && items.length > 0) {
      items.forEach((item, index) => {
        component.push(
          <Col xs={6} key={index}>
            <div
              className="p-2 mb-2"
              style={{
                border: "2px solid #F1F3F4",
                borderRadius: "10px",
              }}
            >
              <a
                className="underline"
                style={{
                  color: "#CE1771",
                  fontSize: "14px",
                  cursor: "pointer",
                }}
                href={item.url}
                target="_blank"
                rel="noreferrer"
              >
                {item.name}
              </a>
            </div>
          </Col>
        );
      });
      return component;
    } else {
      return (
        <Col xs={6}>
          <div
            className="p-2 mb-2"
            style={{
              border: "2px solid #F1F3F4",
              borderRadius: "10px",
            }}
          >
            <p
              className="mb-0"
              style={{
                fontSize: "14px",
              }}
            >
              -
            </p>
          </div>
        </Col>
      );
    }
  };

  const showGoods = (items) => {
    let component = [];
    if (items && items.length > 0) {
      items.forEach((item, index) => {
        component.push(
          <Col xs={6} key={index}>
            <div
              className="p-2 mb-2"
              style={{
                border: "2px solid #F1F3F4",
                borderRadius: "10px",
              }}
            >
              <p className="text-muted mb-0">Nama Muatan</p>
              <p className="mb-0" style={{ fontSize: "16px" }}>
                {item}
              </p>
            </div>
          </Col>
        );
      });
      return component;
    } else {
      return (
        <Col xs={6}>
          <div
            className="p-2 mb-2"
            style={{
              border: "2px solid #F1F3F4",
              borderRadius: "10px",
            }}
          >
            <p className="text-muted mb-0">Nama Muatan</p>
            <p className="mb-0" style={{ fontSize: "16px" }}>
              -
            </p>
          </div>
        </Col>
      );
    }
  };

  const showData = () => {
    let component = [];
    if (data && data.data && data.data.length > 0) {
      data.data.forEach((item, index) => {
        component.push(
          <Accordion.Item eventKey={index} key={index}>
            <Accordion.Header>
              <Row>
                <Col xs={12}>
                  {item.destination_type === "PICKUP"
                    ? "Muat Barang"
                    : "Drop Off"}
                </Col>
              </Row>
            </Accordion.Header>
            <Accordion.Body>
              <div
                className="pb-1 mb-2"
                style={{ borderBottom: "2px solid #F1F3F4" }}
              >
                <h5>Detail Barang</h5>
              </div>

              <div
                className="py-1 mb-4"
                style={{ borderBottom: "2px solid #F1F3F4" }}
              >
                <Row>{showGoods(item.load_details)}</Row>
              </div>

              <div
                className="pb-1 mb-2"
                style={{ borderBottom: "2px solid #F1F3F4" }}
              >
                <h5>Dokumentasi Kendaraan</h5>
              </div>

              <div
                className="py-1 mb-4"
                style={{ borderBottom: "2px solid #F1F3F4" }}
              >
                <Row>{showDocumentation(item.load_document_images)}</Row>
              </div>

              <div
                className="pb-1 mb-2"
                style={{ borderBottom: "2px solid #F1F3F4" }}
              >
                <h5>Kendala Penyerahan</h5>
              </div>

              <div className="py-1 mb-4">
                <Row>
                  <Col xs={12}>
                    <div className="py-2 mb-2">
                      <p className="mb-2"> Deskripsi Kendala: </p>
                      {item.load_obstacle_details &&
                      item.load_obstacle_details.length > 0 ? (
                        <ul>{showObstacle(item.load_obstacle_details)}</ul>
                      ) : (
                        <p className="mb-2"> Tidak Ada </p>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        );
      });
      return component;
    }
  };

  /*======================== UseEffect ======================== */

  useEffect(() => {
    getDocumentObstacle();
  }, []);

  /*======================== Return ======================== */

  return (
    <div className="mt-4">
      <Accordion defaultActiveKey={0}>{showData()}</Accordion>
    </div>
  );
};
export default DokumenKendala;
