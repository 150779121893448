import React, { useState } from "react";
import {
  Modal,
  Button
} from "react-bootstrap";

const RequestReject = ({onSubmit, data, rejectionReason, setRejectionReason}) => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const handleSubmit = async() => {
    onSubmit()
    setRejectionReason("")
    handleClose()
  }

  return(
  <>
    <Button
      disabled={data.length ? false : true}
      size="sm"
      className="fw-bold py-1 px-4 rounded-pill text-capitalize me-2"
      variant="outline-primary"
      onClick={handleShow}
    >
      Tolak
    </Button>
    
    <Modal
      contentClassName="rounded-card p-2"
      show={show}
      onHide={handleClose}
      centered
    >
      <Modal.Header closeButton className="bg-white">
        <strong>Tolak Dokumen</strong>
      </Modal.Header>
      <div className="py-4">
            <h6 className="mb-3 px-4">Apakah anda yakin akan menolak dokumen yang anda pilih dibawah ini?</h6>
            
            <div className="px-4" style={{maxHeight:"175px", overflowY:"scroll"}}>
              {data?.map((item, i)=>
                <div key={i} className="border rounded p-2 px-3 mb-2" style={{backgroundColor:"#F1F3F4", color:"#666666", fontSize:"12px"}}>
                  {item}
                </div>
              )}
            </div>

            <h6 className="my-3 px-4">Silahkan isi alasan penolakan agar partner dapat memperbaiki dokumen tersebut.</h6>
            <h5 className="mb-2 px-4 fw-bold">Alasan Penolakan</h5>
            <div className="px-4">
              <textarea 
                className="rounded border w-100 p-2"
                rows="4"
                placeholder="Tulis alasan penolakan" 
                style={{fontSize:"14px"}}
                onChange={(e)=> setRejectionReason(e.target.value)}
              />
            </div>
      </div>
      <Modal.Footer>
        <Button
          disabled={rejectionReason ? false : true}
          size="sm"
          className="fw-bold py-1 h-25 px-4 rounded-pill text-capitalize"
          onClick={handleSubmit}
          style={{backgroundColor:`${!rejectionReason ? "#BBBBBB" : "#CE1671"}`, border:"none"}}
        >
          Konfirmasi Tolak
        </Button>
      </Modal.Footer>
    </Modal>
  </>
  )
}

export default RequestReject;
