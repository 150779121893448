/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { Col, Row, Button } from "react-bootstrap";

const ProcurementItemCard = (props) => {
  /*======================== Props ======================== */

  const { item } = props;

  function getVehicleImageURL(stringifiedObj) {
    try {
      if (stringifiedObj) {
        const obj = JSON.parse(stringifiedObj)
        if (obj) {
          return obj[0]
        }
      }
    } catch (e) {
      console.log(e)
    }

    return ""
  }

  /*======================== UseEffect ======================== */

  /*======================== Return ======================== */

  return (
    <Col xs={12} md={6}>
      {/* <div className="bg-white partner-card w-full py-2 mb-2"> */}
      <div className="bg-white w-full py-2 mb-2 shadow-sm mb-3" style={{ borderRadius: "20px" }}>
        <Row>
          <div>
            <div className="px-3" style={{ marginTop: "10px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <div>
                <h4 className="fw-bold text-capitalize">{item?.mst_vehicle_brand?.name} - {item?.mst_vehicle_model?.name} ({item.year})</h4>
              </div>
              <div>
                {item?.trx_booking_rent_vehicle?.trx_booking_rent?.is_mutation == 1 && <Button
                  size="sm"
                  className="px-3 rounded-pill"
                  style={{ backgroundColor: "#ffddee", color: "#ff1d8c", border: "0px", marginRight: "10px" }}
                >
                  Mutasi
                </Button>}
                {item?.trx_booking_rent_vehicle?.trx_booking_rent?.unit_type == 1 && <Button
                  size="sm"
                  className="px-3 rounded-pill"
                  style={{ backgroundColor: "#dff5f7", color: "#2bbecb", border: "0px" }}
                >
                  Ganti Tetap
                </Button>}
              </div>
            </div>
            <hr style={{ width: "100%" }} />
          </div>
          <Col xs={12} sm={6} md={4} lg={3}>
            <div className="partner-card-avatar ps-3">
              <img
                src={getVehicleImageURL(item?.vehicle_picture)}
                alt="profile"
                style={{ width: "100%", borderRadius: "10px" }}
              />
            </div>
          </Col>
          <Col xs={12} sm={6} md={8} lg={9}>
            <Row>
              <Col xs={12} sm={6} md={6} lg={4}>
                <p className="fw-bold text mb-1">Partner:</p>
                <p className="text">{item?.mst_partner_service?.mst_partner?.name}</p>
              </Col>
              <Col xs={12} sm={6} md={6} lg={4}>
                <p className="fw-bold text mb-1">Plat Nomor:</p>
                <p className="text" style={{ color: "#ff1d8c", textDecoration: "underline" }}>{item?.plate_number}</p>
              </Col>
              <Col xs={12} sm={6} md={6} lg={4}>
                <p className="fw-bold text mb-1">ID Transaksi:</p>
                <p className="text" style={{ color: "#ff1d8c", textDecoration: "underline" }}>{item?.trx_booking_rent_vehicle?.trx_booking_rent?.trx_order?.order_number}</p>
              </Col>
            </Row>
            <hr style={{ marginTop: "0px" }} />
            <Row>
              <Col xs={12} sm={6} md={6} lg={8}>
                <p className="fw-bold text mb-1">Lokasi Kendaraan:</p>
                <p className="text">
                  <span>{item?.trx_booking_rent_vehicle?.trx_booking_rent?.pickup_address_name} - </span>
                  <span className="text-muted">{item?.trx_booking_rent_vehicle?.trx_booking_rent?.pickup_address}</span>
                </p>
              </Col>
              <Col xs={12} sm={6} md={6} lg={4}>
                <p className="fw-bold text mb-1">Rentang Sewa:</p>
                <p>{moment(item?.trx_booking_rent_vehicle?.trx_booking_rent?.pickup_time).format('DD MMM YYYY hh:mm')} - {moment(item?.trx_booking_rent_vehicle?.trx_booking_rent?.date_to_return).format('DD MMM YYYY hh:mm')}</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Col>
  );
};

ProcurementItemCard.propTypes = {
  item: PropTypes.object,
  is_procurement: PropTypes.bool,
};

export default ProcurementItemCard;
