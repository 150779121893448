import React, { useState } from "react";
import {
  Modal,
  Form,
  InputGroup,
  FloatingLabel,
  Button,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { resetAuth, setAuth } from "../../redux/action/auth_action";
import ProfileApi from "../../api/profile-api";
import AccountApi from "../../api/auth-api";
import { message } from "antd";
import moment from "moment";

// icons
import { MdChevronRight, MdClose } from "react-icons/md";
import icon_date_colored from "../../assets/images/icon_date_colored.svg";

const Birthdate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const auth = useSelector((st) => st.authReducer);

  const [show, setShow] = useState(false);
  const [body, setBody] = useState({
    day: "",
    month: "",
    year: "",
  });

  const openModal = () => {
    setShow(true);
  };

  const closeModal = () => {
    setShow(false);
  };

  const handleSubmit = async () => {
    try {
      const accountApi = new AccountApi();
      const profileApi = new ProfileApi();
      let payload = { birth_date: `${body.year}-${body.month}-${body.day}` };

      const result = await accountApi.checkToken(auth.refresh_token);
      if (result.status === "success") {
        const profileRes = await profileApi.updateBirthDate(
          { ...payload },
          auth.access_token
        );

        if (profileRes.status === "success") {
          message.success(profileRes.message);
          const { data } = await accountApi.getDetailCustomer(
            auth.access_token
          );

          dispatch(setAuth({ ...auth, ...data }));

          // setIsSubmit(true);
          closeModal();
        } else {
          closeModal();
          // message.error(profileRes.message);
        }
      } else {
        history.push("/login");
        dispatch(resetAuth());
      }
    } catch (error) {
      console.log(error.response);
    }
  };

  const onChangeBody = (event, state, limit) => {
    if (event.length <= limit) {
      setBody({ ...body, [state]: +event });
    } else {
      return null;
    }
  };

  return (
    <>
      <div
        className="d-flex align-items-center pointer  px-2"
        onClick={openModal}
      >
        <img src={icon_date_colored} width={25} height={25} alt="" />

        <div className="ms-3">
          <h6 className="text-muted ">Tanggal Lahir</h6>
          <h5>
            {auth?.birth_date
              ? moment(auth?.birth_date).format("DD MMMM YYYY")
              : "Belum Memasukan Data"}
          </h5>
        </div>
        <div
          className="d-flex ms-auto align-items-center"
          style={{ marginTop: "-10px" }}
        >
          <MdChevronRight className="" />
        </div>
      </div>
      <Modal
        contentClassName="rounded-card p-2"
        show={show}
        onHide={closeModal}
        className="p-3"
        centered
      >
        <div className="d-flex p-2">
          <p className="fw-bold">Tanggal Lahir</p>
          <MdClose className="ms-auto me-2 pointer" onClick={closeModal} />
        </div>
        <Modal.Body>
          <div className="">
            <div className="d-flex justify-content-around">
              <InputGroup
                className="border rounded-input"
                style={{ width: "30%" }}
              >
                <FloatingLabel
                  className="rounded-input"
                  label="Hari"
                  style={{ fontSize: "12px", width: "100%" }}
                >
                  <Form.Control
                    type="number"
                    className="border-0 rounded-input"
                    placeholder="m"
                    value={body.day ? body.day : undefined}
                    defaultValue={moment(auth?.birth_date).format("DD")}
                    onChange={(ev) => onChangeBody(ev.target.value, "day", 2)}
                    style={{ height: "50px" }}
                  ></Form.Control>
                </FloatingLabel>
              </InputGroup>
              <InputGroup
                className="border rounded-input"
                style={{ width: "30%" }}
              >
                <FloatingLabel
                  className="rounded-input"
                  label="Bulan"
                  style={{ fontSize: "12px", width: "100%" }}
                >
                  <Form.Control
                    type="number"
                    className="border-0 rounded-input"
                    placeholder="m"
                    value={body.month ? body.month : undefined}
                    defaultValue={moment(auth?.birth_date).format("MM")}
                    onChange={(ev) => onChangeBody(ev.target.value, "month", 2)}
                    style={{ height: "50px" }}
                  ></Form.Control>
                </FloatingLabel>
              </InputGroup>
              <InputGroup
                className="border rounded-input"
                style={{ width: "30%" }}
              >
                <FloatingLabel
                  className="rounded-input"
                  label="Tahun"
                  style={{ fontSize: "12px", width: "100%" }}
                >
                  <Form.Control
                    type="number"
                    className="border-0 rounded-input"
                    placeholder="m"
                    value={body.year ? body.year : undefined}
                    defaultValue={moment(auth?.birth_date).format("YYYY")}
                    onChange={(ev) => onChangeBody(ev.target.value, "year", 4)}
                    style={{ height: "50px" }}
                  ></Form.Control>
                </FloatingLabel>
              </InputGroup>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <p className="text-primary pointer me-4" onClick={closeModal}>
            Batalkan
          </p>
          <Button
            size="sm"
            variant="primary"
            className="float-end rounded-pill px-4 py-2"
            onClick={() => {
              handleSubmit();
            }}
          >
            Simpan
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Birthdate;
