import React, { Component } from "react";
import { Row, Col, Accordion } from "react-bootstrap";
import Countdown, { zeroPad } from "react-countdown";
import { connect } from "react-redux";
import moment from "moment";
import payment_helper from "../../../helper/payment_helper";

class EWallet extends Component {
  handleContent = (payment_method_id) => {
    let component = [];
    const result = [...payment_helper.getContent(payment_method_id)];
    let id = 0;
    for (const iterator of result) {
      let content = [];
      [...iterator.step].map((x, index) => {
        content.push(<li key={index}>{x}</li>);
      });
      component.push(
        <Accordion.Item eventKey={id}>
          <Accordion.Header>
            <p className="mb-0 fw-medium">{iterator.type}</p>
          </Accordion.Header>
          <Accordion.Body>
            <ul className="mb-0 text-muted h6">{content}</ul>
          </Accordion.Body>
        </Accordion.Item>
      );
      id++;
    }
    if (result.length === 0) {
      component.push(<div></div>);
      return component;
    } else {
      return <Accordion defaultActiveKey="0">{component}</Accordion>;
    }
  };

  render() {
    const renderer = ({ hours, minutes, seconds, completed }) => {
      if (completed) {
        // Render a completed state
        return (
          <span className="text-muted fw-medium p">Sesi Waktu Habis!</span>
        );
      } else {
        // Render a countdown
        return (
          <span className="lipstick fw-bold mb-0 h2">
            {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
          </span>
        );
      }
    };

    return (
      <div>
        <Row className="mt-5">
          <Col xs={{ span: 6, offset: 3 }}>
            <img
              className="img-fluid"
              src={this.props.activePaymentReducer.logo}
              alt=""
            />
          </Col>
          <Col xs={{ span: 10, offset: 1 }} className="text-center mt-4">
            <h3 className="fw-light">Selesaikan Pembayaranmu</h3>
            <p className="text-muted">
              Tagihan Pesanan sudah dikirimkan ke nomor{" "}
              {this.props.activePaymentReducer.payment_name} yang kamu
              daftarkan, buka aplikasi{" "}
              {this.props.activePaymentReducer.payment_name} anda untuk
              menyelesaikan pembayaran.
            </p>
          </Col>
          <Col xs={{ span: 8, offset: 2 }} className="text-center mt-4">
            {this.props.activePaymentReducer.data.payment.qr_code ? (
              <div>
                <img
                  className="img-fluid"
                  src={this.props.activePaymentReducer.data.payment.qr_code}
                  alt=""
                />
              </div>
            ) : null}

            <p className="mb-0">Batas Akhir Pembayaran</p>
            <p className="fw-bold">
              {moment(
                this.props.activePaymentReducer.data.payment.expired_at,
                "YYYY-MM-DD HH:mm:ss"
              )
                .add(7, "hours")
                .format("LLLL")}{" "}
              WIB
            </p>
            <div className="rc-ice py-3">
              <Countdown
                renderer={renderer}
                date={this.props.activePaymentReducer.data.payment.expired_at}
              />
            </div>
          </Col>
        </Row>
        <hr style={{ background: "#a7a7a7" }} />
        <Row className="my-3">
          <Col xs={{ span: 4, offset: 1 }}>
            <p className="text-muted mb-0">
              Nomor {this.props.activePaymentReducer.payment_name}
            </p>
          </Col>
          <Col xs={{ span: 4, offset: 1 }}>
            <p className="fw-bold mb-0">
              : +{this.props.activePaymentReducer.data.payment.phone_number}
            </p>
          </Col>
          <br />
          <Col xs={{ span: 4, offset: 1 }}>
            <p className="text-muted mb-0">Total Pembayaran</p>
          </Col>
          <Col xs={{ span: 4, offset: 1 }}>
            <p className="fw-bold mb-0">
              : Rp{" "}
              {parseInt(
                this.props.activePaymentReducer.data.payment.amount || 0
              )
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
            </p>
          </Col>
          {(this.props.activePaymentReducer.data.payment.deeplink ||
            this.props.activePaymentReducer.data.payment.desktop_url) && (
            <>
              <Col xs={{ span: 4, offset: 1 }}>
                <p className="text-muted mb-0">Link Pembayaran</p>
              </Col>
              <Col xs={{ span: 4, offset: 1 }}>
                {": "}
                <a
                  href={
                    this.props.activePaymentReducer.data.payment.deeplink ||
                    this.props.activePaymentReducer.data.payment.desktop_url
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="fw-bold mb-0">Klik Disini</p>
                </a>
              </Col>
            </>
          )}
        </Row>

        <h4 className="fw-bold mb-2">Cara Pembayaran</h4>
        {[
          ...payment_helper.getContent(
            this.props.activePaymentReducer.payment_method_id
          ),
        ].length > 0
          ? this.handleContent(
              this.props.activePaymentReducer.payment_method_id
            )
          : null}

        <div className="rc-ice mt-3 p-3">
          <ul className="h6 text-muted mb-0">
            <li>
              Driver patner elog akan melakukan penjemputan setelah Pembayaran
              terferivikasi
            </li>
            <li>Catatan lainya jika ada silahkan letakan disini</li>
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  activePaymentReducer: state.activePaymentReducer,
});
export default connect(mapStateToProps)(EWallet);
