const initialState = {
  my_partner: {
    data: [],
    meta: {},
  },
  available_partner: {
    data: [],
    meta: {},
  },
  partnerProfile: {},
  partnerVehicle: [],
  partnerVehicleContract: [],
  vehicleDetail: {},
  procurement_list: {
    data: [],
    meta: {},
  },
  headerInformation: {},
  selectedProcurement: null,
  vehicleType: [],
  loading: false,
};

export const partnerManagementReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "SET_PARTNER_MGT":
      return { ...state, ...payload };
    case "SET_PARTNER_PROFILE":
      return { ...state, partnerProfile: { ...payload } };
    case "SET_PARTNER_VEHICLE":
      return { ...state, partnerVehicle: { ...payload } };
    case "SET_PARTNER_VEHICLE_CONTRACT":
      return { ...state, partnerVehicleContract: { ...payload } };
    case "SET_PARTNER_VEHICLE_DETAIL":
      return { ...state, vehicleDetail: { ...payload } };
    case "SET_PARTNER_PROCUREMENT_HEADER":
      return { ...state, headerInformation: { ...payload } };
    case "SET_SELECTED_PROCUREMENT":
      return { ...state, selectedProcurement: payload };
    case "SET_VEHICLE_TYPE":
      return { ...state, vehicleType: payload };
    default:
      return state;
  }
};
