import PartnerApi from "../../../api/Master/partner-api";
import { resetAuth } from "../auth_action";

export const getPartnerSelectable = (params) => async (dispatch, state) => {
  try {
    const api = new PartnerApi();

    dispatch({
      type: "SET_PARTNER",
      payload: { loading: true },
    });

    const response = await api.getPartnerSelectable(
      state().authReducer.access_token,
      params
    );

    if (response.status === 401) {
      dispatch(resetAuth());
    }
    const { data, ...meta } = response;
    dispatch({
      type: "SET_PARTNER",
      payload: { loading: false, selectable: { data, meta } },
    });
  } catch (error) {
    console.log(error?.response);
  }
};
