import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Modal,
  Form,
  Row,
  Col,
  InputGroup,
  FloatingLabel,
} from "react-bootstrap";

import useBreakpoint from "../../../../components/responsiveUtils/useBreakpoint";
import { FaSearch } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import "../style.scss";
import { FiPlusCircle } from "react-icons/fi";
import UploadDocument from "./UploadDocument";
import { setAdditionalData } from "../../../../redux/action/kirimbarang_action";
import iconDocs from "../../../../assets/images/icon-docs.svg";

const AdditionalDataForm = (props) => {
  /*======================== Hooks ======================== */

  const { isMobile } = useBreakpoint();
  const dispatch = useDispatch();
  const additionalData = useSelector(
    (st) => st.kirimbarangReducer.additionalData
  );

  /*======================== Props ======================== */

  const { isOpen, handleClose, handleSubmit } = props;

  /*======================== UseState ===================== */

  const [noTF, setNoTF] = useState("");
  const [document, setDocument] = useState([
    {
      id: 1,
      document_path: "",
      document_file_name: "",
      document_name: "",
    },
  ]);

  /*======================== Handler ====================== */

  const handleOnSelect = () => {
    let payload = {
      noTF: noTF,
      file: document
    }
    dispatch(setAdditionalData({ ...payload }));
    handleClose();
  };

  const handleOnClose = () => {
    handleClose();
  };

  const updateDocument = (value) => {
    let newArr = [...document];
    newArr[value.index] = value.value;
    setDocument(newArr);
  };

  const addDocument = () => {
    setDocument([
      ...document,
      {
        id: document[document.length - 1].id + 1,
        document_path: "",
        document_file_name: "",
        document_name: "",
      },
    ]);
  };

  const deleteDocument = (item) => {
    setDocument((old) => [...old.filter((value) => value.id !== item.id)]);
  };

  const initFile = (fileData) => {
    if (fileData && fileData.length > 0) {
      let data = [];
      fileData.forEach((item, i) => {
        data.push({
          id: item.id,
          document_path: item.document_path,
          document_file_name: item.document_file_name,
          document_name: item.document_name,
        });
      });
      setDocument(data);
    } else {
      setDocument([
        {
          id: 1,
          document_path: "",
          document_file_name: "",
          document_name: "",
        },
      ]);
    }
  };

  /*======================== UseEffect =====================*/

  useEffect(() => {
    if (additionalData) {
      setNoTF(additionalData.noTF);
      initFile(additionalData.file);
    }
  }, [additionalData]);

  /*======================== Return ======================== */

  return (
    <Modal
      contentClassName="rounded-card p-2"
      show={isOpen}
      onHide={handleOnClose}
      className="ice p-3"
      centered
    >
      <div className="d-flex p-2 border-bottom">
        <h5 className="fw-bold">Data Tambahan (Optional)</h5>
        <MdClose className="ms-auto me-2 pointer" onClick={handleOnClose} />
      </div>
      <Modal.Body>
        <div className="additional-data-form">
          <InputGroup className="mt-1">
            <InputGroup.Text className="border-0">
              <img src={iconDocs} className="icon-form" alt="" />
            </InputGroup.Text>
            <FloatingLabel
              label="Nomor Pengiriman"
              style={{ width: isMobile ? "88.5%" : "92.2%", fontSize: "12px" }}
            >
              <Form.Control
                className="border-0"
                type="text"
                placeholder="Nomor Pengiriman"
                value={noTF}
                onChange={(e) => {
                  setNoTF(e.target.value);
                }}
              />
            </FloatingLabel>
          </InputGroup>

          {/* {document.length > 0
            ? document.map((item, index) => (
                <UploadDocument
                  {...{
                    item,
                    index,
                    updateDocument,
                    addDocument,
                    deleteDocument,
                  }}
                  key={item.id}
                />
              ))
            : null} */}

          {/* <div
            style={{
              paddingLeft: "12px",
              color: "#CE1771",
              fontSize: "13px",
              cursor: "pointer",
            }}
            onClick={() => addDocument()}
          >
            <FiPlusCircle />
            <span className="ps-4">Tambah Dokumen</span>
          </div> */}
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className="w-50 float-end">
          <Button
            size="sm"
            className="w-100 fw-bold rounded-pill text-capitalize mt-2"
            onClick={handleOnSelect}
          >
            Submit
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

AdditionalDataForm.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
};

export default AdditionalDataForm;
