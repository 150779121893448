import React, { useState } from "react";
import { Modal } from "react-bootstrap";

// icons
import { FiInfo } from "react-icons/fi";
import { MdClose } from "react-icons/md";

function InfoModalSimple() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <FiInfo className="lipstick pointer" onClick={handleShow} />

      <Modal
        contentClassName="modal-100 p-3"
        show={show}
        onHide={handleClose}
        className=""
        centered
      >
        <div>
          <p className="fw-bold mb-2">
            <MdClose
              size={16}
              className="pointer"
              onClick={handleClose}
              style={{ marginTop: "-4px" }}
            />{" "}
            Optimalkan Rute
          </p>
          <p className="mb-0">
            eLOG akan mengatur alamat pengiriman Anda untuk waktu yang cepat dan
            efisien (alamat pengiriman tidak akan diubah).
          </p>
        </div>
      </Modal>
    </>
  );
}

export default InfoModalSimple;
