import { useState, useEffect } from "react";
import { Row, Col, Dropdown, Button, Card } from "react-bootstrap";
import { Spin } from "antd";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getProcurementPerPartner, setSelectedProcurementName } from "../../../redux/action/Partner/partner_action";
import noData from "../../../assets/images/no-data.png";
import CustomTable from "../../../components/table/customTable";
import { FiChevronLeft } from "react-icons/fi";
import moment from "moment";

const PartnerPenawaranDetail = () => {
  /*======================== Hooks ======================== */
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const data = useSelector((st) => st.partnerManagementReducer.procurement_list.data);
  const meta = useSelector((st) => st.partnerManagementReducer.procurement_list.meta);
  const loading = useSelector((st) => st.partnerManagementReducer.loading);

  /*======================== UseState ======================== */
  const [params, setParams] = useState({
    limit: 10,
    page: 1,
    sort_by: "created_at",
    order_by: "DESC",
    is_contract: false,
  });
  const [sort, setSort] = useState("Terbaru");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  /*======================== Handler ======================== */

  const handleChange = (type, value) => {
    switch (type) {
      case "sort":
        setPage(1);
        setSort(value);
        setParams({
          ...params,
          sort_by: "created_at",
          order_by: value === "Terbaru" ? "DESC" : "ASC",
          page: 1,
        });
        break;
      case "page":
        setPage(value);
        setParams({ ...params, page: value });
        break;
      case "limit":
        setPage(1);
        setLimit(value);
        setParams({ ...params, limit: value, page: 1 });
        break;

      default:
        break;
    }
  };

  const handleBack = () => {
    history.goBack();
  };

  const toDetail = (id, name) => {
    dispatch(setSelectedProcurementName(name));
    history.push(location.pathname + "/" + id)
  }

  /*======================== UseEffect ======================== */

  useEffect(() => {
    dispatch(getProcurementPerPartner({ ...params }, id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  /*======================== Others ======================== */
  const sortOptions = [
    {
      title: "Terbaru",
    },
    {
      title: "Terlama",
    },
  ];

  const tableProps = {
    bordered: false,
    expandable: false,
    title: undefined,
    showHeader: true,
    rowSelection: false,
  };

  const tableColumns = [
    {
      title: "Nama Penawaran",
      dataIndex: "name",
      sorter: false,
      className: "text-center px-3",
    },
    {
      title: "Rentang Penawaran",
      // dataIndex: "start_offering_date",
      sorter: false,
      className: "px-3",
      render: (_, { start_offering_date, end_offering_date }) => {
        return (
          <p className="mb-0">
            {moment(start_offering_date).format("DD MMM YYYY")} -{" "}
            {moment(end_offering_date).format("DD MMM YYYY")}{" "}
          </p>
        );
      },
    },
    {
      title: "Penawaran diterima",
      dataIndex: "total_offer",
      sorter: false,
      className: "px-3",
      render: (_, { accepted_offer }) => {
        return <p className="mb-0" style={{color: 'green'}}>{accepted_offer} Penawaran </p>;
      },
    },
    {
      title: "Total Penawaran",
      dataIndex: "total_offer",
      sorter: false,
      className: "px-3",
      render: (_, { total_offer, unread_offer }) => {
        return <p className="mb-0">
          {total_offer} Tawaran 
          <span style={{color: 'red'}}> ({unread_offer} Baru)</span>
        </p>;
      },
    },
    {
      title: "Action",
      key: "action",
      sorter: false,
      className: "px-3",
      render: (_, { id, name }) => {
        return (
          <Button
            size="sm"
            className="fw-bold px-4 text-capitalize"
            onClick={() => toDetail(id,name)}
          >
            Detail
          </Button>
        );
      },
    },
  ];

  // const meta = {
  //   prev_page: null,
  //   last_page: 1,
  //   next_page: null,
  //   total_data: 1,
  //   status: "success",
  //   message: "Berhasil mendapatkan list procurement",
  // };

  // const data = [
  //   {
  //     partner_offer_header_id: "5500337d-9e7d-4d7b-a5ff-40b5789c522c",
  //     partner_id: "e8d3225d-99de-4c07-986c-fa4e529c4aa5",
  //     delivery_procurement_id: "31d90b3c-454f-47e0-bf1d-247de4c0aa74",
  //     name: "Antar barang seluruh indonesia",
  //     start_offering_date: "2022-08-31T17:00:00.000Z",
  //     end_offering_date: "2022-10-30T17:00:00.000Z",
  //     created_at: "2022-10-01T04:27:07.000Z",
  //     updated_at: "2022-10-01T11:30:14.000Z",
  //     total_offer: "1",
  //     unread_offer: "0",
  //     accepted_offer: "0",
  //   },
  // ];

  /*======================== Return ======================== */

  return (
    <Row>
      <Col xs={12}>
        <div className="bg-white rounded-card w-full py-2 ps-3 mb-4">
          <div className="d-flex flex-row align-items-center">
            <FiChevronLeft
              fontSize={25}
              className="pointer me-3"
              onClick={handleBack}
            />
            <div>
              <h1>Daftar Tawaran Partner</h1>
              <p className="text-muted">
                List tawaran partner untuk penawaran-penawaran anda
              </p>
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center">
          <h4 className="mt-2 me-2" style={{ fontWeight: "600" }}>
            Urutkan Berdasarkan :
          </h4>
          <Dropdown className="d-inline mx-2">
            <Dropdown.Toggle
              id="dropdown-autoclose-true"
              size="sm"
              variant="light"
              className="bg-white text-secondary px-3 border shadow-sm rounded"
            >
              <strong>{sort}</strong>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {sortOptions.map((item, index) => (
                <Dropdown.Item
                  key={index}
                  href="#"
                  onClick={() => {
                    handleChange("sort", item.title);
                  }}
                >
                  {item.title}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>

        {data.length > 0 && (
          <div className="mt-2">
            <CustomTable
              classProps="table-alamat"
              {...{
                tableProps,
                tableColumns,
                handleChange,
                limit,
                page,
                loading,
                data,
              }}
              total_data={meta.total_data}
            />
          </div>
        )}
        {data.length < 1 &&
          (!loading ? (
            <div
              className="d-flex flex-column align-items-center justify-content-center"
              style={{ marginTop: "5rem" }}
            >
              <img src={noData} alt="no-data" />
              <p className="mt-1">Anda belum memiliki penawaran kontrak</p>
            </div>
          ) : (
            <Card
              className="p-4 rounded-card border-0 d-flex align-items-center justify-content-center mt-4"
              style={{
                height: "350px",
              }}
            >
              <Spin />
            </Card>
          ))}
      </Col>
    </Row>
  );
};

export default PartnerPenawaranDetail;
