const initState = {
  isLoading: false,
  logo: null,
  payment_method_id: null,
  payment_type: null,
  payment_name: null,
  data: {
    "order_id": null,
    "invoice_number": null,
    "booking_rent_id": null,
    "is_special_order": 0,
    "payment": {
      "partner_transaction_id": null,
      "user_id": null,
      "payment_method_id": null,
      "order_id": null,
      "external_id": null,
      "amount": null,
      "amount_raw": null,
      "amount_admin": null,
      "status": null,
      "phone": null,
      "account_name": null,
      "va_number": null,
      "desktop_url": null,
      "mobile_url": null,
      "deeplink": null,
      "qr_code": null,
      "payment_code": null,
      "expired_at": null,
      "created_at": null,
      "updated_at": null
    }
  }
};

export const activePaymentReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_ACTIVE_PAYMENT":
      return { ...state, ...action.payload };
    case "RESET_ACTIVE_PAYMENT":
      return { ...initState, ...action.payload };
    default:
      return state;
  }
};
