// const initState = {
//   catatan: "gerwher",
//   isCod: false,
//   isDetailFilled: false,
//   lat: -6.198654899999999,
//   lng: 106.8458209,
//   lokasi:
//     "RSCM Kencana, Jalan Pangeran Diponegoro, RW.5, Kenari, Kota Jakarta Pusat, Daerah Khusus Ibukota Jakarta",
//   namaPenerima: "wgweg",
//   name: "",
//   nominalCod: null,
//   nomorTelepon: "36346436",
//   patokan: "rscm",
//   tipe: "SCHEDULED",
//   startDate: "",
//   endDate: "",
//   periodType: "WEEKLY",
//   payment_method_id: null,
//   detailMap: [
//     { long_name: "No.71", short_name: "No.71", types: ["street_number"] },
//     {
//       long_name: "Jalan Pangeran Diponegoro",
//       short_name: "Jl. Pangeran Diponegoro",
//       types: ["route"],
//     },
//     { long_name: "5", short_name: "5", types: ["political"] },
//     {
//       long_name: "Kenari",
//       short_name: "Kenari",
//       types: ["administrative_area_level_4", "political"],
//     },
//     {
//       long_name: "Kecamatan Senen",
//       short_name: "Kec. Senen",
//       types: ["administrative_area_level_3", "political"],
//     },
//     {
//       long_name: "Kota Jakarta Pusat",
//       short_name: "Kota Jakarta Pusat",
//       types: ["administrative_area_level_2", "political"],
//     },
//     {
//       long_name: "Daerah Khusus Ibukota Jakarta",
//       short_name: "Daerah Khusus Ibukota Jakarta",
//       types: ["administrative_area_level_1", "political"],
//     },
//     {
//       long_name: "Indonesia",
//       short_name: "ID",
//       types: ["country", "political"],
//     },
//     { long_name: "10430", short_name: "10430", types: ["postal_code"] },
//   ],
//   vType: {
//     data: [
//       {
//         id: "17f30507-9be0-4e80-b74e-197a2bf83184",
//         name: "Engkel Bak/CDE Bak",
//         description: "ini deskripsi Engkel Bak/CDE Bak",
//         icon: "https://elog-stg.s3.ap-southeast-1.amazonaws.com/DEV/icon/6c9971bd-5d7e-4b71-ad91-1a5ddedd0054.jpeg",
//         max_weight: 310,
//         dimension_w: 170,
//         dimension_l: 160,
//         dimension_h: 2200,
//         volume: 8.4,
//       },
//       {
//         id: "6951ef0e-2c89-4a64-a153-f3bf3d0e83cb",
//         name: "Small Pick-up",
//         description: "ini deskripsi Small Pick-up",
//         icon: "https://elog-stg.s3.ap-southeast-1.amazonaws.com/DEV/icon/e88dedf9-b622-4c37-872a-9d0e445dae4b.jpg",
//         max_weight: 200,
//         dimension_w: 156,
//         dimension_l: 120,
//         dimension_h: 800,
//         volume: 1.7,
//       },
//       {
//         id: "bf7dc2b2-c7b9-4d6d-b01c-5d0b0d8ec3db",
//         name: "City Car",
//         description: "",
//         icon: "https://elog-stg.s3.ap-southeast-1.amazonaws.com/DEV/icon/bbd59156-cf07-40eb-ab33-d2154ca45a8d.jpeg",
//         max_weight: 150,
//         dimension_w: 100,
//         dimension_l: 300,
//         dimension_h: 75,
//         volume: 2.25,
//       },
//       {
//         id: "daf70712-28ba-4925-87f6-08f7dd181c4d",
//         name: "L300 Pick-up",
//         description: "ini deskripsi L300 Pick-up",
//         icon: "https://elog-stg.s3.ap-southeast-1.amazonaws.com/DEV/icon/06aa5cb4-b7a3-4598-9180-c6f2afa7ad63.jpg",
//         max_weight: 240,
//         dimension_w: 156,
//         dimension_l: 120,
//         dimension_h: 1000,
//         volume: 4.5,
//       },
//     ],
//     isLoading: false,
//   },
//   partnerService: { data: [], isLoading: false },
//   officialPartnerService: { data: [], isLoading: false },
//   selectedVType: {},
//   selectedPartnerService: {},
//   detailOrder: {
//     driver_availability_rent: 0,
//     helper_availability_send: 0,
//     helper_availability_rent: 0,
//     driver_price_rent: 0,
//     helper_price_rent_send: 0,
//     helper_price_rent: 0,
//     driver_availability_rent_selected: false,
//     helper_availability_send_selected: false,
//     helper_availability_rent_selected: false,
//     total_helper_requested: 1,
//     total_price: 0,
//   },
//   totalPrice: {
//     rent_day: 0,
//     vehicle_price: 0,
//     driver_price: 0,
//     helper_price: 0,
//     total_price: 0,
//     final_price: 0,
//     admin_fee: 0,
//     customer_platform_fee: 0,
//     customer_tax_fee: 0,
//     deposit: 0,
//     final_price_with_admin_fee: 0,
//     partner_platform_fee: 0,
//     partner_share: 0,
//     service_price: 0,
//     vehicle_id: "",
//   },
// };

// admin_fee: 4500
// customer_platform_fee: 0
// customer_tax_fee: 0
// deposit: 1293000
// driver_price: 0
// final_price: 5603000
// final_price_with_admin_fee: 5607500
// helper_price: 210000
// partner_platform_fee: 215500
// partner_share: 4094500
// rent_day: 7
// service_price: 4310000
// total_price: 5603000
// vehicle_id: "167ac33f-db93-4d58-a7ec-ab4bc0a8acf2"
// vehicle_price: 4100000

const initState = {
  catatan: "",
  isCod: false,
  isDetailFilled: false,
  lat: null,
  lng: null,
  lokasi: null,
  address_name: null,
  namaPenerima: "",
  name: "",
  nominalCod: null,
  nomorTelepon: "",
  patokan: "",
  tipe: null, // NOW / SCHEDULED
  startDate: null,
  endDate: null,
  periodType: null,
  payment_method_id: null,
  detailMap: [],
  vType: {
    isLoading: false,
    data: [],
  },
  partnerService: {
    isLoading: false,
    data: [],
  },
  selectedVType: {},
  officialPartnerService: { data: [], isLoading: false },
  selectedPartnerService: {},
  detailOrder: {
    driver_availability_rent: 0,
    helper_availability_send: 0,
    helper_availability_rent: 0,
    driver_price_rent: 0,
    helper_price_rent_send: 0,
    helper_price_rent: 0,
    driver_availability_rent_selected: false,
    helper_availability_send_selected: false,
    helper_availability_rent_selected: false,
    total_helper_requested: 0,
    total_price: 0,
  },
  totalPrice: {
    rent_day: 0,
    vehicle_price: 0,
    driver_price: 0,
    helper_price: 0,
    total_price: 0,
    final_price: 0,
    admin_fee: 0,
    customer_platform_fee: 0,
    customer_tax_fee: 0,
    deposit: 0,
    final_price_with_admin_fee: 0,
    partner_platform_fee: 0,
    partner_share: 0,
    service_price: 0,
    vehicle_id: "",
  },
  historyPO:[],
  infoPengantaranMutasi:[]
};

export const sewaKendaraanReducer = (state = initState, action) => {
  switch (action.type) {
    case "UPDATE_PENGAMBILAN_SEWA_KENDARAAN_DETAIL":
      return { ...state, ...action.payload };
    case "RESET_DATA":
      return { ...initState };
    case "GET_HISTORY_PO":
      return { ...initState, historyPO: action.payload };
    case "GET_INFO_PENGANTARAN_MUTASI":
      return { ...initState, infoPengantaranMutasi: action.payload };
    default:
      return state;
  }
};
