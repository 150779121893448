import React, { useEffect } from "react";
import { Card } from "react-bootstrap";
import Gmap from "../../components/gMap";
import TabForgotPassword from "../../components/tabAuth/forgotPassword";
import { connect, useSelector } from "react-redux";
import useBreakpoint from "../../components/responsiveUtils/useBreakpoint";
import { useHistory } from "react-router-dom";
import { hideSidenavMobile } from "../../components/layouts/layout_helper";

// icons
import { IoChevronBack } from "react-icons/io5";

const ForgotPassword = () => {
  const { isMobile } = useBreakpoint();
  const authReducer = useSelector((st) => st.authReducer);
  const history = useHistory();

  useEffect(() => {
    hideSidenavMobile();
  }, [isMobile]);

  return (
    <div style={{ height: "100vh", width: "100%" }}>
      {authReducer.access_token === null && (
        <>
          {isMobile && (
            <div className="w-100 p-2 head-menu shadow-md">
              <div className="d-flex mt-auto mb-2 align-items-center w-100 px-2">
                <div className="d-flex">
                  <IoChevronBack
                    onClick={() => history.goBack()}
                    style={{ fontSize: 25 }}
                    className="pointer me-2"
                  />

                  <h4 className="mt-1">{"Lupa Kata Sandi"}</h4>
                </div>
              </div>
            </div>
          )}
          <Card
            className={`${
              isMobile ? "card-mobile" : " card-absolute height-auto"
            }`}
          >
            <Card.Body className="pb-0 flex-i">
              <TabForgotPassword />
            </Card.Body>
          </Card>
        </>
      )}
      {!isMobile && <Gmap />}
    </div>
  );
};

export default ForgotPassword;
