const initialState = {
  historyBalanceList: { loading: false, data: [] },
  bankAccounts: { loading: false, data: [] },
  disbursementMethods: { loading: false, data: [] },
  selectedBankAccount: {},
};

export const balanceReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "SET_HISTORY_BALANCE_LIST":
      return { ...state, historyBalanceList: { ...payload } };
    case "SET_BANK_ACCOUNTS":
      return { ...state, bankAccounts: { ...payload } };
    case "SET_DISBURSEMENT_METHODS":
      return { ...state, disbursementMethods: { ...payload } };
    case "SET_SELECTED_BANK_ACCOUNT":
      return { ...state, selectedBankAccount: { ...payload } };
    default:
      return state;
  }
};
