import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Divider, Avatar } from "antd";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import util from "../../helper/util";
import {
  getStatusDesc,
  getStatusTextColor,
} from "../../helper/trx_status_helper";
import InfoBar from "../../components/infoBar/InfoBar";
import useBreakpoint from "../../components/responsiveUtils/useBreakpoint";

// icons

import iconLine from "../../assets/images/icon-line.svg";
import icon_clock_blue from "../../assets/images/icon_clock_blue.svg";
import info_kurang_bayar_mobile from "../../assets/images/info_kurang_bayar_mobile.svg";
import info_lebih_bayar_mobile from "../../assets/images/info_lebih_bayar_mobile.svg";
import icon_note_blue from "../../assets/images/icon_note_blue.svg";
import { AiOutlineUser, AiFillPhone } from "react-icons/ai";
import { MdChevronRight, MdLocationPin } from "react-icons/md";
import { IoChatbubbleEllipses } from "react-icons/io5";
import { BsFillStarFill } from "react-icons/bs";
import { FaMapMarkedAlt } from "react-icons/fa";

import InfoPesanan from "./components/Card/InfoPesanan";
import MetodePembayaran from "./components/Card/MetodePembayaran";
import PartnerKendaraan from "./components/Card/PartnerKendaraan";
import RincianPembayaran from "./components/Card/RincianPembayaran";
import DetailAlamat from "./components/Card/DetailAlamat";

const RentSection = ({
  openPartnerDetailModal,
  openContactModal,
  setShowMapTabletAndMobile,
  setShowDetailHistoryLogMobile,
  getOrderDetail,
  infoPengantaranMutasi
}) => {
  const trxDetail = useSelector((st) => st.trxHistoryReducer.trxDetail);
  const partnerDetail = useSelector((st) => st.trxHistoryReducer.partnerDetail);
  const { isMobile, isTablet, isDesktop } = useBreakpoint();

  const [expandDetailAlamat, setExpandDetailAlamat] = useState(false)

  const startDate = trxDetail?.data?.trx_booking_rent?.pickup_time
    ? `${moment(trxDetail?.data?.trx_booking_rent?.pickup_time).format(
        "DD MMM YYYY"
      )} ${moment(trxDetail?.data?.trx_booking_rent?.pickup_time).format(
        "HH : mm"
      )}`
    : null;
  const endDate = trxDetail?.data?.trx_booking_rent?.date_to_return
    ? `${moment(trxDetail?.data?.trx_booking_rent?.date_to_return).format(
        "DD MMM YYYY"
      )} ${moment(trxDetail?.data?.trx_booking_rent?.date_to_return).format(
        "HH : mm"
      )}`
    : null;

  const getLastLogStatus = () => {
    let logs = trxDetail?.data?.log_booking_status;
    return logs?.[logs?.length - 1]?.order_status;
  };

  const isTrxDone = () => {
    if (
      trxDetail?.data?.booking_status === "CANCELLED" ||
      trxDetail?.data?.booking_status === "FINISHED"
    ) {
      return true;
    }

    return false;
  };
  
  return (
    <>
      <Row className="rounded-card bg-white p-4 mt-2" hidden={isDesktop}>
        <Col
          className="text-primary underline pointer"
          onClick={() =>
            isTrxDone()
              ? setShowDetailHistoryLogMobile((prev) => !prev)
              : setShowMapTabletAndMobile((prev) => !prev)
          }
        >
          <FaMapMarkedAlt
            hidden={isTrxDone()}
            className="me-2 mb-1"
            style={{ fontSize: "20px" }}
          />
          <h4 className="d-inline text-primary ">
            {isTrxDone()
              ? "Status Lengkap Pesanan"
              : "Live Tracking dan Status Lengkap Pesanan"}
          </h4>
        </Col>
      </Row>
      
      
      {/* Info Pesanan & Detail Alamat & Partner & Kendaraan */}
      <Row>
        {/* Col */}
        <InfoPesanan trxDetail={trxDetail} infoPengantaranMutasi={infoPengantaranMutasi}/>
        <Col className="mb-3 mt-2">
          {/* Row Col */}
          <DetailAlamat 
            trxDetail={trxDetail}
            expandDetailAlamat={expandDetailAlamat}
            setExpandDetailAlamat={setExpandDetailAlamat}
            getOrderDetail={getOrderDetail}
            infoPengantaranMutasi={infoPengantaranMutasi}
          />
          <Row>
            <PartnerKendaraan
              title="Partner"
              trxDetail={trxDetail}
              partnerDetail={partnerDetail}
            />
            <PartnerKendaraan
              title="Kendaraan"
              trxDetail={trxDetail}
              partnerDetail={partnerDetail}
            />
          </Row>
        </Col>
      </Row>

      {/* Metode Pembayaran & Rincian Pembayaran */}
      <Row style={{marginTop:"-8px"}}>
        {/* Col */}
        <MetodePembayaran trxDetail={trxDetail}/>
        {/* Col */}
        <RincianPembayaran trxDetail={trxDetail}/>
      </Row>





      {/* Waktu Sewa */}
      <Row className="rounded-card bg-white p-4 mt-2"
      hidden
      >
        <Col className="">
          <img src={icon_clock_blue} alt="" className="me-2" />
          Waktu Sewa
          <div className="d-flex mt-2">
            <img
              src={iconLine}
              alt=""
              style={{ marginTop: "5px", height: "60px" }}
              className="ms-2"
            />
            <h6 className="mb-0 text-muted mt-1 ms-2">
              Waktu Pengantaran
              <span className="mt-1 fw-bold ms-2 d-block">{startDate}</span>
              <h6 className="mb-0 text-muted mt-3">
                Waktu Pengembalian
                <span className="mt-1 fw-bold ms-2 d-block">{endDate}</span>
              </h6>
            </h6>
          </div>
          <Divider orientation="left" />
          <h6 className="ms-4">
            Durasi Sewa : {trxDetail?.data?.trx_booking_rent?.rent_day} Hari
          </h6>
        </Col>
        <Col className="text-end">
          {moment(trxDetail?.data?.trx_booking_send?.order_date).format(
            "DD MMMM YYYY"
          )}{" "}
          ,
          {moment(trxDetail?.data?.trx_booking_send?.order_date).format(
            "HH : mm"
          )}
          WIB
        </Col>
      </Row>

      {/* Status */}
      <Row className="rounded-card bg-white p-2 px-4 mt-2"
        hidden
      >
        <div
          className={`d-flex ${
            isMobile ? "flex-column" : "justify-content-between"
          } `}
        >
          <h4>Status</h4>
          <h4 className={`fw-bold ${getStatusTextColor(getLastLogStatus())}`}>
            {getStatusDesc(getLastLogStatus())}
          </h4>
        </div>
        {trxDetail?.data?.booking_status === "CANCELLED" && (
          <div className="d-flex justify-content-between mt-1">
            <h6>Alasan Pembatalan</h6>
            <h6 className={`fw-bold `}>
              {trxDetail?.data?.booking_status_description}
            </h6>
          </div>
        )}
        <Divider orientation="left" />
        <Col>
          <div className="d-flex">
            <MdLocationPin className={`text-secondary me-2`} />
            <h6 className="text-muted">Lokasi Pengambilan</h6>
          </div>
          <div className="">
            <h5 className="fw-bold">
              {trxDetail?.data?.trx_booking_rent?.pickup_contact_name}
            </h5>
          </div>
          <div className="">
            <h5 className="">
              {trxDetail?.data?.trx_booking_rent?.pickup_address}
            </h5>
          </div>
        </Col>
      </Row>

      {/* Layanan */}
      <Row className="rounded-card bg-white p-2 px-4 mt-2"
      hidden
      >
        <div className="d-flex align-items-center">
          <img
            src={icon_note_blue}
            className="i-sidebar"
            width={20}
            height={20}
            style={{ marginLeft: "-20px" }}
            alt=""
          />
          <h4 className="mt-1">Layanan</h4>
        </div>
        {trxDetail?.data?.trx_booking_rent?.driver_required > 0 ||
        trxDetail?.data?.trx_booking_rent?.helper > 0 ? (
          <ul className="trx-services">
            {trxDetail?.data?.trx_booking_rent?.driver_required > 0 && (
              <li className="trx-services">
                Tenaga Supir <span className="text-success">(Termasuk)</span>{" "}
              </li>
            )}
            {trxDetail?.data?.trx_booking_rent?.helper > 0 && (
              <li className="trx-services">
                Driver Helper ({trxDetail?.data?.trx_booking_rent?.helper || 0}{" "}
                Orang)
              </li>
            )}
          </ul>
        ) : (
          <p>-</p>
        )}
      </Row>

      {/* Mitra Layanan */}
      <Row className="rounded-card bg-white p-2 px-4 mt-2"
      hidden
      >
        <div className="d-flex align-items-center justify-content-between">
          <h4>Mitra Layanan</h4>
          <div className="d-flex pointer" onClick={openPartnerDetailModal}>
            <h5 className="fw-bold me-1">
              {trxDetail?.data?.mst_partner_service?.mst_partner?.name}
            </h5>
            <MdChevronRight />
          </div>
        </div>
        <Divider orientation="left" />
        <Col xs={4} md={2}>
          {/* <Badge
                offset={[0, 65]}
                count={"✓"}
                style={{ backgroundColor: "#198754" }}
              > */}
          <Avatar
            shape="square"
            size={72}
            src={trxDetail?.data?.mst_driver?.profile_picture}
            icon={
              !trxDetail?.data?.mst_driver?.profile_picture && <AiOutlineUser />
            }
          ></Avatar>
          {/* </Badge> */}
        </Col>
        <Col>
          <div className="d-flex align-items-center">
            <h6 className="fw-bold">{trxDetail?.data?.mst_driver?.name}</h6>
            {/* <Tag color={"green"} className="mb-2 ms-2">
                  Tervaksinasi
                </Tag> */}
          </div>
          <div className="d-flex align-items-center">
            <BsFillStarFill className="text-warning mb-2 me-1" />
            <h6 className="fw-bold">
              {trxDetail?.data?.mst_driver?.rating || 0}
            </h6>
            <h6 className="text-muted">
              | {trxDetail?.data?.mst_driver?.total_order || 0} Order Selesai
            </h6>
          </div>
          <div hidden={isMobile}>
            <h5 className="fw-bold">
              {
                trxDetail?.data?.trx_booking_rent?.trx_booking_rent_vehicle
                  ?.mst_vehicle?.mst_vehicle_subtype?.name
              }{" "}
              -{" "}
              {
                trxDetail?.data?.trx_booking_rent?.trx_booking_rent_vehicle
                  ?.mst_vehicle?.plate_number
              }
            </h5>
          </div>
        </Col>
        <Col xs={2}>
          {!isTrxDone() && (
            <div className="d-flex flex-column align-items-center gap-2 justify-content-end">
              <Button variant="outline-primary">
                <IoChatbubbleEllipses />
              </Button>
              <Button variant="outline-primary" onClick={openContactModal}>
                <AiFillPhone />
              </Button>
              {/* <ContactModal /> */}
            </div>
          )}
        </Col>
      </Row>

      {/* Rincian Pembayaran */}
      <Row className="rounded-card bg-white p-2 px-4 mt-2"
      hidden
      >
        <div className="d-flex align-items-center justify-content-between">
          <h4>Rincian Pembayaran</h4>
        </div>
        <Divider orientation="left" />
        <div className="d-flex align-items-center justify-content-between">
          <h5>Metode Pembayaran</h5>
          <h5>{trxDetail?.data?.trx_payment?.payment_method_name}</h5>
        </div>
        <Divider orientation="left" />
        <div className="d-flex align-items-center justify-content-between">
          <h5>Tarif Sewa Unit</h5>
          <h5>
            Rp{" "}
            {util.thousandSeparatorPure(
              trxDetail?.data?.trx_booking_rent?.vehicle_price
            )}
          </h5>
        </div>
        {(trxDetail?.data?.trx_booking_rent?.driver_required > 0 ||
          trxDetail?.data?.trx_booking_rent?.helper > 0) && (
          <div className="">
            <h5>Layanan Tambahan</h5>
            <ul className="addtional">
              {trxDetail?.data?.trx_booking_rent?.driver_required > 0 && (
                <li>
                  Tenaga Supir
                  <span className="float-end">
                    Rp{" "}
                    {util.thousandSeparatorPure(
                      trxDetail?.data?.trx_booking_rent?.driver_price || 0
                    )}
                  </span>
                </li>
              )}
              {trxDetail?.data?.trx_booking_rent?.helper > 0 && (
                <li>
                  Helper
                  <span className="float-end">
                    Rp{" "}
                    {util.thousandSeparatorPure(
                      trxDetail?.data?.trx_booking_rent?.helper_price || 0
                    )}
                  </span>
                </li>
              )}
            </ul>
          </div>
        )}
        <div className="d-flex align-items-center justify-content-between">
          <h5>Pajak</h5>
          <h5>
            Rp{" "}
            {util.thousandSeparatorPure(
              trxDetail?.data?.customer_service_fee +
                trxDetail?.data?.customer_vat
            ) || 0}
          </h5>
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <h5>Biaya Deposit</h5>
          <h5>
            Rp{" "}
            {util.thousandSeparatorPure(
              trxDetail?.data?.trx_booking_rent?.deposit
            )}
          </h5>
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <h5>Admin Fee</h5>
          <h5>
            Rp{" "}
            {util.thousandSeparatorPure(
              trxDetail?.data?.trx_payment?.amount_admin
            )}
          </h5>
        </div>
        <div className="d-flex align-items-center justify-content-between ">
          <h5>Diskon</h5>
          <h5>
            (Rp{" "}
            {util.thousandSeparatorPure(
              trxDetail?.data?.elog_discount + trxDetail?.data?.partner_discount
            )}
            )
          </h5>
        </div>
        <Divider orientation="left" />
        <Row>
          <Col hidden={isMobile}></Col>
          <Col xs={12} md={8}>
            <div className="d-flex align-items-center justify-content-between">
              <h5
                className={
                  trxDetail?.data?.booking_status !== "FINISHED" && "fw-bold"
                }
              >
                Total Biaya Layanan
              </h5>
              <h5
                className={
                  trxDetail?.data?.booking_status !== "FINISHED" && "fw-bold"
                }
              >
                (Rp{" "}
                {util.thousandSeparatorPure(
                  trxDetail?.data?.trx_payment?.amount
                )}
                )
              </h5>
            </div>
            {trxDetail?.data?.booking_status === "FINISHED" && (
              <>
                <div className="d-flex align-items-center justify-content-between w-75 mt-4">
                  <h5 className="text-left">Biaya Kerusakan</h5>
                  <h5 className="">
                    Rp{" "}
                    {util.thousandSeparatorPure(
                      trxDetail?.data?.trx_booking_rent
                        ?.trx_booking_rent_vehicle?.damage_compensation || 0
                    )}
                  </h5>
                </div>
                <div className="d-flex align-items-center justify-content-between w-75">
                  <h5 className="text-left">
                    Biaya ekstra Kilometer ({" "}
                    {trxDetail?.data?.trx_booking_rent?.trx_booking_rent_vehicle
                      ?.excess_usage_km || 0}{" "}
                    KM )
                  </h5>
                  <h5 className="">
                    Rp{" "}
                    {util.thousandSeparatorPure(
                      trxDetail?.data?.trx_booking_rent
                        ?.trx_booking_rent_vehicle?.usage_compensation || 0
                    )}
                  </h5>
                </div>
                {/* <div className="d-flex align-items-center justify-content-between w-75">
                  <h5 className="text-left">Biaya ekstra Bahan Bakar</h5>
                  <h5 className="">
                    Rp{" "}
                    {util.thousandSeparatorPure(
                      trxDetail?.data?.trx_booking_rent
                        ?.trx_booking_rent_vehicle?.fuel_compensation || 0
                    )}
                  </h5>
                </div> */}
                <div className="d-flex align-items-center justify-content-between">
                  <h5 className="text-left">Total Biaya Tambahan</h5>
                  <h5 className="">
                    Rp{" "}
                    {util.thousandSeparatorPure(
                      trxDetail?.data?.trx_booking_rent
                        ?.trx_booking_rent_vehicle?.total_compensation || 0
                    )}
                  </h5>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-2">
                  <h5 className="fw-bold">Total Tagihan</h5>
                  <h5 className="fw-bold">
                    Rp{" "}
                    {util.thousandSeparatorPure(
                      trxDetail?.data?.trx_booking_rent?.final_price +
                        trxDetail?.data?.trx_payment?.amount_admin
                    )}
                  </h5>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-2">
                  <h5 className="fw-bold">Total yang telah dibayarkan</h5>
                  <h5 className="fw-bold">
                    Rp
                    {util.thousandSeparatorPure(
                      trxDetail?.data?.trx_booking_rent?.paid_compensation || 0
                    )}
                  </h5>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <h5 className="">
                    Dana deposit ( Rp.{" "}
                    {util.thousandSeparatorPure(
                      trxDetail?.data?.trx_booking_rent?.deposit
                    )}{" "}
                    )
                  </h5>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-4">
                  <h5 className="fw-bold">
                    {" "}
                    {trxDetail?.data?.trx_booking_rent?.balance <= 0
                      ? "Kurang Bayar"
                      : "Lebih Bayar"}
                  </h5>
                  <h5 className="fw-bold">
                    Rp
                    {util.thousandSeparatorPure(
                      trxDetail?.data?.trx_booking_rent?.balance <= 0
                        ? trxDetail?.data?.trx_booking_rent?.lack_compensation
                        : trxDetail?.data?.trx_booking_rent?.balance
                    )}
                  </h5>
                </div>
              </>
            )}
          </Col>
        </Row>
        {trxDetail.data.booking_status === "FINISHED" && !isMobile && (
          <InfoBar
            type={
              trxDetail?.data?.trx_booking_rent?.balance <= 0
                ? "danger"
                : "info"
            }
            infoBarClassName={"mt-2"}
            text={
              <p style={{ marginTop: "-10px" }}>
                {trxDetail?.data?.trx_booking_rent?.balance <= 0
                  ? "Ada kekurangan Pembayaran pada transaksi anda, akun akan akan di blokir dalam waktu 2x 24 jam jika anda tidak melakukan Pembayaran"
                  : "Kelebihan dana akan dikembalikan keakun eLOG anda dalam waktu 2x 24 jam, untuk keterangan lbh Lanjut"}
                <span className="ms-2 underline text-primary pointer">
                  Lihat syarat dan ketentuan sewa eLOG
                </span>
              </p>
            }
          />
        )}
        {trxDetail.data.booking_status === "FINISHED" &&
        isMobile &&
        trxDetail?.data?.trx_booking_rent?.balance <= 0 ? (
          <img src={info_kurang_bayar_mobile} alt="" className="w-100 mt-2" />
        ) : (
          <img src={info_lebih_bayar_mobile} alt="" className="w-100 mt-2" />
        )}
      </Row>
    </>
  );
};

export default RentSection;
