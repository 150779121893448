import { Row, Col } from "react-bootstrap";
import KendaraanItem from "../components/Kendaraan/KendaraanItem";

const KendaraanDetail = (props) => {
  const { data } = props;
  /*======================== Hooks ======================== */

  /*======================== UseState ======================== */

  /*======================== Handler ======================== */

  /*======================== UseEffect ======================== */

  /*======================== Others ======================== */

  /*======================== Return ======================== */

  return (
    <Row className="procurement-detail-content mt-4">
      <Col xs={12} className="mt-2">
        <div className="mb-4">
          <h5 className="fw-normal">Daftar Kendaraan</h5>
          <KendaraanItem data={data} />
        </div>
      </Col>
    </Row>
  );
};

export default KendaraanDetail;
