import React, { Component } from "react";
import Gmap from "../../components/gMap";
import AuthAPI from "../../api/auth-api";
import { connect } from "react-redux";
import { setAuth } from "../../redux/action/auth_action";
import { openSidenavMobileDashboard } from "../../components/layouts/layout_helper";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    openSidenavMobileDashboard();
    this.requestToken();
  }

  async requestToken() {
    const authAPI = new AuthAPI();
    if (this.props.match.params.refresh_token) {
      const response = await authAPI.refreshTokenCustomer(
        this.props.authReducer.refresh_token ||
          this.props.match.params.refresh_token
      );
      console.log("RESPONSE REQUEST TOKEN", response);
      if (response && response.status === "success" && response.data) {
        const detailCustomer = await authAPI.getDetailCustomer(
          response.data.access_token
        );

        this.props.dispatch(
          setAuth({ ...response.data, ...detailCustomer.data })
        );
      }
    }
  }

  render() {
    return (
      <div style={{ height: "100vh", width: "100%" }}>
        <Gmap />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
});
export default connect(mapStateToProps)(Dashboard);
