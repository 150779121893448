import TipeKendaraan from "../components/Kendaraan/TipeKendaraan";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Form } from "react-bootstrap";
import { getPartnerVehicleContract } from "../../../redux/action/Partner/partner_action";
import _ from "lodash";

const TerdaftarKontrak = () => {
  /*======================== Hooks ======================== */

  const { id } = useParams();
  const dispatch = useDispatch();
  const partnerVehicle = useSelector(
    (st) => st.partnerManagementReducer.partnerVehicleContract
  );

  /*======================== UseState ======================== */

  const [vehicles, setVehicles] = useState([]);
  const [contract, setContract] = useState([]);

  /*======================== Handler ======================== */

  const filterData = (val) => {
    if (val) {
      let data = _.filter(
        partnerVehicle,
        (item) => item.transaction_delivery_partner_offer[0].transaction_delivery_procurement.name === val
      );
      setVehicleByType(data);
    } else {
      setVehicleByType(partnerVehicle);
    }
  };

  const setVehicleByType = (items) => {
    let groupData = _.groupBy(
      items,
      (item) => item.mst_vehicle_subtype.mst_vehicle_type.name
    );
    let newArray = [];
    for (const key in groupData) {
      newArray.push({
        type: key,
        data: groupData[key],
      });
    }
    setVehicles([...newArray]);
  };

  const setContractList = () => {
    let groupData = _.groupBy(
      partnerVehicle,
      (item) => item.transaction_delivery_partner_offer[0].transaction_delivery_procurement.name
    );
    
    let newArray = [];
    for (const key in groupData) {
      newArray.push({
        type: key,
      });
    }
    setContract([...newArray]);
  };

  /*======================== UseEffect ======================== */

  useEffect(() => {
    let customer_id = JSON.parse(localStorage.getItem("elog_dasboard")).id;
    dispatch(getPartnerVehicleContract(id, customer_id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (partnerVehicle) {
      setVehicleByType(partnerVehicle);
      setContractList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerVehicle]);

  /*======================== Others ======================== */

  const getDropListValue = () => {
    let component = [];
    contract.forEach((item) => {
      component.push(
        <option value={item.type} key={item.type}>
          {item.type}
        </option>
      );
    });

    return component;
  };

  const renderVehicle = () => {
    let component = [];
    vehicles.forEach((item, i) => {
      component.push(
        <div className="mb-4" key={i}>
          <TipeKendaraan data={item} />
        </div>
      );
    });
    return component;
  };

  /*======================== Return ======================== */

  return (
    <>
      <div className="mt-2 mb-2">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h3 className="fw-normal mb-2">Daftar Kendaraan</h3>
          </div>
          <div className="d-flex align-items-center w-50">
            <h4 className="mt-2 me-2 w-50" style={{ fontWeight: "600" }}>
              Kontrak :
            </h4>
            <Form.Select
              className="w-100"
              aria-label="Default select example"
              onChange={(e) => {
                filterData(e.target.value);
              }}
            >
              <option value="">Semua Kontrak</option>
              {getDropListValue()}
            </Form.Select>
          </div>
        </div>
      </div>

      {renderVehicle()}
    </>
  );
};

export default TerdaftarKontrak;
