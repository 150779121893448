import React, { useState } from "react";
import {
  Modal,
  Form,
  InputGroup,
  FloatingLabel,
  Button,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { resetAuth, setAuth } from "../../redux/action/auth_action";
import ProfileApi from "../../api/profile-api";
import AccountApi from "../../api/auth-api";
import { message } from "antd";

import SectionSuccess from "./SectionSuccess";

// icons
import { MdChevronRight, MdClose } from "react-icons/md";
import icon_user from "../../assets/images/icon-user.svg";

const Name = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const auth = useSelector((st) => st.authReducer);

  const [show, setShow] = useState(false);
  const [body, setBody] = useState({
    name: "",
  });

  const openModal = () => {
    setShow(true);
  };

  const closeModal = () => {
    setShow(false);
  };

  const handleSubmit = async () => {
    try {
      const accountApi = new AccountApi();
      const profileApi = new ProfileApi();

      const result = await accountApi.checkToken(auth.refresh_token);
      if (result.status === "success") {
        const profileRes = await profileApi.updateName(
          { ...body },
          auth.access_token
        );

        if (profileRes.status === "success") {
          message.success(profileRes.message);
          const { data } = await accountApi.getDetailCustomer(
            auth.access_token
          );

          dispatch(setAuth({ ...auth, ...data }));

          // setIsSubmit(true);
          closeModal();
        } else {
          closeModal();
          // message.error(profileRes.message);
        }
      } else {
        history.push("/login");
        dispatch(resetAuth());
      }
    } catch (error) {
      console.log(error.response);
    }
  };
  return (
    <>
      <div
        className="d-flex align-items-center pointer  px-2"
        onClick={openModal}
      >
        <img src={icon_user} width={25} height={25} alt="" />
        <div className="ms-3">
          <h6 className="text-muted ">Nama</h6>
          <h5>{auth?.name}</h5>
        </div>
        <div
          className="d-flex ms-auto align-items-center"
          style={{ marginTop: "-10px" }}
        >
          <MdChevronRight className="" />
        </div>
      </div>
      <Modal
        contentClassName="rounded-card p-2"
        show={show}
        onHide={closeModal}
        className="p-3"
        centered
      >
        <div className="d-flex p-2">
          <p className="fw-bold">Ubah Nama</p>
          <MdClose className="ms-auto me-2 pointer" onClick={closeModal} />
        </div>
        <Modal.Body>
          <div className="">
            <div className="d-flex justify-content-around">
              <InputGroup
                className="border rounded-input"
                style={{ width: "90%" }}
              >
                <FloatingLabel
                  className="rounded-input"
                  label="Nama"
                  style={{ fontSize: "12px", width: "100%" }}
                >
                  <Form.Control
                    type="text"
                    className="border-0 rounded-input"
                    placeholder="m"
                    onChange={(ev) => {
                      setBody({ ...body, name: ev.target.value });
                    }}
                    defaultValue={auth?.name}
                    style={{ height: "50px" }}
                  ></Form.Control>
                </FloatingLabel>
              </InputGroup>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <p className="text-primary pointer me-4" onClick={closeModal}>
            Batalkan
          </p>
          <Button
            disabled={body.name ? false : true}
            size="sm"
            variant="primary"
            className="float-end rounded-pill px-4 py-2"
            onClick={() => {
              handleSubmit();
            }}
          >
            Simpan
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Name;
