import React, { useState } from "react";
import { Row, Col, Card, Modal, Form, Button } from "react-bootstrap";
import { Checkbox, Dropdown } from "antd";
import useBreakpoint from "../responsiveUtils/useBreakpoint";

// icons
import { IoChevronDown } from "react-icons/io5";
import { AiFillMinusCircle, AiFillPlusCircle } from "react-icons/ai";

function PickHelper(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleIncrement = () => {
    let total = props.total_helper_requested;
    props.handlePrice(total + 1, total);
  };
  const handleDecrement = () => {
    let total = props.total_helper_requested;
    props.handlePrice(total - 1, total);
  };

  const dropdownOverlay = () => (
    <Row className="card-body py-2 drpdwn-pick bg-white">
      <Col xs={1} className="align-self-center text-center">
        <Checkbox
          disabled={props.helper_availability_rent === 0}
          checked={props.helper_availability_rent_selected}
          onClick={() => {
            props.handleChecklist?.();
          }}
        />
      </Col>
      <Col xs={6} className="align-self-center">
        <p className="fw-bold mb-0">Helper</p>
        <h6 className="mb-0 text-muted">Maksimal 3 Orang Helper</h6>
      </Col>
      <Col xs={5} className="align-self-center text-end">
        <h6 className="mb-0 fw-bold">
          + Rp {props.helper_price_rent * props.total_helper_requested}
        </h6>
      </Col>
      <Col xs={7} className="align-self-center mt-4">
        <p className="fw-bold mb-0">Jumlah Helper</p>
        <h6 className="mb-0 text-muted">
          Biaya Rp {props.helper_price_rent} /orang
        </h6>
      </Col>
      <Col xs={5} className="align-self-center mt-4 d-flex  float-end">
        <Button
          variant="link"
          onClick={() => {
            handleDecrement();
          }}
          disabled={props.total_helper_requested <= 1}
        >
          <AiFillMinusCircle className="text-muted" />
        </Button>
        <Form.Control type="number" value={props.total_helper_requested} />
        <Button
          variant="link"
          onClick={() => {
            handleIncrement();
          }}
          disabled={props.total_helper_requested >= 3}
        >
          <AiFillPlusCircle />
        </Button>
      </Col>
    </Row>
  );

  const { isMobile } = useBreakpoint();

  return (
    <>
      {props.disabled ? (
        <Card className="border-0 mb-1">
          <Row className="card-body py-2">
            <Col xs={1} className="align-self-center text-center">
              <Checkbox disabled />
            </Col>
            <Col xs={10} className="align-self-center">
              <p className="fw-bold mb-0">Helper</p>
              <h6 className="mb-0 text-muted">
                Bantu bongkar/muat tanpa batasan jarak dan berat.
              </h6>
            </Col>
            <Col xs={1} className="align-self-center text-center">
              <h6
                className="mb-0"
                hidden={props.helper_availability_rent === 0}
              >
                <IoChevronDown className="pointer" />
              </h6>
            </Col>
          </Row>
        </Card>
      ) : (
        <>
          {isMobile ? (
            <>
              <Card className="border-0 mb-1" onClick={handleShow}>
                <Row className="card-body py-2">
                  <Col xs={1} className="align-self-center text-center">
                    <Checkbox
                      disabled={props.helper_availability_rent === 0}
                      checked={props.helper_availability_rent_selected}
                      onClick={() => {
                        props.handleChecklist?.();
                      }}
                    />
                  </Col>
                  <Col xs={10} className="align-self-center">
                    <p className="fw-bold mb-0">Helper</p>
                    <h6 className="mb-0 text-muted">
                      Bantu bongkar/muat tanpa batasan jarak dan berat.
                    </h6>
                  </Col>
                  <Col xs={1} className="align-self-center text-center">
                    <h6
                      className="mb-0"
                      hidden={props.helper_availability_rent === 0}
                    >
                      <IoChevronDown className="pointer" />
                    </h6>
                  </Col>
                </Row>
              </Card>
              <Modal
                contentClassName=""
                show={show}
                onHide={handleClose}
                className=""
                centered
              >
                <Modal.Header closeButton className="bg-white">
                  <strong>Helper</strong>
                </Modal.Header>
                {/* <Modal.Body> */}
                <Card className="border-0 mb-1">
                  <Row className="card-body py-2 ">
                    <Col xs={1} className="align-self-center text-center">
                      <Checkbox
                        disabled={props.helper_availability_rent === 0}
                        checked={props.helper_availability_rent_selected}
                        onClick={() => {
                          props.handleChecklist?.();
                        }}
                      />
                    </Col>
                    <Col xs={6} className="align-self-center">
                      <p className="fw-bold mb-0">Helper</p>
                      <h6 className="mb-0 text-muted">
                        Maksimal 3 Orang Helper
                      </h6>
                    </Col>
                    <Col xs={5} className="align-self-center text-end">
                      <h6 className="mb-0 fw-bold">
                        + Rp{" "}
                        {props.helper_price_rent * props.total_helper_requested}
                      </h6>
                    </Col>
                    <Col xs={7} className="align-self-center mt-4">
                      <p className="fw-bold mb-0">Jumlah Helper</p>
                      <h6 className="mb-0 text-muted">
                        Biaya Rp {props.helper_price_rent} /orang
                      </h6>
                    </Col>
                    <Col
                      xs={5}
                      className="align-self-center mt-4 d-flex  float-end"
                    >
                      <Button
                        variant="link"
                        onClick={() => {
                          handleDecrement();
                        }}
                        disabled={props.total_helper_requested <= 1}
                      >
                        <AiFillMinusCircle className="text-muted" />
                      </Button>
                      <Form.Control
                        type="number"
                        value={props.total_helper_requested}
                      />
                      <Button
                        variant="link"
                        onClick={() => {
                          handleIncrement();
                        }}
                        disabled={props.total_helper_requested >= 3}
                      >
                        <AiFillPlusCircle />
                      </Button>
                    </Col>
                  </Row>
                </Card>
                {/* </Modal.Body> */}
                <Modal.Footer className="border-top-0 bg-ice">
                  <Button
                    size="sm"
                    variant="primary"
                    className="float-end rounded-pill py-1 px-4"
                    onClick={handleClose}
                  >
                    Simpan
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
          ) : (
            <Dropdown
              onClick={() => {
                if (show) {
                  handleClose();
                } else {
                  handleShow();
                }
              }}
              visible={show}
              overlay={dropdownOverlay}
              trigger={["click"]}
            >
              <Card className="border-0 mb-1">
                <Row className="card-body py-2 pointer">
                  <Col xs={1} className="align-self-center text-center">
                    <Checkbox
                      disabled={props.helper_availability_rent === 0}
                      checked={props.helper_availability_rent_selected}
                      onClick={() => {
                        props.handleChecklist?.();
                      }}
                    />
                  </Col>
                  <Col xs={10} className="align-self-center ">
                    <p className="fw-bold mb-0">Helper</p>
                    <h6 className="mb-0 text-muted">
                      Bantu bongkar/muat tanpa batasan jarak dan berat.
                    </h6>
                  </Col>
                  <Col xs={1} className="align-self-center text-center">
                    <h6
                      className="mb-0"
                      hidden={props.helper_availability_rent === 0}
                    >
                      <IoChevronDown onClick={handleShow} className="pointer" />
                    </h6>
                  </Col>
                </Row>
              </Card>
            </Dropdown>
          )}
        </>
      )}

      {/* <Modal
        contentClassName="modal-100"
        show={show}
        onHide={handleClose}
        className=""
        centered
      >
        <Modal.Header closeButton className="bg-white">
          <strong>Driver Helper</strong>
        </Modal.Header>
        
      </Modal> */}
    </>
  );
}

export default PickHelper;
