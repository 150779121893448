import AlamatApi from "../../../api/RuteAlamat/alamat-api";
import { resetAuth } from "../auth_action";

export const getAlamatList = (params) => async (dispatch, state) => {
  try {
    const api = new AlamatApi();

    dispatch({
      type: "SET_ALAMAT",
      payload: { loading: true },
    });

    const response = await api.getAlamatList(
      state().authReducer.access_token,
      params
    );

    if (response.status === 401) {
      dispatch(resetAuth());
    }
    const { data, ...meta } = response;
    if (params.address_type === "PICKING") {
      dispatch({
        type: "SET_ALAMAT",
        payload: { loading: false, alamat_anda: { data, meta } },
      });
    } else {
      dispatch({
        type: "SET_ALAMAT",
        payload: { loading: false, alamat_pengiriman: { data, meta } },
      });
    }
  } catch (error) {
    console.log(error?.response);
  }
};

export const getAlamatListSewa = (params) => async (dispatch, state) => {
  try {
    const api = new AlamatApi();

    dispatch({
      type: "SET_ALAMAT_SEWA",
      payload: { loading: true },
    });

    const response = await api.getAlamatListSewa(
      state().authReducer.access_token,
      params
    );

    if (response.status === 401) {
      dispatch(resetAuth());
    }
    const { data, ...meta } = response;
    dispatch({
      type: "SET_ALAMAT_SEWA",
      payload: { loading: false, alamat_anda_sewa: { data, meta } },
    });
  } catch (error) {
    console.log(error?.response);
  }
};

export const getAlamatAll = (params) => async (dispatch, state) => {
  try {
    const api = new AlamatApi();

    dispatch({
      type: "SET_ALAMAT",
      payload: { loading: true },
    });

    const response = await api.getAlamatList(state().authReducer.access_token, {
      ...params,
      limit: 9999,
    });

    if (response.status === 401) {
      dispatch(resetAuth());
    }

    if (params.address_type === "PICKING") {
      dispatch({
        type: "SET_ALAMAT_ANDA_ALL",
        payload: { all: response.data },
      });
    } else {
      dispatch({
        type: "SET_ALAMAT_PENGIRIMAN_ALL",
        payload: { all: response.data },
      });
    }
  } catch (error) {
    console.log(error?.response);
  }
};

export const getAlamatDetail = (id) => async (dispatch, state) => {
  try {
    const api = new AlamatApi();

    dispatch({
      type: "SET_ALAMAT",
      payload: { loading: true },
    });

    const response = await api.getAlamatDetail(
      state().authReducer.access_token,
      id
    );

    if (response.status === 401) {
      dispatch(resetAuth());
    }

    dispatch({
      type: "SET_ALAMAT",
      payload: { loading: false },
    });
    return response?.data;
  } catch (error) {
    console.log(error?.response);
  }
};

export const getAlamatDetailSewa = (id) => async (dispatch, state) => {
  try {
    const api = new AlamatApi();

    dispatch({
      type: "SET_ALAMAT",
      payload: { loading: true },
    });

    const response = await api.getAlamatDetailSewa(
      state().authReducer.access_token,
      id
    );

    if (response.status === 401) {
      dispatch(resetAuth());
    }

    dispatch({
      type: "SET_ALAMAT",
      payload: { loading: false },
    });
    return response?.data;
  } catch (error) {
    console.log(error?.response);
  }
};

export const createAlamat = (payload) => async (dispatch, state) => {
  try {
    const api = new AlamatApi();

    dispatch({
      type: "SET_ALAMAT",
      payload: { loading: true },
    });

    const response = await api.createAlamat(
      state().authReducer.access_token,
      payload
    );

    if (response.status === 401) {
      dispatch(resetAuth());
    }
    if (payload.address_type === "PICKING") {
      dispatch({
        type: "RESET_ALAMAT_ANDA",
      });
    } else {
      dispatch({
        type: "RESET_ALAMAT_PENGIRIMAN",
      });
    }
    return response;
  } catch (error) {
    console.log(error?.response);
  } finally {
    dispatch({
      type: "SET_ALAMAT",
      payload: { loading: false },
    });
  }
};

export const updateAlamat = (payload) => async (dispatch, state) => {
  try {
    const api = new AlamatApi();

    dispatch({
      type: "SET_ALAMAT",
      payload: { loading: true },
    });

    const response = await api.updateAlamat(
      state().authReducer.access_token,
      payload
    );

    if (response.status === 401) {
      dispatch(resetAuth());
    }

    if (payload.address_type === "PICKING") {
      dispatch({
        type: "RESET_ALAMAT_ANDA",
      });
    } else {
      dispatch({
        type: "RESET_ALAMAT_PENGIRIMAN",
      });
    }
    return response;
  } catch (error) {
    console.log(error?.response);
  } finally {
    dispatch({
      type: "SET_ALAMAT",
      payload: { loading: false },
    });
  }
};

export const updateAlamatSewa = (payload) => async (dispatch, state) => {
  try {
    const api = new AlamatApi();

    dispatch({
      type: "SET_ALAMAT",
      payload: { loading: true },
    });

    const response = await api.updateAlamatSewa(
      state().authReducer.access_token,
      payload
    );

    if (response.status === 401) {
      dispatch(resetAuth());
    }

    if (payload.address_type === "PICKING") {
      dispatch({
        type: "RESET_ALAMAT_ANDA",
      });
    } else {
      dispatch({
        type: "RESET_ALAMAT_PENGIRIMAN",
      });
    }
    return response;
  } catch (error) {
    console.log(error?.response);
  } finally {
    dispatch({
      type: "SET_ALAMAT",
      payload: { loading: false },
    });
  }
};
