import React, { useState } from "react";
import {
  Card,
  Row,
  Col,
  Dropdown,
} from "react-bootstrap";
import moment from "moment";
import useBreakpoint from "../../responsiveUtils/useBreakpoint";
import { useSelector, useDispatch } from "react-redux";
import {
  getAccountList,
} from "../../../redux/action/usermanagement_action";
import UserAccountEdit from "./UserAccountEdit";
import UserAccountDetail from "./UserAccountDetail";
import UserAccountInput from "./UserAccountInput";


const UserAccountList = () => {
  const dispatch = useDispatch();
  const { isDesktop, isTablet } = useBreakpoint();
  const [sortBy, setSortBy] = useState('Terbaru');
  const auth = useSelector((st) => st.authReducer);
  const accountList = useSelector(
    (st) => st.userMgtReducer.accountList.data
  );

  const onChangeSortBy = (value) => {
    if (value === "Terbaru") {
      dispatch(getAccountList(auth.access_token, "desc"));
    } else {
      dispatch(getAccountList(auth.access_token, "asc"));
    }
    setSortBy(value);
  }

  const getInital = (value) => {
    if (value) {
      const fullName = value.split(' ');
      const initials = fullName[0].charAt(0) + (fullName[1] ? fullName[1].charAt(0) : '') + (fullName[2] ? fullName[2].charAt(0) : '')
      return initials.toUpperCase();
    } else {
      return null
    }
  }

  const showData = () => {
    let component = [];

    if (accountList && accountList.length > 0) {
      accountList.forEach((item, i) => {
        component.push(
          <Card className="rounded-card bg-white p-3 mt-2 mb-3 border-0" key={i}>
            <Row>
              <Col lg={3}>
                <div className="w-100 h-100 bg-danger rounded overflow-hidden">
                  {
                    item && item.profile_picture ? (
                      <img src={item.profile_picture} alt="" className="w-100 h-100" />
                    ) : (
                      <div className="w-100 h-100" style={{ position: "relative" }}>
                        <div style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%,-50%)",
                          color: "#fff",
                          fontSize: "18px",
                          fontWeight: "bold"
                        }}>
                          {getInital(item.name)}
                        </div>
                      </div>
                    )
                  }
                </div>
              </Col>
              <Col lg={6}>
                <Row className="h-100">
                  <Col lg={12}>
                    <div className="d-flex">
                      <h5 className="fw-bold">{item.name} </h5>
                      {/* <h6 className="px-2 text-danger"> Offline</h6> */}
                    </div>
                    <h6 style={{ color: "#797979" }}>{item.role_name ? item.role_name : '-'}</h6></Col>
                  <Col lg={6}>
                    <h6 className="fw-bold">Email</h6>
                    <h6 style={{ color: "#797979" }}>{item.email}</h6>
                  </Col>
                  <Col lg={6}>
                    <h6 className="fw-bold">No. Handphone</h6>
                    <h6 style={{ color: "#797979" }}>{item.phone}</h6>
                  </Col>
                </Row>
              </Col>
              <Col lg={3} className='d-flex flex-column justify-content-between'>
                <div>
                  <p align="right" style={{ color: "#797979" }}>Dibuat {moment(item.created_at).format("DD MMMM YYYY")}</p>
                  <UserAccountEdit detail={item} />
                </div>
                <UserAccountDetail detail={item} />
              </Col>
            </Row>
          </Card>
        );
      });

      return component;
    } else {
      return (
        <Card className="rounded-card bg-white p-4 mt-2 mb-3 border-0">
          <h6 className="text-center mb-2" style={{ padding: "35px", color: "#8C8C8C" }}>Anda Belum Menambahkan Akun</h6>
        </Card>
      )
    }
  }

  const showDataMobile = () => {
    let component = [];

    if (accountList && accountList.length > 0) {
      accountList.forEach((item, i) => {
        component.push(
          <Card className="rounded-card bg-white p-3 mt-2 mb-3 border-0" key={i}>
            <Row>
              <Col sm={4} xs={4}>
                <div className="w-100 h-100 bg-danger rounded overflow-hidden">
                  {
                    item && item.profile_picture ? (
                      <img src={item.profile_picture} alt="" className="w-100 h-100" />
                    ) : (
                      <div className="w-100 h-100" style={{ position: "relative" }}>
                        <div style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%,-50%)",
                          color: "#fff",
                          fontSize: "18px",
                          fontWeight: "bold"
                        }}>
                          {getInital(item.name)}
                        </div>
                      </div>
                    )
                  }
                </div>
              </Col>
              <Col sm={8} xs={8}>
                <Row className="h-100">
                  <Col md={12} xs={12}>
                    <div className="d-flex justify-content-between">
                      <h5 className="fw-bold">{item.name}</h5>
                      {/* <h6 className="text-success text-end"> Online</h6> */}
                    </div>
                    <div className="d-flex justify-content-between mb-1">
                      <h6 style={{ color: "#797979" }}>{item.role_name ? item.role_name : '-'}</h6>
                      <UserAccountEdit detail={item} />
                    </div>
                  </Col>
                  <Col md={12} xs={12}>
                    <div className="d-flex justify-content-between align-items-end">
                      <h6 className="text-end" style={{ color: "#797979" }}>Dibuat {moment(item.created_at).format("DD MMMM YYYY")}</h6>
                      <UserAccountDetail detail={item} />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        );
      });

      return component;
    } else {
      return (
        <Card className="rounded-card bg-white p-4 mt-2 mb-3 border-0">
          <h6 className="text-center mb-2" style={{ padding: "35px", color: "#8C8C8C" }}>Anda Belum Menambahkan Akun</h6>
        </Card>
      )
    }
  }

  const showDataTablet = () => {
    let component = [];

    if (accountList && accountList.length > 0) {
      accountList.forEach((item, i) => {
        component.push(
          <Card className="rounded-card bg-white p-3 mt-2 mb-3 border-0" key={i}>
            <Row>
              <Col lg={3} md={3}>
                <div className="w-100 h-100 bg-danger rounded overflow-hidden">
                  {
                    item && item.profile_picture ? (
                      <img src={item.profile_picture} alt="" className="w-100 h-100" />
                    ) : (
                      <div className="w-100 h-100" style={{ position: "relative" }}>
                        <div style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%,-50%)",
                          color: "#fff",
                          fontSize: "18px",
                          fontWeight: "bold"
                        }}>
                          {getInital(item.name)}
                        </div>
                      </div>
                    )
                  }
                </div>
              </Col>
              <Col md={6}>
                <Row className="h-100">
                  <Col md={12}>
                    <div className="d-flex">
                      <h5 className="fw-bold">{item.name} </h5>
                      {/* <h6 className="px-2 text-danger"> Offline</h6> */}
                    </div>
                    <h6 style={{ color: "#797979" }}>{item.role_name ? item.role_name : '-'}</h6></Col>
                  <Col md={6}>
                    <h6 className="fw-bold">Email</h6>
                    <h6 style={{ color: "#797979" }}>{item.email}</h6>
                  </Col>
                  <Col md={6}>
                    <h6 className="fw-bold">No. Handphone</h6>
                    <h6 style={{ color: "#797979" }}>{item.phone}</h6>
                  </Col>
                </Row>
              </Col>
              <Col md={3} className='d-flex flex-column justify-content-between'>
                <div>
                  <h6 className="text-end" style={{ color: "#797979" }}>Dibuat {moment(item.created_at).format("DD MMMM YYYY")}</h6>
                  <UserAccountEdit detail={item} />
                </div>
                <UserAccountDetail detail={item} />
              </Col>
            </Row>
          </Card>
        );
      });

      return component;
    } else {
      return (
        <Card className="rounded-card bg-white p-4 mt-2 mb-3 border-0">
          <h6 className="text-center mb-2" style={{ padding: "35px", color: "#8C8C8C" }}>Anda Belum Menambahkan Akun</h6>
        </Card>
      )
    }
  }

  return (
    <div>
      {isDesktop ? (
        <>
          <div className="px-4 py-2 mt-2">
            <h3>List Akun</h3>
            <h5>List dari Akun yang sudah anda buat.</h5>
            <h4 className="mt-1 d-inline me-5">Urutkan Berdasarkan:</h4>
            <Dropdown className="d-inline mx-2 ms-auto">
              <Dropdown.Toggle
                id="dropdown-autoclose-true"
                size="sm"
                variant="light"
                className="bg-white text-secondary px-3 border shadow-sm rounded"
              >
                {sortBy}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  href="#"
                  onClick={() => {
                    onChangeSortBy('Terbaru')
                  }}
                >
                  Terbaru
                </Dropdown.Item>
                <Dropdown.Item
                  href="#"
                  onClick={() => {
                    onChangeSortBy('Terlama')
                  }}
                >
                  Terlama
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className={`scrollable-70 px-2`}>
            {showData()}
          </div>
        </>
      )
        :
        isTablet ? (
          <>
            <div className="px-4 py-2 mt-2">
              <div className="d-flex justify-content-between">
                <h3>List Akun</h3>
                <UserAccountInput />
              </div>
              <h5>List dari Akun yang sudah anda buat.</h5>
              <h4 className="mt-1 d-inline me-5">Urutkan Berdasarkan:</h4>
              <Dropdown className="d-inline mx-2 ms-auto">
                <Dropdown.Toggle
                  id="dropdown-autoclose-true"
                  size="sm"
                  variant="light"
                  className="bg-white text-secondary px-3 border shadow-sm rounded"
                >
                  {sortBy}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    href="#"
                    onClick={() => {
                      onChangeSortBy('Terbaru')
                    }}
                  >
                    Terbaru
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#"
                    onClick={() => {
                      onChangeSortBy('Terlama')
                    }}
                  >
                    Terlama
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className={`scrollable-70 px-2`}>
              {showDataTablet()}
            </div>
          </>
        ) : (
          <>
            <div className="py-2 mt-2">
              <h3>List Akun</h3>
              <div className="d-flex justify-content-between mb-4">
                <h5>List dari Akun yang<br></br> sudah anda buat.</h5>
                <UserAccountInput />
              </div>

              <h4 className="mt-3 me-2 d-inline">Urutkan Berdasarkan:</h4>
              <Dropdown className="d-inline ms-auto">
                <Dropdown.Toggle
                  id="dropdown-autoclose-true"
                  size="sm"
                  variant="light"
                  className="bg-white text-secondary px-3 border shadow-sm rounded"
                >
                  {sortBy}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    href="#"
                    onClick={() => {
                      onChangeSortBy('Terbaru')
                    }}
                  >
                    Terbaru
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#"
                    onClick={() => {
                      onChangeSortBy('Terlama')
                    }}
                  >
                    Terlama
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div>
              {showDataMobile()}
            </div>
          </>
        )
      }
    </div>
  )
}

export default UserAccountList;
