import { combineReducers } from "redux";
import { map } from "./map_reducer";
import { kirimbarangReducer } from "./kirimbarang_reducer";
import { sewaKendaraanReducer } from "./sewakendaraan_reducer";
import { authReducer } from "./auth_reducer";
import { paymentListReducer } from "./payment_list_reducer";
import { activePaymentReducer } from "./active_payment_reducer";
import { trxHistoryReducer } from "./trx_history_reducer";
import { notifReducer } from "./notif_reducer";
import { balanceReducer } from "./balance_reducer";
import { ruteReducer } from "./RuteAlamat/rute_reducer";
import { alamatReducer } from "./RuteAlamat/alamat_reducer";
import { procurementReducer } from "./Management/procurement_reducer";
import { userMgtReducer } from "./usermanagement_reducer";
import { vehicleReducer } from "./Master/vehicle_reducer";
import { paymentReducer } from "./Master/payment_reducer";
import { partnerReducer } from "./Master/partner_reducer";
import { partnerManagementReducer } from "./Partner/partner_reducer";
import { invoiceReducer } from "./Invoice/invoice_reducer";
import { documentPaymentReducer } from "./Finance/document_payment_reducer";

export default combineReducers({
  map,
  kirimbarangReducer,
  sewaKendaraanReducer,
  authReducer,
  paymentListReducer,
  activePaymentReducer,
  trxHistoryReducer,
  notifReducer,
  balanceReducer,
  ruteReducer,
  alamatReducer,
  userMgtReducer,
  procurementReducer,
  vehicleReducer,
  paymentReducer,
  partnerReducer,
  partnerManagementReducer,
  invoiceReducer,
  documentPaymentReducer
});
