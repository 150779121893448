import React, { useState, useRef } from "react";
import {
  Modal,
  Form,
  Card,
  Col,
  Row,
  Button,
} from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux"; //erick (tambah use Selector)
import {
  getRoleList,
} from "../../../redux/action/usermanagement_action";
import { resetAuth } from "../../../redux/action/auth_action";
import useBreakpoint from "../../responsiveUtils/useBreakpoint";
import icon_detail_MR_userManagment from "../../../assets/images/icon_detail_MR_userManagment.svg";
import icon_checked_UM from "../../../assets/images/icon_checked_UM.svg";
import UserMgtApi from "../../../api/user-management.api";
import RoleInputForm from "./RoleInputForm";
import { message } from "antd";

const RoleUserDetail = ({ detail }) => {
  const dispatch = useDispatch();
  const childRef = useRef(null);
  const accessRoleList = useSelector(
    (st) => st.userMgtReducer.accessRoleList.data
  );
  const auth = useSelector((st) => st.authReducer);

  const { isMobile } = useBreakpoint();
  const [show, setShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [dataForm, setDataForm] = useState({
    id: '',
    name: '',
    role_code: '',
    type: '',
    description: '',
    access: [],
  });

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setIsEdit(false);
    setShow(true);
  }


  const isChecklist = (index) => {
    if (detail && detail.access) {
      let data = detail.access.find(item => item.id === accessRoleList[index].id);
      return data ? (
        <img src={icon_checked_UM} width="50" alt="checked" style={{ marginTop: "-15px" }} />
      ) : null
    } else {
      return null
    }
  }

  const showAccessList = () => {
    let component = [];

    if (accessRoleList && accessRoleList.length > 0) {
      accessRoleList.forEach((item, i) => {
        component.push(
          <Row className="mb-1" key={i}>
            <Col lg={4} md={4} sm={6} xs={5} >
              <h5 className="me-2">{item?.name}</h5>
            </Col>
            <Col lg={6} md={6} sm={6} xs={6}>
              {isChecklist(i)}
            </Col>
          </Row>
        );
      });

      return component;
    } else {
      return null
    }
  }

  const editData = () => {
    setDataForm({
      id: detail.id,
      name: detail.name,
      role_code: detail.role_code,
      type: detail.type,
      description: detail.description,
      access: setAccessRoleValue(detail.access),
    });

    setIsEdit(true);
  };

  const setAccessRoleValue = (items) => {
    return items.map(item => item.id);
  };

  const handleInput = async () => {
    childRef.current.handleInput();
  };

  const submit = (data) => {
    updateRole(data);
  };

  const updateRole = async (data) => {
    try {
      const api = new UserMgtApi();
      const response = await api.updateRole(auth.access_token, data);

      if (response.status === 401) {
        dispatch(resetAuth());
      } else if (response.status === "success") {
        message.success("Role Berhasil Diupdate");
        handleClose();
        dispatch(getRoleList(auth.access_token));
      }

    } catch (error) {
      console.log(error?.response);
    }
  };

  return (
    <>
      {isMobile ?
        <img src={icon_detail_MR_userManagment} width="50" alt="detail" onClick={handleShow} style={{ cursor: "pointer" }} />
        :
        (<Button
          size="sm"
          className="float-end text-white px-3"
          onClick={handleShow}
        >
          Lihat Detail
        </Button>)
      }

      <Modal
        contentClassName=""
        show={show}
        onHide={handleClose}
        className=""
        centered
      >
        <Modal.Header closeButton className="bg-white">
          <div>
            <h4 className="fw-bold">
              {isEdit ? 'Edit Role' : 'Detail Role'}
            </h4>
            <h6>
              {isEdit ? 'Ubah detail role yang sudah anda buat.' : 'Detail role yang sudah Anda buat.'}
            </h6>
          </div>
        </Modal.Header>

        {
          isEdit ? (
            <Card className="rounded-card bg-white p-4 mb-1 border-0">
              <RoleInputForm ref={childRef} submit={submit} dataForm={dataForm} />
            </Card>
          )
            :
            (
              <Card className="rounded-card bg-white p-4 mb-1 border-0">
                <Row>
                  <Col lg={6}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <h6 style={{ color: "#999999" }}>Kode Role</h6>
                      <h5>{detail && detail.role_code ? detail.role_code : '-'}</h5>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <h6 style={{ color: "#999999" }}>Nama Role</h6>
                      <h5>{detail && detail.name ? detail.name : '-'}</h5>
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group className="mb-2" controlId="exampleForm.ControlTextarea1">
                  <h6 style={{ color: "#999999" }}>Deskripsi Role</h6>
                  <h5>{detail && detail.description ? detail.description : '-'}</h5>
                </Form.Group>
                <h5 className="d-inline fw-bold me-2 mt-2">Akses Role</h5>
                <div className="">
                  {showAccessList()}
                </div>
              </Card>
            )
        }
        <Modal.Footer className="border-top-0 mx-1 bg-ice">
          {
            isEdit ? (
              <Button
                size="sm"
                variant="primary"
                className="float-end rounded-pill px-3"
                onClick={() => {
                  handleInput();
                }}
              >
                Simpan
              </Button>

            )
              : (
                <Button
                  size="sm"
                  variant="outline-primary"
                  className="float-end text-primary rounded-pill py-2 px-4"
                  onClick={() => {
                    editData();
                  }}
                >
                  <span className="hvr-white">Edit Role</span>
                </Button>
              )
          }
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default RoleUserDetail;
