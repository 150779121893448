import React, { useState } from "react";
import {
  Row,
  Col,
  Tabs,
  Tab,
  Card,
  Stack,
  Accordion,
  useAccordionButton
} from "react-bootstrap";
import GMap from "../../components/gMap/SimpleMap";
// icons
import {
  BsArrowRight,
  BsFillCircleFill,
} from "react-icons/bs";
import { ReactComponent as ICArrowRight } from '../../assets/images/ic_arrow_right.svg';
import { ReactComponent as ICSend } from '../../assets/images/ic_kirim_grup.svg';
import { useHistory } from "react-router-dom";
import { FiChevronLeft } from "react-icons/fi";

const DetailPesanan = () => {
  const history = useHistory();
  const [pill, setPill] = useState("pesanan");
  const tabOptions = [
    {
      title: "Detail Pesanan",
      value: "pesanan",
    },
    {
      title: "Dokumen & Kendala",
      value: "dokumen",
    }
  ];

  const data = [
    {
      id: 1,
      name: 'Muat Barang 1'
    },
    {
      id: 2,
      name: 'Muat Barang 2'
    },
    {
      id: 3,
      name: 'Drop Off 1'
    },
    {
      id: 4,
      name: 'Drop Off 2'
    },
  ]

  const handleChange = (type, value) => {
    switch (type) {
      case "tab":
        setPill(value)
        break;
      default:
        break;
    }
  }

  return (
    <div
      style={{ minHeight: "100vh", width: "100%", padding: "30px" }}
      className="bg-ice height-100"
    >
      <Row>
        {/* left section */}
        <Col md={7} xs={12}>
          <Card
            className=" pt-4 px-4 pb-0 rounded-card border-0 mb-3"
            style={{ backgroundColor: "white" }}
          >
            <Stack direction="horizontal" gap={2}>
              <FiChevronLeft
                fontSize={25}
                className="pointer me-3"
                onClick={() => history.push(`/detail-invoice/${history.location.state.data}`)}
              />
              <div className="d-flex flex-col gap-3 align-items-center">
                <ICSend />
                <div className="flex-col align-items-center">
                  <h3 className="mb-0">Kirim Barang</h3>
                  <span className="text-muted mb-0">
                    22 Des 2022 16:30
                  </span>
                </div>
              </div>
              <div className="d-flex flex-col ms-auto">
                <div className="d-flex align-items-center gap-3">
                  <div className="d-flex flex-column">
                    <span className="text-muted">ID Transaksi</span>
                    <h3 className="mb-0 text-primary">#BXKRM345567</h3>
                  </div>
                  <div>
                    ic
                  </div>
                </div>
              </div>
            </Stack>
            <Tabs
              defaultActiveKey={pill}
              className="tabs-trx border-bottom-2 mt-2 pl-5"
              onSelect={(value) => handleChange("tab", value)}
            >
              {tabOptions.map(row =>
                <Tab eventKey={row.value} title={row.title} />
              )}
            </Tabs>
          </Card>

          {pill == 'dokumen' && (
            <Col>
              <Accordion >
                {data.map((row) =>
                  <Card key={row.id} className='mb-2'>
                    <Card.Header className='cus-header-pill'>
                      <CustomToggle eventKey={row.id}>
                        {row.name}
                        <ICArrowRight />
                      </CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={row.id}>
                      <Card.Body>
                        <p>tes</p>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                )}
              </Accordion>
            </Col>
          )}

        </Col>
        {/* right section */}
        <Col md={5} xs={12}>
          <Col className="rounded-card card-map-right" style={{ height: "90vh" }}>
            <GMap
              hideControlButton
              driverLocation={{
                lat: '-6.121435',
                long: '106.774124'
              }}
            />
            <div className="card-map-item-top 
              d-flex 
              justify-content-center"
              style={{
                width: "100%"
              }}>
              <div className='bg-white 
                rounded-card 
                px-4 
                p-3 
                ms-2 
                shadow-md 
                d-flex 
                gap-2 
                justify-content-center'
                style={{ opacity: "0.9" }}
              >
                <span className="text-muted">
                  Total Jarak  80.1 Km,  ETA :  10 jam 45 Menit
                </span>
              </div>
            </div>
            <div className="card-map-item m-3" style={{ width: "92%" }}>
              <div
                className="bg-white ms-2 rounded-card p-4 shadow-md"
                style={{ width: "100%" }}
              >
                <Row>
                  <Col xs={3}>
                    <Row>
                      <Col>
                        <h6 className="text-muted">
                          20/10/2022
                        </h6>
                        <h5>12:30 WIB</h5>
                      </Col>
                      <Col>
                        <BsFillCircleFill className="text-secondary" />
                        <div
                          className="br-dash"
                          style={{
                            height: "35px",
                            marginLeft: "7px",
                            marginTop: "5px",
                          }}
                        />
                        <BsFillCircleFill style={{ color: "#ced4da" }} />
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <h5 className="fw-bold">
                      Menuju Lokasi
                    </h5>
                    <>
                      <p>
                        Driver sedang menuju lokasi untuk mengantarkan kendaraan
                        Nama: Supartana, Telp: 6281316722140
                        Perkiraan Tiba: 22 Desember 2021 - 18:10 WIB
                      </p>
                    </>
                    <h5
                      className="text-primary pointer mt-2"
                    >
                      Lihat Detail
                      <BsArrowRight style={{ marginLeft: "5px" }} />
                    </h5>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Col>
      </Row>
    </div>
  )
}

function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey, () =>
    console.log('totally custom!', eventKey),
  );

  return (
    <button
      type="button"
      className="accordion-header"
      style={{ border: 'none' }}
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
}


export default DetailPesanan;