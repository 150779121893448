import DocumentPayment from "../../../api/Finance/document-payment";
import { resetAuth } from "../auth_action";
import { message } from "antd";

export const getBillingTransactionList = (token, params) => async (dispatch) => {
  try {
    const api = new DocumentPayment();

    dispatch({
      type: "SET_BILLING_TRANSACTION_LIST",
      payload: { loading: true, data: [] },
    });
    params.status = [params.status]

    let response = await api.getBillingTransaction(token, params);

    if (response.status === 401) {
      dispatch(resetAuth());
    }

    dispatch({
      type: "SET_BILLING_TRANSACTION_LIST",
      payload: { loading: false, data: response?.data },
    });
    delete response.data
    dispatch({
      type: "SET_META",
      payload: { loading: false, data: response },
    });
  } catch (error) {
    console.log(error?.response);
  }
};

export const getPaymentList = (token) => async (dispatch) => {
  try {
    const api = new DocumentPayment();
    let response = await api.getPaymentList(token);

    if (response.status === 401) {
      dispatch(resetAuth());
    }

    dispatch({
      type: "SET_PAYMENT_LIST",
      payload: { loading: false, data: response?.data },
    });
  } catch (error) {
    console.log(error?.response);
  }
};

export const getBillingTransactionById = (token, id, indexTransaction = 0) => async (dispatch) => {
  try {
    const api = new DocumentPayment();

    dispatch({
      type: "SET_BILLING_TRANSACTION_DETAIL",
      payload: { loading: true, data: [] },
    });

    let response = await api.getBillingTransactionById(token, id);

    if (response.status === 401) {
      dispatch(resetAuth());
    }
    
    dispatch({
      type: "SET_BILLING_TRANSACTION_DETAIL",
      payload: { loading: false, data: response?.data },
    });

    if(response?.data?.trx_order_billing.length){
    // if(response?.data?.trx_order_billing.length && response?.data?.trx_order_billing[indexTransaction]?.trx_order_billing_document?.length){
      dispatch({
        type: "SET_PARTNER_DOCUMENT",
        payload: { loading: false, data: response?.data?.trx_order_billing[indexTransaction]?.trx_order_billing_document },
      });
    }
    if(response?.data?.trx_order_billing.length){
    // if(response?.data?.trx_order_billing.length && response?.data?.trx_order_billing[indexTransaction]?.trx_order_billing_additional_data?.length){
      dispatch({
        type: "SET_ADDITIONAL_DATA",
        payload: { loading: false, data: response?.data?.trx_order_billing[indexTransaction]?.trx_order_billing_additional_data },
      });
    }
  } catch (error) {
    console.log(error?.response);
  }
};

export const setAdditional = (item) => async (dispatch) => {
  dispatch({
    type: "SET_PARTNER_DOCUMENT",
    payload: { loading: false, data: item?.trx_order_billing_document },
  });
  dispatch({
    type: "SET_ADDITIONAL_DATA",
    payload: { loading: false, data: item?.trx_order_billing_additional_data },
  });
}

export const setPartnerDocument = (data) => async (dispatch) => {
  dispatch({
    type: "SET_PARTNER_DOCUMENT",
    payload: { loading: false, data },
  });
}

export const patchConfirmBillingById = (token, id) => async (dispatch) => {
  try {
    const api = new DocumentPayment();

    let response = await api.patchConfirmBillingById(token, id);

    if (response.status === 401) {
      dispatch(resetAuth());
    }
    if(response.status === "success"){
      dispatch(getBillingTransactionById(token, id));
      message.success('Penagihan di Terima')
    }
  } catch (error) {
    console.log(error?.response);
  }
};

export const patchFinishCheckBillingById = (token, id) => async (dispatch) => {
  try {
    const api = new DocumentPayment();

    let response = await api.patchFinishCheckBillingById(token, id);

    if (response.status === 401) {
      dispatch(resetAuth());
    }
    if(response.status === "success"){
      dispatch(getBillingTransactionById(token, id));
      message.success('Pengecekan Selesai')
    }
  } catch (error) {
    console.log(error?.response);
  }
};

export const patchFinishPaymentBillingById = (token, id, payload) => async (dispatch) => {
  try {
    const api = new DocumentPayment();

    let response = await api.patchFinishPaymentBillingById(token, id, payload);

    if (response.status === 401) {
      dispatch(resetAuth());
    }
    if(response.status === "success"){
      dispatch(getBillingTransactionById(token, id));
      message.success('Pembayaran Selesai')
    }
  } catch (error) {
    console.log(error?.response);
  }
};

export const addBulkDocumentAndData = (token, id, payload, detailId, indexTransaction) => async (dispatch) => {
  try {
    const api = new DocumentPayment();

    let response = await api.addBulkDocumentAndData(token, id, payload);

    if (response.status === 401) {
      dispatch(resetAuth());
    }
    if(response.status === "success"){
      dispatch(getBillingTransactionById(token, detailId, indexTransaction));
      message.success('Berhasil tambah data')
    }
  } catch (error) {
    console.log(error?.response);
  }
};

export const updateDocumentById = (token, id, payload, detailId, indexTransaction) => async (dispatch) => {
  try {
    const api = new DocumentPayment();

    let response = await api.updateDocumentById(token, id, payload);

    if (response.status === 401) {
      dispatch(resetAuth());
    }
    if(response.status === "success"){
      dispatch(getBillingTransactionById(token, detailId, indexTransaction));
      message.success('Berhasil memperbarui data')
    }
  } catch (error) {
    console.log(error?.response);
  }
};

export const updateAdditionalDataById = (token, id, payload, detailId, indexTransaction) => async (dispatch) => {
  try {
    const api = new DocumentPayment();

    let response = await api.updateAdditionalDataById(token, id, payload);

    if (response.status === 401) {
      dispatch(resetAuth());
    }
    if(response.status === "success"){
      dispatch(getBillingTransactionById(token, detailId, indexTransaction));
      message.success('Berhasil memperbarui data')
    }
  } catch (error) {
    console.log(error?.response);
  }
};

export const deleteDocumentById = (token, id, detailId, indexTransaction) => async (dispatch) => {
  try {
    const api = new DocumentPayment();

    let response = await api.deleteDocumentById(token, id);

    if (response.status === 401) {
      dispatch(resetAuth());
    }
    if(response.status === "success"){
      dispatch(getBillingTransactionById(token, detailId, indexTransaction));
      message.success('Berhasil hapus data')
    }
  } catch (error) {
    console.log(error?.response);
  }
};

export const deleteAdditionalDataById = (token, id, detailId, indexTransaction) => async (dispatch) => {
  try {
    const api = new DocumentPayment();

    let response = await api.deleteAdditionalDataById(token, id);

    if (response.status === 401) {
      dispatch(resetAuth());
    }
    if(response.status === "success"){
      dispatch(getBillingTransactionById(token, detailId, indexTransaction));
      message.success('Berhasil hapus data')
    }
  } catch (error) {
    console.log(error?.response);
  }
};

export const patchBulkRejectDocument = (token, id, payload, detailId, detailTransactionIndex) => async (dispatch) => {
  try {
    const api = new DocumentPayment();

    let response = await api.patchBulkRejectDocument(token, id, payload);

    if (response.status === 401) {
      dispatch(resetAuth());
    }
    if(response.status === "success"){
      dispatch(getBillingTransactionById(token, detailId, detailTransactionIndex));
      message.success('Berhasil memperbarui data')
    }
  } catch (error) {
    console.log(error?.response);
  }
};

export const patchBulkAppropriateDocument = (token, id, payload, detailId, detailTransactionIndex) => async (dispatch) => {
  try {
    const api = new DocumentPayment();

    let response = await api.patchBulkAppropriateDocument(token, id, payload);

    if (response.status === 401) {
      dispatch(resetAuth());
    }
    if(response.status === "success"){
      dispatch(getBillingTransactionById(token, detailId, detailTransactionIndex));
      message.success('Berhasil memperbarui data')
    }
  } catch (error) {
    console.log(error?.response);
  }
};

export const patchResetDocument = (token, id, detailId, indexTransaction) => async (dispatch) => {
  try {
    const api = new DocumentPayment();

    let response = await api.patchResetDocument(token, id);

    if (response.status === 401) {
      dispatch(resetAuth());
    }
    if(response.status === "success"){
      dispatch(getBillingTransactionById(token, detailId, indexTransaction));
      message.success('Berhasil memperbarui data')
    }
  } catch (error) {
    console.log(error?.response);
  }
};

export const patchReceiptPaymentBill = (token, id, payload) => async (dispatch) => {
  try {
    const api = new DocumentPayment();

    let response = await api.patchReceiptPaymentBill(token, id, payload);

    if (response.status === 401) {
      dispatch(resetAuth());
    }
    if(response.status === "success"){
      dispatch(getBillingTransactionById(token, id));
      message.success('Berhasil memperbarui data')
    }
  } catch (error) {
    console.log(error?.response);
  }
};

export const getCountNotifPengecekan = (token) => async (dispatch) => {
  try {
    const api = new DocumentPayment();

    let response = await api.getCountNotifPengecekan(token);

    if (response.status === 401) {
      dispatch(resetAuth());
    }
    if(response.status === "success"){
      dispatch({
        type: "SET_BILLING_COUNT_PENGECEKAN",
        payload: { loading: false, data: response?.data?.total },
      });
      // message.success('Berhasil memperbarui data')
    }
  } catch (error) {
    console.log(error?.response);
  }
};
export const getCountNotifPembayaran = (token) => async (dispatch) => {
  try {
    const api = new DocumentPayment();

    let response = await api.getCountNotifPembayaran(token);

    if (response.status === 401) {
      dispatch(resetAuth());
    }
    if(response.status === "success"){
      dispatch({
        type: "SET_BILLING_COUNT_PEMBAYARAN",
        payload: { loading: false, data: response?.data?.total },
      });
      // message.success('Berhasil memperbarui data')
    }
  } catch (error) {
    console.log(error?.response);
  }
};