import React, { useState, useEffect } from "react";

/*======================== Third Party ======================== */
import { Button } from "react-bootstrap";
import { BsX } from "react-icons/bs";

/*======================== Utils ======================== */
import useBreakpoint from "./../../../components/responsiveUtils/useBreakpoint";

/*======================== Components ======================== */
import SelectFilter from "./../../../components/input/select";
import SelectDaterange from "./../../../components/input/daterange";
import SearchPartner from "./../../../components/input/seacrh";

/*======================== Icon ======================== */
import iconFilterTable from "./../../../assets/icon/icon_filter_tablet_mobile.svg"


const GroupFilter = (props) => {
  const { isMobile, isTablet, isDesktop } = useBreakpoint();

  /*======================== UseState ======================== */
  
  const [active, setActive] = useState(false)

  /*======================== Handler ======================== */

  const clearFilter = () => {
    props.handleClearFilter()
    if(!isDesktop){
      setActive(false)
    }
  }
  
  const submitFilter = () => {
    props.handleSubmitFilter()
    if(!isDesktop){
      setActive(false)
    }
  }

  /*======================== useEffect ======================== */
  
  useEffect(()=>{
    if(isDesktop){
      setActive(true)
    }else{
      setActive(false)
    }
  }, [isDesktop, isMobile, isTablet])

  /*======================== Return ======================== */


  return( 
    <div className="position-relative w-100 d-flex flex-column align-items-end">
      {/* image only show in tablet and mobile */}
      <img
        hidden={isDesktop}
        src={iconFilterTable}
        alt="iconFilterTable"
        className="text-end"
        style={{
          width: 52
        }}
        onClick={()=> setActive(!active)}
      />
      
      {/* in desktop value active alway true */}
      {active &&       
        <div 
          className={`w-100 px-3 ${isDesktop ? "py-1" : "mt-4 py-3 border rounded bg-white d-flex flex-column" } `} 
          style={{
            zIndex:"2",
            borderRadius:"10px"
          }}
        >
          <SelectDaterange
            placeholder="Pilih Rentan Tanggal"
            dateFilter={props.dateFilter}
            handleDateFilter={props.handleDateFilter}
          />
          <SelectFilter
            placeholder="Pilih Status Transaksi"
            options={props.options}
            handleChecked={props.handleChecked}
            />
          <SelectFilter
            placeholder="Pilih Jenis Pesanan"
            options={props.optionsTwo}
            handleChecked={props.handleChecked}
            />
          <SelectFilter
            placeholder="Pilih Tipe Alamat"
            options={props.optionsThree}
            handleChecked={props.handleChecked}
          />
          <hr/>
          <SearchPartner
            placeholder="Cari Partner Tertentu"
            dataOptions={props.dataOptions}
            handleAddPartnerFilter={props.handleAddPartnerFilter}
          />

          {/* data choose partner from search partner */}
          <div className={`px-0 mt-1 overflow-auto`} style={{height:`${isDesktop ? "175px" : "100px"}`}}>
            {props.choosePartner.map((opt,i)=>(
              <div
                key={i}
                className="py-2 px-3 mb-2 d-flex justify-content-between align-items-center" 
                style={{backgroundColor:"#2BBECB", color:"white", borderRadius:"8px"}}
              >
                <h6 className="m-0" style={{color:"white"}}>{opt.name}</h6>
                <BsX className="pointer" onClick={()=> props.handleDeletePartnerFilter(opt)}/>
              </div>
            ))}
          </div>
          
          <hr/>
          <div className={`px-4 d-flex ${isDesktop ? "justify-content-between align-items-center" : "flex-column-reverse"} `}>
            <h5
              className={`skyblue pointer ${!isDesktop && "w-100 text-center mb-2"} `}
              onClick={()=> clearFilter()}
            >
              Hapus Filter
            </h5>
            <Button size="sm"
              className={`fw-bold py-2 px-3 rounded-pill ${!isDesktop && "w-100 mb-3"}`}
              onClick={()=> submitFilter()}
            >
              Submit
            </Button>
          </div>
        </div>
      }
      </div>
  );
}

export default GroupFilter;
