import React, { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Divider, Avatar } from "antd";
import { useSelector } from "react-redux";
import moment from "moment";
import util from "../../helper/util";
import {
  getStatusDesc,
  getStatusTextColor,
} from "../../helper/trx_status_helper";
import useBreakpoint from "../../components/responsiveUtils/useBreakpoint";

import iconCircle from "../../assets/images/icon-circle.svg";
import iconMarkerO from "../../assets/images/icon-marker-o.svg";
import icon_clock_blue from "../../assets/images/icon_clock_blue.svg";
import icon_note_blue from "../../assets/images/icon_note_blue.svg";
import icon_personal_partner from "../../assets/images/icon_personal_partner.svg";
import icon_tervaksinasi from "../../assets/images/icon_tervaksinasi.svg";
import { BsChevronExpand, BsFillStarFill } from "react-icons/bs";
import { AiOutlineUser, AiFillPhone, AiOutlineFileText } from "react-icons/ai";
import { MdChevronRight, MdLocationPin } from "react-icons/md";
import { IoChatbubbleEllipses } from "react-icons/io5";
import { FaMapMarkedAlt } from "react-icons/fa";
import { BiEdit } from "react-icons/bi";
import ModalUpdateNoShipment from "./components/Modal/ModalUpdateNoShipment";
import OrderSendApi from "../../api/order-send-api";
import { useParams } from "react-router-dom";

const SendSection = ({
  openRouteDetailModal,
  openPartnerDetailModal,
  openContactModal,
  setShowMapTabletAndMobile,
  setShowDetailHistoryLogMobile,
  getOrderDetail
}) => {
  const trxDetail = useSelector((st) => st.trxHistoryReducer.trxDetail);
  const auth = useSelector((st) => st.authReducer);
  const { id } = useParams()
  console.log(trxDetail?.customer_no_shipment)
  const { isMobile, isTablet, isDesktop } = useBreakpoint();
  const [openForm, setOpenForm] = useState(false)

  const getCodSpotsTotal = (send_destination) => {
    // console.log(send_destination);
    return send_destination?.reduce((acc, val) => {
      if (val.send_destination != 0 && val.cod_amount != 0) return (acc += 1);
    }, 0);
  };

  const getCodAmountTotal = (send_destination) => {
    return send_destination?.reduce((acc, val) => {
      if (val.send_destination != 0 && val.cod_amount != 0)
        return (acc += val.cod_amount);
    }, 0);
  };

  const [showOtherSpot, setShowOtherSpot] = useState(false);

  const isTrxDone = () => {
    if (
      trxDetail?.data?.trx_booking_send?.status === "CANCELLED" ||
      trxDetail?.data?.trx_booking_send?.status === "FINISHED"
    ) {
      return true;
    }

    return false;
  };

  const handleUpdateAdditionalData = async (val) => {
    const payload = { customer_no_shipment: val }
    const api = new OrderSendApi();
    const response = await api.patchShipmentNumber(id, payload, auth.access_token)
    console.log(123, response)
    getOrderDetail()
  }

  return (
    <>
      <Row className="rounded-card bg-white p-4 mt-2" hidden={isDesktop}>
        <Col
          className="text-primary underline pointer"
          onClick={() =>
            isTrxDone()
              ? setShowDetailHistoryLogMobile((prev) => !prev)
              : setShowMapTabletAndMobile((prev) => !prev)
          }
        >
          <FaMapMarkedAlt
            hidden={isTrxDone()}
            className="me-2 mb-1"
            style={{ fontSize: "20px" }}
          />
          <h4 className="d-inline text-primary ">
            {isTrxDone()
              ? "Status Lengkap Pesanan"
              : "Live Tracking dan Status Lengkap Pesanan"}
          </h4>
        </Col>
      </Row>
      <Row
        className={`rounded-card bg-white p-4 mt-2 ${
          isMobile && "flex-column"
        }`}
      >
        <Col className="">
          <img src={icon_clock_blue} alt="" className="me-2" />
          Estimasi Waktu Pickup
        </Col>
        <Col className={`${isMobile ? "text-start mt-2 ms-4" : "text-end"} `}>
          <h4>
            {moment(trxDetail?.data?.trx_booking_send?.order_date).format(
              "DD MMMM YYYY"
            )}{" "}
            ,
            {moment(trxDetail?.data?.trx_booking_send?.order_date).format(
              "HH : mm"
            )}
            WIB
          </h4>
        </Col>
      </Row>
      <Row className="rounded-card bg-white p-2 px-4 mt-2">
        <div
          className={`d-flex ${
            isMobile ? "flex-column" : "justify-content-between"
          } `}
        >
          <h4>Status</h4>
          <h4
            className={`fw-bold ${getStatusTextColor(
              trxDetail?.data?.trx_booking_send?.status
            )}`}
          >
            {getStatusDesc(trxDetail?.data?.trx_booking_send?.status)}
          </h4>
        </div>
        <Divider orientation="left" />
        <Col>
          <div className="d-flex align-items-center gap-2">
            <div>
              <img src={iconCircle} alt="" className="" />
              <div
                className="br-dash"
                style={{
                  height: "35px",
                  marginLeft: "7px",
                  marginTop: "5px",
                }}
              />
            </div>
            <div>
              <h6>Lokasi Pengambilan</h6>
              <h6 className="fw-bold mt-2">
                {
                  trxDetail?.data?.trx_booking_send
                    ?.trx_booking_send_destination?.[0]?.contact_name
                }
              </h6>
              <h6 className="text-muted">
                {
                  trxDetail?.data?.trx_booking_send
                    ?.trx_booking_send_destination?.[0]?.address
                }
              </h6>
            </div>
          </div>
          {showOtherSpot &&
            trxDetail?.data?.trx_booking_send?.trx_booking_send_destination?.map(
              (el, i) => {
                if (
                  i == 0 ||
                  i ==
                    trxDetail?.data?.trx_booking_send
                      ?.trx_booking_send_destination?.length -
                      1
                )
                  return;

                return (
                  <div className="d-flex align-items-center gap-2">
                    <div>
                      <img src={el.destination_type === "PICKUP" ? iconCircle : iconMarkerO} alt="" className="" />
                      <div
                        className="br-dash"
                        style={{
                          height: "35px",
                          marginLeft: "7px",
                          marginTop: "5px",
                        }}
                      />
                    </div>
                    <div>
                      <h6 className="fw-bold">{el?.contact_name}</h6>
                      <h6 className="text-muted">{el?.address}</h6>
                    </div>
                  </div>
                );
              }
            )}
          {trxDetail?.data?.trx_booking_send?.trx_booking_send_destination
            ?.length > 2 && (
            <div className="d-flex align-items-center gap-2">
              <div
                className="br-dash"
                style={{
                  height: "35px",
                  marginLeft: "7px",
                  marginTop: "5px",
                }}
              />
              <h6
                className="text-secondary pointer"
                style={{ fontSize: "12px" }}
                onClick={() => setShowOtherSpot((prev) => !prev)}
              >
                {!showOtherSpot &&
                  trxDetail?.data?.trx_booking_send
                    ?.trx_booking_send_destination?.length - 2}{" "}
                {"  "}
                {showOtherSpot ? "Sembunyikan" : "Perhentian Lainnya"}
                <span>
                  <BsChevronExpand />
                </span>
              </h6>
            </div>
          )}

          
          <div className="d-flex align-items-center gap-2">
            <div>
              <img src={iconMarkerO} alt="" className="" />
            </div>
            <div>
              <h6>Lokasi Pengiriman Akhir</h6>

              <h6 className="fw-bold">
                {
                  trxDetail?.data?.trx_booking_send
                    ?.trx_booking_send_destination?.[
                    trxDetail?.data?.trx_booking_send
                      ?.trx_booking_send_destination?.length - 1
                  ]?.contact_name
                }
              </h6>
              <h6 className="text-muted">
                {
                  trxDetail?.data?.trx_booking_send
                    ?.trx_booking_send_destination?.[
                    trxDetail?.data?.trx_booking_send
                      ?.trx_booking_send_destination?.length - 1
                  ]?.address
                }
              </h6>
            </div>
          </div>



          <div
            className="d-flex align-items-center gap-2 mt-2"
            onClick={openRouteDetailModal}
          >
            <h4 className="text-primary pointer">Detail Rute</h4>
          </div>
          <Divider orientation="left" />
        </Col>
        <div className="">
          <div className="d-flex justify-content-between">
            <h5>Info Barang</h5>
            <h5 className="fw-bold">
              {" "}
              {
                trxDetail?.data?.trx_booking_send
                  ?.trx_booking_send_destination?.[0]?.item_type
              }
            </h5>
          </div>
          <div className="d-flex justify-content-between">
            <h5>Titik Penagihan</h5>
            <h5 className="fw-bold">
              {getCodSpotsTotal(
                trxDetail?.data.trx_booking_send?.trx_booking_send_destination
              ) || 0}{" "}
              Lokasi
            </h5>
          </div>
          <div className="d-flex justify-content-between">
            <h5>Total Tagihan Pembayaran Barang</h5>
            <h5 className="fw-bold">
              Rp{" "}
              {util.thousandSeparatorPure(
                getCodAmountTotal(
                  trxDetail?.data.trx_booking_send?.trx_booking_send_destination
                ) || 0
              )}
            </h5>
          </div>
        </div>
      </Row>
      <Row className="rounded-card bg-white p-2 px-4 mt-2">
        <div className="d-flex align-items-center">
          <img
            src={icon_note_blue}
            className="i-sidebar"
            width={20}
            height={20}
            style={{ marginLeft: "-20px" }}
            alt=""
          />
          <h4 className="mt-1">Layanan</h4>
        </div>
        <ul className="trx-services">
          <li className="trx-services">
            {/* <h4> */}
            Tenaga Supir <span className="text-success">(Termasuk)</span>{" "}
            {/* </h4> */}
          </li>
          <li
            className="trx-services"
            hidden={trxDetail?.data?.trx_booking_send?.total_helper === 0}
          >
            Helper / Kenek ({trxDetail?.data?.trx_booking_send?.total_helper}{" "}
            Orang)
          </li>
          <li
            className="trx-services"
            hidden={!trxDetail?.data?.trx_booking_send?.insurance_fee}
          >
            Asuransi
          </li>
        </ul>
          <div className="m-0 mb-1 d-flex align-items-center">
            No. Shipment {trxDetail?.data?.customer_no_shipment ?? "-"}
            <BiEdit className="ms-2 text-primary pointer" onClick={()=> setOpenForm(true)}/>
            <ModalUpdateNoShipment
              isOpen={openForm}
              handleClose={() => setOpenForm(false)}
              value={trxDetail?.data?.customer_no_shipment}
              handleSubmit={handleUpdateAdditionalData}
            />
          </div>
      </Row>
      <Row className="rounded-card bg-white p-2 px-4 mt-2">
        <div className="d-flex align-items-center justify-content-between">
          <h4>Mitra Layanan</h4>
          <div className="d-flex pointer" onClick={openPartnerDetailModal}>
            <h5 className="fw-bold me-1">
              {trxDetail?.data?.mst_partner_service?.mst_partner?.name}
            </h5>
            <MdChevronRight />
          </div>
        </div>
        <Divider orientation="left" />
        <Col xs={4} md={2}>
          {/* <Badge
                offset={[0, 65]}
                count={"✓"}
                style={{ backgroundColor: "#198754" }}
              > */}
          <Avatar
            shape="square"
            size={72}
            src={
              trxDetail?.data?.mst_partner_service?.mst_partner?.profile_picture
            }
            icon={
              !trxDetail?.data?.mst_partner_service?.mst_partner
                ?.profile_picture && <AiOutlineUser />
            }
          ></Avatar>
          {/* </Badge> */}
        </Col>
        <Col>
          <div className="d-flex align-items-center">
            <h6 className="fw-bold">{trxDetail?.data?.mst_driver?.name}</h6>
            {trxDetail?.data?.mst_partner_service?.mst_partner
              ?.partner_account_type === "PERSONAL" && (
              <img src={icon_personal_partner} alt="" className="mb-2 ms-2" />
            )}
            {trxDetail?.data?.mst_driver?.is_vaccinated > 0 && (
              <img src={icon_tervaksinasi} alt="" className="mb-2 ms-2" />

              // <Tag color={"green"} className="mb-2 ms-2">
              //   Tervaksinasi
              // </Tag>
            )}
          </div>
          <div className="d-flex align-items-center">
            <h6 className="fw-bold">
              <BsFillStarFill className="text-warning mb-1 me-1" />
              {trxDetail?.data?.mst_driver?.rating || 0}{" "}
            </h6>
            <h6 className="text-muted">
              {" "}
              | {trxDetail?.data?.mst_driver?.total_order || 0} Order Selesai
            </h6>
          </div>
          <div hidden={isMobile}>
            <h5 className="fw-bold">
              {
                trxDetail?.data?.trx_booking_send?.mst_vehicle
                  ?.mst_vehicle_subtype?.name
              }{" "}
              - {trxDetail?.data?.trx_booking_send?.mst_vehicle?.plate_number}
            </h5>
          </div>
        </Col>
        <Col xs={2}>
          {!isTrxDone() && (
            <div className="d-flex align-items-center gap-2 justify-content-end">
              <Button variant="outline-primary">
                <IoChatbubbleEllipses />
              </Button>
              <Button variant="outline-primary" onClick={openContactModal}>
                <AiFillPhone />
              </Button>
              {/* <ContactModal /> */}
            </div>
          )}
        </Col>
      </Row>
      <Row className="rounded-card bg-white p-2 px-4 mt-2">
        <div className="d-flex align-items-center justify-content-between">
          <h4>Rincian Pembayaran</h4>
        </div>
        <Divider orientation="left" />
        <div className="d-flex align-items-center justify-content-between">
          <h5>Metode Pembayaran</h5>
          <h5>{trxDetail?.data?.trx_payment?.payment_method_name}</h5>
        </div>
        <Divider orientation="left" />
        <div className="d-flex align-items-center justify-content-between">
          <h5>Tarif Dasar</h5>
          <h5>
            Rp{" "}
            {util.thousandSeparatorPure(
              trxDetail?.data?.trx_booking_send?.base_pricing_send
            )}
          </h5>
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <h5>
            Tarif Kenaikan ({trxDetail?.data?.trx_booking_send?.total_km} KM)
          </h5>
          <h5>
            Rp{" "}
            {util.thousandSeparatorPure(
              trxDetail?.data?.trx_booking_send?.total_per_km_price
            )}
          </h5>
        </div>
        <div className="">
          <h5>Layanan Tambahan</h5>
          <ul className="addtional">
            <li>
              Tenaga Supir
              <span className="float-end">Rp 0</span>
            </li>
            <li hidden={trxDetail?.data?.trx_booking_send?.total_helper === 0}>
              Helper
              <span className="float-end">
                Rp{" "}
                {util.thousandSeparatorPure(
                  trxDetail?.data?.trx_booking_send?.total_helper_price || 0
                )}
              </span>
            </li>
            <li hidden={!trxDetail?.data?.trx_booking_send?.insurance_fee}>
              Asuransi
              <span className="float-end">
                Rp{" "}
                {util.thousandSeparatorPure(
                  trxDetail?.data?.trx_booking_send?.insurance_fee || 0
                )}
              </span>
            </li>
            <a
              href={trxDetail?.data?.trx_booking_send?.insurance_policy_file}
              target="_blank"
              rel="norefferer"
              hidden={!trxDetail?.data?.trx_booking_send?.insurance_policy_file}
              className="ms-2 fw-bold text-muted pointer"
              style={{ fontSize: "11px" }}
            >
              Lihat Lampiran Klaim Asuransi
              <AiOutlineFileText
                className="ms-2 mb-1"
                style={{ fontSize: "12px" }}
              />
            </a>
          </ul>
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <h5>Pajak</h5>
          <h5>
            Rp{" "}
            {util.thousandSeparatorPure(
              parseInt(trxDetail?.data?.customer_vat) +
                parseInt(trxDetail?.data?.customer_service_fee)
            ) || 0}
          </h5>
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <h5>Admin Fee</h5>
          <h5>
            Rp{" "}
            {util.thousandSeparatorPure(
              trxDetail?.data?.trx_payment?.amount_admin
            )}
          </h5>
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <h5>Diskon</h5>
          <h5>
            (Rp{" "}
            {util.thousandSeparatorPure(
              trxDetail?.data?.elog_discount + trxDetail?.data?.partner_discount
            ) || 0}
            )
          </h5>
        </div>
        <Divider orientation="left" />
        <div className="d-flex align-items-center justify-content-between">
          <p></p>
          <h5 className="fw-bold">Total Biaya Layanan</h5>
          <h5 className="fw-bold">
            (Rp{" "}
            {util.thousandSeparatorPure(trxDetail?.data?.trx_payment?.amount)})
          </h5>
        </div>
      </Row>
    </>
  );
};

export default SendSection;
