/* eslint-disable react-hooks/exhaustive-deps */
import { Badge } from "antd";
import PropTypes from "prop-types";
import moment from "moment";
import React from "react";
import { Col, Dropdown, Row } from "react-bootstrap";
import { FiMoreVertical } from "react-icons/fi";
import { useHistory } from "react-router-dom";

const ProcurementItemCard = (props) => {
  /*======================== Props ======================== */

  const { item, setDataDelete, setOpenDelete, actions } = props;

  /*======================== Hooks ======================== */

  const history = useHistory();

  /*======================== UseState ======================== */

  /*======================== Handler ======================== */

  const handleStatusLabel = (status) => {
    switch (status) {
      case "DRAFT":
        return "Draft";
      case "WAITING SCHEDULE":
        return "Menunggu Jadwal";
      case "CANCEL":
        return "Dibatalkan";
      case "PROCESS":
        return "Proses Penawaran";
      case "PARTNER SELECTION":
        return "Seleksi Partner";
      case "DONE":
        return "Kontrak Selesai";
      default:
        return "";
    }
  };
  const handleStatusColor = (status) => {
    switch (status) {
      case "DRAFT":
      case "PROCESS":
        return "text-orange text-capitalize";
      case "WAITING SCHEDULE":
        return "text-blue text-capitalize";
      case "CANCEL":
        return "text-red text-capitalize";
      case "DONE":
        return "text-green text-capitalize";
      case "PARTNER SELECTION":
        return "text-blue text-capitalize";
      default:
        return "sub-label text-capitalize";
    }
  };

  const handleAction = (action) => {
    if (action === "delete") {
      setDataDelete(item);
      setOpenDelete(true);
    }
    if (action === "detail") {
      history.push("/penawaran/detail/" + item.id);
    }
    if (action === "edit") {
      history.push("/penawaran/edit-penawaran/" + item.id);
    }
  };

  /*======================== UseEffect ======================== */

  /*======================== Return ======================== */

  return (
    <Col xs={12} md={6} className="mb-3">
      <div className="bg-white rounded px-4 py-3">
        <Row>
          <div className="d-flex justify-content-between align-items-center">
            <div
              className="d-flex align-items-center"
              style={{ maxWidth: "65%" }}
            >
              <p className="title mb-0 text-ellipsis me-2">{item.name}</p>
              {item.new > 0 && (
                <Badge
                  className="text-capitalize"
                  count={item.new + " tawaran rute"}
                />
              )}
            </div>
            <div>
              <Dropdown className="width-100">
                <Dropdown.Toggle id="dropdown-autoclose-true" size="sm">
                  <FiMoreVertical fontSize={15} className="pointer" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu rounded-10 custom-scroll">
                  {actions.map((item, index) => (
                    <div key={item}>
                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          handleAction(item);
                        }}
                        className="text-capitalize"
                      >
                        {item}
                      </Dropdown.Item>
                      {index !== actions.length - 1 && (
                        <hr className="mx-3 my-2" />
                      )}
                    </div>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <p className="sub-label text-capitalize">
            Penawaran: {moment(item.start_offering_date).format("DD MMMM YYYY")}{" "}
            - {moment(item.end_offering_date).format("DD MMMM YYYY")}
          </p>
          <div className="d-flex" style={{ gap: "4rem" }}>
            <div>
              <p className="label mb-1 text-capitalize">Tanggal Kontrak</p>
              <p className="sub-label text-capitalize mb-0">
                {moment(item.start_contract_date).format("DD MMMM YYYY")} -{" "}
                {moment(item.end_contract_date).format("DD MMMM YYYY")}
              </p>
            </div>
            <div>
              <p className="label mb-1 text-capitalize">Penawaran Rute</p>
              <p
                className={`sub-label text-capitalize mb-0${
                  item.route_filled > 0 ? " green" : ""
                }`}
              >{`${item.route_filled}/${item.route_needed} Rute Terisi`}</p>
            </div>
            <div>
              <p className="label mb-1 text-capitalize mb-0">
                Status Penawaran
              </p>
              <p className={handleStatusColor(item.status)}>
                {handleStatusLabel(item.status)}
              </p>
            </div>
          </div>
        </Row>
      </div>
    </Col>
  );
};

ProcurementItemCard.propTypes = {
  item: PropTypes.object,
  is_procurement: PropTypes.bool,
};

export default ProcurementItemCard;
