import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import App from "./App";
import store from "./redux/store";

// vendor
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import "react-datepicker/dist/react-datepicker.css";
import "antd/dist/antd.css";
import "animate.css";
import "./styles/style.scss";

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
