/* eslint-disable react-hooks/exhaustive-deps */
import { message, Spin } from "antd";
import { useEffect } from "react";
import { useState, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Tabs,
  Tab,
  Dropdown,
  Button,
  Badge,
} from "react-bootstrap";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { formatThousand } from "../../../helper/formatCurrency";
import {
  changeOfferStatus,
  getProcurementContractDetailRoutePartner,
  getProcurementContractDetailRoutePartnerWaiting,
  getProcurementContractRouteDetail,
} from "../../../redux/action/Management/procurement_action";
import noData from "../../../assets/images/no-data.png";
import "./styles.scss";

const DetailRutePenawaran = () => {
  /*======================== Hooks ======================== */

  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const loading = useSelector((st) => st.procurementReducer.loading);

  /*======================== State ======================== */

  const [eventKey, setEventKey] = useState("1");
  const [sortBy, setSortBy] = useState("Terbaru");
  const [data, setData] = useState(undefined);
  const [partnerApproved, setPartnerApproved] = useState(undefined);
  const [partnerWaiting, setPartnerWaiting] = useState(undefined);
  const partnerOfferStatusEnum = {
    Approved: "APPROVED",
    Rejected: "REJECTED",
  };

  /*======================== Handler ======================== */

  const handleBack = () => {
    history.push("/penawaran/detail/" + params.id);
  };

  const handleChangeTab = (key) => {
    setEventKey(key);
  };

  const handleSubmit = async (status, id) => {
    try {
      const payload = {
        status,
      };
      await dispatch(changeOfferStatus(payload, id));
      message.success("Berhasil mengupdate status penawaran");
      await handleLoadPartnerApproved();
      await handleLoadPartnerWaiting();
    } catch (error) {
      message.error("Gagal mengupdate status penawaran");
    }
  };

  /*======================== useEffect ======================== */

  const handleSortBy = (value) => {
    if (eventKey === "1") {
      handleLoadPartnerApproved({
        order_by: value === "TERBARU" ? "DESC" : "ASC",
      });
    } else if (eventKey === "2") {
      handleLoadPartnerWaiting({
        order_by: value === "TERBARU" ? "DESC" : "ASC",
      });
    }
    setSortBy(value);
  };

  const handleLoadPartnerApproved = async (others) => {
    const response = await dispatch(
      getProcurementContractDetailRoutePartner({
        id: params.idRute,
        page: 1,
        limit: 9999,
        sort_by: "created_at",
        order_by: "DESC",
        status: "APPROVED",
        ...others,
      })
    );
    setPartnerApproved(response);
  };

  const handleLoadPartnerWaiting = async (others) => {
    const response = await dispatch(
      getProcurementContractDetailRoutePartnerWaiting({
        id: params.idRute,
        page: 1,
        limit: 9999,
        sort_by: "created_at",
        order_by: "DESC",
        ...others,
      })
    );
    setPartnerWaiting(response);
  };
  const handleStatusLabel = (status) => {
    switch (status) {
      case "WAITING":
        return "Menunggu Konfirmasi";
      case "NEGOTIATION":
        return "Sedang Negosiai";
      case "APPROVED":
        return "Tawaran Diterima";

      default:
        return "-";
    }
  };
  const handleStatusColor = (status) => {
    switch (status) {
      case "WAITING":
        return "mb-2 partner-card-text warning";
      case "NEGOTIATION":
        return "mb-2 partner-card-text info";
      case "APPROVED":
        return '"mb-2 partner-card-text success"';

      default:
        return "-";
    }
  };
  const handleSeeDetail = (item) => {
    history.push(
      "/partner/penawaran/" +
        item.partner_id +
        "/" +
        data.delivery_procurement_id +
        "/detail/" +
        item.id
    );
  };

  useEffect(() => {
    async function getDetail() {
      const response = await dispatch(
        getProcurementContractRouteDetail(params.idRute)
      );
      setData(response);
    }
    getDetail();
  }, []);

  useEffect(() => {
    handleLoadPartnerApproved();
    handleLoadPartnerWaiting();
  }, []);

  /*======================== Return ======================== */

  return (
    <div className="procurement-detail procurement-route-detail">
      <div
        style={{ minHeight: "100vh", width: "100%", padding: "30px" }}
        className="bg-ice custom-form pb-2 height-100"
      >
        {loading && (
          <Card
            className="p-4 rounded-card border-0 d-flex align-items-center justify-content-center mt-4"
            style={{
              height: "350px",
            }}
          >
            <Spin />
          </Card>
        )}
        {data && (
          <>
            <Card
              className="p-4 rounded-card border-0"
              style={{ backgroundColor: "white" }}
            >
              <div className="d-flex flex-row align-items-center">
                <FiChevronLeft
                  fontSize={25}
                  className="pointer me-3"
                  onClick={handleBack}
                />
                <div>
                  <h1 style={{ fontSize: "24px" }}>Detail Rute Penawaran</h1>
                  <p>{data.route_name}</p>
                  <p className="mb-0">
                    Partner Terpilih: {data.selected_partner?.length || 0}
                  </p>
                </div>
              </div>
            </Card>

            <Row className="procurement-detail-content mt-4">
              <Col xs={12} sm={12} md={6}>
                <h5 className="fw-normal mb-2 fw-bold">Informasi Penawaran</h5>
                <div className="bg-white rounded-card w-full pt-3 ps-3 mb-2">
                  <Row>
                    <Col xs={6}>
                      <p className="text-muted label mb-0">Kode Rute</p>
                      <p>{data.route_code}</p>
                    </Col>
                    <Col xs={6}>
                      <p className="text-muted label mb-0">Nama Rute</p>
                      <p>{data.route_name}</p>
                    </Col>
                  </Row>
                </div>

                <h5 className="fw-normal mb-2 mt-3 fw-bold">Detail Alamat</h5>
                <div className="bg-white rounded-card w-full pt-3 pb-2 ps-3 mb-2">
                  <p className="text-muted label mb-0">Alamat Pengambilan</p>
                  <p>
                    {data.route_data?.mst_address_pick_up?.complete_address ||
                      "-"}
                  </p>

                  <hr style={{ color: "#9d9fa1" }} />

                  <p className="text-muted label mb-0">Alamat Pengiriman</p>
                  <p>
                    {data.route_data?.mst_address_delivery?.complete_address ||
                      "-"}
                  </p>
                </div>

                <h5 className="fw-normal mb-2 mt-3 fw-bold">
                  Total Budget dan Kendaraan
                </h5>
                <div className="bg-white rounded-card w-full pt-3 ps-3 mb-2">
                  <Row>
                    <Col xs={6}>
                      <p className="text-muted label mb-0">
                        Total Budget Penawaran
                      </p>
                      <p>Rp. {formatThousand(data.route_budget || 0)}</p>
                    </Col>
                    <Col xs={6}>
                      <p className="text-muted label mb-0">
                        Total Kebutuhan Kendaraan
                      </p>
                      <p> {data.vehicle_quantity_needed} Kendaraan</p>
                    </Col>
                  </Row>
                </div>
                <h5 className="fw-normal mb-2 mt-3 fw-bold">
                  Kebutuhan Kendaraan
                </h5>
                {data.vehicles?.length > 0
                  ? data.vehicles.map((item) => (
                      <div className="bg-white rounded-card w-full mb-2 p-3">
                        <Row>
                          <Col xs={6}>
                            <p className="text-muted label mb-0">
                              Jenis Kendaraan
                            </p>
                            <p>
                              {item.name || "-"} - {item.volume || "-"} m3 -{" "}
                              {item.tonage || "-"} kg
                            </p>
                          </Col>
                          <Col xs={3}>
                            <p className="text-muted label mb-0">Kebutuhan</p>
                            <p>{item.quantity || 0} Kendaraan</p>
                          </Col>
                          <Col xs={3}>
                            <p className="text-muted label mb-0">
                              Budget Kebutuhan
                            </p>
                            <p>Rp. {formatThousand(item.budget || 0)}</p>
                          </Col>
                        </Row>
                      </div>
                    ))
                  : "-"}
              </Col>
              <Col xs={12} sm={12} md={6}>
                <Card
                  className="p-4 rounded-card border-0"
                  style={{ backgroundColor: "white" }}
                >
                  <h1 className="mb-0" style={{ fontSize: "24px" }}>
                    Daftar Partner Diterima
                  </h1>
                  <p className="text-muted">
                    List partner yang anda terima untuk menjalankan kontrak
                  </p>

                  <Tabs
                    defaultActiveKey={eventKey}
                    className="tabs-trx mb-2 border-bottom-2 p-0"
                    onSelect={handleChangeTab}
                  >
                    <Tab eventKey="1" title="Partner Diterima"></Tab>
                    <Tab eventKey="2" title="Penawaran Partner"></Tab>
                  </Tabs>
                </Card>

                <div className="mt-4">
                  <Row>
                    <Col xs={2}></Col>
                    <Col
                      xs={10}
                      className="d-flex justify-content-end align-item-center"
                    >
                      <span className="me-3">Urutkan Berdasarkan:</span>
                      <Dropdown className="d-inline ">
                        <Dropdown.Toggle
                          id="dropdown-autoclose-true"
                          size="sm"
                          variant="light"
                          className="bg-white text-secondary px-3 border shadow-sm rounded"
                        >
                          {sortBy}
                        </Dropdown.Toggle>
                        <Dropdown.Menu align="end">
                          <Dropdown.Item
                            href="#"
                            onClick={() => {
                              handleSortBy("Terbaru");
                            }}
                          >
                            <p className="text-muted mb-0">Terbaru </p>
                          </Dropdown.Item>
                          <Dropdown.Item
                            href="#"
                            onClick={() => {
                              handleSortBy("Terlama");
                            }}
                          >
                            <p className="text-muted mb-0">Terlama </p>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  </Row>
                </div>

                {/* Partner*/}
                <div
                  className="custom-scroll"
                  style={{ maxHeight: "500px", overflowX: "hidden" }}
                >
                  {eventKey === "1" ? (
                    <>
                      {partnerApproved?.length > 0 ? (
                        partnerApproved.map((item) => (
                          <div className="bg-white rounded-card w-full pt-2 ps-3 mt-2">
                            <Row>
                              <Col xs={3} sm={3} md={6} lg={2}>
                                <div className="partner-card-avatar">
                                  <div className="content">
                                    {item.mst_partner.name
                                      .split(" ")
                                      .map((n) => n[0])
                                      .join(".")}
                                  </div>
                                </div>
                              </Col>
                              <Col xs={9} sm={9} md={6} lg={7}>
                                <p className="fw-bolder mb-2">
                                  {item.mst_partner.name}
                                </p>

                                <div className="partner-card-detail">
                                  <p className="text-muted mb-0">
                                    Status Penawaran:
                                  </p>
                                  <p className={handleStatusColor(item.status)}>
                                    {handleStatusLabel(item.status)}
                                  </p>
                                </div>

                                <div className="partner-card-detail">
                                  <p className="text-muted mb-0">
                                    Total Penawaran:
                                  </p>
                                  <p className="mb-2">
                                    Rp. {formatThousand(item.amount)}
                                  </p>
                                </div>

                                <div className="partner-card-detail">
                                  <p className="text-muted mb-0">
                                    Penawaran Kendaraan:
                                  </p>
                                  <p className="mb-2">
                                    {item.vehicles?.length || 0} Kendaraan
                                  </p>
                                </div>
                              </Col>
                              <Col xs={12} sm={12} md={12} lg={3}>
                                <div className="mt-3 me-2">
                                  <Button
                                    size="sm"
                                    className="py-2 rounded-10 text-capitalize mb-2 w-100"
                                    style={{
                                      background: "transparent",
                                      borderColor: "#ce1771",
                                      color: "#ce1771",
                                    }}
                                    onClick={() => {
                                      handleSubmit(
                                        partnerOfferStatusEnum.Rejected,
                                        item.id
                                      );
                                    }}
                                  >
                                    Batalkan
                                  </Button>
                                  <Button
                                    size="sm"
                                    className="py-2 rounded-10 text-capitalize w-100"
                                    onClick={() => handleSeeDetail(item)}
                                  >
                                    Lihat Detail
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        ))
                      ) : (
                        <div
                          className="d-flex flex-column align-items-center justify-content-center"
                          style={{ marginTop: "5rem" }}
                        >
                          <img src={noData} alt="no-data" />
                          <p className="mt-1">Tidak ada partner diterima</p>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {partnerWaiting?.length > 0 ? (
                        partnerWaiting.map((item) => (
                          <div className="bg-white rounded-card w-full pt-2 ps-3 mt-2">
                            <Row>
                              <Col xs={3} sm={3} md={6} lg={2}>
                                <div className="partner-card-avatar">
                                  <div className="content">
                                    {item.mst_partner.name
                                      .split(" ")
                                      .map((n) => n[0])
                                      .join(".")}
                                  </div>
                                </div>
                              </Col>
                              <Col xs={9} sm={9} md={6} lg={7}>
                                <p className="fw-bolder mb-2">
                                  {item.mst_partner.name}
                                </p>

                                <div className="partner-card-detail">
                                  <p className="text-muted mb-0">
                                    Status Penawaran:
                                  </p>
                                  <p className={handleStatusColor(item.status)}>
                                    {handleStatusLabel(item.status)}
                                  </p>
                                </div>

                                <div className="partner-card-detail">
                                  <p className="text-muted mb-0">
                                    Total Penawaran:
                                  </p>
                                  <p className="mb-2">
                                    Rp. {formatThousand(item.amount)}
                                  </p>
                                </div>

                                <div className="partner-card-detail">
                                  <p className="text-muted mb-0">
                                    Penawaran Kendaraan:
                                  </p>
                                  <p className="mb-2">
                                    {item.vehicles?.length || 0} Kendaraan
                                  </p>
                                </div>
                              </Col>
                              <Col xs={12} sm={12} md={12} lg={3}>
                                <div className="mt-3 me-2">
                                  <Button
                                    size="sm"
                                    variant="outline-dark"
                                    className="py-2 rounded-10 text-capitalize mb-2 w-100"
                                    onClick={() => {
                                      handleSubmit(
                                        partnerOfferStatusEnum.Approved,
                                        item.id
                                      );
                                    }}
                                  >
                                    Terima Partner
                                  </Button>
                                  <Button
                                    size="sm"
                                    className="py-2 rounded-10 text-capitalize w-100"
                                    onClick={() => handleSeeDetail(item)}
                                  >
                                    Lihat Detail
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        ))
                      ) : (
                        <div
                          className="d-flex flex-column align-items-center justify-content-center"
                          style={{ marginTop: "5rem" }}
                        >
                          <img src={noData} alt="no-data" />
                          <p className="mt-1">Tidak ada penawaran partner</p>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </>
        )}
      </div>
    </div>
  );
};

export default DetailRutePenawaran;
