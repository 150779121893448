import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Tabs,
  Tab,
  InputGroup,
  FloatingLabel,
  Form,
  Dropdown,
} from "react-bootstrap";
import useBreakpoint from "../../../components/responsiveUtils/useBreakpoint";
import { FaSearch } from "react-icons/fa";
import { IoMenuOutline } from "react-icons/io5";
import { openSidenavMobile } from "../../../components/layouts/layout_helper";
import Rute from "./Rute";
import AlamatAnda from "./AlamatAnda";
import AlamatPengiriman from "./AlamatPengiriman";

import RentAlamatAnda from "./Rent/AlamatAnda"

import { useHistory, useLocation } from "react-router-dom";
import useDebounce from "../../../hooks/useDebounce";

const RuteAlamat = () => {
  /*======================== Hooks ======================== */

  const { isMobile } = useBreakpoint();
  const location = useLocation();
  const history = useHistory();

  /*======================== UseState ======================== */

  const [activeStatusFilter, setActiveStatusFilter] = useState("Semua");
  const [tab, setTab] = useState("alamat anda");
  const [layanan, setLayanan] = useState(1);
  const [search, setSearch] = useState(undefined);
  const debounceSearch = useDebounce(search, 300);

  /*======================== Handler ======================== */

  const handleChange = (type, value) => {
    switch (type) {
      case "tab":
        setTab(value);
        break;
      case "layanan":
        setLayanan(parseInt(value));
        break;

      default:
        break;
    }
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  /*======================== UseEffect ======================== */

  useEffect(() => {
    if (location.state?.section) {
      setTab(location.state.section);
      if(location.state?.service){
        setLayanan(location.state?.service)
      }
      history.replace({ pathname: location.pathname, state: {} });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*======================== Others ======================== */

  const tabOptions = [
    {
      title: "Alamat Anda",
      value: "alamat anda",
    },
    {
      title: "Alamat Pengiriman",
      value: "alamat pengiriman",
    },
    {
      title: "Rute Anda",
      value: "rute anda",
    }
  ];
  const layananOptions = [
    {
      title: "Kirim Barang",
      value: 1,
    },
    {
      title: "Sewa Kendaraan",
      value: 2,
    },
  ];

  /*======================== Return ======================== */

  return (
    <div
      style={{ minHeight: "100vh", width: "100%", padding: "30px" }}
      className="bg-ice height-100"
    >
      {!isMobile ? (
        <Card
          className="p-4 rounded-card border-0"
          style={{ backgroundColor: "white" }}
        >
          <h1>Management Rute & Alamat</h1>
          <Row className="card-body">
            <Col>
              <Tabs
                // defaultActiveKey={tab}
                activeKey={tab}
                className="tabs-trx mb-2 border-bottom-2"
                onSelect={(value) => handleChange("tab", value)}
              >
                {tabOptions.map((item, index) => (
                  <Tab key={index} eventKey={item.value} title={item.title} />
                ))}
              </Tabs>
            </Col>
          </Row>
          <Row className="ps-4 align-items-center">
            {/* sementara hanya kirim barang saja */}
            {tab === "alamat anda" && (
              <>
                <span className="" style={{ width: "auto" }}>
                  Layanan:
                </span>
                <Col>
                  <Tabs
                    activeKey={layanan}
                    className="tabs-rute mb-2 border-0"
                    style={{ gap: "10px" }}
                    onSelect={(value) => handleChange("layanan", value)}
                  >
                    {layananOptions.map((item, index) => (
                      <Tab
                        key={index}
                        eventKey={item.value}
                        title={item.title}
                      />
                    ))}
                  </Tabs>
                </Col>
              </>
            )}
            <Col md={6} lg={4}>
              <InputGroup className="border rounded-card">
                <FloatingLabel
                  className="rounded-card w-75"
                  label={"Cari " + tab}
                  style={{ width: "", fontSize: "12px", height: "50px" }}
                >
                  <Form.Control
                    className="border-0 rounded-card"
                    type="text"
                    style={{ height: "50px" }}
                    placeholder={"Cari " + tab}
                    onChange={(event) => handleSearch(event)}
                  />
                </FloatingLabel>
                <InputGroup.Text
                  className="border-0 search-icon-trx rounded-card ms-auto"
                  style={{ height: "50px" }}
                >
                  <FaSearch />
                </InputGroup.Text>
              </InputGroup>
            </Col>
          </Row>
        </Card>
      ) : (
        <div className="p-2 head-menu trx w-100 shadow-md">
          <div
            className="d-flex mb-2 justify-content-center px-2 flex-column"
            style={{ marginTop: "1.7em" }}
          >
            <div className="d-flex">
              <IoMenuOutline
                onClick={openSidenavMobile}
                className="text-secondary pointer me-2"
                style={{ fontSize: 25 }}
              />

              <h4 className="mt-1">Daftar Rute & Alamat</h4>
            </div>
            {/* <Row className="card-body"> */}
            <Col>
              <Tabs
                defaultActiveKey={"rute anda"}
                className="tabs-trx mb-2 border-bottom-2"
                onSelect={(value) => handleChange("tab", value)}
              >
                {tabOptions.map((item, index) => (
                  <Tab key={index} eventKey={item.value} title={item.title} />
                ))}
              </Tabs>
            </Col>
            {/* </Row> */}
            <Row className="d-flex align-items-center">
              {/* sementara hanya kirim barang saja */}
              {false && tab === "alamat anda" && (
                <Col className="d-flex" style={{ gap: "10px" }}>
                  <h4 className="mt-2">Layanan</h4>
                  <Dropdown className="d-inline mx-2 ms-auto">
                    <Dropdown.Toggle
                      id="dropdown-autoclose-true"
                      size="sm"
                      variant="light"
                      className="bg-white text-secondary px-3 border shadow-sm rounded"
                    >
                      <strong>{activeStatusFilter}</strong>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          setActiveStatusFilter("Semua");
                          // handleSelectStatus(1);
                        }}
                      >
                        Semua
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          setActiveStatusFilter("Kirim Barang");
                          // handleSelectStatus(2);
                        }}
                      >
                        Kirim Barang
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          setActiveStatusFilter("Sewa Kendaraan");
                          // handleSelectStatus(3);
                        }}
                      >
                        Sewa Kendaraan
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              )}
              <Col xs={8}>
                <InputGroup className="border rounded-card w-100 flex-nowrap">
                  <FloatingLabel
                    className=""
                    label={"Cari " + tab}
                    style={{ fontSize: "12px", height: "50px" }}
                  >
                    <Form.Control
                      className="border-0 rounded-card"
                      type="text"
                      style={{ height: "50px" }}
                      placeholder={"Cari " + tab}
                      onChange={(event) => handleSearch(event)}
                    />
                  </FloatingLabel>
                  <InputGroup.Text
                    className="border-0 search-icon-trx rounded-card ms-auto"
                    style={{ height: "50px" }}
                  >
                    <FaSearch />
                  </InputGroup.Text>
                </InputGroup>
              </Col>
              {/* </Row> */}
            </Row>
          </div>
        </div>
      )}
      {tab === "rute anda" && <Rute search={debounceSearch} />}
      {tab === "alamat anda" && layanan === 1 && <AlamatAnda search={debounceSearch} />}
      {tab === "alamat pengiriman" && (<AlamatPengiriman search={debounceSearch} />)}
      
      {tab === "alamat anda" && layanan === 2 && <RentAlamatAnda search={debounceSearch} />}
    </div>
  );
};

export default RuteAlamat;
