import React from "react";
import {
  Modal,
  Form,
  InputGroup,
  FloatingLabel,
  Button,
  Row,
  Col,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import util from "../../helper/util";

// icons
import { MdChevronRight, MdClose } from "react-icons/md";

const EnsureWithdraw = ({ show, closeModal, setShowModal, amountDisburse }) => {
  const bankAccounts = useSelector((st) => st.balanceReducer.bankAccounts);
  const selectedBankAccount = useSelector(
    (st) => st.balanceReducer.selectedBankAccount
  );

  const getMainAccounts = (accounts) => {
    if (selectedBankAccount.id) {
      return selectedBankAccount;
    }
    return accounts?.find((account) => account.is_main > 0);
  };

  return (
    <Modal
      contentClassName="rounded-card p-2"
      show={show}
      onHide={closeModal}
      className="p-3"
      centered
    >
      <div className="d-flex p-2">
        <p className="fw-bold">Penarikan Dana</p>
        <MdClose className="ms-auto me-2 pointer" onClick={closeModal} />
      </div>
      <Modal.Body>
        <Row className="">
          {/* <Col xs={1}></Col> */}
          <Col className="">
            <h5 className="fw-bold">Penarikan ke Rekening</h5>
            <h6 className="">
              Anda melakukan penarikan saldo sebesar
              <span className="fw-bold">
                {" "}
                Rp{util.thousandSeparatorPure(amountDisburse)}
              </span>
            </h6>
          </Col>
        </Row>
        <div className=" p-2">
          <Col>
            {getMainAccounts(bankAccounts?.data)?.bank}

            <h6 className="text-muted">
              {getMainAccounts(bankAccounts?.data)?.account_number}
            </h6>
            <h6 className="text-muted">
              a/n {getMainAccounts(bankAccounts?.data)?.account_name}
            </h6>
          </Col>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <p
          className="text-primary pointer me-4"
          onClick={() => setShowModal({ withdraw: true })}
        >
          Batalkan
        </p>
        <Button
          size="sm"
          variant="primary"
          className="float-end rounded-pill px-4 py-2"
          onClick={() => {
            setShowModal({ password: true });
          }}
        >
          Ya, Benar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EnsureWithdraw;
