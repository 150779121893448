import React from "react";
import { Col, Row } from "react-bootstrap";
import DokumenCard from "./component/DokumenCard";

const DokumenKontrak = (props) => {
  const { document, setDocument, handleDeleteDocument } = props;

  const updateDocument = (value) => {
    let newArr = [...document];
    newArr[value.index] = value.value;
    setDocument(newArr);
  };

  const addDocument = () => {
    setDocument([
      ...document,
      {
        id: document.length + 1,
        document_path: "",
        document_file_name: "",
        document_name: "",
        is_must_filled_by_partner: false,
      },
    ]);
  };

  const deleteDocument = (item) => {
    // real id length > 10
    if (item.id.length > 10) {
      handleDeleteDocument(item.id);
    }
    setDocument((old) => [...old.filter((value) => value.id !== item.id)]);
  };

  return (
    <>
      <Row className="mt-4 procurement-content mb-4">
        <Col xs={12} className="mt-2">
          <h5 className="fw-normal mb-0">Dokumen Kontrak</h5>
          <Row>
            {document.length > 0
              ? document.map((item, index) => (
                  <Col xs={12} md={6} className="mt-2" key={item.id}>
                    <DokumenCard
                      {...{
                        item,
                        index,
                        updateDocument,
                        addDocument,
                        deleteDocument,
                      }}
                      total={document.length}
                    />
                  </Col>
                ))
              : null}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default DokumenKontrak;
