import React, { useState, useEffect } from "react";
import {
  Modal,
  FormGroup,
  Form,
  Button,
  Card,
  Row,
  Col,
} from "react-bootstrap";
import { Calendar } from "react-modern-calendar-datepicker";
import { TimePicker } from "antd";
import moment from "moment";
import "moment/locale/id";
import { connect } from "react-redux";
import { message } from "antd";
import useBreakpoint from "../responsiveUtils/useBreakpoint";

// icons
import { MdEditCalendar } from "react-icons/md";

moment.locale("id");

function DateRangePicker(props) {
  const [labelValue, setLabelValue] = useState(
    props.sewaKendaraanReducer.endDate
      ? moment(props.sewaKendaraanReducer.endDate).format("dddd") +
          ", " +
          moment(props.sewaKendaraanReducer.endDate).format("DD/MMM/YYYY")
      : null
  );
  const [show, setShow] = useState(false);
  const [periodType, setPeriodType] = useState(null);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const defaultStartDate = {
    year: parseInt(moment().format("YYYY")),
    month: parseInt(moment().format("MM")),
    day: parseInt(moment().format("DD")),
  };

  const [startDateDefault, setStartDateDefault] = useState(
    props.sewaKendaraanReducer.startDate
      ? {
          year: parseInt(
            moment(props.sewaKendaraanReducer.startDate).format("YYYY")
          ),
          month: parseInt(
            moment(props.sewaKendaraanReducer.startDate).format("MM")
          ),
          day: parseInt(
            moment(props.sewaKendaraanReducer.startDate).format("DD")
          ),
        }
      : null
  );

  const [currentValue, setCurrentValue] = useState({
    from: props.sewaKendaraanReducer.startDate
      ? {
          year: parseInt(
            moment(props.sewaKendaraanReducer.startDate).format("YYYY")
          ),
          month: parseInt(
            moment(props.sewaKendaraanReducer.startDate).format("MM")
          ),
          day: parseInt(
            moment(props.sewaKendaraanReducer.startDate).format("DD")
          ),
        }
      : null,
    to: props.sewaKendaraanReducer.endDate
      ? {
          year: parseInt(
            moment(props.sewaKendaraanReducer.endDate).format("YYYY")
          ),
          month: parseInt(
            moment(props.sewaKendaraanReducer.endDate).format("MM")
          ),
          day: parseInt(
            moment(props.sewaKendaraanReducer.endDate).format("DD")
          ),
        }
      : null,
  });

  const totalDate = () => {
    var startDate = moment(props.sewaKendaraanReducer.startDate);
    var endDate = moment(props.sewaKendaraanReducer.endDate);
    var diff = moment.duration(endDate.diff(startDate));
    var total =
      Math.floor(diff.asWeeks()) + " Minggu, " + (diff.days() % 7) + " Hari.";

    return total;
  };

  const [selectedDayRange, setSelectedDayRange] = useState(currentValue);

  const currentTime = moment().format("HH:mm");
  const [selectedTime, setSeletedTime] = useState(currentTime);

  const handleCalender = (data) => {
    setSelectedDayRange({ ...currentValue, to: data.to });
    if (data.from && data.to) {
      const a = moment(
        `${data.from.year}-${(data.from.month + "").padStart(2, "0")}-${(
          data.from.day + ""
        ).padStart(2, "0")}`,
        "YYYY-MM-DD"
      );
      const b = moment(
        `${data.to.year}-${(data.to.month + "").padStart(2, "0")}-${(
          data.to.day + ""
        ).padStart(2, "0")}`,
        "YYYY-MM-DD"
      );
      const days = b.diff(a, "days");
      let newPeriodType = null;
      if (days < 7) {
        newPeriodType = "DAILY";
      } else {
        if (days < 30) {
          newPeriodType = "WEEKLY";
        } else {
          newPeriodType = "MONTHLY";
        }
      }
      setPeriodType(newPeriodType);
    }
  };

  const confirmationButton = () => {
    if (selectedDayRange.from && selectedDayRange.to) {
      let value = { periodType };
      // value.startDate = moment(
      //   `${selectedDayRange.from.year}-${(
      //     selectedDayRange.from.month + ""
      //   ).padStart(2, "0")}-${(selectedDayRange.from.day + "").padStart(
      //     2,
      //     "0"
      //   )} ${selectedTime}:00`,
      //   "YYYY-MM-DDThh:mm:ssTZD"
      // );
      value.startDate = props.sewaKendaraanReducer.startDate;
      value.endDate = moment(
        `${selectedDayRange.to.year}-${(
          selectedDayRange.to.month + ""
        ).padStart(2, "0")}-${(selectedDayRange.to.day + "").padStart(
          2,
          "0"
        )}  ${selectedTime}:00`,
        "YYYY-MM-DDThh:mm:ssTZD"
      );
      if (props.updateProps) {
        props.updateProps(value);
      }
      handleClose();
    } else {
      alert("Silakan pilih range tanggal.");
    }
  };

  useEffect(() => {
    setLabelValue(
      props.sewaKendaraanReducer.endDate
        ? moment(props.sewaKendaraanReducer.endDate).format("dddd") +
            ", " +
            moment(props.sewaKendaraanReducer.endDate).format("DD/MMM/YYYY")
        : null
    );
    setStartDateDefault(
      props.sewaKendaraanReducer.startDate
        ? {
            year: parseInt(
              moment(props.sewaKendaraanReducer.startDate).format("YYYY")
            ),
            month: parseInt(
              moment(props.sewaKendaraanReducer.startDate).format("MM")
            ),
            day: parseInt(
              moment(props.sewaKendaraanReducer.startDate).format("DD")
            ),
          }
        : null
    );
    setCurrentValue({
      from: props.sewaKendaraanReducer.startDate
        ? {
            year: parseInt(
              moment(props.sewaKendaraanReducer.startDate).format("YYYY")
            ),
            month: parseInt(
              moment(props.sewaKendaraanReducer.startDate).format("MM")
            ),
            day: parseInt(
              moment(props.sewaKendaraanReducer.startDate).format("DD")
            ),
          }
        : null,
      to: props.sewaKendaraanReducer.endDate
        ? {
            year: parseInt(
              moment(props.sewaKendaraanReducer.endDate).format("YYYY")
            ),
            month: parseInt(
              moment(props.sewaKendaraanReducer.endDate).format("MM")
            ),
            day: parseInt(
              moment(props.sewaKendaraanReducer.endDate).format("DD")
            ),
          }
        : null,
    });
    if (
      props.sewaKendaraanReducer.startDate !== selectedDayRange.from ||
      props.sewaKendaraanReducer.endDate !== selectedDayRange.to
    ) {
      setSelectedDayRange({
        from: props.sewaKendaraanReducer.startDate
          ? {
              year: parseInt(
                moment(props.sewaKendaraanReducer.startDate).format("YYYY")
              ),
              month: parseInt(
                moment(props.sewaKendaraanReducer.startDate).format("MM")
              ),
              day: parseInt(
                moment(props.sewaKendaraanReducer.startDate).format("DD")
              ),
            }
          : null,
        to: props.sewaKendaraanReducer.endDate
          ? {
              year: parseInt(
                moment(props.sewaKendaraanReducer.endDate).format("YYYY")
              ),
              month: parseInt(
                moment(props.sewaKendaraanReducer.endDate).format("MM")
              ),
              day: parseInt(
                moment(props.sewaKendaraanReducer.endDate).format("DD")
              ),
            }
          : null,
      });
    }
  }, [props.sewaKendaraanReducer]);

  const { isMobile } = useBreakpoint();

  return (
    <>
      <FormGroup className="form-group" onClick={handleShow}>
        {labelValue === null ? (
          <Form.Control
            type="text"
            placeholder={props.placeholder}
            className="form-white"
            readOnly
            value={labelValue}
          />
        ) : (
          <div className="filled d-flex">
            <div className="w-100 me-3">
              <p className="fw-medium">{labelValue}</p>
              <h6 className="text-muted">
                Waktu Kembali
                <b className="ms-1">
                  {moment(props.sewaKendaraanReducer.endDate).format("HH:mm")}
                </b>
              </h6>
              <hr className="my-1 text-muted" />
              <h6 className="text-muted">
                Durasi Sewa: <b>{totalDate()}</b>
              </h6>
            </div>
            <div className="opacity-50 mt-3">
              <MdEditCalendar />
            </div>
          </div>
        )}
      </FormGroup>

      <Modal
        contentClassName={`${!isMobile && "modal-80w"} p-1`}
        show={show}
        className=""
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton className="bg-white">
          <strong>Waktu dan Tanggal Pengembalian</strong>
        </Modal.Header>
        <Card.Body>
          {/* <div className="gr-btn-select d-grid d-flex">
            <Button
              variant="light"
              className={
                periodType === "DAILY"
                  ? "br-color active w-50"
                  : "br-color w-50"
              }
              style={{ width: "33%" }}
            >
              <p className="fw-bold mb-0">Harian</p>
            </Button>
            <Button
              variant="light"
              className={
                periodType === "WEEKLY"
                  ? "br-color active w-50"
                  : "br-color w-50"
              }
              style={{ width: "33%" }}
            >
              <p className="fw-bold mb-0">Mingguan</p>
            </Button>
            <Button
              variant="light"
              className={
                periodType === "MONTHLY"
                  ? "br-color active w-50"
                  : "br-color w-50"
              }
              style={{ width: "33%" }}
            >
              <p className="fw-bold mb-0">Bulanan</p>
            </Button>
          </div> */}
          <Calendar
            value={selectedDayRange}
            onChange={(data) => {
              setSeletedTime(moment().format("HH:mm"));
              handleCalender(data);
            }}
            minimumDate={startDateDefault || defaultStartDate}
            colorPrimary="#27C0CD"
            colorPrimaryLight="#F3F8F8"
            shouldHighlightWeekends
          />
          <Row>
            <Col xs={8} className="align-self-center text-end">
              <span className="h5">Pilih Jam Pengembalian:</span>
            </Col>
            <Col xs={4} className="align-self-center">
              <TimePicker
                allowClear={false}
                minuteStep={10}
                defaultValue={moment(currentTime, "HH:mm")}
                value={selectedTime ? moment(selectedTime, "HH:mm") : null}
                onChange={(time) => {
                  if (selectedDayRange.to) {
                    const endDateTarget = moment(
                      `${selectedDayRange.to.year}-${(
                        selectedDayRange.to.month + ""
                      ).padStart(2, "0")}-${(
                        selectedDayRange.to.day + ""
                      ).padStart(2, "0")} ${moment().format("HH:mm")}:00`,
                      "YYYY-MM-DD HH:mm::ss"
                    ).format("YYYY-MM-DD");
                    if (endDateTarget === moment().format("YYYY-MM-DD")) {
                      if (
                        moment(time, "HH:mm").diff(
                          moment(new Date(), "HH:mm")
                        ) > 1
                      ) {
                        setSeletedTime(moment(time, "HH:mm").format("HH:mm"));
                      } else {
                        message.error("Jam Pengembalian tidak valid!");
                        setSeletedTime(moment().format("HH:mm"));
                      }
                    } else {
                      setSeletedTime(moment(time, "HH:mm").format("HH:mm"));
                    }
                  } else {
                    message.error("Silakan pilih tanggal!");
                    setSeletedTime(moment().format("HH:mm"));
                  }
                }}
                format="HH:mm"
              />
            </Col>
          </Row>
        </Card.Body>
        <Modal.Footer className="border-top-0 bg-ice">
          <Button
            size="sm"
            variant="light"
            className="fw-bold me-4"
            onClick={() => handleClose()}
          >
            Batal
          </Button>
          <Button
            size="sm"
            variant="primary"
            className="fw-bold rounded-pill px-3"
            onClick={() => confirmationButton()}
          >
            Tambah
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => ({
  map: state.map,
  address: state.map.address,
  sewaKendaraanReducer: state.sewaKendaraanReducer,
});
export default connect(mapStateToProps)(DateRangePicker);
