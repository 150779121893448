import { Modal, Button, Form } from "react-bootstrap";
import { useState } from "react";
import { MdClose } from "react-icons/md";

const AjukanPerubahan = (props) => {
  /*======================== Hooks ======================== */

  /*======================== Props ======================== */

  const { isOpen, data, handleClose, handleSendNegotiatioOffer } = props;

  /*======================== UseState ======================== */

  const [description, setDescription] = useState("");

  /*======================== Handler ======================== */
  const handleOnClose = () => {
    handleClose();
  };

  /*======================== UseEffect ======================== */

  /*======================== Return ======================== */

  return (
    <Modal
      contentClassName="rounded-card p-2"
      show={isOpen}
      onHide={handleOnClose}
      className="p-3"
      size="md"
      centered
    >
      <div className="d-flex p-2">
        <h2 className="fw-nornal">Ajukan Perubahan</h2>
        <MdClose className="ms-auto me-2 pointer" onClick={handleOnClose} />
      </div>
      <p className="px-2 pb-2 text-muted border-bottom">
        Silahkan isi deskripsi apa yang diubah oleh partner
      </p>
      <Modal.Body>
        <div className="px-2">
          <p className="fw-bold mb-0">Deskripsi Perubahan</p>
          <Form.Control
            as="textarea"
            onChange={(event) => {
              setDescription(event.target.value);
            }}
            style={{ height: "120px" }}
          ></Form.Control>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size="sm"
          className="rounded-10 text-capitalize w-25 float-end"
          onClick={() => handleSendNegotiatioOffer({description})}
        >
          Kirim Obrolan
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AjukanPerubahan;
