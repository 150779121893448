import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Dropdown, Button, Tabs, Tab } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import noData from "../../assets/images/no-data.png";
import { useHistory, useLocation } from "react-router-dom";
import { Card, Spin } from "antd";
import KendaraanList from "./KendaraanList/KendaraanList";
import { getProcurementList } from "../../redux/action/Management/procurement_action";
import DeleteProcurement from "./Form/Modal/Delete";
import SelectDaterange from "../../components/input/daterange2";
import axios from 'axios'
import { storage } from "../../redux/local_storage";
import moment from "moment";

const InProcurement = (props) => {
  /*======================== Props ======================== */

  const { search, status } = props;
  const [data, setData] = useState([])
  const [areaFilterOptions, setAreaFilterOptions] = useState([])
  const [totalData, setTotalData] = useState(0)

  /*======================== Hooks ======================== */

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  /*======================== Redux ======================== */

  const filter = useSelector(
    (st) => st.procurementReducer.in_procurement.filter
  );
  const meta = useSelector((st) => st.procurementReducer.in_procurement.meta);
  // const data = useSelector((st) => st.procurementReducer.in_procurement.data);

  async function fetchData({ limit = 10, page = 1, sort_by, order_by, areaIDs = [], areaID, unit_type, filter_start_date, filter_end_date }) {
    const baseURL = process.env.REACT_APP_API_END_POINT
    const store = storage.get(process.env.REACT_APP_LOCAL_STORAGE_NAME);
    if (store) {
      const config = {
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
          Authorization: `Bearer ${store.access_token}`,
        },
      };

      let url = `/customer/vehicle?page=${page}`
      if (limit) {
        url += `&limit=${limit}`
      }
      if (!["", undefined, null].includes(search)) {
        url += `&search=${search}`
      }
      if (areaID) {
        // areaIDs.forEach(x => {
        url += `&area_id[]=${areaID}`
        // })
      }
      if (filter_start_date) {
        url += `&filter_start_date=${filter_start_date}`
      }
      if (filter_end_date) {
        url += `&filter_end_date=${filter_end_date}`
      }
      url += `&sort_by=${sort_by}`
      url += `&order_by=${order_by}`
      url += `&unit_type=${status}`

      try {
        const { data } = await axios.get(baseURL + url, config);
        if (data?.data?.length > 0) {
          setData(data.data)
          setTotalData(data?.total_data)
        }
      } catch (e) {
        console.log(e)
      }
    }
  }

  async function fetchAreaRegency() {
    const baseURL = process.env.REACT_APP_API_END_POINT
    const store = storage.get(process.env.REACT_APP_LOCAL_STORAGE_NAME);
    if (store) {
      const config = {
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
          Authorization: `Bearer ${store.access_token}`,
        },
      };

      let url = `/master/area/regency?limit=1000`

      try {
        const { data } = await axios.get(baseURL + url, config);
        if (data?.data?.length > 0) {
          setAreaFilterOptions([{ id: 0, name: "Semua Lokasi" }, ...data.data])
        }
      } catch (e) {
        console.log(e)
      }
    }
  }

  const loading = useSelector((st) => st.procurementReducer.loading);

  /*======================== UseState ======================== */

  const [params, setParams] = useState({
    limit: 10,
    page: 1,
    search: search || undefined,
    sort_by: "created_at",
    order_by: "DESC",
    status: filter?.status || undefined,
    filter_start_date: undefined,
    filter_end_date: undefined,
    areaID: undefined
    // unit_type: undefined
  });
  const [sort, setSort] = useState("Terbaru");
  // const [areaID, setareaID] = useState(undefined);
  // const [vehicleFilter, setVehicleFilter] = useState("Semua Kendaraan");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  // const [status, setStatus] = useState(filter?.status || undefined);

  /*======================== Handler ======================== */

  const handleLoad = () => {
    setData([])
    if (search) {
      setPage(1);
      fetchData({ ...params, search, page: 1 })
    } else {
      fetchData({ ...params, search: undefined })
    }
  };

  const handleChange = (type, value) => {
    switch (type) {
      case "sort":
        setPage(1);
        setSort(value);
        setParams({
          ...params,
          sort_by: "created_at",
          order_by: value === "Terbaru" ? "DESC" : "ASC",
          page: 1,
        });
        break;
      case "page":
        setPage(value);
        setParams({ ...params, page: value });
        break;
      case "area_id":
        setParams({ ...params, areaID: value });
        break;
      case "limit":
        setPage(1);
        setLimit(value);
        setParams({ ...params, limit: value, page: 1 });
        break;

      // case "status":
      //   setPage(1);
      //   setStatus(value);
      //   setParams({ ...params, status: value, page: 1 });
      //   break;

      default:
        break;
    }
  };

  /*======================== UseEffect ======================== */

  useEffect(() => {
    handleLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, search]);

  useEffect(() => {
    fetchAreaRegency()
  }, [])

  // useEffect(() => {
  //   setParams({ ...params, unit_type })
  // }, [unit_type])

  /*======================== Others ======================== */

  // const statusOptions = [
  //   {
  //     title: "Semua Tipe",
  //     value: "",
  //   },
  //   {
  //     title: "Normal",
  //     value: "normal",
  //   },
  //   {
  //     title: "Ganti Tetap",
  //     value: "ganti",
  //   }
  // ];

  // const vehicleFilterOptions = [
  //   {
  //     title: "Semua Kendaraan",
  //   },
  // ];

  const sortOptions = [
    {
      title: "Terbaru",
    },
    {
      title: "Terlama",
    },
  ];

  function handleDateFilter(date) {
    const from = moment(`${date?.from?.year}-${date?.from?.month}-${date?.from?.day}`, "YYYY-MM-DD").format("YYYY-MM-DD")
    const to = moment(`${date?.to?.year}-${date?.to?.month}-${date?.to?.day}`, "YYYY-MM-DD").format("YYYY-MM-DD")

    setParams({ ...params, filter_start_date: from, filter_end_date: to })
  }

  /*======================== Return ======================== */

  return (
    <>
      <div className="mt-4">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h2 className="fw-bold text-capitalize">
              Daftar Kendaraan Sementara
            </h2>
            <p>
              List kendaraan anda yang sedang dilakukan pergantian sewa sementara.
            </p>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between mb-3">
          <div className="d-flex">
            <h4 className="mt-2 me-2" style={{ fontWeight: "600" }}>
              Tampilkan:
            </h4>
            {/* Location filter */}
            <Dropdown className="d-inline mx-2">
              <Dropdown.Toggle
                id="dropdown-autoclose-true"
                size="sm"
                variant="light"
                className="bg-white px-3 border shadow-sm"
              >
                <strong>{params.areaID == undefined ? "Semua Lokasi" : `Lokasi: ` + areaFilterOptions.find(x => x.id == params.areaID)?.name}</strong>
              </Dropdown.Toggle>

              <Dropdown.Menu className="dropdown-menu" style={{ height: '200px', overflow: "scroll" }}>
                {areaFilterOptions.map((item, index) => (
                  <Dropdown.Item
                    key={index}
                    href="#"
                    onClick={() => {
                      handleChange("area_id", item.id);
                    }}
                  >
                    {item.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            {/* Vehicle filter */}
            {/* <Dropdown className="d-inline mx-2">
              <Dropdown.Toggle
                id="dropdown-autoclose-true"
                size="sm"
                variant="light"
                className="bg-white px-3 border shadow-sm"
              >
                <strong>{vehicleFilter}</strong>
              </Dropdown.Toggle>

              <Dropdown.Menu className="dropdown-menu">
                {vehicleFilterOptions.map((item, index) => (
                  <Dropdown.Item
                    key={index}
                    href="#"
                    onClick={() => {
                      handleChange("sort", item.title);
                    }}
                  >
                    {item.title}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown> */}
            {/* Rentang sewa */}
            <SelectDaterange
              placeholder="Rentang Sewa"
              dateFilter={props.dateFilter}
              handleDateFilter={handleDateFilter}
            />
          </div>
          {/* <div className="d-flex" style={{ gap: "1rem" }}>
            <span className="fw-bold" style={{ width: "auto" }}>
              Tipe Sewa:
            </span>
            <Tabs
              defaultActiveKey={status}
              className="tabs-rute mb-2 border-0"
              style={{ gap: "10px" }}
              onSelect={(value) => handleChange("status", value)}
            >
              {statusOptions.map((item, index) => (
                <Tab key={index} eventKey={item.value} title={item.title} />
              ))}
            </Tabs>
          </div> */}
          <div className="d-flex">
            <h4 className="mt-2 me-2" style={{ fontWeight: "600" }}>
              Urutkan:
            </h4>
            <Dropdown className="d-inline mx-2">
              <Dropdown.Toggle
                id="dropdown-autoclose-true"
                size="sm"
                variant="light"
                className="bg-white text-secondary px-3 border shadow-sm rounded"
              >
                <strong>{sort}</strong>
              </Dropdown.Toggle>

              <Dropdown.Menu className="dropdown-menu">
                {sortOptions.map((item, index) => (
                  <Dropdown.Item
                    key={index}
                    href="#"
                    onClick={() => {
                      handleChange("sort", item.title);
                    }}
                  >
                    {item.title}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
      {data.length > 0 && (
        <KendaraanList
          is_procurement
          {...{
            handleChange,
            limit,
            page,
            loading,
            data,
            handleLoad,
          }}
          total_data={totalData ?? 0}
        />
      )}
      {data.length < 1 &&
        (!loading ? (
          <div
            className="d-flex flex-column align-items-center justify-content-center"
            style={{ marginTop: "5rem" }}
          >
            <img src={noData} alt="no-data" />
            <p style={{ fontSize: '25px' }}>Anda belum memiliki kendaraan yang pengganti</p>
          </div>
        ) : (
          <Card
            className="p-4 rounded-card border-0 d-flex align-items-center justify-content-center mt-4"
            style={{
              height: "350px",
            }}
          >
            <Spin />
          </Card>
        ))}
    </>
  );
};

InProcurement.propTypes = {
  search: PropTypes.string,
};

export default InProcurement;
