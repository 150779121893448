import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Modal,
  Form,
  InputGroup,
  FloatingLabel,
  Button,
  Row,
  Col,
} from "react-bootstrap";
import util from "../../helper/util";
import { getRekeningList } from "../../redux/action/balance_action";
import useBreakpoint from "../responsiveUtils/useBreakpoint";

//img
import info_withdraw from "../../assets/images/info_withdraw.svg";
import info_withdraw_mobile from "../../assets/images/info_withdraw_mobile.svg";
import wallet_green from "../../assets/images/wallet_green.svg";
import icon_bank_grey_circle from "../../assets/images/icon_bank_grey_circle.svg";

// icons
import { MdChevronRight, MdClose } from "react-icons/md";

const WithdrawModal = ({
  show,
  closeModal,
  setShowModal,
  setAmountDisburse,
  amountDisburse,
}) => {
  const { isMobile } = useBreakpoint();
  const dispatch = useDispatch();
  const auth = useSelector((st) => st.authReducer);
  const bankAccounts = useSelector((st) => st.balanceReducer.bankAccounts);
  const selectedBankAccount = useSelector(
    (st) => st.balanceReducer.selectedBankAccount
  );

  const getMainAccounts = () => {
    if (selectedBankAccount.id) {
      return selectedBankAccount;
    }
    return bankAccounts?.data?.find((account) => account.is_main > 0);
  };

  return (
    <Modal
      contentClassName="rounded-card p-2"
      show={show}
      onHide={closeModal}
      className="p-3"
      centered
    >
      <div className="d-flex p-2">
        <p className="fw-bold">Penarikan Dana</p>
        <MdClose className="ms-auto me-2 pointer" onClick={closeModal} />
      </div>
      <Modal.Body>
        <div className="border rounded-card p-3">
          <Row>
            <Col xs={3} md={2}>
              <img src={wallet_green} alt="" />
            </Col>
            <Col className="">
              <h6 className="">Saldo eLOG yang tersedia</h6>
              <h5 className="">
                Rp {util.thousandSeparatorPure(auth.balance)}
              </h5>
            </Col>
          </Row>
          <Row className=" mt-4">
            <Col xs={3} md={2}>
              <img src={icon_bank_grey_circle} alt="" />
            </Col>
            <Col className="">
              <h6 className="">Rekening Anda</h6>
              <h5 className="fw-bold">{getMainAccounts()?.bank}</h5>
              <h6 className="text-muted">
                {getMainAccounts()?.account_number}
              </h6>
              <h6 className="text-muted">
                a/n {getMainAccounts()?.account_name}
              </h6>
            </Col>
            <Col xs={1} className="d-flex align-items-center me-4">
              <h5
                className="text-primary pointer"
                onClick={() => setShowModal({ pickBank: true })}
              >
                Ubah
              </h5>
            </Col>
          </Row>
        </div>
        <div className="mt-3">
          <h5>
            {" "}
            Jumlah Saldo yang ingin anda ambil.
            <span
              className="float-end text-primary pointer me-2"
              onClick={() => setAmountDisburse(auth?.balance)}
            >
              Tarik Semua
            </span>
          </h5>
          <InputGroup
            className="border rounded-input"
            style={{ width: "100%" }}
          >
            <InputGroup.Text
              className="border-0 rounded-input"
              style={{ backgroundColor: "#f8f9fa" }}
            >
              <span className="text-secondary fw-bold">Rp</span>
            </InputGroup.Text>
            <FloatingLabel
              className="rounded-input"
              label="Jumlah Saldo"
              style={{ fontSize: "12px", width: isMobile ? "80%" : "90%" }}
            >
              <Form.Control
                type="number"
                className="border-0 rounded-input"
                placeholder="m"
                onChange={(ev) => {
                  setAmountDisburse(ev.target.value);
                }}
                value={amountDisburse}
                // defaultValue={auth?.name}
                style={{ height: "50px" }}
              ></Form.Control>
            </FloatingLabel>
          </InputGroup>
        </div>
        <img
          src={isMobile ? info_withdraw_mobile : info_withdraw}
          alt=""
          className="w-100 mt-2"
        />
      </Modal.Body>
      <Modal.Footer>
        <p className="text-primary pointer me-4" onClick={closeModal}>
          Batalkan
        </p>
        <Button
          disabled={amountDisburse ? false : true}
          size="sm"
          variant="primary"
          className="float-end rounded-pill px-4 py-2"
          onClick={() => {
            setShowModal({ ensure: true });
          }}
        >
          Lanjutkan
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WithdrawModal;
