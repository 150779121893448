import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  InputGroup,
  FloatingLabel,
  Button,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { resetAuth, setAuth } from "../../redux/action/auth_action";
import ProfileApi from "../../api/profile-api";
import AccountApi from "../../api/auth-api";
import { message } from "antd";

import SectionSuccess from "./SectionSuccess";

// icons
import { MdChevronRight, MdClose } from "react-icons/md";
import { BsFillCheckCircleFill } from "react-icons/bs";
import icon_gender from "../../assets/images/icon_gender.svg";
import man_illustration from "../../assets/images/man_illustration.svg";
import woman_illustration from "../../assets/images/woman_illustration.svg";

const Gender = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const auth = useSelector((st) => st.authReducer);
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState({
    MALE: false,
    FEMALE: false,
  });

  const openModal = () => {
    setShow(true);
  };

  const closeModal = () => {
    setShow(false);
  };

  useEffect(() => {
    if (auth.gender === "MALE") {
      setSelected({ MALE: true, FEMALE: false });
    } else if (auth.gender === "FEMALE") {
      setSelected({ MALE: false, FEMALE: true });
    }
  }, []);

  const handleSubmit = async () => {
    try {
      const accountApi = new AccountApi();
      const profileApi = new ProfileApi();

      let body = {};
      if (selected.MALE) {
        body["gender"] = "MALE";
      }
      if (selected.FEMALE) {
        body["gender"] = "FEMALE";
      }
      const result = await accountApi.checkToken(auth.refresh_token);

      if (result.status === "success") {
        const profileRes = await profileApi.updateGender(
          { ...body },
          auth.access_token
        );

        if (profileRes.status === "success") {
          message.success(profileRes.message);
          const { data } = await accountApi.getDetailCustomer(
            auth.access_token
          );

          dispatch(setAuth({ ...auth, ...data }));

          // setIsSubmit(true);
          closeModal();
        } else {
          closeModal();
          message.error(profileRes.message);
        }
      } else {
        history.push("/login");
        dispatch(resetAuth());
      }
    } catch (error) {
      console.log(error.response);
    }
  };

  return (
    <>
      <div
        className="d-flex align-items-center pointer  px-2"
        onClick={openModal}
      >
        <img src={icon_gender} width={25} height={25} alt="" />

        <div className="ms-3">
          <h6 className="text-muted ">Jenis Kelamin</h6>
          <h5>
            {auth?.gender === "MALE"
              ? "Pria"
              : auth?.gender === "FEMALE"
              ? "Wanita"
              : "Belum Memasukkan Data"}
          </h5>
        </div>
        <div
          className="d-flex ms-auto align-items-center"
          style={{ marginTop: "-10px" }}
        >
          <MdChevronRight className="" />
        </div>
      </div>
      <Modal
        contentClassName="rounded-card p-2"
        show={show}
        onHide={closeModal}
        className="p-3"
        centered
      >
        <div className="d-flex p-2">
          <p className="fw-bold">Jenis Kelamin</p>
          <MdClose className="ms-auto me-2 pointer" onClick={closeModal} />
        </div>
        <Modal.Body>
          <div className="">
            <div className="d-flex justify-content-around">
              <div className="">
                <img
                  src={man_illustration}
                  alt=""
                  className={`rounded-circle border pointer border-2 ${
                    selected.MALE && "border-success"
                  }`}
                  onClick={() => setSelected({ MALE: true, FEMALE: false })}
                />
                <div className="d-flex justify-content-center mt-2">
                  <BsFillCheckCircleFill
                    className={`me-2 ${
                      selected.MALE ? "text-success" : "text-muted"
                    } `}
                  />
                  <p>Pria</p>
                </div>
              </div>
              <div className="">
                <img
                  src={woman_illustration}
                  alt=""
                  className={`rounded-circle border pointer border-2 ${
                    selected.FEMALE && "border-success"
                  }`}
                  onClick={() => setSelected({ MALE: false, FEMALE: true })}
                />
                <div className="d-flex justify-content-center mt-2">
                  <BsFillCheckCircleFill
                    className={`me-2 ${
                      selected.FEMALE ? "text-success" : "text-muted"
                    } `}
                  />
                  <p>Wanita</p>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <p className="text-primary pointer me-4" onClick={closeModal}>
            Batalkan
          </p>
          <Button
            size="sm"
            variant="primary"
            className="float-end rounded-pill px-4 py-2"
            onClick={() => {
              handleSubmit();
            }}
          >
            Simpan
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Gender;
