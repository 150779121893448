import React, { Component, Suspense, lazy } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { connect } from "react-redux";

// layout
import ScrollTop from "./ScrollTop";
import { LayoutRoute, MainLayout } from "../components/layouts";

// containers
import Dashboard from "../pages/Dashboard";
import Balance from "../pages/Pengaturan/Balance";
import KirimBarang from "../pages/KirimBarangV2";
import SewaKendaraan from "../pages/SewaKendaraan";
import DaftarTransaksi from "../pages/DaftarTransaksi";
import Login from "../pages/Auth/Login";
import Otp from "../pages/Auth/Otp";
import ForgotPassword from "../pages/Auth/ForgotPassword";
import DetailTransaksiSewa from "../pages/DetailTransaksi/RentPage";
import DetailTransaksiKirim from "../pages/DetailTransaksi/SendPage";
import IntegrasiApi from "../pages/IntegrasiApi";
import UserManagement from "../pages/UserManagement";
import RuteAlamat from "../pages/Pengaturan/RuteAlamat";

import PengaturanProfile from "../pages/Pengaturan/Profile";
import Notifikasi from "../pages/Pengaturan/Notification";
import BankAccount from "../pages/Pengaturan/BankAccount";
import NotFound from "../pages/404";
import RuteForm from "../pages/Pengaturan/RuteAlamat/Form/RuteForm";
import LengkapiRuteForm from "../pages/Pengaturan/RuteAlamat/Form/LengkapiRuteForm";
import AlamatAndaForm from "../pages/Pengaturan/RuteAlamat/Form/AlamatAndaForm";
import AlamatAndaSewaForm from "../pages/Pengaturan/RuteAlamat/Rent/Form/AlamatAndaForm"
import LengkapiAlamatAndaForm from "../pages/Pengaturan/RuteAlamat/Form/LengkapiAlamatAndaForm";
import LengkapiAlamatAndaSewaForm from "../pages/Pengaturan/RuteAlamat/Rent/Form/LengkapiAlamatAndaForm";
import AlamatPengirimanForm from "../pages/Pengaturan/RuteAlamat/Form/AlamatPengirimanForm";
import LengkapiAlamatPengirimanForm from "../pages/Pengaturan/RuteAlamat/Form/LengkapiAlamatPengirimanForm";
import Penawaran from "../pages/Penawaran";

import PenawaranForm from "../pages/Penawaran/Form/Penawaran";
import DetailProcurement from "../pages/Penawaran/Detail";
import DetailRutePenawaran from "../pages/Penawaran/Detail/DetailRutePenawaran";
import Invoices from "../pages/Invoices";

import Partner from "../pages/Partner";
import PartnerProfile from "../pages/Partner/Profile";
import PartnerKendaraan from "../pages/Partner/Kendaraan";
import PartnerKendaraanDetail from "../pages/Partner/Kendaraan/Detail";
import PartnerPenawaran from "../pages/Partner/Penawaran";
import DetailPartnerPenawaran from "../pages/Partner/Penawaran/Detail";
import PartnerDetailPenawaran from "../pages/Partner/DetailPenawaran";
import DetailInvoice from "../pages/DetailInvoice";
import DetailPesanan from "../pages/DetailInvoice/detail_pesanan";
import DokumenPembayaran from "../pages/Finance/DocumentPayment";
import DetailDokumenPembayaran from "../pages/Finance/DocumentPayment/DetailDocumentPayment" 
// SSO
import LoginV1 from "../pages/Auth/v1/Login"
import Oauth from "../pages/Auth/Oauth";
// END - SSO

// Kendaraan
import Kendaraan from "../pages/Kendaraan";
import KendaraanForm from "../pages/Kendaraan/Form/Penawaran";
// import DetailProcurement from "../pages/Kendaraan/Detail";
import DetailRuteKendaraan from "../pages/Kendaraan/Detail/DetailRutePenawaran";


class AppConnect extends Component {
  render() {
    return (
      <div>
        <Router>
          <ScrollTop>
            <Switch>
              <LayoutRoute
                exact
                path={"/login"}
                layout={MainLayout}
                component={Login}
                withoutLogin
                metaTitle={"Masuk | eLOG"}
                metaDesc={
                  "Sudah memiliki akun ? Masuk untuk menikmati fitur dari eLOG."
                }
              />
              {/* SSO */}
              <LayoutRoute
                exact
                path={"/login/v1"}
                layout={MainLayout}
                component={LoginV1}
                withoutLogin
                metaTitle={"Masuk | eLOG"}
                metaDesc={
                  "Sudah memiliki akun ? Masuk untuk menikmati fitur dari eLOG."
                }
              />
              <LayoutRoute
                exact
                path={"/oauth/:token"}
                layout={MainLayout}
                component={Oauth}
                withoutLogin
                metaTitle={"Masuk | eLOG"}
                metaDesc={
                  "Sudah memiliki akun ? Masuk untuk menikmati fitur dari eLOG."
                }
              />
              {/* END - SSO */}
              <LayoutRoute
                exact
                path={"/otp"}
                layout={MainLayout}
                component={Otp}
                metaTitle={"Masuk | eLOG"}
                metaDesc={
                  "Sudah memiliki akun ? Masuk untuk menikmati fitur dari eLOG."
                }
              />
              <LayoutRoute
                exact
                path={"/lupa-password/:reset_token"}
                layout={MainLayout}
                component={ForgotPassword}
                metaTitle={"Masuk | eLOG"}
                metaDesc={
                  "Sudah memiliki akun ? Masuk untuk menikmati fitur dari eLOG."
                }
              />
              <LayoutRoute
                exact
                path={["/", "/customer/:refresh_token"]}
                layout={MainLayout}
                component={Dashboard}
                menuName={"Dashboard"}
                metaTitle={
                  "Kirim Barang, Sewa Kendaraan? Tinggal Pilih! | eLOG"
                }
                metaDesc={
                  "Solusi Kirim barang & Sewa Kendaraan yang menyesuaikan kebutuhan logistik Anda."
                }
              />
              <LayoutRoute
                exact
                path="/kirim-barang"
                layout={MainLayout}
                component={KirimBarang}
                menuName={"KirimBarang"}
                metaTitle={"Layanan Pengiriman Barang | eLOG"}
                metaDesc={"Kirim barangmu menjadi lebih mudah dan efisien."}
              />
              <LayoutRoute
                exact
                path="/sewa-kendaraan"
                layout={MainLayout}
                component={SewaKendaraan}
                menuName={"SewaKendaraan"}
                metaTitle={"Layanan Sewa Kendaraan | eLOG"}
                metaDesc={
                  "Solusi praktis Sewa Kendaraan untuk kebutuhan bisnismu."
                }
              />
              {/* <LayoutRoute
                exact
                path="/integrasi-api"
                layout={MainLayout}
                component={IntegrasiApi}
                menuName={"IntegrasiApi"}
                requireLogin
                metaTitle={"Integrasi API | eLOG"}
                metaDesc={"yuk join di Integrasi API"}
              /> */}
              <LayoutRoute
                exact
                path="/dokumen-pembayaran"
                layout={MainLayout}
                component={DokumenPembayaran}
                menuName={"Finance"}
                requireLogin
                metaTitle={"Finance: Dokumen & Pembayaran | eLOG"}
                metaDesc={
                  "Atur Rute anda untuk melengkapi data yang diperlukan."
                }
              />
              <LayoutRoute
                exact
                path="/dokumen-pembayaran/:id"
                layout={MainLayout}
                component={DetailDokumenPembayaran}
                menuName={"Finance"}
                requireLogin
                metaTitle={"Finance: Dokumen & Pembayaran | eLOG"}
                metaDesc={
                  "Atur Rute anda untuk melengkapi data yang diperlukan."
                }
              />
              <LayoutRoute
                exact
                path="/user-management"
                layout={MainLayout}
                component={UserManagement}
                menuName={"UserManagement"}
                requireLogin
                metaTitle={"User Management | eLOG"}
                metaDesc={"yuk join di Integrasi API"}
              />
              <LayoutRoute
                exact
                path="/daftar-transaksi"
                layout={MainLayout}
                component={DaftarTransaksi}
                menuName={"DaftarTransaksi"}
                requireLogin
                metaTitle={"Daftar Transaksi Anda | eLOG"}
                metaDesc={
                  "Cek transaksi apa saja yang sudah Anda lakukan di eLOG."
                }
              />
              <LayoutRoute
                exact
                path="/daftar-transaksi-sewa/:id"
                layout={MainLayout}
                component={DetailTransaksiSewa}
                menuName={"DetailTransaksi"}
                requireLogin
                metaTitle={"Daftar Transaksi Anda | eLOG"}
                metaDesc={
                  "Cek transaksi apa saja yang sudah Anda lakukan di eLOG."
                }
              />
              <LayoutRoute
                exact
                path="/daftar-transaksi-kirim/:id"
                layout={MainLayout}
                component={DetailTransaksiKirim}
                menuName={"DetailTransaksi"}
                requireLogin
                metaTitle={"Daftar Transaksi Anda | eLOG"}
                metaDesc={
                  "Cek transaksi apa saja yang sudah Anda lakukan di eLOG."
                }
              />
              <LayoutRoute
                exact
                path="/pengaturan/profile"
                layout={MainLayout}
                component={PengaturanProfile}
                menuName={"Pengaturan"}
                requireLogin
                metaTitle={"Atur Profil | eLOG"}
                metaDesc={
                  "Atur Profil Anda untuk melengkapi data yang diperlukan."
                }
              />
              <LayoutRoute
                exact
                path="/pengaturan/notifikasi"
                layout={MainLayout}
                component={Notifikasi}
                menuName={"Pengaturan"}
                requireLogin
                metaTitle={"Atur Profil | eLOG"}
                metaDesc={
                  "Atur Profil Anda untuk melengkapi data yang diperlukan."
                }
              />
              <LayoutRoute
                exact
                path="/pengaturan/saldo"
                layout={MainLayout}
                component={Balance}
                menuName={"Pengaturan"}
                requireLogin
                metaTitle={"Atur Profil | eLOG"}
                metaDesc={
                  "Atur Profil Anda untuk melengkapi data yang diperlukan."
                }
              />
              <LayoutRoute
                exact
                path="/pengaturan/rekening"
                layout={MainLayout}
                component={BankAccount}
                menuName={"Pengaturan"}
                requireLogin
                metaTitle={"Atur Profil | eLOG"}
                metaDesc={
                  "Atur Profil Anda untuk melengkapi data yang diperlukan."
                }
              />
              <LayoutRoute
                exact
                path="/pengaturan/rute-alamat"
                layout={MainLayout}
                component={RuteAlamat}
                menuName={"Pengaturan"}
                requireLogin
                metaTitle={"Atur Rute - Alamat | eLOG"}
                metaDesc={
                  "Atur Rute anda untuk melengkapi data yang diperlukan."
                }
              />
              <LayoutRoute
                exact
                path="/pengaturan/rute-alamat/tambah-rute"
                layout={MainLayout}
                component={RuteForm}
                menuName={"Pengaturan"}
                requireLogin
                metaTitle={"Atur Rute | eLOG"}
                metaDesc={
                  "Tambah rute anda untuk melengkapi data yang diperlukan."
                }
              />
              <LayoutRoute
                exact
                path="/pengaturan/rute-alamat/tambah-rute/:id"
                layout={MainLayout}
                component={RuteForm}
                menuName={"Pengaturan"}
                requireLogin
                metaTitle={"Atur Rute | eLOG"}
                metaDesc={
                  "Update rute anda untuk melengkapi data yang diperlukan."
                }
              />
              <LayoutRoute
                exact
                path="/pengaturan/rute-alamat/lengkapi-rute/:id"
                layout={MainLayout}
                component={LengkapiRuteForm}
                menuName={"Pengaturan"}
                requireLogin
                metaTitle={"Lengkapi Rute | eLOG"}
                metaDesc={
                  "Lengkapi data yang diperlukan."
                }
              />
              <LayoutRoute
                exact
                path="/pengaturan/rute-alamat/tambah-alamat-anda"
                layout={MainLayout}
                component={AlamatAndaForm}
                menuName={"Pengaturan"}
                requireLogin
                metaTitle={"Atur Alamat Anda | eLOG"}
                metaDesc={
                  "Tambah alamat anda untuk melengkapi data yang diperlukan."
                }
              />
              <LayoutRoute
                exact
                path="/pengaturan/rute-alamat/tambah-alamat-anda/:id"
                layout={MainLayout}
                component={AlamatAndaForm}
                menuName={"Pengaturan"}
                requireLogin
                metaTitle={"Atur Alamat Anda | eLOG"}
                metaDesc={
                  "Update alamat anda untuk melengkapi data yang diperlukan."
                }
              />
              <LayoutRoute
                exact
                path="/pengaturan/rute-alamat/tambah-alamat-anda-sewa"
                layout={MainLayout}
                component={AlamatAndaSewaForm}
                menuName={"Pengaturan"}
                requireLogin
                metaTitle={"Atur Alamat Anda | eLOG"}
                metaDesc={
                  "Tambah alamat anda untuk melengkapi data yang diperlukan."
                }
              />
              <LayoutRoute
                exact
                path="/pengaturan/rute-alamat/tambah-alamat-anda-sewa/:id"
                layout={MainLayout}
                component={AlamatAndaSewaForm}
                menuName={"Pengaturan"}
                requireLogin
                metaTitle={"Atur Alamat Anda | eLOG"}
                metaDesc={
                  "Update alamat anda untuk melengkapi data yang diperlukan."
                }
              />
              <LayoutRoute
                exact
                path="/pengaturan/rute-alamat/lengkapi-alamat-anda/:id"
                layout={MainLayout}
                component={LengkapiAlamatAndaForm}
                menuName={"Pengaturan"}
                requireLogin
                metaTitle={"Lengkapi Alamat Anda | eLOG"}
                metaDesc={
                  "Lengkapi data yang diperlukan."
                }
              />
              <LayoutRoute
                exact
                path="/pengaturan/rute-alamat/lengkapi-alamat-anda-sewa/:id"
                layout={MainLayout}
                component={LengkapiAlamatAndaSewaForm}
                menuName={"Pengaturan"}
                requireLogin
                metaTitle={"Lengkapi Alamat Anda | eLOG"}
                metaDesc={
                  "Lengkapi data yang diperlukan."
                }
              />
              <LayoutRoute
                exact
                path="/pengaturan/rute-alamat/tambah-alamat-pengiriman"
                layout={MainLayout}
                component={AlamatPengirimanForm}
                menuName={"Pengaturan"}
                requireLogin
                metaTitle={"Atur Alamat Pengiriman | eLOG"}
                metaDesc={
                  "Tambah alamat pengiriman untuk melengkapi data yang diperlukan."
                }
              />
              <LayoutRoute
                exact
                path="/pengaturan/rute-alamat/tambah-alamat-pengiriman/:id"
                layout={MainLayout}
                component={AlamatPengirimanForm}
                menuName={"Pengaturan"}
                requireLogin
                metaTitle={"Atur Alamat Pengiriman | eLOG"}
                metaDesc={
                  "Update alamat pengiriman untuk melengkapi data yang diperlukan."
                }
              />
              <LayoutRoute
                exact
                path="/pengaturan/rute-alamat/lengkapi-alamat-pengiriman/:id"
                layout={MainLayout}
                component={LengkapiAlamatPengirimanForm}
                menuName={"Pengaturan"}
                requireLogin
                metaTitle={"Lengkapi Alamat Pengiriman | eLOG"}
                metaDesc={
                  "Lengkapi data yang diperlukan."
                }
              />
              <LayoutRoute
                exact
                path="/penawaran"
                layout={MainLayout}
                component={Penawaran}
                menuName={"Management"}
                requireLogin
                metaTitle={"Daftar procurement anda | eLOG"}
                metaDesc={"Daftar procurement anda."}
              />

              <LayoutRoute
                path="/penawaran/tambah-penawaran"
                layout={MainLayout}
                component={PenawaranForm}
                menuName={"Penawaran"}
                requireLogin
                metaTitle={"Atur Alamat Anda | eLOG"}
                metaDesc={"Buat penawaran untuk kebutuhan jangka panjang anda."}
              />
              <LayoutRoute
                path="/penawaran/edit-penawaran/:id"
                layout={MainLayout}
                component={PenawaranForm}
                menuName={"Penawaran"}
                requireLogin
                metaTitle={"Atur Alamat Anda | eLOG"}
                metaDesc={"Buat penawaran untuk kebutuhan jangka panjang anda."}
              />
              <LayoutRoute
                exact
                path="/penawaran/detail/:id"
                layout={MainLayout}
                component={DetailProcurement}
                menuName={"Penawaran"}
                requireLogin
                metaTitle={"Atur Alamat Anda | eLOG"}
                metaDesc={"Buat penawaran untuk kebutuhan jangka panjang anda."}
              />
              <LayoutRoute
                exact
                path="/penawaran/detail/:id/rute/:idRute"
                layout={MainLayout}
                component={DetailRutePenawaran}
                menuName={"Penawaran"}
                requireLogin
                metaTitle={"Atur Alamat Anda | eLOG"}
                metaDesc={"Buat penawaran untuk kebutuhan jangka panjang anda."}
              />
              <LayoutRoute
                exact
                path="/partner"
                layout={MainLayout}
                component={Partner}
                menuName={"Partner"}
                requireLogin
                metaTitle={"Daftar partner anda | eLOG"}
                metaDesc={
                  "Daftar Partner Official yang anda pilih sebagai partner layanan."
                }
              />
              <LayoutRoute
                exact
                path="/partner/profile/:id"
                layout={MainLayout}
                component={PartnerProfile}
                menuName={"Partner"}
                requireLogin
                metaTitle={"Daftar partner anda | eLOG"}
                metaDesc={
                  "Daftar Partner Official yang anda pilih sebagai partner layanan."
                }
              />
              <LayoutRoute
                exact
                path="/partner/kendaraan/:id"
                layout={MainLayout}
                component={PartnerKendaraan}
                menuName={"Partner"}
                requireLogin
                metaTitle={"Daftar partner anda | eLOG"}
                metaDesc={
                  "Daftar Partner Official yang anda pilih sebagai partner layanan."
                }
              />
              <LayoutRoute
                exact
                path="/partner/kendaraan/:id/:vehicle"
                layout={MainLayout}
                component={PartnerKendaraanDetail}
                menuName={"Partner"}
                requireLogin
                metaTitle={"Daftar partner anda | eLOG"}
                metaDesc={
                  "Daftar Partner Official yang anda pilih sebagai partner layanan."
                }
              />
              <LayoutRoute
                exact
                path="/partner/penawaran/:id"
                layout={MainLayout}
                component={PartnerPenawaran}
                menuName={"Partner"}
                requireLogin
                metaTitle={"Daftar partner anda | eLOG"}
                metaDesc={
                  "List tawaran partner untuk penawaran-penawaran anda."
                }
              />
              <LayoutRoute
                exact
                path="/partner/penawaran/:id/:procurement"
                layout={MainLayout}
                component={DetailPartnerPenawaran}
                menuName={"Partner"}
                requireLogin
                metaTitle={"Daftar partner anda | eLOG"}
                metaDesc={
                  "List tawaran partner untuk penawaran-penawaran anda."
                }
              />
              <LayoutRoute
                exact
                path="/partner/penawaran/:id/:procurement/detail/:id_partner_offer"
                layout={MainLayout}
                component={PartnerDetailPenawaran}
                menuName={"Partner"}
                requireLogin
                metaTitle={"Daftar partner anda | eLOG"}
                metaDesc={
                  "List tawaran partner untuk penawaran-penawaran anda."
                }
              />
              {/* <LayoutRoute
                exact
                path="/invoices"
                layout={MainLayout}
                component={Invoices}
                menuName={"Invoices"}
                requireLogin
                metaTitle={"Invoices | eLOG"}
                metaDesc={"Invoices."}
              /> */}
              <LayoutRoute
                exact
                path="/detail-invoice/:id"
                layout={MainLayout}
                component={DetailInvoice}
                menuName={"Invoices"}
                requireLogin
                metaTitle={"Detail Invoice | eLOG"}
                metaDesc={"Invoices."}
              />
              <LayoutRoute
                exact
                path="/detail-pesanan/:slug"
                layout={MainLayout}
                component={DetailPesanan}
                menuName={"Invoices"}
                requireLogin
                metaTitle={"Detail Dokumen | eLOG"}
                metaDesc={"Invoices."}
              />
              {/* Kendaraan */}
              <LayoutRoute
                exact
                path="/kendaraan"
                layout={MainLayout}
                component={Kendaraan}
                menuName={"Management"}
                requireLogin
                metaTitle={"Daftar kendaraan anda | eLOG"}
                metaDesc={"Daftar kendaraan anda."}
              />
              {/* End of Kendaraan */}
              <LayoutRoute
                exact
                path="*"
                layout={MainLayout}
                component={NotFound}
                menuName={""}
              />
            </Switch>
          </ScrollTop>
        </Router>
      </div>
    );
  }
}

// hammadaf9408@gmail.com
// Adina159123
const mapStateToProps = (state) => ({
  map: state.map,
  pengambilan: state.kirimbarangReducer.pengambilan,
  pengiriman: state.kirimbarangReducer.pengiriman,
  auth: state.authReducer,
});

export const App = connect(mapStateToProps)(AppConnect);

export default App;
