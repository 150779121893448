import React, { useEffect, useState } from "react";

/*======================== Third Party ======================== */
import { useSelector, useDispatch } from "react-redux";
import {
  Card,
  Row,
  Col
} from "react-bootstrap";
import { Spin } from "antd";
import moment from "moment";
import { IoMenuOutline } from "react-icons/io5";
import qs from "query-string";
import { useDebouncedCallback } from "use-debounce";

/*======================== Utils ======================== */
import useBreakpoint from "../../components/responsiveUtils/useBreakpoint";

/*======================== API Services ======================== */
import TrxListApi from "../../api/trx_list_api";

/*======================== Set Global State ======================== */
import { setTrxState } from "../../redux/action/trx_history_action";

/*======================== Components ======================== */
import { hideSidenavMobile, openSidenavMobile } from "../../components/layouts/layout_helper";
import TabTypeOrder from "./components/TabTypeOrder";
import CardListOrder from "./components/CardListOrder";
import GroupFilter from "./components/GroupFilter";
import CustomPagination from "./components/Pagination";
import SearchByNoOrder from "./components/SearchByNoOrder";
import SortByDate from "./components/SortByDate";

/*======================== Icon || Image ======================== */
import noData from "../../assets/images/no-data.png";


export default function DaftarTransaksi() {
  const { isMobile, isDesktop } = useBreakpoint();

  /*======================== Hooks  ======================== */

  const dispatch = useDispatch();

  /*======================== Redux ======================== */

  const auth = useSelector((st) => st.authReducer);
  const trxOngoing = useSelector((st) => st.trxHistoryReducer.trxOngoing);
  
  /*======================== UseState ======================== */

  const [eventKey, setEventKey] = useState(1);
  const [query, setQuery] = useState({ limit: 10, page:1, order_by: "DESC"});
  const [optionsPartner, setOptionsPartner] = useState(null)
  const [choosePartner, setChoosePartner] = useState([])
  const [dateFilter, setDateFilter] = useState("");
  const [sort, setSort] = useState("Terbaru");
  const [totalData, setTotalData] = useState(10);

  /*======================== Others ======================== */

  const sortOptions = [
    {
      title: "Terbaru",
    },
    {
      title: "Terlama",
    },
  ];
  const [optionsStatusTransaction, setOptionsStatusTransaction] = useState([
    {
      isActive: false,
      name: "Menunggu Pembayaran",
      value: "WAITING_FOR_CONFIRMATION"
    },
    {
      isActive: false,
      name: "Konfirmasi Partner",
      value: "CANCELLED"
    },
    {
      isActive: false,
      name: "Berlangsung",
      value: "ON_GOING"
    },
    {
      isActive: false,
      name: "Pesanan Selesai",
      value: "FINISHED"
    },
    {
      isActive: false,
      name: "Pesanan Dibatalkan",
      value: "CANCELLED"
    },
  ])
  const [optionsTypeOrder, setOptionsTypeOrder] = useState([
    {
      isActive: false, 
      name: "Sekarang",
      value: "INSTANT"
    },
    {
      isActive: false,
      name: "Terjadwal",
      value: "SCHEDULED"
    }
  ])
  const [optionsTypeAddress, setOptionsTypeAdress] = useState([
    {
      isActive: false,
      name: "Single Pickup",
      value: "SINGLE_PICKUP"
    },
    {
      isActive: false,
      name: "Single Dropoff",
      value: "SINGLE_DROPOFF"
    },
    {
      isActive: false,
      name: "Multiple Pickup",
      value: "MULTIPLE_PICKUP"
    },
    {
      isActive: false,
      name: "Multiple Dropoff",
      value: "MULTIPLE_DROPOFF"
    }
  ])

  /*======================== Handler ======================== */

  // Get order list
  // it already handle by filter tab
  const getOrderList = async () => {
    const api = new TrxListApi();
    const stringified = qs.stringify(query, { arrayFormat: "bracket" });

    dispatch(
      setTrxState(
        { trxOngoing: { loading: true, data: [] } },
        auth.refresh_token
      )
    );

    let data, total_row;

    try {
      // ongoing - all
      if (eventKey == 1) {
        const { data: orderList, total_data } = await api.getAllOrderList(
          auth.access_token,
          stringified
        );
        total_row = total_data
        data = [...orderList];
      }

      // ongoing - send
      if (eventKey == 2) {
        const { data: orderListSend, total_data } = await api.getSendOrderList(
          auth.access_token,
          stringified
        );
        total_row = total_data
        data = [...orderListSend];
      }

      // ongoing - rent
      if (eventKey == 3) {
        const { data: orderListRent, total_data } = await api.getRentOrderList(
          auth.access_token,
          stringified
        );
        total_row = total_data
        data = [...orderListRent];
      }
      
      setTotalData(total_row) // set total (it will be update by tab)

      dispatch(
        setTrxState(
          { trxOngoing: { loading: false, data: [...data] } },
          auth.refresh_token
        )
      );
    } catch (error) {
      dispatch(
        setTrxState(
          { trxOngoing: { loading: false, data: [] } },
          auth.refresh_token
        )
      );
    }
  };

  const getListPartner = async () => {
    const api = new TrxListApi();
    const stringified = qs.stringify(query, { arrayFormat: "bracket" });
    try{
      const result = await api.getListPartner(
        auth.access_token,
        stringified
      );
      setOptionsPartner(result)
    }catch(err){
      console.log(err)
    }
  }

  const handleChangeTab = (key) => {
    sessionStorage.setItem("daftar-transaksi-tabJenisOrder", key);
    setQuery({ ...query, page: 1, limit:10 });
    setEventKey(+key);
  };

  const handleSearch = useDebouncedCallback((ev) => {
    setQuery({ ...query, page:1, search: ev.target.value });
  }, 300);

  const handleSort = (val) => {
    let sort = ""
    if(val === "Terbaru"){
      setSort("Terbaru")
      sort = "DESC"
    }else{
      setSort("Terlama")
      sort = "ASC"
    }
    setQuery({ ...query, page: 1, limit:10, order_by:sort });
  }

  const handleDateFilter = (date) => {
    let dateQuery = {
      filter_start_date: moment({ ...date.from }).format(),
      filter_end_date: moment({ ...date.to }).format(),
    };
    setDateFilter(dateQuery);
  };

  const handleChecked = (name, param) => {
    if(param && param === "Pilih Status Transaksi"){
      const data = optionsStatusTransaction.map((item)=>
      item.name === name ? { ...item, isActive: !item.isActive } : item
      );
      setOptionsStatusTransaction(data)
    }
    if(param && param === "Pilih Jenis Pesanan"){
      const data = optionsTypeOrder.map((item)=>
      item.name === name ? { ...item, isActive: !item.isActive } : item
      );
      setOptionsTypeOrder(data)
    }
    if(param && param === "Pilih Tipe Alamat"){
      const data = optionsTypeAddress.map((item)=>
      item.name === name ? { ...item, isActive: !item.isActive } : item
      );
      setOptionsTypeAdress(data)
    }
  }

  const handleAddPartnerFilter = (val) => {
    const check = choosePartner.filter(e => e.id === val.id)
    if(check.length > 0){
      console.log("data udah ada bro")
    }else{
      const data = [...choosePartner, val] 
      setChoosePartner(data)
    }
  }

  const handleDeletePartnerFilter = (val) => {
    const { id } = val
    const data = choosePartner.filter(e=> e.id !== id)
    setChoosePartner(data)
  }

  const handleSubmitFilter = async () => {
    var statusTransaction = [],
    typeOrder = [],
    typeAddress = [],
    partner = []

    optionsStatusTransaction.forEach((row) => {
      if(row.isActive){
        statusTransaction.push(row.value)
      }
    })
    optionsTypeOrder.forEach((row) => {
      if(row.isActive){
        typeOrder.push(row.value)
      }
    })
    optionsTypeAddress.forEach((row) => {
      if(row.isActive){
        typeAddress.push(row.value)
      }
    })
    choosePartner.forEach((row)=>{
      partner.push(row.id)
    })

    setQuery({ 
      ...query, 
      limit: 10, 
      page: 1, 
      ...dateFilter, 
      booking_status: statusTransaction,
      category_order: typeOrder,
      send_category_order: typeAddress,
      partner_id: partner
    });
  }

  const handleClearFilter = () => {
    setDateFilter("")
    setOptionsStatusTransaction([
      {
        isActive: false,
        name: "Menunggu Pembayaran",
        value: "WAITING_FOR_CONFIRMATION"
      },
      {
        isActive: false,
        name: "Konfirmasi Partner",
        value: "CANCELLED"
      },
      {
        isActive: false,
        name: "Berlangsung",
        value: "ON_GOING"
      },
      {
        isActive: false,
        name: "Pesanan Selesai",
        value: "FINISHED"
      },
      {
        isActive: false,
        name: "Pesanan Dibatalkan",
        value: "CANCELLED"
      },
    ])
    setOptionsTypeOrder([
      {
        isActive: false, 
        name: "Sekarang",
        value: "INSTANT"
      },
      {
        isActive: false,
        name: "Terjadwal",
        value: "SCHEDULED"
      }
    ])
    setOptionsTypeAdress([
      {
        isActive: false,
        name: "Single Pickup",
        value: "SINGLE_PICKUP"
      },
      {
        isActive: false,
        name: "Single Dropoff",
        value: "SINGLE_DROPOFF"
      },
      {
        isActive: false,
        name: "Multiple Pickup",
        value: "MULTIPLE_PICKUP"
      },
      {
        isActive: false,
        name: "Multiple Dropoff",
        value: "MULTIPLE_DROPOFF"
      }
    ])
    setChoosePartner([])
    setQuery({limit: 10, page: 1 });
  }

  const handlePagination = (type, value) => {
    switch (type) {
      case "page":
        setQuery({ ...query, page: value });
        break;
      case "limit":
        setQuery({ ...query, limit: value, page: 1 });
        break;
      default:
        break;
    }
  };

  /*======================== useEffect ======================== */

  // handle back previous page for tab
  useEffect(() => {
    getListPartner()
    if(sessionStorage.getItem("daftar-transaksi-tabJenisOrder")){
      setEventKey(sessionStorage.getItem("daftar-transaksi-tabJenisOrder"))
    }
  }, []);

  // Get List Data Order, (it wil be automatic recall when query and eventKey update)
  // query & eventKey is a filter
  useEffect(() => {
    getOrderList();
  }, [query, eventKey]);

  // Handle Responsive to Mobile
  useEffect(() => {
    if (isMobile) hideSidenavMobile();
  }, [isMobile]);

  /*======================== Return ======================== */


  return (
    <div
      style={{ minHeight: "100vh", width: "100%", padding: "20px 30px" }}
      className="bg-ice"
    >
      {!isMobile ? (
        <Card
          className="p-4 rounded-card border-0"
          style={{ backgroundColor: "white" }}
        >
          <h1>Daftar Transaksi</h1>
          <TabTypeOrder
            handleChangeTab={handleChangeTab}
          />
          <div className="d-flex w-full">
            <SearchByNoOrder
              handleSearch={handleSearch}
            />
            {/* for tablet only */}
            {!isDesktop &&
              <div hidden={isDesktop} className="pointer w-50 d-flex position-absolute" style={{right:10}}>
                <GroupFilter
                  // filter date 
                  dateFilter={dateFilter}
                  handleDateFilter={handleDateFilter}
                  // filter check box
                  options={optionsStatusTransaction}
                  optionsTwo={optionsTypeOrder}
                  optionsThree={optionsTypeAddress}
                  handleChecked={handleChecked}
                  // filter by partner
                  dataOptions={optionsPartner}
                  handleAddPartnerFilter={handleAddPartnerFilter}
                  handleDeletePartnerFilter={handleDeletePartnerFilter}
                  choosePartner={choosePartner}
                  // clear all input filter
                  handleClearFilter={handleClearFilter}
                  // submit
                  handleSubmitFilter={handleSubmitFilter}
                />
              </div>      
            }
            {/* for desktop only */}
            {isDesktop &&
              <SortByDate
                sort={sort}
                sortOptions={sortOptions}
                handleSort={handleSort}
              />
            }
          </div>
        </Card>
      ) : (
        <div className="p-2 head-menu trx w-100 shadow-md">
          <div
            className="d-flex mb-2 justify-content-center px-2 flex-column w-100"
            style={{ marginTop: "1.7em" }}
          >
            <div className="d-flex">
              <IoMenuOutline
                onClick={openSidenavMobile}
                className="text-secondary pointer me-2"
                style={{ fontSize: 25 }}
              />

              <h4 className="mt-1">Daftar Transaksi</h4>
            </div>
            <TabTypeOrder
              handleChangeTab={handleChangeTab}
            />
            <div className="d-flex w-full">
              <SearchByNoOrder
                handleSearch={handleSearch}
              />
              <div hidden={isDesktop} className="pointer w-75 d-flex position-absolute" style={{right:10}}>
                <GroupFilter
                  // filter date 
                  dateFilter={dateFilter}
                  handleDateFilter={handleDateFilter}
                  // filter check box
                  options={optionsStatusTransaction}
                  optionsTwo={optionsTypeOrder}
                  optionsThree={optionsTypeAddress}
                  handleChecked={handleChecked}
                  // filter by partner
                  dataOptions={optionsPartner}
                  handleAddPartnerFilter={handleAddPartnerFilter}
                  handleDeletePartnerFilter={handleDeletePartnerFilter}
                  choosePartner={choosePartner}
                  // clear all input filter
                  handleClearFilter={handleClearFilter}
                  // submit
                  handleSubmitFilter={handleSubmitFilter}
                />
              </div>
            </div>
          </div>
        </div>
      )}


      {/* ======================== Start Body ======================== */}

      {/* Filter and List Order */}
      <Row className="mt-2">
        {/* Filter, and only show in desktop */}
        {isDesktop && (
          <Col md={3} lg={3}>
            <div className="bg-white mt-2 py-2 pt-3 rounded-card">
              {/* Title */}
              <h4 className="px-3 fw-bold" style={{marginBottom:"-5px"}}> Filter Pencarian</h4>
              <hr/>
              <GroupFilter
                // filter date 
                dateFilter={dateFilter}
                handleDateFilter={handleDateFilter}
                // filter check box
                options={optionsStatusTransaction}
                optionsTwo={optionsTypeOrder}
                optionsThree={optionsTypeAddress}
                handleChecked={handleChecked}
                // filter by partner
                dataOptions={optionsPartner}
                handleAddPartnerFilter={handleAddPartnerFilter}
                handleDeletePartnerFilter={handleDeletePartnerFilter}
                choosePartner={choosePartner}
                // clear all input filter
                handleClearFilter={handleClearFilter}
                // submit
                handleSubmitFilter={handleSubmitFilter}
              />
            </div>
          </Col>
        )}

        {/* List Order */}
        <Col>
          <div className={`scrollable-70`} style={{marginTop:`${isMobile ? "190px":"5px"}`}}>
            
            {/* Loading */}
            {trxOngoing.loading && (
              <Card className="p-4 rounded-card border-0 h-50 d-flex align-items-center justify-content-center">
                <Spin />
              </Card>
            )}

            {/* No Data */}
            {!trxOngoing.loading && !trxOngoing?.data?.length && (
              <div
                className="d-flex flex-column align-items-center justify-content-center"
                style={{ marginTop: "6rem" }}
              >
                <img src={noData} alt="no-data" />
                <p className="mt-3">List order tidak di temukan..</p>
              </div>
            )}

            {/* List Data */}
            {
              !trxOngoing.loading &&
              trxOngoing?.data?.map((eachData, i) => (
                <CardListOrder eachData={eachData} />
              ))
            }
          </div>

          {/* Pagination */}
          {trxOngoing?.data?.length ?
            (<CustomPagination
              data={trxOngoing?.data}
              limit={query.limit}
              page={query.page}
              handleChange={handlePagination}
              total_data={totalData}
              />) : null
          }
        </Col>
        {/* END - List Order */}
      </Row>

    </div>
  );
}
