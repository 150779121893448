import React from "react";
import {
  Modal,
  Form,
  InputGroup,
  FloatingLabel,
  Button,
} from "react-bootstrap";

import { MdChevronRight, MdClose } from "react-icons/md";

import add_bank_success from "../../assets/images/add_bank_success.svg";

const AddBankSuccess = ({ show, closeModal }) => {
  return (
    <Modal
      contentClassName="rounded-card p-2"
      show={show}
      onHide={closeModal}
      className="p-3"
      centered
    >
      <div className="d-flex p-2">
        <MdClose className="ms-auto me-2 pointer" onClick={closeModal} />
      </div>
      <Modal.Body>
        <div className="d-flex align-items-center justify-content-center flex-column gap-2">
          <img src={add_bank_success} alt="" />
          <h3 className="fw-bold">Rekning Berhasil ditambahkan.</h3>
          <h5 className="text-muted text-center">
            Data Rekening baru anda sudah Berhasil ditambahkan ke daftar
            rekeningmu!
          </h5>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddBankSuccess;
