import { BaseApiRequest } from "./base-api";
import { OpResult } from "../helper/operation_result";
import { message } from "antd";
import axios from "axios";

export default class AuthApi extends BaseApiRequest {
  constructor() {
    super(process.env.REACT_APP_API_END_POINT);
  }

  async refreshTokenCustomer(token) {
    let result = OpResult.failed("Failed refreshTokenCustomer");
    try {
      let resApi = await this.put("/customer/authentication", { token });
      if (resApi.status) {
        result = resApi;
      }
    } catch (e) {
      result = OpResult.failed("Internal Error");
    }
    return result;
  }

  async loginCustomer(
    user, 
    password, 
    notif_id, 
    otp_id  // add parameter for otp engine
    ) {
    let result = OpResult.failed("Failed loginCustomer");
    try {
      let resApi = await axios.post(
        this.endpoint + "/customer/authentication",
        {
          user,
          password,
          notif_id,
          otp_id // add for otp engine
        }
      );
      result = resApi.data;
    } catch (e) {
      console.log(e.response);
      message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async otpWithRequestToken() {
    let result = OpResult.failed("Failed loginCustomer");
    try {
      let resApi = await axios.post(
        this.endpoint + "/customer/authentication/request-new-otp",
        {
          token_otp_request: localStorage.token_otp_request,
          customer_id: localStorage.customer_id,
        }
      );
      result = resApi.data;
    } catch (e) {
      console.log(e.response);
      message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async validateOtpWithRequestToken(body) {
    let result = OpResult.failed("Failed loginCustomer");
    try {
      let resApi = await axios.post(
        this.endpoint + "/customer/authentication/validate-otp",
        {
          otp_code: body.otp_code,
          customer_id: localStorage.customer_id,
        }
      );
      result = resApi.data;
    } catch (e) {
      console.log(e.response);
      message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async checkToken(refreshToken) {
    let result = OpResult.failed("Failed refreshTokenCustomer");
    try {
      let resApi = await this.get(
        `/customer/authentication?token=${refreshToken}&type=refresh_token`
      );
      if (resApi.status) {
        result = resApi;
      }
    } catch (e) {
      result = OpResult.failed("Internal Error");
    }
    return result;
  }

  async checkAccessToken(accessToken) {
    let result = OpResult.failed("Failed check token");
    try {
      let resApi = await this.get(
        `/customer/authentication?token=${accessToken}&type=access_token`
      );
      if (resApi.status) {
        result = resApi;
      }
    } catch (e) {
      result = OpResult.failed("Internal Error");
    }
    return result;
  }

  async getDetailCustomer(token) {
    let result = OpResult.failed("Failed GET detail customer");
    try {
      let resApi = await this.get(`/customer`, token);
      if (resApi.status) {
        result = resApi;
      }
    } catch (e) {
      result = OpResult.failed("Internal Error");
    }
    return result;
  }

  async forgotPassword(body) {
    let result = OpResult.failed("Failed POST forgot password");
    try {
      let resApi = await axios.post(
        this.endpoint + "/customer/forget-password/",
        {
          user: body.user,
          url: process.env.REACT_APP_WEBAPP_URL + "/lupa-password/",
        }
      );
      result = resApi.data;
    } catch (e) {
      console.log(e.response);
      message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }

  async resetForgottenPassword(body) {
    let result = OpResult.failed("Failed POST reset forgotten password");
    try {
      let resApi = await axios.patch(
        this.endpoint + "/customer/reset-password/",
        {
          reset_token: body.reset_token,
          new_password: body.new_password,
        }
      );
      result = resApi.data;
    } catch (e) {
      console.log(e.response);
      message.error(e?.response?.data?.message);
      result = e.response;
    }
    return result;
  }
}
