import { Row, Col, Card } from "react-bootstrap";
import { useState, useEffect } from "react";
import { setContract } from "../../../redux/action/kirimbarang_action";
import { useDispatch, useSelector } from "react-redux";
import { FaChevronRight } from "react-icons/fa";
import OrderSendApi from "../../../api/order-send-api";
import RuteList from "./Modal/RuteList";

const KirimBarangRute = (props) => {
  /*======================== Hooks ======================== */

  const dispatch = useDispatch();
  const route = useSelector((st) => st.kirimbarangReducer.route);
  const auth = useSelector((st) => st.authReducer);

  /*======================== UseState ===================== */

  const [openRute, setOpenRute] = useState(false);
  const [contractList, setContractList] = useState([
    {
      id: "1",
      name: "Tanpa Kontrak",
    },
  ]);

  /*======================== Handler ====================== */

  const actionUpdateRoute = (item, contract) => {
    setOpenRute(false);
    setContractValue(contract);
    props.updateRoute(item);
  };

  const setContractValue = (item) => {
    dispatch(setContract({ ...item }));
  };

  const updateContractList = (item) => {
    let arr1 = [
      {
        id: "1",
        name: "Tanpa Kontrak",
      },
    ];
    let mergeArray = arr1.concat(item);
    setContractList([...mergeArray]);
  };

  const getTenderList = async () => {
    const api = new OrderSendApi();
    const data = await api.getTender(auth.access_token);
    if (data && data.data && data.data.length > 0) {
      updateContractList(data.data);
    }
  };

  /*======================== UseEffect ==================== */

  useEffect(() => {
    if (openRute) {
      getTenderList();
    }
  }, [openRute]);

  /*======================== Return ======================= */

  return (
    <div className="mb-2">

      {/* --- --- --- HIDE SEMENTARA FOR PRODUCTION 1/12/2022  --- --- ---  */}
      <Card className="ice mb-2">
        <Card.Body>
          <div
            style={{ display: "flex", justifyContent: "space-between" }}
            onClick={() => setOpenRute(true)}
          >
            <span style={{ fontSize: "13px" }}>
              {" "}
              {route && route.name
                ? "Rute: " + route.name
                : "Pilih Rute Pengiriman"}
            </span>
            <FaChevronRight style={{ cursor: "pointer" }} />
          </div>
        </Card.Body>
      </Card>
      {/* --- --- --- HIDE SEMENTARA FOR PRODUCTION 1/12/2022  --- --- ---  */}

      {openRute && (
        <RuteList
          contractList={contractList}
          isOpen={openRute}
          actionUpdateRoute={actionUpdateRoute}
          handleClose={() => setOpenRute(false)}
        />
      )}
    </div>
  );
};

export default KirimBarangRute;
