import React, { useState, useEffect } from "react";
import {
  Modal,
  Tabs,
  Tab
} from "react-bootstrap";
import util from "../../../../helper/util";


const ModalRincianPembayaran = ({trxDetail}) => {
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    const [eventKey, setEventKey] = useState(1)

    const handleChangeTab = (key) => {
      setEventKey(parseInt(key))
    }

    const hargaLayanan = [
      {
        title: "Tarif dasar Layanan",
        value: util.thousandSeparatorPure(trxDetail?.data?.service_price || 0),
        style: {}
      },
      {
        title: "Layanan Driver",
        value: util.thousandSeparatorPure(trxDetail?.data?.trx_booking_rent?.driver_price || 0 ),
        style: {}
      },
      {
        title: "Layanan Helper",
        value: util.thousandSeparatorPure(trxDetail?.data?.trx_booking_rent?.helper_price || 0 ),
        style: {}
      },
      {
        title: "Diskon Partner",
        value: util.thousandSeparatorPure(trxDetail?.data?.trx_booking_rent?.partner_discount || 0),
        style: {color: trxDetail?.data?.trx_booking_rent?.partner_discount > 0 ? "#50B280" : "#666666"} 
      },
      {
        title: "VAT",
        value: util.thousandSeparatorPure(trxDetail?.data?.customer_vat || 0),
        style: {}
      },
      {
        title: "Biaya Layanan eLOG (0.55%)",
        value: util.thousandSeparatorPure(trxDetail?.data?.trx_booking_rent?.customer_service_fee || 0),
        style: {}
      },
    ]

    const tarifLainnya = [
      {
        title: "Promo eLOG",
        value: util.thousandSeparatorPure(trxDetail?.data?.elog_discount || 0),
        style: {}
      },
      {
        title: "Deposit (30%)*",
        value: util.thousandSeparatorPure(trxDetail?.data?.customer_deposit || 0),
        style: {color: trxDetail?.data?.customer_deposit > 0 ? "#50B280" : "#666666"}
      },
    ]

    const rincianGantiRugi = [
      {
        title: "Biaya Kerusakan",
        value: util.thousandSeparatorPure(trxDetail?.data?.trx_booking_rent?.trx_booking_rent_vehicle?.damage_compensation || 0),
        style: {}
      },
      {
        title: "Kelebihan Jarak Tempuh",
        value: util.thousandSeparatorPure(trxDetail?.data?.trx_booking_rent?.excess_usage_km || 0 ),
        style: {}
      },
      {
        title: "Kelebihan Bensin",
        value: util.thousandSeparatorPure(trxDetail?.data?.trx_booking_rent?.excess_fuel_bar || 0 ),
        style: {}
      },
    ]

    const rincianGantiRugiOther = [
      {
        title: "Deposit (30%)",
        value: util.thousandSeparatorPure(trxDetail?.data?.trx_booking_rent?.deposit || 0),
        style: {}
      },
      {
        title: "Kekurangan Bayar",
        value: util.thousandSeparatorPure(trxDetail?.data?.trx_booking_rent?.lack_compensation || 0 ),
        style: {}
      },
      {
        title: "Kelebihan Bayar",
        value: util.thousandSeparatorPure(trxDetail?.data?.trx_booking_rent?.balance  || 0 ),
        style: {color:trxDetail?.data?.trx_booking_rent?.helper_price > 0 ? "#0FAA4B" : "#666666"}
      },
    ]


    useEffect(()=>{
      setEventKey(1)
    },[])


    return (
    <>
      <h6
        className="fw-bold text-center"
        style={{color:"#FF1DBC", textDecoration:"underline", fontSize:"11px", cursor:"pointer"}}
        onClick={()=>handleShow()}
      >
        Lihat Rincian
      </h6>
      
       <Modal
        contentClassName="rounded-card p-2"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton className="bg-white border-0">
          <strong>Rincian Pembayaran</strong>
        </Modal.Header>
        <Tabs
          defaultActiveKey={eventKey}
          className="tabs-trx mb-2 border-bottom-2"
          style={{
            marginTop:"-10px"
          }}
          onSelect={handleChangeTab}
        >
          <Tab eventKey="1" title="Rincian Transaksi"></Tab>
          <Tab eventKey="2" title="Info Ganti Rugi"></Tab>
        </Tabs>
        {eventKey === 1 &&
          <>
            <div className="p-3">
              <h6 className="m-0 p-0 fw-bold mb-3">Harga Layanan</h6>
              {hargaLayanan.map((item, i)=>(
                <div key={i} className="d-flex justify-content-between mb-3">
                  <h6 className="m-0 p-0">{item.title}</h6>
                  <h6 className="m-0 p-0" style={item.style}>Rp {item.value}</h6>
                </div>
              ))}

              <h6 className="m-0 p-0 fw-bold mb-3">Tarif Lainnya</h6>
              {tarifLainnya.map((item, i)=>(
                <div key={i} className="d-flex justify-content-between mb-3">
                  <h6 className="m-0 p-0">{item.title}</h6>
                  <h6 className="m-0 p-0" style={item.style}>Rp {item.value}</h6>
                </div>
              ))}

              <h6 className="m-0 p-0 fw-bold mb-3">Biaya Tambahan</h6>
              <div className="d-flex justify-content-between">
                <h6 className="m-0 p-0">MDR ({trxDetail?.data?.trx_payment?.payment_method_name})</h6>
                <h6 className="m-0 p-0">
                  Rp {util.thousandSeparatorPure(trxDetail?.data?.trx_payment?.amount_admin || 0 )}
                  </h6>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center border-top p-3">
              <h6 className="m-0 p-0 fw-bold" style={{fontSize:"16px"}}>TOTAL PEMBAYARAN</h6>
              <h6 className="m-0 p-0 fw-bold" style={{fontSize:"16px", color:"#2BBECB"}}>Rp {util.thousandSeparatorPure(trxDetail?.data?.total_customer_paid || 0)}</h6>
            </div>
          </>
        }

        {eventKey === 2 &&
          <>
            {/* <div className="p-5">
              <h6 className="text-center">Belum ada informasi ganti rugi</h6>    
            </div> */}

            <div className="p-3">
              <h6 className="m-0 p-0 fw-bold mb-3">Biaya Tambahan</h6>
              {rincianGantiRugi.map((item, i)=>(
                <div key={i} className="d-flex justify-content-between mb-3">
                  <h6 className="m-0 p-0">{item.title}</h6>
                  <h6 className="m-0 p-0" style={item.style}>Rp {item.value}</h6>
                </div>
              ))}

              <div className="border-bottom mt-4 mb-3"></div>
              <div className="mb-3 d-flex justify-content-between">
                <h6 className="fw-bold m-0" style={{fontSize:"16px"}}>TOTAL GANTI RUGI</h6>
                <h6 className="fw-bold m-0" style={{fontSize:"16px", color:"#FF4949"}}>Rp {util.thousandSeparatorPure(trxDetail?.data?.trx_booking_rent?.total_compensation || 0)}</h6>
              </div>
              <div className="border-bottom mb-4"></div>


              {rincianGantiRugiOther.map((item, i)=>(
                <div key={i} className="d-flex justify-content-between mb-3">
                  <h6 className="m-0 p-0">{item.title}</h6>
                  <h6 className="m-0 p-0" style={item.style}>Rp {item.value}</h6>
                </div>
              ))}
            </div>
          </>
        }

      </Modal>
    </>
    )
}

export default ModalRincianPembayaran;
