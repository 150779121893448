import React from "react";
import {
  Modal,
  Button
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { FaDownload } from "react-icons/fa";
import { BiEdit } from "react-icons/bi";

const ProofPayment = ({button = false, show, setShow, onSubmit, setModalChangeReceipt, payload}) => {
  const detail = useSelector((st) => st.documentPaymentReducer.detail);
  const handleClose = () => setShow(false)

  const handleSubmit = async() => {
    onSubmit()
    handleClose()
  }

  return(
  <>
    {button &&
      <h6
        size="sm"
        className="py-1 m-0 h-25 text-primary pointer underline"
        onClick={()=>setShow(true)}
      >
        Lihat Bukti
      </h6>
    }
    <Modal
      contentClassName="rounded-card p-2"
      show={show}
      onHide={handleClose}
      centered
    >
        <Modal.Header closeButton className="bg-white">
            <strong>Bukti Pembayaran</strong>
        </Modal.Header>
        <div className="p-2" style={{maxHeight:"520px", overflowY:"scroll"}}>
          {detail?.customer_payment_confirmation_receipt && detail?.customer_payment_confirmation_receipt?.split('.')[detail?.customer_payment_confirmation_receipt?.split('.')?.length - 1] === "pdf" &&
            <Button className="w-100 my-3" onClick={()=> window.open(detail?.customer_payment_confirmation_receipt, "_blank")}>Buka File PDF</Button>
          }
          {detail?.customer_payment_confirmation_receipt && detail?.customer_payment_confirmation_receipt?.split('.')[detail?.customer_payment_confirmation_receipt?.split('.')?.length - 1] !== "pdf" &&
            <img className="img-fluid rounded" src={detail?.customer_payment_confirmation_receipt} alt="no-data"/>
          }
          {/* {payload?.receipt &&
            <img className="img-fluid rounded" src={payload?.receipt} alt="no-data"/>
          } */}
          {(!detail?.customer_payment_confirmation_receipt && !payload?.receipt) &&
            <h5 className="my-5 text-center" style={{color:"gray"}}>"Bukti belum ada"</h5>
          }
        </div>
        <Modal.Footer>
          <div className="w-100 d-flex justify-content-between">
            <div className="d-flex align-items-center gap-1" onClick={()=> {handleClose(); setModalChangeReceipt(true)}}>
              <BiEdit className="text-primary"/>
              <h5
                size="sm"
                className="py-1 m-0 text-primary pointer fw-bold"
                >
                Ubah Bukti
              </h5>
            </div>
            {(detail?.customer_payment_confirmation_receipt || payload?.receipt) &&
              <div className="d-flex align-items-center gap-1" onClick={(e) => { e.preventDefault(); window.open(detail?.customer_payment_confirmation_receipt, '_blank'); }}>
                <FaDownload className="text-primary"/>
                <h5
                  size="sm"
                  className="m-0 text-primary pointer fw-bold"
                >
                  Download
                </h5>
              </div>
            }
          </div>
        </Modal.Footer>
    </Modal>
  </>
  )
}

export default ProofPayment;
