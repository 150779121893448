import React from 'react'
import iconI from "./../../assets/info/Group 4369.png"
import bgSm from "./../../assets/info/Vector-1.png"
import bgLg from "./../../assets/info/Vector.png"
import parse from 'html-react-parser';

const Info = ({info}) => {
  return (
    <div className="rounded p-2 mb-3" style={{backgroundColor:"#E7F3F5", position:"relative", overflow:"hidden"}}>
      <img src={bgLg} alt="carDepan" className="ms-3" style={{position:"absolute", left:-25, top:0}}></img>
      <img src={bgSm} alt="carDepan" className="ms-3" style={{position:"absolute", left:-5, top:0}}></img>
      <img src={iconI} alt="carDepan" className="ms-3" style={{position:"absolute", left:0, top:20}}></img>
      <h6 className="m-0 py-2 text-muted" style={{paddingLeft:"40px"}}>
        {parse(info)}
      </h6>
    </div>
  )
}

export default Info