import {
  Row,
  Col,
} from "react-bootstrap";
import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import useBreakpoint from "../../../../components/responsiveUtils/useBreakpoint";
import { getDetailVehicle } from "../../../../redux/action/Partner/partner_action";
import SideNav from "../../components/SideNav";
import PartnerKendaraanDetailInformasi from "./DetailKendaraan";

const PartnerKendaraanDetail = () => {
  /*======================== Hooks ======================== */

  const { id, vehicle} = useParams();
  const dispatch = useDispatch();
  const vehicleDetail = useSelector(
    (st) => st.partnerManagementReducer.vehicleDetail
  );
  const { isMobile } = useBreakpoint();


  /*======================== UseState ======================== */

  /*======================== Handler ======================== */

  /*======================== UseEffect ======================== */

  useEffect(() => {
    dispatch(getDetailVehicle(id, vehicle));
  }, []);

  useEffect(() => {
    if (vehicleDetail) {
    }
  }, [vehicleDetail]);

  /*======================== Others ======================== */

  /*======================== Return ======================== */

  return (
    <div
      style={{ minHeight: "100vh", width: "100%", padding: "30px" }}
      className="bg-ice height-100"
    >
      <Row className="">
        <Col xs={12} lg={3}>
          <SideNav />
        </Col>
        <Col xs={12} lg={9} className={`${isMobile && "mt-18rem"}`}>
          <PartnerKendaraanDetailInformasi detail={vehicleDetail}/>
        </Col>
      </Row>
    </div>
  );
};

export default PartnerKendaraanDetail;
