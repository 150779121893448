import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Modal,
  Form,
  Row,
  Col,
  InputGroup,
  FloatingLabel,
} from "react-bootstrap";
import { Checkbox } from "antd";
import { getMasterArea } from "../../../../redux/action/Management/procurement_action";
import { useDispatch, useSelector } from "react-redux";
import useBreakpoint from "../../../../components/responsiveUtils/useBreakpoint";
import { FaSearch, FaTrashAlt } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import PropTypes from "prop-types";
import _ from "lodash";

const Area = (props) => {
  /*======================== Hooks ======================== */

  const { isMobile } = useBreakpoint();
  const dispatch = useDispatch();
  const area_options = useSelector((st) => st.procurementReducer.master_area);
  const auth = useSelector((st) => st.authReducer);

  /*======================== Props ======================== */

  const { isOpen, handleClose, handleSubmit, formValue } = props;

  /*======================== UseState ======================== */

  const [active, setActive] = useState([]);
  const [area, setArea] = useState([]);
  const [group, setGroup] = useState([]);
  const [groupActive, setGroupActive] = useState([]);
  const [search, setSearch] = useState(undefined);
  const [checkAll, setCheckAll] = useState(false);

  /*======================== Handler ======================== */

  const handleActive = (value) => {
    let temp = active;
    if (temp && temp.length > 0) {
      let find = temp.find((item) => item.id === value.id);
      if (!find) {
        setActive([...active, value]);
      }
    } else {
      setActive([...active, value]);
    }
  };

  const handleDeleteActive = (value) => {
    setActive((old) => [...old.filter((item) => item.id !== value.id)]);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleOnClose = () => {
    handleClose();
  };

  const handleOnSelect = () => {
    handleSubmit(active);
  };

  const onChangeSelectAll = (e) => {
    let selectall = [];
    if (e.target.checked) {
      area_options.forEach((item) => {
        selectall.push(item);
      });
    }

    setActive(selectall);
    setCheckAll(e.target.checked);
  };

  const onChangeSelectAllProvince = (e, item) => {
    let groupArr = [...group];
    let index = groupArr.findIndex((val) => val.province === item.province);
    groupArr[index].checked = e.target.checked;

    setGroup([...groupArr]);
    if (e.target.checked) {
      let array = area.filter((val) => val.mst_province.name === item.province);
      let newArr = [...active];
      let mergeArray = newArr.concat(array);
      setActive([...mergeArray]);
    } else {
      let newArr = [...active];
      let filterArray = newArr.filter(
        (val) => val.mst_province.name !== item.province
      );
      setActive([...filterArray]);
    }
  };

  const createArrayGroup = (datas) => {
    let newArray = [];
    for (const key in datas) {
      let findActive = active.filter((item) => item.mst_province.name === key);
      let checked = false;
      if (findActive && findActive.length > 0) {
        checked = findActive.length === datas[key].length;
      }
      newArray.push({
        province: key,
        checked: checked,
        data: datas[key],
      });
    }
    setGroup([...newArray]);
  };

  const createArrayActiveGroup = () => {
    let groupData = _.groupBy(active, (item) => item.mst_province.name);
    let newArray = [];
    for (const key in groupData) {
      newArray.push({
        province: key,
        data: groupData[key],
      });
    }
    setGroupActive([...newArray]);
  };

  const renderListComponent = () => {
    let component = [];
    if (group && group.length > 0) {
      group.forEach((item, index) => {
        component.push(
          <div key={index}>
            <div className="mt-2 mb-2">
              <Checkbox
                onChange={(e) => onChangeSelectAllProvince(e, item)}
                checked={item.checked}
              >
                {" "}
                {item.province}
              </Checkbox>
            </div>
            {loopZone(item.data)}
          </div>
        );
      });
      return component;
    } else {
      return;
    }
  };

  const loopZone = (item) => {
    let component = [];
    if (item && item.length > 0) {
      item.forEach((val, i) => {
        component.push(
          <Card
            key={i}
            className={`rounded mb-1 pointer${
              active.find((act) => act.id === val.id) ? " active" : ""
            }`}
            onClick={() => handleActive(val)}
          >
            <Card.Body>{val.name}</Card.Body>
          </Card>
        );
      });
      return component;
    } else {
      return;
    }
  };

  const renderActiveComponent = () => {
    let component = [];
    if (groupActive && groupActive.length > 0) {
      groupActive.forEach((item, index) => {
        component.push(
          <div key={index}>
            <div className="mt-2 mb-2">
              <h6> {item.province}</h6>
            </div>
            {loopActiveZone(item.data)}
          </div>
        );
      });
      return component;
    } else {
      return (
        <Form.Control
          value="Anda Belum Memilih Area"
          readOnly={true}
          style={{ fontSize: "16px", backgroundColor: "#fff" }}
        />
      );
    }
  };

  const loopActiveZone = (item) => {
    let component = [];
    if (item && item.length > 0) {
      item.forEach((val, i) => {
        component.push(
          <div key={i}>
            <InputGroup className="mb-3 pointer" size="lg" key={i}>
              <Form.Control
                value={val.name}
                readOnly={true}
                style={{ fontSize: "16px", backgroundColor: "#fff" }}
              />
              <InputGroup.Text onClick={() => handleDeleteActive(val)}>
                <FaTrashAlt style={{ width: "16px", color: "#bbb" }} />
              </InputGroup.Text>
            </InputGroup>
          </div>
        );
      });
      return component;
    } else {
      return;
    }
  };

  /*======================== UseEffect ======================== */

  useEffect(() => {
    if (area_options.length < 1) {
      dispatch(getMasterArea(auth.access_token));
    }
  }, []);

  useEffect(() => {
    let groupData = null;

    if (area_options && area_options.length > 0) {
      if (search) {
        let data = area_options.filter(
          (item) =>
            item.name.toLowerCase().includes(search.toLowerCase()) ||
            item.mst_province.name.toLowerCase().includes(search.toLowerCase())
        );
        groupData = _.groupBy(data, (item) => item.mst_province.name);

        setArea([...data]);
      } else {
        groupData = _.groupBy(area_options, (item) => item.mst_province.name);
        setArea([...area_options]);
      }
      createArrayGroup(groupData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [area_options, search]);

  useEffect(() => {
    if (formValue.areas && formValue.areas.length > 0) {
      setActive(formValue.areas);
    } else {
      setActive([]);
    }
  }, [formValue]);

  useEffect(() => {
    if (active && active.length > 0) {
      setCheckAll(active.length === area.length);
      createArrayActiveGroup();
    } else {
      setCheckAll(false);
      createArrayActiveGroup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  /*======================== Return ======================== */

  return (
    <Modal
      contentClassName="rounded-card p-2"
      show={isOpen}
      onHide={handleOnClose}
      className="p-3"
      size="lg"
      centered
    >
      <div className="d-flex p-2 border-bottom">
        <h2 className="fw-bold">Pilih Area Kontrak</h2>
        <MdClose className="ms-auto me-2 pointer" onClick={handleOnClose} />
      </div>
      <Modal.Body
      // style={{
      //   maxHeight: "calc(100vh - 210px)",
      //   overflowY: "auto",
      // }}
      >
        <Row>
          <Col
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className={!isMobile ? "pe-3 custom-scroll" : "mb-4 custom-scroll"}
          >
            <InputGroup
              className="border"
              style={{
                top: 0,
                zIndex: 1,
                position: "sticky",
                background: "#fff",
              }}
            >
              <FloatingLabel
                className="rounded-card w-75"
                label={"Cari Area"}
                style={{ width: "", fontSize: "12px", height: "50px" }}
              >
                <Form.Control
                  className="border-0 rounded-card"
                  type="text"
                  style={{ height: "50px" }}
                  placeholder="."
                  onChange={(event) => handleSearch(event)}
                />
              </FloatingLabel>
              <InputGroup.Text
                className="border-0 search-icon-trx rounded-card ms-auto"
                style={{ height: "50px" }}
              >
                <FaSearch />
              </InputGroup.Text>
            </InputGroup>
            <div className="jenis-barang-opts">{renderListComponent()}</div>
          </Col>

          {!isMobile ? (
            <div
              className="p-0"
              style={{
                position: "absolute",
                top: "0px",
                left: "50%",
                transform: "translateX(-50%)",
                height: "100%",
                backgroundColor: "#C0C0C0",
                width: "1px",
              }}
            ></div>
          ) : null}

          <Col
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className={!isMobile ? "ps-3 custom-scroll" : "mt-4 custom-scroll"}
          >
            <div className="jenis-barang-opts">{renderActiveComponent()}</div>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: "space-between" }}>
        <div className="w-100">
          <Row>
            <Col lg={6} md={6} sm={6} xs={12}>
              <div className="w-100 mt-2 float-start">
                <Checkbox onChange={onChangeSelectAll} checked={checkAll}>
                  Pilih Semua
                </Checkbox>
              </div>
            </Col>
            <Col lg={6} md={6} sm={6} xs={12}>
              <Button
                size="sm"
                className="w-100 fw-bold rounded-pill text-capitalize mt-2"
                onClick={handleOnSelect}
              >
                Pilih Area Kontrak ({active.length})
              </Button>
            </Col>
          </Row>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

Area.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  formValue: PropTypes.object,
};

export default Area;
