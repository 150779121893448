import { useState, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Tabs,
  Tab,
  Dropdown,
  Button,
  Badge,
} from "react-bootstrap";
import moment from "moment";
import Area from "./Modal/Area";
import Partner from "./Modal/Partner";
// import "./styles.scss";
import { Editor } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const InformasiUtamaDetail = (props) => {
  const { data, editorState } = props;
  /*======================== Hooks ======================== */

  /*======================== State ======================== */
  const [openPartner, setOpenPartner] = useState(false);
  const [openArea, setOpenArea] = useState(false);

  /*======================== Handler ======================== */

  const handleAreaCounter = (areas) => {
    let count = 0;
    areas.forEach((item) => {
      count += item.area.length;
    });
    return count;
  };

  const handleStatusLabel = (status) => {
    switch (status) {
      case "DRAFT":
        return "Draft";
      case "WAITING SCHEDULE":
        return "Menunggu Jadwal";
      case "CANCEL":
        return "Dibatalkan";
      case "PROCESS":
        return "Proses Penawaran";
      case "PARTNER SELECTION":
        return "Seleksi Partner";
      case "DONE":
        return "Kontrak Selesai";
      default:
        return "";
    }
  };
  const handleStatusColor = (status) => {
    switch (status) {
      case "DRAFT":
      case "PROCESS":
        return "text-orange text-capitalize";
      case "WAITING SCHEDULE":
        return "text-blue text-capitalize";
      case "CANCEL":
        return "text-red text-capitalize";
      case "DONE":
        return "text-green text-capitalize";
      case "PARTNER SELECTION":
        return "text-blue text-capitalize";
      default:
        return "sub-label text-capitalize";
    }
  };

  /*======================== Return ======================== */

  return (
    <div>
      <Row className="procurement-detail-content mt-4">
        <Col xs={12} md={6} className="mt-2">
          <div className="mb-4">
            <h5 className="fw-normal">Status Penawaran</h5>
            <div className="bg-white rounded-card w-full p-3">
              <Row>
                <Col xs={6}>
                  <div className="px-2">
                    <p className="text-muted label mb-0">Status Penawaran</p>
                    <p
                      className={`mb-0 status ${handleStatusColor(
                        data.status
                      )}`}
                    >
                      {handleStatusLabel(data.status)}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="mb-4">
            <h5 className="fw-normal">Informasi Umum</h5>
            <div className="bg-white rounded-card w-full p-3">
              <Row>
                <Col xs={6}>
                  <div className="px-2">
                    <p className="text-muted label mb-0">Nama Penawaran</p>
                    <p className="mb-0">{data.name}</p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="mb-4">
            <h5 className="fw-normal">Tanggal Penawaran & Kontrak</h5>
            <div className="bg-white rounded-card w-full p-3">
              <Row>
                <Col xs={6}>
                  <div className="px-2">
                    <p className="text-muted label mb-0">Rentang Penawaran</p>
                    <p className="mb-0">
                      {moment(data.start_offering_date).format("DD/MM/YYYY")} -{" "}
                      {moment(data.end_offering_date).format("DD/MM/YYYY")}
                    </p>
                  </div>
                </Col>
                <Col xs={6}>
                  <div className="px-2">
                    <p className="text-muted label mb-0">
                      Rentang Tanggal Kontrak
                    </p>
                    <p className="mb-0">
                      {moment(data.start_contract_date).format("DD/MM/YYYY")} -{" "}
                      {moment(data.end_contract_date).format("DD/MM/YYYY")}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="mb-4">
            <h5 className="fw-normal mt-3">Partner & Area Kontrak</h5>
            <div className="bg-white rounded-card w-full p-3">
              <Row>
                <Col xs={6}>
                  <div className="px-2">
                    <p className="text-muted label mb-0">Partner Kontrak</p>
                    <p
                      className="underline mb-0"
                      style={{
                        color: "#CE1771",
                        cursor: "pointer",
                      }}
                      onClick={() => setOpenPartner(true)}
                    >
                      {data.partners.length} Partner Kontrak
                    </p>
                  </div>
                </Col>
                <Col xs={6}>
                  <div className="px-2">
                    <p className="text-muted label mb-0">Area</p>
                    <p
                      className="underline mb-0"
                      style={{
                        color: "#CE1771",
                        cursor: "pointer",
                      }}
                      onClick={() => setOpenArea(true)}
                    >
                      {handleAreaCounter(data.areas)} Area Kontrak
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="mb-4">
            <h5 className="fw-normal mt-3">Metode Pembayaran</h5>
            <div className="bg-white rounded-card w-full p-3">
              <Row>
                <Col xs={6}>
                  <div className="px-2">
                    <p className="text-muted label mb-0">Jenis Pembayaran</p>
                    <p className="mb-0">
                      Tempo ({data?.payment_tenure_data?.tenure} Hari)
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="mb-4">
            <h5 className="fw-normal mt-3">Deskripsi Kontrak</h5>
            <div className="bg-white rounded-card w-full p-3">
              <div className="px-2">
                <p className="mb-0">{data.contract_description}</p>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={12} md={6} className="mt-2">
          <div className="mb-4">
            <h5 className="fw-normal mt-0">Syarat dan Ketentuan</h5>
            <div
              className="bg-white rounded-card w-full p-3"
              style={{ minHeight: "40vh" }}
            >
              <div className="px-2">
                <Editor
                  toolbarHidden
                  readOnly
                  editorState={editorState}
                  toolbarClassName="syarat-toolbar"
                  wrapperClassName="syarat-wrapper"
                  editorClassName="syarat-editor"
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>

      {openPartner && (
        <Partner
          data={data.partners}
          isOpen={openPartner}
          handleClose={() => setOpenPartner(false)}
        />
      )}

      {openArea && (
        <Area
          data={data.areas}
          isOpen={openArea}
          handleClose={() => setOpenArea(false)}
        />
      )}
    </div>
  );
};

export default InformasiUtamaDetail;
