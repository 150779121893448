import React from "react";
import {
  Modal,
  Form,
  InputGroup,
  FloatingLabel,
  Button,
} from "react-bootstrap";

import { MdChevronRight, MdClose } from "react-icons/md";

import withdraw_in_process from "../../assets/images/withdraw_in_process.svg";

const WithdrawInProcess = ({ show, closeModal }) => {
  return (
    <Modal
      contentClassName="rounded-card p-2"
      show={show}
      onHide={closeModal}
      className="p-3"
      centered
    >
      <div className="d-flex p-2">
        <MdClose className="ms-auto me-2 pointer" onClick={closeModal} />
      </div>
      <Modal.Body>
        <div className="d-flex align-items-center justify-content-center flex-column gap-2">
          <img src={withdraw_in_process} alt="" />
          <h3 className="fw-bold">Penarikan Sedang di proses!</h3>
          <h5 className="text-muted">
            ditunggu ya, perlu beberapa saat sampai saldo kamu masuk ke bank!
          </h5>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default WithdrawInProcess;
