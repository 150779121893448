import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Tabs,
  Tab,
  InputGroup,
  FloatingLabel,
  Form,
  Dropdown,
} from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import { IoMenuOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { openSidenavMobile } from "../../components/layouts/layout_helper";
import useBreakpoint from "../../components/responsiveUtils/useBreakpoint";
import useDebounce from "../../hooks/useDebounce";
import { getRoleList } from "../../redux/action/usermanagement_action";
import { getProcurementFilter } from "../../redux/action/Management/procurement_action";
import Kontrak from "./Kontrak";

const Invoices = () => {
  /*======================== Hooks ======================== */

  const { isMobile } = useBreakpoint();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  /*======================== Redux ======================== */

  const auth = useSelector((st) => st.authReducer);
  const kontrakOpts = useSelector((st) => st.procurementReducer.filter.data);

  /*======================== UseState ======================== */

  const [tab, setTab] = useState("kontrak");
  const [search, setSearch] = useState(undefined);
  const debounceSearch = useDebounce(search, 300);

  /*======================== Handler ======================== */

  const handleChange = (type, value) => {
    switch (type) {
      case "tab":
        setTab(value);
        break;
      case "layanan":
        // setLayanan(value);
        break;

      default:
        break;
    }
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  /*======================== UseEffect ======================== */

  useEffect(() => {
    if (location.state?.section) {
      setTab(location.state.section);
      history.replace({ pathname: location.pathname, state: {} });
    }
    dispatch(getRoleList(auth.access_token, "desc"));

    dispatch(
      getProcurementFilter({
        limit: 9999,
        page: 1,
        search: undefined,
        sort_by: "created_at",
        order_by: "DESC",
        is_contract: true,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*======================== Others ======================== */

  const tabOptions = [
    {
      title: "Kontrak Anda",
      value: "kontrak",
    },
  ];

  /*======================== Return ======================== */

  return (
    <div
      style={{ minHeight: "100vh", width: "100%", padding: "30px" }}
      className="bg-ice height-100"
    >
      {!isMobile ? (
        <Card
          className="p-4 rounded-card border-0"
          style={{ backgroundColor: "white" }}
        >
          <h1>Management Invoice & Dokumen</h1>
          <Row className="card-body">
            <Col>
              <Tabs
                // defaultActiveKey={tab}
                activeKey={tab}
                className="tabs-trx mb-2 border-bottom-2"
                onSelect={(value) => handleChange("tab", value)}
              >
                {tabOptions.map((item, index) => (
                  <Tab key={index} eventKey={item.value} title={item.title} />
                ))}
              </Tabs>
            </Col>
          </Row>
          <Row className="ps-4 align-items-center">
            <Col md={6} lg={4}>
              <InputGroup className="border rounded-card">
                <FloatingLabel
                  className="rounded-card w-75"
                  label={"Cari " + tab}
                  style={{ width: "", fontSize: "12px", height: "50px" }}
                >
                  <Form.Control
                    className="border-0 rounded-card"
                    type="text"
                    style={{ height: "50px" }}
                    placeholder={"Cari " + tab}
                    onChange={(event) => handleSearch(event)}
                  />
                </FloatingLabel>
                <InputGroup.Text
                  className="border-0 search-icon-trx rounded-card ms-auto"
                  style={{ height: "50px" }}
                >
                  <FaSearch />
                </InputGroup.Text>
              </InputGroup>
            </Col>
          </Row>
        </Card>
      ) : (
        <div className="p-2 head-menu trx w-100 shadow-md">
          <div
            className="d-flex mb-2 justify-content-center px-2 flex-column"
            style={{ marginTop: "1.7em" }}
          >
            <div className="d-flex">
              <IoMenuOutline
                onClick={openSidenavMobile}
                className="text-secondary pointer me-2"
                style={{ fontSize: 25 }}
              />

              <h4 className="mt-1">Management Invoice & Dokumen</h4>
            </div>
            {/* <Row className="card-body"> */}
            <Col>
              <Tabs
                defaultActiveKey={"rute anda"}
                className="tabs-trx mb-2 border-bottom-2"
                onSelect={(value) => handleChange("tab", value)}
              >
                {tabOptions.map((item, index) => (
                  <Tab key={index} eventKey={item.value} title={item.title} />
                ))}
              </Tabs>
            </Col>
            {/* </Row> */}
            <Row className="d-flex align-items-center">
              <Col xs={8}>
                <InputGroup className="border rounded-card w-100 flex-nowrap">
                  <FloatingLabel
                    className=""
                    label={"Cari " + tab}
                    style={{ fontSize: "12px", height: "50px" }}
                  >
                    <Form.Control
                      className="border-0 rounded-card"
                      type="text"
                      style={{ height: "50px" }}
                      placeholder={"Cari " + tab}
                      onChange={(event) => handleSearch(event)}
                    />
                  </FloatingLabel>
                  <InputGroup.Text
                    className="border-0 search-icon-trx rounded-card ms-auto"
                    style={{ height: "50px" }}
                  >
                    <FaSearch />
                  </InputGroup.Text>
                </InputGroup>
              </Col>
              {/* </Row> */}
            </Row>
          </div>
        </div>
      )}
      {tab === "kontrak" && <Kontrak search={debounceSearch} kontrakOpts={kontrakOpts} />}
    </div>
  );
};

export default Invoices;
