/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";
import { Dropdown, Button, Tabs, Tab } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import noData from "../../../assets/images/no-data.png";
import Table from "../../../components/table/customTable";
import { getRuteListByName, createBulkRute, downloadSample } from "../../../redux/action/RuteAlamat/rute_action";
import UploadBulk from "./Form/Modal/UploadBulk";
import ErrorUploadBulk from "./Form/Modal/ErrorUploadBulk";
import { saveAs } from 'file-saver'


const Rute = (props) => {
  /*======================== Props ======================== */

  const { search } = props;

  /*======================== Hooks  ======================== */

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  /*======================== Redux ======================== */

  const dataRute = useSelector((st) => st.ruteReducer.data);
  const meta = useSelector((st) => st.ruteReducer.meta);
  const loading = useSelector((st) => st.ruteReducer.loading);

  /*======================== UseState ======================== */

  const [params, setParams] = useState({
    limit: 9999,
    page: 1,
    sort: "DESC",
  });
  const [sort, setSort] = useState("Terbaru");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [data, setData] = useState(dataRute || []);
  const [status, setStatus] = useState(undefined)
  const [optionsCreateRute, setOptionsCreateRute] = useState(false)
  const [modalUploadBulk, setModalUploadBulk] = useState(false)
  const [modalErorrBulk, setModalErorrBulk] = useState(false)
  const [errorBulkMessage, setErrorBulkMessage] = useState("")

  /*======================== Handler ======================== */

  const handleMouseOver = () => {
    setOptionsCreateRute(true)
  }

  const handleOnMouseOut = () => {
    setTimeout(()=>{
      setOptionsCreateRute(false)
    },3000)
  }

  const handleCloseModalBulk = () => {
    setModalUploadBulk(false)
    // setModalErorrBulk(false)
  }

  const handleCloseModalErrBulk = () => {
    setModalErorrBulk(false)
  }

  const handleChange = (type, value) => {
    switch (type) {
      case "sort":
        dispatch(
          getRuteListByName({
            limit: 9999,
            page: 1,
            sort: value === "Terbaru" ? "DESC" : "ASC",
          })
        );
        setPage(1);
        setSort(value);
        break;
      case "page":
        setPage(value);
        setParams({ ...params, page: value });
        break;
      case "limit":
        setPage(1);
        setLimit(value);
        setParams({ ...params, limit: value, page: 1 });
        break;
        
      case "status":
        dispatch(
          getRuteListByName({
            ...params,
            is_completed: value
          })
        );
        setPage(1);
        // setSort(value);
        // setPage(1);
        // setStatus(value);
        // setParams({ ...params, is_completed: value, page: 1 });
        break;

      default:
        break;
    }
  };

  const handleShowPickup = (pick_up_address) => {
    const tempPickup = [];
    pick_up_address.forEach((item) => {
      if (tempPickup.findIndex((itemIdx) => itemIdx.id === item.id) === -1) {
        tempPickup.push(item);
      }
    });
    return (
      <>
        <p className="mb-0">
          {pick_up_address[0]?.code} - {pick_up_address[0]?.name} 
          {/* {pick_up_address[0].code} - {pick_up_address[0].name}  */}
        </p>
        {tempPickup.length > 1 && (
          <span className="alamat-more">
            +{tempPickup.length - 1} Alamat Lainnya
          </span>
        )}
      </>
    );
  };

  const handleTotalAddress = ({ pick_up_address, delivery_address }) => {
    let total = 0;
    const tempPickup = [];
    // counting pickup add
    pick_up_address.forEach((item) => {
      if (tempPickup.findIndex((itemIdx) => itemIdx === item.id) === -1) {
        tempPickup.push(item.id);
      }
    });
    // counting deliv add
    delivery_address.forEach((item) => {
      if (tempPickup.findIndex((itemIdx) => itemIdx === item.id) === -1) {
        tempPickup.push(item.id);
      }
    });
    total += tempPickup.length;
    return total + " Alamat";
  };

  /*======================== UseEffect ======================== */

  useEffect(() => {
    dispatch(getRuteListByName({ ...params }));
  }, []);

  // useEffect(() => {
  //   if (search) {
  //     setPage(1);
  //     setData([
  //       ...dataRute
  //         .filter(
  //           (item) =>
  //             item.name.toLowerCase().includes(search.toLowerCase()) ||
  //             item.route_code.toLowerCase().includes(search.toLowerCase())
  //         )
  //         .slice((page - 1) * limit, limit * page),
  //     ]);
  //     // dispatch(getRuteListByName({ ...params, search, page: 1 }));
  //   } else {
  //     setData([...dataRute.slice((page - 1) * limit, limit * page)]);
  //     // dispatch(getRuteListByName({ ...params }));
  //   }
  // }, [dataRute]);

  const handleDownloadSampeBulkUpload = async(payload) => {
    const response = await dispatch(downloadSample())
    console.log("ini response",response)
    const buffer = await response.blob()
	    const blob = new Blob([buffer], {
		    // type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		    type: 'text/csv;charset=utf-8',
	    })  
    	saveAs(blob, "Sampe upload Rute")
  }

  const handleSubmitCreateBulk = async(payload) => {
    const response = await dispatch(createBulkRute(payload))
    if (response?.status === "success") {
      dispatch(
        getRuteListByName({
          limit: 9999,
          page: 1,
          sort: "DESC"
        })
      );
    }else{
      setModalErorrBulk(true)
      setErrorBulkMessage(response?.data?.message)
    }
    handleCloseModalBulk()
  }

  useEffect(() => {
    if (dataRute.length > 0) {
      if (search) {
        setPage(1);
        setData([
          ...dataRute
            .filter(
              (item) =>
                item.name.toLowerCase().includes(search.toLowerCase()) ||
                item.route_code.toLowerCase().includes(search.toLowerCase())
            )
            .slice((page - 1) * limit, limit * page),
        ]);
        // dispatch(getRuteListByName({ ...params, search, page: 1 }));
      } else {
        setData([...dataRute.slice((page - 1) * limit, limit * page)]);
        // dispatch(getRuteListByName({ ...params }));
      }
    }
  }, [dataRute, params, search]);

  /*======================== Others ======================== */
  const statusOptions = [
    {
      title: "Semua",
      value: "",
    },
    {
      title: "Lengkap",
      value: 1,
    },
    {
      title: "Belum Lengkap",
      value: 0,
    }
  ];
  const sortOptions = [
    {
      title: "Terbaru",
    },
    {
      title: "Terlama",
    },
  ];
  const tableProps = {
    bordered: false,
    expandable: false,
    title: undefined,
    showHeader: true,
    rowSelection: false,
  };
  const tableColumns = [
    {
      title: "No",
      dataIndex: "no",
      sorter: false,
      className: "text-center px-3",
    },
    {
      title: "Kode Rute",
      dataIndex: "route_code",
      sorter: false,
      className: "px-3",
    },
    {
      title: "Nama Rute",
      dataIndex: "name",
      sorter: false,
      className: "px-3",
    },
    {
      title: "Total Titik",
      sorter: false,
      className: "px-3",
      render: (_, { pick_up_address, delivery_address }) => {
        // console.log("PICK UP ADDRESS === > ",pick_up_address)
        // console.log("DELIVERY ADDRESS === > ",delivery_address)
        return (
          <span>
            {handleTotalAddress({ pick_up_address, delivery_address })}
          </span>
        );
      },
    },
    {
      title: "Alamat Pengambilan",
      sorter: false,
      className: "px-3",
      render: (_, { pick_up_address }) => {
        return <>{handleShowPickup(pick_up_address)}</>;
      },
    },
    {
      title: "Alamat Pengiriman",
      sorter: false,
      className: "px-3",
      render: (_, { delivery_address }) => {
        return (
          <>
            <p className="mb-0">
              {delivery_address[0]?.code} - {delivery_address[0]?.name}
              {/* {delivery_address[0].code} - {delivery_address[0].name} */}
            </p>
            {delivery_address.length > 1 && (
              <span className="alamat-more">
                +{delivery_address.length - 1} Alamat Lainnya
              </span>
            )}
          </>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      sorter: false,
      className: "px-3",
      render: (_, { route_code, is_completed }) => {
        return (
          <div className="d-flex justify-content-end">
            <button
              hidden={is_completed}
              size="sm"
              className="fw-bold px-4 me-2 rounded btn-outline-primary"
              style={{backgroundColor:"white"}}
              onClick={() =>
                history.push(location.pathname + "/lengkapi-rute/" + route_code)
              }
            >
              Lengkapi
            </button>
            <Button
              size="sm"
              className="fw-bold px-4 text-capitalize"
              onClick={() =>
                history.push(location.pathname + "/tambah-rute/" + route_code)
              }
            >
              Detail
            </Button>
          </div>
        );
      },
    },
  ];

  /*======================== Return ======================== */

  return (
    <>
      <div className="mt-4">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h2 className="fw-bold text-capitalize">Daftar Rute Anda</h2>
            <p>List rute anda untuk memudahkan anda melakukan order</p>
          </div>
          <div className="position-relative">
            <Button
              size="sm"
              className="fw-bold py-2 px-4 rounded-pill text-capitalize"
              // onMouseOver={()=>handleMouseOver()}
              // onMouseOut={()=>handleOnMouseOut()}
              onClick={()=> setOptionsCreateRute(!optionsCreateRute)}
            >
              Tambahkan rute anda
            </Button>
            {optionsCreateRute && (

              <div  
                className="position-absolute bg-white w-100 border p-2 px-3" 
                style={{zIndex:"10", top:45, borderRadius:"10px"}}
              >
                <h5 className="p-0 m-0 text-right pointer" onClick={()=> {setModalUploadBulk(true); setOptionsCreateRute(false)}}>Upload Data Rute</h5>
                <hr className="my-2"/>
                <h5 className="p-0 m-0 text-right pointer" onClick={() => history.push(location.pathname + "/tambah-rute")}>Buat Rute Manual</h5>
              </div>
            )}
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between mb-3">
          <div className="d-flex" style={{ gap: "1rem" }}>
            <span className="fw-bold" style={{ width: "auto" }}>
              Tampilkan:
            </span>
            <Tabs
              defaultActiveKey={status}
              className="tabs-rute mb-2 border-0"
              style={{ gap: "10px" }}
              onSelect={(value) => handleChange("status", value)}
            >
              {statusOptions.map((item, index) => (
                <Tab key={index} eventKey={item.value} title={item.title} />
              ))}
            </Tabs>
          </div>
        
          <div className="d-flex align-items-center">
            <h4 className="mt-2 me-2" style={{ fontWeight: "600" }}>
              Urutkan Berdasarkan :
            </h4>
            <Dropdown className="d-inline mx-2">
              <Dropdown.Toggle
                id="dropdown-autoclose-true"
                size="sm"
                variant="light"
                className="bg-white text-secondary px-3 border shadow-sm rounded"
              >
                <strong>{sort}</strong>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {sortOptions.map((item, index) => (
                  <Dropdown.Item
                    key={index}
                    href="#"
                    onClick={() => {
                      handleChange("sort", item.title);
                    }}
                  >
                    {item.title}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
      {/* with data */}
      {data.length > 0 ? (
        <div className="mt-2">
          <Table
            classProps="table-rute"
            {...{
              tableProps,
              tableColumns,
              handleChange,
              limit,
              page,
              loading,
              data,
            }}
            total_data={meta.total_data}
          />
        </div>
      ) : (
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{ marginTop: "5rem" }}
        >
          <img src={noData} alt="no-data" />
          <p className="mt-3">
            {search
              ? "Rute yang anda cari tidak ditemukan"
              : "Anda belum memiliki rute"}
          </p>
          {!search && (
            <Button
              size="sm"
              className="fw-bold py-2 px-4 rounded-pill text-capitalize"
              onClick={() => history.push(location.pathname + "/tambah-rute")}
            >
              Tambahkan rute anda
            </Button>
          )}
        </div>
      )}
      <UploadBulk
        show={modalUploadBulk}
        handleClose={handleCloseModalBulk}
        submitDownload={handleDownloadSampeBulkUpload}
        submit={handleSubmitCreateBulk}
      />
      <ErrorUploadBulk
        show={modalErorrBulk}
        handleClose={handleCloseModalErrBulk}
        message={errorBulkMessage}
      />
    </>
  );
};

Rute.propTypes = {
  search: PropTypes.string,
};

export default Rute;
