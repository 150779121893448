
import { useState } from 'react'
import { Modal, } from "react-bootstrap";
import { FaDownload } from "react-icons/fa";

const prfefix_s3 = process.env.REACT_APP_PREFIX_S3

const ShowDokumen = ({ show, handleClose, image }) => {
    const [downloading, setDownloading] = useState(false)

	const downloadFile = async () => {
		setDownloading(true)
		try {
			const response = await fetch(image)
			const blob = await response.blob()
			const url = window.URL.createObjectURL(blob)
			const a = document.createElement('a')
			a.href = url
			a.download = image?.endsWith('.pdf')
				? 'bukti-pembayaran.pdf'
				: 'bukti-pembayaran.jpg'
			a.style.display = 'none'
			document.body.appendChild(a)
			a.click()
			window.URL.revokeObjectURL(url)
		} catch (error) {
			console.error('Error downloading file:', error)
		} finally {
			setDownloading(false)
		}
	}
  return (
    <Modal
      contentClassName="rounded-card p-2"
      show={show}
      onHide={handleClose}
      centered
      size='lg'
    >
        <Modal.Header closeButton className="bg-white">
            <strong>Preview Dokumen</strong>
        </Modal.Header>
        <div className="p-2 d-flex justify-content-center" style={{maxHeight:"600px", overflowY:"scroll"}}>
          {image && image?.split('.')[image?.split('.')?.length - 1] === "pdf" &&
            <iframe
                title="pdf file"
                src={prfefix_s3+image.split(".co.id/pdf")[1]}
                width="100%"
                height="600px"
            />
          }
          {image && image?.split('.')[image?.split('.')?.length - 1] !== "pdf" &&
            <img className="img-fluid rounded" src={image} alt="no-data"/>
          }
        </div>
        <Modal.Footer>
          <div className="w-100 d-flex justify-content-end">
            <div className="d-flex align-items-center gap-1" onClick={downloadFile}>
                <FaDownload className="text-primary"/>
                {downloading ? (
                    'Downloading ...'
                ) : (
                <h5
                  size="sm"
                  className="m-0 text-primary pointer fw-bold"
                >
                  Download
                </h5>
                )}
              </div>
          </div>
        </Modal.Footer>
    </Modal>
  )
}

export default ShowDokumen