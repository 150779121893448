import React, {useState, useEffect} from "react";
import {
    Modal,
    Row, 
    Col
} from "react-bootstrap";
import { message } from "antd";

import { BsPlusLg, BsChevronLeft } from "react-icons/bs";
import LableUploadPhoto from "../LableUploadPhoto";


function ModalDokumentasiPenyerahan({
    payloadSelesai,
    setPayloadSelesai,
    show,
    handleClose,
    submit
}) {

    const [form, setForm] = useState({
		step_1: true,
		step_2: false,
		step_3: false,
        step_4: false,
        step_5: false
	})

    const [exteriorDepan, setExteriorDepan] = useState()
    const [exteriorBelakang, setExteriorBelakang] = useState()
    const [exteriorKiri, setExteriorKiri] = useState()
    const [exteriorKanan, setExteriorKanan] = useState()
    
    const [interiorDepan, setInteriorDepan] = useState()
    const [interiorBelakang, setInteriorBelakang] = useState()
    const [interiorKiri, setInteriorKiri] = useState()
    const [interiorKanan, setInteriorKanan] = useState()

    const handleNextStep = () => {
        if (form.step_1) {
            setForm({
                step_1: false,
                step_2: true,
                step_3: false,
                step_4: false,
                step_5: false,
            })
        } else if (form.step_2) {
            setForm({
                step_1: false,
                step_2: false,
                step_3: true,
                step_4: false,
                step_5: false,
            })
        } else if (form.step_3) {
            setForm({
                step_1: false,
                step_2: false,
                step_3: false,
                step_4: true,
                step_5: false,
            })
        } else if (form.step_4) {
            setForm({
                step_1: false,
                step_2: false,
                step_3: false,
                step_4: false,
                step_5: true,
            })
        } else if (form.step_5) submit("dokumentasi")
    }

    const handlePrevStep = () => {
        if (form.step_2) {
            setForm({
                step_1: true,
                step_2: false,
                step_3: false,
                step_4: false,
                step_5: false,
            })
        } else if (form.step_3) {
            setForm({
                step_1: false,
                step_2: true,
                step_3: false,
                step_4: false,
                step_5: false,
            })
        } else if (form.step_4) {
            setForm({
                step_1: false,
                step_2: false,
                step_3: true,
                step_4: false,
                step_5: false,
            })
        } else if (form.step_5) {
            setForm({
                step_1: false,
                step_2: false,
                step_3: false,
                step_4: true,
                step_5: false,
            })
        }
    }

    const handleReset = () => {
        setPayloadSelesai({
            is_skip : 0,
            received_by : null,
            odometer : null,
            fuel : null,
            photo_interior : [],
            photo_exterior : [],
            receiver_photo : null,
            photo_odometer : null
        })
        setForm({
            step_1: true,
            step_2: false,
            step_3: false,
            step_4: false,
            step_5: false
        })
        setExteriorDepan()
        setExteriorBelakang()
        setExteriorKiri()
        setExteriorKanan()

        setInteriorDepan()
        setInteriorBelakang()
        setInteriorKiri()
        setInteriorKanan()
    }

    const onSelectFile = (e, key, etc) => {
        if (!e.target.files || e.target.files.length === 0) {
            console.log(e.target.files)
        }
        if (e.target.files[0].size > 5000000) {
            message.error("Ukuran Maksimal foto adalah 5Mb");
        } else {
            if(key === "photo_exterior" || key === "photo_interior"){
                if(etc=== "exteriorDepan") setExteriorDepan(e.target.files[0])
                if(etc=== "exteriorBelakang") setExteriorBelakang(e.target.files[0])
                if(etc=== "exteriorKiri") setExteriorKiri(e.target.files[0])
                if(etc=== "exteriorKanan") setExteriorKanan(e.target.files[0])

                if(etc=== "interiorDepan") setInteriorDepan(e.target.files[0])
                if(etc=== "interiorBelakang") setInteriorBelakang(e.target.files[0])
                if(etc=== "interiorKiri") setInteriorKiri(e.target.files[0])
                if(etc=== "interiorKanan") setInteriorKanan(e.target.files[0])

                setPayloadSelesai({...payloadSelesai, [key]:[...payloadSelesai[key], e.target.files[0]]})
            }else{
                setPayloadSelesai({...payloadSelesai, [key]:e.target.files[0]})
            }
        }
    };


    return<>  
        <Modal
            contentClassName="rounded-card p-2"
            show={show}
            onHide={handleClose}
            centered
        >
            <Modal.Header closeButton className="bg-white">
                <strong>{form.step_5 ? "Konfirmasi Penyerahan Kendaraan" : "Dokumentasi Penyerahan Kendaraan"}</strong>
            </Modal.Header>

            <div className="p-4 m-0" style={{maxHeight:"500px", overflowY:"scroll", overflowX:"hidden"}}>
                <div className="">
                    <h6 className="fw-bold mb-2">
                        {form.step_1 && "Step 1. Penerima Kendaraan"}
                        {form.step_2 && "Step 2. Eksterior Kendaraan"}
                        {form.step_3 && "Step 3. Interior Kendaraan"}
                        {form.step_4 && "Step 4. Odometer Bensin"}
                    </h6>
                    {!form.step_5 && 
                    <div className="d-flex">
                        <div className="rounded-pill me-1" style={{width:"40px", height:"7px", backgroundColor:"#209AB7"}}></div>
                        <div className="rounded-pill me-1" style={{width:"40px", height:"7px", backgroundColor:`${form.step_2 || form.step_3 || form.step_4 ? "#209AB7":"#E2E5E8"}`}}></div>
                        <div className="rounded-pill me-1" style={{width:"40px", height:"7px", backgroundColor:`${form.step_3 || form.step_4 ? "#209AB7":"#E2E5E8"}`}}></div>
                        <div className="rounded-pill me-1" style={{width:"40px", height:"7px", backgroundColor:`${form.step_4 ? "#209AB7":"#E2E5E8"}`}}></div>
                    </div>
                    }
                </div>
                {!form.step_5 && 
                    <div className="border-bottom my-3"></div>
                }
                {form.step_1 &&
                <div>
                    <Row className="mb-3">
                        <Col>
                            <h6 className="fw-bold mb-2">Foto Penerima & Kendaraan</h6>
                            {payloadSelesai?.receiver_photo ?
                            <div className="d-flex justify-content-center flex-column align-items-center pointer" style={{borderRadius:"10px", height:"100px", overflow:"hidden"}}>
                                <img
                                    src={URL.createObjectURL(payloadSelesai?.receiver_photo)}
                                    alt="receiver_photo"
                                    style={{ cursor: "pointer", width: "100%", maxWidth: "200px" }}
                                />
                            </div> :
                            <label className="d-flex justify-content-center flex-column align-items-center pointer" style={{border:"1px dotted #FF1D8C", borderRadius:"10px", height:"100px"}}>
                                <BsPlusLg className="mb-2" style={{color:"#FF1D8C", marginTop:"25px"}}/>
                                <h6 className="m-0" style={{color:"#FF1D8C"}}>Tambah Foto</h6>
                                <input type="file"style={{ visibility: "hidden", width:"50px" }} onChange={(e)=>onSelectFile(e,"receiver_photo")}  ></input>
                            </label>
                            }
                        </Col>
                        <Col></Col>
                        <LableUploadPhoto/>
                    </Row>
                    <Row>
                        <Col>
                            <h6 className="fw-bold mb-2">Nama Penerima</h6>
                            <div className="d-flex justify-content-center flex-column align-items-center pointer overflow-hidden" style={{border:"1px solid #BBBBBB", borderRadius:"5px",overflow:"hidden"}}>
                                <input className="w-100 p-2" placeholder="Masukan nama penerima" type="text" style={{border:"none", outline:"none"}} value={payloadSelesai.received_by} onChange={(e)=> setPayloadSelesai({...payloadSelesai, received_by: e.target.value})}/>
                            </div>
                        </Col>
                    </Row>
                </div>
                }
                {form.step_2 &&
                <div>
                    <Row className="mb-3">
                        <Col>
                            <h6 className="fw-bold mb-2">Eksterior Depan</h6>
                            {exteriorDepan ?
                            <div className="d-flex justify-content-center flex-column align-items-center pointer" style={{borderRadius:"10px", height:"100px", overflow:"hidden"}}>
                                <img
                                    src={URL.createObjectURL(exteriorDepan)}
                                    alt="receiver_photo"
                                    style={{ cursor: "pointer", width: "100%", maxWidth: "200px" }}
                                />
                            </div> :
                            <label className="d-flex justify-content-center flex-column align-items-center pointer" style={{border:"1px dotted #FF1D8C", borderRadius:"10px", height:"100px"}}>
                                    <BsPlusLg className="mb-2" style={{color:"#FF1D8C", marginTop:"25px"}}/>
                                    <h6 className="m-0" style={{color:"#FF1D8C"}}>Tambah Foto</h6>
                                    <input multiple type="file"style={{ visibility: "hidden", width:"50px" }} onChange={(e)=>onSelectFile(e,"photo_exterior", "exteriorDepan")} ></input>
                            </label>
                            }
                        </Col>
                        <Col>
                            <h6 className="fw-bold mb-2">Eksterior Belakang</h6>
                            {exteriorBelakang ?
                            <div className="d-flex justify-content-center flex-column align-items-center pointer" style={{borderRadius:"10px", height:"100px", overflow:"hidden"}}>
                                <img
                                    src={URL.createObjectURL(exteriorBelakang)}
                                    alt="receiver_photo"
                                    style={{ cursor: "pointer", width: "100%", maxWidth: "200px" }}
                                />
                            </div> :
                            <label className="d-flex justify-content-center flex-column align-items-center pointer" style={{border:"1px dotted #FF1D8C", borderRadius:"10px", height:"100px"}}>
                                    <BsPlusLg className="mb-2" style={{color:"#FF1D8C", marginTop:"25px"}}/>
                                    <h6 className="m-0" style={{color:"#FF1D8C"}}>Tambah Foto</h6>
                                    <input multiple type="file"style={{ visibility: "hidden", width:"50px" }} onChange={(e)=>onSelectFile(e,"photo_exterior", "exteriorBelakang")} ></input>
                            </label>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h6 className="fw-bold mb-2">Eksterior Samping Kiri</h6>
                            {exteriorKiri ?
                            <div className="d-flex justify-content-center flex-column align-items-center pointer" style={{borderRadius:"10px", height:"100px", overflow:"hidden"}}>
                                <img
                                    src={URL.createObjectURL(exteriorKiri)}
                                    alt="receiver_photo"
                                    style={{ cursor: "pointer", width: "100%", maxWidth: "200px" }}
                                />
                            </div> :
                            <label className="d-flex justify-content-center flex-column align-items-center pointer" style={{border:"1px dotted #FF1D8C", borderRadius:"10px", height:"100px"}}>
                                    <BsPlusLg className="mb-2" style={{color:"#FF1D8C", marginTop:"25px"}}/>
                                    <h6 className="m-0" style={{color:"#FF1D8C"}}>Tambah Foto</h6>
                                    <input multiple type="file"style={{ visibility: "hidden", width:"50px" }} onChange={(e)=>onSelectFile(e,"photo_exterior","exteriorKiri")} ></input>
                            </label>
                            }
                        </Col>
                        <Col>
                            <h6 className="fw-bold mb-2">Eksterior Samping Kanan</h6>
                            {exteriorKanan ?
                            <div className="d-flex justify-content-center flex-column align-items-center pointer" style={{borderRadius:"10px", height:"100px", overflow:"hidden"}}>
                                <img
                                    src={URL.createObjectURL(exteriorKanan)}
                                    alt="receiver_photo"
                                    style={{ cursor: "pointer", width: "100%", maxWidth: "200px" }}
                                />
                            </div> :
                            <label className="d-flex justify-content-center flex-column align-items-center pointer" style={{border:"1px dotted #FF1D8C", borderRadius:"10px", height:"100px"}}>
                                    <BsPlusLg className="mb-2" style={{color:"#FF1D8C", marginTop:"25px"}}/>
                                    <h6 className="m-0" style={{color:"#FF1D8C"}}>Tambah Foto</h6>
                                    <input multiple type="file"style={{ visibility: "hidden", width:"50px" }} onChange={(e)=>onSelectFile(e,"photo_exterior","exteriorKanan")} ></input>
                            </label>
                            }
                        </Col>
                        <LableUploadPhoto/>
                    </Row>
                </div>
                }
                {form.step_3 &&
                <div>
                    <Row className="mb-3">
                        <Col>
                            <h6 className="fw-bold mb-2">Interior Depan</h6>
                            {interiorDepan ?
                            <div className="d-flex justify-content-center flex-column align-items-center pointer" style={{borderRadius:"10px", height:"100px", overflow:"hidden"}}>
                                <img
                                    src={URL.createObjectURL(interiorDepan)}
                                    alt="interiorDepan"
                                    style={{ cursor: "pointer", width: "100%", maxWidth: "200px" }}
                                />
                            </div> :
                            <label className="d-flex justify-content-center flex-column align-items-center pointer" style={{border:"1px dotted #FF1D8C", borderRadius:"10px", height:"100px"}}>
                                <BsPlusLg className="mb-2" style={{color:"#FF1D8C", marginTop:"25px"}}/>
                                <h6 className="m-0" style={{color:"#FF1D8C"}}>Tambah Foto</h6>
                                <input type="file"style={{ visibility: "hidden", width:"50px" }} onChange={(e)=>onSelectFile(e,"photo_interior", "interiorDepan")} ></input>
                            </label>
                            }
                        </Col>
                        <Col>
                            <h6 className="fw-bold mb-2">Interior Belakang</h6>
                            {interiorBelakang ?
                            <div className="d-flex justify-content-center flex-column align-items-center pointer" style={{borderRadius:"10px", height:"100px", overflow:"hidden"}}>
                                <img
                                    src={URL.createObjectURL(interiorBelakang)}
                                    alt="interiorBelakang"
                                    style={{ cursor: "pointer", width: "100%", maxWidth: "200px" }}
                                />
                            </div> :
                            <label className="d-flex justify-content-center flex-column align-items-center pointer" style={{border:"1px dotted #FF1D8C", borderRadius:"10px", height:"100px"}}>
                                <BsPlusLg className="mb-2" style={{color:"#FF1D8C", marginTop:"25px"}}/>
                                <h6 className="m-0" style={{color:"#FF1D8C"}}>Tambah Foto</h6>
                                <input type="file"style={{ visibility: "hidden", width:"50px" }} onChange={(e)=>onSelectFile(e,"photo_interior","interiorBelakang")} ></input>
                            </label>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h6 className="fw-bold mb-2">Interior Samping Kiri</h6>
                            {interiorKiri ?
                            <div className="d-flex justify-content-center flex-column align-items-center pointer" style={{borderRadius:"10px", height:"100px", overflow:"hidden"}}>
                                <img
                                    src={URL.createObjectURL(interiorKiri)}
                                    alt="interiorKiri"
                                    style={{ cursor: "pointer", width: "100%", maxWidth: "200px" }}
                                />
                            </div> :
                            <label className="d-flex justify-content-center flex-column align-items-center pointer" style={{border:"1px dotted #FF1D8C", borderRadius:"10px", height:"100px"}}>
                                <BsPlusLg className="mb-2" style={{color:"#FF1D8C", marginTop:"25px"}}/>
                                <h6 className="m-0" style={{color:"#FF1D8C"}}>Tambah Foto</h6>
                                <input type="file"style={{ visibility: "hidden", width:"50px" }} onChange={(e)=>onSelectFile(e,"photo_interior","interiorKiri")} ></input>
                            </label>
                            }
                        </Col>
                        <Col>
                            <h6 className="fw-bold mb-2">Interior Samping Kanan</h6>
                            {interiorKanan ?
                            <div className="d-flex justify-content-center flex-column align-items-center pointer" style={{borderRadius:"10px", height:"100px", overflow:"hidden"}}>
                                <img
                                    src={URL.createObjectURL(interiorKanan)}
                                    alt="interiorKanan"
                                    style={{ cursor: "pointer", width: "100%", maxWidth: "200px" }}
                                />
                            </div> :
                            <label className="d-flex justify-content-center flex-column align-items-center pointer" style={{border:"1px dotted #FF1D8C", borderRadius:"10px", height:"100px"}}>
                                <BsPlusLg className="mb-2" style={{color:"#FF1D8C", marginTop:"25px"}}/>
                                <h6 className="m-0" style={{color:"#FF1D8C"}}>Tambah Foto</h6>
                                <input type="file"style={{ visibility: "hidden", width:"50px" }} onChange={(e)=>onSelectFile(e,"photo_interior","interiorKanan")} ></input>
                            </label>
                            }
                        </Col>
                        <LableUploadPhoto/>
                    </Row>
                </div>
                }
                {form.step_4 &&
                <div>
                    <Row className="mb-3">
                        <Col>
                            <h6 className="fw-bold mb-2">Foto Odometer</h6>
                            {payloadSelesai?.photo_odometer ?
                            <div className="d-flex justify-content-center flex-column align-items-center pointer" style={{borderRadius:"10px", height:"100px", overflow:"hidden"}}>
                                <img
                                    src={URL.createObjectURL(payloadSelesai?.photo_odometer)}
                                    alt="photo_odometer"
                                    style={{ cursor: "pointer", width: "100%", maxWidth: "200px" }}
                                />
                            </div> :
                            <label className="d-flex justify-content-center flex-column align-items-center pointer" style={{border:"1px dotted #FF1D8C", borderRadius:"10px", height:"100px"}}>
                                <BsPlusLg className="mb-2" style={{color:"#FF1D8C", marginTop:"25px"}}/>
                                <h6 className="m-0" style={{color:"#FF1D8C"}}>Tambah Foto</h6>
                                <input type="file"style={{ visibility: "hidden", width:"50px" }} onChange={(e)=>onSelectFile(e,"photo_odometer")}  ></input>
                            </label>
                            }
                        </Col>
                        <Col></Col>
                        <LableUploadPhoto/>
                    </Row>
                    <Row>
                        <Col>
                            <h6 className="fw-bold mb-2">Jarak Tempuh</h6>
                            <div className="d-flex justify-content-center flex-column align-items-center pointer overflow-hidden" style={{border:"1px solid #BBBBBB", borderRadius:"5px",overflow:"hidden"}}>
                            <input className="w-100 p-2" placeholder="Masukan Jarak Tempuh" type="number" style={{border:"none", outline:"none"}} value={payloadSelesai.odometer} onChange={(e)=> setPayloadSelesai({...payloadSelesai, odometer: e.target.value})}/>
                            </div>
                        </Col>
                        <Col>
                            <h6 className="fw-bold mb-2">Bar Bensin</h6>
                            <div className="d-flex justify-content-center flex-column align-items-center pointer overflow-hidden" style={{border:"1px solid #BBBBBB", borderRadius:"5px",overflow:"hidden"}}>
                            <input className="w-100 p-2" placeholder="Masukan Bar Bensin" type="number" style={{border:"none", outline:"none"}} value={payloadSelesai.fuel} onChange={(e)=> setPayloadSelesai({...payloadSelesai, fuel: e.target.value})}/>
                            </div>
                        </Col>
                    </Row>
                </div>
                }
                {form.step_5 &&
                <div>
                    <h6 className="m-0">Penerima Kendaraan</h6>
                    <div className="border-bottom my-3"></div>
                    <Row className="mb-3">
                        <Col>
                            <h6 className="fw-bold mb-2">Foto Penerima & Kendaraan</h6>
                            <h6 className="fw-bold mb-2">Foto Odometer</h6>
                            {payloadSelesai?.receiver_photo ?
                            <div className="d-flex justify-content-center flex-column align-items-center pointer" style={{borderRadius:"10px", height:"100px", overflow:"hidden"}}>
                                <img
                                    src={URL.createObjectURL(payloadSelesai?.receiver_photo)}
                                    alt="photo_odometer"
                                    style={{ cursor: "pointer", width: "100%", maxWidth: "200px" }}
                                />
                            </div> :
                            <div className="d-flex justify-content-center flex-column align-items-center" style={{border:"1px solid #BBBBBB", borderRadius:"10px", height:"100px"}}>
                                <BsPlusLg className="mb-2" style={{color:"#BBBBBB"}}/>
                                <h6 className="m-0" style={{color:"#BBBBBB"}}>Tidak ada Foto</h6>
                            </div>
                            }
                        </Col>
                        <Col>
                            <div className="mt-4 mb-4">
                                <h6 className="fw-bold mb-2">Nama Penerima</h6>
                                <h6 className="">{payloadSelesai?.received_by}</h6>
                            </div>
                        </Col>
                    </Row>
                    <div className="border-bottom my-3"></div>
                    <h6 className="m-0">Interior Kendaraan</h6>
                    <div className="border-bottom my-3"></div>
                    <Row className="mb-3">
                        <Col>
                            <h6 className="fw-bold mb-2">Interior Depan</h6>
                            {interiorDepan ?
                            <div className="d-flex justify-content-center flex-column align-items-center pointer" style={{borderRadius:"10px", height:"100px", overflow:"hidden"}}>
                                <img
                                    src={URL.createObjectURL(interiorDepan)}
                                    alt="interiorDepan"
                                    style={{ cursor: "pointer", width: "100%", maxWidth: "200px" }}
                                />
                            </div> :
                            <div className="d-flex justify-content-center flex-column align-items-center" style={{border:"1px solid #BBBBBB", borderRadius:"10px", height:"100px"}}>
                                <BsPlusLg className="mb-2" style={{color:"#BBBBBB"}}/>
                                <h6 className="m-0" style={{color:"#BBBBBB"}}>Tidak ada foto</h6>
                            </div>
                            }
                        </Col>
                        <Col>
                            <h6 className="fw-bold mb-2">Interior Belakang</h6>
                            {interiorBelakang ?
                            <div className="d-flex justify-content-center flex-column align-items-center pointer" style={{borderRadius:"10px", height:"100px", overflow:"hidden"}}>
                                <img
                                    src={URL.createObjectURL(interiorBelakang)}
                                    alt="interiorBelakang"
                                    style={{ cursor: "pointer", width: "100%", maxWidth: "200px" }}
                                />
                            </div> :
                            <div className="d-flex justify-content-center flex-column align-items-center" style={{border:"1px solid #BBBBBB", borderRadius:"10px", height:"100px"}}>
                                <BsPlusLg className="mb-2" style={{color:"#BBBBBB"}}/>
                                <h6 className="m-0" style={{color:"#BBBBBB"}}>Tidak ada foto</h6>
                            </div>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h6 className="fw-bold mb-2">Interior Samping Kiri</h6>
                            {interiorKiri ?
                            <div className="d-flex justify-content-center flex-column align-items-center pointer" style={{borderRadius:"10px", height:"100px", overflow:"hidden"}}>
                                <img
                                    src={URL.createObjectURL(interiorKiri)}
                                    alt="interiorKiri"
                                    style={{ cursor: "pointer", width: "100%", maxWidth: "200px" }}
                                />
                            </div> :
                            <div className="d-flex justify-content-center flex-column align-items-center" style={{border:"1px solid #BBBBBB", borderRadius:"10px", height:"100px"}}>
                                <BsPlusLg className="mb-2" style={{color:"#BBBBBB"}}/>
                                <h6 className="m-0" style={{color:"#BBBBBB"}}>Tidak ada foto</h6>
                            </div>
                            }
                        </Col>
                        <Col>
                            <h6 className="fw-bold mb-2">Interior Samping Kanan</h6>
                            {interiorKanan ?
                            <div className="d-flex justify-content-center flex-column align-items-center pointer" style={{borderRadius:"10px", height:"100px", overflow:"hidden"}}>
                                <img
                                    src={URL.createObjectURL(interiorKanan)}
                                    alt="interiorKanan"
                                    style={{ cursor: "pointer", width: "100%", maxWidth: "200px" }}
                                />
                            </div> :
                            <div className="d-flex justify-content-center flex-column align-items-center" style={{border:"1px solid #BBBBBB", borderRadius:"10px", height:"100px"}}>
                                <BsPlusLg className="mb-2" style={{color:"#BBBBBB"}}/>
                                <h6 className="m-0" style={{color:"#BBBBBB"}}>Tidak ada foto</h6>
                            </div>
                            }
                        </Col>
                    </Row>
                    <div className="border-bottom my-3"></div>
                    <h6 className="m-0">Eksterior Kendaraan</h6>
                    <div className="border-bottom my-3"></div>
                    <Row className="mb-3">
                        <Col>
                            <h6 className="fw-bold mb-2">Eksterior Depan</h6>
                            {exteriorDepan ?
                            <div className="d-flex justify-content-center flex-column align-items-center pointer" style={{borderRadius:"10px", height:"100px", overflow:"hidden"}}>
                                <img
                                    src={URL.createObjectURL(exteriorDepan)}
                                    alt="exteriorDepan"
                                    style={{ cursor: "pointer", width: "100%", maxWidth: "200px" }}
                                />
                            </div> :
                            <div className="d-flex justify-content-center flex-column align-items-center" style={{border:"1px solid #BBBBBB", borderRadius:"10px", height:"100px"}}>
                                <BsPlusLg className="mb-2" style={{color:"#BBBBBB"}}/>
                                <h6 className="m-0" style={{color:"#BBBBBB"}}>Tidak ada foto</h6>
                            </div>
                            }
                        </Col>
                        <Col>
                            <h6 className="fw-bold mb-2">Eksterior Belakang</h6>
                            {exteriorBelakang ?
                            <div className="d-flex justify-content-center flex-column align-items-center pointer" style={{borderRadius:"10px", height:"100px", overflow:"hidden"}}>
                                <img
                                    src={URL.createObjectURL(exteriorBelakang)}
                                    alt="exteriorBelakang"
                                    style={{ cursor: "pointer", width: "100%", maxWidth: "200px" }}
                                />
                            </div> :
                            <div className="d-flex justify-content-center flex-column align-items-center" style={{border:"1px solid #BBBBBB", borderRadius:"10px", height:"100px"}}>
                                <BsPlusLg className="mb-2" style={{color:"#BBBBBB"}}/>
                                <h6 className="m-0" style={{color:"#BBBBBB"}}>Tidak ada foto</h6>
                            </div>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h6 className="fw-bold mb-2">Eksterior Samping Kiri</h6>
                            {exteriorKiri ?
                            <div className="d-flex justify-content-center flex-column align-items-center pointer" style={{borderRadius:"10px", height:"100px", overflow:"hidden"}}>
                                <img
                                    src={URL.createObjectURL(exteriorKiri)}
                                    alt="exteriorKiri"
                                    style={{ cursor: "pointer", width: "100%", maxWidth: "200px" }}
                                />
                            </div> :
                            <div className="d-flex justify-content-center flex-column align-items-center" style={{border:"1px solid #BBBBBB", borderRadius:"10px", height:"100px"}}>
                                <BsPlusLg className="mb-2" style={{color:"#BBBBBB"}}/>
                                <h6 className="m-0" style={{color:"#BBBBBB"}}>Tidak ada foto</h6>
                            </div>
                            }
                        </Col>
                        <Col>
                            <h6 className="fw-bold mb-2">Eksterior Samping Kanan</h6>
                            {exteriorKanan ?
                            <div className="d-flex justify-content-center flex-column align-items-center pointer" style={{borderRadius:"10px", height:"100px", overflow:"hidden"}}>
                                <img
                                    src={URL.createObjectURL(exteriorKanan)}
                                    alt="exteriorKanan"
                                    style={{ cursor: "pointer", width: "100%", maxWidth: "200px" }}
                                />
                            </div> :
                            <div className="d-flex justify-content-center flex-column align-items-center" style={{border:"1px solid #BBBBBB", borderRadius:"10px", height:"100px"}}>
                                <BsPlusLg className="mb-2" style={{color:"#BBBBBB"}}/>
                                <h6 className="m-0" style={{color:"#BBBBBB"}}>Tidak ada foto</h6>
                            </div>
                            }
                        </Col>
                    </Row>
                    <div className="border-bottom my-3"></div>
                    <h6 className="m-0">Odometer Bensin</h6>
                    <div className="border-bottom my-3"></div>
                    <Row className="mb-3">
                        <Col>
                            <h6 className="fw-bold mb-2">Foto Odometer</h6>
                            {payloadSelesai?.photo_odometer ?
                            <div className="d-flex justify-content-center flex-column align-items-center pointer" style={{borderRadius:"10px", height:"100px", overflow:"hidden"}}>
                                <img
                                    src={URL.createObjectURL(payloadSelesai?.photo_odometer)}
                                    alt="photo_odometer"
                                    style={{ cursor: "pointer", width: "100%", maxWidth: "200px" }}
                                />
                            </div> :
                            <div className="d-flex justify-content-center flex-column align-items-center" style={{border:"1px solid #BBBBBB", borderRadius:"10px", height:"100px"}}>
                                <BsPlusLg className="mb-2" style={{color:"#BBBBBB"}}/>
                                <h6 className="m-0" style={{color:"#BBBBBB"}}>Tidak ada Foto</h6>
                            </div>
                            }   
                        </Col>
                        <Col>
                            <div className="mt-4 mb-4">
                                <h6 className="fw-bold mb-2">Foto Odometer</h6>
                                <h6 className="">{payloadSelesai?.odometer} Km</h6>
                            </div>
                            <div>
                                <h6 className="fw-bold mb-2">Bar Bensin</h6>
                                <h6 className="">{payloadSelesai?.fuel}</h6>
                            </div>
                        </Col>
                    </Row>
                </div>
                }
            </div>


            <Modal.Footer className={`d-flex ${form.step_1 ? "": "justify-content-between"}  align-items-center`} style={{overflow:"hidden"}}>
                {!form.step_1 &&
                    <h6 
                        style={{cursor:"pointer", fontSize:"14px", border:"none"}}
                        onClick={() => handlePrevStep()}
                    >
                        <BsChevronLeft style={{marginTop:"-4px"}}/> Sebelumnya
                    </h6>
                }
                <div className="d-flex align-items-center">
                    {form.step_5 &&
                        <h6 className="py-1 px-4 m-0" 
                            style={{cursor:"pointer", fontSize:"14px", border:"none", color:"#FF1D8C"}}
                            onClick={() => handleReset()}
                        >
                            Reset
                        </h6>
                    }
                    <button 
                        className="rounded-pill py-1 px-4 m-0"
                        style={{backgroundColor:"#FF1D8C", cursor:"pointer", color:"white", fontSize:"14px", border:"none"}}
                        onClick={() => handleNextStep()}
                        >
                        {form.step_5 ? "Submit & Selesai" : "Selanjutnya"}
                    </button>
                </div>
            </Modal.Footer>

        </Modal>
    </>;
}

export default ModalDokumentasiPenyerahan;
