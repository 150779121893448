import { useState, Fragment, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import moment from "moment";
import Area from "../Penawaran/Modal/Area";
import Partner from "../Penawaran/Modal/Area";
import DetailRute from './Modal/DetailRute';
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getPartnerOfferHeader } from "../../../redux/action/Partner/partner_action";
import { message } from "antd";
import { set } from "lodash";
import { formatRupiah } from "../../../helper/formatCurrency";
// import "./styles.scss";

const InformasiUtamaDetail = (props) => {
  const { data } = props;
  /*======================== Hooks ======================== */

  /*======================== State ======================== */
  const [openPartner, setOpenPartner] = useState(false);
  const [openDetailRute, setOpenDetailRute] = useState(false);

  /*======================== Handler ======================== */

  const renderVehicle = () => {
    let component = [];
    if (data) {
      data?.route_detail?.vehicles?.forEach((item, i) => {
        component.push(
          <div key={i}>
            <Row>
              <Col xs={12}>
                <div className="px-2">
                  <p className="text-muted label mb-0">Jenis Kendaraan</p>
                  <p className="mb-0">{item.name}-{item.tonage} kg</p>
                </div>
              </Col>
            </Row>
            <hr className="my-2" />
            <Row>
              <Col xs={6}>
                <div className="px-2">
                  <p className="text-muted label mb-0">Total Kebutuhan</p>
                  <p className="mb-0">{item.quantity} Kendaraan</p>
                </div>
              </Col>
              <Col xs={6}>
                <div className="px-2">
                  <p className="text-muted label mb-0">Budget Kebutuhan</p>
                  <p className="mb-0">{formatRupiah(item.budget)}</p>
                </div>
              </Col>
            </Row>
          </div>
        );
      });
    }
    return component;
  };

  const renderVehicleOffer = () => {
    let component = [];
    if (data) {
      data?.vehicle_offer?.forEach((item, i) => {
        component.push(
          <div key={i}>
            <Row>
              <Col xs={6}>
                <div className="px-2">
                  <p className="text-muted label mb-0">Jenis Kendaraan</p>
                  <p className="mb-0">{item.name}-{item.tonage} kg</p>
                </div>
              </Col>
              <Col xs={6}>
                <div className="px-2">
                  <p className="text-muted label mb-0">Harga Penawaran</p>
                  <p className="mb-0">{formatRupiah(item.amount)}</p>
                </div>
              </Col>
            </Row>
            <hr className="my-2" />
            <Row>
              <Col xs={12}>
                <div className="px-2">
                  <p className="text-muted label mb-0">Biaya Tambahan</p>
                  <p className="mb-0">
                    {additionalFeeRender(item.additional_fee)}
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        );
      });
    }
    return component;
  };

  const additionalFeeRender = (item) => {
    let string = "";
    if (item && item.length > 0) {
      item.forEach((val, i) => {
        string += val.type + " (" + formatRupiah(val.fee) + ") ";
        if (i < item.length - 1) {
          string += " - ";
        }
      });
    } else {
      return "-";
    }

    return string;
  };

  const handleStatusLabel = (status) => {
    switch (status) {
      case "WAITING":
        return "Menunggu Konfirmasi";
      case "NEGOTIATION":
        return "Dalam Negosiasi";
      case "APPROVED":
        return "Penawaran Diterima";
      case "REJECTED":
        return "Penawaran Ditolak";
      default:
        return "";
    }
  };

  const handleStatusColor = (status) => {
    switch (status) {
      case "WAITING":
        return "#FFAC14";
      case "NEGOTIATION":
        return "#209AB7";
      case "APPROVED":
        return "#1EAF56";
      case "REJECTED":
        return "red";
      default:
        return "#1EAF56";
    }
  };

  /*======================== Use Effect ======================== */
  useEffect(() => {
  }, [])


  /*======================== Return ======================== */
  if (!data) {
    return null
  }

  return (
    <div>
      <Row className="procurement-detail-content mt-4">
        <Col xs={12} md={6} className="mt-2">
          <div className="mb-4">
            <h5 className="fw-normal">Status Penawaran</h5>
            <div className="bg-white rounded-card w-full p-3">
              <Row>
                <Col xs={6}>
                  <div className="px-2">
                    <p className="text-muted label mb-0">Status Penawaran</p>
                    <p className="mb-0 text-capitalize status" style={{"color": handleStatusColor(data.status)}}>
                      {handleStatusLabel(data.status)}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="mb-4">
            <h5 className="fw-normal">Rute Penawaran</h5>
            <div className="bg-white rounded-card w-full p-3">
              <Row>
                <Col xs={6}>
                  <div className="px-2">
                    <p className="text-muted label mb-0">Kode Rute</p>
                    <p className="mb-0">{data?.route_detail?.route_code}</p>
                  </div>
                </Col>
                <Col xs={6}>
                  <div className="px-2">
                    <p className="text-muted label mb-0">Nama Rute</p>
                    <p className="mb-0">{data?.route_detail?.route_name}</p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="mb-4">
            <h5 className="fw-normal">Total Kebutuhan Anda</h5>
            <div className="bg-white rounded-card w-full p-3">
              <Row>
                <Col xs={6}>
                  <div className="px-2">
                    <p className="text-muted label mb-0">Budget Penawaran</p>
                    <p className="mb-0">{formatRupiah(data?.route_detail?.route_budget)}</p>
                  </div>
                </Col>
                <Col xs={6}>
                  <div className="px-2">
                    <p className="text-muted label mb-0">Total Kebutuhan</p>
                    <p className="mb-0">
                      {data?.route_detail?.vehicle_quantity_needed} Kendaraan
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="mb-4">
            <h5 className="fw-normal mt-3">Rincian Kebutuhan Anda</h5>
            <div className="bg-white rounded-card w-full p-3">
              {renderVehicle()}
            </div>
          </div>
        </Col>
        <Col xs={12} sm={6}>
          <div className="mb-4">
            <h5 className="fw-normal">Update Penawaran</h5>
            <div className="bg-white rounded-card w-full p-3">
              <Row>
                <Col xs={6}>
                  <div className="px-2">
                    <p className="text-muted label mb-0">Update Terakhir</p>
                    <p className="mb-0">{data?.updated_at}</p>
                  </div>
                </Col>
                <Col xs={6}>
                  <div className="px-2">
                    <p className="text-muted label mb-0">Update Oleh</p>
                    <p className="mb-0">
                      {data?.updated_by ? data?.updated_by : "-"}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>

          <div className="mb-4">
            <h5 className="fw-normal">Detail Rute</h5>
            <div className="bg-white rounded-card w-full p-3">
              <Row>
                <Col xs={6}>
                  <div className="px-2">
                    <p className="text-muted label mb-0">Detail Rute</p>
                    <p
                      className="underline mb-0"
                      style={{
                        color: "#CE1771",
                        cursor: "pointer",
                      }}
                      onClick={() => setOpenDetailRute(true)}
                    >
                      Lihat Rute lengkap
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>

          <div className="mb-4">
            <h5 className="fw-normal">Total Penawaran Partner</h5>
            <div className="bg-white rounded-card w-full p-3">
              <Row>
                <Col xs={6}>
                  <div className="px-2">
                    <p className="text-muted label mb-0">Total Penawaran</p>
                    <p className="mb-0">{formatRupiah(data?.amount)}</p>
                  </div>
                </Col>
                <Col xs={6}>
                  <div className="px-2">
                    <p className="text-muted label mb-0">Total Kebutuhan</p>
                    <p className="mb-0">{data?.vehicle_quantity} Kendaraan</p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="mb-4">
            <h5 className="fw-normal mt-3">Rincian Penawaran Partner</h5>
            <div className="bg-white rounded-card w-full p-3">
              {renderVehicleOffer()}
            </div>
          </div>
        </Col>
      </Row>

      {openDetailRute && (
        <DetailRute
          data={data.route_detail.route_data}
          isOpen={openDetailRute}
          handleClose={() => setOpenDetailRute(false)}
        />
      )}

      {openPartner && (
        <Partner
          data={data.partners}
          isOpen={openPartner}
          handleClose={() => setOpenPartner(false)}
        />
      )}
    </div>
  );
};

export default InformasiUtamaDetail;
