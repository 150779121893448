import { BaseApiRequest } from "./base-api";
import { OpResult } from "../helper/operation_result";
import { message } from "antd";

export default class OrderSendApi extends BaseApiRequest {
  constructor() {
    super(process.env.REACT_APP_API_END_POINT); // PROD
    // super(process.env.REACT_APP_API_END_POINT_DEV); // DEV
  }

  async validateDestination(payload) {
    let result = OpResult.failed("Failed validate destination");
    try {
      let resApi = await this.post(
        "/customer/order/send/destination/validate",
        payload
      );
      // if (resApi.status) {
      result = resApi;
      // }
    } catch (e) {
      result = OpResult.failed("Internal Error");
    }
    return result;
  }

  async getAvailableVehicleSubtype(payload) {
    let result = OpResult.failed("Failed GET Vehicle Subtype");
    try {
      let resApi = await this.post("/customer/order/send/subtype", payload);
      if (resApi.status) {
        result = resApi;
      } else {
        message.error(resApi.message);
        result = OpResult.failed(resApi.message);
      }
    } catch (e) {
      result = OpResult.failed("Internal Error");
    }
    return result;
  }

  async getAvailablePartnerServices(payload) {
    let result = OpResult.failed("Failed GET Partner Services");
    try {
      let resApi = await this.post("/customer/order/send/service", payload);
      if (resApi.status) {
        result = resApi;
      }
    } catch (e) {
      result = OpResult.failed("Internal Error");
    }
    return result;
  }

  async getOrderSummary(payload) {
    let result = OpResult.failed("Failed GET OrderSummary");
    try {
      let resApi = await this.post("/customer/order/send/summary", payload);
      if (resApi.status) {
        result = resApi;
      }
    } catch (e) {
      result = OpResult.failed("Internal Error");
    }
    return result;
  }

  async createOrder(payload, token) {
    let result = OpResult.failed("Failed Create Order");
    try {
      let resApi = await this.post("/customer/order/send", payload, token);
      if (resApi.status) {
        result = resApi;
      } else {
        result = OpResult.failed(resApi.message);
      }
    } catch (e) {
      result = OpResult.failed("Internal Error");
    }
    return result;
  }

  async getTender(token) {
    let result = OpResult.failed("Failed getTender");
    try {
      let resApi = await this.get("/customer/order/send/tender?status=DONE", token);
      if (resApi.status) {
        result = resApi;
      }
    } catch (e) {
      result = OpResult.failed("Internal Error");
    }
    return result;
  }

  async getRuteByTenderId(tenderId, token) {
    let result = OpResult.failed("Failed getRute");
    try {
      let resApi = await this.get("/route/list?page=1&limit=999&sort=DESC&delivery_procurement_id="+ tenderId, token);
      if (resApi.status) {
        result = resApi;
      }
    } catch (e) {
      result = OpResult.failed("Internal Error");
    }
    return result;
  }

  async getSubtypeWithTender(tenderId, routeId, token) {
    let result = OpResult.failed("Failed getSubtype");
    try {
      let resApi = await this.get("/customer/order/send/type/"+ tenderId + "/"+ routeId , token);
      if (resApi.status) {
        result = resApi;
      }
    } catch (e) {
      result = OpResult.failed("Internal Error");
    }
    return result;
  }

  async patchShipmentNumber(id, payload, token) {
    let result = OpResult.failed("Failed getSubtype");
    try {
      let resApi = await this.patch(`/customer/order/send/${id}/shipment_number`, payload,token);
      if (resApi.status) {
        result = resApi;
      }
      return resApi
    } catch (e) {
      result = OpResult.failed("Internal Error");
    }
    return result;
  }
}
