/*======================== Third Party ======================== */
import { Card } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import SlideToggle from "react-slide-toggle";

/*======================== Utils ======================== */
import useBreakpoint from "../../../../components/responsiveUtils/useBreakpoint";

/*======================== icon ======================== */
import icon_send_sch from "../../../../assets/images/icon_send_sch.svg";
import icon_send_ins from "../../../../assets/images/icon_send_ins.svg";
import icon_rent_sch from "../../../../assets/images/icon_rent_sch.svg";
import icon_rent_ins from "../../../../assets/images/icon_rent_ins.svg";

/*======================== Components ======================== */
import CardHeader from "./CardHeader"
import CardContent from "./CardContent"
import CardFooter from "./CardFooter"


const CardListOrder = ({ eachData }) => {
  const { isMobile } = useBreakpoint();

  /*======================== Hooks  ======================== */
  
  const history = useHistory();

  /*======================== Handler ======================== */
 
  const getIcon = () => {
    const { type_order } = eachData;

    if (type_order === "SEND_GOODS") {
      const {
        trx_booking_send: { send_category },
      } = eachData;

      if (send_category === "SCHEDULED") {
        return icon_send_sch;
      }

      if (send_category === "INSTANT") {
        return icon_send_ins;
      }
    }

    if (type_order === "RENT") {
      const {
        trx_booking_rent: { rent_category },
      } = eachData;

      if (rent_category === "SCHEDULED") {
        return icon_rent_sch;
      }

      if (rent_category === "INSTANT") {
        return icon_rent_ins;
      }
    }

    return null;
  };

  const getTypeAddress = (param) => {
    const [indexOne, indexTwo] = eachData?.send_category_order
    let result = [
      "Multiple Address",
      {
        color:"#FF1D8C",
        backgroundColor:"#FFDDEE"
      }
    ]
    if(indexOne === "SINGLE_PICKUP" && indexTwo === "SINGLE_DROPOFF"){
      result = ["Single Address", {color:"#2BBECB",backgroundColor:"#DFF5F7"}]
    }

    // result index 1 for lable
    // result index 2 for style
    
    if(param === "lable"){
      return result[0]
    }
    return result[1]
  }

  const getStatusOrder = (param) => {
    let helper = {
      FINISHED: ["Selesai", "green"],
      CANCELLED: ["Dibatalkan","red"],
      WAITING_FOR_CONFIRMATION: ["Menunggu Konfirmasi","gold"],
      WAITING_FOR_PAYMENT: ["Menunggu Pembayaran","gold"],
      CONFIRMED: ["Dikonfirmasi","green"],
      ON_GOING: ["Sedang Dirposes","green"]
    };

    let result = helper[eachData?.booking_status]
    if(param === 'lable'){
      return result[0]
    }
    return result[1]
  }

  const splitAddres = (val, param) => {
    const data = val?.trx_booking_send?.trx_booking_send_destination
    const pickup = data.filter((e)=> e.destination_type === "PICKUP")
    const dropoff = data.filter((e)=> e.destination_type === "DROPOFF")

    if(param === "PICKUP"){
      return `${val?.mst_driver?.name.length > 7 ? val?.mst_driver?.name.substr(0,7)+".." : val?.mst_driver?.name} - ${pickup[0].address.length > 18 ? pickup[0].address.substr(0,18)+".." : pickup[0].address} ${pickup.length > 1 ? "+"+pickup.length-1+"more" : ""}`
    }
    if(param === "DROPOFF"){
      return `${val?.mst_driver?.name.length > 7 ? val?.mst_driver?.name.substr(0,7)+".." : val?.mst_driver?.name} - ${dropoff[0].address.length > 18 ? dropoff[0].address.substr(0,18)+".." : dropoff[0].address} ${dropoff.length > 1 ? "+"+dropoff.length-1+"more" : ""}`
    }

    return "-"
  }

  const getFleet = () => {
    const { type_order } = eachData;

    if (type_order === "SEND_GOODS") {
      return eachData?.trx_booking_send?.mst_vehicle?.mst_vehicle_subtype?.name;
    }

    if (type_order === "RENT") {
      return eachData?.trx_booking_rent?.trx_booking_rent_vehicle?.mst_vehicle
        ?.mst_vehicle_subtype?.name;
    }

    return null;
  };

  const goToPage = (id) => {
    if (eachData?.type_order === "RENT") {
      history.push({
        pathname: `/daftar-transaksi-sewa/${id}`,
        state: { type: eachData.type_order },
      });
    }
    if (eachData?.type_order === "SEND_GOODS") {
      history.push({
        pathname: `/daftar-transaksi-kirim/${id}`,
        state: { type: eachData.type_order },
      });
    }
    // localStorage.setItem("type", eachData.type_order);
  };

  /*======================== Return ======================== */

  
  return (
    <Card
      key={eachData?.id}
      className={`${
        isMobile ? "px-2 py-3 mt-1" : "py-3 px-2"
      } rounded-card border-trx-card-${
        (eachData?.trx_booking_send?.send_category ||
          eachData?.trx_booking_rent?.rent_category) === "SCHEDULED"
          ? "scheduled"
          : "instant"
      } mb-4`}
      style={{ backgroundColor: "white" }}
    >
      <div>
        <CardHeader
          eachData={eachData}
          getIcon={getIcon}
          getTypeAddress={getTypeAddress}
          getStatusOrder={getStatusOrder}
        />
        <SlideToggle
          collapsed
          duration={800}
          render={({ toggle, setCollapsibleElement, progress }) => (
            <>
              <CardContent
                eachData={eachData}
                setCollapsibleElement={setCollapsibleElement}
                splitAddres={splitAddres}
                getFleet={getFleet}
              />
              <CardFooter
                eachData={eachData}
                toggle={toggle}
                progress={progress}
                goToPage={goToPage}
              />
            </>
          )}
        />        
      </div>
    </Card>
  );
};

export default CardListOrder;
