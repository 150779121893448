import React from "react";
import {
  Row,
  Col,
  Tabs,
  Tab
} from "react-bootstrap";

const TabTypeOrder = ({handleChangeTab}) => {
    return(
        <Row className="py-2">
            <Col>
                <Tabs
                    defaultActiveKey={sessionStorage.getItem("daftar-transaksi-tabJenisOrder") ? sessionStorage.getItem("daftar-transaksi-tabJenisOrder") : 1 }
                    className="tabs-trx mb-2 border-bottom-2"
                    onSelect={handleChangeTab}
                >
                    <Tab eventKey="1" title="Semua"></Tab>
                    <Tab eventKey="2" title="Kirim Barang"></Tab>
                    <Tab eventKey="3" title="Sewa Kendaraan"></Tab>
                </Tabs>
            </Col>
        </Row>
    );
}

export default TabTypeOrder;
