import NotifApi from "../../api/notif-api";
import AuthApi from "../../api/auth-api";
import history from "../../helper/history";
import { resetAuth } from "./auth_action";

export const getNotification = (query, token) => async (dispatch) => {
  try {
    const api = new NotifApi();
    const authApi = new AuthApi();

    dispatch(setNotification({ loading: true, data: [] }));

    // const result = authApi.checkAccessToken(token);

    const { data } = await api.getNotification(query, token);

    if (data.error === "ERR_UNAUTHORIZED") dispatch(resetAuth());

    dispatch(setNotification({ loading: false, data: [...data] }));
  } catch (error) {
    dispatch(setNotification({ loading: false, data: [] }));
  }
};

export const getCountUnreadNotification = (token) => async (dispatch) => {
  try {
    const api = new NotifApi();

    dispatch(setCountUnread(0));

    const { data } = await api.getUnreadNotification(token);

    dispatch(setCountUnread(data.total_unread));
  } catch (error) {
    dispatch(setCountUnread(0));
  }
};

export const setNotification = (payload) => ({
  type: "SET_NOTIFICATION",
  payload,
});

export const setCountUnread = (payload) => ({
  type: "SET_COUNT_UNREAD",
  payload,
});

export const setTabKey = (payload) => ({
  type: "SET_TAB_KEY",
  payload,
});
