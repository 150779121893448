import React, { Component, useState, useEffect } from "react";
import {
  Card,
  FormGroup,
  Form,
  InputGroup,
  Button,
  FloatingLabel,
} from "react-bootstrap";
import { message } from "antd";
import { withRouter, useHistory, useParams } from "react-router-dom";
import AuthApi from "../../api/auth-api";
import { setAuth } from "../../redux/action/auth_action";
import OneSignal from "react-onesignal";
import { useDispatch, useSelector } from "react-redux";
import useBreakpoint from "../responsiveUtils/useBreakpoint";
import { isWeakPassword } from "../../helper/password_strength_helper";
import useDidMountEffect from "../../hooks/useDidMountEffect";

// images
import iconUser from "../../assets/images/icon-user.svg";
import iconKey from "../../assets/images/icon-key.svg";

// icons
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import { hideSidenavMobile } from "../layouts/layout_helper";

const TabForgotPassword = () => {
  const { isMobile } = useBreakpoint();
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const authReducer = useSelector((st) => st.authReducer);
  const [state, setState] = useState({
    hidePwd: true,
    password: "",
    hideConfirmPwd: true,
    confirmPassword: "",
  });
  const [weakPassword, setWeakPassword] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);

  const handleSubmitResetPassword = async () => {
    const authAPI = new AuthApi();

    if (state.password !== state.confirmPassword)
      return message.error("Konfirmasi kata sandi tidak sesuai");

    const response = await authAPI.resetForgottenPassword({
      new_password: state.password,
      reset_token: params.reset_token,
    });

    if (response.status === "success") {
      message.success("Reset password berhasil.");
      history.push("/login");
    }
  };

  useDidMountEffect(() => {
    setWeakPassword(isWeakPassword(state.password));
  }, [state.password]);

  useEffect(() => {
    if (!weakPassword && state.password === state.confirmPassword) {
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  }, [state.password, state.confirmPassword]);

  return (
    <Card.Body
      className={`pt-0 d-flex flex-column ${isMobile && "card-body-auth"} `}
    >
      {!isMobile && <strong className="h2 fw-bold">Ubah Kata Sandi</strong>}
      <h3 className={`${isMobile ? "mt-4" : "mt-2"}`}>
        Masukkan kata sandi baru anda guna melanjutkan transaksi di eLOG.
      </h3>
      <div className="rc-ice p-2 mb-2 mt-4">
        <Form>
          <FormGroup className={`form-group my-1 d-flex `}>
            <img src={iconKey} alt="" className="icon-form-o" />
            <FloatingLabel
              label="Kata Sandi Baru"
              style={{ width: "92.2%", fontSize: "12px" }}
            >
              <Form.Control
                type={state.hidePwd ? "password" : "text"}
                placeholder="Kata Sandi Baru"
                className="form-white border-0 py-3"
                onChange={(e) => {
                  setState({
                    ...state,
                    password: e.target.value,
                  });
                }}
              />
            </FloatingLabel>
            <InputGroup.Text
              className="border-0 pointer"
              onClick={() => {
                setState({
                  ...state,
                  hidePwd: !state.hidePwd,
                });
              }}
            >
              {state.hidePwd ? <VscEye /> : <VscEyeClosed />}
            </InputGroup.Text>
          </FormGroup>
          <FormGroup className={`form-group my-1 d-flex `}>
            <img src={iconKey} alt="" className="icon-form-o" />
            <FloatingLabel
              label="Ulangi Kata Sandi Baru"
              style={{ width: "92.2%", fontSize: "12px" }}
            >
              <Form.Control
                type={state.hideConfirmPwd ? "password" : "text"}
                placeholder="Ulangi Kata Sandi"
                className="form-white border-0 py-3"
                onChange={(e) => {
                  setState({
                    ...state,
                    confirmPassword: e.target.value,
                  });
                }}
              />
            </FloatingLabel>
            <InputGroup.Text
              className="border-0 pointer"
              onClick={() => {
                setState({
                  ...state,
                  hideConfirmPwd: !state.hideConfirmPwd,
                });
              }}
            >
              {state.hideConfirmPwd ? <VscEye /> : <VscEyeClosed />}
            </InputGroup.Text>
          </FormGroup>
        </Form>
        {weakPassword ? (
          <h6 className="text-danger">
            Password terdiri dari minimal 6 karakter, huruf besar dan angka
          </h6>
        ) : null}
      </div>
      <div className="mt-auto d-grid">
        <Button
          disabled={disabledButton}
          size="sm"
          className="fw-bold py-2 rounded-pill"
          onClick={async () => {
            await handleSubmitResetPassword();
          }}
        >
          Submit
        </Button>
      </div>
    </Card.Body>
  );
};

export default TabForgotPassword;
