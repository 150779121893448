import React, {
} from "react";
import {
  Card,
  Row,
  Button,
  Col,
} from "react-bootstrap";
import { Tag, Divider } from "antd";
import { useHistory } from "react-router-dom";
import { formatRupiah } from "../../helper/formatCurrency";

const Transaction = ({ params, data }) => {
  const history = useHistory();
  return (
    <>
      <div className={`scrollable-56 mt-2 px-2`}>
        {data?.invoiceLine?.map((_, i) =>
          <Card
            className={`p-3 pointer rounded-card border-trx-card-scheduled mb-3`}
            style={{ backgroundColor: "white" }}
          >
            <Row className="align-items-center">
              <Col>
                <h5 className="fw-bold text-muted">{_.invoiceLineDetail?.order_detail?.trx_booking_send?.mst_vehicle?.plate_number}</h5>
                <h5>{_?.created_at?.split('T')[0]}</h5>
              </Col>
              <Col className="align-self-start">
                <Tag
                  color='gold'
                  className='mb-2'
                >
                  {_.invoiceLineDetail?.order_detail?.trx_booking_send?.status}
                </Tag>
              </Col>
              <Col>
                <small>Total  :</small>
                <h5 className="fw-bold text-muted">{formatRupiah(_.invoiceLineDetail?.amount)}</h5>
              </Col>
              <Col>
                <Button variant="primary lg text-nowrap btn-block"
                  onClick={() => history.push({
                    pathname: `/daftar-transaksi-kirim/${_.invoiceLineDetail?.order_detail?.id}`,
                    state: { 
                      data: params
                     },
                  })
                  }>
                  <small>Lihat Pesanan</small>
                </Button>
              </Col>


            </Row>
          </Card>
        )}
      </div>
      <div className="bg-white p-3 shadow-sm rounded-top rounded-lg">
        <div className="d-flex justify-content-between">
          <h5>Subtotal Tagihan ({data?.invoiceLine?.length})</h5>
          <h5>{formatRupiah(data?.total_amount) || ''}</h5>
        </div>
        <div className="d-flex justify-content-between">
          <h5>Potongan Tagihan (Rp)</h5>
          <h5>{formatRupiah(data?.total_amount - data?.total_amount_after_disc)}</h5>
        </div>
        <Divider />
        <div className="d-flex justify-content-between">
          <h5>Subtotal Tagihan ({data?.invoiceLine?.length})</h5>
          <h5>{formatRupiah(data?.total_amount_after_disc)}</h5>
        </div>
      </div>
    </>

  )
}

// ma.ruffajarr@gmail.com
// Password123

export default Transaction;