import React, { Component } from "react";
import { Row, Col, Accordion, Button } from "react-bootstrap";
import Countdown, { zeroPad } from "react-countdown";
import { connect } from "react-redux";
import moment from "moment";
import payment_helper from "../../../helper/payment_helper";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { BiCopyAlt } from "react-icons/bi";

class VA extends Component {
  state = {
    copied: false,
  };
  render() {
    const renderer = ({ hours, minutes, seconds, completed }) => {
      if (completed) {
        // Render a completed state
        return (
          <span className="text-muted fw-medium p">Sesi Waktu Habis!</span>
        );
      } else {
        // Render a countdown
        return (
          <span className="lipstick fw-bold mb-0 h2">
            {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
          </span>
        );
      }
    };

    const handleContent = (payment_method_id) => {
      let component = [];
      const result = [...payment_helper.getContent(payment_method_id)];
      let id = 0;
      for (const iterator of result) {
        let content = [];
        [...iterator.step].map((x, index) => {
          content.push(<li key={index}>{x}</li>);
        });
        component.push(
          <Accordion.Item eventKey={id}>
            <Accordion.Header>
              <p className="mb-0 fw-medium">{iterator.type}</p>
            </Accordion.Header>
            <Accordion.Body>
              <ul className="mb-0 text-muted h6">{content}</ul>
            </Accordion.Body>
          </Accordion.Item>
        );
        id++;
      }
      if (result.length === 0) {
        component.push(<div></div>);
        return component;
      } else {
        return <Accordion defaultActiveKey="0">{component}</Accordion>;
      }
    };

    return (
      <div>
        <Row className="mt-5">
          <Col xs={{ span: 6, offset: 3 }}>
            <img
              className="img-fluid"
              src={this.props.activePaymentReducer.logo}
              alt=""
            />
          </Col>
          <Col xs={{ span: 10, offset: 1 }} className="text-center mt-4">
            <h3 className="fw-light">Menunggu Pembayaran</h3>
            <p className="text-muted">
              Mohon Selesaikan pembayaran Anda dengan rincian sebagai berikut:
            </p>
          </Col>
          <Col xs={{ span: 8, offset: 2 }} className="text-center mt-4">
            <p className="mb-0">Batas Akhir Pembayaran</p>
            <p className="fw-bold">
              {moment(
                this.props.activePaymentReducer.data.payment.expired_at,
                "YYYY-MM-DD HH:mm:ss"
              ).add(7, 'hours').format("LLLL")}{" "}
              WIB
            </p>
            <div className="rc-ice py-3">
              <Countdown
                renderer={renderer}
                date={this.props.activePaymentReducer.data.payment.expired_at}
              />
            </div>
          </Col>
        </Row>
        <hr style={{ background: "#a7a7a7" }} />
        <Row className="my-2">
          <Col xs={{ span: 3, offset: 1 }}>
            <p className="text-muted mb-0">Nama Akun</p>
          </Col>
          <Col xs={7}>
            <p className="fw-bold mb-0">
              : {this.props.activePaymentReducer.data.customer_name}
            </p>
          </Col>
          <br />
          <Col xs={{ span: 3, offset: 1 }}>
            <p className="text-muted mb-0">Nomor VA</p>
          </Col>
          <Col xs={7}>
            <p className="fw-bold mb-0">
              : {this.props.activePaymentReducer.data.payment.va_number}{" "}
              <CopyToClipboard
                text={this.props.activePaymentReducer.data.payment.va_number}
                onCopy={() => this.setState({ copied: true })}
              >
                <BiCopyAlt
                  className="text-primary pointer"
                  style={{ fontSize: "18px", marginLeft: "10px" }}
                />
              </CopyToClipboard>
              {this.state.copied ? (
                <span className="copied">Copied!</span>
              ) : null}
            </p>
          </Col>
          <br />
          <Col xs={{ span: 3, offset: 1 }}>
            <p className="text-muted mb-0">Total Pembayaran</p>
          </Col>
          <Col xs={7}>
            <p className="fw-bold mb-0">
              : Rp{" "}
              {parseInt(
                this.props.activePaymentReducer.data.payment.amount || 0
              )
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
            </p>
          </Col>
        </Row>
        <hr style={{ background: "#a7a7a7" }} />

        <h4 className="fw-bold mb-2">Cara Pembayaran</h4>

        {[
          ...payment_helper.getContent(
            this.props.activePaymentReducer.payment_method_id
          ),
        ].length > 0
          ? handleContent(this.props.activePaymentReducer.payment_method_id)
          : null}

        <div className="rc-ice mt-3 p-3">
          <ul className="h6 text-muted mb-0">
            <li>
              Driver patner elog akan melakukan penjemputan setelah Pembayaran
              terferivikasi
            </li>
            <li>Catatan lainya jika ada silahkan letakan disini</li>
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  activePaymentReducer: state.activePaymentReducer,
});
export default connect(mapStateToProps)(VA);
