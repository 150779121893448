const util = {
  thousandSeparator: (x) => {
    try {
      var parts = x.toString().split(",");
      parts[0] = "Rp " + parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      return parts.join(",");
    } catch (e) {
      return null;
    }
  },
  thousandSeparatorPure: (x) => {
    if (!x) return 0;
    try {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    } catch (e) {
      return null;
    }
  },
  currencySeparator: (x) => {
    try {
      x = x.toString();
      if (x.length === 1 && x === "0") {
        return "0";
      } else {
        if (x === "00") {
          return "0";
        }
        x = x.replace(/^0+/, "");
        const addCommas = (num) =>
          num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");
        return addCommas(removeNonNumeric(x));
      }
    } catch (e) {
      return null;
    }
  },
  etaConvertion: (totalMinutes) => {
    let hours = Math.floor(totalMinutes / 60);
    let minutes = totalMinutes % 60;
    let result;
    result = `${hours} Jam`;
    if (minutes > 0) {
      result += ` ${minutes} Menit`;
    }
    return result;
  },
};

export default util;
