import React from 'react'
import { Row, Col } from 'react-bootstrap'
import moment from 'moment'

const InfoBilling = ({data}) => {
  return (
    <div className="mb-3">
      <h5 className="fw-bold">Info Penagihan</h5>
      <div className="bg-white  w-full p-3" style={{borderRadius:"20px 20px 0 0"}}>
        <Row>
          <Col xs={6}>
            <div className="px-2">
              <p className="text-muted label mb-1">No. Penagihan</p>
              <p className="mb-0">{data?.billing_number ?? "-"}</p>
            </div>
          </Col>
          <Col xs={6}>
            <div className="px-2">
              <p className="text-muted label mb-1">Waktu Penagihan</p>
              <p className="mb-0">{data?.created_at ? moment(data?.created_at).format('DD/MM/YYYY HH:mm') : "-"}</p>
            </div>
          </Col>
        </Row>
      </div>
      <div className="bg-white w-full px-3 py-2" style={{ borderTop:"1px solid #E5E5E5", borderBottom:"1px solid #E5E5E5", position:"relative"}}>
        <Row>
          <Col xs={12}>
            <div className="px-2">
              <p className="text-muted label mb-1">Partner Anda</p>
              <p className="mb-0">{data?.mst_partner?.name ?? "-"}</p>
            </div>
          </Col>
        </Row>
      </div>
      <div className="bg-white w-full p-3" style={{borderRadius:"0 0 20px 20px"}}>
        <Row>
          <Col xs={6}>
            <div className="px-2">
              <p className="text-muted label mb-1">Kontak Partner</p>
              <p className="mb-0">{data?.mst_partner?.mst_user_partner?.wa_number ? `0${data?.mst_partner?.mst_user_partner?.wa_number.substring(2)}` : "-"}</p>
            </div>
          </Col>
          <Col xs={6}>
            <div className="px-2">
              <p className="text-muted label mb-1">Jumlah Transaksi</p>
              <p className="mb-0">{data?.trx_order_billing?.length} Transaksi</p>
            </div>
          </Col>
          {/* <Col xs={6}>
            <div className="px-2">
              <p className="text-muted label mb-1">Transaksi Khusus</p>
              <p className="mb-0">{detail?.is_special_transaction ? "Ya" : "Tidak"}</p>
            </div>
          </Col> */}
        </Row>
      </div>
    </div>
  )
}

export default InfoBilling