import { BaseApiRequest } from "./base-api";
import { OpResult } from "../helper/operation_result";
import { message } from "antd";
import axios from "axios";


export default class OtpEngine extends BaseApiRequest {
    constructor() {
        super(process.env.REACT_APP_API_END_POINT);
    }

    async otpRequest(data) {
        let result = OpResult.failed("Failed request otp");
        try {
            let resApi = await axios.post(
                this.endpoint + "/otp/request",
                data
            );
            result = resApi.data;
        } catch (e) {
            console.log(e.response);
            message.error(e?.response?.data?.message);
            result = e.response;
        }
        return result;
    }

    async otpCheck(id) {
        let result = OpResult.failed("Failed check otp");
        try {
            let resApi = await axios.post(
                this.endpoint + "/otp/check",
                { id: id}
            );
            result = resApi.data;
        } catch (e) {
            console.log(e.response);
            message.error(e?.response?.data?.message);
            result = e.response;
        }
        return result;
    }
}
