import React from "react";
import { Row, Col, Button, Modal, Form } from "react-bootstrap";
import { Divider } from "antd";
import { useSelector } from "react-redux";
import moment from "moment";
import util from "../../helper/util";

import logoElog from "../../assets/images/logo-color.svg";
import elogwhite from "../../assets/images/elog_logo_white.svg";
import appStore from "../../assets/images/App_Store.png";
import gPlay from "../../assets/images/Google_Play.png";

const RentInvoice = React.forwardRef((props, ref) => {
  const trxDetail = useSelector((st) => st.trxHistoryReducer.trxDetail);

  return (
    <div ref={ref} className="hide-print-component" style={{ padding: "2em" }}>
      <div className="d-flex flex-column" style={{ height: "93.5vh" }}>
        <div className="d-flex align-items-center">
          <img className={"logo-invoice"} src={logoElog} alt="" />
          <div className="ms-auto">
            <h2 className="fw-bold text-end">INVOICE</h2>
            <h5>{trxDetail?.data?.invoice_number}</h5>
          </div>
        </div>

        <Row className="mt-2">
          <Col>
            <h5 className="fw-bold text-secondary">Diterbitkan oleh :</h5>
            <Row className="mt-1">
              <Col>
                <h6>Nama Partner </h6>
              </Col>
              <Col>
                <h6 className="fw-bold">
                  {trxDetail?.data?.mst_partner_service?.mst_partner?.name}
                </h6>
              </Col>
            </Row>
            <Row>
              <Col>
                <h6>No. Ponsel </h6>
              </Col>
              <Col>
                <h6 className="fw-bold">
                  {trxDetail?.data?.mst_partner_service?.mst_partner?.wa_number}
                </h6>
              </Col>
            </Row>
            <Row>
              <Col>
                <h6>Area Layanan </h6>
              </Col>
              <Col>
                <h6 className="fw-bold">
                  {trxDetail?.data?.mst_partner_service?.mst_regency?.name}
                </h6>
              </Col>
            </Row>
          </Col>
          <Col>
            <h5 className="fw-bold text-secondary">Untuk :</h5>
            <Row className="mt-1">
              <Col>
                <h6>Nama Perusahaan </h6>
              </Col>
              <Col>
                <h6 className="fw-bold">
                  {trxDetail?.data?.mst_customer?.company_name || "-"}
                </h6>
              </Col>
            </Row>
            <Row>
              <Col>
                <h6>Nama Customer </h6>
              </Col>
              <Col>
                <h6 className="fw-bold">
                  {trxDetail?.data?.mst_customer?.name}
                </h6>
              </Col>
            </Row>
            <Row>
              <Col>
                <h6>No. Ponsel </h6>
              </Col>
              <Col>
                <h6 className="fw-bold">
                  {trxDetail?.data?.mst_customer?.wa_number}
                </h6>
              </Col>
            </Row>
            <Row>
              <Col>
                <h6>Alamat Customer </h6>
              </Col>
              <Col>
                <h6 className="fw-bold">
                  {trxDetail?.data?.mst_customer?.address}
                </h6>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-2">
          <h5 className="fw-bold text-secondary mt-1">Detail Transaksi</h5>
        </Row>
        <table id="invoice-table" style={{ width: "100%" }}>
          <tr>
            <th>ID Transaksi</th>
            <th>Info Pesanan</th>
            <th>Waktu Pesanan</th>
            {/* <th>Info Barang</th> */}
            <th>Total Tagihan</th>
          </tr>
          <tr>
            <td className="text-primary ">{trxDetail.data?.order_number}</td>
            <td>
              Sewa Kendaraan (
              {trxDetail?.data?.category_order === "SCHEDULED"
                ? "Terjadwal"
                : "Instant"}
              )
            </td>
            <td>
              {moment(trxDetail?.data?.created_at).format(
                "DD MMM YYYY HH : mm"
              )}
            </td>
            {/* <td>Makanan & Minuman</td> */}
            <td>
              Rp{" "}
              {util.thousandSeparatorPure(trxDetail?.data?.trx_payment?.amount)}
            </td>
          </tr>
        </table>

        <Row className="mt-2">
          <Col></Col>
          <Col xs={8}>
            <div className="d-flex align-items-center justify-content-between">
              <h6>Tarif Sewa Unit</h6>
              <h6>
                Rp{" "}
                {util.thousandSeparatorPure(
                  trxDetail?.data?.trx_booking_rent?.vehicle_price
                )}
              </h6>
            </div>
            <div className="">
              <h6>Layanan Tambahan</h6>
              <ul className="addtional">
                <li className="fs-12">
                  Tenaga Supir
                  <span className="float-end">
                    Rp{" "}
                    {util.thousandSeparatorPure(
                      trxDetail?.data?.trx_booking_rent?.driver_price || 0
                    )}
                  </span>
                </li>
                <li
                  className="fs-12"
                  // hidden={trxDetail?.data?.trx_booking_send?.total_helper === 0}
                >
                  Helper
                  <span className="float-end">
                    Rp{" "}
                    {util.thousandSeparatorPure(
                      trxDetail?.data?.trx_booking_rent?.helper_price || 0
                    )}
                  </span>
                </li>
                {/* <li
                  className="fs-12"
                >
                  Asuransi
                  <span className="float-end">

                  </span>
                </li> */}
              </ul>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <h6>Pajak</h6>
              <h6>
                Rp{" "}
                {util.thousandSeparatorPure(
                  trxDetail?.data?.customer_vat +
                    trxDetail?.data?.customer_servive_fee
                )}
              </h6>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <h6>Admin Fee</h6>
              <h6>
                Rp{" "}
                {util.thousandSeparatorPure(
                  trxDetail?.data?.trx_payment?.amount_admin
                )}
              </h6>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <h6>Diskon</h6>
              <h6>
                (Rp{" "}
                {util.thousandSeparatorPure(
                  trxDetail?.data?.elog_discount +
                    trxDetail?.data?.partner_discount
                ) || 0}
                )
              </h6>
            </div>
            {/* <hr />
            <div className="d-flex align-items-center justify-content-between">
              <h6 className="fw-bold">Total Biaya Layanan</h6>
              <h6 className="fw-bold">
                Rp380.000
              </h6>
            </div> */}
            <hr />

            <div className="d-flex align-items-center justify-content-between w-75 mt-4">
              <h6 className="text-left">Biaya Kerusakan</h6>
              <h6 className="">
                Rp{" "}
                {util.thousandSeparatorPure(
                  trxDetail?.data?.trx_booking_rent?.trx_booking_rent_vehicle
                    ?.damage_compensation || 0
                )}
              </h6>
            </div>
            <div className="d-flex align-items-center justify-content-between w-75">
              <h6 className="text-left">
                Biaya ekstra Kilometer ({" "}
                {trxDetail?.data?.trx_booking_rent?.trx_booking_rent_vehicle
                  ?.excess_usage_km || 0}{" "}
                KM )
              </h6>
              <h6 className="">
                Rp{" "}
                {util.thousandSeparatorPure(
                  trxDetail?.data?.trx_booking_rent?.trx_booking_rent_vehicle
                    ?.usage_compensation || 0
                )}
              </h6>
            </div>
            {/* <div className="d-flex align-items-center justify-content-between w-75">
                  <h6 className="text-left">Biaya ekstra Bahan Bakar</h6>
                  <h6 className="">
                    Rp{" "}
                    {util.thousandSeparatorPure(
                      trxDetail?.data?.trx_booking_rent
                        ?.trx_booking_rent_vehicle?.fuel_compensation || 0
                    )}
                  </h6>
                </div> */}
            <div className="d-flex align-items-center justify-content-between">
              <h6 className="text-left">Total Biaya Tambahan</h6>
              <h6 className="">
                Rp{" "}
                {util.thousandSeparatorPure(
                  trxDetail?.data?.trx_booking_rent?.trx_booking_rent_vehicle
                    ?.total_compensation || 0
                )}
              </h6>
            </div>
            <hr />
            {/* <Divider orientation="left" /> */}
            <div className="d-flex align-items-center justify-content-between">
              {/* <p></p> */}
              <h6 className="">Metode Pembayaran</h6>
              <div>
                <h6 className="">
                  {trxDetail?.data?.trx_payment?.payment_method_name}
                  {/* (Rp{" "}
            {util.thousandSeparatorPure(trxDetail?.data?.trx_payment?.amount)}) */}
                </h6>
                <h6 className="">
                  {trxDetail?.data?.trx_payment?.va_number
                    ? `No Va: ${trxDetail?.data?.trx_payment?.va_number}`
                    : `No : ${trxDetail?.data?.trx_payment?.phone_number}`}
                </h6>
                <h6 className="">
                  ({" "}
                  {trxDetail?.data?.paid_date
                    ? moment(trxDetail?.data?.paid_date).format(
                        "DD/MM/YYYY HH:mm"
                      )
                    : "-"}{" "}
                  )
                  {/* (Rp{" "}
            {util.thousandSeparatorPure(trxDetail?.data?.trx_payment?.amount)}) */}
                </h6>
              </div>
            </div>
            <hr />
            <div className="d-flex align-items-center justify-content-between">
              <h6 className="">Total Tagihan</h6>
              <h6 className="">
                (Rp{" "}
                {util.thousandSeparatorPure(
                  trxDetail?.data?.trx_payment?.amount
                )}
                )
              </h6>
            </div>
          </Col>
        </Row>
        <div className="d-flex mt-auto align-items-center bg-secondary justify-content-between px-4">
          <h6 className="text-white fw-bold">www.elog.co.id</h6>
          <img src={elogwhite} width={100} height={75} alt="elog white" />
          <div className="d-flex flex-column">
            <h6 className="text-white float-end">Download aplikasi eLOG di</h6>
            <div className="d-flex align-self-end">
              <img src={appStore} className="me-2" height={25} alt="" />
              <img src={gPlay} height={25} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default RentInvoice;
